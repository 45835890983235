import { Inline } from '@bedrock-layout/primitives';
import _isNil from 'lodash/isNil';
import { useEffect, useMemo } from 'react';
import type { UseControllerProps } from 'react-hook-form';

import { getPublicIP } from '../../../utils/common';
import type { QuestionModel } from '../models';
import { questionComponents } from './QuestionComponents';
import { QuestionnairePageContainer } from './Questionnaire.styled';

export type QuestionnairePageProps = Omit<UseControllerProps, 'name'> & {
  questions: QuestionModel[];
  isSmallScreen: boolean;
};

export function QuestionnairePage({
  questions,
  control,
  isSmallScreen,
}: QuestionnairePageProps) {
  const inlineQuestions = useMemo(() => {
    return questions.filter(({ styles }) => styles?.width === 'half');
  }, [questions]);

  const nonInlineQuestions = useMemo(
    () => questions.filter(({ styles }) => styles?.width !== 'half'),
    [questions]
  );

  useEffect(() => {
    void getPublicIP();
  }, []);

  return (
    <QuestionnairePageContainer>
      {isSmallScreen ? (
        <>
          {questions.map((question) => {
            const { key, questionType } = question;

            const Component = questionComponents[questionType];

            return (
              <>
                {!_isNil(Component) && (
                  <Component key={key} question={question} control={control} />
                )}
              </>
            );
          })}
        </>
      ) : (
        <>
          {inlineQuestions.length > 0 && (
            <Inline>
              {inlineQuestions.map((question) => {
                const { key, questionType } = question;

                const Component = questionComponents[questionType];

                return (
                  <>
                    {!_isNil(Component) && (
                      <Component
                        key={key}
                        question={question}
                        control={control}
                      />
                    )}
                  </>
                );
              })}
            </Inline>
          )}

          {nonInlineQuestions.map((question) => {
            const { key, questionType } = question;

            const Component = questionComponents[questionType];

            return (
              <>
                {!_isNil(Component) && (
                  <Component key={key} question={question} control={control} />
                )}
              </>
            );
          })}
        </>
      )}
    </QuestionnairePageContainer>
  );
}
