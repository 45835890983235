import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import styled from 'styled-components';

export const PublishButtonContainer = styled(PadBox)`
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: var(--color-white);
`;

export const FooterActionsContainer = styled(Inline)<{
  $deleteDisable?: boolean;
}>`
  justify-content: ${({ $deleteDisable = false }) =>
    $deleteDisable ? 'end' : 'space-between'};
`;
