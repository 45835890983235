export const oAuthJson = {
  grantType: {
    action: '',
    label: 'Grant Type',
    order: 1,
    placeholder: 'Select Grant Type',
    tooltipText:
      'These are methods for getting tokens to make requests to a resource server',
    type: 'dropdown',
    defaultValue: 'client_credentials',
    options: [
      {
        label: 'Client Credentials',
        value: 'client_credentials',
      },
      {
        label: 'Password',
        value: 'password',
      },
      {
        label: 'Authentication Code',
        value: 'authorization_code',
      },
    ],
    hidden: 'false',
    isDisabled: 'false',
    isRequired: 'true',
    width: '100%',
  },
  authURL: {
    label: 'Authorization URL',
    order: 2,
    placeholder: 'https://www.example.com',
    regex: '^(http|https)://([\\w-]+\\.)+[\\w-]+(/[\\w-./?%&=]*)?$',
    tooltipText: null,
    type: 'text',
    hidden: "!({grantType} == 'authorization_code')",
    isRequired: "{grantType} == 'authorization_code'",
    isDisabled: 'false',
    width: '100%',
  },
  tokenType: {
    label: 'Header Prefix',
    order: 3,
    placeholder: '',
    regex: '',
    tooltipText: null,
    type: 'text',
    value: 'Bearer',
    hidden: 'false',
    isDisabled: 'false',
    isRequired: 'false',
    width: '100%',
  },
  tokenURL: {
    label: 'Token URL',
    order: 4,
    placeholder: '',
    regex: '^(http|https)://([\\w-]+\\.)+[\\w-]+(/[\\w-./?%&=]*)?$',
    tooltipText:
      'The full URL path to the token endpoint that serves OAuth tokens',
    type: 'text',
    hidden: 'false',
    isDisabled: 'false',
    isRequired: 'true',
    width: '100%',
  },
  clientId: {
    label: 'Client ID',
    order: 5,
    placeholder: '',
    regex: '',
    tooltipText:
      'A public identifier used to identify this client to the authorization/token servers.',
    type: 'text',
    hidden: 'false',
    isDisabled: 'false',
    isRequired: 'true',
    width: '50%',
  },
  clientSecret: {
    label: 'Client Secret',
    order: 6,
    placeholder: '',
    regex: '',
    tooltipText:
      'A secret shared between the client and the authorizing/token servers to verify the client ID.',
    type: 'password',
    hidden: 'false',
    isDisabled: 'false',
    isRequired: 'true',
    width: '50%',
  },
  audience: {
    label: 'Audience',
    order: 7,
    placeholder: '',
    regex: '',
    tooltipText:
      'The intended recipient of the token, most likely a resource server.',
    type: 'text',
    hidden: 'false',
    isDisabled: 'false',
    isRequired: 'false',
    width: '100%',
    helpText: 'Type comma separated values',
  },
  scopes: {
    label: 'Scope',
    order: 8,
    placeholder: '',
    regex: '',
    tooltipText: 'A comma-separated list of permissions to be requested.',
    type: 'text',
    hidden: "!({grantType} != 'password')",
    isDisabled: 'false',
    isRequired: 'false',
    width: '100%',
    helpText: 'Type comma separated values',
  },
  username: {
    label: 'Username',
    order: 9,
    placeholder: '',
    regex: '',
    tooltipText: null,
    type: 'text',
    hidden: "!({grantType} == 'password')",
    isDisabled: 'false',
    isRequired: "{grantType} == 'password'",
    width: '50%',
  },
  password: {
    label: 'Password',
    order: 9,
    placeholder: '',
    regex: '',
    tooltipText: null,
    type: 'password',
    hidden: "!({grantType} == 'password')",
    isDisabled: 'false',
    isRequired: "{grantType} == 'password'",
    width: '50%',
  },
};
