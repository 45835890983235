import { Inline } from '@bedrock-layout/inline';
import styled from 'styled-components';

export const NodeStyled = styled(Inline)<{
  $showHover?: boolean;
  $padding?: string;
  $version?: 'v1' | 'v2';
}>`
  cursor: default;
  inline-size: 100%;
  padding-left: 0.5rem;

  ${({ $padding = '4px 0px' }) => $padding !== '' && `padding: ${$padding};`}
  ${({ $showHover = false, $version = 'v1' }) =>
    $showHover
      ? `
      ${
        $version === 'v1' ? '' : 'border-left: 2px solid var(--color-primary1);'
      }
      &:hover {
    background-color: var(--color-primary2);
    cursor: pointer;
  }`
      : `
      ${$version === 'v1' ? '' : 'border-left: 2px solid var(--color-black);'}
      &:hover {
    cursor: not-allowed;
  }`}


  .title-style {
    word-break: break-word;
  }
`;

export const NodeAccordion = styled.div`
  block-size: 2.2rem;
  inline-size: 2.2rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color-primary2);
  border-radius: 4px;

  &:hover {
    background-color: var(--color-primary2);
  }
`;
