import { z } from 'zod';

export const entiyDataSchema = z.object({
  name: z
    .string()
    .min(1, `Name cannot be empty`)
    .max(100, 'Name cannot be more than 100 characters'),
  description: z
    .string()
    .max(1000, 'Description cannot be more than 1000 characters')
    .optional(),
});
