import { Inline } from '@bedrock-layout/primitives';
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useRef } from 'react';
import { useVirtual } from 'react-virtual';

import { RuleTestActionObject } from '../../types';

export type TableProps = {
  columns: Array<ColumnDef<any>>;
  data: RuleTestActionObject[];
};

export function Table({ columns, data }: TableProps) {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const { rows } = table.getRowModel();

  const tableContainerRef = useRef<HTMLDivElement>(null);

  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: 10,
  });
  const { virtualItems: virtualRows } = rowVirtualizer;

  return (
    <table>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th
                key={header.id}
                colSpan={header.id === 'name' ? 2 : header.colSpan}
              >
                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {virtualRows.map((virtualRow) => {
          const row = rows[virtualRow.index];

          return (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <Inline align="start" as={'td'} key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </Inline>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
