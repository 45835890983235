import styled from 'styled-components';

export const MenuLauncherStyled = styled.button`
  background: var(--color-whiteSmoke);
  border-radius: 4px;
  cursor: pointer;
  border: none;
  font-size: 12px;
  padding: 0.6rem 1rem;
`;
