import { Inline, PadBox, Stack } from '@bedrock-layout/primitives';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useRef, useState } from 'react';
import { CiSearch } from 'react-icons/ci';
import {
  CheckboxInput,
  DateTimePickerInput,
  Menu,
  MenuItem,
  PopoverMethods,
  TextInput,
} from 'ui';

import { InlineChildrenContainer } from '../../../pages/Workspace/component/common/InlineChildrenContainer';
import type { ListingDetailsProps } from '../types';
import { renderFilterLauncher } from './renderFilterLauncher';

type RenderFiltersProps = {
  filters: ListingDetailsProps;
  handleFiltersChange: (event: any, name: string, type: string) => void;
};

export const RenderFilters = ({
  filters,
  handleFiltersChange,
}: RenderFiltersProps) => {
  const [searchValue, setSearchValue] = useState<string>('');

  const ref = useRef<PopoverMethods>(null);

  const searchKeyName = filters.allFilters?.find(
    (filterObj: any) => filterObj.type === 'searchBox'
  )?.keyName;

  useEffect(() => {
    if (!_isNil(searchKeyName) && !_isEmpty(searchKeyName)) {
      setSearchValue(filters.appliedFilters[searchKeyName]);
    }
  }, []);

  return (
    <Inline gutter="1rem" align="center">
      {filters.allFilters?.map((filterObj: any, index: number) => {
        const filter = filterObj.keyName;
        const count = _isEmpty(filters.appliedFilters[filter])
          ? 0
          : Object.keys(filters.appliedFilters[filter]).length;

        return (
          <Stack style={{ position: 'relative' }} gutter="1rem" key={index}>
            {filterObj.type === 'datetime' && (
              <DateTimePickerInput
                showIcon
                timeInputLabel="Time:"
                showTimeInput
                showCustomInput
                value={
                  !_isNil(filters.appliedFilters[filter]) &&
                  !_isEmpty(filters.appliedFilters[filter])
                    ? new Date(filters.appliedFilters[filter])
                    : null
                }
                placeholderText={filterObj.name}
                dateFormat="d, MMM, yy h:mm aa"
                name={filter}
                onChange={(e) => handleFiltersChange(e, filter, filterObj.type)}
              />
            )}
            {filterObj.type === 'dropdown' && (
              <>
                <Menu
                  launcher={renderFilterLauncher(filterObj.name, count)}
                  onMenuItemClick={() => {}}
                  ref={ref}
                  placement="bottom-start"
                >
                  <>
                    {filterObj.values.map(
                      (details: Record<string, string>, index: number) => {
                        const { label, value } = details;

                        return (
                          <MenuItem minWidth="15rem" value={label} key={index}>
                            <PadBox padding="0.5rem">
                              <CheckboxInput
                                label={label}
                                useId={value}
                                name={value}
                                checked={
                                  filters.appliedFilters[filter]?.[value] ??
                                  false
                                }
                                onChange={(e) =>
                                  handleFiltersChange(e, filter, filterObj.type)
                                }
                              />
                            </PadBox>
                          </MenuItem>
                        );
                      }
                    )}
                  </>
                </Menu>
              </>
            )}
            {filterObj.type === 'searchBox' && (
              <InlineChildrenContainer>
                <PadBox
                  padding={{
                    left: '.8rem',
                  }}
                >
                  <CiSearch />
                </PadBox>
                <TextInput
                  placeholder={filterObj.name}
                  value={searchValue}
                  id={filter}
                  showOutline={false}
                  name={filter}
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                    handleFiltersChange(e.target.value, filter, filterObj.type);
                  }}
                />
              </InlineChildrenContainer>
            )}
          </Stack>
        );
      })}
    </Inline>
  );
};
