import { PadBox } from '@bedrock-layout/padbox';
import { Inline, Stack } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { MdDelete } from 'react-icons/md';
import { Dataset, IconButton } from 'ui';

import { isWorkflowReadOnlyAtom } from '../../../../../atoms/atoms';
import {
  getGroupNodeFromPath,
  getIdsToBeRemovedForGroup,
} from '../../../../../utils/common';
import { AddCondition } from './AddCondition';
import { PathGroupContainer } from './PathBlock.styled';
import { PathCondition } from './PathCondition';
import { PathOperator } from './PathOperator';

type PathGroupProps = {
  control?: Control<any>;
  setValue: UseFormSetValue<any>;
  name: string;
  remove: (index: number) => void;
  watch: UseFormWatch<any>;
  index: number;
  path: Record<string, any>;
  updatedDataSet: Record<string, Dataset>;
  size: number;
  groupIndex: number;
  groupId: string;
  groupKey: string;
  groupName: string;
  pathKey: string;
  parentId: string;
};

export default function PathGroup({
  index,
  name,
  path,
  remove,
  setValue,
  size,
  updatedDataSet,
  watch,
  control,
  groupName,
  groupId,
  groupIndex,
  groupKey,
  pathKey,
  parentId,
}: PathGroupProps) {
  const groupNode = getGroupNodeFromPath(path, groupId);
  const [isWorkflowReadOnly] = useAtom(isWorkflowReadOnlyAtom);

  const handleRemoveGroup = () => {
    const path = watch(`paths.${groupIndex}`);

    const idsToBeRemoved: string[] = [];

    getIdsToBeRemovedForGroup(groupId, path, idsToBeRemoved);

    idsToBeRemoved.forEach((id) => {
      path[id] = undefined;
    });

    path[groupId] = undefined;

    const updatedChildren = path[parentId].children.filter(
      (c: string) => c !== groupId
    );

    path[parentId].children = updatedChildren;

    setValue(`paths.${groupIndex}`, path);
  };

  return (
    <PadBox padding="1rem">
      <Inline stretch={'start'} align="center">
        <PathGroupContainer padding="0.5rem">
          <Stack gutter="1rem">
            {groupNode.value?.children.map((id: string, i: number) => {
              const nodeType = watch(`paths.${index}.${id}.nodeType`);

              return (
                <Stack key={`${name}_${i}_${id}_condition`} gutter="0.5rem">
                  {nodeType === 'group' ? (
                    <PathGroup
                      groupId={id}
                      groupIndex={index}
                      groupName={`${name}.${id}`}
                      index={i}
                      setValue={setValue}
                      name={name}
                      path={path}
                      remove={remove}
                      watch={watch}
                      updatedDataSet={updatedDataSet}
                      control={control}
                      groupKey={id}
                      parentId={id}
                      pathKey={pathKey}
                      size={size}
                    />
                  ) : (
                    <PathCondition
                      groupIndex={groupIndex}
                      index={i}
                      name={`${name}.${id}`}
                      conditionId={id}
                      remove={remove}
                      setValue={setValue}
                      watch={watch}
                      control={control}
                      updatedDataSet={updatedDataSet}
                      groupKey={groupNode.key}
                      pathKey={pathKey}
                      size={groupNode.value?.children.length ?? 0}
                    />
                  )}

                  {i !== groupNode.value?.children.length - 1 && (
                    <PadBox
                      padding={{
                        left: '1rem',
                      }}
                    >
                      <PathOperator
                        setValue={setValue}
                        groupName={`paths.${groupIndex}.${groupNode.key}`}
                        operator={groupNode.value.operator}
                      />
                    </PadBox>
                  )}
                </Stack>
              );
            })}
          </Stack>

          <PadBox padding={['0.5rem', '1rem']}>
            <Inline>
              <AddCondition
                watch={watch}
                groupIndex={groupIndex}
                isAlone={(groupNode.value?.children?.length ?? 0) <= 1}
                setValue={setValue}
                control={control}
                groupKey={groupNode.key}
                size={size}
                groupName={`paths.${groupIndex}.${groupNode.key}`}
              />
            </Inline>
          </PadBox>
        </PathGroupContainer>

        {size > 1 && (
          <IconButton disabled={isWorkflowReadOnly} onClick={handleRemoveGroup}>
            <MdDelete />
          </IconButton>
        )}
      </Inline>
    </PadBox>
  );
}
