import { Inline } from '@bedrock-layout/primitives';
import styled, { css } from 'styled-components';

export const AddDataContainer = styled(Inline)<{ $disabled: boolean }>`
  cursor: pointer;
  color: var(--color-primary1);
  background-color: var(--color-white);

  ${({ $disabled = false }) =>
    $disabled &&
    css`
      cursor: default;
      color: var(--color-darkGray);
    `}
`;

export const AdditionalDataContainer = styled(Inline)`
  flex-wrap: wrap;
  border-radius: 6px;
  border: 1px solid var(--color-whiteSmoke);
  padding: 0.8rem;
  max-inline-size: 60vw;
  min-inline-size: 46.4rem;
  margin-bottom: 0.8rem;
`;
