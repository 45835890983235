import { Inline, Stack } from '@bedrock-layout/primitives';
import styled, { css } from 'styled-components';

type MessageContainerProps = {
  align: string;
};

export const Message = styled.div`
  font-size: 1rem;
`;

export const MessageContainer = styled(Stack)<MessageContainerProps>`
  ${({ align }) =>
    css`
      align-items: ${align};
    `}
`;

export const VariantContainer = styled(Inline)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;
