import { Center } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import styled from 'styled-components';

export const FormContainer = styled(Center)`
  padding-bottom: 3rem;
`;

export const QuestionnairePageContainer = styled.div`
  display: flex;
  gap: 2.5rem;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 2rem;
`;

export const QuestionnaireFormHeader = styled.div`
  display: flex;
  gap: 0rem;
  -webkit-box-pack: center;
  justify-content: center;
  flex-direction: column;
`;

export const FormStyled = styled.form`
  max-width: 80rem;
  min-width: 80rem;
  background: rgba(244, 244, 252, 1);
  border-radius: 4px;
  padding: 2rem 4rem;

  @media (max-width: 360px) {
    max-width: 28rem;
    min-width: 20rem;
    padding: 2.4rem;
  }

  @media (min-width: 361px) and (max-width: 480px) {
    max-width: 32rem;
    min-width: 32rem;
    padding: 2.4rem;
  }

  @media (min-width: 481px) and (max-width: 760px) {
    max-width: 40rem;
    min-width: 35rem;
    padding: 2.4rem;
  }

  @media (min-width: 761px) and (max-width: 1000px) {
    max-width: 70rem;
    min-width: 70rem;
    padding: 2.4rem;
  }
`;

export const ProgressBarContainer = styled.div`
  width: 100%;
`;

export const SkipButtonContainer = styled.div`
  position: relative;
  inset-block-start: 0.5rem;
`;

export const CoverStyled = styled.div`
  background-color: rgba(244, 244, 252, 1);
  block-size: 100vh;
  padding-top: 0rem;
`;

export const QuestionCount = styled(Stack)`
  flex: 1;
  position: relative;
`;
export const SkipQuestion = styled.div`
  position: absolute;
  right: 0px;
`;

export const QuestionContainer = styled(Stack)`
  flex: 1;
`;
