import { gql, useLazyQuery } from '@apollo/client';

import type { DependencyUsingModel } from '../../model';

const getDependencyUsingQuery = gql`
  query getDependencies(
    $entityId: String
    $entityType: EntityTypes
    $version: String
    $perPage: Int
    $page: Int
  ) {
    getDependencies(
      entityId: $entityId
      entityType: $entityType
      version: $version
      perPage: $perPage
      page: $page
    ) {
      data {
        id
        name
        type
        version
        commitTitle
        subType
        nodeId
      }
      paginationInfo {
        perPage
        currentPage
        totalPage
        totalRecord
      }
    }
  }
`;

export function useGetDependencyUsingData() {
  return useLazyQuery<DependencyUsingModel, Record<string, any>>(
    getDependencyUsingQuery
  );
}
