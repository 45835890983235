import { useState } from 'react';

export type RuleTestResponse = {
  json: string;
  executionTime: string;
};

export function useServerResponse() {
  const [serverResponse, setServerResponse] = useState<RuleTestResponse>({
    json: '',
    executionTime: '',
  });

  return { serverResponse, setServerResponse };
}
