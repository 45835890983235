import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import _reduce from 'lodash/reduce';
import { useEffect, useRef, useState } from 'react';
import {
  OperatorOnChange,
  OperatorsPopover,
  OperatorsProps,
  PopoverMethods,
  PopoverPanel,
} from 'ui';

import { operators } from '../../../fixtures/operators';
import { isRuleReadOnlyAtom } from '../../../index';
import { getFreshRulesRhs, getOperatorTextById } from '../../../utils/common';
import { sendEventToGTMType } from '../RuleBlock/RuleBlock';
import { simpleNodeErrors, simpleRuleNodesAtom } from '../SimpleRule';
import { ErrorByNodeId, SimpleRuleNodesModel } from '../models';
import { OperatorsLauncher } from './OperatorLauncher';
import { PopoverContainerStyled } from './RuleParamPopover.styled';

type OperatorParamsPopoverProps = {
  ruleId: string;
  operator?: string;
  handleSendEventToGTM?: (obj: sendEventToGTMType) => void;
};

export const OperatorParamPopover = ({
  ruleId,
  operator,
  handleSendEventToGTM,
}: OperatorParamsPopoverProps) => {
  const [rules, setRules] = useAtom(simpleRuleNodesAtom);
  const [errorByRuleId, setErrorByRuleId] = useAtom(simpleNodeErrors);
  const [filteredOperators, setFilteredOperators] = useState<OperatorsProps>(
    {}
  );
  const ref = useRef<PopoverMethods>(null);
  const [isRuleReadOnly] = useAtom(isRuleReadOnlyAtom);

  const currentOperator = getOperatorTextById(
    operators,
    operator,
    rules[ruleId]?.dataType
  );

  const error = !_isNil(errorByRuleId[ruleId])
    ? errorByRuleId[ruleId].message
    : null;

  useEffect(() => {
    setFilteredOperators(
      _reduce(
        operators,
        (result: OperatorsProps, value, key) => {
          if (key === rules[ruleId]?.dataType || key === 'generic') {
            return { ...result, [key]: operators[key] };
          }

          return result;
        },
        {}
      )
    );
  }, [rules[ruleId]]);

  const handleOperatorClick = ({
    value,
    type,
    leftOperands,
    rightOperands,
  }: OperatorOnChange) => {
    if (typeof handleSendEventToGTM === 'function') {
      handleSendEventToGTM({
        action: 'selection',
        element: 'operator',
        actionName: type,
      });
    }

    const rightNodes: string[] = rules[ruleId].rightNode ?? [];
    let updatedRules: Record<string, SimpleRuleNodesModel> = {};

    // if (rightNodes.length !== rightOperands) {
    updatedRules = getFreshRulesRhs(
      rules,
      ruleId,
      rightNodes,
      rightOperands,
      rules[ruleId].dataType ?? ''
    );
    // }

    if (Object.keys(updatedRules).length === 0) {
      updatedRules = { ...rules };
    }

    setRules(updatedRules);

    if (!_isNil(errorByRuleId[ruleId])) {
      setErrorByRuleId((prev) =>
        _reduce(
          prev,
          (result: ErrorByNodeId, value, key) => {
            const rightNodes = rules[ruleId].rightNode;

            if (
              key === ruleId ||
              (!_isNil(rightNodes) && !rightNodes.includes(key))
            ) {
              return result;
            }

            return {
              ...result,
              [key]: prev[key],
            };
          },
          {}
        )
      );
    }

    setRules((prev) => ({
      ...prev,
      [ruleId]: { ...prev[ruleId], operator: value },
    }));

    ref.current?.hide();
  };

  return (
    <PopoverPanel
      trigger="click"
      placement="bottom-start"
      launcher={
        <OperatorsLauncher
          error={error}
          text={currentOperator}
          dataType={rules[ruleId]?.dataType ?? ''}
          handleSendEventToGTM={handleSendEventToGTM}
        />
      }
      ref={ref}
      disabled={isRuleReadOnly}
    >
      <PopoverContainerStyled>
        <OperatorsPopover
          operators={filteredOperators}
          onClick={handleOperatorClick}
        />
      </PopoverContainerStyled>
    </PopoverPanel>
  );
};
