import { Inline } from '@bedrock-layout/primitives';
import styled from 'styled-components';

export const AddNodeLargeStyled = styled.div`
  block-size: 8rem;
  inline-size: 8rem;
  border-radius: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e3ecef;
`;

export const AddNodeMdContainer = styled(Inline)`
  inline-size: 8rem;
  /* block-size: 8rem; */
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;
