import { Inline, PadBox } from '@bedrock-layout/primitives';
import { Control } from 'react-hook-form';
import { EditorLanguages, NectedEditorField, Sheet, Typography } from 'ui';

import { editorDomain } from '../../../../../utils/constant';
import { JsonFieldContainer } from './Fields.styled';

type JsonEditorProps = {
  control?: Control<any>;
  name: string;
  defaultValue?: string;
  type: string;
};

export function JsonEditor({
  type,
  control,
  name,
  defaultValue,
}: JsonEditorProps) {
  return (
    <Sheet size="medium">
      <PadBox padding="1rem">
        <Inline align="center" stretch="start">
          <Typography name="heading1">
            {type === 'json' ? 'JSON' : 'List'} Editor
          </Typography>
        </Inline>
      </PadBox>
      <JsonFieldContainer>
        <NectedEditorField
          defaultValue={defaultValue}
          name={name}
          control={control}
          showError={false}
          mode={type as EditorLanguages}
          domain={editorDomain}
        />
      </JsonFieldContainer>
    </Sheet>
  );
}
