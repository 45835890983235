import { ImRadioChecked, ImRadioUnchecked } from 'react-icons/im';

import { RadioIcon, RadioInputStyled } from './RadioInput.styled';

export type RadioInputProps = {
  value: string;
  checked: boolean;
  id: string;
  displayRadio: boolean;
};

export function RadioInput({
  value,
  checked,
  id,
  displayRadio,
  ...rest
}: RadioInputProps) {
  return (
    <>
      <RadioInputStyled value={value} id={id} checked={checked} {...rest} />
      {displayRadio && (
        <>
          {checked ? (
            <RadioIcon>
              <ImRadioChecked size={18} />
            </RadioIcon>
          ) : (
            <RadioIcon>
              <ImRadioUnchecked size={18} />
            </RadioIcon>
          )}
        </>
      )}
    </>
  );
}
