import { Children, ReactElement, ReactNode, cloneElement } from 'react';

import { TabListStyled } from './Tab.styled';

export type TabListProps = {
  children: ReactElement | ReactElement[];
  activeIndex?: number;
  type?: string;
  onClick?: (tab: number) => void;
  styleClassName?: string;
  tabEnd?: ReactNode;
};

export function TabList({
  children,
  activeIndex = 0,
  type = 'horizontal',
  onClick,
  styleClassName = '',
  tabEnd,
}: TabListProps) {
  const gutterByType = type === 'vertical' ? '0.5rem' : 0;

  return (
    <TabListStyled
      className={styleClassName}
      type={type}
      gutter={gutterByType}
      stretch={Array.isArray(children) ? children.length : undefined}
    >
      {Children.map(children, (child, index) =>
        cloneElement(child, {
          active: activeIndex === index,
          type,
          onClick: () => {
            if (onClick != null) {
              onClick(index);
            }
          },
        })
      )}

      {tabEnd}
    </TabListStyled>
  );
}
