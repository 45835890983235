import { Inline } from '@bedrock-layout/primitives';
import { ReactNode, useState } from 'react';
import { BiCopy } from 'react-icons/bi';
import { IconButton, Typography } from 'ui';

import { SelectionStyled } from './RuleExecutionOutput.styled';

type ExecutionViewProps = {
  children: ReactNode[] | ReactNode;
  defaultIndex?: number;
  onCopy?: () => void;
};

export function ExecutionView({
  children,
  defaultIndex = 0,
  onCopy,
}: ExecutionViewProps) {
  const [tabItem, setTabItem] = useState(defaultIndex);

  return (
    <>
      <Inline
        stretch={typeof onCopy === 'function' ? 'end' : undefined}
        gutter={0}
      >
        <SelectionStyled
          $bgColor={
            tabItem === 0 ? 'var(--color-primary1)' : 'var(--color-white)'
          }
          $color={tabItem === 0 ? 'var(--color-white)' : 'var(--color-black)'}
          onClick={() => setTabItem(0)}
        >
          <Typography>Raw</Typography>
        </SelectionStyled>

        <SelectionStyled
          $bgColor={
            tabItem === 1 ? 'var(--color-primary1)' : 'var(--color-white)'
          }
          $color={tabItem === 1 ? 'var(--color-white)' : 'var(--color-black)'}
          onClick={() => setTabItem(1)}
        >
          <Typography>Pretty</Typography>
        </SelectionStyled>

        {typeof onCopy === 'function' && (
          <Inline
            style={{
              blockSize: '4rem',
            }}
            align="center"
            justify="end"
          >
            <IconButton onClick={() => onCopy()}>
              <BiCopy />
            </IconButton>
          </Inline>
        )}
      </Inline>

      {Array.isArray(children) ? children[tabItem] : children}
    </>
  );
}
