import { Inline, PadBox, Stack } from '@bedrock-layout/primitives';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useMemo } from 'react';
import { IoMdArrowDropdown, IoMdArrowDropright } from 'react-icons/io';
import { IconButton, Image, Typography } from 'ui';

import { isValidImageURL } from '../../utils/common';
import type { FromType } from '../Modals/ImportEntityModal/ImportEntityModal';
import type { DependencyConfig } from './EntityDependencyView';
import { NodeContainer, StyledBlueText } from './Node.styled';

type NodeProps = {
  rootId: string;
  id: string;
  data: DependencyConfig;
  handleShowChildNodes: (id: string) => void;
  nodeId: string;
  parentId?: string;
  isExpanded?: boolean;
  iconUrl?: string;
  from?: FromType;
};

export function Node({
  rootId,
  id,
  nodeId,
  data,
  isExpanded = false,
  parentId,
  handleShowChildNodes,
  iconUrl,
  from = 'import_sheet',
}: NodeProps) {
  const {
    name,
    category,
    entity,
    isNameConflict,
    isCategoryConflict,
    dependencies,
    version,
  } = data;

  const isConflict = isNameConflict || isCategoryConflict;

  const categoryName = useMemo(() => {
    switch (entity) {
      case 'variable':
        return 'Global Variable';
      case 'dataSet':
        return 'Datasource';
      case 'workflow':
        return 'Workflow';
      default:
        return category;
    }
  }, [category, entity]);

  return (
    <NodeContainer $hasBeforeClass={rootId !== id || !_isNil(parentId)}>
      <Stack gutter="0.5rem">
        <Inline gutter="0.5rem" align="center">
          {!_isNil(dependencies) && dependencies.length !== 0 && (
            <IconButton onClick={() => handleShowChildNodes(nodeId)}>
              {isExpanded ? <IoMdArrowDropdown /> : <IoMdArrowDropright />}
            </IconButton>
          )}

          {!_isNil(iconUrl) && (
            <Image
              src={isValidImageURL(iconUrl)}
              alt="minus"
              size="small"
              round
            />
          )}
          <Typography fontWeight={400}>{name}</Typography>
          {!_isNil(version) && !_isEmpty(version) && (
            <Typography name="paragraphSmall">{`(${version})`}</Typography>
          )}
          <Typography name="paragraphXs">{`(${categoryName})`}</Typography>
        </Inline>
        <PadBox padding={[0, 0, 0, '2.5rem']}>
          {from.includes('import') && (
            <StyledBlueText name="paragraphXs">
              {isConflict ? '(Already Exists)' : '(Not Found)'}
            </StyledBlueText>
          )}

          {from.includes('export') && (
            <StyledBlueText name="paragraphXs">
              {!isConflict &&
                entity === 'connector' &&
                'Credentials are not exported'}
            </StyledBlueText>
          )}
        </PadBox>
      </Stack>
    </NodeContainer>
  );
}
