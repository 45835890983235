import { NectedSuggestionModel } from './NectedEditor';

function allBracketsHaveZeroIndex(key: string) {
  let match;
  const EXTRACT_SQUARE_BRACKETS = /\[\d+\]/g;
  while ((match = EXTRACT_SQUARE_BRACKETS.exec(key)) !== null) {
    if (match[0] !== '[0]') {
      return false;
    }
  }

  return true;
}

export const formatCustomAttributes = (tokens: NectedSuggestionModel[]) => {
  return tokens
    .map((token) => {
      const newTokenName = token.name
        ?.replace('"<<', '<<')
        .replace('>>"', '>>')
        .replace('"{{', '<<')
        .replace('}}"', '>>');

      const newTokenValue = token.value
        ?.replace('"<<', '<<')
        .replace('>>"', '>>')
        .replace('"{{', '<<')
        .replace('}}"', '>>');

      let tokenName = newTokenName?.replace('<<', '{{.').replace('>>', '}}');
      let tokenValue = newTokenValue.replace('<<', '{{.').replace('>>', '}}');

      if (token.meta === 'string') {
        tokenName = tokenName?.replace('{{.', '"{{.').replace('}}', '}}"');
        tokenValue = tokenValue.replace('{{.', '"{{.').replace('}}', '}}"');
      }

      return {
        ...token,
        name: tokenName?.replaceAll('..', '.'),
        value: tokenValue?.replaceAll('..', '.'),
        display: allBracketsHaveZeroIndex(tokenName ?? ''),
      };
    })
    .filter(
      (token) =>
        !['{{.custom.sql}}', '{{.custom.excel}}'].includes(token.value ?? '')
    );
};
