import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { ReactElement } from 'react';
import { Menu, MenuItem, Typography } from 'ui';

import { AddIcon } from '../../../../../components/icons/AddIcon';
import { PropertyMenuItem } from './DecisionTableBlock.styled';

type PropertyMenuProps = {
  onItemSelect: (type: string) => void;
  showGroup?: boolean;
  launcher?: ReactElement;
  disabled?: boolean;
};

export function PropertyMenu({
  onItemSelect,
  showGroup = false,
  launcher,
  disabled = false,
}: PropertyMenuProps) {
  const menuItems = [
    <MenuItem value="condition" key="condition-dropdown">
      <PropertyMenuItem>
        <Stack>
          <Typography>Add Condition</Typography>
        </Stack>
        <AddIcon color="var(--color-black)" />
      </PropertyMenuItem>
    </MenuItem>,
  ];

  if (showGroup) {
    menuItems.push(
      <MenuItem value="group" key="group-dropdown">
        <PropertyMenuItem>
          <Stack>
            <Inline justify="end">
              <Typography>Add Group</Typography>
            </Inline>
            <Typography name="paragraphXs">
              A group to nest conditions
            </Typography>
          </Stack>

          <AddIcon color="var(--color-black)" />
        </PropertyMenuItem>
      </MenuItem>
    );
  }

  return (
    <Menu
      onMenuItemClick={onItemSelect}
      launcher={launcher}
      disabled={disabled}
    >
      {menuItems.map((menuItem) => menuItem)}
    </Menu>
  );
}
