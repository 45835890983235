import { gql, useMutation } from '@apollo/client';

const updateWorkflowSettingsMutation = gql`
  mutation UpdateWorkflow(
    $id: String!
    $checksum: String!
    $editMode: Boolean
    $settings: WorkflowSettingsInput
    $isEnabled: Boolean
    $dependencyMap: [DependencyInput!]
  ) {
    updateWorkflow(
      input: {
        id: $id
        checksum: $checksum
        editMode: $editMode
        settings: $settings
        isEnabled: $isEnabled
        dependencyMap: $dependencyMap
      }
    ) {
      id
      name
      checksum
      status
      staticUrl
      isLive
      approvalInfo {
        title
        description
        approvers
      }
      versionInfo {
        currentVersion
        currentLiveVersion
        lastVersion
      }
      dependencyMap {
        id
        name
        type
        version
        commitTitle
        subType
        nodeId
      }
    }
  }
`;

export function useUpdateSettings() {
  return useMutation(updateWorkflowSettingsMutation);
}
