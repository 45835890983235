import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';

import { generateUid } from '../../../utils/common';
import { simpleRuleNodesAtom } from '../components/SimpleRule';
import type { SimpleRuleNodesModel } from '../components/SimpleRule/models';
import type { sendEventToGTMType } from '../types';
import { handleDeleteElement } from '../utils/common';

type UseHandleRuleMenuActionsProps = {
  ruleId: string;
  rule: SimpleRuleNodesModel;
  handleSendEventToGTM?: (obj: sendEventToGTMType) => void;
};

export function useHandleRuleMenuActions({
  rule,
  ruleId,
  handleSendEventToGTM,
}: UseHandleRuleMenuActionsProps) {
  const [rules, setRules] = useAtom(simpleRuleNodesAtom);

  const siblings = rules[rule.parent]?.children;

  const convertConditionToGroup = (
    item: SimpleRuleNodesModel,
    itemId: string
  ) => {
    const oldRule = { ...item, parent: itemId, siblingIndex: 1 };
    const newRules = { ...rules };

    const id = generateUid('rule_');

    const group = {
      parent: item.parent,
      nodeType: 'group',
      operator: '',
      children: [id],
      siblingIndex: !_isNil(item.siblingIndex) ? item.siblingIndex : 1,
      dataType: item.dataType,
    };

    newRules[itemId] = group;
    newRules[id] = oldRule;

    setRules(newRules);
  };

  const convertGroupToGroup = (item: SimpleRuleNodesModel, itemId: string) => {
    const id = generateUid('rule_');

    const oldParent = item.parent;

    const oldRule = { ...item, parent: id, siblingIndex: 1 };
    const newRules = { ...rules };

    const group = {
      parent: item.parent,
      nodeType: 'group',
      operator: 'and',
      children: [itemId],
      siblingIndex: !_isNil(item.siblingIndex) ? item.siblingIndex : 1,
      dataType: item.dataType,
    };

    newRules[id] = group;
    newRules[oldParent].children = newRules[oldParent].children?.map((c) => {
      if (c === itemId) {
        return id;
      }

      return c;
    });
    newRules[itemId] = oldRule;

    setRules(newRules);
  };

  const unGroupAnElement = () => {
    /**
     * Steps to un group a condition
     * 1. Remove it from parent's children. **
     * 2. Change the sibling index of the other children to oldValue - 1 **
     * 3. Change the current condition's parent to it's grandparent. **
     * 4. Add current condition to it's grand parent's children at position parent->current-value.
     * 5. Set current condition's position to parent's position **
     * 6. Change the sibling index of the other children to oldValue + 1 **
     */

    let sibIndex: null | number = null;

    const newSiblings = siblings?.filter((c, i) => {
      const cond = c === ruleId;

      if (!_isNil(sibIndex)) {
        setRules((prev) => ({
          ...prev,
          [c]: {
            ...prev[c],
            siblingIndex: prev[c].siblingIndex - 1,
          },
        }));
      }

      if (cond) {
        sibIndex = i + 1;

        setRules((prev) => ({
          ...prev,
          [c]: {
            ...prev[c],
            parent: prev[rule.parent].parent,
          },
        }));

        return false;
      }

      return true;
    });

    setRules((prev) => ({
      ...prev,
      [rule.parent]: {
        ...prev[rule.parent],
        children: newSiblings,
      },
    }));

    const grandParent = rules[rules[rule.parent].parent];

    const indexToBeInsertedAt =
      grandParent.children?.findIndex((c) => c === rule.parent) ?? 0;

    const newGC = [...(grandParent.children ?? [])];

    newGC?.splice(indexToBeInsertedAt, 0, ruleId);

    setRules((prev) => ({
      ...prev,
      [rules[rule.parent].parent]: {
        ...prev[rules[rule.parent].parent],
        children: newGC,
      },
    }));

    newGC?.forEach((c, i) => {
      if (i + 1 > indexToBeInsertedAt) {
        setRules((prev) => ({
          ...prev,
          [c]: {
            ...prev[c],
            siblingIndex: i + 2,
          },
        }));
      } else if (i + 1 === indexToBeInsertedAt) {
        setRules((prev) => ({
          ...prev,
          [c]: {
            ...prev[c],
            siblingIndex: i + 1,
          },
        }));
      }
    });

    if (siblings?.length === 1) {
      setRules((prev) => handleDeleteElement(rule.parent, prev));
    }
  };

  const handleUnGroupAGroup = () => {
    /**
     * STEPS: To un-group a group
     * 1. Change the parent of the group's children to it's grand parent
     * 2. Change the sibling index of the group's children to @param oldSIndex + i
     * 3. Change the grand parent's children index if it's greater than @param oldSIndex to oldSIndex + i
     * 4. Append the group's children to it's grand parent's children at position @param oldSIndex + i
     * 6. Filter out the group ID from it's parent's children
     * 7. Empty the group's children
     * 8. Delete the group
     * 9. Set the updated nodes
     */

    const oldChildren = rule.children ?? [];
    const oldSIndex = rule.siblingIndex;
    const oldParent = rule.parent;

    setRules((prev) => {
      const nNodes = prev;
      nNodes[ruleId].children?.forEach((c, i) => {
        nNodes[c].parent = oldParent;
        nNodes[c].siblingIndex = oldSIndex + i;
      });

      nNodes[oldParent].children?.forEach((c, i) => {
        if (i > oldSIndex) {
          nNodes[c].siblingIndex = oldSIndex + nNodes[c].siblingIndex;
        }
      });

      nNodes[oldParent].children?.splice(oldSIndex, 0, ...oldChildren);

      nNodes[oldParent].children =
        nNodes[oldParent].children?.filter((c) => c !== ruleId) ?? [];
      nNodes[oldParent].operator =
        // eslint-disable-next-line
        !!nNodes[oldParent].operator
          ? nNodes[oldParent].operator
          : // eslint-disable-next-line
          !!nNodes[ruleId].operator
          ? nNodes[ruleId].operator
          : 'and';

      nNodes[ruleId].children = [];
      const updatedValue = handleDeleteElement(ruleId, nNodes);

      return updatedValue;
    });
  };

  const handleDelete = (type: string = 'condition') => {
    setRules(handleDeleteElement(ruleId, rules));

    if (typeof handleSendEventToGTM === 'function') {
      handleSendEventToGTM({
        action: 'delete',
        element: type,
        actionName: '',
      });
    }
  };

  return {
    handleDelete,
    convertConditionToGroup,
    unGroupAnElement,
    convertGroupToGroup,
    handleUnGroupAGroup,
  };
}
