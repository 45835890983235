import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  Typography,
  useCurrentLayer,
} from 'ui';

import { handleSetCheckSumByEntityName } from '../../../utils/common';

type DeleteAuthenticationKeyModalProps = {
  title: string;
  checksum: string;
  deleteAuthKey: (id: string) => void;
  authKeyId: string;
  messageTitle: string;
};

export function DeleteAuthenticationKeyModal({
  title,
  checksum,
  deleteAuthKey,
  authKeyId,
  messageTitle,
}: DeleteAuthenticationKeyModalProps) {
  const { close } = useCurrentLayer();

  function deleteAuthKeyAndCloseModal(id: string) {
    deleteAuthKey(id);
    close();
  }

  return (
    <Modal size="medium" title={title}>
      <ModalContent>
        <Typography name="paragraph">
          {`Are you sure you want to delete the ${messageTitle} ? If used to call any rule
            or ruleset, it might fail.`}
        </Typography>
      </ModalContent>

      <ModalFooter>
        <Button appearance="neutral" onClick={close}>
          Cancel
        </Button>

        <Button
          appearance="danger"
          onClick={async () => {
            handleSetCheckSumByEntityName('credentials', checksum);

            deleteAuthKeyAndCloseModal(authKeyId);
          }}
        >
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  );
}
