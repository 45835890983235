import { Inline } from '@bedrock-layout/primitives';
import type { InlineProps } from '@bedrock-layout/primitives';
import { ReactNode } from 'react';

import { ModalFooterContainer } from './Modal.styled';

type ModalFooterProp = {
  children?: ReactNode;
  justify?: InlineProps['justify'];
  showBorder?: boolean;
  hasBgColor?: boolean;
};

export const ModalFooter = ({
  children,
  justify = 'end',
  showBorder = false,
  hasBgColor = false,
}: ModalFooterProp) => {
  return (
    <ModalFooterContainer
      padding={['1rem', 0, 0]}
      $showBorder={showBorder}
      $hasBgColor={hasBgColor}
    >
      <Inline justify={justify} gutter="1rem">
        {children}
      </Inline>
    </ModalFooterContainer>
  );
};
