import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { useEffect } from 'react';

import { premiumFeatureAtom, userProfileAtom } from '../atom';
import { updateWidgetState } from '../pages/Home/components/sub-components/UpdateWidgetState';
import { currentWorkspaceDetailAtom } from '../pages/Workspace/atom';
import { formatNectedDate } from '../utils/common';
import { envMap, userPlanCreated } from '../utils/constant';
import { useAxiosVidhanPrivate } from './useAxiosVidhanPrivate';

type UsePremiumFeatureProps = {
  onLoadGetData?: boolean;
};

export const usePremiumFeature = ({
  onLoadGetData = true,
}: UsePremiumFeatureProps) => {
  const { axiosVidhanPrivate } = useAxiosVidhanPrivate();

  const [userProfile] = useAtom(userProfileAtom);
  const [currWorkspace] = useAtom(currentWorkspaceDetailAtom);

  const role = currWorkspace?.role;

  const [, setPremiumFeatJson] = useAtom(premiumFeatureAtom);

  const email = userProfile?.email;
  const wsid =
    window.sessionStorage.getItem('workspaceUUID') ??
    window.localStorage.getItem('workspaceUUID');

  useEffect(() => {
    if (onLoadGetData && !_isNil(email) && !_isNil(wsid) && !_isNil(role)) {
      void getFeaturePlan();

      void getWorspacePauseDetails();
    }
  }, [onLoadGetData, email, wsid, role]);

  const getFeaturePlan = async () => {
    if (
      envMap.VITE_DEPLOYMENT_TYPE === 'self' &&
      !_isNil(envMap.VITE_API_PREMIUM_FEAT_PLAN)
    ) {
      try {
        const url = envMap.VITE_API_PREMIUM_FEAT_PLAN;

        const environment =
          envMap.VITE_ENVIRONMENT === 'development' ? 'staging' : 'production';

        const userId = window.localStorage.getItem('userUUID');
        const payload = {
          environment,
          isTest: false,
          params: {
            wsid,
            email,
            role,
            metaData: JSON.parse(window.localStorage.getItem('source') ?? '{}'),
            signup_date: formatNectedDate(
              userProfile?.createdAt ?? '',
              'dateTime'
            ),
            accessToken: localStorage.getItem('accessToken') ?? '',
          },
        };

        const response = await axiosVidhanPrivate.post(url, payload);

        const data = response?.data?.data?.output;

        if (!_isNil(data)) {
          setPremiumFeatJson((premFeatJson) => ({
            ...premFeatJson,
            plan: data?.userPlan ?? null,
            usage: data?.usageData ?? null,
          }));

          if (!_isNil(data?.userPlan) && !_isNil(userId)) {
            updateWidgetState(userPlanCreated, userId)
              .then((res) => {})
              .catch((err) => {
                // eslint-disable-next-line no-console
                console.log(err);
              });
          }

          window.sessionStorage.setItem(
            'userPlan',
            JSON.stringify(data.userPlan ?? {})
          );

          window.sessionStorage.setItem(
            'userUsage',
            JSON.stringify(data.usageData ?? {})
          );
        }
      } catch (err) {}
    }
  };

  const getWorspacePauseDetails = async () => {
    if (
      envMap.VITE_DEPLOYMENT_TYPE === 'self' &&
      !_isNil(envMap.VITE_API_WORKSPACE_PAUSE_GET)
    ) {
      try {
        const url = envMap.VITE_API_WORKSPACE_PAUSE_GET;

        const environment =
          envMap.VITE_ENVIRONMENT === 'development' ? 'staging' : 'production';

        const payload = {
          environment,
          isTest: false,
          params: {
            wsid,
          },
        };

        const response = await axiosVidhanPrivate.post(url, payload);

        const data = response?.data?.data?.output[0];

        window.sessionStorage.setItem(
          'userWorkspace',
          window.btoa(
            JSON.stringify({
              isPaused: data?.isPaused ?? false,
            })
          )
        );
      } catch (err) {}
    }
  };

  return {
    getFeaturePlan,
    getWorspacePauseDetails,
  };
};
