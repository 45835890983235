import { PadBox } from '@bedrock-layout/padbox';
import styled from 'styled-components';

export const FooterStyled = styled(PadBox)`
  border-block-start: 1px solid var(--color-lightGray);
  margin-top: auto;
`;

export const JsEditorContainer = styled(PadBox)<{ $readOnly?: boolean }>`
  block-size: calc(100vh - 25rem);
  border: 1px solid var(--color-lightGray);
  border-radius: 4px;

  ${({ $readOnly = false }) => $readOnly && 'pointer-events: none;'}
`;

export const ExcelEditorContainer = styled(PadBox)<{ $readOnly?: boolean }>`
  block-size: 45rem;
  border: 1px solid var(--color-lightGray);
  border-radius: 4px;

  ${({ $readOnly = false }) => $readOnly && 'pointer-events: none;'}
`;
