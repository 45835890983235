import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { toasts } from 'ui';

import { useSendEventToGTM } from '../../../hooks/useSendEventToGTM';
import {
  TESTABLE_NODES,
  sourceTypesToIgnoreOnValueReplacement,
} from '../../../utils/constant';
import {
  changedNodeIdsAtom,
  workflowEdgesAtom,
  workflowIdAtom,
  workflowNodesAtom,
  workflowStatusAtom,
} from '../atoms/atoms';
import {
  getAllParentLoopNodesForCurrentNode,
  getAllPredecessorsSkippingType,
  getExecutedValueAndStatus,
  getNodesByType,
  sanitizedStringV2,
} from '../utils/common';
import { statusListForDataUpdate } from '../utils/constant';
import { useTestWorkflow } from './restApi/useTestWorkflow';
import { useGenerateDataset } from './useGenerateDataset';
import { useGetDataset } from './useGetDataset';
import { WorkflowNodeType } from './useOpenWorkflow';

type UseTestWorkflowDbNodeProps = {
  localData: Record<string, any>;
  id: string;
};

export function useTestWorkflowDbNode({
  localData,
  id,
}: UseTestWorkflowDbNodeProps) {
  const {
    testWorkflow,
    data: connectorData,
    error: connectorError,
  } = useTestWorkflow();
  const [isTesting, setIsTesting] = useState(false);
  const [currentStatus, setCurrentStatus] = useState<string>(
    localData.status ?? ''
  );
  const [, setChangedNodeIds] = useAtom(changedNodeIdsAtom);

  const [parentNodes, setParentNodes] = useState<WorkflowNodeType[]>([]);
  const [parentLoopNodes, setParentLoopNodes] = useState<WorkflowNodeType[]>(
    []
  );

  const [currentTab, setCurrentTab] = useState(0);

  const [workflowNodes] = useAtom(workflowNodesAtom);
  const [workflowEdges] = useAtom(workflowEdgesAtom);
  const [workflowId] = useAtom(workflowIdAtom);
  const [workflowStatus] = useAtom(workflowStatusAtom);

  const workflowNode = workflowNodes.find((wn) => wn.id === id);

  const { sendEventToGTM } = useSendEventToGTM();

  const { updatedDataSet } = useGetDataset({
    parentNodes,
    parentLoopNodes,
  });

  useEffect(() => {
    const directParents = getAllPredecessorsSkippingType(
      id,
      workflowNodes,
      workflowEdges,
      'addNode'
    );

    const parentLoopNodes = getAllParentLoopNodesForCurrentNode(
      id,
      workflowNodes
    );

    setParentLoopNodes(parentLoopNodes);

    setParentNodes(directParents);
  }, [JSON.stringify(workflowNodes)]);

  useEffect(() => {
    if (!_isNil(connectorData)) {
      if (
        !_isNil(workflowNode) &&
        statusListForDataUpdate.includes(workflowStatus)
      ) {
        const newWorkflowNode = workflowNode;
        const entity = getExecutedValueAndStatus(connectorData);

        newWorkflowNode.data.status = entity.status;

        setCurrentStatus(entity.status);
        setChangedNodeIds([]);

        setTimeout(() => {
          localData.onWorkflowNodeChange(workflowNode);
        }, 100);
      }
      setCurrentTab(0);
      setCurrentTab(1);
      setIsTesting(false);
      toasts.success('Node tested successfully', 'node-test-success');
    }
  }, [connectorData]);

  useEffect(() => {
    if (!_isNil(connectorError)) {
      setCurrentStatus('error');

      if (!_isNil(workflowNode)) {
        const newWorkflowNode = workflowNode;
        newWorkflowNode.data.status = 'error';

        localData.onWorkflowNodeChange(workflowNode);
      }
      setIsTesting(false);
      // toasts.error(connectorError.message, 'node-test-fail');
    }
  }, [connectorError]);

  const { tokens } = useGenerateDataset({
    id,
    updatedDataset: updatedDataSet,
  });

  const testConnectorActionData = async (query = '', action = 0) => {
    const params: Record<string, any> = {};
    // eslint-disable-next-line
    const triggerNodes = getNodesByType(workflowNodes, 'trigger');

    if (
      parentNodes.find(
        (node) =>
          TESTABLE_NODES.includes(node.type ?? '') &&
          node.data.status !== 'success'
      ) != null
    ) {
      setIsTesting(false);

      return toasts.error(
        'Previous node(s) are  not tested. Please test the previous node(s) before proceeding',
        'parent-node'
      );
    }

    // Add updated query in params
    params.query = sanitizedStringV2(
      query,
      updatedDataSet,
      sourceTypesToIgnoreOnValueReplacement
    );
    params.action = action;

    sendEventToGTM({
      event: 'workflow',
      source: 'listing',
      element: workflowNode?.type,
      action: 'test_click',
      type: localData?.nodeType,
    });

    try {
      testWorkflow({
        id: workflowId ?? '',
        nodeId: id,
        params,
      });
    } catch (error) {
      if (error instanceof Error) {
        toasts.error(error.message, 'node-test-fail');
      }
    }

    setIsTesting(false);
  };

  return {
    testConnectorActionData,
    parentNodes,
    tokens,
    updatedDataSet,
    isTesting,
    setIsTesting,
    currentTab,
    connectorData,
    currentStatus,
    connectorError,
  };
}
