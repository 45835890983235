import { Typography } from 'ui';

import { EntityText, ReviewContainer } from './EntityUnderReview.styled';

type EntityUnderReviewProps = {
  entityType: string;
  entityStatus: string;
};

export function EntityUnderReview({
  entityType,
  entityStatus,
}: EntityUnderReviewProps) {
  if (entityStatus !== 'inreview') {
    return null;
  }

  return (
    <ReviewContainer>
      <EntityText>{`${entityType} under review`}</EntityText>

      <Typography>Review is pending so editing has been disabled</Typography>
    </ReviewContainer>
  );
}
