import { Inline } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useMemo, useState } from 'react';
import { UseControllerProps, UseFormSetValue, useWatch } from 'react-hook-form';
import { BiExpandAlt } from 'react-icons/bi';
import { MdDataArray } from 'react-icons/md';
import { VscJson } from 'react-icons/vsc';
import {
  Attributes,
  Dataset,
  Image,
  NectedSuggestionModel,
  RoundButton,
  TextButton,
  TextField,
  useLayer,
} from 'ui';

import { CalenderIconPicker } from '../../../../../components/CalenderIconPicker/CalenderIconPicker';
import { FieldByDataType } from '../../../../../components/FieldByDataType/FieldByDataType';
import { TextInputModal } from '../../../../../components/Modals/TextInputModal/TextInputModal';
import {
  createResultDataset,
  formatNectedDate,
  isArrayNotPresent,
} from '../../../../../utils/common';
import {
  TokenScores,
  envMap,
  hideOptionalCustomAttributes,
} from '../../../../../utils/constant';
import { maxFiftyCharactersRule } from '../../../../../utils/validation';
import { isRuleReadOnlyAtom } from '../../../index';
import { dataSetParamsAtom } from '../../CreateRuleSheet/CreateRuleSheet';
import { ResultHeader } from '../../DecisionTable/components/ResultHeader';
import { ResultRhs } from '../../RestltRhs/ResultRhs';
import { ResultAddDataModel } from '../models';
import { DataParamContainer } from './DataParams.styled';
import { DataParamsType } from './DataParamsTypePopover';
import type { ResultType, sendEventToGTMType } from './Results';

export const iconByDataTypeRhs: Record<string, any> = {
  list: <MdDataArray fontSize={20} color="var(--color-lightGray)" />,
  json: <VscJson fontSize={20} color="var(--color-lightGray)" />,
};

type DataParamsProps = Omit<UseControllerProps, 'name'> & {
  index: number;
  isLast: boolean;
  onAddDataClick: (dataType: string) => void;
  type: ResultType;
  dataType: string;
  handleDeleteElement: (index: number) => void;
  key: string;
  handleSendEventToGTM: (obj: sendEventToGTMType) => void;
  setValue?: UseFormSetValue<any>;
};

export function DataParams({
  control,
  index,
  isLast,
  onAddDataClick,
  type,
  dataType,
  handleDeleteElement,
  key,
  handleSendEventToGTM,
  setValue,
}: DataParamsProps) {
  const fields: any[] = useWatch({ control, name: type });
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [suggestionsObj, setSuggestionsObj] = useState<NectedSuggestionModel[]>(
    []
  );
  const [isRuleReadOnly] = useAtom(isRuleReadOnlyAtom);
  const { openWithProps } = useLayer(<TextInputModal />);

  useEffect(() => {
    const suggestionList: string[] = [];
    const suggestionObjList: NectedSuggestionModel[] = [];

    fields.forEach((field: ResultAddDataModel) => {
      if (
        field.dataType === 'string' ||
        field.dataType === 'date' ||
        field.returnType === 'string' ||
        field.returnType === 'date' ||
        field.returnType?.toLowerCase() === 'datetime'
      ) {
        suggestionList.push(`"<<outputData.${field.keyName}>>"`);
        suggestionObjList.push({
          name: `"<<outputData.${field.keyName}>>"`,
          value: `"<<outputData.${field.keyName}>>"`,
          meta:
            field.dataType === 'jsFormula'
              ? field.returnType ?? 'unknown'
              : field.dataType,
          score: TokenScores.outputData,
          executedValue:
            field.dataType === 'jsFormula'
              ? field.executedValue ?? ''
              : field.value,
        });
      } else {
        suggestionList.push(`<<outputData.${field.keyName}>>`);
        suggestionObjList.push({
          name: `<<outputData.${field.keyName}>>`,
          value: `<<outputData.${field.keyName}>>`,
          meta:
            field.dataType === 'jsFormula'
              ? field.returnType ?? 'unknown'
              : field.dataType,
          score: TokenScores.outputData,
          executedValue:
            field.dataType === 'jsFormula' || field.dataType === 'json'
              ? field.executedValue
              : field.value,
        });
      }
    });

    setSuggestionsObj(suggestionObjList);
    setSuggestions(suggestionList);
  }, [fields]);

  const keyName: string = useWatch({
    name: `${type}.${index}.keyName`,
    control,
  });

  const value: string = useWatch({
    name: `${type}.${index}.value`,
    control,
  });

  const handleDeleteClick = () => {
    handleDeleteElement(index);

    handleSendEventToGTM({
      action: 'delete',
      element: 'data',
      actionName: dataType,
    });
  };

  const [dataset] = useAtom(dataSetParamsAtom);

  const updatedDataset = useMemo(() => {
    const newDs = createResultDataset({ ...dataset });

    const suggestionName = 'resultData';

    if (
      !_isNil(newDs.dataSet) &&
      !_isNil(newDs.dataSet.attributes) &&
      _isNil(newDs.dataSet.attributes[suggestionName])
    ) {
      const suggestionPayload: Attributes = {
        name: suggestionName,
        dataType: 'list',
        executedValue: [
          Object.keys(newDs.dataSet.attributes).reduce(
            (acc: Record<string, any>, key: string) => {
              acc[key] = newDs.dataSet.attributes[key].executedValue;

              return acc;
            },
            {}
          ),
        ],
      };
      newDs.dataSet.attributes = {
        ...newDs.dataSet.attributes,
        [suggestionName]: {
          ...suggestionPayload,
        },
      };
    }

    const outputData: Dataset = {
      name: 'Output Data',
      id: 'outputData',
      attributes: fields.reduce((acc: any, curr: any, i) => {
        if (i >= index) {
          return acc;
        }

        return {
          ...acc,
          [curr.keyName]: {
            name: curr.keyName,
            // eslint-disable-next-line
            dataType: !!curr.returnType
              ? curr.returnType === 'number'
                ? 'numeric'
                : curr.returnType
              : curr.dataType,
            executedValue: curr.executedValue,
          },
        };
      }, {}),
    };

    return {
      ...newDs,
      outputData,
    };
  }, [JSON.stringify(fields), JSON.stringify(dataset)]);

  const resValue = useWatch({
    name: `${type}.${index}`,
    control,
  });

  const nodeType =
    !_isNil(resValue.source) &&
    !_isNil(resValue.attribute) &&
    !_isEmpty(resValue.source) &&
    !_isEmpty(resValue.attribute)
      ? 'token'
      : 'constant';

  return (
    <Inline align="start" key={key}>
      <Inline
        onClick={() =>
          handleSendEventToGTM({
            action: 'edit',
            element: 'data',
            actionName: dataType,
          })
        }
      >
        <TextField
          control={control}
          rules={{
            required: 'Key is required',
            maxLength: maxFiftyCharactersRule,
          }}
          name={`${type}.${index}.keyName`}
          placeholder="Key"
          size="small"
          showErrorIcon={false}
          disabled={isRuleReadOnly}
        />

        <DataParamContainer align="center" topPadding={0}>
          {['jsFormula', 'excelFormula'].includes(dataType) ? (
            <FieldByDataType
              name={`${type}.${index}.value`}
              returnTypeName={`${type}.${index}.returnType`}
              executedValueName={`${type}.${index}.executedValue`}
              dataType={dataType}
              control={control}
              showError
              index={index}
              suggestions={suggestions}
              disabled={isRuleReadOnly}
              section={type}
              hideOptionalCustomAttributes={hideOptionalCustomAttributes}
              setValue={setValue}
              suggestionObjs={suggestionsObj}
              dataSet={updatedDataset}
            />
          ) : (
            <Inline
              style={{
                position: 'relative',
              }}
            >
              <ResultRhs
                control={control}
                nodeName={`${type}.${index}`}
                dataType={dataType}
                setValue={setValue}
                keyName={keyName}
                typesToAllow={[dataType, 'generic']}
                showTooltip
                allowList
                updatedDataSet={updatedDataset}
                disabled={isRuleReadOnly}
                readOnly={['list', 'json', 'date', 'dateTime'].includes(
                  dataType
                )}
                rightIcon={
                  ['dateTime', 'date'].includes(dataType) ? (
                    <CalenderIconPicker
                      value={value}
                      dataType={dataType}
                      disabled={isRuleReadOnly}
                      onPick={(val) => {
                        if (typeof setValue === 'function') {
                          setValue(
                            `${type}.${index}.value`,
                            formatNectedDate(val, dataType)
                          );
                          setValue(`${type}.${index}.attribute`, null);
                          setValue(`${type}.${index}.source`, null);
                        }
                      }}
                    />
                  ) : undefined
                }
                options={{
                  updateDataType: ['jsFormula', 'excelFormula'].includes(
                    dataType
                  ),
                }}
                header={
                  ['list', 'json'].includes(dataType) ? (
                    <ResultHeader
                      dataSet={updatedDataset}
                      // eslint-disable-next-line
                      isAdd={isArrayNotPresent(value)}
                      nodeName={`${type}.${index}`}
                      resIndex={index}
                      suggestionsObjs={suggestionsObj}
                      control={control}
                      setOriginalValue={setValue}
                      disabled={isRuleReadOnly}
                      returnTypeName={`${type}.${index}.returnType`}
                      executedValueName={`${type}.${index}.executedValue`}
                      dataType={dataType}
                      section={type}
                      editorType={dataType as unknown as 'list' | 'json'}
                    />
                  ) : undefined
                }
                inputType={
                  [dataType, 'generic'].includes('string')
                    ? 'textarea'
                    : 'input'
                }
              />
              {['string'].includes(dataType) && (
                <RoundButton
                  type="button"
                  disabled={nodeType !== 'constant'}
                  onClick={() => {
                    openWithProps({
                      onSubmit: (val: Record<string, any>) => {
                        if (typeof setValue === 'function') {
                          setValue(`${type}.${index}.value`, val.value);
                          setValue(`${type}.${index}.source`, null);
                          setValue(`${type}.${index}.attribute`, null);
                        }
                      },
                      value: resValue.value,
                    });
                  }}
                >
                  <BiExpandAlt />
                </RoundButton>
              )}
            </Inline>
          )}
        </DataParamContainer>
      </Inline>

      <DataParamContainer align="center">
        {!isRuleReadOnly && (
          <TextButton onClick={handleDeleteClick}>
            <Image
              src={`${envMap.VITE_ASSETS_URL}website/icons/trash.svg`}
              alt="minus"
              size="small"
            />
          </TextButton>
        )}

        {isLast && !isRuleReadOnly && (
          <Inline>
            <DataParamsType
              control={control}
              handleAddDataParam={onAddDataClick}
            />
          </Inline>
        )}
      </DataParamContainer>
    </Inline>
  );
}
