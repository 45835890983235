import { Stack } from '@bedrock-layout/stack';
import { atom, useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useForm } from 'react-hook-form';
import { Typography, toasts } from 'ui';

import { Header } from '../../../components/Header';
import { PermissionType } from '../../../components/PermissionComponent/constant';
import { PagesBodyContainer } from '../../../components/layouts/PagesBodyContainer';
import { getUserState } from '../../../hooks/getUserState';
import { useGetSiteMeta } from '../../../hooks/useGetSiteMeta';
import {
  isOnboardingCompleted,
  showGraphQlErrorToast,
} from '../../../utils/common';
import { exploreGlobalAttributes } from '../../../utils/constant';
import { updateWidgetState } from '../../Home/components/sub-components/UpdateWidgetState';
import { useDeleteConfig } from '../hooks/useDeleteRemoteConfig';
import { useGetRemoteConfig } from '../hooks/useGetRemoteConfig';
import { useUpdateConfig } from '../hooks/useUpdateRemoteConfig';
import type {
  AddConfigValueType,
  GetRemoteConfigResult,
  SavedConfigModel,
} from '../models';
import { AddConfigFieldArray } from './AddConfigFieldArray';
import { RemoteConfigContainer } from './RemoteConfig.styled';
import { TableData } from './TableData';
import { TableHeader } from './TableHeader';

export const remoteLimitsConfigAtom = atom<Record<string, any>>({});

export type RemoteConfigFormValues = Record<
  string,
  AddConfigValueType | Date | SavedConfigModel[]
>;

type RemoteConfigProps = {
  limits: Record<string, any>;
  permissions: Record<PermissionType, boolean>;
};

const getRemoteConfigData = (val: any) => {
  if (typeof val === 'object') {
    return JSON.stringify(val, null, 2);
  }

  return val;
};

export function RemoteConfig({ limits, permissions }: RemoteConfigProps) {
  const [, setLimitConfig] = useAtom(remoteLimitsConfigAtom);
  const [remoteData, setRemoteData] = useState<
    GetRemoteConfigResult | undefined
  >();
  const { siteMeta } = useGetSiteMeta();

  const { data: savedConfigList, loading, refetch } = useGetRemoteConfig();

  const [updateConfig] = useUpdateConfig();
  const [deleteConfig] = useDeleteConfig();

  const { control, getValues, watch, trigger, setValue } = useForm<any>({
    mode: 'onChange',
  });

  useEffect(() => {
    setLimitConfig(limits);
  }, [limits]);

  const handleUpdateConfig = async (
    config: Pick<SavedConfigModel, 'id' | 'value' | 'checksum'>
  ) => {
    if (!_isNil(config.value)) {
      try {
        await updateConfig({
          variables: {
            configId: config.id,
            value: config.value,
            checksum: config.checksum,
          },
        });

        setRemoteData(undefined);
        void resetData();

        toasts.success('Updated successfully', 'updated-success');
      } catch (error) {
        showGraphQlErrorToast(error);
      }
    }
  };

  const resetData = async () => {
    try {
      const data = await refetch();
      setRemoteData(data.data);

      setValue(
        'savedConfig.data',
        formatValuesForForm(data.data.savedConfig.data)
      );
    } catch (error) {}
  };

  const formatValuesForForm = (savedData: SavedConfigModel[]) => {
    return savedData.map((savedData) => {
      if (savedData.dataType === 'dateTime' || savedData.dataType === 'date') {
        return {
          ...savedData,
          value: new Date(savedData.value as string),
        };
      }

      if (
        savedData.dataType === 'json' &&
        typeof savedData.value === 'object'
      ) {
        return {
          ...savedData,
          value: getRemoteConfigData(savedData.value),
        };
      }

      // if (
      //   savedData.dataType === 'list' &&
      //   typeof savedData.value === 'object'
      // ) {
      //   return {
      //     ...savedData,
      //     value: convertArrayToString(savedData.value as any[]),
      //   };
      // }

      return savedData;
    });
  };

  useEffect(() => {
    if (!_isNil(savedConfigList)) {
      setRemoteData(savedConfigList);

      if (savedConfigList.savedConfig.data.length > 0) {
        if (!isOnboardingCompleted(exploreGlobalAttributes)) {
          updateWidgetState(exploreGlobalAttributes)
            .then(() => {
              void getUserState();
            })
            .catch((err) => {
              // eslint-disable-next-line no-console
              console.log(err);
            });
        }
      }

      setValue(
        'savedConfig.data',
        formatValuesForForm(savedConfigList.savedConfig.data)
      );
    }
  }, [savedConfigList]);

  const handleDeleteConfig = async (id: string, checksum: string) => {
    try {
      await deleteConfig({
        variables: {
          id,
          checksum,
        },
      });
      toasts.success('Deleted successfully', 'deleted-success');

      setRemoteData(undefined);

      void resetData();
    } catch (error) {
      showGraphQlErrorToast(error);
    }
  };

  const isLoading = loading || _isNil(savedConfigList);

  if (isLoading) {
    return <div>loading</div>;
  }

  if (_isNil(savedConfigList)) {
    return <div>error</div>;
  }

  return (
    <PagesBodyContainer>
      <Helmet>
        <title>
          {siteMeta[window.location.pathname.substring(1)]?.title ?? ''}
        </title>
        <meta
          name="description"
          content={
            siteMeta[window.location.pathname.substring(1)]?.description ?? ''
          }
        />
      </Helmet>
      <Header
        content={
          <Typography name="heading1" fontWeight={700}>
            Global Attributes
          </Typography>
        }
      />

      <RemoteConfigContainer padding="2rem">
        <Stack gutter="2.4rem">
          <Typography>
            The attributes can be configured here with any datatype and values
            and can be used in any rule
          </Typography>

          <Stack gutter="1.6rem">
            <Stack gutter=".8rem">
              <TableHeader />
              {!_isNil(remoteData) &&
                !_isNil(remoteData.savedConfig) &&
                remoteData?.savedConfig.data.length > 0 && (
                  <form>
                    <TableData
                      isLoading={isLoading}
                      savedConfigList={savedConfigList}
                      control={control}
                      handleDeleteConfig={handleDeleteConfig}
                      handleUpdateConfig={handleUpdateConfig}
                      getValues={getValues}
                      watch={watch}
                      trigger={trigger}
                    />
                  </form>
                )}
              <AddConfigFieldArray
                refetch={refetch}
                savedConfigList={savedConfigList}
                setRemoteData={setRemoteData}
              />
            </Stack>
          </Stack>
        </Stack>
      </RemoteConfigContainer>
    </PagesBodyContainer>
  );
}
