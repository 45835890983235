import _isNil from 'lodash/isNil';

import type { RoleJsonType } from '../../types';
import { getRoleJson, updateRoleJson } from '../../utils/common';
import { envMap } from '../../utils/constant';
import { useAxiosVidhanPrivate } from '../useAxiosVidhanPrivate';

export function useGetPermissionsByEntity() {
  const { axiosVidhanPrivate } = useAxiosVidhanPrivate();

  const getUserPermissionsByEntity = async (
    user: string,
    role: string,
    wid: string,
    entity: string,
    setRoleJson: (roleJson: RoleJsonType) => void
  ) => {
    const environment =
      envMap.VITE_ENVIRONMENT === 'development' ? 'staging' : 'production';

    const payload = {
      environment,
      isTest: false,
      params: {
        role,
        entity,
        user,
        version: '0.1',
        wid,
      },
    };

    const rJson = getRoleJson();

    if (!_isNil(rJson.internals) && _isNil(rJson.internals[entity])) {
      try {
        const response = await axiosVidhanPrivate.post(
          envMap.VITE_API_FEATURE_FLAG,
          payload
        );

        const data = response?.data?.data?.output ?? [];

        if (data.length > 0) {
          let updatedRoleJson = {};

          updatedRoleJson = {
            flags: rJson?.flags ?? {},
            internals: {
              ...rJson.internals,
              [entity]: data[0]?.data?.internals ?? {},
            },
          };

          setRoleJson(updatedRoleJson);
          updateRoleJson(updatedRoleJson);
        }
      } catch (error) {}
    }
  };

  return {
    getUserPermissionsByEntity,
  };
}
