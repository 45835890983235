import _isNil from 'lodash/isNil';
import { memo } from 'react';
import type { NodeProps } from 'reactflow';

import { Components } from './loopNode';

type LoopNodeTypes = 'loopNode';

function isOfNodeType(nodeType: string): nodeType is LoopNodeTypes {
  return ['loopNode'].includes(nodeType);
}

export const LoopNode = memo(
  ({ data, isConnectable, id, ...rest }: NodeProps) => {
    const { nodeType = '' } = data;
    const Component = isOfNodeType(nodeType) ? Components[nodeType] : null;

    if (_isNil(Component)) {
      return null;
    }

    return (
      <Component data={data} id={id} isConnectable={isConnectable} {...rest} />
    );
  }
);

LoopNode.displayName = 'LoopNode';
