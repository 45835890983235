import type { RuleEnvironment } from '../../../../utils/constant';
import type {
  SortedPluginParamsFieldKeys,
  UpdateConnectorStatusFunction,
} from '../../types';
import { usePublishConnector } from './usePublishConnector';
import { useUpdateConnector } from './useUpdateConnector';

export function useUpdateConnectorPublishStatus(
  connectorId: string,
  currentEnvironment: RuleEnvironment,
  sortedFields: SortedPluginParamsFieldKeys[],
  updateConnectorStatus: UpdateConnectorStatusFunction,
  connectorName: string,
  pluginType: string
) {
  const { updateConnector, loading: updateInProgress } = useUpdateConnector(
    currentEnvironment,
    connectorId,
    sortedFields
  );

  const { publishConnector, loading: publishInProgress } = usePublishConnector(
    connectorId,
    updateConnectorStatus,
    connectorName,
    pluginType
  );

  return {
    publishConnector,
    publishInProgress,
    updateConnector,
    updateInProgress,
  };
}
