import { PadBox } from '@bedrock-layout/padbox';
import styled from 'styled-components';

export const RefreshStyled = styled(PadBox)<{
  $isDisabled?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 0.5rem;
  block-size: 3rem;
  inline-size: 3rem;
  border-radius: 0.5rem;
  border: 1px solid var(--color-solitude);
  background: var(--color-white);
  cursor: pointer;
  position: relative;

  ${({ $isDisabled = false }) => $isDisabled && 'pointer-events: none'}
`;
