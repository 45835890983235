import { Inline } from '@bedrock-layout/primitives';
import { CellContext } from '@tanstack/react-table';
import dateFormat from 'dateformat';
import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en.json';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _isUndefined from 'lodash/isUndefined';
import { type ReactElement, useRef } from 'react';
import ReactTimeAgo from 'react-time-ago';
import {
  IconButton,
  Image,
  Menu,
  MenuItem,
  PopoverMethods,
  ToolTip,
  Typography,
  toasts,
} from 'ui';

import { siteConstantsAtom, subscriptionPlanAtom } from '../../../atom';
import {
  checkLimitExceeded,
  convertObjecttoStringCSS,
  getLocale,
  getTooltipText,
  getTrimmedText,
  isValidImageURL,
  shortenName,
} from '../../../utils/common';
import { editEntityAccessDisableRoles, envMap } from '../../../utils/constant';
import {
  type PermissionType,
  permissionObj,
} from '../../PermissionComponent/constant';
import { useCheckPermissions } from '../../PermissionComponent/hooks/useCheckPermissions';
import { ruleNameMap } from '../gettingStarted/components/rulesTemplate';
import {
  ActionMenuContainer,
  ActionMenuItem,
  CustomTimeAgoTitle,
  DateContainer,
  EntityName,
  LinkText,
  ListingStatusPill,
  NameContainer,
  StringContainer,
  TimeAgoContainer,
} from '../listing.styled';
import type { ListingDetailsProps } from '../types';
import {
  formatDateTime,
  getCustomValueFromExpression,
  getValueFromObject,
} from '../utils';

TimeAgo.addDefaultLocale(en);

export const renderTableCell = (
  entity: string,
  listingDetails: ListingDetailsProps,
  props: CellContext<any, any>,
  headerType: string,
  permissions?: Record<PermissionType, boolean>,
  handleActionItemClick?: (obj?: any) => void,
  metaData?: Record<string, any>,
  styles: Record<string, string> = {},
  checkPermissionsToEnable?: (
    allowedPermission: string[],
    entityStatus: string,
    statusShouldBe?: string[]
  ) => boolean
) => {
  const value = props.getValue();
  const { original } = props.row;
  const [subscriptionPlan] = useAtom(subscriptionPlanAtom);

  const [siteConstants] = useAtom(siteConstantsAtom);

  const isEditAccessDisable = editEntityAccessDisableRoles.includes(
    original?.accessRole
  );

  const { isHide: isDeleteDisable } = useCheckPermissions({
    entityList: [entity],
    allowedPermission: [permissionObj.delete],
    entityAccessRole: original?.accessRole,
  });

  const { isHide: isCloneDisable } = useCheckPermissions({
    entityList: [entity],
    allowedPermission: [permissionObj.create],
  });

  const plan = JSON.parse(window.sessionStorage.getItem('userPlan') ?? '{}');

  const ref = useRef<PopoverMethods>(null);

  const handleMenuItemClick = (value: any) => {
    const isLimitExceeded = checkLimitExceeded(entity, subscriptionPlan);

    if (value === 'clone' && isLimitExceeded) {
      toasts.info('Please upgrade your plan', 'plan-upgrade');

      return;
    }

    if (typeof handleActionItemClick === 'function') {
      handleActionItemClick({ type: value, data: original });
    }

    ref.current?.hide();
  };

  switch (headerType) {
    case 'datetime':
      return (
        <DateContainer>
          {dateFormat(new Date(value ?? null), "h:MM TT	 'on' dd/mm/yyyy")}
        </DateContainer>
      );
    case 'customName': {
      let status = '';

      if (original.status === 'draft') {
        status = 'Has Draft';
      } else if (original.status === 'tested') {
        status = 'Has Tested';
      } else if (original.status === 'inreview') {
        status = 'In Review';
      }

      const isAlreadyPublished =
        original.versionInfo.currentLiveVersion !== 'draft' &&
        original.versionInfo.currentLiveVersion !== '';

      return (
        <NameContainer>
          <EntityName>{shortenName(original.name, 25)}</EntityName>
          {isAlreadyPublished && (
            <div>
              <ListingStatusPill status={status}>{status}</ListingStatusPill>
            </div>
          )}
        </NameContainer>
      );
    }

    case 'customStatus': {
      let status = '';

      const isAlreadyPublished =
        original.versionInfo.currentLiveVersion !== 'draft' &&
        original.versionInfo.currentLiveVersion !== '';

      if (isAlreadyPublished) {
        status = 'Published';
      } else if (original.status === 'draft') {
        status = 'Has Draft';
      } else if (original.status === 'tested') {
        status = 'Has Tested';
      } else if (original.status === 'inreview') {
        status = 'In Review';
      }

      return (
        <>
          <ListingStatusPill status={status}>{status}</ListingStatusPill>
          {props.row.original.statusTimeMs > 0 && (
            <ListingStatusPill status={status}>
              {`${props.row.original.statusTimeMs as number} ms`}
            </ListingStatusPill>
          )}
        </>
      );
    }

    case 'status': {
      const status = props.row.original.status;

      const currentTime = new Date();
      const message = original?.message ?? '';

      const lastMessage = message.split(' ')[message.split(' ').length - 1];
      let delayTime: Date | null = null;

      try {
        delayTime = new Date(lastMessage);
      } catch (error) {
        delayTime = currentTime;
      }

      if (
        original?.type?.toLowerCase() === 'delay' &&
        original?.status?.toLowerCase() === 'success' &&
        !_isNil(delayTime) &&
        currentTime < delayTime
      ) {
        return (
          <Typography>
            <ListingStatusPill status="scheduled">Scheduled</ListingStatusPill>
          </Typography>
        );
      }

      return (
        <Typography>
          <ListingStatusPill status={status}>{status}</ListingStatusPill>
          {props.row.original.statusTimeMs > 0 && (
            <ListingStatusPill status={status}>
              {`${props.row.original.statusTimeMs as number} ms`}
            </ListingStatusPill>
          )}
        </Typography>
      );
    }

    case 'customType': {
      return <Typography>{ruleNameMap[value]}</Typography>;
    }
    case 'node':
      return (
        <>
          {!_isEmpty(original.rootNode.name) ? (
            <ToolTip
              message={original.rootNode.name}
              size="large"
              placement="right-end"
              visible={original.rootNode.name.length > 25}
            >
              <LinkText fontWeight={700}>
                {getTrimmedText(original.rootNode.name, 25)}
              </LinkText>
            </ToolTip>
          ) : (
            '-'
          )}
        </>
      );
    case 'timeAgo': {
      const userName = !_isUndefined(metaData)
        ? (original[metaData.userName] as string)
        : '';

      const time = formatDateTime(value, 'time');
      const date = formatDateTime(value, 'date');

      return (
        <TimeAgoContainer gutter={0}>
          {!_isNil(value) ? (
            <CustomTimeAgoTitle>
              <ToolTip
                message={`${date}, ${time} ${
                  !_isEmpty(userName) ? 'by ' + userName : '-'
                }`}
              >
                <ReactTimeAgo
                  date={new Date(value)}
                  locale={getLocale()}
                  timeStyle="round"
                  tooltip={false}
                />
              </ToolTip>
            </CustomTimeAgoTitle>
          ) : (
            '-'
          )}
        </TimeAgoContainer>
      );
    }
    case 'iconWithLabel': {
      const { label: labelKey, icon: iconKey } = !_isUndefined(metaData)
        ? metaData
        : { label: '', icon: '' };

      const label = getValueFromObject(original, labelKey);
      const imageUrl = getValueFromObject(original, iconKey);

      return (
        <Inline gutter=".8rem">
          <Image src={isValidImageURL(imageUrl)} alt="connector" round />
          <Typography>{label}</Typography>
        </Inline>
      );
    }
    case 'actionColumn': {
      const actionList = [
        {
          label: 'Edit Access',
          value: 'edit_access',
          statusShouldBe: [],
          tooltipText: getTooltipText(
            siteConstants,
            'rules',
            'editAccessFileText'
          ),
        },
        {
          label: 'Clone',
          value: 'clone',
          statusShouldBe: [],
          tooltipText: getTooltipText(siteConstants, 'rules', 'cloneFileText'),
        },
        {
          label: 'Delete',
          value: 'delete',
          tooltipText: getTooltipText(siteConstants, 'rules', 'deleteFileText'),
        },
        {
          label: 'Export',
          value: 'export',
        },
      ];

      const menuItems: ReactElement[] = [];
      const filteredActionList = actionList;

      filteredActionList
        .filter((action: any) => {
          if (
            ['clone', 'export', 'edit_access'].includes(action.value) &&
            entity === 'datasets'
          ) {
            return false;
          }

          return true;
        })
        .forEach((action: any) => {
          let enabled = true;

          switch (action.value) {
            case 'edit_access':
              enabled = !isEditAccessDisable;
              break;
            case 'clone':
              enabled = !isCloneDisable;
              break;
            case 'delete':
              enabled = !isDeleteDisable;
              break;
            default:
              break;
          }

          if (action.value === 'export') {
            const exportComponentId = getValueFromObject(
              plan,
              `plan.export.componentId`
            );

            menuItems.push(
              <MenuItem
                disabled={!enabled}
                value={action.value}
                key={action.value}
                tooltipText={action?.tooltipText}
                style={{ flex: 1 }}
              >
                <ActionMenuItem>
                  <div
                    id={exportComponentId}
                    data-premium-component-id={exportComponentId}
                    data-premium-component-trigger={getValueFromObject(
                      plan,
                      `plan.export.trigger`
                    )}
                  >
                    {action.label}
                  </div>
                </ActionMenuItem>
              </MenuItem>
            );
          } else {
            menuItems.push(
              <MenuItem
                disabled={!enabled}
                value={action.value}
                key={action.value}
                tooltipText={action?.tooltipText}
                style={{ flex: 1 }}
              >
                <ActionMenuItem>{action.label}</ActionMenuItem>
              </MenuItem>
            );
          }
        });

      return (
        <ActionMenuContainer>
          <Menu
            launcher={
              <IconButton>
                <img
                  src={`${envMap.VITE_ASSETS_URL}website/icons/three_dot_horz.svg`}
                  alt="Dot Icon"
                />
              </IconButton>
            }
            ref={ref}
            onMenuItemClick={handleMenuItemClick}
          >
            {menuItems}
          </Menu>
        </ActionMenuContainer>
      );
    }
    default: {
      const expression =
        !_isNil(metaData) && !_isNil(metaData.expression)
          ? metaData.expression
          : '';

      const updatedValue =
        !_isNil(expression) && !_isEmpty(expression)
          ? getCustomValueFromExpression(expression, original)
          : value;

      return (
        <StringContainer styles={convertObjecttoStringCSS(styles)}>
          {_isEmpty(updatedValue) ? '-' : updatedValue}
        </StringContainer>
      );
    }
  }
};
