import { Inline } from '@bedrock-layout/inline';
import { Stack } from '@bedrock-layout/stack';
import type { UseControllerProps, UseFormSetValue } from 'react-hook-form';
import { useFieldArray } from 'react-hook-form';
import {
  Image,
  NectedSuggestionModel,
  TextButton,
  TextField,
  TextInput,
  Typography,
} from 'ui';

import { AddButton } from '../../../../../../components/AddButton';
import type {
  KeyValuePairList,
  PluginFieldCommonProps,
} from './../../../../types';
import { PluginFieldContainer } from './../../../common/PluginFieldContainer';
import {
  DeleteButtonContainer,
  TextFieldStyled,
} from './../fields/HeaderFieldsArray.styled';
import ActionTextValue from './ActionTextValue';

export const KEY = `key`;
export const VALUE = `value`;

type HeaderFieldsArrayProps = UseControllerProps<any> &
  PluginFieldCommonProps & {
    label?: string;
    disabledKeyValuePairs?: KeyValuePairList[];
    variant?: 'ace' | 'text';
    customSuggestions?: NectedSuggestionModel[];
    setValue?: UseFormSetValue<any>;
    autoChange?: boolean;
    tooltipText?: string;
    handleGetExecData?: (data: Record<string, any>) => void;
    execValues?: Record<string, any>;
  };

export function HeaderFieldsArray({
  control,
  label,
  name,
  required = false,
  regex,
  errorMessage = 'Not a valid key',
  disabledKeyValuePairs = [],
  variant = 'text',
  customSuggestions = [],
  disabled = false,
  setValue,
  autoChange = false,
  tooltipText,
  handleGetExecData,
  execValues,
}: HeaderFieldsArrayProps) {
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });

  return (
    <PluginFieldContainer
      label={label}
      required={required}
      tooltipText={tooltipText}
    >
      <Stack gutter=".8rem">
        {disabledKeyValuePairs.map((pair, index) => {
          return (
            <Inline key={`disabled_${index}`} align="start" minItemWidth={24}>
              <TextFieldStyled>
                <TextInput
                  disabled
                  placeholder="key"
                  type="text"
                  value={pair.key}
                />
              </TextFieldStyled>
              <TextFieldStyled>
                <TextInput
                  disabled
                  placeholder="value"
                  type="text"
                  value={pair.value}
                />
              </TextFieldStyled>
              <Inline as={DeleteButtonContainer} align="center" />
            </Inline>
          );
        })}

        {fields.map(({ id }, index) => {
          const keyName = `${name}.${index}.${KEY}`;
          const valueName = `${name}.${index}.${VALUE}`;

          return (
            <Inline key={id} align="start" minItemWidth={24}>
              <TextFieldStyled>
                <TextField
                  control={control}
                  name={keyName}
                  placeholder="key"
                  type="text"
                  rules={{
                    required: required ? 'This field is required' : false,
                    pattern: {
                      value: regex,
                      message: errorMessage,
                    },
                  }}
                  disabled={disabled}
                />
              </TextFieldStyled>
              <TextFieldStyled>
                {variant === 'text' && (
                  <TextField
                    control={control}
                    name={valueName}
                    placeholder="value"
                    type="text"
                    disabled={disabled}
                  />
                )}

                {variant === 'ace' && (
                  <ActionTextValue
                    control={control}
                    name={valueName}
                    customSuggestions={customSuggestions}
                    readOnly={disabled}
                    setValue={setValue}
                    index={index}
                    keyName={keyName}
                    autoChange={autoChange}
                    execValues={execValues}
                    handleGetExecData={handleGetExecData}
                  />
                )}
              </TextFieldStyled>

              {!disabled && (
                <Inline as={DeleteButtonContainer} align="center">
                  <TextButton onClick={() => remove(index)}>
                    <Image
                      src="/assets/konark/icons/trash.svg"
                      alt="trash"
                      size="small"
                    />
                  </TextButton>
                </Inline>
              )}
            </Inline>
          );
        })}

        {!disabled && (
          <Inline>
            <TextButton
              onClick={() => {
                if (disabled) {
                  return;
                }

                append({
                  [`${KEY}`]: '',
                  [`${VALUE}`]: '',
                });
              }}
            >
              <Inline as="span" align="center" gutter={5}>
                <AddButton />
                <Typography element="span">Add {label}</Typography>
              </Inline>
            </TextButton>
          </Inline>
        )}
      </Stack>
    </PluginFieldContainer>
  );
}
