import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import styled, { css } from 'styled-components';
import { Typography } from 'ui';

export const CardStyled = styled(Stack)`
  border-radius: 0.5rem;
  border: 1px solid var(--color-lightGray5);
`;

export const InlineStyled = styled(Inline)<{
  bgColor?: string;
  $hasTopBorder?: boolean;
  $hasBottomBorder?: boolean;
}>`
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  padding: 0.5rem 1rem;

  ${({ $hasTopBorder = true }) =>
    $hasTopBorder &&
    css`
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
    `}

  ${({ $hasBottomBorder = true }) =>
    $hasBottomBorder &&
    css`
      border-bottom-left-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
    `}

  background-color: ${({ bgColor = 'var(--color-white)' }) => bgColor};
`;

export const BlueTextStyled = styled(Typography)`
  font-size: 1rem;
  color: var(--color-primary1);
  text-transform: capitalize;
`;

export const EnvironmentText = styled(Typography)<{ env?: string }>`
  text-transform: capitalize;
  font-size: 1rem;

  color: ${({ env }) =>
    env === 'staging' ? 'var(--color-tangerine)' : 'var(--color-waterCourse)'};
`;

export const StyledName = styled(Typography)`
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;
