import { gql, useLazyQuery } from '@apollo/client';

import type { GetConnectorsQueryResult } from '../../pages/DataSets/hooks/useGetPublishedConnectors';

const getConnectorsQuery = gql`
  query getConnector($connectorId: String!) {
    getConnector(id: $connectorId, live: false) {
      data {
        id
        name
        staging {
          conf
          isTested
          isPublish
        }
        production {
          conf
          isTested
          isPublish
        }
        plugin {
          name
          id
          imageUrl
        }
      }
    }
  }
`;

export function useGetConnectorById() {
  return useLazyQuery<
    GetConnectorsQueryResult,
    {
      connectorId: string;
    }
  >(getConnectorsQuery);
}
