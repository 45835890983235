import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';

import { userProfileAtom } from '../atom';
import { currentWorkspaceDetailAtom } from '../pages/Workspace/atom';
import { sendToGA } from '../utils/common';
import { envMap } from '../utils/constant';

export const useSendEventToGTM = () => {
  const [userProfile] = useAtom(userProfileAtom);
  const [currentWorkspace] = useAtom(currentWorkspaceDetailAtom);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const sendEventToGA = (payload: Record<string, any>) => {
    const eventName = payload.event;
    // loop trough all the keys and add "nec_" as prefix
    const updatedPayload: Record<string, any> = {};
    Object.keys(payload).forEach((key) => {
      if (eventName !== 'page_view') {
        updatedPayload[`nec_${key}`] = payload[key];
      } else {
        updatedPayload[key] = payload[key];
      }
    });

    if (eventName === 'page_view') {
      sendToGA('set', 'page_path', window.location.href);
    } else if (eventName === 'userdata') {
      sendToGA('set', 'user_properties', {
        ...updatedPayload,
        user_id: payload.user_id,
      });
      sendToGA('event', eventName, {
        ...updatedPayload,
        user_id: payload.user_id,
      });
    } else if (eventName === 'config') {
      sendToGA('config', payload.trackingId, {
        user_id: payload.user_id,
        email: payload.email,
      });
    } else {
      sendToGA('event', eventName, {
        ...updatedPayload,
      });
    }
  };

  const sendEventToGTM = (event: Record<string, any>) => {
    const eventsList = ['signin', 'signup', 'onboarding_first_completed'];

    if (envMap.VITE_ENVIRONMENT !== 'development') {
      let updatedEvent = event;

      if (!_isNil(userProfile)) {
        updatedEvent = {
          email: userProfile.email,
          username: `${userProfile.firstName} ${userProfile.lastName}`,
          userid: window.localStorage.getItem('userUUID') ?? 'not_logged_in',
          ...updatedEvent,
        };
      }

      if (!_isNil(currentWorkspace)) {
        updatedEvent = {
          wsid: currentWorkspace.uuid,
          ...updatedEvent,
        };
      }

      if (!_isNil((window as any).nectedLayer)) {
        (window as any).nectedLayer.push(updatedEvent);
      }

      if (
        !_isNil(updatedEvent.email) &&
        !_isEmpty(updatedEvent.email) &&
        eventsList.includes(updatedEvent.event)
      ) {
        if (
          updatedEvent.event === 'signup' ||
          updatedEvent.event === 'signin'
        ) {
          updatedEvent.source = JSON.parse(
            window.localStorage.getItem('source') ?? '{}'
          );
        }

        // void callAnalyticsDBAPI(updatedEvent);
      } else if (
        _isNil(updatedEvent.email) &&
        (updatedEvent.event === 'signup' || updatedEvent.event === 'signin')
      ) {
        updatedEvent.source = JSON.parse(
          window.localStorage.getItem('source') ?? '{}'
        );
        updatedEvent.email = 'google_login';
        // void callAnalyticsDBAPI(updatedEvent);
      }
      sendEventToGA(updatedEvent);
    }
  };

  return {
    sendEventToGTM,
  };
};
