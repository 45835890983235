import { ObservableQuery } from '@apollo/client';
import { Inline, PadBox, Stack } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _reduce from 'lodash/reduce';
import { useEffect, useRef, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import {
  Button,
  HorizontalLine,
  TextField,
  Toast,
  Typography,
  toasts,
  useCurrentLayer,
  useLayer,
} from 'ui';

import { siteConstantsAtom } from '../../../../atom';
import { AddButton } from '../../../../components/AddButton';
import { AuthKeyModal } from '../../../../components/AuthKeyModal/AuthKeyModal';
import { WhitelistIps } from '../../../../components/ConnectorComponents/WhitelistIps/WhitelistIps';
import { Form as DynamicForm } from '../../../../components/Form';
import { WebButtonField } from '../../../../components/Form/components/WebButtonField';
import { WebGSheetColumnField } from '../../../../components/Form/components/WebGSheetColumnField';
import { WebGSheetPickerField } from '../../../../components/Form/components/WebGSheetPickerField';
import { FormFields } from '../../../../components/Form/types';
import {
  getInitialFormValuesBasedOnFields,
  parseExpression,
} from '../../../../components/Form/utlis';
import { permissionObj } from '../../../../components/PermissionComponent/constant';
import { useCheckPermissions } from '../../../../components/PermissionComponent/hooks/useCheckPermissions';
import { AutoSaveLoader } from '../../../../components/autoSaveLoader/AutoSaveLoader';
import { getUserState } from '../../../../hooks/getUserState';
import type { TimerId } from '../../../../types';
import {
  getTooltipText,
  isOnboardingCompleted,
} from '../../../../utils/common';
import type { RuleEnvironment } from '../../../../utils/constant';
import {
  ENTITY_ID,
  Environment,
  integrationsAdded,
} from '../../../../utils/constant';
import { NOT_EMPTY_STRING_REGEX } from '../../../../utils/regex';
import { updateWidgetState } from '../../../Home/components/sub-components/UpdateWidgetState';
import type {
  IntegrationCommonProps,
  PluginParams,
  SortedPluginParamsFieldKeys,
} from '../../types';
import {
  FormFieldsContainer,
  FormFieldsStack,
  SaveButtonContainer,
} from '../connector/form/fields/ConnectorForm.styled';
import { HeaderFieldsArray } from '../connector/form/fields/HeaderFieldsArray';
import { PluginBooleanField } from '../connector/form/fields/PluginBooleanField';
import { CreateConnector } from '../connector/form/fields/PluginDropdown.styled';
import { PluginDropDownField } from '../connector/form/fields/PluginDropdownField';
import { PluginTextField } from '../connector/form/fields/PluginTextField';
import { validateConnectorForm } from '../connector/utils';
// import { useGetTestMethods } from '../hooks/useGetTestMethods';
import type { PublishConnectorFunction } from '../hooks/usePublishConnector';
import { useStoreFormValues } from '../hooks/useStoreFormValues';
import { useTestConnector } from '../hooks/useTestConnector';
import { Form } from './IntegrationSheet.styled';
import { PluginFieldContainer } from './PluginFieldContainer';

export type IntegrationFormProps = IntegrationCommonProps & {
  currentEnvironment: RuleEnvironment;
  isCreatingOrUpdating: boolean;
  publishConnector: PublishConnectorFunction;
  shouldFormRender: boolean;
  sortedFields: SortedPluginParamsFieldKeys[];
  formJson: PluginParams;
  formType: string;
  pluginId: string;
  pluginName: string;
  resetInitialData: number;
  setResetInitialData: (count: number) => void;
  whitelistIps: string[];
  pluginType: string;
  from?: string;
  onPublish?: () => void;
  refetchAuthKeys?: ObservableQuery<any>['refetch'];
  setLocalConnName?: (name: string) => void;
};

export const integrationComponents = {
  dropdown: PluginDropDownField,
  map: HeaderFieldsArray,
  number: PluginTextField,
  password: PluginTextField,
  partialPassword: PluginTextField,
  text: PluginTextField,
  url: PluginTextField,
  boolean: PluginBooleanField,
  ssl: PluginBooleanField,
  'multi-select': PluginDropDownField,
  button: WebButtonField,
  googlePicker: WebGSheetPickerField,
  gSheetColumns: WebGSheetColumnField,
};

export function IntegrationForm({
  connectorId,
  connectorName,
  connectorStatus,
  currentEnvironment,
  isCreatingOrUpdating,
  onSubmit,
  publishConnector,
  shouldFormRender = true,
  sortedFields,
  formJson,
  formType,
  pluginId,
  pluginName,
  updateConnectorStatus,
  whitelistIps,
  from = '',
  onPublish,
  refetchAuthKeys,
  pluginType = '',
  resetInitialData = 0,
  setResetInitialData,
  setLocalConnName,
}: IntegrationFormProps) {
  const [counter, setCounter] = useState(0);
  const [siteConstants] = useAtom(siteConstantsAtom);
  const [resetFieldsMap, setResetFieldsMap] = useState<Record<string, any>>({});

  const authorizedPluginList = ['slack', 'gsheet'];
  const isPluginAuthorizeRequired = authorizedPluginList.includes(pluginName);

  const [parentFormData, setParentFormData] = useState<Record<string, any>>({});
  // const [, setTestButtonJson] = useState<Record<string, any>>({});

  // const [getTestMethods] = useGetTestMethods();

  useEffect(() => {
    if (!_isEmpty(pluginId)) {
      void fetchTestMethds();
    }
  }, [pluginId]);

  const { open } = useLayer(<AuthKeyModal refetch={refetchAuthKeys} />);

  const MenuListFooter = () => (
    <PadBox padding={['0.8rem', '1rem']}>
      <CreateConnector onClick={open} gutter={8}>
        <AddButton />

        <Typography>Create Authentication</Typography>
      </CreateConnector>
    </PadBox>
  );

  const { close } = useCurrentLayer();

  const fetchTestMethds = async () => {
    // const response = await getTestMethods({
    //   variables: {
    //     pluginId,
    //   },
    //   fetchPolicy: 'no-cache',
    // });
    // if (!_isNil(response.data)) {
    //   const data = response.data.databasePlugin.data;
    //   if (!_isNil(data) && data.length > 0) {
    //     const testMethodSchema = data[0].schema.test.params;
    //     setTestButtonJson(testMethodSchema);
    //   }
    // }
    // console.log('response:', response);
  };

  const { testConnector, loading: testInProgress } = useTestConnector(
    updateConnectorStatus,
    currentEnvironment,
    connectorId,
    connectorName,
    pluginType
  );

  const { integrationFormValues, storeFormValues } = useStoreFormValues();

  const initialFormValuesWithoutName =
    currentEnvironment === Environment.STAGING
      ? integrationFormValues.staging
      : integrationFormValues.production;

  const { isHide } = useCheckPermissions({
    allowedPermission: [permissionObj.create, permissionObj.edit],
    entityList: [ENTITY_ID.integrations],
  });

  const { isHide: isTestDisable } = useCheckPermissions({
    allowedPermission: [permissionObj.test],
    entityList: [ENTITY_ID.integrations],
  });

  const { isHide: isPublishDisable } = useCheckPermissions({
    allowedPermission: [permissionObj.publish],
    entityList: [ENTITY_ID.integrations],
  });

  const {
    control,
    handleSubmit,
    watch,
    reset,
    setError,
    setValue,
    formState: { errors },
  } = useForm<Record<string, any>>();

  const currentFormValues = useWatch({
    control,
  });

  const testConnectionHandler = () => {
    const formValues = structuredClone(watch());

    const isFormValid = validateConnectorForm(
      formValues,
      sortedFields,
      setError
    );

    if (isFormValid && _isEmpty(errors)) {
      if (!isPluginAuthorizeRequired) {
        testConnector();
      } else {
        if (_isEmpty(formValues.credentialId)) {
          toasts.error('Please connect app first!', 'error');
        } else {
          testConnector();
        }
      }
    }
  };

  useEffect(() => {
    return () => {
      const { name, ...formValuesWithoutName } = structuredClone(watch());

      storeFormValues(formValuesWithoutName, currentEnvironment);
    };
  }, []);

  useEffect(() => {
    if (shouldFormRender && !isPluginAuthorizeRequired) {
      reset({ ...initialFormValuesWithoutName, name: connectorName });
    }
  }, [shouldFormRender, currentEnvironment]);

  useEffect(() => {
    if (formType === 'edit' && isPluginAuthorizeRequired) {
      const initalFormValues = constructInitialFomValuesForEditFormType();

      reset({ ...initalFormValues, name: connectorName });
    }
  }, [JSON.stringify(initialFormValuesWithoutName)]);

  useEffect(() => {
    if (
      formType === 'create' &&
      isPluginAuthorizeRequired &&
      !_isNil(sortedFields) &&
      sortedFields.length > 0 &&
      resetInitialData < 2
    ) {
      const initialFormValuesbasedOnFields =
        getInitialFormValuesBasedOnFields(sortedFields);

      setResetInitialData(resetInitialData + 1);

      reset({ ...initialFormValuesbasedOnFields, name: connectorName });
    }

    const resetFieldsMapDetails: Record<string, string[]> = {};

    sortedFields.forEach((i) => {
      if (!_isNil(i.reset) && i.reset.length > 0) {
        resetFieldsMapDetails[i.key] = i.reset;
      }
    });
    setResetFieldsMap(resetFieldsMapDetails);
  }, [JSON.stringify(sortedFields), currentEnvironment]);

  useEffect(() => {
    if (
      formType === 'create' &&
      isPluginAuthorizeRequired &&
      !_isEmpty(initialFormValuesWithoutName) &&
      resetInitialData >= 2
    ) {
      reset({ ...initialFormValuesWithoutName, name: connectorName });
    }
  }, [currentEnvironment]);

  useEffect(() => {
    setParentFormData({
      ...parentFormData,
      pluginName,
      connectorId: connectorId ?? '',
    });
  }, [pluginName, connectorId]);

  const autoSaveForm = handleSubmit(async (formValues) => {
    if (!isOnboardingCompleted(integrationsAdded)) {
      updateWidgetState(integrationsAdded)
        .then(() => {
          void getUserState();
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    }

    return await onSubmit(formValues);
  });
  const previousProtocol = useRef(currentFormValues?.protocol?.value);

  useEffect(() => {
    if (currentFormValues?.protocol?.value !== previousProtocol.current) {
      Object.keys(currentFormValues).forEach((fieldKey) => {
        if (
          Object.keys(resetFieldsMap).length > 0 &&
          !_isNil(resetFieldsMap[fieldKey])
        ) {
          const fieldsToReset = resetFieldsMap[fieldKey];
          fieldsToReset.forEach((fieldToReset: any) => {
            setValue(fieldToReset, null);
          });
        }
      });

      previousProtocol.current = currentFormValues.protocol.value;
    }

    let submitTimeout: TimerId;
    setCounter(counter + 1);

    if (counter > 1) {
      submitTimeout = setTimeout(() => {
        void autoSaveForm();
      }, 500);
    }

    return () => {
      if (!_isNil(submitTimeout)) {
        clearTimeout(submitTimeout);
      }
    };
  }, [JSON.stringify(currentFormValues)]);

  useEffect(() => {
    if (typeof setLocalConnName === 'function') {
      setLocalConnName(currentFormValues.name ?? '');
    }
  }, [JSON.stringify(currentFormValues?.name)]);

  const constructInitialFomValuesForEditFormType = () => {
    const initialFormValuesBasedOnFields =
      getInitialFormValuesBasedOnFields(sortedFields);

    if (
      _isNil(initialFormValuesWithoutName) ||
      _isEmpty(initialFormValuesWithoutName)
    ) {
      return initialFormValuesBasedOnFields;
    }

    const finalFormValues = _reduce(
      initialFormValuesWithoutName,
      (formValues: Record<string, any>, value, key) => {
        if (_isNil(value) || _isEmpty(value)) {
          formValues[key] = initialFormValuesBasedOnFields[key];
        }

        return formValues;
      },
      { ...initialFormValuesWithoutName }
    );

    return finalFormValues;
  };

  if (!shouldFormRender) {
    return null;
  }

  return (
    <Stack as={Form}>
      <PadBox
        padding={{
          left: 20,
          right: 20,
        }}
      >
        <Toast
          message={getTooltipText(
            siteConstants,
            'integrations',
            `connector${currentEnvironment}`,
            'otherText'
          )}
          type="infoBlue"
        />
      </PadBox>
      <FormFieldsContainer padding="1.6rem">
        <FormFieldsStack gutter="1rem">
          {sortedFields.length > 0 && (
            <PluginFieldContainer required>
              <TextField
                control={control}
                label="Name"
                labelFontWeight={700}
                name="name"
                placeholder="Connector name"
                rules={{
                  required: 'Connector name cannot be empty',
                  pattern: {
                    value: NOT_EMPTY_STRING_REGEX,
                    message: 'Maximum 50 characters allowed',
                  },
                }}
                type="text"
                defaultValue={connectorName}
                disabled={isHide}
              />
            </PluginFieldContainer>
          )}

          {!isPluginAuthorizeRequired &&
            sortedFields.map((field) => {
              const {
                key,
                type,
                placeholder,
                hidden = 'false',
                isDisabled = 'false',
                regex,
                required,
                isRequired,
                ...rest
              } = field;
              const Component = integrationComponents[type];

              const hiddenCondtionResult: boolean = parseExpression(
                currentFormValues,
                parentFormData,
                hidden
              );

              const requiredCondtionResult: boolean = parseExpression(
                currentFormValues,
                parentFormData,
                isRequired
              );

              if (
                _isNil(Component) ||
                hiddenCondtionResult ||
                hidden === 'true'
              ) {
                return null;
              }

              return (
                <Component
                  control={control}
                  key={key}
                  name={key}
                  placeholder={placeholder}
                  regex={new RegExp(regex)}
                  disabled={isHide || isDisabled === 'true'}
                  required={
                    requiredCondtionResult ||
                    (required && !hiddenCondtionResult)
                  }
                  type={type}
                  refetchAuthKeys={refetchAuthKeys}
                  footer={
                    key === 'credentialId' ? <MenuListFooter /> : undefined
                  }
                  isMulti={type === 'multi-select'}
                  setValue={setValue}
                  formJson={formJson}
                  parentFormData={parentFormData}
                  {...rest}
                />
              );
            })}

          {isPluginAuthorizeRequired && (
            <DynamicForm
              name={pluginName}
              formFields={sortedFields as FormFields[]}
              entity="integrations"
              watch={watch}
              parentFormData={parentFormData}
              formJson={formJson}
              setValue={setValue}
              control={control}
            />
          )}
          <WhitelistIps whitelistIps={whitelistIps} />
        </FormFieldsStack>
      </FormFieldsContainer>

      <SaveButtonContainer>
        <HorizontalLine />

        <PadBox padding="0.8rem">
          <Inline justify="end" gutter={8} align="center">
            <AutoSaveLoader isLoading={isCreatingOrUpdating} />

            {!isTestDisable && (
              // (authorizedPluginList.includes(pluginType.toLocaleLowerCase()) ? (
              //   <WebButtonField
              //     disabled={
              //       isCreatingOrUpdating ||
              //       _isEmpty(connectorId) ||
              //       testInProgress
              //     }
              //     control={control}
              //     type={'button'}
              //     label="Authorize and Test"
              //     name="Authorize and Test"
              //     regex={/.*?/}
              //     {...testButtonJson}
              //     formJson={testButtonJson}
              //     parentFormData={parentFormData}
              //   />
              // ) :
              <Button
                appearance="filled"
                size="medium"
                disabled={
                  isCreatingOrUpdating ||
                  _isEmpty(connectorId) ||
                  testInProgress
                }
                onClick={testConnectionHandler}
              >
                Test Connection
              </Button>
            )}

            {!isPublishDisable && (
              <Button
                disabled={
                  !connectorStatus[currentEnvironment].isTested ||
                  connectorStatus[currentEnvironment].isPublish
                }
                size="medium"
                onClick={async () => {
                  try {
                    await publishConnector(currentEnvironment);

                    if (typeof onPublish === 'function') {
                      onPublish();
                    }

                    if (from === 'rules') {
                      close();
                    }
                  } catch (error) {}
                }}
              >
                Publish in
                {currentEnvironment === Environment.STAGING
                  ? ' Staging'
                  : ' Production'}
              </Button>
            )}
          </Inline>
        </PadBox>
      </SaveButtonContainer>
    </Stack>
  );
}
