import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import styled from 'styled-components';

export const InputContainer = styled(Stack)`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const JsonPillStyled = styled(Inline)<{ $hasError: boolean }>`
  min-inline-size: 4rem;
  border-radius: 1.6rem;
  max-inline-size: 14rem;
  padding: 0.4rem 2rem;
  cursor: pointer;
  position: relative;
  background: var(--color-lightGray);
  outline: 1px solid
    ${({ $hasError }) =>
      $hasError ? 'var(--color-fireEngineRed)' : 'var(--color-lavender)'};
`;

export const UntestedFooter = styled(Inline)`
  padding: 0.4rem 2rem;
  border-radius: 0.4rem;
  background-color: var(--color-supernova);
  color: var(--color-darkBrown);
`;
