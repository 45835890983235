import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import styled from 'styled-components';
import { Link, Typography } from 'ui';

type ProgressWidthProps = {
  progress: number;
  aboutToExceed: boolean;
};

type StatsDisplayProps = {
  aboutToExceed: boolean;
};

type UpgradeLinkProps = {
  textDecoration?: boolean;
  fontSize?: number;
};

export const SidebarStyled = styled(PadBox)`
  max-height: calc(100vh - 52px);
  overflow-y: auto;
  flex: 1;
`;

export const UsageBox = styled(Stack)`
  border: 0.1rem solid var(--color-lightGray6);
  border-radius: 0.8rem;
  background: var(--color-lavender);
  padding: 0.8rem;
`;

export const ExternalLink = styled(Link)`
  text-decoration: none;
  color: var(--color-charcoal);
  display: block;
`;

export const ItemName = styled(Typography)`
  text-decoration: none;
  color: var(--color-charcoal);
  display: block;
`;

export const UsageContainer = styled(Inline)`
  justify-content: space-between;
`;

export const ProgressBarContainer = styled.div`
  background: var(--color-lightGray5);
  block-size: 0.3rem;
  inline-size: 100%;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  overflow: hidden;
`;

export const AddOnContainer = styled(Inline)`
  -webkit-box-align: end;
  align-items: end;
  justify-content: flex-end;
`;

export const UpgradeLink = styled.a<UpgradeLinkProps>`
  :link {
    color: var(--color-primary9);
  }
  :visited {
    color: var(--color-primary9);
  }
  :hover {
    color: var(--color-primary9);
  }
  :active {
    color: var(--color-primary9);
  }
  ${({ textDecoration = false }) =>
    `text-decoration:${textDecoration ? 'underline' : 'none'}`};

  ${({ fontSize = 1.2 }) => `font-size:${fontSize.toLocaleString()}rem`};
`;

export const ProgressWidth = styled.div<ProgressWidthProps>`
  overflow: hidden;
  background: var(--color-black);
  block-size: 0.3rem;
  ${({ progress = 0, aboutToExceed }) =>
    `inline-size : ${progress}%; background:var(${
      aboutToExceed ? '--color-progressRed' : '--color-black'
    })`}
`;

export const StatsDisplay = styled(Typography)<StatsDisplayProps>`
  text-align: right;
  ${({ aboutToExceed }) =>
    `color:var(${aboutToExceed ? '--color-progressRed' : '--color-black'})`}
`;

export const UsageHelpText = styled(Typography)`
  margin-top: 1rem;
`;

export const UsageAlert = styled.div``;

export const SidebarFooter = styled(Stack)`
  position: absolute;
  inline-size: 100%;
  bottom: -50px;
  background: var(--color-white);
`;

export const SidebarFooterContainer = styled(Stack)`
  position: relative;
`;

export const SidebarMenuContainer = styled(Stack)`
  margin-bottom: 7rem;
`;

export const SidebarBeta = styled(PadBox)<{ $isActive: boolean }>`
  background: ${({ $isActive = false }) =>
    $isActive ? 'var(--color-primary8)' : 'var(--color-lightGray6)'};
  color: ${({ $isActive = false }) =>
    $isActive ? 'var(--color-primary1)' : 'var(--color-stormDust)'};

  border-radius: 2rem;
  font-size: 1rem;
`;

export const TrialPeriodComponent = styled(Stack)<{ bgColor: string }>`
  background-color: ${({ bgColor }) => bgColor};
  border-radius: 0.5rem;
  padding: 1rem;
  gap: 1rem;
  align-items: start;

  #sidebar-extend-request-button,
  #sidebar-upgrade-button {
    font-size: 1.2rem;
    padding: 0.5rem 1.5rem;
  }
`;

export const TrialText = styled(Typography)`
  color: var(--color-white);
  font-size: 1.2rem;
`;
