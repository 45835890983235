import { Inline } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { Button, Modal, ModalContent, Typography } from 'ui';

import { checksumWarningAtom } from '../../atom';
import { useSendEventToGTM } from '../../hooks/useSendEventToGTM';
import { envMap } from '../../utils/constant';
import { CSErrorContainer } from './checksum.styled';

type ChecksumWarningProps = {
  redirectTo: string;
};

export const ChecksumWarning = ({ redirectTo = '' }: ChecksumWarningProps) => {
  const [checksumWarningData, setChecksumWarningData] =
    useAtom(checksumWarningAtom);
  const { sendEventToGTM } = useSendEventToGTM();

  useEffect(() => {
    if (checksumWarningData.showPopup) {
      sendEventToGTM({
        event: 'checksum',
        source: getEnitityName(),
        ...checksumWarningData.metaData,
      });
    }
  }, [checksumWarningData]);

  const getEnitityName = () => {
    if (redirectTo.includes('rules')) {
      return 'Rule';
    } else if (redirectTo.includes('datasets')) {
      return 'Dataset';
    } else if (redirectTo.includes('integrations')) {
      return 'Integration';
    } else if (redirectTo.includes('workflow')) {
      return 'Workflow';
    }

    return '';
  };

  return (
    <Modal
      hideCloseButton
      size="large"
      title={
        <Typography name="heading2">
          <Inline
            justify="center"
            align="center"
            stretch="start"
            gutter={'1rem'}
          >
            <img
              style={{ maxWidth: '4rem' }}
              src={`${envMap.VITE_ASSETS_URL}website/icons/warning_icon.svg`}
              alt="warning image"
            />
            {`${getEnitityName()}`} Editing Blocked
          </Inline>
        </Typography>
      }
    >
      <ModalContent>
        <CSErrorContainer gutter="3rem">
          <Typography name="heading3" fontWeight={700}>
            Only one person or tab can edit this {`${getEnitityName()}`} at a
            time. Please close other open editors, & reload the page before
            continuing here.
          </Typography>
          <Button
            onClick={() => {
              setChecksumWarningData({ showPopup: false });
              window.open(`/${redirectTo}`, '_self');
            }}
          >
            Close
          </Button>
        </CSErrorContainer>
      </ModalContent>
    </Modal>
  );
};
