import { useMutation } from '@tanstack/react-query';
import type { AxiosError, AxiosResponse } from 'axios';
import { useAtom } from 'jotai';
import { useNavigate } from 'react-router-dom';
import { toasts } from 'ui';

import { allWorkspaceAtom, userProfileAtom } from '../../atom';
import {
  AccessToken,
  RefreshToken,
  useAuth,
} from '../../components/authentication';
import { useAxios } from '../../hooks';
import { useSendEventToGTM } from '../../hooks/useSendEventToGTM';
import { getRedirectUrl, getResponseErrorMessage } from '../../utils/common';
import { CustomAxiosError } from '../../utils/response/types';

export type SigninRequestPayload = {
  tokenId: string;
  source: Record<string, string>;
};

type GoogleSigninResponse = {
  data: {
    newUser: boolean;
    accessToken: AccessToken;
    refreshToken: RefreshToken;
    workspaceUUID: string;
    userUUID: string;
  };
};

export function useSignedURL(type: string) {
  const navigate = useNavigate();
  const { axios } = useAxios();
  const { setAuth } = useAuth();
  const [, setAllWorkspaceAtom] = useAtom(allWorkspaceAtom);
  const [, setUserProfile] = useAtom(userProfileAtom);
  const { sendEventToGTM } = useSendEventToGTM();

  const { mutate: signinWithSignedURL } = useMutation({
    mutationFn: async (request: SigninRequestPayload) => {
      return await axios.post(`/auth/${type}/login`, request, {
        headers: {
          'Nected-User-IP': localStorage.getItem('Nected-User-IP') ?? '',
          'X-CSRF-Token': localStorage.getItem('X-CSRF-Token') ?? '',
        },
      });
    },
    onSuccess(response: AxiosResponse<GoogleSigninResponse>) {
      const { data } = response.data;
      const { accessToken, refreshToken, workspaceUUID, userUUID } = data;
      setAllWorkspaceAtom(null);
      setUserProfile(null);
      const path = 'signin_signedURL';

      sendEventToGTM({
        event: path,
        status: 'success',
        provider: type,
        userid: userUUID,
        wsid: workspaceUUID,
      });

      sendEventToGTM({
        event: `${path}_success`,
        userid: userUUID,
      });

      setAuth({ accessToken, refreshToken, workspaceUUID, userUUID });

      const to = getRedirectUrl(window.location.href) ?? '/home';
      try {
        const url = new URL(to);
        window.open(url, '_self');
      } catch (error) {
        navigate(to, { replace: true });
      }
    },
    onError(error: AxiosError<CustomAxiosError>) {
      toasts.error(getResponseErrorMessage(error), 'error');

      const { code } = error.response?.data ?? { code: '' };

      sendEventToGTM({
        event: 'signin_signedURL',
        status: 'error',
        reason: code,
        provider: type,
      });
      navigate('/signin');
    },
  });

  return {
    signinWithSignedURL,
  };
}
