import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import styled from 'styled-components';

export const TitleContainer = styled.div`
  padding: 1rem;
  border-bottom: 1px solid var(--color-lightGray);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RadioContainer = styled(Stack)`
  padding: 1rem;
`;

export const StyledButtonContainer = styled(Inline)`
  padding: 1rem;
  border-top: 1px solid var(--color-lightGray);
`;
