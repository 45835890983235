import { PadBox } from '@bedrock-layout/padbox';
import { Stack } from '@bedrock-layout/stack';
import styled from 'styled-components';

export const CardContainer = styled(PadBox)`
  background-color: var(--color-white);
  border-radius: 0.5rem;
`;
export const CredentialsContainer = styled(Stack)`
  margin-top: 4rem;
`;
