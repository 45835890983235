import { useController } from 'react-hook-form';
import type { FieldValues, UseControllerProps } from 'react-hook-form';
import { TreeViewer, TreeViewerProps } from 'ui';

import { Field } from '../Field';

export type TreeViewerFieldProps<P extends FieldValues> =
  UseControllerProps<P> &
    TreeViewerProps & {
      showError?: boolean;
    };

export const TreeViewerField = <P extends FieldValues>({
  name,
  control,
  rules,
  defaultValue,
  showError,
  ...rest
}: TreeViewerFieldProps<P>) => {
  const { field, fieldState } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  return (
    <Field fieldState={fieldState} showError={showError}>
      <TreeViewer {...field} {...rest} />
    </Field>
  );
};
