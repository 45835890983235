import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import { memo } from 'react';
import { BsArrowReturnRight } from 'react-icons/bs';
import { IoPlayOutline } from 'react-icons/io5';
import { Handle, NodeProps, Position } from 'reactflow';
import { IconButton, Typography, useLayer } from 'ui';

import { useSendEventToGTM } from '../../../../../../hooks/useSendEventToGTM';
import {
  isWorkflowTestOnlyAtom,
  workflowErrorByNodeAtom,
} from '../../../../atoms/atoms';
import { useTestResponseNode } from '../../../../hooks/useTestResponseNode';
import { ResponseNodeSheet } from '../../../Sheets/ResponseNodeSheet/ResponseNodeSheet';
import { NodeStatus } from '../../NodeStatus/NodeStatus';
import { ResponseAction } from '../ResponseAction/ResponseAction';
import {
  IconContainer,
  ResponseActionsContainer,
  ResponseContainer,
  ResponseInfoContainer,
} from './NectedResponseNode.styled';

export const NectedResponseNode = memo(
  ({ data, isConnectable, id, type }: NodeProps) => {
    const { openWithProps } = useLayer(<ResponseNodeSheet />);

    const [workflowErrorByNode] = useAtom(workflowErrorByNodeAtom);
    const [isWorkflowTestOnly] = useAtom(isWorkflowTestOnlyAtom);

    const { testData } = useTestResponseNode({ localData: data, id });

    const { sendEventToGTM } = useSendEventToGTM();

    const handleTestData = async () => {
      try {
        await testData(data.input);
      } catch (error) {}
    };

    return (
      <>
        <Handle
          type="source"
          position={Position.Bottom}
          style={{
            background: 'var(--color-primary1)',
            height: 8,
            width: 8,
            border: '1px solid var(--color-primary1)',
          }}
          isConnectable={isConnectable}
        />
        <Handle
          type="target"
          position={Position.Top}
          style={{
            background: 'var(--color-primary1)',
            height: 8,
            width: 8,
            border: '1px solid var(--color-primary1)',
          }}
          isConnectable={isConnectable}
        />
        <ResponseContainer
          padding="0.5rem"
          $isError={!(workflowErrorByNode[id] == null)}
        >
          <NodeStatus status={data.status} />

          <IconContainer
            onClick={() => {
              sendEventToGTM({
                event: 'workflow',
                source: 'listing',
                element: type,
                action: 'node_click',
                type: data.nodeType,
              });

              openWithProps({
                id,
                data,
              });
            }}
          >
            <BsArrowReturnRight size={28} />
          </IconContainer>

          <PadBox
            padding={{
              top: '6px',
              bottom: '6px',
            }}
          >
            <ResponseInfoContainer>
              <Typography name="paragraphXs" fontWeight={700}>
                {data.name ?? 'Step 1'}
              </Typography>

              <Typography name="paragraphXs">Control</Typography>

              {!(workflowErrorByNode[id] == null) && (
                <Typography name="errorXs">
                  {workflowErrorByNode[id]?.message}
                </Typography>
              )}
            </ResponseInfoContainer>

            <ResponseActionsContainer>
              <Inline
                align="center"
                style={{
                  background: 'var(--color-lightGray7)',
                  width: '4rem',
                }}
              >
                <IconButton
                  disabled={!isWorkflowTestOnly}
                  onClick={handleTestData}
                >
                  <IoPlayOutline
                    color={
                      isWorkflowTestOnly
                        ? 'var(--color-black)'
                        : 'var(--color-darkGray)'
                    }
                  />
                </IconButton>

                <ResponseAction data={data} id={id} />
              </Inline>
            </ResponseActionsContainer>
          </PadBox>
        </ResponseContainer>
      </>
    );
  }
);

NectedResponseNode.displayName = 'NectedResponseNode';
