import { useAtom } from 'jotai';
import { Expander, Joiner, Typography } from 'ui';

import { siteConstantsAtom } from '../../../atom';
import { getTooltipText } from '../../../utils/common';
import { WhitelistIpsContainer } from './WhitelistIps.styled';

type WhitelistIpsProps = {
  whitelistIps: string[];
};

export function WhitelistIps({ whitelistIps }: WhitelistIpsProps) {
  const [siteConstants] = useAtom(siteConstantsAtom);

  return (
    <Expander
      title="Expand to see the IP addresses to add to your allow-list"
      fontWeight={700}
      name="heading4"
      tooltip={getTooltipText(
        siteConstants,
        'integrations',
        'expandToSeeIpList'
      )}
    >
      <WhitelistIpsContainer padding="1.5rem">
        <Typography>[</Typography>

        <Joiner isBlock>
          {whitelistIps.map((ip, index) => (
            <Typography element="span" key={index}>
              &nbsp;&nbsp;&nbsp;{ip}
            </Typography>
          ))}
        </Joiner>

        <Typography>]</Typography>
      </WhitelistIpsContainer>
    </Expander>
  );
}
