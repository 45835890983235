import { Inline, Stack } from '@bedrock-layout/primitives';
import _isNil from 'lodash/isNil';
import type {
  ChangeEvent,
  HTMLInputTypeAttribute,
  ReactNode,
  Ref,
} from 'react';
import { forwardRef } from 'react';
import { GoAlert } from 'react-icons/go';

import { FontWeight, Typography } from '../../Typography';
import { DataListInputStyled } from './DataListInput.styled';

export type DataListInputSize = 'small' | 'medium' | 'self';

export type DataListInputProps = {
  icon?: ReactNode;
  startIcon?: ReactNode;
  hasError?: boolean;
  id?: string;
  name?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  type?: HTMLInputTypeAttribute;
  value?: string;
  options?: string[];
  label?: string;
  showError?: boolean;
  size?: DataListInputSize;
  disabled?: boolean;
  readOnly?: boolean;
  showErrorIcon?: boolean;
  isSmallInput?: boolean;
  showOutline?: boolean;
  labelFontWeight?: FontWeight;
};

type InputWidths = {
  [key in DataListInputSize]: number;
};

const inputSize: InputWidths = {
  small: 10,
  medium: 15,
  self: 20,
};

export const DataListInput = forwardRef(
  (
    {
      placeholder,
      value,
      name,
      hasError = false,
      onChange,
      label,
      options = [],
      showError = true,
      size = 'self',
      disabled = false,
      readOnly = false,
      showErrorIcon = true,
      isSmallInput = false,
      showOutline = true,
      labelFontWeight,
    }: DataListInputProps,
    ref: Ref<HTMLInputElement>
  ) => {
    const id = (name ?? '') + options.length.toString();

    return (
      <Stack gutter=".8rem">
        {!_isNil(label) && (
          <label>
            <Typography fontWeight={labelFontWeight}>{label}</Typography>
          </label>
        )}
        <Inline align="center" gutter="1.1rem" stretch="start">
          <DataListInputStyled
            list={id}
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            hasError={hasError}
            ref={ref}
            size={inputSize[size]}
            disabled={disabled}
            readOnly={readOnly}
            isSmallInput={isSmallInput}
            showOutline={showOutline}
          />

          <datalist id={id}>
            {options.map((optionVal, index) => (
              <option key={index} value={optionVal}></option>
            ))}
          </datalist>

          {hasError && showError && showErrorIcon && (
            <span>
              <GoAlert fill="var(--color-fireEngineRed)" size={20} />
            </span>
          )}
        </Inline>
      </Stack>
    );
  }
);

DataListInput.displayName = 'DataListInput';
