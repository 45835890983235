import { PadBox } from '@bedrock-layout/padbox';
import styled from 'styled-components';

export const TriggerListContainer = styled(PadBox)`
  block-size: 16rem;
  inline-size: 24rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const TriggerHeader = styled(PadBox)`
  border-block-end: 1px solid var(--color-lightGray6);
`;

export const TriggerItems = styled(PadBox)`
  border-block-end: 1px solid var(--color-lightGray6);
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

export const TriggerItemStyled = styled.div`
  border: 1px solid var(--color-lightGray6);
  block-size: 6rem;
  inline-size: 6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
`;
