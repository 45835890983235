import { Inline } from '@bedrock-layout/primitives';
import { GoAlert } from 'react-icons/go';
import { TextButton, Typography } from 'ui';

import { ResendEmailVerificationLinkContainer } from './ResendEmailVerificationLink.styled';
import { useSendEmailVerificationLink } from './hooks/useSendEmailVerificationLink';

type ResendEmailVerificationLinkProps = {
  verificationDaysLeft?: number;
};

export function ResendEmailVerificationLink({
  verificationDaysLeft,
}: ResendEmailVerificationLinkProps) {
  const { sendVerificationLink } = useSendEmailVerificationLink();

  return (
    <ResendEmailVerificationLinkContainer>
      <Inline gutter="1.6rem" justify="center">
        <GoAlert fill="var(--color-fireEngineRed)" size={20} />
        <Inline gutter=".8rem">
          <Typography>
            Action required – Verify your email within {verificationDaysLeft}{' '}
            days to continue using our product. {`To verify now `}
          </Typography>
          <TextButton onClick={sendVerificationLink}>Click here</TextButton>
        </Inline>
      </Inline>
    </ResendEmailVerificationLinkContainer>
  );
}
