import { gql, useLazyQuery } from '@apollo/client';

const getDatasetQuery = gql`
  query getDataSet($id: String) {
    getDataSet(id: $id) {
      data {
        status
        checksum
      }
    }
  }
`;

export function useGetDatasetAfterTest() {
  return useLazyQuery(getDatasetQuery, {
    fetchPolicy: 'no-cache',
  });
}
