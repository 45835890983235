import { Inline, Stack } from '@bedrock-layout/primitives';
import styled from 'styled-components';

export const BannerContainer = styled.div`
  height: auto;
`;
export const OfferingsContainer = styled(Stack)`
  justify-content: center;
  align-items: start;
  margin-top: 3rem;
  padding: 3rem;
  height: calc(100% - 10rem);
`;

export const OfferingItem = styled(Inline)`
  align-items: center;
`;
