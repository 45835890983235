import { gql, useMutation } from '@apollo/client';
import type { AxiosError } from 'axios';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { toasts } from 'ui';

import { checksumWarningAtom } from '../../../../atom';
import {
  checksumMessage,
  getResponseErrorMessage,
  handleGetCheckSumByEntityName,
  handleSetCheckSumByEntityName,
  showGraphQlErrorToast,
} from '../../../../utils/common';
import type { RuleEnvironment } from '../../../../utils/constant';
import type { CustomAxiosError } from '../../../../utils/response/types';
import type { UpdateConnectorStatusFunction } from '../../types';

const publishConnectorQuery = gql`
  mutation publishConnector(
    $id: String!
    $environment: Environment!
    $checksum: String!
  ) {
    publishConnector(id: $id, environment: $environment, checksum: $checksum) {
      checksum
      status
    }
  }
`;

type PublishConnectorResult = {
  publishConnector: {
    checksum: string;
    status: string;
  };
};

type PublishConnectorVariables = {
  id: string;
  environment: RuleEnvironment;
  checksum: string;
};

export type PublishConnectorFunction = (environment: RuleEnvironment) => void;

export type UsePublishConnectorReturn = {
  loading: boolean;
  publishConnector: PublishConnectorFunction;
};

export function usePublishConnector(
  connectorId: string,
  updateConnectorStatus: UpdateConnectorStatusFunction,
  connectorName: string,
  pluginType: string
): UsePublishConnectorReturn {
  const [publishConnectorMutator, { loading }] = useMutation<
    PublishConnectorResult,
    PublishConnectorVariables
  >(publishConnectorQuery);

  const [, setShowChecksumPopup] = useAtom(checksumWarningAtom);

  const publishConnector = async (environment: RuleEnvironment) => {
    try {
      const checksum = handleGetCheckSumByEntityName('integrations');

      const { data } = await publishConnectorMutator({
        variables: {
          id: connectorId,
          environment,
          checksum: checksum ?? '',
        },
      });

      if (!_isNil(data)) {
        handleSetCheckSumByEntityName(
          'integrations',
          data.publishConnector.checksum
        );
      }

      updateConnectorStatus(environment, false, true);

      toasts.success(`Published in ${environment}`, 'success');
    } catch (error) {
      showGraphQlErrorToast(error);

      if (error instanceof Error) {
        if (
          getResponseErrorMessage(
            error as AxiosError<CustomAxiosError>
          ).includes(checksumMessage)
        ) {
          setShowChecksumPopup({
            showPopup: true,
            metaData: {
              connectorId,
              connectorName,
              type: pluginType,
            },
          });
        }
      }
    }
  };

  return {
    loading,
    publishConnector,
  };
}
