import { Inline } from '@bedrock-layout/inline';
import { Ref, forwardRef } from 'react';

import { PopoverPanel } from '../../Popover';
import { TextInput } from '../TextInput';
import { WeekDayButton } from './MonthDayPicker.styled';

export type MonthDayPickerProps = {
  value?: number[];
  onChange?: (value: any) => void;
};

const listOfDays = Array.from({ length: 31 }, (value, index) => index + 1);

export const MonthDayPicker = forwardRef(
  ({ value = [], onChange }: MonthDayPickerProps, ref: Ref<HTMLElement>) => {
    return (
      <PopoverPanel
        trigger="click"
        launcher={
          <TextInput
            placeholder="Select days"
            value={value.join(',')}
            size="small"
            readOnly
          />
        }
      >
        <Inline
          align="center"
          gutter="0.5rem"
          justify="start"
          style={{
            width: '28rem',
            columnGap: '1rem',
            flexWrap: 'wrap',
          }}
        >
          {listOfDays.map((day, index) => {
            return (
              <WeekDayButton
                $isActive={value.includes(day)}
                type="button"
                onClick={() => {
                  if (typeof onChange === 'function') {
                    if (!value.includes(day)) {
                      onChange([...value, day]);
                    } else {
                      onChange(value.filter((val) => val !== day));
                    }
                  }
                }}
                key={`weekday_${index}`}
              >
                {day}
              </WeekDayButton>
            );
          })}
        </Inline>
      </PopoverPanel>
    );
  }
);

MonthDayPicker.displayName = 'MonthDayPicker';
