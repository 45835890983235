import { PadBox } from '@bedrock-layout/padbox';
import { ReactNode } from 'react';

import { TestNodeFooterContainer } from './TestNodeFooter.styled';

type TestNodeFooterProps = {
  children: ReactNode;
};

export const TestNodeFooter = ({ children }: TestNodeFooterProps) => {
  return (
    <TestNodeFooterContainer align="end" justify="end">
      <PadBox padding="0.8rem">{children}</PadBox>
    </TestNodeFooterContainer>
  );
};
