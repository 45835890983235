import styled, { css } from 'styled-components';
import theme from 'styled-theming';

const connectorStatusAppearance = theme('status', {
  production: css`
    color: var(--color-green);
    background: var(--color-honeydew);
  `,
  staging: css`
    color: var(--color-hanPurple);
    background: var(--color-magnolia);
  `,
  draft: css`
    color: var(--color-tangerine);
    background: var(--color-earlyDawn);
  `,
  tested: css`
    color: var(--color-primary1);
    background: var(--color-primary2);
  `,
});

export const ConnectorStatusStyled = styled.div`
  border: none;
  border-radius: 4px;
  padding: 0 0.4rem;
  ${connectorStatusAppearance}
`;
