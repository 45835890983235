import type { CellContext, ColumnDef } from '@tanstack/react-table';

import type { PermissionType } from '../../PermissionComponent/constant';
import type { ListingDetailsProps } from '../types';
import { renderTableCell } from './renderTableCells';

export const getTableColumns = (
  entity: string,
  listingDetails: ListingDetailsProps,
  permissions?: Record<PermissionType, boolean>,
  handleActionItemClick?: (obj?: any) => void,
  checkPermissionsToEnable?: (
    allowedPermission: string[],
    entityStatus: string,
    statusShouldBe?: string[]
  ) => boolean
) => {
  const columns: Array<
    ColumnDef<any> & { isSort: boolean; width?: number; actionList?: any }
  > = [];
  listingDetails.tableHeaders.forEach((header: any) => {
    columns.push({
      header: header.keyName === 'action' ? '' : header.name,
      accessorKey:
        header.keyName === 'rootNode' ? 'rootNode.id' : header.keyName,
      cell: (props: CellContext<any, any>) => {
        return renderTableCell(
          entity,
          listingDetails,
          props,
          header.type,
          permissions,
          handleActionItemClick,
          header.metaData,
          header.style,
          checkPermissionsToEnable
        );
      },
      isSort: header.sortable ?? false,
      width: header.key === 'status' ? 150 : undefined,
    });
  });

  return columns;
};
