import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { Ref, forwardRef } from 'react';
import { VscTriangleDown } from 'react-icons/vsc';
import { ExecutedValueTooltip } from 'ui';

import { ErrorPopoverPositioned } from '../../../../../Rules/components/SimpleRule/Error/ErrorPopoverPositioned';
import { sendEventToGTMType } from '../../../../../Rules/types';
import {
  ContainerStyled,
  PropertyContainer,
  RuleLauncherLabel,
} from './PathBlock.styled';

type PropertyLauncherProps = {
  id: string;
  error: string | null;
  text?: string;
  propertyValue?: any;
  dataType?: string;
  handleSendEventToGTM?: (obj: sendEventToGTMType) => void;
};

export const PropertyLauncher = forwardRef(
  (
    {
      id,
      text = 'Property',
      error,
      propertyValue,
      dataType,
      handleSendEventToGTM,
    }: PropertyLauncherProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const handleClick = () => {
      if (typeof handleSendEventToGTM === 'function') {
        handleSendEventToGTM({
          action: _isEmpty(text) ? 'add' : 'edit',
          element: 'property',
          actionName: dataType ?? '',
        });
      }
    };

    return (
      <ContainerStyled padding={[3, 8]} onClick={handleClick}>
        {!_isNil(error) && <ErrorPopoverPositioned error={error} />}

        <ExecutedValueTooltip
          id={id}
          attribute={text}
          value={propertyValue}
          showExecutedValue={false}
          placement="right"
          dataType={dataType}
          isVisible={!_isNil(text) && !_isEmpty(text)}
        >
          <PropertyContainer stretch="start" align="center" ref={ref}>
            <RuleLauncherLabel>
              {!_isEmpty(text) ? text : 'Property'}
            </RuleLauncherLabel>
            <VscTriangleDown size={12} />
          </PropertyContainer>
        </ExecutedValueTooltip>
      </ContainerStyled>
    );
  }
);

PropertyLauncher.displayName = 'PropertyLauncher';
