import _isNil from 'lodash/isNil';
import { useEffect } from 'react';
import { UseControllerProps, UseFormReturn, useWatch } from 'react-hook-form';
import { CheckboxField } from 'ui';

type CaseSensitiveCheckboxProps = UseControllerProps<any> & {
  field: string;
  index: number;
  disabled: boolean;
} & Pick<UseFormReturn<any>, 'setValue'>;

export function CaseSensitiveCheckbox({
  control,
  name,
  field,
  index,
  setValue,
  disabled,
}: CaseSensitiveCheckboxProps) {
  const dataType = useWatch({ control, name: field });

  useEffect(() => {
    if (_isNil(dataType) || dataType.value !== 'string') {
      setValue(`attributes.${index}.isCaseSensitive`, false);
    }
  }, [dataType]);

  return (
    <CheckboxField
      control={control}
      name={name}
      useId={name}
      disabled={_isNil(dataType) || dataType.value !== 'string' || disabled}
    />
  );
}
