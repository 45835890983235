import {  Stack } from '@bedrock-layout/primitives';
import _isNil from 'lodash/isNil';
import type {
  Ref,
} from 'react';
import { forwardRef, useEffect, useRef } from 'react';
import AceEditor from 'react-ace';
import { addCompleter } from 'ace-builds/src-noconflict/ext-language_tools';
import modeText from "ace-builds/src-noconflict/mode-text";
import githubTheme from "ace-builds/src-noconflict/theme-github";
import { config } from "ace-builds";
import { GoAlert } from 'react-icons/go';

import { NectedSuggestionModel } from '../../NectedEditor';
import { FontWeight, Typography } from '../../Typography';
import { EditorContainer } from './SearchableTextInput.styled';


export type SearchableTextInputProps = {
  hasError?: boolean;
  id?: string;
  name?: string;
  updateValue?: (value: string) => void;
  placeholder?: string;
  value?: string;
  options?: NectedSuggestionModel[];
  label?: string;
  showError?: boolean;
  readOnly?: boolean;
  showErrorIcon?: boolean;
  labelFontWeight?: FontWeight;
};

config.setModuleUrl("ace/mode/java", modeText);
config.setModuleUrl("ace/theme/github", githubTheme);

export const SearchableTextInput = forwardRef(
  (
    {
      placeholder,
      value,
      name,
      hasError = false,
      updateValue,
      label,
      options = [],
      showError = true,
      readOnly = false,
      showErrorIcon = true,
      labelFontWeight,
    }: SearchableTextInputProps,
    ref: Ref<HTMLInputElement>
  ) => {
    const editorRef = useRef<any>(null);
    const editor = editorRef.current?.editor

    const completers = {
      getCompletions: function (
        editor: any,
        session: any,
        pos: any,
        prefix: any,
        callback: (...args: any) => void
      ) {
        callback(null, options);
      },
      getExactMatch: true,
    };

    useEffect(() => {
      addCompleter(completers);
    }, [JSON.stringify(options)]);

    return (
      <Stack gutter=".8rem">
        {!_isNil(label) && (
          <label>
            <Typography fontWeight={labelFontWeight}>{label}</Typography>
          </label>
        )}
        <EditorContainer align="center" gutter="1.1rem" stretch="start">
          <AceEditor
            ref={editorRef}
            mode="text"
            theme="github"
            placeholder={placeholder}
            width="100%"
            fontSize={14}
            maxLines={1}
            showPrintMargin={false}
            highlightActiveLine={false}
            enableBasicAutocompletion={true}
            enableLiveAutocompletion={true}
            showGutter={false}
            readOnly={readOnly}
            value={value}
            onChange={(value, e) => {
              if (e.action === 'insert' && e.lines.length > 1) {
                editor.getSession().removeFullLines(1, e.end.row)
              } else {
                if (typeof updateValue === 'function') {
                  updateValue(value);
                }
              }
            }}
            name={name}
          />
          {hasError && showError && showErrorIcon && (
            <span>
              <GoAlert fill="var(--color-fireEngineRed)" size={20} />
            </span>
          )}
        </EditorContainer>
      </Stack>
    );
  }
);

SearchableTextInput.displayName = 'SearchableTextInput';
