import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';

import { IntervalTimerId } from '../../../types';
import { MAX_POLLING_TIMER } from '../../../utils/constant';

export type usePollingProps = {
  functionToExecute: (args?: any) => void;
  args: any;
  timeInterval: number;
};

let timerId: IntervalTimerId | null = null;

export function usePolling({
  functionToExecute,
  args,
  timeInterval = 4000,
}: usePollingProps) {
  const [isPolling, setIsPolling] = useState(false);
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    if (!isPolling || counter >= MAX_POLLING_TIMER) {
      if (!_isNil(timerId)) {
        clearInterval(timerId);
      }

      setCounter(0);
    } else {
      timerId = setInterval(() => {
        if (counter < MAX_POLLING_TIMER && isPolling) {
          setCounter((counter) => counter + timeInterval);
        } else {
          setIsPolling(false);

          if (!_isNil(timerId)) clearInterval(timerId);
          setCounter(0);
        }
      }, timeInterval);
    }
  }, [isPolling]);

  useEffect(() => {
    if (counter < MAX_POLLING_TIMER && isPolling) {
      functionToExecute(args);
    }
  }, [counter, isPolling]);

  useEffect(() => {
    return () => {
      setIsPolling(false);
      setCounter(0);

      if (!_isNil(timerId)) clearInterval(timerId);
    };
  }, []);

  return {
    isPolling,
    setIsPolling,
  };
}
