import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import {
  UseControllerProps,
  UseFormSetValue,
  useController,
} from 'react-hook-form';
import { MdEdit } from 'react-icons/md';
import { Typography, useLayer } from 'ui';

import { sendEventToGTMType } from '../../../types';
import { ErrorPopoverPositioned } from '../../SimpleRule/Error/ErrorPopoverPositioned';
import { decisionTableNodesAtom } from '../DecisionTable';
import { JsNodeSheet } from '../JsNodeSheet/JsNodeSheet';
import { JsPill } from './TableNodes.styled';

export type JsNodePillProps = Omit<UseControllerProps, 'name'> & {
  ruleId: string;
  setValue: UseFormSetValue<any>;
  index: number;
  rowKey: string;
  rowIndex: number;
  handleSendEventToGTM?: (obj: sendEventToGTMType) => void;
};

export function JsNodePill({
  ruleId,
  setValue,
  index,
  rowKey,
  rowIndex,
  control,
  handleSendEventToGTM,
}: JsNodePillProps) {
  const { open: openCodeNode } = useLayer(
    <JsNodeSheet
      ruleId={ruleId}
      handleChangeValue={setValue}
      index={index}
      rowKey={rowKey}
      rowIndex={rowIndex}
    />
  );

  const [nodes] = useAtom(decisionTableNodesAtom);

  const ruleName = nodes[ruleId]?.name ?? '';

  const nodeName = `rows.${rowIndex}.${rowKey}.condition.${index}.${ruleId}`;

  const { fieldState } = useController({ name: nodeName, control });

  const error = fieldState.error?.message;

  const handleClick = () => {
    openCodeNode();

    if (typeof handleSendEventToGTM === 'function') {
      handleSendEventToGTM({
        action: 'edit',
        element: 'rhs_value',
        actionName: 'jsCondition',
      });
    }
  };

  return (
    <JsPill onClick={handleClick} padding={[2, 12]}>
      {!_isNil(error) && <ErrorPopoverPositioned error={error} />}

      <Typography>
        {_isNil(ruleName) || _isEmpty(ruleName) ? 'Untitled' : ruleName}
      </Typography>

      <MdEdit />
    </JsPill>
  );
}
