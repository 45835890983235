import _isNil from 'lodash/isNil';
import { useEffect, useRef, useState } from 'react';
import { RiArrowDropDownFill } from 'react-icons/ri';
import { ButtonWithPopover, PopoverMethods } from 'ui';
import { ButtonOptionItemType } from 'ui/src/Button/ButtonWithPopover';

import { ButtonOptionsIdsType } from '../../ButtonWithOptions/type';
import { ClearCacheButtonStyled } from './ClearCacheButton.styled';

type ClearCacheButtonProps = {
  buttonOptionList?: ButtonOptionItemType[];
  defaultSelectedOptionId?: ButtonOptionsIdsType;
  id?: string;
  onClick?: (selectedOptionId: string) => void;
};

export default function ClearCacheButton({
  buttonOptionList = [],
  defaultSelectedOptionId,
  id,
  onClick,
}: ClearCacheButtonProps) {
  const ref = useRef<PopoverMethods>(null);
  const [selectedOptionId, setSelectedOptionId] = useState<string>();
  const selectedOption =
    buttonOptionList.find((option) => option.id === selectedOptionId) ??
    buttonOptionList[0];

  useEffect(() => {
    if (!_isNil(defaultSelectedOptionId)) {
      setSelectedOptionId(defaultSelectedOptionId);
    }
  }, [defaultSelectedOptionId]);

  const onOptionChange = (optionId: string) => {
    setSelectedOptionId(optionId);
    ref?.current?.hide();
  };

  const handleButtonClick = () => {
    if (typeof onClick === 'function' && !_isNil(selectedOption)) {
      onClick(selectedOption.id);
    }
  };

  return (
    <ButtonWithPopover
      trailingIcon={
        <RiArrowDropDownFill size={31} color="var(--color-black)" />
      }
      buttonOptionList={buttonOptionList}
      onOptionChange={onOptionChange}
      appearance="borderedNeutral"
      onClick={handleButtonClick}
      ref={ref}
      selectedOptionId={selectedOption.id + (id ?? '')}
      hoverText={selectedOption?.subTitle}
    >
      <ClearCacheButtonStyled>{selectedOption?.label}</ClearCacheButtonStyled>
    </ButtonWithPopover>
  );
}
