import { PadBox } from '@bedrock-layout/padbox';
import styled from 'styled-components';

export const DurationInputContainer = styled.div`
  max-block-size: 30rem;
  inline-size: 32rem;
  overflow-y: auto;
`;

export const ContainerStyled = styled(PadBox)`
  min-block-size: 2.5rem;
  background-color: var(--color-solitudeSecondary);
  border-radius: 4px;
  cursor: pointer;
  position: relative;
`;
