import { Inline } from '@bedrock-layout/primitives';
import _capitalize from 'lodash/capitalize';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import type { UseControllerProps, UseFormSetValue } from 'react-hook-form';
import { useWatch } from 'react-hook-form';
import { FiChevronDown } from 'react-icons/fi';
import { Button, RulesPopoverField, Typography } from 'ui';

import type { FieldsByID } from '../../../../pages/Rules/types';
import { AttributeSelectionLauncher } from './DatasetAttributePopover.styled';

type DatasetAttributePopoverProps = Omit<UseControllerProps<any>, 'name'> & {
  setValue: UseFormSetValue<any>;
  index: number;
  isDisabled: boolean;
  selectedDataSets: string[];
  dataSetFieldsById: FieldsByID;
  isFilterBasedOnDataType?: boolean;
};

export function DatasetAttributePopover({
  control,
  setValue,
  index,
  isDisabled,
  isFilterBasedOnDataType = false,
  selectedDataSets,
  dataSetFieldsById,
}: DatasetAttributePopoverProps) {
  const propertyName = `attributes.${index}.attribute`;

  const sourceTypeName = `attributes.${index}.sourceType`;
  const dataTypeName = `attributes.${index}.dataType`;

  const property = useWatch({ control, name: propertyName });
  const sourceType = useWatch({ control, name: sourceTypeName });

  const dataType = useWatch({ control, name: dataTypeName });

  const options =
    !_isNil(selectedDataSets[0]) &&
    !_isNil(dataSetFieldsById[selectedDataSets[0]])
      ? dataSetFieldsById[selectedDataSets[0]].fields.filter((field) => {
          if (isFilterBasedOnDataType && !_isNil(dataType)) {
            return field.type === dataType.value;
          }

          return field.type !== 'json';
        })
      : [];

  const updatePropertyAttributes = (currentProperty: string) => {
    setValue(propertyName, currentProperty);

    if (
      !_isNil(currentProperty) &&
      !_isEmpty(currentProperty) &&
      sourceType !== 'restAPI'
    ) {
      setValue(`attributes.${index}.sourceType`, 'dataSet');

      const selectedProperty = options.find(
        (option) => option.name === currentProperty
      );

      if (!_isNil(selectedProperty)) {
        setValue(
          `attributes.${index}.sampleValue`,

          selectedProperty.executedValue
        );

        setValue(`attributes.${index}.dataType`, {
          label: _capitalize(selectedProperty.type),
          value: selectedProperty.type,
        });
      }
    }
  };

  const launcher = (
    <AttributeSelectionLauncher
      padding="0.8rem"
      $isDisabled={isDisabled || sourceType !== 'dataSet'}
    >
      <Inline align="center" stretch="start">
        <Typography>
          {!_isNil(property) && !_isEmpty(property) && sourceType !== 'restAPI'
            ? options.find((data) => data.id === property)?.name
            : sourceType === 'dataSet'
            ? 'Map primary key'
            : '-'}
        </Typography>
        <FiChevronDown size={20} />
      </Inline>
    </AttributeSelectionLauncher>
  );

  const footer = (
    <Inline minItemWidth="100%">
      <Button
        onClick={() =>
          window.open(
            `/datasets/${selectedDataSets[0]}?type=edit&stage=staging&editor=${
              dataSetFieldsById[selectedDataSets[0]].type as string
            }`,
            window !== window.parent ? '_self' : '_blank'
          )
        }
        size="small"
      >
        Go to Data Source
      </Button>
    </Inline>
  );

  return (
    <RulesPopoverField
      launcher={launcher}
      options={options}
      control={control}
      name={`attributes.${index}.attribute`}
      disabled={isDisabled || sourceType !== 'dataSet'}
      placeholder="Execute and Test Data Source first"
      isErrorMessage
      footer={options.length === 0 ? footer : undefined}
      onChange={updatePropertyAttributes}
    />
  );
}
