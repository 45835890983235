import type { Control } from 'react-hook-form';
import { EditorMethods, NectedEditorField, NectedSuggestionModel } from 'ui';

import type { DatabaseName } from '../../pages/DataSets/types';
import { editorDomain } from '../../utils/constant';

type QueryEditorProps = {
  name: string;
  control?: Control<any>;
  customSuggestion?: string[];
  customSuggestionObj?: NectedSuggestionModel[];
  databaseName?: DatabaseName;
  disabled?: boolean;
  setValidity?: (state: boolean) => void;
  methods?: EditorMethods;
  handleGetExecutionValues?: (value: Record<string, any>) => void;
  execValues?: Record<string, any>;
  defaultValue?: string | null;
  resetValue?: string | null;
  onReset?: (value?: any) => void;
};

export function QueryEditor({
  name,
  control,
  databaseName = 'mysql',
  customSuggestion = [],
  disabled = false,
  customSuggestionObj = [],
  setValidity,
  methods = 'write',
  handleGetExecutionValues,
  execValues,
  defaultValue = '',
  resetValue,
  onReset,
}: QueryEditorProps) {
  switch (databaseName) {
    case 'mysql':
    case 'pgsql':
    case 'mongodb':
    case 'redshift':
    case 'sqlserver':
    case 'snowflake':
    case 'oracle':
      return (
        <NectedEditorField
          control={control}
          name={name}
          mode={databaseName}
          customSuggestions={customSuggestionObj}
          readOnly={disabled}
          methods={methods}
          onSetEditorValidity={setValidity}
          domain={editorDomain}
          handleGetExecData={handleGetExecutionValues}
          execValues={execValues}
          defaultValue={defaultValue}
          resetValue={resetValue}
          onReset={onReset}
        />
      );
    default:
      return null;
  }
}
