import { ApolloError } from '@apollo/client';
import { PadBox } from '@bedrock-layout/padbox';
import { Stack } from '@bedrock-layout/stack';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  Spinner,
  TextAreaField,
  Typography,
  toasts,
  useCurrentLayer,
} from 'ui';

import { checksumWarningAtom } from '../../../atom';
import type { CheckSumEntityNames } from '../../../types';
import { handleGetCheckSumByEntityName } from '../../../utils/common';
import { useRequestChangesEntity } from '../hooks/graphql/useRequestChangesEntity';
import { rejectFormSchema } from '../schema';
import type { ModalCommonProps } from '../type';
import {
  getChecksumMetaData,
  getEntityTypeForApi,
  getEntityTypeForChecksum,
} from '../utils/common';

type RejectChangesModalFormData = {
  title: string;
  description: string;
};
export function RejectChangesModal({
  entityType,
  entityId,
  entityName = '',
  entitySubType = '',
  handleEntityUpdate,
  title = 'Send Changes in draft',
}: ModalCommonProps) {
  const [, setShowChecksumPopup] = useAtom(checksumWarningAtom);
  const [isLoading, setIsLoading] = useState(false);

  const { close: closeModal } = useCurrentLayer();

  const [rejectChangesEntityQuery, { data, error }] =
    useRequestChangesEntity(entityType);

  const { control, handleSubmit } = useForm<RejectChangesModalFormData>({
    resolver: zodResolver(rejectFormSchema),
  });

  const handleRejectChanges = async (data: RejectChangesModalFormData) => {
    const { description = '' } = data;
    const checksumEntityName = getEntityTypeForChecksum(entityType);

    const payload = {
      id: entityId,
      entityType: getEntityTypeForApi(entityType),
      comment: description,
      checksum:
        handleGetCheckSumByEntityName(
          checksumEntityName as CheckSumEntityNames
        ) ?? '',
    };

    try {
      setIsLoading(true);
      await rejectChangesEntityQuery({
        variables: payload,
        fetchPolicy: 'no-cache',
      });
    } catch (error) {
    } finally {
      setIsLoading(false);
      closeModal();
    }
  };

  useEffect(() => {
    if (
      typeof handleEntityUpdate === 'function' &&
      !_isNil(data) &&
      !_isNil(data.requestChanges)
    ) {
      toasts.success(
        data.requestChanges.message ?? '',
        'entity request changes'
      );

      handleEntityUpdate(data.requestChanges);
    }
  }, [JSON.stringify(data)]);

  useEffect(() => {
    if (error instanceof ApolloError) {
      if (error?.graphQLErrors[0]?.extensions?.code === 'checksum_mismatched') {
        setShowChecksumPopup({
          showPopup: true,
          metaData: getChecksumMetaData({
            entityId,
            entityName,
            entityType,
            entitySubType,
          }),
        });
      } else {
        toasts.error(error.message, 'error');
      }
    }
  }, [error]);

  return (
    <Modal size="extraLarge" title={title} overflow="none">
      <form onSubmit={handleSubmit(handleRejectChanges)}>
        <ModalContent>
          <Stack as={PadBox} gutter="1.5rem" padding={['1.5rem', 0, '4rem', 0]}>
            <Stack gutter="0.8rem">
              <Typography name="heading3">Describe changes</Typography>
              <TextAreaField
                control={control}
                placeholder="Explain in detail"
                name="description"
                rows={4}
              />
            </Stack>
          </Stack>
        </ModalContent>
        <ModalFooter>
          <Button onClick={closeModal} appearance="neutral">
            Cancel
          </Button>
          <Button type="submit">
            {isLoading ? <Spinner size="extraSmall" /> : <>Send to Draft</>}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
