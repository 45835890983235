import { ApolloError } from '@apollo/client';
import { Stack } from '@bedrock-layout/stack';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { ReactFlowProvider } from 'reactflow';
import { Sheet, toasts, useLayer } from 'ui';

import {
  DynamicPanelContainer,
  EditorPanelContainer,
  SidePanelContainer,
} from '../../../../components/CommonStyles/CommonStyles.styled';
import { EntityAccessBlock } from '../../../../components/EntityAccessBlock/EntityAccessBlock';
import { EntityDependencyUsage } from '../../../../components/EntityDependencyUsage/EntityDependencyUsage';
import { EntityUnderReview } from '../../../../components/EntityUnderReview/EntityUnderReview';
import { FAQMenu } from '../../../../components/FAQMenu';
import { getValueFromObject } from '../../../../components/Listing/utils';
import { Panel } from '../../../../components/Panel/Panel';
import { VersionControl } from '../../../../components/VersionControl/VersionControl';
import { tabList } from '../../../../components/VersionControl/constant';
import { getUserState } from '../../../../hooks/getUserState';
import { useHandleRequests } from '../../../../hooks/useHandleRequests';
import { useSendEventToGTM } from '../../../../hooks/useSendEventToGTM';
import { useUpdateAttributesOnResize } from '../../../../hooks/useUpdateAttributesOnResize';
import { useUpdateMappedConnectorsData } from '../../../../hooks/useUpdateMappedConnectorsData';
import type {
  DependencyUsingMapType,
  UsedConnectorMappingInEntityType,
} from '../../../../types';
import {
  handleGetCheckSumByEntityName,
  handleSetCheckSumByEntityName,
  isOnboardingCompleted,
  showGraphQlErrorToast,
} from '../../../../utils/common';
import {
  ENTITY_ID,
  addActionWorkflow,
  addDelayNode,
  addResponseNode,
  defineTriggerAndCI,
  testAndPublishWorkflow,
} from '../../../../utils/constant';
import { updateWidgetState } from '../../../Home/components/sub-components/UpdateWidgetState';
import { SaveType } from '../../../Rules/models';
import {
  activePanelWorkflowAtom,
  approvalInfoWorkflowAtom,
  changedNodeIdsAtom,
  isWorkflowLiveAtom,
  isWorkflowReadOnlyAtom,
  ruleListWorkflowAtom,
  vcListTabIndexWorkflowAtom,
  versionInfoWorkflowAtom,
  versionMappingWfInfoAtom,
  workflowAccessRoleAtom,
  workflowEdgesAtom,
  workflowEnvironmentAtom,
  workflowErrorByNodeAtom,
  workflowIdAtom,
  workflowNodeSavingAtom,
  workflowNodesAtom,
  workflowStaticUrlAtom,
  workflowStatusAtom,
} from '../../atoms/atoms';
import { useCreateWorkflow } from '../../hooks/graphql/useCreateWorkflow';
import { useDiscardWorkflow } from '../../hooks/graphql/useDiscardWorkflow';
import { useGetRuleList } from '../../hooks/graphql/useGetRuleList';
import { useGetWorkflowById } from '../../hooks/graphql/useGetWorkflowById';
import { useUpdateNameDesc } from '../../hooks/graphql/useUpdateNameDesc';
import { useUpdateNodesEdges } from '../../hooks/graphql/useUpdateNodesEdges';
import { useUpdateSettings } from '../../hooks/graphql/useUpdateSettings';
import { useUpdateWorkflow } from '../../hooks/graphql/useUpdateWorkflow';
import { useEditWorkflow } from '../../hooks/useEditWorkflow';
import { WorkflowNodeType, useOpenWorkflow } from '../../hooks/useOpenWorkflow';
import {
  filterWorkflowEdges,
  filterWorkflowNodes,
  getStartIdFromTrigger,
} from '../../utils/common';
import { validateWorkflowBeforeSubmit } from '../../utils/validations';
import { WorkflowSettingsSheet } from '../Sheets/WorkflowSettingsSheet/WorkflowSettingsSheet';
import { WorkflowTestSheet } from '../Sheets/WorkflowTestSheet/WorkflowTestSheet';
import { WorkflowEditor } from '../WorkflowEditor/WorkflowEditor';
import { WorkflowSettingsInfo } from '../WorkflowSettingsInfo/WorkflowSettingsInfo';
import { WorkflowSheetHeader } from '../WorkflowSheetHeader/WorkflowSheetHeader';
import { Form, WorkflowEditorContainer } from './WorkflowSheet.styled';

type WorkflowSheetProps = {
  id?: string;
  isLive?: boolean;
  commitId?: string;
};

export function WorkflowSheet({ id, isLive, commitId }: WorkflowSheetProps) {
  const navigate = useNavigate();
  const [workflowNodes, setWorkflowNodes] = useAtom(workflowNodesAtom);
  const [workflowEdges] = useAtom(workflowEdgesAtom);
  const [workflowId, setWorkflowId] = useAtom(workflowIdAtom);
  const [isWorkflowReadOnly] = useAtom(isWorkflowReadOnlyAtom);
  const [workflowStatus, setWorkflowStatus] = useAtom(workflowStatusAtom);
  const [, setWorkflowStaticUrl] = useAtom(workflowStaticUrlAtom);
  const [, setWorkflowErrorByNode] = useAtom(workflowErrorByNodeAtom);
  const [changedNodeIds, setChangedNodeIds] = useAtom(changedNodeIdsAtom);
  const [, setWorkflowEnvironment] = useAtom(workflowEnvironmentAtom);

  const [, setIsWorkflowLive] = useAtom(isWorkflowLiveAtom);
  const [approvalInfoWorkflow, setApprovalInfoWorkflow] = useAtom(
    approvalInfoWorkflowAtom
  );
  const [versionInfoWorkflow, setVersionInfoWorkflow] = useAtom(
    versionInfoWorkflowAtom
  );
  const [vcListTabIndex, setVcListTabIndex] = useAtom(
    vcListTabIndexWorkflowAtom
  );

  const [versionMappingInfo, setVersionMappingInfo] = useAtom(
    versionMappingWfInfoAtom
  );

  const [, setRuleListWorkflow] = useAtom(ruleListWorkflowAtom);
  const [workflowNodeSaving, setWorkflowNodeSaving] = useAtom(
    workflowNodeSavingAtom
  );
  const [accessRole] = useAtom(workflowAccessRoleAtom);

  const [searchParams] = useSearchParams();
  const type = searchParams.get('type');

  const [enableAutoSave, setEnableAutoSave] = useState(false);
  const [showEditor] = useState(true);
  const [activePanel, setActivePanel] = useAtom(activePanelWorkflowAtom);

  const [createWorkflow, { loading: isCreating }] = useCreateWorkflow();
  const [updateWorkflow, { loading: isModifying }] = useUpdateWorkflow();
  const [updateNameDesc, { loading: isUpdatingNameDesc }] = useUpdateNameDesc();
  const [updateNodesEdges, { loading: isUpdatingNodesEdges }] =
    useUpdateNodesEdges();
  const [updateSettings, { loading: isUpdatingSettings }] = useUpdateSettings();

  const [
    discardWorkflow,
    { data: discardWorkflowData, loading: isDiscarding },
  ] = useDiscardWorkflow();

  const { fetchMappedConnectorsData } = useUpdateMappedConnectorsData();

  const { sendEventToGTM } = useSendEventToGTM();

  const plan = JSON.parse(window.sessionStorage.getItem('userPlan') ?? '{}');

  const isUpdating =
    isModifying ||
    isUpdatingNameDesc ||
    isUpdatingNodesEdges ||
    isUpdatingSettings;

  useUpdateAttributesOnResize({
    attributes: ['height'],
    containerClassName: 'workflow-editor-container',
    dependentClassNameList: [
      'workflow-header-container',
      'workflow-settings-info',
    ],
  });

  const { control, handleSubmit, watch, setValue, setError } = useForm<any>({
    defaultValues: {
      name: 'Untitled',
      production: {},
      isEnabled: true,
    },
  });

  const isEnabled = watch('isEnabled');

  // eslint-disable-next-line
  const { createdAt, publishedAt, message, ...currentData } = watch();

  useEffect(() => {
    setWorkflowEnvironment('production');

    if (!_isNil(id) && type !== 'clone') {
      setWorkflowId(id);
    }
  }, [id, type]);

  const {
    oldNodes,
    oldEdges,
    getWorkflowByIdOnPublish,
    handleGetWorkflowAfterStateTransition,
  } = useEditWorkflow({
    id,
    setValue,
    discardWorkflowData,
    isLive,
    commitId,
  });
  const [getWorkflowById] = useGetWorkflowById();

  const {
    closeWorkflowSheet,
    edges,
    nodes,
    onEdgesChange,
    onNodesChange,
    // eslint-disable-next-line
    reactFlowInstance,
    // eslint-disable-next-line
    setEdges,
    // eslint-disable-next-line
    setNodes,
    setReactFlowInstance,
    onConnect,
    onNodeDragStop,
    updateNodesWithMethods,
  } = useOpenWorkflow({ oldEdges, oldNodes });

  const workflowNodesUpdated = filterWorkflowNodes(workflowNodes);

  const checkAllNodesHasEntityId = (nodes: WorkflowNodeType[]) => {
    let hasId = true;

    nodes.forEach((node) => {
      if (
        !['trigger', 'addNode', 'arrowNode'].includes(node.type ?? '') &&
        (_isNil(node.data.entityId) || _isEmpty(node.data.entityId))
      ) {
        hasId = false;
      }
    });

    return hasId;
  };

  const workflowEdgesUpdated = filterWorkflowEdges(
    workflowEdges,
    workflowNodes
  );

  const { openWithProps: openWorkflowTest } = useLayer(<WorkflowTestSheet />);

  const updateOnboardingSteps = (nodes: WorkflowNodeType[]) => {
    const triggerNode = nodes.find((n) => n.type === 'trigger');
    const delayNode = nodes.find((n) => n.type === 'delayNode');
    const responseNode = nodes.find((n) => n.type === 'responseNode');
    const actionNode = nodes.find(
      (n) => n.type === 'dbNode' || n.type === 'restApiNode'
    );

    if (!_isNil(triggerNode)) {
      if (!isOnboardingCompleted(defineTriggerAndCI)) {
        updateWidgetState(defineTriggerAndCI)
          .then(() => {
            void getUserState();
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.log(err);
          });
      }
    }

    if (!_isNil(delayNode)) {
      if (!isOnboardingCompleted(addDelayNode)) {
        updateWidgetState(addDelayNode)
          .then(() => {
            void getUserState();
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.log(err);
          });
      }
    }

    if (!_isNil(responseNode)) {
      if (!isOnboardingCompleted(addResponseNode)) {
        updateWidgetState(addResponseNode)
          .then(() => {
            void getUserState();
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.log(err);
          });
      }
    }

    if (!_isNil(actionNode)) {
      if (!isOnboardingCompleted(addActionWorkflow)) {
        updateWidgetState(addActionWorkflow)
          .then(() => {
            void getUserState();
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.log(err);
          });
      }
    }
  };

  const triggerNode =
    workflowNodesUpdated
      .filter((n) => n.type === 'trigger')
      .map((n) => n.id)[0] ?? '';

  const handlePostUpdate = (response: Record<string, any>) => {
    if (!_isNil(response.data) && !_isNil(response.data.updateWorkflow)) {
      handleSetCheckSumByEntityName(
        'workflow',
        response.data.updateWorkflow.checksum
      );

      setWorkflowStatus(response.data.updateWorkflow.status);
      setWorkflowStaticUrl(response.data.updateWorkflow.staticUrl);

      setVersionInfoWorkflow(response.data.updateWorkflow.versionInfo);
      setApprovalInfoWorkflow(response.data.updateWorkflow.approvalInfo);
      setIsWorkflowLive(response.data.updateWorkflow.isLive);

      setVersionMappingInfo(
        response.data.updateWorkflow.dependencyMap?.map(
          (currMapping: DependencyUsingMapType) => ({
            entityId: currMapping.id,
            type: currMapping.type,
            version: currMapping.version,
            nodeId: currMapping.nodeId,
          })
        ) ?? []
      );
    }

    setTimeout(() => {
      void updateOnboardingSteps(workflowNodesUpdated);
    }, 2000);
  };

  const validateUsedConnectors = async () => {
    const currUsedConnectors: UsedConnectorMappingInEntityType = {};

    workflowNodes.forEach((node: any) => {
      if (
        ['restApiNode', 'dbNode', 'gSheetNode'].includes(node.data.nodeType)
      ) {
        currUsedConnectors[node.data.entityId] = {
          status: true,
          source: ['node'],
        };
      }
    });

    const response = await fetchMappedConnectorsData();
    let error = false;

    if (!_isNil(response)) {
      const connectorList = response.getConnector.data;

      for (let i = 0; i < connectorList.length; i++) {
        const connector = connectorList[i];

        if (connector.id in currUsedConnectors) {
          error =
            !(connector.staging.isTested as boolean) ||
            !(connector.staging.isPublish as boolean);

          if (error) {
            toasts.error(
              'Seems integration used in the node is not published with staging config, Publish your integration staging config before testing this workflow.',
              'wf-connector-staging-not-published'
            );
            break;
          }
        }
      }
    }

    return error;
  };

  // eslint-disable-next-line
  const onSubmit = async (
    data: any,
    isTest: boolean = false,
    type: SaveType = 'all'
  ) => {
    setWorkflowNodeSaving(true);

    const firstNode = getStartIdFromTrigger(
      workflowNodesUpdated,
      workflowEdgesUpdated,
      workflowNodesUpdated.find((n) => n.type === 'trigger')?.id ?? ''
    );

    const { isSaveValid, isTestValid, errorNodes } =
      validateWorkflowBeforeSubmit(
        data,
        setError,
        workflowNodesUpdated,
        workflowEdgesUpdated
      );

    let hasConnectorErr = true;

    if (isTest) {
      hasConnectorErr = await validateUsedConnectors();
    }

    if (isTest && isTestValid && !hasConnectorErr) {
      setWorkflowNodeSaving(false);

      return openWorkflowTest({
        onTestSuccess: async () => {
          try {
            const data = await getWorkflowById({
              variables: { id: workflowId },
            });
            const newNodes = data.data.getWorkflow.data[0].nodes;

            setWorkflowStatus((prev) =>
              !_isNil(data.data.getWorkflow.data[0])
                ? data.data.getWorkflow.data[0].status
                : prev
            );
            void setChangedNodeIds([]);

            handleSetCheckSumByEntityName(
              'workflow',
              data.data.getWorkflow.data[0].checksum
            );

            setIsWorkflowLive(data.data.getWorkflow.data[0]?.isLive ?? false);

            setNodes(newNodes);
            setWorkflowNodes(newNodes);
          } catch (error) {}
        },
      });
    } else if (isTest && !isTestValid) {
      setWorkflowNodeSaving(false);
      setWorkflowErrorByNode(
        errorNodes.reduce((acc, curr) => {
          return {
            ...acc,
            [curr.id]: curr,
          };
        }, {})
      );
      // eslint-disable-next-line
      return;
    } else if (isTest && hasConnectorErr) {
      setWorkflowNodeSaving(false);

      return;
    }

    if (!isSaveValid) {
      removeRequest();
      setWorkflowNodeSaving(false);
      // eslint-disable-next-line
      return;
    }

    if (workflowNodesUpdated.length === 0) {
      removeRequest();
      setWorkflowNodeSaving(false);
      // eslint-disable-next-line
      return;
    }

    try {
      if (_isNil(workflowId)) {
        const response = await createWorkflow({
          variables: {
            name: data.name,
            nodes: workflowNodesUpdated,
            edges: workflowEdgesUpdated,
            description: '',
            startNode: firstNode,
            isEnabled: data.isEnabled,
            settings: data.production,
            trigger: {
              apiNode:
                workflowNodesUpdated
                  .filter((n) => n.type === 'trigger')
                  .map((n) => n.id)[0] ?? '',
            },
            dependencyMap: versionMappingInfo,
          },
        });
        void updateOnboardingSteps(workflowNodesUpdated);
        setWorkflowId(response.data.createWorkflow.id);

        setWorkflowStaticUrl(response.data.createWorkflow.staticUrl);

        handleSetCheckSumByEntityName(
          'workflow',
          response.data.createWorkflow.checksum
        );

        setWorkflowStatus(response.data.createWorkflow.status);

        setValue('createdAt', response.data.createWorkflow.createdAt);

        setVersionInfoWorkflow(response.data.createWorkflow.versionInfo);

        setVersionMappingInfo(
          response.data.createWorkflow.dependencyMap?.map(
            (currMapping: DependencyUsingMapType) => ({
              entityId: currMapping.id,
              type: currMapping.type,
              version: currMapping.version,
              nodeId: currMapping.nodeId,
            })
          ) ?? []
        );
      } else if (
        ((workflowNodesUpdated.length > 1 && workflowEdges.length >= 1) ||
          workflowNodesUpdated.length === 1) &&
        checkAllNodesHasEntityId(workflowNodesUpdated)
      ) {
        if (type === 'all') {
          const response = await updateWorkflow({
            variables: {
              id: workflowId,
              name: data.name,
              nodes: workflowNodesUpdated,
              edges: workflowEdgesUpdated,
              description: data.description,
              editMode: data.editMode,
              startNode: firstNode,
              checksum: handleGetCheckSumByEntityName('workflow') ?? '',
              isEnabled: data.isEnabled,
              staging: data.staging,
              production: data.production,
              trigger: {
                apiNode:
                  workflowNodesUpdated
                    .filter((n) => n.type === 'trigger')
                    .map((n) => n.id)[0] ?? '',
              },
              dependencyMap: versionMappingInfo,
            },
          });

          handlePostUpdate(response);
        } else if (type === 'nameDesc') {
          const response = await updateNameDesc({
            variables: {
              id: workflowId,
              name: data.name,
              description: data.description,
              editMode: data.editMode,
              checksum: handleGetCheckSumByEntityName('workflow') ?? '',
              dependencyMap: versionMappingInfo,
            },
          });

          handlePostUpdate(response);
        } else if (type === 'settings') {
          const response = await updateSettings({
            variables: {
              id: workflowId,
              editMode: data.editMode,
              checksum: handleGetCheckSumByEntityName('workflow') ?? '',
              settings: data.production,
              isEnabled: data.isEnabled,
              dependencyMap: versionMappingInfo,
            },
          });

          handlePostUpdate(response);
        } else if (type === 'nodesEdges') {
          const response = await updateNodesEdges({
            variables: {
              id: workflowId,
              nodes: workflowNodesUpdated,
              edges: workflowEdgesUpdated,
              editMode: data.editMode,
              startNode: firstNode,
              trigger: {
                apiNode:
                  workflowNodesUpdated
                    .filter((n) => n.type === 'trigger')
                    .map((n) => n.id)[0] ?? '',
              },
              checksum: handleGetCheckSumByEntityName('workflow') ?? '',
              dependencyMap: versionMappingInfo,
            },
          });

          handlePostUpdate(response);
        }
      }
    } catch (error) {
      if (error instanceof Error) {
        toasts.error(error.message, 'error');
      }
    }

    removeRequest();

    setWorkflowNodeSaving(false);

    setChangedNodeIds([]);
  };

  // eslint-disable-next-line
  const submitForm = handleSubmit(async (data) => await onSubmit(data));
  const submitFormNameDesc = handleSubmit(
    async (data) => await onSubmit(data, false, 'nameDesc')
  );
  const submitFormNodesEdges = handleSubmit(
    async (data) => await onSubmit(data, false, 'nodesEdges')
  );

  const submitFormSettings = handleSubmit(
    async (data) => await onSubmit(data, false, 'settings')
  );

  const handleTestSubmit = handleSubmit(
    async (data) => await onSubmit(data, true)
  );

  const handleEditButton = handleSubmit(
    async (data) => await onSubmit({ ...data, editMode: true })
  );
  const isMutating = isCreating || isUpdating;

  const { appendRequest, removeRequest } = useHandleRequests({
    onResolve: async (resArgs: { type: string }) => {
      if (resArgs.type === 'nameDesc') {
        await submitFormNameDesc();
      } else if (resArgs.type === 'settings') {
        await submitFormSettings();
      } else if (resArgs.type === 'nodesEdges') {
        await submitFormNodesEdges();
      } else {
        await submitForm();
      }
    },
    hasActivity: isMutating,
  });

  // This is the auto-save functionality
  useEffect(() => {
    const submitTimeout: ReturnType<typeof setTimeout> = setTimeout(() => {
      if (!isWorkflowReadOnly || type === 'clone') {
        if (!enableAutoSave) {
          setEnableAutoSave(true);
        }

        if ((enableAutoSave || type === 'clone') && _isNil(workflowId)) {
          appendRequest({ type: 'all' });
        }
      }
    }, 1000);

    return () => {
      if (!_isNil(submitTimeout)) {
        clearTimeout(submitTimeout);
      }
    };
  }, [
    JSON.stringify(workflowNodes.length),
    JSON.stringify(currentData),
    JSON.stringify(versionMappingInfo),
    isWorkflowReadOnly,
  ]);

  useEffect(() => {
    const submitTimeout: ReturnType<typeof setTimeout> = setTimeout(() => {
      if (enableAutoSave && workflowNodes.length > 0) {
        appendRequest({ type: 'nodesEdges' });
      }
    }, 1000);

    return () => {
      if (!_isNil(submitTimeout)) {
        clearTimeout(submitTimeout);
      }
    };
  }, [
    JSON.stringify(changedNodeIds.length),
    JSON.stringify(workflowNodes.length),
    JSON.stringify(versionMappingInfo),
  ]);

  useEffect(() => {
    const submitTimeout: ReturnType<typeof setTimeout> = setTimeout(() => {
      if (enableAutoSave) {
        appendRequest({ type: 'triggers' });
      }
    }, 1000);

    return () => {
      if (!_isNil(submitTimeout)) {
        clearTimeout(submitTimeout);
      }
    };
  }, [triggerNode]);

  useEffect(() => {
    const submitTimeout: ReturnType<typeof setTimeout> = setTimeout(() => {
      if (enableAutoSave) {
        appendRequest({ type: 'settings' });
      }
    }, 1000);

    return () => {
      if (!_isNil(submitTimeout)) {
        clearTimeout(submitTimeout);
      }
    };
  }, [
    JSON.stringify(currentData.staging),
    JSON.stringify(currentData.production),
    isEnabled,
  ]);

  useEffect(() => {
    const submitTimeout: ReturnType<typeof setTimeout> = setTimeout(() => {
      if (enableAutoSave) {
        appendRequest({ type: 'nameDesc' });
      }
    }, 1000);

    return () => {
      if (!_isNil(submitTimeout)) {
        clearTimeout(submitTimeout);
      }
    };
  }, [
    JSON.stringify(currentData.name),
    JSON.stringify(currentData.description),
  ]);

  useEffect(() => {
    const updatedVcListTabIndex = tabList.findIndex(
      (tabObj) => tabObj.value === workflowStatus
    );
    setVcListTabIndex(updatedVcListTabIndex !== -1 ? updatedVcListTabIndex : 0);
  }, [workflowStatus]);

  const handleDiscardWorkflow = async () => {
    if (!_isNil(workflowId)) {
      const checksum = handleGetCheckSumByEntityName('workflow');

      try {
        await discardWorkflow({
          variables: { workflowId, checksum: checksum ?? '' },
        });

        setEnableAutoSave(false);
        toasts.success(
          'Workflow reverted to live version successfully',
          'success'
        );
      } catch (err) {}
    }
  };

  const handleUpdateErrorNodes = (error: ApolloError) => {
    Object.keys(error?.graphQLErrors[0].extensions?.nodes ?? {}).forEach(
      (n: string) => {
        const currentNode = workflowNodes.find((node) => node.data.name === n);
        const node = (error?.graphQLErrors[0].extensions?.nodes as any)[n];

        if (currentNode != null) {
          setWorkflowErrorByNode((prev) => ({
            ...prev,
            [currentNode.id]: {
              id: currentNode.id,
              message: node.message ?? 'Please publish the rule',
            },
          }));
        }
      }
    );
  };

  const handleAfterWorkflowIsPublished = async () => {
    try {
      sendEventToGTM({
        event: 'workflow',
        source: 'listing',
        element: '',
        action: 'publish_click',
        type: '',
      });

      if (!isOnboardingCompleted(testAndPublishWorkflow)) {
        updateWidgetState(testAndPublishWorkflow)
          .then(() => {
            void getUserState();
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.log(err);
          });
      }

      setEnableAutoSave(false);

      setWorkflowErrorByNode({});

      await getWorkflowByIdOnPublish({
        variables: {
          id: workflowId,
        },
        fetchPolicy: 'no-cache',
      });
    } catch (error) {
      if (error instanceof ApolloError) {
        showGraphQlErrorToast(error);
      }
    }
  };

  const handleFetchWorkflow = () => {
    setEnableAutoSave(false);
    void handleGetWorkflowAfterStateTransition();
  };

  const handleEntityUpdate = (data: Record<string, any>) => {
    if (!_isNil(setValue) && !_isNil(data)) {
      Object.keys(data).forEach((key) => {
        if (key === 'checksum') {
          handleSetCheckSumByEntityName('workflow', data.checksum);
        } else if (key === 'status') {
          setWorkflowStatus(data.status);
        } else if (key === 'entityPublished') {
          void handleAfterWorkflowIsPublished();
        } else if (key === 'workflowNodesError') {
          void handleUpdateErrorNodes(data.workflowNodesError);
        } else if (key === 'discardEntity') {
          void handleDiscardWorkflow();
        } else if (key === 'formData') {
          const reviewFormData = data.formData.requestReview;

          if (!_isNil(reviewFormData)) {
            setApprovalInfoWorkflow(reviewFormData);
          }
        } else if (key === 'fetchEntity') {
          void handleFetchWorkflow();
        } else {
          setValue(key, data[key]);
        }
      });
    }
  };

  const [getRuleList] = useGetRuleList();

  const handleGetRuleList = async () => {
    try {
      const { data } = await getRuleList({
        variables: {
          page: 1,
          perPage: 500,
        },
        fetchPolicy: 'no-cache',
      });

      if (!_isNil(data)) {
        setRuleListWorkflow(data.getRule.data);
      }
    } catch {}
  };

  useEffect(() => {
    void handleGetRuleList();
  }, []);

  return (
    <Sheet
      size="large"
      onClose={() => {
        if (workflowNodeSaving || isCreating || isModifying || isDiscarding) {
          return toasts.info('Save is in progress', 'workflow-saving');
        }
        setChangedNodeIds([]);
        setWorkflowId(null);

        setApprovalInfoWorkflow(undefined);
        setVersionInfoWorkflow(undefined);
        setActivePanel(undefined);
        setVersionMappingInfo(undefined);

        if (window !== window.parent) {
          window.history.back();
        } else {
          navigate('/workflow');
        }
        closeWorkflowSheet();
      }}
    >
      <ReactFlowProvider>
        <Form>
          <EntityAccessBlock isVisible={accessRole === 'restricted'} />

          <Stack gutter={0}>
            <WorkflowSheetHeader
              onPublish={() => {}}
              control={control}
              isLoading={isCreating || isModifying || isDiscarding}
              onTest={handleTestSubmit}
              onEdit={handleEditButton}
              setValue={setValue}
              handleEntityUpdate={handleEntityUpdate}
              id={workflowId ?? ''}
              handleFetchWorkflow={handleFetchWorkflow}
            />
            <WorkflowSettingsInfo id={workflowId} />
          </Stack>
          <WorkflowEditorContainer
            className="workflow-editor-container"
            gutter={0}
          >
            <EditorPanelContainer className="workflow-editor-panel">
              <WorkflowEditor
                nodes={nodes}
                edges={edges}
                onConnect={onConnect}
                onEdgesChange={onEdgesChange}
                onNodesChange={onNodesChange}
                setReactFlowInstance={setReactFlowInstance}
                onNodeDragStop={onNodeDragStop}
                setEdges={setEdges}
                setNodes={setNodes}
                showEditor={showEditor}
                updateNodesWithMethods={updateNodesWithMethods}
              />
              <div
                style={{ position: 'absolute', bottom: '5rem', left: '5rem' }}
              >
                <EntityUnderReview
                  entityType="Workflow"
                  entityStatus={workflowStatus}
                />
              </div>
            </EditorPanelContainer>

            {activePanel === 'settings' && (
              <DynamicPanelContainer>
                <WorkflowSettingsSheet
                  setOriginalValue={setValue}
                  parentControl={control}
                />
              </DynamicPanelContainer>
            )}

            {activePanel === 'versionControl' && (
              <DynamicPanelContainer
                id={getValueFromObject(plan, 'plan.versionControl.componentId')}
                data-premium-component-id={getValueFromObject(
                  plan,
                  'plan.versionControl.componentId'
                )}
                data-premium-component-trigger={getValueFromObject(
                  plan,
                  'plan.versionControl.trigger'
                )}
                activePanel={activePanel}
              >
                <VersionControl
                  entityInfo={{
                    type: ENTITY_ID.workflow,
                    status: workflowStatus,
                    name: currentData.name,
                    id: workflowId ?? '',
                    accessRole,
                  }}
                  handleEntityUpdate={handleEntityUpdate}
                  currentTab={vcListTabIndex}
                  modalFormdata={{
                    requestReview: approvalInfoWorkflow,
                    publishModal: {
                      title: approvalInfoWorkflow?.title,
                    },
                  }}
                  updateTabIndex={setVcListTabIndex}
                />
              </DynamicPanelContainer>
            )}

            {activePanel === 'faqMenu' && (
              <DynamicPanelContainer activePanel={activePanel}>
                <FAQMenu />
              </DynamicPanelContainer>
            )}

            {activePanel === 'dependencyUsage' && (
              <DynamicPanelContainer
                id={getValueFromObject(plan, 'plan.dependencyMap.componentId')}
                data-premium-component-id={getValueFromObject(
                  plan,
                  'plan.dependencyMap.componentId'
                )}
                data-premium-component-trigger={getValueFromObject(
                  plan,
                  'plan.dependencyMap.trigger'
                )}
                activePanel={activePanel}
              >
                <EntityDependencyUsage
                  entityInfo={{
                    type: ENTITY_ID.workflow,
                    status: workflowStatus,
                    name: currentData.name ?? '',
                    id: workflowId ?? '',
                    version: versionInfoWorkflow?.currentVersion ?? 'draft',
                  }}
                />
              </DynamicPanelContainer>
            )}

            <SidePanelContainer>
              <Panel
                defaultActivePanel={activePanel}
                onPanelItemClick={setActivePanel}
              />
            </SidePanelContainer>
          </WorkflowEditorContainer>
        </Form>
      </ReactFlowProvider>
    </Sheet>
  );
}
