import { PadBox } from '@bedrock-layout/primitives';
import { Typography } from 'ui';

import { Container } from './Loader.styled';

export const Loader = () => {
  return (
    <PadBox padding="1rem" as={Container}>
      <Typography>Loading...</Typography>
    </PadBox>
  );
};
