import { BiUser } from 'react-icons/bi';

import { ProfileIconContainer } from './ProfileIcon.styled';

export function ProfileIconSkeleton() {
  return (
    <ProfileIconContainer align="center" justify="center">
      <BiUser />
    </ProfileIconContainer>
  );
}
