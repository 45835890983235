import { gql, useMutation } from '@apollo/client';

const deleteWorkflowMutation = gql`
  mutation deleteWorkflow($id: String!, $checksum: String!) {
    deleteWorkflow(id: $id, checksum: $checksum)
  }
`;

export function useDeleteWorkflow() {
  return useMutation(deleteWorkflowMutation);
}
