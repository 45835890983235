import { useLazyQuery } from '@apollo/client';

import { emptyQuery } from '../../../../utils/constant';
import { getRuleById as getDTById } from './useGetDecisionTableByID';
import { getRuleById as getSimpleRuleById } from './useGetRuleById';
import { getRuleSetById } from './useGetRuleSetById';

const getRuleQueryBasedOnType = (type?: string) => {
  switch (type) {
    case 'simpleRule':
      return getSimpleRuleById;
    case 'decisionTable':
      return getDTById;
    case 'ruleSet':
      return getRuleSetById;
    default:
      return emptyQuery;
  }
};

export function useGetRuleByIdAndType(type?: string) {
  return useLazyQuery(getRuleQueryBasedOnType(type), {
    fetchPolicy: 'no-cache',
  });
}
