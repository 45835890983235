import { Inline } from '@bedrock-layout/primitives';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { StackAsItem } from '../../../../components/layouts/Stack.styled';

export const RuleExecutionTestStyled = styled(StackAsItem)`
  border: 1px solid var(--color-whiteSmoke);
  border-radius: 4px;
  overflow-y: auto;
`;

export const ActionCount = styled(Inline)`
  inline-size: 2rem;
  block-size: 2rem;
  border-radius: 50%;
  background-color: var(--color-primary3);
  color: var(--color-primary7);
`;

export const ErrorIconContainer = styled(Inline)`
  block-size: 2rem;
`;

export const StyledLink = styled(Link)`
  font-size: 1.4rem;
  font-weight: 500;
  cursor: pointer;
  color: var(--color-dodgerBlue);
  text-decoration: underline;
  margin-left: 1rem;
`;
