import { Inline, Stack } from '@bedrock-layout/primitives';
import styled from 'styled-components';
import { Typography } from 'ui';

export const AccessTextContainer = styled(Inline)`
  padding: 0rem 1.5rem 1.5rem 1.5rem;
`;

export const EntityAccessContainer = styled(Stack)`
  min-block-size: 30rem;
`;

export const AccessHeaderContainer = styled.div`
  display: flex;
  background-color: var(--color-primary2);
`;

export const AccessHeader = styled(Inline)<{ $width: string }>`
  color: var(--color-charcoal);
  padding: 1rem;
  font-size: 1.4rem;
  justify-content: space-between;

  ${({ $width = '10rem' }) => `inline-size: ${$width};`}
`;

export const VerticalLine = styled.div<{ $height?: string }>`
  width: 1px;
  background-color: var(--color-darkGray);

  ${({ $height = '3rem' }) => `block-size: ${$height};`}
`;

export const EmptyState = styled(Inline)`
  margin: 4rem auto;
`;

export const AccessDetailsContainer = styled.div`
  display: flex;
  border-bottom: 1px solid var(--color-lightGray);

  &:nth-last-child() {
    border-bottom: none;
  }
`;

export const AccessDetailsCell = styled(Inline)<{ $width: string }>`
  padding: 1rem;
  justify-content: space-between;

  ${({ $width = '10rem' }) => `inline-size: ${$width};`}

  .workspace-role {
    text-transform: capitalize;
  }
`;

export const UserInfoContainer = styled(Inline)`
  flex: 1;
  padding-right: 1rem;

  .text-break {
    word-break: break-word;
  }
`;

export const ModalTitleStyled = styled(Typography)`
  text-align: center;
`;
