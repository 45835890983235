import { gql, useLazyQuery } from '@apollo/client';

import { emptyQuery } from '../../../../utils/constant';

const getSimpleRuleById = gql`
  query getRule($id: String) {
    getRule(id: $id) {
      data {
        id
        publishedAt
        status
        isLive
        checksum
        versionInfo {
          currentVersion
          currentLiveVersion
          lastVersion
        }
      }
    }
  }
`;

const getDecisionTableById = gql`
  query getRule($id: String) {
    getRule(id: $id) {
      data {
        id
        publishedAt
        status
        isLive
        checksum
        versionInfo {
          currentVersion
          currentLiveVersion
          lastVersion
        }
      }
    }
  }
`;

const getRuleSetById = gql`
  query getRule($id: String) {
    getRule(id: $id) {
      data {
        id
        publishedAt
        status
        isLive
        checksum
        versionInfo {
          currentVersion
          currentLiveVersion
          lastVersion
        }
      }
    }
  }
`;

const getRuleQueryBasdOnType = (ruleType: string) => {
  switch (ruleType) {
    case 'simpleRule':
      return getSimpleRuleById;
    case 'decisionTable':
      return getDecisionTableById;
    case 'ruleSet':
      return getRuleSetById;
    default:
      return emptyQuery;
  }
};

export function useGetRuleByIdOnPublish(ruleType: string) {
  return useLazyQuery<any>(getRuleQueryBasdOnType(ruleType), {
    fetchPolicy: 'no-cache',
  });
}
