import _isNil from 'lodash/isNil';
import type { IconType } from 'react-icons';
import { BiSolidQuoteAltRight } from 'react-icons/bi';
import { FaHashtag, FaRegCheckCircle } from 'react-icons/fa';
import { GoInbox } from 'react-icons/go';
import { LuBox, LuCalendarClock } from 'react-icons/lu';
import { MdOutlineListAlt } from 'react-icons/md';
import { RiJavascriptLine } from 'react-icons/ri';
import { SlCalculator } from 'react-icons/sl';

type IconByDataTypeProps = {
  dataType?: string;
  fontSize?: string | number;
  color?: string;
};

const iconByDt: Record<string, IconType> = {
  string: BiSolidQuoteAltRight,
  date: LuCalendarClock,
  dateTime: LuCalendarClock,
  restApi: LuBox,
  json: LuBox,
  list: MdOutlineListAlt,
  array: MdOutlineListAlt,
  numeric: FaHashtag,
  number: FaHashtag,
  boolean: FaRegCheckCircle,
  jsCondition: RiJavascriptLine,
  excelCondition: SlCalculator,
  generic: GoInbox,
};

export function IconByDataType({
  dataType,
  fontSize = 16,
  color = 'black',
}: IconByDataTypeProps) {
  if (_isNil(dataType)) {
    return null;
  }

  const Component = iconByDt[dataType];

  if (!_isNil(Component)) {
    return <Component fontSize={fontSize} color={color} />;
  }

  return null;
}
