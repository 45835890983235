import { gql, useLazyQuery } from '@apollo/client';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';

import { VersionMappingInfoType } from '../../../../types';
import { stringifyWithoutQuotes } from '../../../../utils/common';
import { emptyQuery } from '../../../../utils/constant';

const attachedRuleAttrResponseStructure = `
    data {
        id
        name
        type
        accessRole
        action {
          then {
            outputData
          }
          else {
            outputData
          }
        }
        type
        customInput
      }
`;

const generateQuery = (
  vMap: VersionMappingInfoType[] | undefined,
  parentInfo?: Record<string, any>
) => {
  if (_isNil(vMap) || _isEmpty(vMap)) {
    return emptyQuery;
  }

  let query = `query {`;

  vMap?.forEach((mappingObj, index) => {
    let subQuery =
      `ID_ALIAS: ` +
      `getRule(id: ID, live: LIVE, filters: FILTERS, parentInfo: PARENT_INFO)`;

    const filters: Record<string, any> = {};

    const { version, entityId } = mappingObj;

    if (
      !_isNil(version) &&
      !_isEmpty(version) &&
      !['live', 'draft'].includes(version)
    ) {
      filters.eq = { version };
    }

    const live =
      !_isNil(version) && !_isEmpty(version) ? version !== 'draft' : false;

    subQuery = subQuery.replace(
      'ID_ALIAS',
      `rule_${index as unknown as string}`
    );
    subQuery = subQuery.replace('ID', `"${entityId}"`);
    subQuery = subQuery.replace('LIVE', live as unknown as string);
    subQuery = subQuery.replace('FILTERS', stringifyWithoutQuotes(filters));

    if (!_isNil(parentInfo) && !_isEmpty(parentInfo)) {
      subQuery = subQuery.replace(
        'PARENT_INFO',
        stringifyWithoutQuotes(parentInfo ?? {})
      );
    }

    subQuery += `{ ${attachedRuleAttrResponseStructure}}`;
    query += subQuery;
  });

  query += '}';

  return gql`
    ${query}
  `;
};

export function useGetAttachedRuleAttributesById(
  vMap: VersionMappingInfoType[] | undefined,
  parentInfo?: Record<string, any>
) {
  return useLazyQuery<any, any>(generateQuery(vMap, parentInfo));
}
