import { useGetRuleByIdAndType } from '../../../pages/Rules/hooks/graphql/useGetRuleByIdAndType';
import { useGetWorkflowById } from '../../../pages/Workflow/hooks/graphql/useGetWorkflowById';

export const useGetEntityDataByIdAndType = (
  entityType: string,
  type?: string
) => {
  switch (entityType) {
    case 'rule':
      return useGetRuleByIdAndType(type);
    case 'workflow':
    default:
      return useGetWorkflowById();
  }
};
