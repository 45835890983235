import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import _isEmpty from 'lodash/isEmpty';
import { CiSearch } from 'react-icons/ci';
import { TextInput, Typography } from 'ui';

import { envMap } from '../../../../utils/constant';
import { BannerBox } from '../../../BannerBox';
import {
  ActionBlockContainer,
  IconContainer,
} from '../../../DemoRulePopup/demoRule.styled';
import {
  DemoRuleBlock,
  NoDemoRules,
  RuleSearchInputContainer,
  RuleTypePill,
  SearchContainerStyled,
} from '../../listing.styled';
import { ruleNameMap } from './rulesTemplate';

export type DemoObjProps = {
  name: string;
  id: string;
  data: {
    entityId: string;
    entity: string;
  };
  description: string;
  category: string;
};

type StartFromTemplateProps = {
  demoEntity: DemoObjProps[];
  selectedDemoObj: DemoObjProps | null;
  selectDemoEntity: (obj: DemoObjProps) => void;
  searchedText: string;
  setSearchedText: (value: string) => void;
};

export const StartFromTemplate = ({
  demoEntity,
  searchedText,
  setSearchedText,
  selectDemoEntity,
  selectedDemoObj,
}: StartFromTemplateProps) => {
  return (
    <>
      <RuleSearchInputContainer>
        <SearchContainerStyled align="center" gutter={0}>
          <PadBox
            padding={{
              left: '.8rem',
            }}
          >
            <CiSearch />
          </PadBox>
          <TextInput
            placeholder="Search your use case"
            value={searchedText}
            showOutline={false}
            name="usecase_search"
            onChange={(e) => {
              setSearchedText(e.target.value);
            }}
          />
        </SearchContainerStyled>
        {searchedText !== '' && (
          <Typography>Search Result for &quot;{searchedText}&quot;</Typography>
        )}
      </RuleSearchInputContainer>
      <ActionBlockContainer gutter="1rem">
        {demoEntity.map((demoObj: any, index: number) => (
          <PadBox
            key={index}
            onClick={() => {
              selectDemoEntity(demoObj);
            }}
            as={DemoRuleBlock}
            padding="1.5rem"
            gutter="1.5rem"
            selected={selectedDemoObj?.id === demoObj.id}
          >
            <IconContainer>
              <img
                src={
                  !_isEmpty(demoObj.icon)
                    ? `${envMap.VITE_ASSETS_URL}website/icons/${
                        demoObj.icon as string
                      }`
                    : `${envMap.VITE_ASSETS_URL}website/icons/${
                        demoObj.id as string
                      }.png`
                }
                alt="icon"
                style={{ maxWidth: '10rem' }}
              />
            </IconContainer>

            <Stack gutter={'1rem'}>
              <Inline>
                <Typography fontWeight={700} name={'heading4'}>
                  {demoObj.name}
                </Typography>
                {demoObj.data.entity === 'rule' && (
                  <RuleTypePill type={demoObj.category} name={'heading4'}>
                    {ruleNameMap[demoObj.category]}
                  </RuleTypePill>
                )}
              </Inline>

              <Typography name="secondary2" fontWeight={400}>
                {demoObj.description}
              </Typography>
            </Stack>
          </PadBox>
        ))}
        {demoEntity.length === 0 && searchedText !== '' && (
          <NoDemoRules>
            <BannerBox
              title="Usecase not found"
              subTitle="Get help with Solutions Expert"
              bannerImage="no_content.png"
              buttonProps={{
                buttonUrl: '',
                buttonText: '',
                buttonTarget: '',
              }}
            />
          </NoDemoRules>
        )}
      </ActionBlockContainer>
    </>
  );
};
