import { Inline, Stack } from '@bedrock-layout/primitives';
import { useRef } from 'react';
import {
  Control,
  UseFormSetValue,
  useController,
  useWatch,
} from 'react-hook-form';
import {
  Dataset,
  PopoverMethods,
  PopoverPanel,
  RulePopover,
  TooltipReact,
  Typography,
} from 'ui';

import { ParamElement } from './ParamElement';
import {
  ParameterContainer,
  RuleFieldContainer,
} from './ParameterPopover.styled';

type ParameterPopoverProps = {
  control?: Control<any>;
  setValue?: UseFormSetValue<any>;
  name: string;
  dataset?: Record<string, Dataset>;
};

export function ParameterPopover({
  name,
  control,
  setValue,
  dataset = {},
}: ParameterPopoverProps) {
  const ref = useRef<PopoverMethods>(null);
  const params: string = useWatch({ control, name }) ?? '';
  const filteredParams = params
    .replaceAll('', '')
    .split(',')
    .filter((p) => p !== '');

  const { fieldState } = useController({
    name,
    control,
  });

  const error = fieldState.error;

  const handleAddElement = (value: string) => {
    if (!filteredParams.includes(value) && typeof setValue === 'function') {
      const newParams = [...filteredParams, value];
      setValue(name, newParams.join(','));
    }
  };

  return (
    <Stack gutter="0.5rem">
      <Inline gutter="0.5rem" align="center">
        <Typography>Key Parameters</Typography>
        <TooltipReact id={`${name}-parameter`}>
          <span>
            Define group of key parameter(s), to determine and retrieve cached
            data
          </span>
        </TooltipReact>
      </Inline>

      <PopoverPanel
        launcher={
          <span>
            <ParameterContainer $error={!(error == null)}>
              {filteredParams.length > 0 ? (
                filteredParams.map((param, i) => {
                  return (
                    <ParamElement
                      item={param?.replaceAll('{{.', '').replaceAll('}}', '')}
                      onRemove={(val) => {
                        ref.current?.hide();
                        const value = `{{.${val}}}`;

                        if (
                          filteredParams.includes(value) &&
                          typeof setValue === 'function'
                        ) {
                          setValue(
                            name,
                            filteredParams.filter((p) => p !== value).join(',')
                          );
                        }
                      }}
                      key={`${param}-${i as unknown as string}`}
                    />
                  );
                })
              ) : (
                <Typography name="secondary">Select values</Typography>
              )}
            </ParameterContainer>
          </span>
        }
        trigger="click"
        placement="bottom-start"
        ref={ref}
      >
        <RuleFieldContainer>
          <RulePopover
            dataset={dataset}
            allowList
            version="v2"
            onClick={({ key, value }) => {
              handleAddElement(`{{.${key}.${value}}}`);
              ref.current?.hide();
            }}
            showFooter={false}
            typesToAllow={[
              'json',
              'list',
              'numeric',
              'boolean',
              'date',
              'dateTime',
              'string',
            ]}
          />
        </RuleFieldContainer>
      </PopoverPanel>
    </Stack>
  );
}
