import { AllowedSelectedDataSetTypes } from '../../../../types';

export const dataSetTypeToExcludeRules: AllowedSelectedDataSetTypes = {
  mongodb: {
    excludes: ['list', 'json'],
  },
  mysql: {
    excludes: ['list', 'json'],
  },
  redshift: {
    excludes: ['list', 'json'],
  },
  snowflake: {
    excludes: ['list', 'json'],
  },
  oracle: {
    excludes: ['list', 'json'],
  },
  postgres: {
    excludes: ['list', 'json'],
  },
  sqlserver: {
    excludes: ['list', 'json'],
  },
  gsheet: {
    excludes: ['list', 'json'],
  },
};
