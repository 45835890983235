import { ReactNode } from 'react';
import { Typography } from 'ui';

import { QuestionContainer } from './Questionnaire.styled';

type QuestionProps = {
  children: ReactNode;
  question: string;
};

export function Question({ children, question }: QuestionProps) {
  return (
    <QuestionContainer gutter="size2">
      <Typography>{question}</Typography>

      {children}
    </QuestionContainer>
  );
}
