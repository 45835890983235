import { useMutation } from '@tanstack/react-query';
import type { AxiosError, AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';
import { toasts } from 'ui';

import { useAuth } from '../../../components/authentication';
import { useAxios } from '../../../hooks';
import { useSendEventToGTM } from '../../../hooks/useSendEventToGTM';
import { getRedirectUrl, getResponseErrorMessage } from '../../../utils/common';
import { userLoggedIn } from '../../../utils/constant';
import type { CustomAxiosError } from '../../../utils/response/types';
import { updateWidgetState } from '../../Home/components/sub-components/UpdateWidgetState';

export type SigninRequestPayload = {
  email: string;
  password: string;
};

type UseSignInProps = {
  setIsLoading: (isLoading: boolean) => void;
};

export function useSignin({ setIsLoading }: UseSignInProps) {
  const { axios } = useAxios();
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const { sendEventToGTM } = useSendEventToGTM();
  const to = getRedirectUrl(window.location.href) ?? '/home';

  const { mutate: signin, isLoading } = useMutation({
    mutationFn: async (request: SigninRequestPayload) => {
      return await axios.post('/auth/login', request, {
        headers: {
          'Nected-User-IP': localStorage.getItem('Nected-User-IP') ?? '',
          'X-CSRF-Token': localStorage.getItem('X-CSRF-Token') ?? '',
        },
      });
    },
    onSuccess(response: AxiosResponse, requestPayload: SigninRequestPayload) {
      const { data } = response.data;
      const { accessToken, refreshToken, workspaceUUID, userUUID } = data;

      sendEventToGTM({
        event: 'signin',
        status: 'success',
        email: requestPayload.email,
        provider: 'form',
        userid: userUUID,
        wsid: workspaceUUID,
      });

      sendEventToGTM({
        event: 'signin_success',
        userid: userUUID,
        wsid: workspaceUUID,
        email: requestPayload.email,
      });

      const state = userLoggedIn;
      setIsLoading(true);
      setAuth({ accessToken, refreshToken, workspaceUUID, userUUID });
      navigate(to, { replace: true });
      setTimeout(
        () => {
          updateWidgetState(state, userUUID)
            .then((res) => {})
            .catch((err) => {
              // eslint-disable-next-line no-console
              console.log(err);
            })
            .finally(() => {
              setIsLoading(false);
            });
        },
        100,
        state,
        userUUID
      );
    },
    onError(
      error: AxiosError<CustomAxiosError>,
      requestPayload: SigninRequestPayload
    ) {
      toasts.error(getResponseErrorMessage(error), 'error');

      const { code } = error.response?.data ?? { code: '' };

      sendEventToGTM({
        event: 'signin',
        status: 'error',
        reason: code,
        email: requestPayload.email,
        provider: 'form',
      });
    },
  });

  return {
    signin,
    isLoading,
  };
}
