import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';

import { otherValueText } from './components/QuestionnaireForm';
import { QuestionModel } from './models';

export function convertRequestPayload(
  formValues: Record<string, string | string[] | any>,
  currentPageQuestions: QuestionModel[]
) {
  return currentPageQuestions.map((questionsDetails: QuestionModel) => {
    const { key, questionType, otherAllowed } = questionsDetails;
    const value = formValues[key];
    const request: Record<string, string> = {
      key,
    };

    const otherValueKey = `${key}_${otherValueText}`;

    if (Array.isArray(value) && questionType === 'multi') {
      const values: string[] = [];
      value.forEach((i) => values.push(i.value));

      request.value = values.join(',');
    } else {
      request.value = value;
      const otherValue = formValues[otherValueKey];

      if (questionType === 'select-single' && !_isNil(value)) {
        const values = value.value;
        request.value = values;
      }

      if (
        questionType === 'radio' &&
        !_isNil(otherAllowed) &&
        otherAllowed &&
        !_isEmpty(otherValue) &&
        !Array.isArray(otherValue)
      ) {
        request[otherValueText] = otherValue;
      }
    }

    return request;
  });
}
