import { Inline } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';

import { useSetVersionCount } from '../../../../hooks/useSetVersionCount';
import { ENTITY_ID } from '../../../../utils/constant';
import { EnvButtons } from '../../../Rules/components/RuleComponents/EnvButtons/EnvButtons';
import { workflowStatusAtom } from '../../atoms/atoms';
import { Container } from './WorkflowSettingsInfo.styled';

type WorkflowSettingsInfoProps = {
  id: string | null;
};

export function WorkflowSettingsInfo({ id }: WorkflowSettingsInfoProps) {
  const [status] = useAtom(workflowStatusAtom);
  const { versionCount } = useSetVersionCount({
    id,
    entityType: ENTITY_ID.workflow,
    status,
  });

  return (
    <Container align="center" gutter="1rem" className="workflow-settings-info">
      <Inline />

      <EnvButtons count={versionCount} entityName="workflow" />
    </Container>
  );
}
