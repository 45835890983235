import { Stack } from '@bedrock-layout/stack';
import type { AxiosError } from 'axios';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { useEffect, useRef, useState } from 'react';
import { Control, useWatch } from 'react-hook-form';
import { JsonHeroTreeViewer, NectedEditor, Typography } from 'ui';

import { siteConstantsAtom } from '../../../../../../atom';
import { ExecutionView } from '../../../../../../components/jsonOutputField/components/ExecutionView';
import { getTooltipText } from '../../../../../../utils/common';
import { editorDomain, envMap } from '../../../../../../utils/constant';
import type { CustomAxiosError } from '../../../../../../utils/response/types';
import { DataSetOutput } from '../../../../../DataSets/components/DataSetOutput';
import { getFieldsByValue } from '../../../../utils/common';
import { queryOptions } from '../constant';
import {
  JsonContainer,
  JsonTreeContainer,
  OutputContainer,
} from './ConnectorTest.styled';

type ConnectorTestProps = {
  control: Control<any>;
  outputValue?: Record<string, any>;
  error: AxiosError<unknown, any> | null;
};

export function ConnectorTest({
  control,
  outputValue,
  error,
}: ConnectorTestProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [outputJson, setOutputJson] = useState('');
  const [siteConstants] = useAtom(siteConstantsAtom);

  const selectedAction = useWatch({
    name: 'input.action.value',
    control,
  });

  const showOutputInTable =
    _isNil(selectedAction) || selectedAction?.value === queryOptions[0].value;

  const tableOutput = {
    fields: getFieldsByValue(outputValue?.executedValue?.output ?? []),
    rows: outputValue?.executedValue?.output ?? [],
    executionTime: outputValue?.executionTime ?? '',
  };

  useEffect(() => {
    if (!_isNil(error)) {
      setOutputJson(JSON.stringify(error ?? {}, null, 2));
    } else {
      const json = {
        output: outputValue?.executedValue?.output ?? null,
        error: outputValue?.error ?? null,
      };
      setOutputJson(JSON.stringify(json ?? {}, null, 2));
    }
  }, [error, outputValue]);

  return (
    <OutputContainer padding="1rem">
      <Typography name="paragraphSmall">
        {getTooltipText(
          siteConstants,
          'workflow',
          'dbTestHelpText',
          'otherText'
        )}
      </Typography>

      {showOutputInTable ? (
        tableOutput.rows.length === 0 ? (
          // eslint-disable-next-line
          outputValue?.executedValue?.error ? (
            <Stack gutter="1rem">
              <ExecutionView>
                <NectedEditor
                  mode="json"
                  defaultValue={JSON.stringify(
                    outputValue.executedValue ?? {},
                    null,
                    2
                  )}
                  readOnly
                  domain={editorDomain}
                />

                <JsonTreeContainer>
                  <JsonHeroTreeViewer json={outputValue.executedValue ?? {}} />
                </JsonTreeContainer>
              </ExecutionView>
            </Stack>
          ) : (
            <Typography>{outputValue?.error ?? 'No Records Found'}</Typography>
          )
        ) : (
          <DataSetOutput
            isLoading={false}
            data={tableOutput}
            error={error as AxiosError<CustomAxiosError, any>}
            outputRef={ref}
          />
        )
      ) : (
        <JsonContainer>
          <Stack gutter="1rem">
            <ExecutionView>
              <NectedEditor
                mode="json"
                readOnly
                defaultValue={
                  !_isNil(outputValue?.executedValue)
                    ? JSON.stringify(outputValue?.executedValue ?? {}, null, 2)
                    : outputJson
                }
                domain={envMap.VITE_EDITOR_URL}
              />

              <JsonTreeContainer>
                <JsonHeroTreeViewer json={outputValue?.executedValue ?? {}} />
              </JsonTreeContainer>
            </ExecutionView>
          </Stack>
        </JsonContainer>
      )}
    </OutputContainer>
  );
}
