import { Inline, PadBox } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import styled from 'styled-components';

export const ListingBlockExpanderContainer = styled(Stack)`
  border: 0.1rem solid rgba(0, 0, 0, 0.08);
  border-radius: 0.8rem;
  background: var(--color-white);
  font-weight: 600;
  padding: 1rem;
  margin-top: 2rem;
`;

export const GSBlockContainer = styled(PadBox)<{
  styles: any;
}>`
  ${({ styles }) => styles};
`;

export const GSBlock = styled(Inline)`
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  -webkit-box-pack: justify;
  width: calc(33.33% - 0.5rem);
  align-items: center;
`;

export const IconContainer = styled.div`
  width: 10rem;
`;
