import { ApolloError } from '@apollo/client';
import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Typography,
} from 'ui';
import { PublishedStatusType } from 'ui/src/WorkflowNodeSheetHeader/PublishedStatusLabel';

import { useUpdateModalStateOnButtonOptionClick } from '../../hooks/useUpdateModalStateOnButtonOptionClick';
import { currentWorkspaceDetailAtom } from '../../pages/Workspace/atom';
import type { Role } from '../../pages/Workspace/component/types';
import { ApprovalInfoType } from '../../types';
import { showGraphQlErrorToast } from '../../utils/common';
import { getEntityTypeForApi } from '../Modals/utils/common';
import {
  StyledEmptyListImg,
  StyledTextBlue,
  VCContainer,
} from './VersionControl.styled';
import { Card } from './components/Card/Card';
import { tabList } from './constant';
import { useGetVersionList } from './hooks/graphql/useGetVersionList';

/* eslint-disable-next-line import/no-absolute-path */
import emptyListImg from '/assets/konark/images/emptyList.svg';

export type CommentDataType = {
  comment?: string;
  author: string;
  timeStamp: string;
  commitId?: string;
};

export type PaginationType = {
  perPage: number;
  currentPage: number;
  totalPage: number;
  totalRecord: number;
};

export type CardDataType = {
  commitId: string;
  isLive: boolean;
  status: PublishedStatusType;
  version: string;
  approvalInfo: ApprovalInfoType;
};

export type EntityInfoType = {
  id: string;
  type: string;
  name?: string;
  status?: string;
  subType?: string;
  accessRole?: Role;
  iconUrl?: string;
  version?: string;
};

type VersionControlProps = {
  entityInfo: EntityInfoType;
  currentTab?: number;
  handleEntityUpdate?: (data: Record<string, any>) => void;
  modalFormdata?: Record<string, any>;
  updateTabIndex?: (index: number) => void;
};

const pageSize = 10;

export function VersionControl({
  entityInfo,
  currentTab = 0,
  handleEntityUpdate,
  modalFormdata,
  updateTabIndex,
}: VersionControlProps) {
  const navigate = useNavigate();

  const [list, setList] = useState<CardDataType[]>([]);
  const [paginationInfo, setPaginationInfo] = useState<PaginationType>();
  const [canAppendData, setAppendData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [currentWorkspace] = useAtom(currentWorkspaceDetailAtom);
  const approvalFlowEnabled = currentWorkspace?.approvalFlowEnabled ?? false;

  const { type, name, status, id, subType } = entityInfo;

  const showLoadMoreBtn =
    !_isNil(paginationInfo) &&
    paginationInfo.currentPage < paginationInfo.totalPage;

  const [getVersionListQuery, { data, error }] = useGetVersionList(type);

  const { selectedOptionId, handleOptionButtonClick } =
    useUpdateModalStateOnButtonOptionClick({
      entityId: id,
      entityType: type,
      entityName: name ?? '',
      entityStatus: status ?? '',
      entitySubType: subType,
      handleParentStateUpdate: handleEntityUpdate,
      formData: modalFormdata,
    });

  useEffect(() => {
    if (!_isNil(id) && !_isEmpty(id)) {
      void handleGetInitialData();
    }
  }, [id, currentTab, status]);

  const methodName = useMemo(() => {
    switch (type) {
      case 'rules':
        return 'getRule';
      case 'datasets':
        return 'getDataSet';
      case 'workflow':
        return 'getWorkflow';
      default:
        return null;
    }
  }, [type]);

  useEffect(() => {
    if (!_isNil(data) && !_isNil(methodName) && !_isNil(data[methodName])) {
      setList(
        canAppendData
          ? [...list, ...(data[methodName].data ?? [])]
          : [...(data[methodName].data ?? [])]
      );

      setPaginationInfo(data[methodName].paginationInfo);
    }
  }, [data]);

  useEffect(() => {
    if (!_isNil(error) && error instanceof ApolloError) {
      showGraphQlErrorToast(error);
      setList([]);
    }
  }, [error]);

  const handleGetInitialData = async () => {
    const payload = {
      id,
      entityType: getEntityTypeForApi(type),
      page: 1,
      perPage: pageSize,
      live: ['published', 'archived'].includes(tabList[currentTab].value),
      filters: {
        in: {
          status:
            tabList[currentTab].value === 'draft'
              ? ['draft', 'tested']
              : [tabList[currentTab].value],
        },
      },
      sort: {
        updatedAt: -1,
      },
    };
    setAppendData(false);
    setIsLoading(true);

    await getVersionListQuery({
      variables: payload,
      fetchPolicy: 'no-cache',
    });

    setIsLoading(false);
  };

  const handleLoadMoreData = async () => {
    const payload = {
      id,
      status,
      entityType: getEntityTypeForApi(type),
      page: (paginationInfo?.currentPage ?? 0) + 1,
      perPage: pageSize,
      live: ['published', 'archived'].includes(tabList[currentTab].value),
      filters: {
        in: {
          status:
            tabList[currentTab].value === 'draft'
              ? ['draft', 'tested']
              : [tabList[currentTab].value],
        },
      },
      sort: {
        updatedAt: -1,
      },
    };

    setAppendData(true);
    setIsLoading(true);

    await getVersionListQuery({
      variables: payload,
      fetchPolicy: 'no-cache',
    });

    setIsLoading(false);
  };

  const handleRouteToWorkspace = () => {
    navigate(`/workspace/settings`);
  };

  const onTabChange = (index: number) => {
    setList([]);

    if (typeof updateTabIndex === 'function') {
      updateTabIndex(index);
    }
  };

  return (
    <VCContainer>
      <PadBox padding={'1.6rem'}>
        <Typography name="heading3" id="version-contol-heading">
          Version Control
        </Typography>
      </PadBox>
      <Tabs defaultOpen={currentTab} onTabChange={onTabChange}>
        <TabList styleClassName="tabListStyled">
          {tabList.map((tab) => (
            <Tab key={tab.value} size="extraSmall">
              {tab.label}
            </Tab>
          ))}
        </TabList>

        <TabPanels>
          <TabPanel>
            <PadBox padding="1rem" as={Stack} gutter="1rem">
              {list.map((item, index) => (
                <Card
                  data={item}
                  index={index}
                  key={index}
                  entityInfo={entityInfo}
                  tabName="draft"
                  selectedOptionId={selectedOptionId}
                  handleButtonClick={handleOptionButtonClick}
                />
              ))}
              {showLoadMoreBtn && !isLoading && (
                <Inline justify="center">
                  <Button appearance="neutral" onClick={handleLoadMoreData}>
                    Load more
                  </Button>
                </Inline>
              )}
              {list.length === 0 && !isLoading && (
                <Inline as={Stack} align="center" justify="center">
                  <StyledEmptyListImg src={emptyListImg} alt="No Data Found" />
                  <Typography>There is no item here</Typography>
                </Inline>
              )}

              {isLoading && (
                <Inline justify="center">
                  <Spinner />
                </Inline>
              )}
            </PadBox>
          </TabPanel>
          <TabPanel>
            <PadBox padding="1rem" as={Stack} gutter="1rem">
              {list.map((item, index) => (
                <Card
                  data={item}
                  index={index}
                  key={index}
                  entityInfo={entityInfo}
                  tabName="inreview"
                  selectedOptionId={selectedOptionId}
                  handleButtonClick={handleOptionButtonClick}
                />
              ))}
              {showLoadMoreBtn && !isLoading && (
                <Inline justify="center">
                  <Button appearance="neutral" onClick={handleLoadMoreData}>
                    Load more
                  </Button>
                </Inline>
              )}
              {list.length === 0 && !approvalFlowEnabled && !isLoading && (
                <Stack>
                  <Typography>
                    There is nothing in review as approval flow has been
                    disabled*
                  </Typography>
                  <Inline gutter="0.5rem">
                    <div onClick={handleRouteToWorkspace}>
                      <StyledTextBlue fontWeight={700}>
                        Click Here
                      </StyledTextBlue>
                    </div>
                    <Typography>to enable it in workspace settings</Typography>
                  </Inline>
                </Stack>
              )}

              {list.length === 0 && approvalFlowEnabled && !isLoading && (
                <Inline as={Stack} align="center" justify="center">
                  <StyledEmptyListImg src={emptyListImg} alt="No Data Found" />
                  <Typography name="heading5">There is no item here</Typography>
                </Inline>
              )}

              {isLoading && (
                <Inline justify="center">
                  <Spinner />
                </Inline>
              )}
            </PadBox>
          </TabPanel>
          <TabPanel>
            <PadBox padding="1rem" as={Stack} gutter="1rem">
              {list.map((item, index) => (
                <Card
                  data={item}
                  index={index}
                  key={index}
                  entityInfo={entityInfo}
                  tabName="published"
                  handleButtonClick={handleOptionButtonClick}
                />
              ))}
              {showLoadMoreBtn && !isLoading && (
                <Inline justify="center">
                  <Button onClick={handleLoadMoreData}>Load more</Button>
                </Inline>
              )}

              {list.length === 0 && !isLoading && (
                <Inline as={Stack} align="center" justify="center">
                  <StyledEmptyListImg src={emptyListImg} alt="No Data Found" />
                  <Typography name="heading5">There is no item here</Typography>
                </Inline>
              )}

              {isLoading && (
                <Inline justify="center">
                  <Spinner />
                </Inline>
              )}
            </PadBox>
          </TabPanel>
          <TabPanel>
            <PadBox padding="1rem" as={Stack} gutter="1rem">
              {list.map((item, index) => (
                <Card
                  data={item}
                  index={index}
                  key={index}
                  entityInfo={entityInfo}
                  tabName="archived"
                  handleButtonClick={handleOptionButtonClick}
                />
              ))}
              {showLoadMoreBtn && !isLoading && (
                <Inline justify="center">
                  <Button appearance="neutral" onClick={handleLoadMoreData}>
                    Load more
                  </Button>
                </Inline>
              )}
              {list.length === 0 && !isLoading && (
                <Inline as={Stack} align="center" justify="center">
                  <StyledEmptyListImg src={emptyListImg} alt="No Data Found" />
                  <Typography name="heading5">There is no item here</Typography>
                </Inline>
              )}

              {isLoading && (
                <Inline justify="center">
                  <Spinner />
                </Inline>
              )}
            </PadBox>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </VCContainer>
  );
}
