import { IoMdSync } from 'react-icons/io';
import { Typography } from 'ui';

import { AutoSavingContainer } from './AutoSaving.styled';

export function AutoSaving() {
  return (
    <AutoSavingContainer gutter={4} align="center">
      <IoMdSync color="var(--color-lightGray)" />
      <Typography name="secondarySmall">Saving...</Typography>
    </AutoSavingContainer>
  );
}
