import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _isUndefined from 'lodash/isUndefined';
import { useEffect, useState } from 'react';
import {
  type UseControllerProps,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';

import { PluginBooleanField } from '../../../pages/Integrations/components/connector/form/fields/PluginBooleanField';
import type { PluginFieldCommonProps } from '../../../pages/Integrations/types';
import { parseExpression } from '../utlis';

type WebBooleanFieldProps = UseControllerProps<any> &
  PluginFieldCommonProps & {
    setValue?: UseFormSetValue<any>;
    parentFormData?: Record<string, any>;
    formJson: Record<string, any>;
    reset?: string[];
    onUpdate?: Array<Record<string, string>>;
  };

export function WebBooleanField({
  errorMessage = 'Please enter a valid input',
  type,
  control,
  label,
  name,
  required = false,
  regex,
  placeholder,
  disabled = false,
  tooltipText,
  reset = [],
  setValue,
  formKeyPrefix = '',
  onUpdate = [],
  parentFormData = {},
  ...rest
}: WebBooleanFieldProps) {
  const [resetFields, setResetFields] = useState(false);
  const formData = useWatch({
    control,
  });

  const fieldValue = useWatch({
    name,
    control,
  });

  useEffect(() => {
    if (!_isEmpty(parentFormData)) {
      const { formType = 'create' } = parentFormData;
      setResetFields(formType === 'create');
    }
  }, [parentFormData]);

  useEffect(() => {
    // Reset value of keys present in config if the current field value changes

    if (resetFields && !_isUndefined(setValue) && reset.length > 0) {
      reset.forEach((keyToReset) => {
        setValue(`${formKeyPrefix}${keyToReset}`, null);
      });
    } else if (!resetFields) {
      setResetFields(true);
    }

    // Update value of keys present in config if the current field value changes
    if (!_isUndefined(setValue) && onUpdate.length > 0 && !_isNil(formData)) {
      onUpdate.forEach((currConfig) => {
        const { key, value } = currConfig;
        const valueToUpdate = parseExpression(
          formData,
          parentFormData,
          value,
          formKeyPrefix
        );

        setValue(`${formKeyPrefix}${key}`, valueToUpdate);
      });
    }
  }, [fieldValue]);

  return (
    <PluginBooleanField
      type={type}
      control={control}
      name={name}
      errorMessage={errorMessage}
      label={label}
      placeholder={placeholder}
      required={required}
      regex={regex}
      disabled={disabled}
      tooltipText={tooltipText}
      {...rest}
    />
  );
}
