import { Inline } from '@bedrock-layout/inline';
import _isEmpty from 'lodash/isEmpty';
import _isUndefined from 'lodash/isUndefined';

import { ExecutedValueTooltip } from '../../ExecutedValueTooltip/ExecutedValueTooltip';
import { Typography } from '../../Typography';
import { IconByDataType } from '../RulePopover/IconByDataType';
import { LeafNodeStyled, StyledText, SymbolText } from './NodeLeaf.styled';

export type NodeLeafProps = {
  title: string;
  symbol?: string;
  onClick: () => void;
  dataType?: string;
  executedValue?: any;
  showTooltip?: boolean;
  version?: 'v1' | 'v2';
};

export const NodeLeaf = ({
  title,
  symbol = '',
  onClick,
  dataType,
  executedValue,
  showTooltip = false,
  version = 'v1',
}: NodeLeafProps) => {
  return (
    <LeafNodeStyled
      id="nodeLeaf"
      onClick={onClick}
      padding={[6, 0, 6, 8]}
      $version={version}
    >
      {showTooltip ? (
        <ExecutedValueTooltip
          id={title}
          value={executedValue}
          attribute={title}
          dataType={dataType}
          placement="top"
        >
          <Inline stretch="end" align="center" gutter={10}>
            {version === 'v2' && (
              <span>
                <IconByDataType
                  dataType={dataType}
                  color="var(--color-paleDodgerBlue)"
                />
              </span>
            )}
            <Inline align="center">
              {!_isEmpty(symbol) && <SymbolText>{symbol}</SymbolText>}
              <StyledText
                id="nodeLeafText"
                name="secondaryXsDark"
                fontWeight={700}
                $version={version}
              >
                {title}
              </StyledText>
            </Inline>

            {!_isUndefined(dataType) && (
              <Typography name="secondaryXs">{dataType}</Typography>
            )}
          </Inline>
        </ExecutedValueTooltip>
      ) : (
        <Inline stretch="end" align="center" gutter={10}>
          <Inline align="center">
            {!_isEmpty(symbol) && <SymbolText>{symbol}</SymbolText>}
            {version === 'v2' && (
              <span>
                <IconByDataType
                  dataType={dataType}
                  color="var(--color-paleDodgerBlue)"
                />
              </span>
            )}

            <StyledText
              id="nodeLeafText"
              name="secondaryXsDark"
              fontWeight={700}
              $version={version}
            >
              {title}
            </StyledText>
          </Inline>

          {!_isUndefined(dataType) && (
            <Typography name="secondaryXs">{dataType}</Typography>
          )}
        </Inline>
      )}
    </LeafNodeStyled>
  );
};
