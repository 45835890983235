import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { useEffect, useRef } from 'react';
import { PopoverMethods, PopoverPanel } from 'ui';

import { allWorkspaceAtom } from '../../../atom';
import { currentWorkspaceDetailAtom } from '../../../pages/Workspace/atom';
import { WorkspaceComponent } from '../../WorkspaceComponent';
import {
  RowContainer,
  WorkspaceListContainer,
} from './WorkspaceSelection.styled';

export function WorkspaceSelection() {
  const ref = useRef<PopoverMethods>(null);

  const [currWorkspace, setCurrWorkspace] = useAtom(currentWorkspaceDetailAtom);
  const [allWorkspaceList] = useAtom(allWorkspaceAtom);

  useEffect(() => {
    if (!_isNil(currWorkspace)) {
      window.sessionStorage.setItem('workspaceUUID', currWorkspace.uuid);
    }
  }, [currWorkspace]);

  const handleUpdateWorkspace = (uuid: string) => {
    if (!_isNil(allWorkspaceList)) {
      const updatedCurrWorkspace = allWorkspaceList.filter(
        (workspace) => workspace.uuid === uuid
      );

      setCurrWorkspace(updatedCurrWorkspace[0]);
      window.open('/', '_self');
    }
  };

  const handleWorkspaceClick = (uuid: string) => {
    ref.current?.hide();
    handleUpdateWorkspace(uuid);
  };

  if (_isNil(currWorkspace)) {
    return null;
  }

  return (
    <PopoverPanel
      trigger="click"
      placement="bottom"
      launcher={<WorkspaceComponent {...currWorkspace} showBorder />}
      padding="8px"
      ref={ref}
    >
      <WorkspaceListContainer>
        {!_isNil(allWorkspaceList) &&
          allWorkspaceList.map((workspace) => (
            <RowContainer key={workspace.uuid}>
              <WorkspaceComponent
                {...workspace}
                bgcolor={
                  workspace.uuid === currWorkspace.uuid
                    ? ''
                    : 'var(--color-white)'
                }
                onClick={(uuid) => handleWorkspaceClick(uuid)}
                showArrowIcon={false}
              />
            </RowContainer>
          ))}
      </WorkspaceListContainer>
    </PopoverPanel>
  );
}
