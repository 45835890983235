import { useState } from 'react';

import type { RuleEnvironment } from '../../../../utils/constant';
import type {
  ConnectorStatus,
  UpdateConnectorStatusFunction,
} from '../../types';

export const connectorInitialState: ConnectorStatus = {
  staging: { isTested: false, isPublish: false },
  production: { isTested: false, isPublish: false },
};

export type UseUpdateConnectorStatusReturn = {
  connectorStatus: ConnectorStatus;
  updateConnectorStatus: UpdateConnectorStatusFunction;
};

export function useUpdateConnectorStatus(): UseUpdateConnectorStatusReturn {
  const [connectorStatus, setConnectorStatus] = useState(connectorInitialState);

  const updateConnectorStatus = (
    environment: RuleEnvironment,
    isTested: boolean = false,
    isPublished: boolean = false,
    isResettingStatusInCurrentEnvironment: boolean = false
  ) => {
    if (isTested) {
      setConnectorStatus((prev) => ({
        ...prev,
        [environment]: {
          ...prev[environment],
          isTested,
          isPublish: false,
        },
      }));
    }

    if (isPublished) {
      setConnectorStatus((prev) => ({
        ...prev,
        [environment]: {
          ...prev[environment],
          isPublish: isPublished,
        },
      }));
    }

    if (isResettingStatusInCurrentEnvironment) {
      setConnectorStatus((prev) => ({
        ...prev,
        [environment]: {
          ...prev[environment],
          isTested: false,
          isPublish: false,
        },
      }));
    }
  };

  return {
    connectorStatus,
    updateConnectorStatus,
  };
}
