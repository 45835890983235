import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import styled from 'styled-components';

export const WorkspaceContainer = styled(Stack)`
  background-color: var(--color-white);
  border-radius: 8px;
`;

export const InlineChildrenContainerStyled = styled(Inline)`
  border: 1px solid var(--color-gainsboro);
  border-radius: 4px;
  justify-content: space-between;
`;
