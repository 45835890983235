import { Inline } from '@bedrock-layout/primitives';
import { useNavigate } from 'react-router-dom';
import { ProgressBar, TextButton, Typography } from 'ui';

import { useSendEventToGTM } from '../../../hooks/useSendEventToGTM';
import {
  ProgressBarContainer,
  QuestionCount,
  SkipQuestion,
} from './Questionnaire.styled';

export function QuestionnaireTracker({
  canSkip,
  currentStep,
  pages,
  isFromHome,
  onClickSkipPage,
  totalQuestions,
  currentQuestions,
  goToPage,
}: {
  canSkip: boolean;
  currentStep: number;
  pages: number;
  isFromHome: boolean;
  onClickSkipPage: () => void;
  goToPage: (index: number) => void;
  totalQuestions: number;
  currentQuestions: number;
}) {
  const navigate = useNavigate();
  const { sendEventToGTM } = useSendEventToGTM();

  const handleClickHomeIcon = () => {
    sendEventToGTM({
      event: 'onboarding',
      status: 'inprogress',
      action: 'home',
    });

    navigate('/home');
  };

  return (
    <Inline style={{ position: 'relative' }}>
      <QuestionCount gutter=".5rem">
        <Typography name="secondarySmall">
          {currentStep} / {pages}
        </Typography>
        <Inline>
          {Array.from({ length: pages }).map((_, index) => {
            return (
              <ProgressBarContainer
                key={index}
                onClick={() => {
                  if (currentStep > index + 1) {
                    goToPage(index);
                  }
                }}
                style={{ cursor: 'pointer' }}
              >
                <ProgressBar fill={currentStep > index ? 100 : 0} />
              </ProgressBarContainer>
            );
          })}
        </Inline>
      </QuestionCount>

      <SkipQuestion>
        {canSkip && (
          <TextButton
            appearance="secondary"
            bold={false}
            onClick={handleClickHomeIcon}
          >
            Skip for now
          </TextButton>
        )}
      </SkipQuestion>
    </Inline>
  );
}
