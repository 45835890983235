import { Menu, MenuItem, PopoverMethods, TextButton, Typography } from 'ui';

import { AddButton } from '../../../components/AddButton';
import { RemoteConfigFieldsDataTypes } from '../models';
import { AddChildStyled } from './RemoteConfigDataType.styled';

type RemoteConfigDataTypeProps = {
  handleAddConfig: (type: RemoteConfigFieldsDataTypes) => void;
  dataTypeRef: React.RefObject<PopoverMethods>;
};
export function RemoteConfigDataType({
  handleAddConfig,
  dataTypeRef,
}: RemoteConfigDataTypeProps) {
  const menuItems = [
    { value: 'boolean', label: 'Boolean' },
    { value: 'numeric', label: 'Numeric' },
    { value: 'string', label: 'String' },
    { value: 'dateTime', label: 'DateTime' },
    { value: 'date', label: 'Date' },
    { value: 'json', label: 'JSON' },
    { value: 'list', label: 'List' },
  ];

  const launcher = (
    <AddChildStyled align="center" gutter={5}>
      <AddButton />
      <TextButton>Add Value</TextButton>
    </AddChildStyled>
  );

  return (
    <Menu
      launcher={launcher}
      onMenuItemClick={(value) => {
        dataTypeRef.current?.hide();
        handleAddConfig(value as RemoteConfigFieldsDataTypes);
      }}
      ref={dataTypeRef}
      placement="bottom-start"
    >
      {menuItems.map((menuItem) => (
        <MenuItem value={menuItem.value} key={menuItem.value}>
          <Typography>{menuItem.label}</Typography>
        </MenuItem>
      ))}
    </Menu>
  );
}
