import { Inline, PadBox } from '@bedrock-layout/primitives';
import styled from 'styled-components';

export const TableContainerStyled = styled(PadBox)<{
  isMarginReqired: boolean;
}>`
  background-color: var(--color-white);
  border-radius: 1rem;
  margin-top: ${({ isMarginReqired }) => (isMarginReqired ? '4rem' : '0rem')};
  table {
    margin-top: 1rem;
    border-spacing: 0;
    max-inline-size: 100%;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th,
    td {
      margin: 0;
      padding: 0.5rem;

      :last-child {
        border-right: 0;
      }
    }

    th {
      text-align: left;

      .datatype-text {
        text-transform: capitalize;
      }
    }

    thead {
      th {
        background-color: var(--color-primary3);
      }
    }
  }
`;

export const TableHeadingContainerStyled = styled(Inline)`
  border-bottom: 1px solid #ddd;
  padding-bottom: 0.5rem;
`;
