import { Inline } from '@bedrock-layout/primitives';
import styled, { css } from 'styled-components';

export const ParameterContainer = styled.div<{ $error?: boolean }>`
  border: 1px solid var(--color-lightGray);
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 0.5rem 1rem;
  position: relative;

  ${({ $error = false }) =>
    $error &&
    css`
      border: 1px solid var(--color-fireEngineRed);
      background-color: var(--color-snow);
    `}
`;

export const ParamElementContainer = styled(Inline)`
  padding: 0.3rem 1rem;
  border: 1px solid var(--color-lightGray);
  border-radius: 4px;
  cursor: pointer;
`;

export const RuleFieldContainer = styled.div`
  max-inline-size: 90vw;
  inline-size: 40rem;
  max-block-size: 30rem;
  overflow-y: auto;
`;
