import type { ObservableQuery } from '@apollo/client';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useState } from 'react';
import { useCurrentLayer } from 'ui';

import { checksumWarningAtom } from '../../../../atom';
import type { EntityType } from '../../../../hooks/useLayerCloseOnPath';
import { useLayerCloseOnPath } from '../../../../hooks/useLayerCloseOnPath';
import {
  checksumMessage,
  handleGetCheckSumByEntityName,
  handleSetCheckSumByEntityName,
  showGraphQlErrorToast,
} from '../../../../utils/common';
import { IntegrationSheet } from '../common/IntegrationSheet';
import { useCreateConnector } from '../hooks/useCreateConnector';
import { useCurrentEnvironment } from '../hooks/useCurrentEnvironment';
import type { GetConnectorsQueryResult } from '../hooks/useGetConnectors';
import { useGetPlugin } from '../hooks/useGetPlugin';
import { useResetForm } from '../hooks/useResetForm';
import { useStoreFormValues } from '../hooks/useStoreFormValues';
import { useUpdateConnectorPublishStatus } from '../hooks/useUpdateConnectorPublishStatus';
import { useUpdateConnectorStatus } from '../hooks/useUpdateConnectorStatus';
import type { PluginModel } from './Plugin';

export type PluginFormSheetProps = {
  plugin?: PluginModel;
  refetch?: ObservableQuery<GetConnectorsQueryResult>['refetch'];
  currentStage?: string;
  from?: string;
  onPublish?: () => void;
};

export function PluginFormSheet({
  plugin,
  refetch,
  currentStage,
  from = '',
  onPublish,
}: PluginFormSheetProps) {
  const [connectorId, setConnectorId] = useState<string>('');
  const { updateConnectorStatus, connectorStatus } = useUpdateConnectorStatus();

  const [connectorName, setConnectorName] = useState('');
  const { currentEnvironment, toggleEnvironment } = useCurrentEnvironment();

  const [, setShowChecksumPopup] = useAtom(checksumWarningAtom);

  const { close, closeAllLayers } = useCurrentLayer();

  useLayerCloseOnPath({
    entityName: location.pathname.split('/')[1] as EntityType,
    onRouteChange: () => {
      closeAllLayers();
    },
  });

  if (_isNil(plugin)) {
    close();

    return <div>Loading...</div>;
  }

  const { clearIntegrationFormValues } = useStoreFormValues();
  const {
    sortedFields,
    formJson,
    loading: getPluginInProgress,
    error,
    isSource,
    category,
    shouldFormRender,
    refetch: refetchAuthKeys,
  } = useGetPlugin(plugin.id);

  const { createConnector, loading: createInProgress } = useCreateConnector(
    currentEnvironment,
    plugin.id,
    sortedFields,
    isSource,
    category
  );

  const { displayName, name, imageUrl, id } = plugin;

  const { updateInProgress, updateConnector, publishConnector } =
    useUpdateConnectorPublishStatus(
      connectorId,
      currentEnvironment,
      sortedFields,
      updateConnectorStatus,
      connectorName,
      displayName
    );

  useResetForm(() => {
    clearIntegrationFormValues();

    if (typeof refetch === 'function') {
      void refetch();
    }
  });

  const onSubmit = async (formValues: unknown) => {
    const { name } = formValues as Record<string, any>;

    if (_isEmpty(connectorId)) {
      try {
        const { data } = await createConnector(formValues);

        setConnectorName(name);

        if (!_isNil(data)) {
          handleSetCheckSumByEntityName(
            'integrations',
            data.createConnector.checksum
          );
        }

        const connectorId = data?.createConnector.id;

        if (!_isEmpty(connectorId) && !_isNil(connectorId)) {
          setConnectorId(connectorId);
        }
      } catch (error: unknown) {
        showGraphQlErrorToast(error);
      }
    } else {
      try {
        const checksum = handleGetCheckSumByEntityName('integrations');

        const { data } = await updateConnector(formValues, checksum ?? '');

        if (!_isNil(data)) {
          handleSetCheckSumByEntityName(
            'integrations',
            data.updateConnector.checksum
          );
        }

        updateConnectorStatus(currentEnvironment, false, false, true);
        setConnectorName(name);
      } catch (error: unknown) {
        showGraphQlErrorToast(error);

        if (error instanceof Error) {
          if (error.message.includes(checksumMessage)) {
            setShowChecksumPopup({
              showPopup: true,
              metaData: {
                connectorId,
                connectorName,
                type: displayName,
              },
            });
          }
        }
      }
    }
  };

  const isLoading = getPluginInProgress;

  if (!_isNil(error)) {
    return <div>error</div>;
  }

  return (
    <IntegrationSheet
      shouldFormRender={shouldFormRender}
      connectorId={connectorId}
      connectorName={connectorName}
      connectorStatus={connectorStatus}
      displayName={displayName}
      pluginName={name}
      imageUrl={imageUrl}
      isCreatingOrUpdating={updateInProgress || createInProgress}
      isLoading={isLoading}
      onSubmit={onSubmit}
      publishConnector={publishConnector}
      stagingSortedFields={sortedFields}
      productionSortedFields={sortedFields}
      formJson={formJson}
      formType="create"
      pluginId={id}
      toggleEnvironment={toggleEnvironment}
      updateConnectorStatus={updateConnectorStatus}
      currentStage={currentStage}
      from={from}
      onPublish={onPublish}
      refetchAuthKeys={refetchAuthKeys}
    />
  );
}
