import { Inline, PadBox } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import _isNil from 'lodash/isNil';
import type { ReactNode } from 'react';
import { TooltipReact, Typography } from 'ui';

type PluginFieldContainerProps = {
  label?: string;
  children: ReactNode;
  required: boolean;
  tooltipText?: string;
};

export function PluginFieldContainer({
  children,
  label,
  required,
  tooltipText,
}: PluginFieldContainerProps) {
  return (
    <PadBox padding=".1rem">
      <Stack gutter=".4rem">
        <Inline gutter=".8rem" align="center">
          <Inline gutter={8}>
            <Typography fontWeight={700}>{label}</Typography>
            {!_isNil(tooltipText) && (
              <TooltipReact id={`field-${!_isNil(label) ? label : ''}`}>
                <Typography>{tooltipText}</Typography>
              </TooltipReact>
            )}
          </Inline>
          {!required && (
            <Typography name="secondarySmall">(optional)</Typography>
          )}
        </Inline>

        {children}
      </Stack>
    </PadBox>
  );
}
