import { Inline } from '@bedrock-layout/inline';
import { Stack } from '@bedrock-layout/stack';
import _isNil from 'lodash/isNil';

import { Button, TextButton } from '../../Button';
import { Image } from '../../Image';
import { Typography } from '../../Typography';
import { FileItem, SelectFileContainer } from './GSheetPicker.styled';

export type GSheetPickerProps = {
  fileIconUrl: string;
  crossIconUrl: string;
  handleOpenPicker: () => void;
  handleDeleteFile: () => void;
  name?: string;
  disabled?: boolean;
  hasError?: boolean;
  value?: {
    label: string;
    value: string;
  };
  selectedFile?: {
    label: string;
    value: string;
  };
};

export const GSheetPicker = ({
  selectedFile,
  hasError = false,
  disabled = false,
  value,
  fileIconUrl,
  crossIconUrl,
  handleOpenPicker,
  handleDeleteFile,
}: GSheetPickerProps) => {
  return (
    <Stack gutter={'0.5rem'}>
      <SelectFileContainer align="center">
        <FileItem hasData={!_isNil(value)} hasError={hasError}>
          {_isNil(value) ? (
            <Typography>Click ‘Add Sheet’ to upload a sheet</Typography>
          ) : (
            <>
              <Inline gutter="1.5rem" justify="center" align="center">
                <img src={fileIconUrl} alt="file icon" />
                <Typography fontWeight={700}>{value.label}</Typography>
              </Inline>
              <TextButton disabled={disabled} onClick={handleDeleteFile}>
                <Image src={crossIconUrl} alt="minus" size="xs" />
              </TextButton>
            </>
          )}
        </FileItem>
        <Button disabled={disabled} onClick={handleOpenPicker}>{`${
          _isNil(value) ? 'Add' : 'Update'
        } Sheet`}</Button>
      </SelectFileContainer>
    </Stack>
  );
};
