import { Inline } from '@bedrock-layout/primitives';
import { Typography } from 'ui';

import { HeaderDataStyled } from './RemoteConfig.styled';

export function TableHeader() {
  return (
    <Inline>
      {['Name', 'Value'].map((th, index) => (
        <HeaderDataStyled key={index}>
          <Typography fontWeight={700} element="span">
            {th}
          </Typography>
        </HeaderDataStyled>
      ))}
    </Inline>
  );
}
