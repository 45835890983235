import { gql, useLazyQuery } from '@apollo/client';

import {
  UseGetRuleListDataModel,
  UseGetRuleVariableModel,
} from '../../../Rules/components/RuleSet/models';

const getRuleListQuery = gql`
  query getRule($page: Int, $perPage: Int, $filters: QueryOperators) {
    getRule(perPage: $perPage, page: $page, filters: $filters) {
      data {
        id
        name
        type
      }
    }
  }
`;

export function useGetRuleList() {
  return useLazyQuery<
    UseGetRuleListDataModel,
    UseGetRuleVariableModel | { filters: Record<string, any> }
  >(getRuleListQuery, {
    fetchPolicy: 'cache-and-network',
  });
}
