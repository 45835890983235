import { gql, useLazyQuery } from '@apollo/client';

const getMappedConnectorsQuery = gql`
  query getConnector($filters: QueryOperators) {
    getConnector(filters: $filters) {
      data {
        name
        id
        staging {
          isTested
          isPublish
        }
      }
    }
  }
`;

export function useGetMappedConnectorsById() {
  return useLazyQuery<Record<string, any>>(getMappedConnectorsQuery);
}
