import { Inline, PadBox } from '@bedrock-layout/primitives';
import { Expander, Typography } from 'ui';

import type { WorkflowAttribute } from '../../../../../models/models';
import { TestValue } from './TestValue/TestValue';

type TestValuesMappedProps = {
  value: WorkflowAttribute;
  isNoCode: boolean;
  index: number;
  params: Record<string, any>;
};

export function TestMappedValues({
  value,
  isNoCode,
  index,
  params,
}: TestValuesMappedProps) {
  if (isNoCode) {
    return (
      <Inline
        gutter="1.5rem"
        align="center"
        style={{
          border: '1px solid #ddd',
        }}
      >
        <div
          style={{
            width: '40%',
            height: '3rem',
            padding: '0.4rem 0.5rem',
            borderRight: '1px solid #ddd',
          }}
        >
          <Typography fontWeight={700} name="heading3">
            {value.keyName}:
          </Typography>
        </div>

        <div
          style={{
            width: '60%',
            height: '3rem',
            padding: '0.4rem 0.5rem',
          }}
        >
          <TestValue
            value={
              ['restAPI', 'json', 'list'].includes(value.dataType)
                ? JSON.stringify(params[value.keyName])
                : String(params[value.keyName])
            }
            key={`value_${index}`}
          />
        </div>
      </Inline>
    );
  }

  return (
    <Inline gutter={0}>
      <Expander
        expanderPosition="left"
        expanderTitleGap="0.5rem"
        expanderGap="0.5rem"
        title={
          <Typography fontWeight={700} name="heading3">
            {value.keyName}
          </Typography>
        }
      >
        <PadBox
          padding={{
            left: '3rem',
          }}
        >
          <Typography name="secondarySmall">
            {['restAPI', 'json', 'list'].includes(value.dataType)
              ? JSON.stringify(params[value.keyName])
              : String(params[value.keyName])}
          </Typography>
        </PadBox>
      </Expander>
    </Inline>
  );
}
