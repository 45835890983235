import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  Typography,
  useCurrentLayer,
} from 'ui';

import { RemoteConfigDeleteButtonProps } from './RemoteConfigDeleteButton';

type DeleteRemoteConfigModalProps = RemoteConfigDeleteButtonProps;

export default function DeleteRemoteConfigModal({
  deleteConfig,
  deletingIndex,
  title,
}: DeleteRemoteConfigModalProps) {
  const { close } = useCurrentLayer();

  return (
    <Modal title={title} size="medium">
      <ModalContent>
        <Typography name="paragraph">
          Are you sure you want to delete the attribute? If used in any rule or
          ruleset, it might fail.
        </Typography>
      </ModalContent>

      <ModalFooter>
        <Button appearance="neutral" onClick={close}>
          Cancel
        </Button>

        <Button
          appearance="danger"
          onClick={async () => {
            deleteConfig(deletingIndex);
            close();
          }}
        >
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  );
}
