export const StatusCode = Object.freeze({
  ACCESS_TOKEN: 'access_token_error',
  ACCESS_TOKEN_EXPIRED: 'access_token_expired',
  ACCESS_TOKEN_NOT_FOUND: 'access_token_not_found',
  EMAIL_ALREADY_VERIFIED: 'email_already_verified',
  EMAIL_OR_PASSWORD_WRONG: 'login_error',
  INVALID_ID: 'invalid_id',
  INVALID_TOKEN_ID: 'invalid_token_id',
  JSON_BINDING: 'json_invalid',
  LOGIN_REQUIRED: 'login_required',
  PASSWORD_RESET_TOKEN_EXPIRE: 'password_reset_token_expired',
  QUESTION_UPDATE: 'profile_update_error',
  RECORD_NOT_FOUND: 'record_not_found',
  REDIS: 'redis_error',
  REFRESH_TOKEN_EXPIRED: 'refresh_token_expired',
  REQUEST_PARAMS: 'request_param_error',
  SERVER: 'server_error',
  SERVER_ROUTE_NOT_FOUND: 'route_not_found',
  SESSION_ID_NOT_FOUND: 'session_id_not_found',
  USER_ALREADY_DELETED: 'user_deleted',
  USER_ALREADY_EXIST: 'already_exist',
  USER_CREATED_LOGIN_REQUIRED: 'user_created_login_required',
  USER_NOT_EXIST: 'user_not_exist',
  COMPLETED: 'Completed',
  FAILED: 'Failed',
  RUNNING: 'Running',
});
