import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Typography } from 'ui';

export const BaseUrl = styled(Typography)`
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
`;

export const BodyContainer = styled.div`
  height: 24rem;
`;

export const LinkStyled = styled(Link)`
  color: var(--color-primary1);
  text-decoration: none;
`;
