import { MdOutlineClose } from 'react-icons/md';
import { IconButton, Typography } from 'ui';

import { ParamElementContainer } from './ParameterPopover.styled';

type ParamElementProps = {
  item: string;
  onRemove: (item: string) => void;
};

export function ParamElement({ item, onRemove }: ParamElementProps) {
  return (
    <ParamElementContainer align="center" gutter="0.5rem">
      <Typography>{item}</Typography>
      <IconButton onClick={() => onRemove(item)}>
        <MdOutlineClose />
      </IconButton>
    </ParamElementContainer>
  );
}
