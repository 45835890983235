export const dataTypes = Object.freeze({
  string: 'string',
  numeric: 'numeric',
  dateTime: 'dateTime',
  boolean: 'boolean',
  date: 'date',
  json: 'json',
  list: 'list',
});

export const fieldDefaultValue = {
  string: '',
  numeric: '',
  dateTime: null,
  date: null,
  boolean: true,
  json: '{}',
  list: '[]',
};
