import { Inline } from '@bedrock-layout/primitives';
import { IoMdSync } from 'react-icons/io';
import { Typography } from 'ui';

export function AutoSaving() {
  return (
    <Inline gutter={4} align="center">
      <IoMdSync color="var(--color-lightGray)" />
      <Typography name="secondarySmall">Saving...</Typography>
    </Inline>
  );
}
