import { PadBox } from '@bedrock-layout/padbox';
import { Inline, Stack } from '@bedrock-layout/primitives';
import _isNil from 'lodash/isNil';
import { useMemo, useState } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { FiRefreshCw } from 'react-icons/fi';
import { IconButton, Typography } from 'ui';

import { ConnectorAndPluginModel } from '../../../pages/Integrations/types';
import { ConnectorList } from '../../../pages/Workflow/components/Nodes/AddNode/ControlPopover/ConnectorList/ConnectorList';
import { ControlItem } from '../../../pages/Workflow/components/Nodes/AddNode/ControlPopover/ControlItem';
import {
  ControlHeader,
  ControlList,
} from '../../../pages/Workflow/components/Nodes/AddNode/ControlPopover/ControlPopover.styled';
import type {
  ControlItemsType,
  ControlListType,
} from '../../../pages/Workflow/models/models';
import { DIFFERENT_TABS_V2 } from '../../../utils/constant';
import { RefreshStyled } from '../../rules/forms/CustomAttributeSheet/DataSourceAttribute/DataSourceLauncher.styled';
import { CreateActionContainerStyled } from './CreateActionContainer.styled';

type CreateActionContainerProps = {
  controlList: ControlListType[];
  handleClick: (item: any) => void;
  hideHeader?: boolean;
  handleRefresh?: () => void;
  refetchCount?: number;
};

export function CreateActionContainer({
  controlList = [],
  handleClick,
  hideHeader = false,
  handleRefresh,
  refetchCount = 0,
}: CreateActionContainerProps) {
  const [currentTab, setCurrentTab] = useState<ControlItemsType>({
    header: 'Select Type',
    currentTab: 0,
  });
  const [selectedPlugin, setSelectedPlugin] = useState('');
  const [metaData, setMetaData] = useState<Record<string, any>>();

  const handleSelectType = (
    type: string,
    entityId?: string,
    metaData?: Record<string, any>
  ) => {
    switch (type) {
      case 'plugin': {
        const item = DIFFERENT_TABS_V2.find((tab) => tab.currentTab === 3);

        if (!_isNil(item)) {
          setCurrentTab(item);
          setSelectedPlugin(entityId ?? '');
          setMetaData(metaData);
        }

        break;
      }
    }
  };

  const handleAddConnector = (item: ConnectorAndPluginModel) => {
    // eslint-disable-next-line
    handleClick({
      key: item.plugin.name,
      value: item.id,
    });
  };

  const componentByTab = useMemo(() => {
    switch (currentTab.currentTab) {
      case 0:
        return (
          <Stack>
            <ControlList padding="0.8rem">
              {controlList.map((item, index) => (
                <ControlItem
                  item={item}
                  key={index}
                  onItemClick={handleSelectType}
                />
              ))}
            </ControlList>

            <PadBox padding={['1rem', 0]}>
              {controlList.length === 0 && (
                <PadBox padding="1rem">
                  <Typography>No plugins available</Typography>
                </PadBox>
              )}
            </PadBox>
          </Stack>
        );

      case 3:
        return (
          <ConnectorList
            onConnectorSelect={handleAddConnector}
            onItemClick={handleSelectType}
            pluginName={selectedPlugin}
            metaData={metaData}
            doRefetch={refetchCount}
          />
        );

      default:
        return null;
    }
  }, [currentTab, controlList, refetchCount]);

  const onBackClick = (backIndex: number) => {
    const item = DIFFERENT_TABS_V2.find((tab) => tab.currentTab === backIndex);

    if (!_isNil(item)) {
      setCurrentTab(item);
    }
  };

  return (
    <CreateActionContainerStyled>
      {hideHeader ? null : (
        <Inline stretch="start">
          <ControlHeader padding="1rem">
            <Inline gutter="1rem" align="center">
              {!_isNil(currentTab.backTo) && (
                <IconButton onClick={() => onBackClick(currentTab.backTo ?? 0)}>
                  <FaArrowLeft size={18} />
                </IconButton>
              )}

              <Typography>{currentTab.header}</Typography>
            </Inline>
          </ControlHeader>
          {!_isNil(handleRefresh) && (
            <RefreshStyled onClick={() => handleRefresh()} $showBorder={false}>
              <FiRefreshCw />
            </RefreshStyled>
          )}
        </Inline>
      )}

      {componentByTab}
    </CreateActionContainerStyled>
  );
}
