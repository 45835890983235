import styled from 'styled-components';

export const OnPremiseContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh);
  gap: 2rem;
  padding: 1rem;
`;
