import { Stack } from '@bedrock-layout/stack';
import _isNil from 'lodash/isNil';
import { UseControllerProps, UseFormSetValue, useWatch } from 'react-hook-form';
import { CheckboxField, Typography } from 'ui';

import { TestNodeTypes } from '../SimpleRule/models';
import { testNodeComponents } from './TestNodeComponents';

type TestNodeFieldProps = Omit<UseControllerProps<any>, 'name'> & {
  index: number;
  nodeKey: string;
  dataType: string;
  isNullable: boolean;
  isOptional?: boolean;
  isDisabled?: boolean;
  nodeType?: 'test' | 'cronAttributes';
  setValue?: UseFormSetValue<any>;
};

function isOfDataType(dataType: string): dataType is TestNodeTypes {
  return [
    'string',
    'numeric',
    'boolean',
    'date',
    'dateTime',
    'json',
    'restAPI',
    'list',
  ].includes(dataType);
}

export function TestNodeField({
  index,
  control,
  nodeKey,
  dataType,
  isNullable,
  isOptional = false,
  isDisabled = false,
  nodeType = 'test',
  setValue,
}: TestNodeFieldProps) {
  const Component = isOfDataType(dataType)
    ? testNodeComponents[dataType]
    : null;

  if (_isNil(Component)) {
    return null;
  }

  const isDataNull: boolean = useWatch({
    control,
    name: `${nodeType}.${index}.${nodeKey}.isNullable`,
  });

  const isDataOptional: boolean = useWatch({
    control,
    name: `${nodeType}.${index}.${nodeKey}.isOptional`,
  });

  return (
    <Stack gutter={8}>
      <Component
        dataType={dataType}
        index={index}
        nodeKey={nodeKey}
        control={control}
        isNullable={isNullable || isOptional}
        disabled={isDataNull || isDataOptional || isDisabled}
        nodeType={nodeType}
        setValue={setValue}
        from="test-sheet"
      />

      {isNullable && (
        <CheckboxField
          control={control}
          name={`${nodeType}.${index}.${nodeKey}.isNullable`}
          showError
          useId={`${nodeType}.${index}.${nodeKey}.isNullable`}
          label={
            <Typography
              name={isDataOptional ? 'secondaryXs' : 'paragraphSmall'}
            >
              Send null value
            </Typography>
          }
          disabled={isDataOptional || isDisabled}
        />
      )}

      {isOptional && (
        <CheckboxField
          control={control}
          name={`${nodeType}.${index}.${nodeKey}.isOptional`}
          showError
          useId={`${nodeType}.${index}.${nodeKey}.isOptional`}
          label={
            <Typography name="paragraphSmall">
              Don&apos;t send attribute
            </Typography>
          }
          disabled={isDisabled}
        />
      )}
    </Stack>
  );
}
