import { useAtom } from 'jotai';
import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { toasts } from 'ui';

import { getPropertyIfExists } from '../../../utils/common';
import {
  TESTABLE_NODES,
  sourceTypesToIgnoreOnValueReplacement,
} from '../../../utils/constant';
import {
  changedNodeIdsAtom,
  workflowEdgesAtom,
  workflowIdAtom,
  workflowNodesAtom,
  workflowStatusAtom,
} from '../atoms/atoms';
import {
  getAllParentLoopNodesForCurrentNode,
  getAllPredecessorsSkippingType,
  getExecutedValueAndStatus,
  sanitizedStringV2,
} from '../utils/common';
import { statusListForDataUpdate } from '../utils/constant';
import { useTestWorkflow } from './restApi/useTestWorkflow';
import { useGenerateDataset } from './useGenerateDataset';
import { useGetDataset } from './useGetDataset';
import { WorkflowNodeType } from './useOpenWorkflow';

type UseTestGSheetNodeProps = {
  localData: any;
  id: string;
};

export function useTestGSheetNode({ localData, id }: UseTestGSheetNodeProps) {
  const [workflowId] = useAtom(workflowIdAtom);
  const [, setChangedNodeIds] = useAtom(changedNodeIdsAtom);
  const [workflowNodes] = useAtom(workflowNodesAtom);
  const [workflowEdges] = useAtom(workflowEdgesAtom);
  const [workflowStatus] = useAtom(workflowStatusAtom);

  const [parentNodes, setParentNodes] = useState<WorkflowNodeType[]>([]);
  const [parentLoopNodes, setParentLoopNodes] = useState<WorkflowNodeType[]>(
    []
  );

  const [isTesting, setIsTesting] = useState(false);
  const [currentStatus, setCurrentStatus] = useState<string>(
    localData.status ?? ''
  );

  const workflowNode = workflowNodes.find((wn) => wn.id === id);

  const {
    testWorkflow,
    data: gSheetData,
    error: gSheetError,
    isLoading: isWorkflowTesting,
  } = useTestWorkflow();

  const { updatedDataSet } = useGetDataset({
    parentNodes,
    parentLoopNodes,
  });

  const { tokens } = useGenerateDataset({
    id,
    updatedDataset: updatedDataSet,
  });

  useEffect(() => {
    if (!_isNil(gSheetData)) {
      if (
        !_isNil(workflowNode) &&
        statusListForDataUpdate.includes(workflowStatus)
      ) {
        const newWorkflowNode = workflowNode;
        const exec = getExecutedValueAndStatus(gSheetData);
        newWorkflowNode.data.status = exec.status;

        newWorkflowNode.data.executedValue = exec.executedValue;

        setChangedNodeIds([]);
        setCurrentStatus(exec.status);

        if (exec.status !== 'error') {
          toasts.success('Node tested successfully', 'node-test-success');
        } else {
          toasts.error('Node test failed', 'node-test-fail');
        }

        setTimeout(() => {
          localData.onWorkflowNodeChange(newWorkflowNode);
        }, 100);
      }
      setIsTesting(false);
    }
  }, [gSheetData]);

  const testData = async (
    data: Record<string, any>,
    onSuccess?: () => void
  ) => {
    if (
      parentNodes.find(
        (node) =>
          TESTABLE_NODES.includes(node.type ?? '') &&
          node.data.status !== 'success'
      ) != null
    ) {
      setIsTesting(false);

      return toasts.error(
        'Previous node(s) are  not tested. Please test the previous node(s) before proceeding',
        'parent-node'
      );
    }

    const params: Record<string, any> = {};
    _forEach(data.input, (currInput: Record<string, any>, key: string) => {
      if (
        (_isNil(currInput) ||
          _isEmpty(currInput) ||
          _isNil(currInput.value) ||
          _isEmpty(currInput.value)) &&
        !['filterList', 'filterConditions'].includes(key)
      ) {
        params[key] = ['headers', 'queryParams'].includes(key) ? [] : '';
      } else if (!['filterList', 'filterConditions'].includes(key)) {
        params[key] = data.input[key]?.value;
      } else if (key === 'filterConditions') {
        params[key] = JSON.stringify(data.input[key].executedValue);
      } else {
        let mappedValue: any = null;

        // eslint-disable-next-line
        if (!!data.input[key].attribute) {
          mappedValue =
            !_isNil(data.input[key].source) &&
            !_isEmpty(data.input[key].source) &&
            !_isNil(data.input[key].attribute) &&
            !_isEmpty(data.input[key].attribute)
              ? getPropertyIfExists(
                  JSON.parse(
                    JSON.stringify(
                      Object.keys(
                        updatedDataSet[data.input[key].source].attributes
                      ).reduce((acc, curr) => {
                        return {
                          ...acc,
                          [curr]:
                            updatedDataSet[data.input[key].source ?? '']
                              .attributes[`${curr}`].executedValue,
                        };
                      }, {})
                    )
                  ) ?? {},
                  data.input[key].attribute ?? ''
                )
              : data.input[key].value;
        } else {
          mappedValue = sanitizedStringV2(
            data.input[key].value ?? '',
            updatedDataSet,
            sourceTypesToIgnoreOnValueReplacement
          );
          try {
            mappedValue = JSON.parse(mappedValue);
          } catch {}

          if (
            key === 'filterList' &&
            data.input.mappingMethod?.value === 'manual'
          ) {
            mappedValue = data.input[key]?.executedValue;
          }
        }

        params[key] = mappedValue !== '' ? mappedValue ?? null : '{}';
      }
    });

    try {
      testWorkflow({
        id: workflowId ?? '',
        nodeId: id,
        params,
      });
    } catch (error) {}

    if (typeof onSuccess === 'function') {
      onSuccess();
    }
  };

  useEffect(() => {
    const directParents = getAllPredecessorsSkippingType(
      id,
      workflowNodes,
      workflowEdges,
      'addNode'
    );

    const parentLoopNodes = getAllParentLoopNodesForCurrentNode(
      id,
      workflowNodes
    );

    setParentNodes(directParents);
    setParentLoopNodes(parentLoopNodes);
  }, [JSON.stringify(workflowNodes)]);

  return {
    testData,
    gSheetData,
    gSheetError,
    updatedDataSet,
    tokens,
    parentNodes,
    currentStatus,
    isTesting,
    setCurrentStatus,
    setIsTesting,
    isWorkflowTesting,
  };
}
