import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import styled from 'styled-components';

export const FooterStyled = styled(PadBox)`
  position: sticky;
  bottom: 0;
  margin-top: auto;
  border-top: 1px solid var(--color-lightGray);
  background-color: var(--color-white);
`;

export const RuleActionContainer = styled(Stack)`
  border: 1px solid var(--color-lightGray6);
  border-radius: 2px;
`;

export const Separator = styled.div`
  border: 1px solid var(--color-lightGray6);
`;

export const RuleContainer = styled(Inline)`
  padding: 0.8rem 1.6rem;
  border: 1px solid var(--color-solitude);
  border-radius: 0.4rem;
`;
