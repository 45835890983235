import styled from 'styled-components';

export const ContainerHeader = styled.div<{ $isEdit?: boolean }>`
  padding: 0.5rem 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  border: 1px solid var(--color-gainsboro);
  border-radius: 0.4rem;

  ${({ $isEdit = false }) =>
    $isEdit &&
    `background: var(--color-solitude); color: var(--color-primary1); border: none;`}
`;
