import { useState } from 'react';

import { Environment } from '../../../../utils/constant';
import type { RuleEnvironment } from '../../../../utils/constant';

export type ToggleEnvironmentFunction = (tabIndex: number) => void;

export type UseCurrentEnvironmentReturn = {
  currentEnvironment: RuleEnvironment;
  toggleEnvironment: ToggleEnvironmentFunction;
};

export function useCurrentEnvironment(): UseCurrentEnvironmentReturn {
  const [currentEnvironment, setCurrentEnvironment] = useState<RuleEnvironment>(
    Environment.STAGING
  );

  const toggleEnvironment = (tabIndex: number) => {
    // tabIndex 0 means staging
    // tabIndex 1 means production
    if (tabIndex === 0) {
      setCurrentEnvironment(Environment.STAGING);
    } else {
      setCurrentEnvironment(Environment.PRODUCTION);
    }
  };

  return {
    currentEnvironment,
    toggleEnvironment,
  };
}
