import { Inline } from '@bedrock-layout/primitives';
import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  Typography,
  useCurrentLayer,
} from 'ui';

import { envMap } from '../../../utils/constant';
import { ContainerStyled } from './ApprovalFlowEnableWarningModal.styled';

type ApprovalFlowEnableWarningModalProps = {
  openRequestReviewModal?: () => void;
};

export function ApprovalFlowEnableWarningModal({
  openRequestReviewModal,
}: ApprovalFlowEnableWarningModalProps) {
  const { close } = useCurrentLayer();

  const handleRequestReview = () => {
    close();

    if (typeof openRequestReviewModal === 'function') {
      openRequestReviewModal();
    }
  };

  return (
    <Modal
      size="large"
      title={
        <Typography name="heading2">
          <Inline
            justify="center"
            align="center"
            stretch="start"
            gutter={'1rem'}
          >
            <img
              style={{ maxWidth: '4rem' }}
              src={`${envMap.VITE_ASSETS_URL}website/icons/warning_icon.svg`}
              alt="warning image"
            />
            Alert
          </Inline>
        </Typography>
      }
      hideCloseButton
    >
      <ModalContent>
        <ContainerStyled>
          <Typography name="heading3" fontWeight={700}>
            The approval flow has been Turned On, you have to add an approver in
            order to publish
          </Typography>
        </ContainerStyled>
      </ModalContent>

      <ModalFooter justify="center">
        <Button
          appearance="neutral"
          onClick={() => {
            window.location.reload();
          }}
        >
          Refresh
        </Button>
        <Button onClick={handleRequestReview}>Request To Review</Button>
      </ModalFooter>
    </Modal>
  );
}
