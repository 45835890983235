import { AxiosResponse } from 'axios';
import { type UseControllerProps, useWatch } from 'react-hook-form';

import { useAxiosPrivate } from '../../../../hooks';
import { convertBodyParams } from '../../utlis';

type UseGetDataFromRestApiProps = UseControllerProps<any> & {
  formJson: Record<string, any>;
  parentFormData: Record<string, any>;
  formKeyPrefix: string;
};

export function useGetDataFromRestApi({
  control,
  formJson,
  parentFormData,
  formKeyPrefix,
}: UseGetDataFromRestApiProps) {
  const formValues = useWatch({
    control,
  });
  const { axiosPrivate } = useAxiosPrivate();
  const fetchDataFromRestApi = async (apiSpec: Record<string, any>) => {
    let response: AxiosResponse<any, any> | null = null;
    const {
      method,
      endpoint,
      bodyParams = {},
      fetchParent = {},
      queryParams = {},
    } = apiSpec;

    const updatedQueryParams = convertBodyParams(
      queryParams,
      fetchParent,
      formValues,
      formJson,
      parentFormData,
      formKeyPrefix
    );

    const queryString = Object.keys(updatedQueryParams)
      .map((key) => `${key}=${updatedQueryParams[key] as string}`)
      .join('&');

    const url = `${endpoint as string}?${queryString}`;

    try {
      if (method === 'GET') {
        response = await axiosPrivate.get(url);
      } else if (method === 'POST') {
        const updatedBodyParams = convertBodyParams(
          bodyParams,
          fetchParent,
          formValues,
          formJson,
          parentFormData,
          formKeyPrefix
        );

        response = await axiosPrivate.post(url, updatedBodyParams);
      }

      return response;
    } catch (err) {
      return err;
    }
  };

  return { fetchDataFromRestApi };
}
