import type { Control } from 'react-hook-form';
import { CheckboxField } from 'ui';

import { AddConfigValueType } from '../../../models';
import type { RemoteConfigFormValues } from '../../RemoteConfig';

type RemoteConfigBooleanProps = {
  control: Control<RemoteConfigFormValues>;
  name: string;
  value: AddConfigValueType;
  disabled: boolean;
};

export function RemoteConfigBooleanField({
  name,
  value,
  control,
  disabled,
}: RemoteConfigBooleanProps) {
  return (
    <CheckboxField
      defaultValue={value}
      control={control}
      name={name}
      showError
      appearance="switch"
      useId={name}
      disabled={disabled}
    />
  );
}
