import { Inline, PadBox } from '@bedrock-layout/primitives';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useForm } from 'react-hook-form';
import {
  Button,
  ExpandingTextField,
  NectedEditorField,
  Sheet,
  Typography,
  useCurrentLayer,
} from 'ui';

import { editorDomain } from '../../../../../utils/constant';
import { SqlEditorContainer } from '../RuleBlock/RuleSqlCondition.styled';
import { simpleRuleNodesAtom } from '../SimpleRule';
import { SqlNodeModel } from '../models';
import { sqlNodeScheme } from '../schema';
import { FooterStyled, NodeContainerStyled } from './CodeNodeSheet.styled';

export type CodeNodeSheetProps = {
  ruleId: string;
};

export function CodeNodeSheet({ ruleId }: CodeNodeSheetProps) {
  const [ruleList, setRuleList] = useAtom(simpleRuleNodesAtom);
  const { close: closeSqlNode } = useCurrentLayer();

  const { control, handleSubmit } = useForm<SqlNodeModel>({
    resolver: zodResolver(sqlNodeScheme),
    defaultValues: {
      sqlNodeName:
        _isEmpty(ruleList[ruleId].name) || _isNil(ruleList[ruleId].name)
          ? 'Untitled'
          : ruleList[ruleId].name,
      query:
        _isEmpty(ruleList[ruleId].name) || _isNil(ruleList[ruleId].name)
          ? '/* Write your code here */'
          : ruleList[ruleId].query,
    },
    mode: 'onSubmit',
  });

  const onSubmit = (data: SqlNodeModel) => {
    setRuleList((prev) => ({
      ...prev,
      [ruleId]: { ...prev[ruleId], name: data.sqlNodeName, query: data.query },
    }));

    closeSqlNode();
  };

  return (
    <Sheet size="medium">
      <form onSubmit={handleSubmit(onSubmit)}>
        <NodeContainerStyled gutter="1rem">
          <PadBox padding="1rem">
            <Inline align="start" gutter={16}>
              <Typography name="heading2">
                <ExpandingTextField control={control} name="sqlNodeName" />
              </Typography>
            </Inline>
            <br />
            <SqlEditorContainer>
              <NectedEditorField
                control={control}
                name="query"
                domain={editorDomain}
                mode="sqlserver"
              />
            </SqlEditorContainer>
          </PadBox>

          <FooterStyled padding={[4, 8]}>
            <Inline justify="end">
              <Button type="submit" appearance="filled">
                Save
              </Button>
            </Inline>
          </FooterStyled>
        </NodeContainerStyled>
      </form>
    </Sheet>
  );
}
