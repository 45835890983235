import { FormEvent, KeyboardEvent } from 'react';

export function stopPropagate(callback: () => void) {
  return (e: FormEvent) => {
    e.preventDefault();
    e.stopPropagation();

    callback();
  };
}

export const handleKeyDown = (e: KeyboardEvent<HTMLElement>) => {
  if (e.code === 'Enter' && !e.shiftKey) {
    e.preventDefault();
  }
};
