import { Inline, PadBox } from '@bedrock-layout/primitives';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import type { UseControllerProps, UseFormSetValue } from 'react-hook-form';
import { useWatch } from 'react-hook-form';
import { NectedSuggestionModel } from 'ui';

import {
  TokenScores,
  hideOptionalCustomAttributes,
} from '../../../../../utils/constant';
import { sendEventToGTMType } from '../../../types';
import {
  getEvaluatedExValueForResult,
  getRequiredKey,
  outputKeyGenerator,
} from '../../../utils/common';
import { DecisionTableResultRow } from '../types';
import { RowResultSelection } from './RowResultSelection';
import { ResultBlockContainer } from './RowsFieldArray.styled';

type RowResultFieldArrayProps = Omit<UseControllerProps, 'name'> & {
  setValue: UseFormSetValue<any>;
  index: number;
  rowKey: string;
  isError: boolean;
  handleSendEventToGTM?: (obj: sendEventToGTMType) => void;
};

export function RowResultFieldArray({
  control,
  setValue,
  index,
  rowKey,
  isError,
  handleSendEventToGTM,
}: RowResultFieldArrayProps) {
  const currentResults: DecisionTableResultRow[] | null = useWatch({
    name: `rows.${index}.${rowKey}.ruleResult`,
    control,
  });

  const rows = useWatch({ control, name: 'rows' });

  const results = useWatch({ name: 'results', control });

  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [suggestionsObjs, setSuggestionsObjs] = useState<
    NectedSuggestionModel[]
  >([]);

  useEffect(() => {
    const suggestionList: string[] = [];
    const suggestionListObj: NectedSuggestionModel[] = [];

    results.forEach((field: any, index: number) => {
      const fieldKey = getRequiredKey(field, ['id']);
      const currentResult = field[fieldKey];

      const rowKey = getRequiredKey(rows[0], ['id']);
      const firstRow = rows[0][rowKey];

      const ruleResultKey = getRequiredKey(firstRow.ruleResult[index], ['id']);

      const firstOutput = firstRow.ruleResult[index]?.[ruleResultKey];

      const source = currentResult.source ?? null;
      const attribute = currentResult.attribute ?? null;
      let executedValue = currentResult.executedValue;

      if (
        _isNil(source) &&
        _isNil(attribute) &&
        !['jsFormula', 'json', 'excelFormula', 'list'].includes(
          currentResult.dataType ?? ''
        )
      ) {
        executedValue = getEvaluatedExValueForResult(
          firstOutput?.value,
          firstOutput?.dataType
        );
      }

      if (
        currentResult.dataType === 'string' ||
        currentResult.dataType === 'date'
      ) {
        suggestionList.push(
          `"<<outputData.${currentResult.keyName as string}>>"`
        );

        suggestionListObj.push({
          name: `"<<outputData.${currentResult.keyName as string}>>"`,
          value: `"<<outputData.${currentResult.keyName as string}>>"`,
          meta: currentResult.dataType,
          score: TokenScores.outputData,
          executedValue: firstOutput.value,
        });
      } else {
        suggestionList.push(
          `<<outputData.${currentResult.keyName as string}>>`
        );

        suggestionListObj.push({
          name: outputKeyGenerator(
            currentResult?.keyName,
            ['jsFormula', 'excelFormula'].includes(currentResult?.dataType)
              ? firstOutput.returnType ?? 'unknown'
              : currentResult.dataType
          ),
          value: outputKeyGenerator(
            currentResult?.keyName,
            ['jsFormula', 'excelFormula'].includes(currentResult?.dataType)
              ? firstOutput.returnType ?? 'unknown'
              : currentResult?.dataType
          ),
          meta: ['jsFormula', 'excelFormula'].includes(currentResult.dataType)
            ? firstOutput.returnType ?? 'unknown'
            : currentResult.dataType,
          score: TokenScores.outputData,
          executedValue,
        });
      }
    });

    setSuggestionsObjs(suggestionListObj);
    setSuggestions(suggestionList);
  }, [results]);

  const getKeyNameByIndex = (index: number): string => {
    const key = getRequiredKey(results[index], ['id']);
    const currentResultKey = results[index][key].keyName;

    return currentResultKey;
  };

  const handleClick = (dataType: string) => {
    if (typeof handleSendEventToGTM === 'function') {
      handleSendEventToGTM({
        action: 'edit',
        element: 'result',
        actionName: dataType,
      });
    }
  };

  return (
    <Inline
      align="stretch"
      style={{ backgroundColor: isError ? 'var(--color-snow)' : '' }}
    >
      {currentResults?.map((field, i: number) => {
        const currentKey = Object.keys(field).filter((key) => key !== 'id')[0];

        return (
          <ResultBlockContainer
            key={`field_${i}_${currentKey}`}
            align="center"
            justify="center"
            dataType={field[currentKey].dataType}
            onClick={() => handleClick(field[currentKey].dataType)}
          >
            <PadBox padding={8}>
              <RowResultSelection
                control={control}
                nodeName={`rows.${index}.${rowKey}.ruleResult.${i}.${currentKey}`}
                dataType={field[currentKey].dataType}
                setValue={setValue}
                keyName={getKeyNameByIndex(i)}
                resIndex={i}
                rowIndex={index}
                rowKey={rowKey}
                currentKey={currentKey}
                hideOptionalCustomAttributes={hideOptionalCustomAttributes}
                suggestions={suggestions}
                suggestionsObjs={suggestionsObjs}
              />
            </PadBox>
          </ResultBlockContainer>
        );
      })}

      {_isNil(currentResults) ||
        (currentResults.length === 0 && <ResultBlockContainer />)}
    </Inline>
  );
}
