import { gql } from '@apollo/client';

export const getAuthConfigKeysQuery = gql`
  query getKeys {
    getAuthConfigKeys: getCredentials(
      page: 1
      perPage: 50
      filters: { eq: { type: "API" } }
    ) {
      data {
        type
        name
        id
        value
        authType
        checksum
        isValid
      }
    }
  }
`;
