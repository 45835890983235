import { Inline } from '@bedrock-layout/primitives';
import styled from 'styled-components';

export const AddItemStyled = styled(Inline)`
  background-color: var(--color-white);
  cursor: pointer;
  color: var(--color-primary1);
`;

export const IconButtonStyled = styled.button`
  padding-top: 1rem;
  cursor: pointer;
  border: none;
  background: transparent;

  &.btn-disabled-interceptor {
    background: transparent !important;
    color: var(--color-black) !important;
    cursor: progress !important;
  }
`;
