import { PadBox } from '@bedrock-layout/padbox';
import styled from 'styled-components';

export const LauncherContainer = styled(PadBox)`
  min-inline-size: 15rem;
  min-block-size: 2.5rem;
  border-radius: 4px;
  cursor: pointer;
`;

export const DataTypeLauncher = styled(PadBox)<{
  $isDisabled: boolean;
}>`
  background: var(--color-white);
  border-radius: 4px;
  cursor: pointer;
  outline: 1px solid var(--color-lightGray);

  ${({ $isDisabled = false }) =>
    $isDisabled && 'background: var(--color-whiteSmoke);'}
`;
