import { useCreateRuleByType } from '../../../pages/Rules/hooks/graphql/useCreateRuleByType';
import { useCreateWorkflow } from '../../../pages/Workflow/hooks/graphql/useCreateWorkflow';

export const useCreateEntityByType = (entityType: string, type?: string) => {
  switch (entityType) {
    case 'rule':
      return useCreateRuleByType(type);
    case 'workflow':
    default:
      return useCreateWorkflow();
  }
};
