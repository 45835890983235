import { gql, useMutation } from '@apollo/client';

import { emptyMutationQuery } from '../../../../utils/constant';

const publishRuleMutation = gql`
  mutation publishRule(
    $id: String!
    $checksum: String!
    $title: String
    $description: String
  ) {
    publishRule(
      id: $id
      checksum: $checksum
      title: $title
      description: $description
    ) {
      checksum
      status
      message
    }
  }
`;

const publishDataSetMutation = gql`
  mutation publishDataSet(
    $id: String!
    $checksum: String!
    $title: String
    $description: String
  ) {
    publishDataSet(
      id: $id
      checksum: $checksum
      title: $title
      description: $description
    ) {
      checksum
      status
      message
    }
  }
`;

const publishWorkflowMutation = gql`
  mutation publishWorkflow(
    $id: String!
    $checksum: String!
    $title: String
    $description: String
  ) {
    publishWorkflow(
      id: $id
      checksum: $checksum
      title: $title
      description: $description
    ) {
      checksum
      status
      message
    }
  }
`;

const getQueryBasedonEntity = (entityName: string) => {
  // Dont use aliasing while writing the query
  switch (entityName) {
    case 'rules':
      return publishRuleMutation;
    case 'datasets':
      return publishDataSetMutation;
    case 'workflow':
      return publishWorkflowMutation;
    default:
      return emptyMutationQuery;
  }
};

export function usePublishEntity(entityName: string) {
  return useMutation<Record<string, any>>(getQueryBasedonEntity(entityName));
}
