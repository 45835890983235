import _isNil from 'lodash/isNil';
import type { ReactNode } from 'react';

import { variants } from './Variants/variants';

export type ThresholdVariants = 'basic';
export type ThresholdEntity = 'workflow' | 'rule' | 'node';

export type ThresholdProps = {
  current: number;
  max: number;
  entity: ThresholdEntity;
  message?: ReactNode;
  variant?: ThresholdVariants;
  align?: string;
};

function isOfType(type: string): type is ThresholdVariants {
  return ['basic'].includes(type);
}

export function Threshold({
  current,
  max,
  message,
  entity,
  variant = 'basic',
  align = 'end',
}: ThresholdProps) {
  const Component = isOfType(variant) ? variants[variant] : null;

  if (_isNil(Component)) {
    return null;
  }

  return (
    <>
      {max !== 0 && (
        <Component
          current={current}
          entity={entity}
          max={max}
          message={message}
          align={align}
        />
      )}
    </>
  );
}
