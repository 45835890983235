import { PadBox } from '@bedrock-layout/padbox';
import styled from 'styled-components';

export const ResultBox = styled(PadBox)`
  border: 1px solid var(--color-lightGray);
  border-radius: 4px;
  inline-size: 80%;
  min-block-size: 40rem;
  display: flex;
  flex-direction: column;
`;

export const InfoBlock = styled(PadBox)`
  inline-size: 80%;
  flex-direction: column;
`;

export const OutputBlock = styled(PadBox)`
  background-color: var(--color-whiteSmoke);
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const OutputSection = styled(PadBox)`
  background-color: var(--color-white);
  flex: 1;
  border-radius: 4px;
`;

export const CronRow = styled(PadBox)`
  border-bottom: 1px solid var(--color-lightGray);
  display: flex;
  width: 100%;
`;

export const CronColumn = styled(PadBox)<{ $small: boolean }>`
  width: 70%;
  ${({ $small = false }) =>
    $small &&
    'width: 30%; border-inline-end: 1px solid var(--color-lightGray);'}
`;

export const CronJsonContainer = styled.div`
  block-size: 20rem;
`;
