import { Inline } from '@bedrock-layout/primitives';
import { JsonHeroTreeViewer, NectedEditor, Typography } from 'ui';

import { ExecutionView } from '../../../../../components/jsonOutputField/components/ExecutionView';
import { editorDomain } from '../../../../../utils/constant';
import { JsonTreeContainer } from '../SwitchNodeSheet/SwitchNodeSheet.styled';
import { JsonContainer, OutputContainer } from './ApiTestResult.styled';

type ApiTestResultProps = {
  result: Record<string, any>;
};

export function ApiTestResult({ result }: ApiTestResultProps) {
  return (
    <OutputContainer padding="2rem">
      <Inline gutter="2rem" align="center">
        <Typography name="heading3">Output</Typography>

        <Inline gutter="1rem" align="center">
          <Typography name="success">Success</Typography>
        </Inline>
      </Inline>
      <ExecutionView>
        <JsonContainer>
          <NectedEditor
            readOnly
            defaultValue={JSON.stringify(result, null, '\t')}
            mode="json"
            domain={editorDomain}
          />
        </JsonContainer>

        <JsonTreeContainer>
          <JsonHeroTreeViewer json={result} />
        </JsonTreeContainer>
      </ExecutionView>
    </OutputContainer>
  );
}
