import { Inline, PadBox } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { useAtom } from 'jotai';
import { useMemo } from 'react';
import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { RiDeleteBin6Line } from 'react-icons/ri';
import { Dataset, IconButton, TextField, Typography } from 'ui';

import { arrayMove } from '../../../../../../../utils/common';
import { isWorkflowReadOnlyAtom } from '../../../../../atoms/atoms';
import {
  getConditionLength,
  getRuleNodeFromPath,
} from '../../../../../utils/common';
import { Indicator, IndicatorText } from '../SwitchForm.styled';
import { AddCondition } from './AddCondition';
import { AddGroup } from './AddGroup';
import {
  PathBlockOrder,
  PathBlockProperty,
  PathBlockPropertyMajor,
  PathBlockPropertyMinor,
  PathContainer,
  PathHeader,
} from './PathBlock.styled';
import { PathCondition } from './PathCondition';
import PathGroup from './PathGroup';
import { PathOperator } from './PathOperator';

type PathBlockProps = {
  control?: Control<any>;
  setValue: UseFormSetValue<any>;
  name: string;
  remove: (index: number) => void;
  watch: UseFormWatch<any>;
  index: number;
  path: Record<string, any>;
  updatedDataSet: Record<string, Dataset>;
  size: number;
};

export function PathBlock({
  remove,
  control,
  name,
  index,
  setValue,
  watch,
  path,
  updatedDataSet,
  size,
}: PathBlockProps) {
  const [isWorkflowReadOnly] = useAtom(isWorkflowReadOnlyAtom);

  const groupNode = getRuleNodeFromPath(path);

  const handleChangeOrder = (order: 'up' | 'down') => {
    const paths = watch('paths');

    if (order === 'up') {
      return setValue('paths', arrayMove(paths, index, index - 1));
    }

    setValue('paths', arrayMove(paths, index, index + 1));
  };

  const conditionSize = useMemo(() => {
    return getConditionLength(path);
  }, [path]);

  return (
    <PathContainer>
      <PathBlockProperty>
        <PathBlockPropertyMinor>
          <PathBlockOrder>
            <IconButton
              disabled={index === 0 || isWorkflowReadOnly}
              onClick={() => handleChangeOrder('up')}
            >
              <BiChevronUp />
            </IconButton>
            <Typography>{index + 1}</Typography>
            <IconButton
              disabled={index === size - 1 || isWorkflowReadOnly}
              onClick={() => handleChangeOrder('down')}
            >
              <BiChevronDown />
            </IconButton>
          </PathBlockOrder>
        </PathBlockPropertyMinor>
        <PathBlockPropertyMajor>
          <PathHeader padding="0.6rem">
            <Inline gutter="1rem" align="center">
              <Indicator padding={['0.5rem', '1rem']}>
                <IndicatorText>{index === 0 ? 'IF' : 'ELSE IF'}</IndicatorText>
              </Indicator>

              <TextField
                name={`paths.${index}.${groupNode.key}.name`}
                control={control}
                value={watch(`paths.${index}.${groupNode.key}.name`)}
                size="small"
                showError
                disabled={isWorkflowReadOnly}
              />
            </Inline>

            {size > 1 && (
              <IconButton
                onClick={() => remove(index)}
                disabled={size === 1 || isWorkflowReadOnly}
              >
                <RiDeleteBin6Line />
              </IconButton>
            )}
          </PathHeader>

          <Stack gutter="1rem">
            {groupNode.value?.children.map((id: string, i: number) => {
              const nodeType = watch(`paths.${index}.${id}.nodeType`);

              return (
                <Stack key={`${name}_condition`} gutter="0.5rem">
                  {nodeType === 'group' ? (
                    <PathGroup
                      groupId={id}
                      groupIndex={index}
                      groupName={`${name}.${id}`}
                      index={i}
                      setValue={setValue}
                      name={name}
                      path={path}
                      remove={remove}
                      watch={watch}
                      updatedDataSet={updatedDataSet}
                      control={control}
                      groupKey={id}
                      parentId={groupNode.key}
                      pathKey={groupNode.key}
                      size={conditionSize}
                    />
                  ) : (
                    <PathCondition
                      groupIndex={index}
                      index={i}
                      name={`${name}.${id}`}
                      conditionId={id}
                      remove={remove}
                      setValue={setValue}
                      watch={watch}
                      control={control}
                      updatedDataSet={updatedDataSet}
                      groupKey={groupNode.key}
                      pathKey={groupNode.key}
                      size={conditionSize}
                    />
                  )}

                  {i !== groupNode.value?.children.length - 1 && (
                    <PadBox
                      padding={{
                        left: '1rem',
                      }}
                    >
                      <PathOperator
                        setValue={setValue}
                        groupName={`paths.${index}.${groupNode.key}`}
                        operator={groupNode.value.operator}
                      />
                    </PadBox>
                  )}
                </Stack>
              );
            })}
          </Stack>

          <PadBox padding={['0.5rem', '1rem']}>
            <Inline>
              <AddCondition
                watch={watch}
                groupIndex={index}
                isAlone={(groupNode.value?.children?.length ?? 0) <= 1}
                setValue={setValue}
                control={control}
                groupKey={groupNode.key}
                size={conditionSize}
                groupName={`paths.${index}.${groupNode.key}`}
              />
              <AddGroup
                watch={watch}
                groupIndex={index}
                isAlone={(groupNode.value?.children?.length ?? 0) <= 1}
                setValue={setValue}
                control={control}
                groupKey={groupNode.key}
                size={conditionSize}
              />
            </Inline>
          </PadBox>
        </PathBlockPropertyMajor>
      </PathBlockProperty>
    </PathContainer>
  );
}
