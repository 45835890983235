import { UseControllerProps } from 'react-hook-form';
import { NectedEditorField, NectedSuggestionModel, Typography } from 'ui';

import { PluginFieldContainer } from '../../../pages/Integrations/components/common/PluginFieldContainer';
import type { PluginFieldCommonProps } from '../../../pages/Integrations/types';
import { editorDomain } from '../../../utils/constant';
import { EditorContainer } from '../form.styled';

type TextEditorProps = UseControllerProps<any> &
  PluginFieldCommonProps & {
    newCustomSuggestions?: NectedSuggestionModel[];
    handleGetExecutionValues?: (data: Record<string, any>) => void;
    execValues?: Record<string, any>;
  };

export function TextEditor({
  control,
  name,
  label,
  tooltipText,
  helpText = '',
  disabled = false,
  required = false,
  newCustomSuggestions = [],
  handleGetExecutionValues,
  execValues,
}: TextEditorProps) {
  return (
    <PluginFieldContainer
      label={label}
      required={required}
      tooltipText={tooltipText}
    >
      <EditorContainer>
        <NectedEditorField
          control={control}
          name={name}
          mode="text"
          readOnly={disabled}
          customSuggestions={newCustomSuggestions}
          methods="write"
          domain={editorDomain}
          handleGetExecData={handleGetExecutionValues}
          execValues={execValues}
        />

        <Typography name="secondary2">{helpText}</Typography>
      </EditorContainer>
    </PluginFieldContainer>
  );
}
