import { gql, useQuery } from '@apollo/client';

import type { ConnectorAndPluginModel } from '../../Integrations/types';

export type GetConnectorsQueryResult = {
  getConnector: {
    data: ConnectorAndPluginModel[];
  };
};

export function useGetPublishedConnectors(isSource: boolean = false) {
  const getConnectorsQuery = gql`
    query publishedConnectorList {
      getConnector(
        page: 1
        perPage: 300
        live: false
        ${isSource ? `filter: { isSource: true }` : ''}
      ) {
        data {
          id
          name
          category
          plugin {
            imageUrl
            name
            id
            category
            displayName
          }
          production {
            conf 
          }
          staging {
            conf
            isTested
            isPublish
          }
        }
      }
    }
  `;

  return useQuery<GetConnectorsQueryResult>(getConnectorsQuery, {
    fetchPolicy: 'no-cache',
  });
}
