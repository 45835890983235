import { Stack } from '@bedrock-layout/stack';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import { useEffect } from 'react';
import {
  type Control,
  type UseFormSetValue,
  useController,
  useWatch,
} from 'react-hook-form';
import { BiSolidError } from 'react-icons/bi';
import {
  NectedSuggestionModel,
  TooltipReact,
  Typography,
  toasts,
  useLayer,
} from 'ui';

import { siteConstantsAtom } from '../../atom';
import { useGetConnectorById } from '../../hooks/graphql/useGetConnectorById';
import { getEditorDetailsByPlugin } from '../../pages/DataSets/utils';
import type {
  ResultActionType,
  sendEventToGTMType,
} from '../../pages/Rules/components/SimpleRule/Results';
import { getTooltipText } from '../../utils/common';
import ActionSheet from './ActionSheet';
import { ActionPill, ErrorIcon } from './ActionSheetPill.styled';

type ActionSheetPillProps = {
  index: number;
  connectorId: string;
  suggestions: string[];
  section?: ResultActionType;
  control?: Control<any>;
  field?: Record<string, any>;
  hasError?: boolean;
  setValue?: UseFormSetValue<any>;
  open?: boolean;
  ruleType?: string;
  setOpen?: (val: boolean) => void;
  handleSendEventToGTM?: (obj: sendEventToGTMType) => void;
  suggestionsObjs?: NectedSuggestionModel[];
  hasConnectorError?: boolean;
};

export function ActionSheetPill({
  section = 'thenActionParams',
  control,
  connectorId,
  field,
  index,
  suggestions,
  setValue,
  open = false,
  setOpen,
  ruleType = 'simpleRule',
  handleSendEventToGTM,
  suggestionsObjs,
  hasConnectorError = false,
}: ActionSheetPillProps) {
  const [getConnectorById] = useGetConnectorById();
  const [siteConstants] = useAtom(siteConstantsAtom);

  const { openWithProps: openActionSheet } = useLayer(
    <ActionSheet
      section={section}
      control={control}
      index={index}
      setValue={setValue}
      sheetMode=""
    />
  );

  const name = useWatch({ control, name: `${section}.${index}.name` });

  const openActionSheetByType = async (sheetMode: string) => {
    const data = await getConnectorById({
      variables: {
        connectorId: field?.connectorId,
      },
    });

    if (data.data?.getConnector.data.length === 0) {
      toasts.error(
        `Some issue occurred with this integration, Please check`,
        'connector-not-found'
      );

      return;
    }

    const name = data.data?.getConnector.data[0].plugin.name;

    const editorDetails = getEditorDetailsByPlugin(
      data.data?.getConnector.data[0].plugin
    );

    if (typeof handleSendEventToGTM === 'function' && !open) {
      handleSendEventToGTM({
        action: 'edit',
        element: 'action',
        actionName: name ?? '',
      });
    }

    const sheetIndex =
      ruleType === 'decisionTable' ? index : open ? index + 1 : index;

    openActionSheet({
      section,
      connectorId: field?.connectorId,
      field,
      suggestions,
      suggestionsObjs,
      type: editorDetails?.databaseName ?? name ?? 'mysql',
      editorDetails,
      index: sheetIndex,
      connector: data.data?.getConnector.data[0],
      sheetMode,
    });
  };

  const { fieldState } = useController({
    name: `${section}.${index}`,
    control,
  });

  useEffect(() => {
    if (open) {
      void openActionSheetByType('create');

      if (typeof setOpen === 'function') {
        setOpen(false);
      }
    }
  }, [open]);

  return (
    <Stack>
      <ActionPill
        onClick={async () => await openActionSheetByType('edit')}
        padding={[2, 12]}
        $hasError={!(fieldState.error == null)}
      >
        {hasConnectorError && (
          <ErrorIcon>
            <TooltipReact
              id={`connector_error_${index}`}
              launcher={<BiSolidError color="var(--color-fireEngineRed)" />}
            >
              <Typography>
                {getTooltipText(
                  siteConstants,
                  'integrations',
                  'integrationNotConnected'
                )}
              </Typography>
            </TooltipReact>
          </ErrorIcon>
        )}
        <Typography>{name?.trim()?.length > 0 ? name : 'Untitled'}</Typography>
      </ActionPill>

      {!_isEmpty(fieldState.error) && (
        <Typography name="error">{fieldState.error.message}</Typography>
      )}
    </Stack>
  );
}
