import { Stack } from '@bedrock-layout/stack';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { ReactElement, useEffect, useRef, useState } from 'react';
import {
  Control,
  UseFormSetValue,
  useController,
  useWatch,
} from 'react-hook-form';
import { Button, PopoverMethods, PopoverPanel, Typography } from 'ui';

import { isRuleReadOnlyAtom } from '../../..';
import { getValueFromObject } from '../../../../../components/Listing/utils';
import { openSchedularAtom } from '../TriggerForm';
import { SchedulerForm } from './SchedulerForm';

type SchedulerProps = {
  control?: Control<any>;
  setValue?: UseFormSetValue<any>;
  launcher?: ReactElement;
  cronIndex?: number;
};

export function Scheduler({
  control,
  setValue,
  launcher,
  cronIndex = 0,
}: SchedulerProps) {
  const ref = useRef<PopoverMethods>(null);
  const [isVisible, setIsVisible] = useState(false);
  const [isRuleReadOnly] = useAtom(isRuleReadOnlyAtom);

  const status: string = useWatch({
    control,
    name: `status`,
  });

  const [autoOpenScheduler, setAutoOpenScheduler] = useAtom(openSchedularAtom);

  const { fieldState } = useController({
    name: `productionConfig.schedule`,
    control,
  });

  useEffect(() => {
    if (autoOpenScheduler) {
      ref.current?.show();
      setAutoOpenScheduler(false);
    }
  }, [ref.current, autoOpenScheduler]);

  const plan = JSON.parse(window.sessionStorage.getItem('userPlan') ?? '{}');

  return (
    <Stack>
      <PopoverPanel
        arrow
        padding="1.6rem"
        trigger="click"
        launcher={
          launcher != null ? (
            <Stack>
              {launcher}
              {!_isNil(fieldState.error) &&
                !_isNil(fieldState.error?.message) && (
                  <Typography name="error">
                    {fieldState.error.message}
                  </Typography>
                )}
            </Stack>
          ) : (
            <Stack>
              <Button
                id={getValueFromObject(plan, 'plan.cron.componentId')}
                data-premium-component-id={getValueFromObject(
                  plan,
                  'plan.cron.componentId'
                )}
                data-premium-component-trigger={getValueFromObject(
                  plan,
                  'plan.cron.trigger'
                )}
                appearance="filled"
              >
                Create Schedule
              </Button>
              {!_isNil(fieldState.error) &&
                !_isNil(fieldState.error?.message) && (
                  <Typography name="error">
                    {fieldState.error.message}
                  </Typography>
                )}
            </Stack>
          )
        }
        ref={ref}
        setIsTippyVisible={setIsVisible}
        disabled={isRuleReadOnly}
      >
        <SchedulerForm
          index={cronIndex}
          control={control}
          onClose={() => {
            ref.current?.hide();
          }}
          setValue={setValue}
          isVisible={isVisible}
          showWarning={status !== 'published'}
        />
      </PopoverPanel>
    </Stack>
  );
}
