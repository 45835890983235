import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { useAtom } from 'jotai';
import { Control, useWatch } from 'react-hook-form';
import { CheckboxField, DropdownField, TextField, Typography } from 'ui';

import { timeToExpireUnits } from '../../../../DataSets/utils';
import { isWorkflowReadOnlyAtom } from '../../../atoms/atoms';

type GSheetSettingsProps = {
  control?: Control<any>;
  name: string;
};

export function GSheetSettings({ control, name }: GSheetSettingsProps) {
  const [isWorkflowReadOnly] = useAtom(isWorkflowReadOnlyAtom);

  const actionMethod = useWatch({
    control,
    name: 'actionMethod',
  });

  const isNotLookup = actionMethod?.value !== 'lookup';

  return (
    <PadBox padding="2rem">
      <Stack gutter="1rem">
        <Inline align="center" gutter={8}>
          <Typography fontWeight={700}>Timeout after (s)</Typography>
          <TextField
            name={`${name}.timeout`}
            type="number"
            size="small"
            control={control}
            disabled={isWorkflowReadOnly}
          />
        </Inline>

        <Inline align="center" gutter={8}>
          <Typography fontWeight={700}>Continue on error?</Typography>
          <CheckboxField
            name={`${name}.errorContinue`}
            control={control}
            disabled={isWorkflowReadOnly}
            appearance="switch"
            useId={`${name}.errorContinue`}
          />
        </Inline>

        <Inline gutter="1.6rem">
          <Typography fontWeight={700}>Row Limit</Typography>

          <TextField
            type="number"
            size="small"
            control={control}
            name={`${name}.rowLimit`}
            showErrorIcon={false}
            disabled={isWorkflowReadOnly || isNotLookup}
          />
        </Inline>

        <Inline gutter="1.6rem" align="center">
          <Typography fontWeight={700}>Cache</Typography>
          <CheckboxField
            control={control}
            name={`${name}.cacheEnabled`}
            appearance="switch"
            disabled={isWorkflowReadOnly || isNotLookup}
          />
        </Inline>

        <Inline gutter="1.6rem">
          <Inline gutter={8}>
            <Typography fontWeight={700}>Time to expire</Typography>
          </Inline>

          <TextField
            type="number"
            size="medium"
            control={control}
            name={`${name}.durationValue`}
            showErrorIcon={false}
            disabled={isWorkflowReadOnly || isNotLookup}
          />
          <DropdownField
            control={control}
            name={`${name}.durationUnit`}
            options={timeToExpireUnits}
            disabled={isWorkflowReadOnly || isNotLookup}
          />
        </Inline>
      </Stack>
    </PadBox>
  );
}
