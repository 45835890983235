import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { useAtom } from 'jotai';
import { BiCopy } from 'react-icons/bi';
import {
  IconButton,
  NectedEditor,
  TextInput,
  TooltipReact,
  Typography,
} from 'ui';

import { siteConstantsAtom } from '../../../../../atom';
import {
  copyToClipboard,
  getTooltipText,
  handleChangeApiToWebhookUrl,
} from '../../../../../utils/common';
import { envMap } from '../../../../../utils/constant';
import {
  versionInfoWorkflowAtom,
  workflowStatusAtom,
} from '../../../atoms/atoms';
import {
  ApiContainer,
  Curl,
  JsonContainer,
  StyledLink,
} from './ApiSettings.styled';

type WebhookSettingsProps = {
  triggerUrl: string;
  staticUrl: string;
  bodyParamsHook: string;
};

export function WebhookSettings({
  staticUrl,
  triggerUrl,
  bodyParamsHook,
}: WebhookSettingsProps) {
  const [siteConstants] = useAtom(siteConstantsAtom);
  const [versionInfoWorkflow] = useAtom(versionInfoWorkflowAtom);
  const [workflowStatus] = useAtom(workflowStatusAtom);

  const currentLiveVersion = versionInfoWorkflow?.currentLiveVersion;

  return (
    <>
      <Stack gutter="0.5rem">
        <Inline gutter={8} align="center">
          <Typography fontWeight={700}>Base URL</Typography>
          <TooltipReact id="baseurl-rule-id">
            {getTooltipText(siteConstants, 'rules', 'triggerBaseURL')}
          </TooltipReact>
        </Inline>

        <TextInput
          disabled
          value={handleChangeApiToWebhookUrl(
            staticUrl,
            workflowStatus === 'published' ? 'production' : 'staging',
            currentLiveVersion
          )}
          icon={
            <IconButton
              onClick={(e: any) => {
                e.stopPropagation();
                void copyToClipboard(
                  handleChangeApiToWebhookUrl(
                    staticUrl,
                    workflowStatus === 'published' ? 'production' : 'staging',
                    currentLiveVersion
                  ),
                  'Webhook URL copied'
                );
              }}
            >
              <BiCopy />
            </IconButton>
          }
        />
      </Stack>
      <Stack gutter="0.5rem">
        <Inline gutter={8} align="center">
          <Typography fontWeight={700}>Body Params</Typography>
          <TooltipReact id="body-params-rule-id">
            {getTooltipText(siteConstants, 'rules', 'triggerBodyParams')}
          </TooltipReact>
        </Inline>

        <Typography>
          <span>
            There is no need to provide an environment; the default environment
            for testing is staging.
          </span>
        </Typography>

        <JsonContainer>
          <NectedEditor
            mode="json"
            readOnly
            domain={envMap.VITE_EDITOR_URL}
            defaultValue={bodyParamsHook}
          />
        </JsonContainer>

        <Inline align="center">
          <StyledLink
            href={getTooltipText(
              siteConstants,
              'workflow',
              'howToTriggerWorkflowHook',
              'howToLinks'
            )}
            target={window !== window.parent ? '_self' : '_blank'}
          >
            How to trigger?
          </StyledLink>
          <TooltipReact id="body-params-rule-id">
            {getTooltipText(siteConstants, 'rules', 'triggerHowTo')}
          </TooltipReact>
        </Inline>

        <ApiContainer stretch="start">
          <Curl justify="start">
            <Typography name="paragraphSmall">
              <pre>{triggerUrl}</pre>
            </Typography>
          </Curl>

          <Inline justify="end">
            <IconButton
              onClick={async () =>
                await copyToClipboard(triggerUrl, 'API copied')
              }
            >
              <BiCopy size={20} />
            </IconButton>
          </Inline>
        </ApiContainer>
      </Stack>
    </>
  );
}
