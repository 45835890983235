import styled, { css } from 'styled-components';

type DataListInputStyledProps = {
  hasError: boolean;
  isSmallInput: boolean;
  showOutline: boolean;
};

const outlineNoneCssRule = css`
  border: none;
  outline: none;
`;

const disabledCssRules = css`
  cursor: default;
  background-color: var(--color-solitude);
`;

export const DataListInputStyled = styled.input<DataListInputStyledProps>`
  border-radius: 4px;
  border: 1px solid var(--color-gainsboro);
  font-size: 1.4rem;
  max-width: 100%;

  padding: ${({ isSmallInput }) =>
    !isSmallInput ? '0.8rem 1.6rem' : '0.4rem 0.8rem'};

  outline: ${({ hasError }) =>
    `1px solid ${
      hasError ? 'var(--color-fireEngineRed)' : 'var(--color-solitude)'
    }`};
  background-color: ${({ hasError }) =>
    hasError ? 'var( --color-snow)' : 'var(--color-white)'};

  &:focus {
    outline: 1px solid
      ${({ hasError }) =>
        hasError ? 'var(--color-fireEngineRed)' : 'var(--color-darkGray)'};
  }

  :disabled {
    ${disabledCssRules}
  }
  ${({ showOutline }) =>
    !showOutline &&
    css`
      ${outlineNoneCssRule}
      &:focus {
        ${outlineNoneCssRule}
      }
    `}
`;
