import { PaddingAsPropType } from '../../../../types';
import { RuleTestActionObject } from '../../types';
import { Table, TableProps } from './Table';
import { TableContainerStyled } from './TableContaier.styled';

type TableContainerProps = Pick<TableProps, 'columns'> & {
  tableData: RuleTestActionObject[];
  padding?: PaddingAsPropType;
  flexGrow?: number;
};

export function TableContainer({
  columns,
  tableData,
  padding = '0px' as PaddingAsPropType,
  flexGrow = 0,
}: TableContainerProps) {
  return (
    <TableContainerStyled padding={padding} flexGrow={flexGrow}>
      <Table columns={columns} data={tableData} />
    </TableContainerStyled>
  );
}
