import { Inline, PadBox } from '@bedrock-layout/primitives';
import _isEmpty from 'lodash/isEmpty';
import { useEffect } from 'react';
import { Control, UseFormSetValue } from 'react-hook-form';
import {
  Button,
  HorizontalLine,
  Typography,
  toasts,
  useCurrentLayer,
  useLayer,
} from 'ui';

import { ButtonWithOptions } from '../../../components/ButtonWithOptions/ButtonWithOptions';
import { permissionObj } from '../../../components/PermissionComponent/constant';
import { useCheckPermissions } from '../../../components/PermissionComponent/hooks/useCheckPermissions';
import { AutoSaveLoader } from '../../../components/autoSaveLoader/AutoSaveLoader';
import { TrashIcon } from '../../../components/icons/Trash';
import { useUpdateModalStateOnButtonOptionClick } from '../../../hooks/useUpdateModalStateOnButtonOptionClick';
import {
  handleGetCheckSumByEntityName,
  showGraphQlErrorToast,
} from '../../../utils/common';
import { ENTITY_ID } from '../../../utils/constant';
import { useDeleteDataSet } from '../hooks/useDeleteDataSet';
import { useWatchFields } from '../hooks/useWatchFields';
import { DataSetModel } from '../models';
import type {
  CreateDataSetFormValues,
  DataSetPublishedStatus,
  UpdateDataSetStatusFunction,
} from '../types';
import {
  FooterActionsContainer,
  PublishButtonContainer,
} from './DataSetFooter.styled';
import { DeleteDataSetModal } from './DeleteDataSetModal';

type DataSetFooterProps = {
  dataSetId: string;
  isCreatingOrUpdating: boolean;
  dataSetStatus: DataSetPublishedStatus;
  updateDataSetStatus: UpdateDataSetStatusFunction;
  datasetObj: DataSetModel | undefined;
  control: Control<CreateDataSetFormValues>;
  version?: string;
  from?: string;
  onPublish?: () => void;
  onUnmount?: () => void;
  setValue?: UseFormSetValue<CreateDataSetFormValues>;
  handleEntityUpdate: (data: Record<string, any>) => void;
};

export function DataSetFooter({
  dataSetId,
  isCreatingOrUpdating,
  updateDataSetStatus,
  from = '',
  onPublish,
  onUnmount,
  datasetObj,
  control,
  handleEntityUpdate,
}: DataSetFooterProps) {
  const [deleteDataSet] = useDeleteDataSet();

  const { statusValue, approvalInfo } = useWatchFields(control);

  const { isHide: deleteDisable } = useCheckPermissions({
    allowedPermission: [permissionObj.delete],
    entityList: [ENTITY_ID.datasets],
    entityStatus: statusValue,
  });

  const handleDeleteDataSet = async (id: string) => {
    const checksum = handleGetCheckSumByEntityName('datasets');
    try {
      await deleteDataSet({
        variables: {
          id,
          checksum: checksum ?? '',
        },
      });
      toasts.success('Data Source deleted successfully', 'deleted-success');
    } catch (error) {
      showGraphQlErrorToast(error);
    }
  };

  const onDeleteClick = async (dataSetId?: string) => {
    await handleDeleteDataSet(dataSetId ?? '');
    window.location.href = '/datasets';
    closeAllLayers();
  };

  const { closeAllLayers } = useCurrentLayer();

  const { openWithProps: openDeleteConfirmationModal } = useLayer(
    <DeleteDataSetModal onDeleteClick={onDeleteClick} />
  );

  useEffect(() => {
    return () => {
      if (typeof onUnmount === 'function') {
        onUnmount();
      }
    };
  }, []);

  const { selectedOptionId, handleOptionButtonClick } =
    useUpdateModalStateOnButtonOptionClick({
      entityId: dataSetId,
      entityType: ENTITY_ID.datasets,
      entityName: datasetObj?.name ?? '',
      entityStatus: statusValue ?? '',
      entitySubType: datasetObj?.connector?.plugin?.name ?? '',
      handleParentStateUpdate: handleEntityUpdate,
      formData: {
        requestReview: approvalInfo,
        publishModal: {
          title: approvalInfo?.title,
        },
      },
    });

  return (
    <PublishButtonContainer className="dataset-footer-container">
      <HorizontalLine />

      <PadBox padding=".8rem">
        <FooterActionsContainer $deleteDisable={deleteDisable}>
          {!deleteDisable && (
            <Button
              size="medium"
              disabled={_isEmpty(dataSetId)}
              appearance="neutral"
              leadingIcon={<TrashIcon />}
              onClick={() => openDeleteConfirmationModal({ dataSetId })}
            >
              <Typography fontWeight={700}>Delete</Typography>
            </Button>
          )}

          <Inline align="center" justify="center" gutter="1rem">
            <AutoSaveLoader isLoading={isCreatingOrUpdating} />

            {!['published', 'archived'].includes(statusValue) && (
              <ButtonWithOptions
                id="dataset-footer"
                entityType={ENTITY_ID.datasets}
                entityStatus={statusValue}
                defaultSelectedOptionId={selectedOptionId}
                onClick={handleOptionButtonClick}
              />
            )}
          </Inline>
        </FooterActionsContainer>
      </PadBox>
    </PublishButtonContainer>
  );
}
