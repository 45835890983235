import _isNil from 'lodash/isNil';
import { ReactNode } from 'react';
import { Button } from 'ui/src/Button';
import { useCurrentLayer } from 'ui/src/Layer';
import { Modal, ModalContent, ModalFooter } from 'ui/src/Modal';
import { Typography } from 'ui/src/Typography';

type RuleExitModalProps = {
  title?: string;
  onCloseConfirm?: () => void;
  description?: ReactNode;
};

export const DeleteConfirmModal = ({
  title = '',
  onCloseConfirm,
  description = 'Are you sure you want to delete this ?',
}: RuleExitModalProps) => {
  const { close: closeModal } = useCurrentLayer();

  return (
    <Modal size="large" title={title}>
      <ModalContent>
        {!_isNil(description) && (
          <Typography name="paragraph">{description}</Typography>
        )}
      </ModalContent>

      <ModalFooter>
        <Button appearance="neutral" onClick={closeModal}>
          Close
        </Button>

        <Button
          onClick={() => {
            closeModal();

            if (typeof onCloseConfirm === 'function') {
              onCloseConfirm();
            }
          }}
          appearance="danger"
        >
          Confirm
        </Button>
      </ModalFooter>
    </Modal>
  );
};
