import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _isUndefined from 'lodash/isUndefined';
import _reduce from 'lodash/reduce';
import type { UseFormSetError } from 'react-hook-form';
import type { Dataset, SimpleDropDownModel } from 'ui';

import type { AttributeModel } from '../../../../pages/Rules/models';
import { FieldsByID } from '../../../../pages/Rules/types';
import {
  isArrayAsInputValid,
  isValidTokenPresentInLeafNodesV2,
} from '../../../../utils/common';
import { isValidDate, isValidDateTime } from '../../../../utils/validation';

export type FormValues = {
  attributes: AttributeModel[];
  selectedDataSets: string[];
};

export const isAttributeNameUnique = (
  { attributes }: FormValues,
  setError: UseFormSetError<any>
) => {
  let isCustomAttributesNameValid = true;
  let sourceTypeOfErr = 'none';

  const duplicateNameIndexes = _reduce(
    attributes,
    (acc: Record<string, number[]>, { name }, index) => {
      acc[name] = (acc[name] ?? []).concat(index);

      return acc;
    },
    {}
  );

  _forEach(duplicateNameIndexes, (indexes, key) => {
    if (indexes.length > 1) {
      isCustomAttributesNameValid = false;

      const filteredAttribute = attributes.find((attr) => attr.name === key);

      if (!_isUndefined(filteredAttribute)) {
        sourceTypeOfErr = filteredAttribute?.sourceType ?? 'none';
      }

      indexes.forEach((index) => {
        setError(`attributes.${index}.name`, {
          message: 'Name must be unique',
          type: 'validate',
        });
      });
    }
  });

  return { isCustomAttributesNameValid, sourceTypeOfErr };
};

export const validateCustomAttributes = (
  source: AttributeModel,
  index: number,
  options: SimpleDropDownModel[],
  customAttributeFormValues: FormValues,
  setError: UseFormSetError<any>,
  fieldsById: FieldsByID,
  dataSet: string[]
) => {
  let { isCustomAttributesNameValid: isCustomAttributeValid, sourceTypeOfErr } =
    isAttributeNameUnique(customAttributeFormValues, setError);

  if (
    source.dataType === null ||
    (source.sourceType === 'dataSet' && _isNil(fieldsById[dataSet[0]]))
  ) {
    setError(`attributes.${index}.selectedType`, {
      message: 'Please select dataType',
    });

    isCustomAttributeValid = false;
    sourceTypeOfErr = source.dataType === null ? '' : 'dataSet';
  }

  if (
    source.sourceType === 'dataSet' &&
    (_isNil(source.attribute) || _isEmpty(source.attribute))
  ) {
    setError(`attributes.${index}.attribute`, {
      message: 'Please select attribute',
    });

    isCustomAttributeValid = false;
    sourceTypeOfErr = 'dataSet';
  }

  if (
    source.sourceType === 'dataSet' &&
    !_isEmpty(source.attribute) &&
    _isNil(options.find((data) => data.id === source.attribute)) &&
    !_isNil(fieldsById[dataSet[0]])
  ) {
    setError(`attributes.${index}.attribute`, {
      message: `Unable to find attribute for ${fieldsById[dataSet[0]].name}`,
    });

    isCustomAttributeValid = false;
    sourceTypeOfErr = 'dataSet';
  }

  if (source.name === 'id') {
    setError(`attributes.${index}.name`, {
      message: 'Please do not name the key as id',
    });

    isCustomAttributeValid = false;
    sourceTypeOfErr = source.sourceType ?? 'none';
  }

  if (source.sourceType === 'restAPI') {
    if (
      _isNil(source.sampleValue) ||
      _isEmpty(source.sampleValue) ||
      source.sampleValue === '{}'
    ) {
      setError(`attributes.${index}.sampleValue`, {
        message: 'Please execute and fetch action',
      });
      isCustomAttributeValid = false;
      sourceTypeOfErr = 'restAPI';
    }
  }

  if (source.dataType?.value === 'json') {
    if (_isNil(source.sampleValue) || _isEmpty(source.sampleValue)) {
      setError(`attributes.${index}.sampleValue`, {
        message: 'Please add a value',
      });
      isCustomAttributeValid = false;
      sourceTypeOfErr = '';
    }
  } else if (source.selectedType?.value === 'numeric') {
    if (!_isNil(source.sampleValue) && !_isEmpty(source.sampleValue)) {
      if (isNaN(source.sampleValue)) {
        setError(`attributes.${index}.sampleValue`, {
          message: 'Please enter a number',
        });
        isCustomAttributeValid = false;
        sourceTypeOfErr = '';
      }
    }
  } else if (source.selectedType?.value === 'date') {
    if (!_isNil(source.sampleValue) && !_isEmpty(source.sampleValue)) {
      if (!isValidDate(source.sampleValue)) {
        setError(`attributes.${index}.sampleValue`, {
          message: 'Please enter a valid Date',
        });
        isCustomAttributeValid = false;
        sourceTypeOfErr = '';
      }
    }
  } else if (source.selectedType?.value === 'dateTime') {
    if (!_isNil(source.sampleValue) && !_isEmpty(source.sampleValue)) {
      if (!isValidDateTime(source.sampleValue)) {
        setError(`attributes.${index}.sampleValue`, {
          message: 'Please enter a valid DateTime',
        });
        isCustomAttributeValid = false;
        sourceTypeOfErr = '';
      }
    }
  }

  return { isCustomAttributeValid, sourceTypeOfErr };
};

export const validateCustomInputTokens = (
  source: Record<string, any>,
  index: number,
  setError: UseFormSetError<any>,
  updatedDataset: Record<string, Dataset>
) => {
  const haveValidTokens = isValidTokenPresentInLeafNodesV2(
    source,
    updatedDataset
  );
  let sourceTypeOfErr = 'none';

  if (!haveValidTokens) {
    setError(`attributes.${index}.sampleValue`, {
      message: 'Invalid tokens used',
    });
    sourceTypeOfErr = source.sourceType ?? 'none';
  }

  return { haveValidTokens, sourceTypeOfErr };
};

export const validateArrayAsInput = (
  source: Record<string, any>,
  index: number,
  setError: UseFormSetError<any>
) => {
  let sourceTypeOfErr = 'none';

  if (source.dataType?.value === 'list') {
    const isArrayInputValid = isArrayAsInputValid(source.sampleValue);

    if (!isArrayInputValid) {
      setError(`attributes.${index}.sampleValue`, {
        message: 'Error while parsing input',
      });
      sourceTypeOfErr = source.sourceType ?? 'none';
    }

    return { isArrayInputValid, sourceTypeOfErr };
  }

  return { isArrayInputValid: true, sourceTypeOfErr };
};
