import { PadBox } from '@bedrock-layout/padbox';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import theme from 'styled-theming';

const modalSize = theme('size', {
  small: css`
    inline-size: 35rem;
  `,
  medium: css`
    inline-size: 45rem;
  `,
  large: css`
    inline-size: 55rem;
  `,
  extraLarge: css`
    inline-size: 65rem;
  `,
  extraLargeXS: css`
    inline-size: 80rem;
  `,
  doubleExtraLarge: css`
    width: 80vw;
    max-inline-size: 100rem;
  `,
});

export const ModalContainer = styled(motion.div)<{ overflow?: string }>`
  background-color: var(--color-white);
  border-radius: 8px;
  position: absolute;
  top: 50%;
  left: 50%;
  overflow: ${({ overflow = 'hidden' }) => overflow};
  transform: translate(-50%, -50%);
  ${modalSize}
`;

export const ModalFooterContainer = styled(PadBox)<{
  $showBorder?: boolean;
  $hasBgColor?: boolean;
}>`
  ${({ $showBorder = false }) =>
    $showBorder && 'border-top: 1px solid var(--color-solitude);'}

  ${({ $hasBgColor = false }) =>
    $hasBgColor && 'background-color: var(--color-primary2)'}
`;
