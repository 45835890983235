import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  Typography,
  useCurrentLayer,
} from 'ui';

type RegenerateKeyProps = {
  onRegenrateClick?: () => Promise<void>;
  title?: string;
  description?: string;
};

export function RegenerateKeyModal({
  onRegenrateClick,
  title = 'Regenerate API Key',
  description = 'Are you certain you want to proceed with the update? This action will affect all rules and workflows with private authentication settings enabled.',
}: RegenerateKeyProps) {
  const { close } = useCurrentLayer();

  return (
    <Modal size="medium" title={title}>
      <ModalContent>
        &nbsp;
        <Typography>{description}</Typography>
        &nbsp;
      </ModalContent>
      <ModalFooter>
        <Button onClick={close} appearance="neutral">
          Cancel
        </Button>

        <Button
          onClick={async () => {
            if (typeof onRegenrateClick === 'function') {
              await onRegenrateClick();
            }
            close();
          }}
        >
          Regenerate
        </Button>
      </ModalFooter>
    </Modal>
  );
}
