import { useMutation } from '@tanstack/react-query';
import type { AxiosError, AxiosResponse } from 'axios';

import { useAxiosPrivate } from '../../../hooks';
import { CustomAxiosError } from '../../../utils/response/types';

export type RedirectionPayloadObjectProps = {
  apiUrl: string;
  redirectUri: string;
  method: 'PATCH' | 'POST';
};

export type SourceRedirectionPayloadProps = {
  home: RedirectionPayloadObjectProps;
};

export function useVerifyWSInviteToken() {
  const { axiosPrivate } = useAxiosPrivate();

  const {
    mutate: callTokenVerificationAPI,
    isLoading,
    data: verificationData,
    error,
  } = useMutation({
    mutationFn: async (request: SourceRedirectionPayloadProps) => {
      return await axiosPrivate.patch(`${request.home.apiUrl}`, {});
    },
    onSuccess(response: AxiosResponse) {
      return response;
    },
    onError(error: AxiosError<CustomAxiosError>) {
      return error.response?.data;
    },
  });

  return {
    callTokenVerificationAPI,
    isLoading,
    verificationData,
    error,
  };
}
