import { Inline, PadBox } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { useState } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import { RxDragHandleDots2 } from 'react-icons/rx';
import { Typography } from 'ui';

import { handleDeleteElement } from '../../../utils/common';
import { RuleParamPopover } from '../RulePopovers/RuleParamPopover';
import { simpleRuleNodesAtom } from '../SimpleRule';
import { SimpleRuleNodesModel } from '../models';
import { sendEventToGTMType } from './RuleBlock';
import {
  ConditionStyled,
  DeleteIconStyled,
  OperatorStyled,
  RuleSqlConditionStyled,
} from './RuleBlock.styled';
import { SqlNodePill } from './SqlNodePill';

export type RuleConditionProps = {
  ruleId: string;
  rule: SimpleRuleNodesModel;
  handleSendEventToGTM?: (obj: sendEventToGTMType) => void;
};

export const RuleSqlCondition = ({
  rule,
  ruleId,
  handleSendEventToGTM,
}: RuleConditionProps) => {
  const [hover, setHover] = useState(false);
  const [rules, setRules] = useAtom(simpleRuleNodesAtom);
  const operator = rules[rule.parent].operator;

  const firstChild = 1;

  return (
    <RuleSqlConditionStyled>
      {rules[rule.parent].operator !== '' &&
        !_isNil(rule.siblingIndex) &&
        rule.siblingIndex !== 1 && (
          <PadBox padding={[5, 30]}>
            <Typography>
              <OperatorStyled operator={operator ?? 'and'}>
                {operator}
              </OperatorStyled>
            </Typography>
          </PadBox>
        )}
      <Inline
        gutter={4}
        align="center"
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <RxDragHandleDots2 size={20} color={'var(--color-lightGray)'} />

        <ConditionStyled padding={[5, 5]}>
          <Inline gutter={10}>
            <RuleParamPopover
              ruleId={ruleId}
              nodeId={ruleId}
              handleSendEventToGTM={handleSendEventToGTM}
              isSqlNode
            />

            <SqlNodePill name={rule.name} ruleId={ruleId} />

            {rule.siblingIndex !== firstChild && (
              <DeleteIconStyled
                onClick={() => {
                  setRules(handleDeleteElement(ruleId, rules));
                }}
                active={hover}
              >
                <AiOutlineDelete size={20} />
              </DeleteIconStyled>
            )}
          </Inline>
        </ConditionStyled>
      </Inline>
    </RuleSqlConditionStyled>
  );
};
