import { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { BannerBox } from '../../components/BannerBox';
import { getUserState } from '../../hooks/getUserState';
import { useGetSiteMeta } from '../../hooks/useGetSiteMeta';
import { isOnboardingCompleted } from '../../utils/common';
import { envMap, exploreOnPremise } from '../../utils/constant';
import { updateWidgetState } from '../Home/components/sub-components/UpdateWidgetState';
import { OnPremiseContainer } from './onPremise.styled';

export function OnPremise() {
  const { siteMeta } = useGetSiteMeta();

  useEffect(() => {
    if (!isOnboardingCompleted(exploreOnPremise)) {
      updateWidgetState(exploreOnPremise)
        .then(() => {
          void getUserState();
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    }
  }, []);

  return (
    <OnPremiseContainer>
      <Helmet>
        <title>
          {siteMeta[window.location.pathname.substring(1)]?.title ?? ''}
        </title>
        <meta
          name="description"
          content={
            siteMeta[window.location.pathname.substring(1)]?.description ?? ''
          }
        />
      </Helmet>
      <BannerBox
        title="On Premise"
        subTitle="If your company requires higher levels of data security and compliance, optionally set up global variables agent to ensure customer data from database and APIs never leave your network."
        bannerImage="onpremise_banner.png"
        buttonProps={{
          buttonUrl: '',
          buttonText: 'Request for On-Premise',
          buttonTarget: '',
        }}
        imageStyles={{
          height: '70vh',
          width: 'auto',
        }}
        textButtonProps={{
          buttonUrl: envMap.VITE_HELM_CHARTS_URL,
          buttonText: 'See installation steps',
          buttonTarget: '',
        }}
      />
    </OnPremiseContainer>
  );
}
