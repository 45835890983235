import { useRef } from 'react';
import { Control, useWatch } from 'react-hook-form';
import {
  Dataset,
  PopoverMethods,
  PopoverPanel,
  RulePopover,
  TextField,
} from 'ui';

import { PopoverContainer } from './GSheetColumnPopover.styled';

type GSheetColumnPopoverProps = {
  dataSet?: Record<string, Dataset>;
  name: string;
  onClick: (args: any) => void;
  typesToAllow: string[];
  control?: Control<any>;
  disabled?: boolean;
};

export function GSheetColumnPopover({
  name,
  onClick,
  typesToAllow,
  dataSet = {},
  control,
  disabled = false,
}: GSheetColumnPopoverProps) {
  const value = useWatch({
    name,
    control,
  });

  const ref = useRef<PopoverMethods>(null);

  return (
    <div>
      <PopoverPanel
        trigger="click"
        placement="auto-start"
        ref={ref}
        launcher={
          <span>
            <TextField
              control={control}
              name={name}
              disabled={disabled}
              value={value}
            />
          </span>
        }
      >
        <PopoverContainer>
          <RulePopover
            dataset={{
              ...dataSet,
              nected: {
                name: 'Nected Attributes',
                id: 'nected_attributes',
                attributes: {
                  NECTED_EMPTY_VALUE_TOKEN: {
                    dataType: 'nected',
                    name: 'NECTED_EMPTY_VALUE_TOKEN',
                    executedValue: 'NECTED_EMPTY_VALUE_TOKEN',
                  },
                },
              },
            }}
            typesToAllow={typesToAllow}
            disabled={disabled}
            onClick={(args) => {
              onClick(args);
              ref.current?.hide();
            }}
            allowList
            version="v2"
          />
        </PopoverContainer>
      </PopoverPanel>
    </div>
  );
}
