import _isNil from 'lodash/isNil';
import { ChangeEvent, useState } from 'react';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import { toasts } from 'ui';

import { axios } from '../../../../api/axios';
import { useAxiosPrivate } from '../../../../hooks';
import {
  Alert,
  Container,
  ImagePreview,
  Input,
  Loader,
  UploadButton,
} from './WorkspaceUploader.styled';

type WorkspaceUploaderProps = {
  setValue: UseFormSetValue<any>;
  control?: Control<any>;
  name: string;
};

const fileType: Record<string, string> = {
  'image/jpeg': 'jpg',
  'image/png': 'png',
  'image/gif': 'gif',
  'image/svg+xml': 'svg',
  'image/bmp': 'bmp',
  'image/tiff': 'tiff',
  'image/webp': 'webp',
  'image/x-icon': 'ico',
  'image/vnd.microsoft.icon': 'ico',
};

export function WorkspaceUploader({
  setValue,
  name,
  control,
}: WorkspaceUploaderProps) {
  const previewLink = useWatch({
    control,
    name,
  });

  const [preview, setPreview] = useState<string | null>(null);
  const [uploading, setUploading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const maxFileSize = 2 * 1024 * 1024; // 2MB in bytes
  const { axiosPrivate } = useAxiosPrivate();

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (file != null) {
      if (file.size > maxFileSize) {
        setError('File size exceeds 2MB. Please upload a smaller image.');

        return;
      }

      if (name === 'theme.favicon') {
        const validFaviconTypes = ['image/x-icon', 'image/vnd.microsoft.icon'];

        if (!validFaviconTypes.includes(file.type)) {
          setError('Favicon format should be of .ico type.');

          return;
        }
      }

      if (name === 'theme.logoUrl' && _isNil(fileType[file.type])) {
        setError('Upload a valid file format.');

        return;
      }

      setError(null);
      setPreview(URL.createObjectURL(file));
      void uploadImage(file);
    }
  };

  const uploadImage = async (imageFile: File) => {
    let preUrl = '';
    let fileUrl = '';

    const fileName =
      name === 'theme.favicon'
        ? `favicon.ico`
        : `logo.${fileType[imageFile.type]}`;
    try {
      const response = await axiosPrivate.get(
        `common/assets/upload/psn?filename=${fileName}&filetype=image`
      );

      if (!_isNil(response.data?.data?.presignedUrl)) {
        preUrl = response.data?.data?.presignedUrl;
      }

      if (!_isNil(response.data?.data?.fileUrl)) {
        fileUrl = response.data?.data?.fileUrl;
      }
    } catch (error) {}

    try {
      await axios.put(preUrl, imageFile, {
        headers: {
          // eslint-disable-next-line
          'Content-Type': imageFile.type || 'application/octet-stream',
        },
      });

      setValue(name, fileUrl);
    } catch {
      setPreview(null);
      toasts.error('Unable to upload file', 'upload-file-error');
    }

    setUploading(false);
  };

  return (
    <Container>
      <Input
        type="file"
        accept="image/*"
        id={name}
        onChange={handleImageChange}
      />
      <UploadButton htmlFor={name}>Choose an Image</UploadButton>
      {!_isNil(error) && <Alert>{error}</Alert>}

      {(!_isNil(preview) ||
        // eslint-disable-next-line
        !!previewLink) && (
        <ImagePreview src={preview ?? previewLink} alt="Preview" />
      )}
      {uploading && <Loader>Uploading...</Loader>}
    </Container>
  );
}
