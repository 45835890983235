import type { Dataset } from 'ui';

import { generateUid } from '../../../../../utils/common';

export const defaultDataTypes: Record<string, Dataset> = {
  primitive: {
    name: 'Datatypes',
    id: generateUid('primitive_'),
    attributes: {
      string: {
        name: 'String',
        dataType: 'string',
      },
      numeric: {
        name: 'Numeric',
        dataType: 'numeric',
      },
      date: {
        name: 'Date',
        dataType: 'date',
      },
      dateTime: {
        name: 'DateTime',
        dataType: 'dateTime',
      },
      boolean: {
        name: 'Boolean',
        dataType: 'boolean',
      },
      json: {
        name: 'Json',
        dataType: 'json',
      },
      list: {
        name: 'List',
        dataType: 'list',
      },
    },
  },
};
