import { gql, useMutation } from '@apollo/client';

const deleteAuthConfigKey = gql`
  mutation deleteCredential($id: String!, $checksum: String!) {
    deleteCredential(id: $id, checksum: $checksum)
  }
`;

type DeleteAuthConfigKey = {
  data: {
    deleteCredential: boolean;
  };
};

export function useDeleteAuthConfigKey() {
  return useMutation<DeleteAuthConfigKey, { id: string; checksum: string }>(
    deleteAuthConfigKey
  );
}
