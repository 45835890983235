import { Inline } from '@bedrock-layout/primitives';
import _isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';

export const DataParamContainer = styled(Inline)<{ topPadding?: string }>`
  min-block-size: 3.7rem;

  ${({ topPadding = '0' }) =>
    !_isEmpty(topPadding) && `padding-top: ${topPadding};`}
`;
