import { Inline, PadBox } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { useEffect, useRef } from 'react';
import { RxDragHandleDots2 } from 'react-icons/rx';
import { useLayer } from 'ui';

import { useHandleRuleMenuActions } from '../../../hooks/useHandleRuleMenuActions';
import { isRuleReadOnlyAtom } from '../../../index';
import { isFirstChild, isUnGroupAble } from '../../../utils/common';
import { ExcelNodeModal } from '../ExcelNodeModal/ExcelNodeModal';
import {
  RuleParamPopover,
  simpleRuleNodeId,
} from '../RulePopovers/RuleParamPopover';
import { simpleRuleNodesAtom } from '../SimpleRule';
import { SimpleRuleNodesModel } from '../models';
import { ExcelNodePill } from './ExcelNodePill';
import { GroupOperatorSelection } from './GroupOperatorSelection';
import { sendEventToGTMType } from './RuleBlock';
import { ConditionStyled, RuleSqlConditionStyled } from './RuleBlock.styled';
import { RuleMenu } from './RuleMenu';
import { DragContainer } from './RuleSqlCondition.styled';

export type RuleConditionProps = {
  ruleId: string;
  rule: SimpleRuleNodesModel;
  handleSendEventToGTM?: (obj: sendEventToGTMType) => void;
};

export const RuleExcelCondition = ({
  rule,
  ruleId,
  handleSendEventToGTM,
}: RuleConditionProps) => {
  const [rules] = useAtom(simpleRuleNodesAtom);
  const operator = rules[rule.parent].operator;
  const [isRuleReadOnly] = useAtom(isRuleReadOnlyAtom);
  const { open: openCodeNode } = useLayer(<ExcelNodeModal ruleId={ruleId} />);

  const siblings = rules[rule.parent].children;

  const numberOfSiblings = !_isNil(siblings) ? siblings.length : 0;

  const [selectedRuleId] = useAtom(simpleRuleNodeId);

  const isMountedRef = useRef(false);

  useEffect(() => {
    if (!isMountedRef.current && ruleId === selectedRuleId) {
      isMountedRef.current = true;
      openCodeNode();
    }
  }, []);

  const { convertConditionToGroup, handleDelete, unGroupAnElement } =
    useHandleRuleMenuActions({
      rule,
      ruleId,
    });

  return (
    <RuleSqlConditionStyled $readOnly={isRuleReadOnly}>
      {!isFirstChild(rules, ruleId) &&
        rules[rule.parent].operator !== '' &&
        !_isNil(rule.siblingIndex) &&
        rule.siblingIndex !== 1 && (
          <PadBox padding={[5, 30]}>
            <GroupOperatorSelection
              id={rule.parent}
              operator={operator ?? ''}
            />
          </PadBox>
        )}
      <Inline gutter={4} align="center">
        <DragContainer>
          <RxDragHandleDots2 size={20} color={'var(--color-lightGray)'} />
        </DragContainer>

        <ConditionStyled padding={[5, 5]}>
          <Inline gutter={10}>
            <RuleParamPopover
              ruleId={ruleId}
              nodeId={ruleId}
              handleSendEventToGTM={handleSendEventToGTM}
              isExcelCondition
            />

            <ExcelNodePill name={rule.name} ruleId={ruleId} />

            <RuleMenu
              showDelete={numberOfSiblings > 1}
              convertIntoGroup
              handleConvertIntoGroup={() =>
                convertConditionToGroup(rule, ruleId)
              }
              handleDelete={handleDelete}
              unGroupItem={isUnGroupAble(rules, ruleId)}
              handleUnConvert={unGroupAnElement}
            />
          </Inline>
        </ConditionStyled>
      </Inline>
    </RuleSqlConditionStyled>
  );
};
