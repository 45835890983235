import { atom, useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { ReactElement, createRef, useEffect, useState } from 'react';
import { Button, Dataset, PopoverMethods, PopoverPanel } from 'ui';

import { usedConnectorMappingAtom } from '../../atom';
import { useGetPlugins } from '../../hooks/graphql/useGetPlugins';
import { useGetPublishedConnectors } from '../../pages/DataSets/hooks/useGetPublishedConnectors';
import { isRuleReadOnlyAtom } from '../../pages/Rules';
import { PublishedConnectors } from '../../pages/Rules/components/DecisionTable/types';
import { ControlListType } from '../../pages/Workflow/models/models';
import { generateUid, isValidImageURL } from '../../utils/common';
import { roleJsonAtom } from '../authentication/router/AuthProvider';
import { CreateActionContainer } from './components/CreateActionContainer';

type CreateActionProps = {
  addAction: (type: string, id: string) => void;
  launcher?: ReactElement;
};

export const publishedConnectorsAtom = atom<PublishedConnectors>({});

export const connectorPluginsToIgnoreForActions: Record<string, boolean> = {
  gsheet: false,
};

export function CreateAction({ launcher, addAction }: CreateActionProps) {
  const { data, refetch } = useGetPublishedConnectors(false);
  // eslint-disable-next-line
  const [connectors, setConnectors] = useState<Record<string, Dataset>>({});
  const [publishConnectors, setPublishConnectors] = useAtom(
    publishedConnectorsAtom
  );
  const [isRuleReadOnly] = useAtom(isRuleReadOnlyAtom);
  const [roleJson] = useAtom(roleJsonAtom);
  const [usedConnectorMapping, setUsedConnectorMapping] = useAtom(
    usedConnectorMappingAtom
  );

  const [refetchCount, setRefetchCount] = useState(0);

  const [getConnectorPlugins, { data: pluginData }] = useGetPlugins({
    isSource: false,
  });

  const [controlList, setControlList] = useState<ControlListType[]>([]);

  const popoverMethods = createRef<PopoverMethods>();

  // eslint-disable-next-line
  const handleClick = ({ value, key }: { value: string; key: string }) => {
    const connectorStatus =
      !_isNil(publishConnectors[value]) &&
      (publishConnectors[value].staging?.isTested ?? true) &&
      (publishConnectors[value].staging?.isPublish ?? true);

    const originalSource = usedConnectorMapping?.[value]?.source ?? [];

    const source = originalSource?.includes('action')
      ? originalSource
      : [...originalSource, 'action'];

    setUsedConnectorMapping({
      ...usedConnectorMapping,
      [value]: {
        status: connectorStatus,
        source,
      },
    });

    addAction(key, value);
    popoverMethods.current?.hide();
  };

  useEffect(() => {
    if (!_isNil(data)) {
      const publishedConnectors = data.getConnector.data.filter((connector) => {
        const pluginName = connector.plugin.name;

        if (
          pluginName in connectorPluginsToIgnoreForActions &&
          connectorPluginsToIgnoreForActions[pluginName]
        ) {
          return false;
        }

        return true;
      });

      const currentData: Record<string, Dataset> = {};
      const publishConnectorIdAndPlugin: PublishedConnectors = {};

      if (publishedConnectors.length > 0) {
        publishedConnectors.forEach((connector) => {
          publishConnectorIdAndPlugin[connector.id] = connector;

          const name = connector.plugin.name;

          if (!_isNil(currentData[name])) {
            currentData[connector.plugin.name] = {
              ...currentData[connector.plugin.name],
              attributes: {
                ...currentData[connector.plugin.name].attributes,
                [connector.id]: {
                  name: connector.name,
                  dataType: '',
                },
              },
            };
          } else {
            currentData[connector.plugin.name] = {
              name: connector.plugin.name,
              id: generateUid('connector_'),
              image: isValidImageURL(connector.plugin.imageUrl),
              attributes: {
                [connector.id]: {
                  name: connector.name,
                  dataType: '',
                },
              },
            };
          }
        });
      }

      setPublishConnectors(publishConnectorIdAndPlugin);
      setConnectors(currentData);
    }
  }, [data]);

  useEffect(() => {
    void getConnectorPlugins();
  }, []);

  useEffect(() => {
    if (!_isNil(pluginData)) {
      setControlList(
        pluginData.getPlugin.data.map((p) => {
          return {
            name: p.displayName,
            type: 'plugin',
            entityId: p.name,
            icon: p.imageUrl,
            metaData: {
              pluginId: p.id,
              category: p.category,
            },
          };
        })
      );
    }
  }, [pluginData]);

  const integrationsPermissions = roleJson.internals?.integrations?.permissions;

  // eslint-disable-next-line
  const isCreateIntegration =
    !_isNil(integrationsPermissions) &&
    ((integrationsPermissions.create as boolean) ||
      (integrationsPermissions.edit as boolean));

  return (
    <PopoverPanel
      padding={5}
      trigger="click"
      launcher={launcher ?? <Button>Launcher</Button>}
      ref={popoverMethods}
      placement="auto"
      disabled={isRuleReadOnly}
    >
      <CreateActionContainer
        controlList={controlList}
        handleClick={handleClick}
        handleRefresh={async () => {
          await refetch();
          setRefetchCount((c) => c + 1);
        }}
        refetchCount={refetchCount}
      />
    </PopoverPanel>
  );
}
