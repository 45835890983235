import { ApolloError } from '@apollo/client';
import { PadBox } from '@bedrock-layout/padbox';
import { Stack } from '@bedrock-layout/stack';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  Spinner,
  TextAreaField,
  TextField,
  Typography,
  toasts,
  useCurrentLayer,
} from 'ui';

import { checksumWarningAtom } from '../../../atom';
import { CheckSumEntityNames } from '../../../types';
import { handleGetCheckSumByEntityName } from '../../../utils/common';
import { useRollbackEntity } from '../hooks/graphql/useRollbackEntity';
import { arhiveFormSchema } from '../schema';
import type { ModalCommonProps } from '../type';
import {
  getChecksumMetaData,
  getEntityTypeForApi,
  getEntityTypeForChecksum,
} from '../utils/common';
import { entityActions } from '../utils/constant';

type ArchiveEntityModalFormData = {
  title: string;
  description: string;
};

type ArchiveEntityModalProps = ModalCommonProps & {
  defaultFormValues?: ArchiveEntityModalFormData;
  commitId?: string;
};

export function ArchiveEntityModal({
  entityType,
  entityStatus,
  entityId,
  title = 'Archive Changes',
  defaultFormValues,
  handleEntityUpdate,
  entityName = '',
  entitySubType = '',
}: ArchiveEntityModalProps) {
  const [, setShowChecksumPopup] = useAtom(checksumWarningAtom);
  const [isLoading, setIsLoading] = useState(false);

  const { close: closeModal } = useCurrentLayer();

  const [rollbackEntityMutation, { data, error }] = useRollbackEntity();

  const { control, setValue, handleSubmit } =
    useForm<ArchiveEntityModalFormData>({
      resolver: zodResolver(arhiveFormSchema),
    });

  useEffect(() => {
    if (!_isNil(defaultFormValues)) {
      setValue('title', defaultFormValues.title);
      setValue('description', defaultFormValues.description);
    }
  }, [defaultFormValues]);

  useEffect(() => {
    if (
      typeof handleEntityUpdate === 'function' &&
      !_isNil(data) &&
      !_isNil(data.rollbackEntity)
    ) {
      toasts.success(data.rollbackEntity.message ?? '', 'entity archived');

      handleEntityUpdate({
        fetchEntity: true,
      });
    }
  }, [JSON.stringify(data)]);

  useEffect(() => {
    if (error instanceof ApolloError) {
      if (error?.graphQLErrors[0]?.extensions?.code === 'checksum_mismatched') {
        setShowChecksumPopup({
          showPopup: true,
          metaData: getChecksumMetaData({
            entityId,
            entityName,
            entityType,
            entitySubType,
          }),
        });
      } else {
        toasts.error(error.message, 'error');
      }
    }
  }, [error]);

  const handleArchiveEntity = async (data: ArchiveEntityModalFormData) => {
    const { title = '', description = '' } = data;

    const checksumEntityName = getEntityTypeForChecksum(entityType);

    let payload: Record<string, any> = {
      id: entityId,
      entityType: getEntityTypeForApi(entityType),
      action: entityActions.archive,
      checksum:
        handleGetCheckSumByEntityName(
          checksumEntityName as CheckSumEntityNames
        ) ?? '',
    };

    if (['draft', 'tested'].includes(entityStatus ?? '')) {
      payload = {
        ...payload,
        title,
        description,
      };
    } else if (entityStatus === 'inreview') {
      payload = {
        ...payload,
        comment: description,
      };
    }

    try {
      setIsLoading(true);

      await rollbackEntityMutation({
        variables: payload,
        fetchPolicy: 'no-cache',
      });
    } catch (error) {
    } finally {
      setIsLoading(false);
      closeModal();
    }
  };

  return (
    <Modal size="extraLarge" title={title} overflow="none">
      <form onSubmit={handleSubmit(handleArchiveEntity)}>
        <ModalContent>
          <Stack as={PadBox} gutter="1.5rem" padding={['1.5rem', 0, '4rem', 0]}>
            <TextField
              control={control}
              label="Title"
              placeholder="Describe title"
              name="title"
              showErrorIcon={false}
              disabled={entityStatus !== 'draft' && entityStatus !== 'tested'}
            />

            <Stack gutter="0.8rem">
              <Typography>Describe changes</Typography>

              <TextAreaField
                control={control}
                placeholder="Explain in detail"
                name="description"
                rows={4}
              />
            </Stack>
          </Stack>
        </ModalContent>
        <ModalFooter>
          <Button onClick={closeModal} appearance="neutral">
            Cancel
          </Button>

          <Button type="submit">
            {isLoading ? <Spinner size="extraSmall" /> : <>Send to archive</>}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
