import { ObservableQuery } from '@apollo/client';
import { Inline, Stack } from '@bedrock-layout/primitives';
import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  Typography,
  useCurrentLayer,
  useLayer,
} from 'ui';

import { useGetAuthKeyList } from '../../hooks/useGetAuthKeyList';
import { ApiKeySheet } from '../../pages/Credentials/authenticationConfig/components/ApiKeySheet';
import { BasicAuthSheet } from '../../pages/Credentials/authenticationConfig/components/BasicAuth';
import { BearerTokenSheet } from '../../pages/Credentials/authenticationConfig/components/BearerTokenSheet';
import { OAuthSheet } from '../../pages/Credentials/authenticationConfig/components/oAuth';
import { AuthKeyTypeContainer } from './AuthKeyModal.styled';

type AuthKeyModalProps = {
  refetch?: ObservableQuery<any>['refetch'];
};

export function AuthKeyModal({ refetch }: AuthKeyModalProps) {
  const { close: closeModal } = useCurrentLayer();

  const { authKeyList } = useGetAuthKeyList();

  const { open: openBearerTokenSheet } = useLayer(
    <BearerTokenSheet refetch={refetch} />
  );
  const { open: openApiKeySheet } = useLayer(<ApiKeySheet refetch={refetch} />);
  const { open: openOAuthSheet } = useLayer(<OAuthSheet refetch={refetch} />);
  const { open: openBasicAuthSheet } = useLayer(
    <BasicAuthSheet refetch={refetch} />
  );

  const handleAuthenticationClick = (type: string) => {
    if (type === 'bearerToken') {
      openBearerTokenSheet();
    } else if (type === 'ApiKey') {
      openApiKeySheet();
    } else if (type === 'oAuth') {
      openOAuthSheet();
    } else if (type === 'basicAuth') {
      openBasicAuthSheet();
    }

    closeModal();
  };

  return (
    <Modal
      size="large"
      title={
        <Inline align="center" stretch="start">
          <Typography name="heading2">Create Authentication</Typography>
        </Inline>
      }
    >
      <ModalContent>
        <Stack gutter={16}>
          <Typography>Select the kind of Authentication</Typography>

          <Stack gutter={8}>
            {authKeyList.map((authKey) => (
              <AuthKeyTypeContainer
                key={authKey.title}
                padding={8}
                onClick={() => handleAuthenticationClick(authKey.id)}
              >
                <Typography fontWeight={700}>{authKey.title}</Typography>
                <Typography name="secondarySmall">{authKey.desc}</Typography>
              </AuthKeyTypeContainer>
            ))}
          </Stack>
        </Stack>
      </ModalContent>

      <ModalFooter justify="start">
        <Button appearance="neutral" onClick={closeModal}>
          Back
        </Button>
      </ModalFooter>
    </Modal>
  );
}
