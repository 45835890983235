import { ObservableQuery } from '@apollo/client';
import { Stack } from '@bedrock-layout/stack';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import type { ChangeEvent } from 'react';
import { CiSearch } from 'react-icons/ci';
import {
  Button,
  Image,
  Modal,
  ModalContent,
  ModalFooter,
  TextInput,
  Typography,
  useCurrentLayer,
  useLayer,
} from 'ui';

import { useGetPlugins } from '../../../../../hooks/graphql/useGetPlugins';
import type { GetConnectorsQueryResult } from '../../../../../pages/DataSets/hooks/useGetPublishedConnectors';
import type { PluginModel } from '../../../../../pages/Integrations/components/plugin/Plugin';
import { PluginFormSheet } from '../../../../../pages/Integrations/components/plugin/PluginFormSheet';
import { isValidImageURL } from '../../../../../utils/common';
import { ListContainer, ListItem } from './DataSetModal.styled';

type PluginModalProps = {
  refetch?: ObservableQuery<GetConnectorsQueryResult>['refetch'];
  isSource?: boolean;
};

export function PluginModal({ refetch, isSource = true }: PluginModalProps) {
  const [searchInput, setSearchInput] = useState<string>('');
  const [pluginList, setPluginList] = useState<PluginModel[]>([]);

  const [getConnectorPlugins, { data: pluginData, loading }] = useGetPlugins({
    isSource,
  });

  const { close: closeModal } = useCurrentLayer();

  const { openWithProps: openPluginFormSheet } = useLayer(
    <PluginFormSheet
      currentStage="staging"
      from="rules"
      onPublish={async () => {
        if (typeof refetch === 'function') {
          await refetch();
        }
      }}
    />
  );

  const handleSearchInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
  };

  useEffect(() => {
    void getConnectorPlugins();
  }, []);

  useEffect(() => {
    if (!_isNil(pluginData)) {
      setPluginList(
        pluginData.getPlugin.data.filter(
          (plugin) =>
            plugin.displayName
              .toLowerCase()
              .includes(searchInput.toLowerCase()) || _isEmpty(searchInput)
        )
      );
    }
  }, [pluginData, searchInput]);

  useEffect(() => {
    return () => {
      if (typeof refetch === 'function') {
        void refetch();
      }
    };
  }, []);

  return (
    <Modal size="large" title="Add Connector">
      <ModalContent>
        <Stack gutter="1rem">
          <Typography name="paragraph">
            Select a Database you&apos;d like a connector for
          </Typography>

          <TextInput
            type="text"
            placeholder="Search Plugins"
            isSmallInput
            icon={<CiSearch size={18} color="var(--color-suvaGray)" />}
            onChange={handleSearchInputChange}
            value={searchInput}
          />
          <ListContainer gutter="0.8rem">
            {loading && <Typography>Loading...</Typography>}

            {!loading && pluginList.length === 0 && (
              <Typography>No data found</Typography>
            )}

            {pluginList.map((plugin) => {
              return (
                <ListItem
                  gutter="1rem"
                  onClick={() => {
                    openPluginFormSheet({
                      plugin,
                    });

                    closeModal();
                  }}
                  key={plugin.id}
                >
                  <Image
                    src={isValidImageURL(plugin.imageUrl)}
                    alt="minus"
                    size="small"
                    round
                  />
                  <Typography>{plugin.displayName}</Typography>
                </ListItem>
              );
            })}
          </ListContainer>
        </Stack>
      </ModalContent>

      <ModalFooter justify="start">
        <Button appearance="neutral" onClick={closeModal}>
          Back
        </Button>
      </ModalFooter>
    </Modal>
  );
}
