import { Center, Stack } from '@bedrock-layout/primitives';
import styled from 'styled-components';

export const CenterStyled = styled(Center)`
  text-align: center;
`;

export const StackStyled = styled(Stack)`
  height: calc(100vh - 9rem);
  justify-content: center;
  padding-bottom: 4.5rem;
`;
