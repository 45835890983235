import { PadBox } from '@bedrock-layout/padbox';
import { Inline, Stack } from '@bedrock-layout/primitives';
import styled from 'styled-components';

export const TableContainerStyled = styled(Stack)`
  border: 1px solid var(--color-whiteSmoke);
  position: relative;
  border-radius: 4px;
`;

export const TableHeaderContainerStyled = styled(PadBox)`
  border-block-end: 1px solid var(--color-whiteSmoke);
`;

export const TableHeaderStyled = styled(PadBox)<{ width?: string }>`
  width: ${({ width = '25%' }) => width};
  border-inline-end: 1px solid var(--color-whiteSmoke);
  min-block-size: 3rem;

  &:last-child {
    border-inline-end: none;
  }
`;

export const TableDataContainerStyled = styled(PadBox)`
  border-block-end: 1px solid var(--color-whiteSmoke);
`;

export const TableDataStyled = styled(PadBox)<{ width?: string }>`
  width: ${({ width = '25%' }) => width};
  border-inline-end: 1px solid var(--color-whiteSmoke);
  min-block-size: 3rem;

  &:last-child {
    border-inline-end: none;
  }
`;

export const TableFooterContainerStyled = styled(PadBox)`
  border-block-start: 1px solid var(--color-whiteSmoke);
`;

export const AddFieldStyled = styled(Inline)`
  background-color: var(--color-white);
  color: var(--color-primary1);
  cursor: pointer;
`;

export const TableRowContainerStyled = styled.ul`
  list-style: none;
  padding: 0;
`;
