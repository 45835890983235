import _isNil from 'lodash/isNil';
import { memo } from 'react';
import type { NodeProps } from 'reactflow';

import { Components } from './ruleNode';

type RuleNodeTypes = 'dtNode' | 'srNode' | 'ruleSetNode';

function isOfNodeType(nodeType: string): nodeType is RuleNodeTypes {
  return ['dtNode', 'srNode', 'ruleSetNode'].includes(nodeType);
}

export const RuleNode = memo(
  ({ data, isConnectable, id, ...rest }: NodeProps) => {
    const { nodeType = '' } = data;
    const Component = isOfNodeType(nodeType) ? Components[nodeType] : null;

    if (_isNil(Component)) {
      return null;
    }

    return (
      <Component data={data} id={id} isConnectable={isConnectable} {...rest} />
    );
  }
);

RuleNode.displayName = 'RuleNode';
