import { permissionObj } from '../PermissionComponent/constant';
import type { ButtonOptionItemType, ButtonOptionsIdsType } from './type';

export const buttonOptionIdsObj: Record<
  ButtonOptionsIdsType,
  ButtonOptionsIdsType
> = {
  publish: 'publish',
  reject: 'reject',
  requestReview: 'requestReview',
  rollback: 'rollback',
  preview: 'preview',
  resetEditor: 'resetEditor',
  restoreToDraft: 'restoreToDraft',
  archived: 'archived',
  'cache-production': 'cache-production',
  'cache-staging': 'cache-staging',
};

// permssionList is list of permissions on which button Option is enabled.
export const defaultButtonOptions: ButtonOptionItemType[] = [
  {
    label: 'Publish',
    subTitle: 'Publish to production',
    id: buttonOptionIdsObj.publish,
    permissionList: [permissionObj.publish, permissionObj.approve],
  },
  {
    label: 'Request Changes',
    subTitle: 'Convert to draft for more changes',
    id: buttonOptionIdsObj.reject,
    permissionList: [permissionObj.edit],
    statusShouldBe: ['inreview'],
  },
  {
    label: 'Request Review',
    subTitle: 'Request for approval',
    id: buttonOptionIdsObj.requestReview,
    permissionList: [permissionObj.create, permissionObj.edit],
    statusShouldBe: ['tested'],
  },
];
