import { atom } from 'jotai';

import type {
  ChecksumWarningModel,
  ProfileModel,
  SiteConstantsModel,
  SubscriptionPlanType,
  UsedConnectorMappingInEntityType,
  WorkSpaceDetailsModel,
} from '../types';

export const userProfileAtom = atom<ProfileModel | null>(null);

export const subscriptionPlanAtom = atom<SubscriptionPlanType | null>(null);

export const siteConstantsAtom = atom<SiteConstantsModel | null>(null);

export const allWorkspaceAtom = atom<WorkSpaceDetailsModel[] | null>(null);

export const checksumWarningAtom = atom<ChecksumWarningModel>({
  showPopup: false,
});

export const enableGetAllWorkspaceApiAtom = atom<boolean>(false);

export const enablePermissionApiAtom = atom<boolean>(false);

export const isFetchedUserStateAtom = atom<boolean>(false);

export const usedConnectorMappingAtom = atom<
  UsedConnectorMappingInEntityType | undefined
>(undefined);

export const premiumFeatureAtom = atom<Record<string, any> | undefined>(
  undefined
);
