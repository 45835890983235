import { Inline } from '@bedrock-layout/primitives';
import styled, { css } from 'styled-components';
import { Typography } from 'ui';

const getStatusCSS = (status: string) => {
  switch (status) {
    case 'Success':
      return css`
        color: var(--color-green);
      `;
    case 'Error':
      return css`
        color: var(--color-statusTextRed);
      `;
    case 'Failed':
      return css`
        color: var(--color-statusTextRed);
      `;
    default:
      return css``;
  }
};

export const IOContainer = styled.div`
  width: 50%;
  padding: 1rem;
  border: 0.1rem solid var(--color-lightGray5);
  border-radius: 0.5rem;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
export const IOWrapper = styled(Inline)`
  inline-size: 100%;
`;

export const LogsDetailContainer = styled.div`
  border-radius: 0.8rem;
  border: 0.1rem solid rgba(0, 0, 0, 0.08);
  background: var(--color-white);
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
`;

export const DetailsGoBackButton = styled.div`
  font-size: 1.4rem;
  cursor: pointer;
  border: none;
  background: transparent;
  display: flex;
  -webkit-box-pack: center;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  gap: 1rem;
`;

export const LogIdText = styled(Typography)`
  overflow-wrap: anywhere;
`;

export const MessageText = styled(Typography)<{ status: string }>`
  overflow-wrap: anywhere;
  ${({ status }) => getStatusCSS(status)}
`;

export const DetailsTableContainer = styled.div``;
