import styled from 'styled-components';

export const InputModalContainer = styled.div`
  position: relative;
  background-color: var(--color-white);
  display: flex;
`;

export const InputModalStyled = styled.textarea`
  flex: 1;
  background-color: var(--color-whiteSmoke);
  border: none;
  border-radius: 0.5rem;
  padding: 0.4rem 0.8rem;
  outline: none;
  resize: none;
`;

export const IconContainer = styled.div`
  position: absolute;
  background-color: var(--color-darkGray);
  border-radius: 0.4rem;
  inline-size: 2.5rem;
  block-size: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  right: -1rem;
  top: -1rem;
  cursor: pointer;
`;
