import { SheetSize, useLayer } from 'ui';

import type { ResultType } from '../components/SimpleRule/Results';
import { JsonNodeSheetV2 } from '../components/SimpleRule/Results/JsonNodeSheetV2';

export type UseOpenJsonEditorProps = {
  type: string;
  index?: number;
  section?: ResultType;
  size?: SheetSize;
  isJsonInCustomInput?: boolean;
};

export function useOpenJsonEditorSheetV2({
  index = 0,
  section = 'thenDataParams',
  size,
  isJsonInCustomInput,
  type,
}: UseOpenJsonEditorProps) {
  return useLayer(
    <JsonNodeSheetV2
      type={type}
      index={index}
      section={section}
      size={size}
      isJsonInCustomInput={isJsonInCustomInput}
    />
  );
}
