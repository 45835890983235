import { Inline, Stack } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { Control } from 'react-hook-form';
import {
  DropdownField,
  NectedEditorField,
  NectedSuggestionModel,
  Typography,
} from 'ui';

import { editorDomain } from '../../../../../../utils/constant';
import { useGetPublishedConnectors } from '../../../../../DataSets/hooks/useGetPublishedConnectors';
import { PublishedConnectors } from '../../../../../Rules/components/DecisionTable/types';
import { filterRestApiConnectorsFromPublished } from '../../../../../Rules/utils/common';
import { isWorkflowReadOnlyAtom } from '../../../../atoms/atoms';
import { CodeContainer } from './CodeMapping.styled';

type CodeMappingProps = {
  tokens: NectedSuggestionModel[];
  control?: Control<any>;
  handleGetExecutionValues: (items: Record<string, any>) => void;
  executedValue?: Record<string, any>;
};

export type RestAPIEditorPayload = {
  name: string;
  value: string;
  type: string;
  prodBaseURL?: string;
  stagBaseURL?: string;
};

export function CodeMapping({
  control,
  tokens,
  handleGetExecutionValues,
  executedValue,
}: CodeMappingProps) {
  const { data } = useGetPublishedConnectors(false);
  const [publishRestAPIConnectors, setPublishRestAPIConnectors] = useState<
    RestAPIEditorPayload[]
  >([]);

  const [isWorkflowReadOnly] = useAtom(isWorkflowReadOnlyAtom);

  useEffect(() => {
    if (!_isNil(data)) {
      const publishedConnectors = data.getConnector.data;

      const publishConnectorIdAndPlugin: PublishedConnectors = {};

      if (publishedConnectors.length > 0) {
        publishedConnectors.forEach((connector) => {
          publishConnectorIdAndPlugin[connector.id] = connector;
        });
      }
      setPublishRestAPIConnectors(
        filterRestApiConnectorsFromPublished(publishConnectorIdAndPlugin)
      );
    }
  }, [data]);

  return (
    <Stack gutter={16}>
      <Stack gutter={8}>
        <Inline>
          <Typography fontWeight={700}>Select language</Typography>
        </Inline>

        <DropdownField
          control={control}
          name="language"
          disabled={isWorkflowReadOnly}
          options={[
            {
              label: 'JavaScript',
              value: 'JS',
            },
            {
              label: 'Formula',
              value: 'excelFormula',
            },
          ]}
        />
      </Stack>
      <Stack>
        <Inline>
          <Typography fontWeight={700}>Add Query</Typography>
        </Inline>
        <div>
          <CodeContainer>
            <NectedEditorField
              name="query"
              control={control}
              domain={editorDomain}
              methods="read,write"
              mode="js"
              customSuggestions={tokens}
              handleGetExecData={handleGetExecutionValues}
              execValues={executedValue}
              restApiPayload={publishRestAPIConnectors}
              readOnly={isWorkflowReadOnly}
            />
          </CodeContainer>
        </div>
      </Stack>
    </Stack>
  );
}
