import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import _isNil from 'lodash/isNil';
import styled, { css } from 'styled-components';

export const ConditionBlockContainer = styled.div<{
  hasSwitchField: boolean;
  hasRelativeField: boolean;
  padding?: string;
}>`
  min-inline-size: 45rem;
  border: 1px solid var(--color-whiteSmoke);
  position: relative;

  min-block-size: initial;

  ${({ hasSwitchField = false }) => (hasSwitchField ? 'display: flex;' : '')}

  align-items: ${({ hasRelativeField, hasSwitchField }) =>
    hasSwitchField ? (hasRelativeField ? 'end' : 'start') : ''};

  ${({ padding = null }) => !_isNil(padding) && `padding: ${padding};`}
`;

export const ResultBlockContainer = styled(Inline)<{ dataType: string }>`
  min-block-size: 5rem;
  inline-size: 20rem;
  border: 1px solid var(--color-whiteSmoke);
  position: relative;

  ${({ dataType }) => dataType !== 'boolean' && 'display:block'}
`;

export const RowActionsContainer = styled(Inline)`
  min-inline-size: 12rem;
`;

export const AddRow = styled(PadBox)`
  border: 1px solid var(--color-whiteSmoke);
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  flex: 1;
`;

export const AddRowStyled = styled(Inline)<{ $disabled: boolean }>`
  cursor: pointer;
  color: var(--color-primary1);
  background-color: var(--color-white);

  ${({ $disabled = false }) =>
    $disabled &&
    css`
      cursor: default;
      color: var(--color-darkGray);
    `}
`;

export const RowNumContainer = styled(Inline)<{ isError: boolean }>`
  min-inline-size: 4rem;
  padding: 12px 8px;
  border: 1px solid var(--color-whiteSmoke);
  border-top: none;

  ${({ isError }) => isError && `background-color: var(--color-snow);`}
`;

export const ExpandButtonContainer = styled.button`
  cursor: pointer;
  background-color: var(--color-lightGray2);
  block-size: 3rem;
  inline-size: 3rem;
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.4rem;
  border: none;

  &:disabled {
    cursor: auto;
  }
`;
