import { Ref } from 'react';
import { useController } from 'react-hook-form';
import type { FieldValues, UseControllerProps } from 'react-hook-form';
import { DateTimePickerInput, DateTimePickerInputProps } from 'ui';

import { Field } from '../Field';

export type DateTimePickerFieldPropTypes<P extends FieldValues> =
  UseControllerProps<P> &
    DateTimePickerInputProps & {
      ref?: Ref<HTMLInputElement>;
      showError?: boolean;
    };

export const DateTimePickerField = <P extends FieldValues>(
  props: DateTimePickerFieldPropTypes<P>
) => {
  const { name, control, ref, rules, defaultValue, showError, ...rest } = props;
  const { field, fieldState } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  return (
    <Field fieldState={fieldState} showError={showError}>
      <DateTimePickerInput {...field} {...rest} />
    </Field>
  );
};
