import { Stack } from '@bedrock-layout/stack';
import styled, { css } from 'styled-components';

export const ResponseHeader = styled.div<{
  $isActive: boolean;
  $maxWidthOnTop?: string;
}>`
  border-top: 1px solid var(--color-lightGray);
  border-left: 1px solid var(--color-lightGray);
  border-right: 1px solid var(--color-lightGray);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  padding: 0.8rem 1rem;
  ${({ $maxWidthOnTop = '40vw' }) =>
    css`
      max-inline-size: ${$maxWidthOnTop};
    `}

  background-color: var(--color-white);

  ${({ $isActive = false }) =>
    !$isActive &&
    css`
      border: 1px solid var(--color-lightGray);
      border-radius: 4px;
    `}
`;

export const ResponseCacheItems = styled(Stack)<{ $disabled?: boolean }>`
  border: 1px solid var(--color-lightGray);
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  padding: 1rem;
  background-color: var(--color-white);

  ${({ $disabled = false }) =>
    $disabled &&
    css`
      pointer-events: none;
    `}
`;
