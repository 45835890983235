import { gql, useMutation } from '@apollo/client';

import type { UpdateAuthConfigKey } from '../../../../types';
import type { UpdateAuthConfigKeyResult } from '../models';

const updateAuthConfigKey = gql`
  mutation updateCredential(
    $id: String!
    $name: String!
    $value: Map!
    $checksum: String!
  ) {
    updateCredential(
      input: { id: $id, name: $name, value: $value, checksum: $checksum }
    ) {
      id
      name
      type
      value
      authType
      checksum
      isValid
      authCodeUrl
    }
  }
`;

export function useUpdateAuthConfigKey() {
  return useMutation<UpdateAuthConfigKeyResult, UpdateAuthConfigKey>(
    updateAuthConfigKey
  );
}
