import { PadBox, Inline } from '@bedrock-layout/primitives';
import styled from 'styled-components';

export const ApiContainer = styled(PadBox)`
  background-color: var(--color-whiteSmoke);
  border-radius: 4px;
`;

export const Curl = styled(Inline)`
  overflow-x: auto;
`;

export const NavigateToCredentialStyled = styled.a`
  color: var(--color-white);
  text-decoration: none;
`;
