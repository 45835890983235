import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import type { Control, UseFormWatch } from 'react-hook-form';
import { FiPlusCircle } from 'react-icons/fi';
import {
  Button,
  CheckboxField,
  TextButton,
  TextField,
  Toast,
  TooltipReact,
  Typography,
  toasts,
} from 'ui';

import { siteConstantsAtom } from '../../../../atom';
import { useSendEventToGTM } from '../../../../hooks/useSendEventToGTM';
import { ruleLimitsConfigAtom } from '../../../../pages/Rules';
import { getTooltipText } from '../../../../utils/common';
import { AddButton } from '../../../AddButton';
import { CaseSensitiveCheckbox } from './CaseSensitiveCheckbox';
import {
  CustomAttributeTabCommonProps,
  FormHeader,
  customAttributeDefaultValues,
} from './CustomAttributeSheet';
import {
  FormFooter,
  FormPanel,
  Table,
  TableData,
  TableHeader,
  TableRow,
} from './CustomAttributeSheet.styled';
import { DataTypeSelection } from './DataTypeSelection';
import { SampleValueField } from './SampleValueField';
import type { FormValues } from './validation';

type InputAttributeProps = CustomAttributeTabCommonProps & {
  watch: UseFormWatch<FormValues>;
  control: Control<FormValues, any>;
  setTabIndex: (index: number) => void;
};

export function InputAttributes({
  fields,
  append,
  remove,
  control,
  watch,
  setValue,
  onSubmit,
  tokensSet,
  isReadOnly,
  setTabIndex,
  localDataSetFieldsById,
}: InputAttributeProps) {
  const [siteConstants] = useAtom(siteConstantsAtom);
  const [limitConfig] = useAtom(ruleLimitsConfigAtom);

  const formHeaders: FormHeader[] = [
    {
      label: 'Name',
    },
    {
      label: 'Type',
    },
    {
      label: 'Test Value',
    },
    {
      label: 'Can be Null',
      tooltipContent: (
        <Typography>
          {getTooltipText(siteConstants, 'rules', 'ciCanBeNull')}
        </Typography>
      ),
    },
    {
      label: 'Case Sensitive',
      tooltipContent: (
        <Typography>
          {getTooltipText(siteConstants, 'rules', 'ciIsCaseSensitive')}
        </Typography>
      ),
    },
    {
      label: 'Is Optional',
      tooltipContent: (
        <Typography>
          {getTooltipText(siteConstants, 'rules', 'ciIsMandatory')}
        </Typography>
      ),
    },
    {
      label: '',
    },
  ];

  const isDataTypeREST = (
    index: number,
    localWatch: UseFormWatch<FormValues>
  ) => {
    return ['restAPI'].includes(
      localWatch(`attributes.${index}.dataType`)?.value as string
    );
  };

  const { sendEventToGTM } = useSendEventToGTM();

  const handleSendGTMEvent = (action: string) => {
    sendEventToGTM({
      event: 'rule',
      type: 'inputAttributes',
      action,
      element: '',
      nec_source: '',
      action_name: '',
    });
  };

  const handleAddConfig = () => {
    handleSendGTMEvent('add');

    if (!_isEmpty(limitConfig) && fields.length < limitConfig.maxCi.value) {
      append({ ...customAttributeDefaultValues });
    } else if (
      !_isNil(limitConfig?.maxCi?.message) &&
      !_isEmpty(limitConfig?.maxCi?.message)
    ) {
      toasts.info(limitConfig.maxCi.message, 'success');
    }
  };

  const handleDeleteRow = (index: number) => {
    handleSendGTMEvent('delete');

    remove(index);
  };

  return (
    <Stack as={FormPanel}>
      <Stack as={FormPanel} gutter={'2.4rem'} padding={['1.2rem', '2.4rem']}>
        <Stack gutter={15}>
          <Inline>
            <Inline>
              <Toast
                type="infoBlue"
                message="Type of an attribute once saved cannot be edited"
              />
            </Inline>
          </Inline>
        </Stack>
        <Stack gutter="1rem">
          <Table>
            <TableRow>
              {formHeaders.map((header) => (
                <TableHeader key={header.label}>
                  <PadBox padding="1.2rem">
                    <Inline align="center" gutter={8}>
                      <Typography>{header.label}</Typography>

                      {!_isNil(header.tooltipContent) && (
                        <TooltipReact id={header.label} placement="top-start">
                          {header.tooltipContent}
                        </TooltipReact>
                      )}
                    </Inline>
                  </PadBox>
                </TableHeader>
              ))}
            </TableRow>

            {fields.map((field, index) => {
              return (
                <TableRow key={field.id}>
                  <TableData>
                    <PadBox padding={'1.2rem'}>
                      <TextField
                        control={control}
                        rules={{ required: 'Required' }}
                        name={`attributes.${index}.name`}
                        placeholder="Property Name"
                        showErrorIcon={false}
                        disabled={isReadOnly}
                      />
                    </PadBox>
                  </TableData>

                  <TableData>
                    <PadBox padding={'1.2rem'}>
                      <DataTypeSelection
                        index={index}
                        setValue={setValue}
                        control={control}
                        name={`attributes.${index}.selectedType`}
                        isDisabled={
                          isReadOnly ||
                          (!_isNil(field.sourceType) && field.sourceType !== '')
                        }
                        tokensSet={tokensSet}
                        hideDataSet={true}
                        hideRestApi={true}
                        hideInputAttr={false}
                        setTabIndex={setTabIndex}
                        localDataSetFieldsById={localDataSetFieldsById}
                      />
                    </PadBox>
                  </TableData>

                  <TableData>
                    <PadBox padding={'1.2rem'}>
                      <SampleValueField
                        index={index}
                        setValue={setValue}
                        control={control}
                        name={`attributes.${index}.sampleValue`}
                        isDisabled={isReadOnly}
                        tokensSet={tokensSet}
                      />
                    </PadBox>
                  </TableData>

                  <TableData>
                    <PadBox padding="2.3rem">
                      <CheckboxField
                        control={control}
                        name={`attributes.${index}.isNullable`}
                        useId={`${index}_isNullable`}
                        disabled={isReadOnly || isDataTypeREST(index, watch)}
                      />
                    </PadBox>
                  </TableData>

                  <TableData>
                    <PadBox padding="2.3rem">
                      <CaseSensitiveCheckbox
                        control={control}
                        name={`attributes.${index}.isCaseSensitive`}
                        field={`attributes.${index}.dataType`}
                        setValue={setValue}
                        index={index}
                        disabled={isReadOnly || isDataTypeREST(index, watch)}
                      />
                    </PadBox>
                  </TableData>

                  <TableData>
                    <PadBox padding="2.3rem">
                      <CheckboxField
                        control={control}
                        name={`attributes.${index}.isOptional`}
                        useId={`${index}_isOptional`}
                        disabled={isReadOnly || isDataTypeREST(index, watch)}
                      />
                    </PadBox>
                  </TableData>

                  <TableData>
                    <PadBox padding="2.3rem">
                      <TextButton
                        onClick={() => handleDeleteRow(index)}
                        disabled={isReadOnly}
                      >
                        Delete
                      </TextButton>
                    </PadBox>
                  </TableData>
                </TableRow>
              );
            })}
          </Table>

          <Inline align="center">
            {isReadOnly ? (
              <FiPlusCircle color="var(--color-darkGray)" />
            ) : (
              <AddButton />
            )}

            <TextButton disabled={isReadOnly} onClick={handleAddConfig}>
              Add Field
            </TextButton>
          </Inline>
        </Stack>
      </Stack>
      {!isReadOnly && (
        <Inline as={FormFooter} padding="0.8rem" justify="end">
          <Button appearance="filled" onClick={() => onSubmit('nextTab')}>
            Save and Next
          </Button>
          <Button appearance="contained" onClick={() => onSubmit('closeSheet')}>
            Save and Close
          </Button>
        </Inline>
      )}
    </Stack>
  );
}
