import { PadBox } from '@bedrock-layout/padbox';
import styled from 'styled-components';

export const SqlPillStyled = styled(PadBox)`
  min-inline-size: 4rem;
  border-radius: 2rem;
  background-color: var(--color-lightGray);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
`;

export const SqlEditorContainer = styled(PadBox)<{ $readOnly?: boolean }>`
  block-size: calc(100vh - 25rem);
  border: 1px solid var(--color-lightGray);
  border-radius: 4px;

  ${({ $readOnly = false }) => $readOnly && 'pointer-events: none;'}
`;

export const DragContainer = styled.div<{ $isVisible?: boolean }>`
  ${({ $isVisible = false }) =>
    $isVisible
      ? 'opacity: 1; pointer-events: none;'
      : 'opacity: 0; pointer-events: auto;'}
`;
