import { gql, useMutation } from '@apollo/client';

const requestChangesMutation = gql`
  mutation requestChanges(
    $id: String!
    $entityType: EntityTypes!
    $comment: String!
    $checksum: String!
  ) {
    requestChanges(
      id: $id
      entityType: $entityType
      comment: $comment
      checksum: $checksum
    ) {
      status
      checksum
      message
    }
  }
`;

export function useRequestChangesEntity(entityName: string) {
  return useMutation<Record<string, any>>(requestChangesMutation);
}
