import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import styled from 'styled-components';

export const FormContainer = styled(Inline)`
  flex-wrap: wrap;
`;

export const FieldContainer = styled(Stack)<{
  width: string;
  height: string;
  styles: any;
}>`
  ${({ width }) => width !== '' && `width: ${width}`};
  ${({ height }) => height !== '' && `height: ${height}`};
  ${({ styles }) => styles};
`;

export const EditorContainer = styled.div`
  block-size: 31rem;
`;
