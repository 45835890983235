import { gql, useLazyQuery } from '@apollo/client';
import { DataTypes } from 'ui';

const getVariablesQuery = gql`
  query getVariables {
    getSystemVars: getVariables(
      filter: { category: "systemVar" }
      perPage: 100
    ) {
      data {
        id
        name
        dataType
        category
      }
    }

    getGlobalVars: getVariables(
      filter: { category: "globalVar" }
      perPage: 100
    ) {
      data {
        id
        name
        dataType
        category
        value
      }
    }
  }
`;

type VariableModel = {
  id: string;
  name: string;
  dataType: DataTypes;
  category: string;
  value: any;
};

export type VariablesResult = {
  getGlobalVars: { data: VariableModel[] };
  getSystemVars: { data: VariableModel[] };
};

export function useGetVariables() {
  return useLazyQuery<VariablesResult>(getVariablesQuery, {
    fetchPolicy: 'cache-and-network',
  });
}
