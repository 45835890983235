import { Inline, PadBox, Stack } from '@bedrock-layout/primitives';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _map from 'lodash/map';
import _reduce from 'lodash/reduce';
import { useEffect, useState } from 'react';
import {
  UseControllerProps,
  UseFormSetValue,
  useForm,
  useWatch,
} from 'react-hook-form';
import {
  Button,
  Dataset,
  ExpandingTextField,
  Modal,
  ModalContent,
  NectedEditorField,
  NectedSuggestionModel,
  TooltipReact,
  Typography,
  toasts,
  useCurrentLayer,
  useLayer,
} from 'ui';

import { siteConstantsAtom } from '../../../../../atom';
import { customAttributesAtom } from '../../../../../components/rules/forms/CustomAttributeSheet/CustomAttributeSheet';
import {
  getTooltipText,
  isCorrectJsSyntaxV2,
} from '../../../../../utils/common';
import { editorDomain } from '../../../../../utils/constant';
import { useGenerateDataset } from '../../../../Workflow/hooks/useGenerateDataset';
import { useUpdateExecutedValueRules } from '../../../hooks/useUpdateExecutedValueRules';
import { isRuleReadOnlyAtom, selectedDataSetAtom } from '../../../index';
import { jsNodeSchema } from '../../../schema';
import { updateDataSetOnChange } from '../../../utils/common';
import { dataSetParamsAtom } from '../../CreateRuleSheet/CreateRuleSheet';
import { SyntaxErrorContainer } from '../../RuleComponents/RuleComponents.styled';
import { OnCloseNodeModal } from '../../SimpleRule/Results/OnCloseNodeModal';
import { decisionTableNodesAtom } from '../DecisionTable';
import { JsNodeValues } from '../models';
import { ExcelEditorContainer, FooterStyled } from './ExcelNodeModal.styled';

type ExcelNodeModalProps = Omit<UseControllerProps, 'name'> & {
  ruleId: string;
  handleChangeValue: UseFormSetValue<any>;
  index: number;
  rowKey: string;
  rowIndex: number;
};

export function ExcelNodeModal({
  ruleId,
  handleChangeValue,
  rowKey,
  rowIndex,
  index,
  control: formControl,
}: ExcelNodeModalProps) {
  const [ruleList, setRuleList] = useAtom(decisionTableNodesAtom);
  const [dataSetVariables] = useAtom(dataSetParamsAtom);
  const [isReadOnly] = useAtom(isRuleReadOnlyAtom);
  const [customAttributes] = useAtom(customAttributesAtom);
  const [dataSetSelected] = useAtom(selectedDataSetAtom);
  const [hasEditorChanged, setHasEditorChanged] = useState(false);
  const [siteConstants] = useAtom(siteConstantsAtom);

  const { close: closeExcelNode } = useCurrentLayer();

  const [, setDataSetTokens] = useState<string[]>([]);

  const [isQueryValidNected, setIsQueryValidNected] = useState(true);
  const [suggestionObjs, setSuggestionObjs] = useState<NectedSuggestionModel[]>(
    []
  );
  const [isReturnTypeRequired, setIsReturnTypeRequired] = useState(false);
  const [returnType, setReturnType] = useState<string | null>(null);

  const [updatedDataset, setUpdatedDataset] = useState<Record<string, Dataset>>(
    {}
  );

  const nodeName = `rows.${rowIndex}.${rowKey}.condition.${index}.${ruleId}`;

  const { control, handleSubmit, setValue, watch } = useForm<JsNodeValues>({
    resolver: zodResolver(jsNodeSchema),
    defaultValues: {
      nodeName:
        _isEmpty(ruleList[ruleId].name) || _isNil(ruleList[ruleId].name)
          ? 'Untitled'
          : ruleList[ruleId].name,
      nodeQuery:
        _isEmpty(ruleList[ruleId].query) || _isNil(ruleList[ruleId].query)
          ? ''
          : ruleList[ruleId].query,
    },
    mode: 'onSubmit',
  });

  const { open: onCloseModal } = useLayer(
    <OnCloseNodeModal onClose={() => closeExcelNode()} />
  );

  const jsCode = useWatch({
    name: 'nodeQuery',
    control,
  });

  const { tokens: dataSetSuggestionsObj } = useGenerateDataset({
    updatedDataset,
  });

  const { executedValue: execValues, handleGetExecutionValues } =
    useUpdateExecutedValueRules({
      updatedDataset,
    });
  useEffect(() => {
    const name = watch('nodeName');

    if (name === 'Any' && hasEditorChanged) {
      setValue('nodeName', 'Formula');
    }
  }, [hasEditorChanged]);

  const onSubmit = (data: JsNodeValues) => {
    if (!isQueryValidNected) {
      toasts.error(
        'You still have some errors in the editor. Please resolve to proceed',
        'error'
      );

      return;
    }

    setIsReturnTypeRequired(true);
  };

  useEffect(() => {
    setSuggestionObjs([...dataSetSuggestionsObj]);
  }, [dataSetSuggestionsObj]);

  useEffect(() => {
    if (!_isNil(dataSetVariables)) {
      const dataSetSuggestions = _reduce(
        dataSetVariables,
        (result: string[], value, key) => {
          if (!_isNil(value.attributes)) {
            return [
              ...result,
              ..._map(value.attributes, (attributeValue, attributeKey) => {
                if (
                  ['string', 'dateTime', 'date'].includes(
                    attributeValue.dataType
                  )
                ) {
                  return `"<<${key}.${attributeKey}>>"`;
                }

                return `<<${key}.${attributeKey}>>`;
              }),
            ];
          }

          return result;
        },
        []
      );

      setUpdatedDataset((prev) => ({
        ...prev,
        ...updateDataSetOnChange(
          customAttributes,
          dataSetVariables,
          dataSetSelected
        ),
      }));

      setDataSetTokens([...dataSetSuggestions]);
    }
  }, [dataSetVariables]);

  const updatedQuery = watch('nodeQuery');

  useEffect(() => {
    if (
      !_isNil(returnType) &&
      !_isEmpty(returnType) &&
      returnType !== 'undefined'
    ) {
      setRuleList((prev) => ({
        ...prev,
        [ruleId]: {
          ...prev[ruleId],
          name: watch('nodeName'),
          query: watch('nodeQuery'),
          nodeType: 'excelCondition',
        },
      }));

      handleChangeValue(nodeName, {
        leftOperands: 1,
        rightOperands: 0,
        value: watch('nodeQuery'),
        type: 'excelCondition',
      });

      closeExcelNode();
    }
  }, [returnType]);

  useEffect(() => {
    setReturnType('');
  }, [updatedQuery]);

  return (
    <Modal size="extraLargeXS" hideHeader hideCloseButton>
      <ModalContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack gutter="1rem">
            <PadBox padding="1rem">
              <Stack gutter={8}>
                <Inline align="start" gutter={16}>
                  <Typography name="heading2">
                    <ExpandingTextField
                      control={control}
                      name="nodeName"
                      disabled={isReadOnly}
                    />
                  </Typography>
                </Inline>

                <Inline gutter={8} align="center">
                  <Typography>Add Formula</Typography>
                  <TooltipReact id="js-condition-type">
                    <Typography>
                      {getTooltipText(
                        siteConstants,
                        'rules',
                        'customExcelRule'
                      )}
                    </Typography>
                  </TooltipReact>
                </Inline>
                <ExcelEditorContainer $readOnly={isReadOnly}>
                  <NectedEditorField
                    name="nodeQuery"
                    control={control}
                    mode="formula"
                    customSuggestions={suggestionObjs}
                    readOnly={isReadOnly}
                    onSetEditorValidity={setIsQueryValidNected}
                    setReturnType={(type) => {
                      if (type === 'boolean') {
                        setReturnType(type);
                      } else {
                        setReturnType('undefined');
                      }
                      setIsReturnTypeRequired(false);
                    }}
                    sendReturnType={isReturnTypeRequired}
                    domain={editorDomain}
                    execValues={execValues}
                    handleGetExecData={handleGetExecutionValues}
                    setHasEditorChanged={setHasEditorChanged}
                  />
                </ExcelEditorContainer>
              </Stack>
            </PadBox>

            {!isCorrectJsSyntaxV2(jsCode, updatedDataset).status && (
              <SyntaxErrorContainer padding="1rem">
                <Typography>
                  {isCorrectJsSyntaxV2(jsCode, updatedDataset).message}
                </Typography>
              </SyntaxErrorContainer>
            )}

            {returnType === 'undefined' && (
              <SyntaxErrorContainer padding="1rem">
                <Typography>
                  The last statement must be a boolean expression
                </Typography>
              </SyntaxErrorContainer>
            )}

            <FooterStyled padding={[4, 8]}>
              <Inline justify="end">
                <Button
                  type="button"
                  appearance="filled"
                  onClick={() => {
                    if (hasEditorChanged) {
                      onCloseModal();
                    } else {
                      closeExcelNode();
                    }
                  }}
                >
                  Close
                </Button>

                <Button
                  type="submit"
                  appearance="contained"
                  disabled={isReadOnly}
                >
                  Save & Close
                </Button>
              </Inline>
            </FooterStyled>
          </Stack>
        </form>
      </ModalContent>
    </Modal>
  );
}
