import { gql, useLazyQuery } from '@apollo/client';

import type { DataSetModel } from '../models';

const getDataSetsQuery = gql`
  query getDataSet(
    $page: Int
    $perPage: Int
    $sort: Map
    $filters: QueryOperators
  ) {
    getDataSet(page: $page, perPage: $perPage, sort: $sort, filters: $filters) {
      data {
        id
        name
        status
        version
        checksum
        createdAt
        createdBy
        publishedAt
        publishedBy
        updatedAt
        updatedBy
        connector {
          id
          name
          plugin {
            imageUrl
            name
          }
        }
        versionInfo {
          currentVersion
          currentLiveVersion
          lastVersion
        }
      }

      paginationInfo {
        currentPage
        perPage
        totalPage
        totalRecord
      }
    }
  }
`;

export type GetDataSetsQueryResult = {
  getDataSet: {
    data: DataSetModel[];
    paginationInfo: {
      perPage: number;
      totalRecord: number;
      totalPage: number;
    };
  };
};

export type DataSetTablePayloadVariables = {
  page: number;
  perPage: number;
  sort?: Record<string, any>;
  filters?: Record<string, any>;
};

export function useGetDataSets() {
  return useLazyQuery<GetDataSetsQueryResult, DataSetTablePayloadVariables>(
    getDataSetsQuery,
    {
      fetchPolicy: 'no-cache',
    }
  );
}

export type UseGetDataSetsReturnType = ReturnType<typeof useGetDataSets>;
