import { PadBox } from '@bedrock-layout/padbox';
import styled from 'styled-components';

export const AttributeSelectionLauncher = styled(PadBox)<{
  $isDisabled: boolean;
}>`
  background: var(--color-white);
  border-radius: 4px;
  cursor: pointer;
  outline: 1px solid var(--color-lightGray);

  ${({ $isDisabled = false }) =>
    $isDisabled && 'background: var(--color-whiteSmoke);'}
`;
