import { PadBox } from '@bedrock-layout/padbox';
import { Stack } from '@bedrock-layout/stack';
import { JsonHeroTreeViewer, NectedEditor } from 'ui';

import { ExecutionView } from '../../../../../../components/jsonOutputField/components/ExecutionView';
import { editorDomain } from '../../../../../../utils/constant';
import {
  JsonContainer,
  JsonTreeContainer,
  OutputContainer,
} from './RestApiTest.styled';

type RestApiNodeTestProps = {
  output?: Record<string, any>;
};
export function RestApiNodeTest({
  output = {
    _comment: 'Please Test the node first',
  },
}: RestApiNodeTestProps) {
  return (
    <PadBox padding="2rem">
      <OutputContainer padding="1rem">
        <Stack gutter="1rem">
          <ExecutionView>
            <JsonContainer>
              <NectedEditor
                domain={editorDomain}
                mode="json"
                defaultValue={JSON.stringify(output, null, 2)}
                readOnly
              />
            </JsonContainer>

            <JsonTreeContainer>
              <JsonHeroTreeViewer json={output} />
            </JsonTreeContainer>
          </ExecutionView>
        </Stack>
      </OutputContainer>
    </PadBox>
  );
}
