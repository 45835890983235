import styled from 'styled-components';
import { Typography } from 'ui';

export const Container = styled.div<{ isVisible: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  height: 100vh;
  margin: 0px;
  background: #fff;
  opacity: 0.8;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 1000;

  ${({ isVisible }) => (isVisible ? 'display: flex' : 'display: none')}
`;

export const BlueTextStyled = styled(Typography)`
  color: var(--color-primary1);
`;
