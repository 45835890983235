import { Inline } from '@bedrock-layout/primitives';
import _isNil from 'lodash/isNil';
import { memo } from 'react';
import type { RefObject } from 'react';
import { Spinner } from 'ui';

import { DataSetTestOutputResult } from './DataSetTestOutputResult';
import type { DataSetTestOutputResultProps } from './DataSetTestOutputResult';

type DataSetOutputProps = Pick<
  DataSetTestOutputResultProps,
  'data' | 'error'
> & {
  isLoading: boolean;
  outputRef: RefObject<HTMLDivElement>;
};

export function DataSetOutput({
  isLoading,
  error,
  data,
  outputRef,
}: DataSetOutputProps) {
  if (isLoading) {
    return (
      <Inline justify="center">
        <Spinner size="small" />
      </Inline>
    );
  }

  return !_isNil(error) || !_isNil(data) ? (
    <DataSetTestOutputResult data={data} error={error} outputRef={outputRef} />
  ) : null;
}

export const DataSetOutputMemoized = memo(DataSetOutput);
