import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
import { Spinner, Typography, toasts } from 'ui';

import { generateCaptchaToken, getRedirectUrl } from '../../utils/common';
import { Container } from '../OAuth2Redirect/OAuth2Redirect.styled';
import { useSignedURL } from './useSignedURL';

export const RedirectViaSignedURL = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const { signinWithSignedURL } = useSignedURL('signedURL');
  const urlParams = new URLSearchParams(window.location.search);
  const redirectUrl = urlParams.get('redirect');

  useEffect(() => {
    const validateAndRedirect = async () => {
      try {
        if (_isNil(token)) {
          throw new Error('No token found in URL');
        }

        if (_isNil(redirectUrl)) {
          throw new Error('No redirect URL provided');
        }

        await generateCaptchaToken(signinWithSignedURL, {
          tokenId: token,
        });
        window.localStorage.setItem('x-nec-signed-token', token);
      } catch (err: unknown) {
        const message: string =
          err instanceof Error &&
          typeof err.message === 'string' &&
          err.message.trim().length > 0
            ? err.message
            : 'Failed to validate signed URL. Please try again or contact support.';

        toasts.error(message, 'error');
      }
    };
    const preSignedToken = window.localStorage.getItem('x-nec-signed-token');

    if (
      (_isNil(preSignedToken) || _isEmpty(preSignedToken)) &&
      preSignedToken !== token
    ) {
      void validateAndRedirect();
    } else {
      const to = getRedirectUrl(window.location.href) ?? '/home';
      try {
        const url = new URL(to);
        window.open(url, '_self');
      } catch (error) {
        navigate(to, { replace: true });
      }
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Redirecting...</title>
      </Helmet>
      <Container
        align="center"
        justify="center"
        gutter={5}
        style={{ flexDirection: 'column' }}
      >
        <Spinner size="small" />
        <Typography name="heading1">
          Please wait while we redirect you to {redirectUrl}
        </Typography>
      </Container>
    </>
  );
};
