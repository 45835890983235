import { PadBox } from '@bedrock-layout/padbox';
import styled from 'styled-components';

export const OutputContainer = styled(PadBox)`
  min-block-size: 30rem;
  background-color: var(--color-lightGray6);
  overflow-y: auto;
`;

export const TreeContainer = styled(PadBox)`
  background-color: var(--color-white);
  min-block-size: 40rem;
`;

export const JsonContainer = styled.div`
  height: 40rem;
  position: relative;
`;
