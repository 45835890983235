import axios, { AxiosError } from 'axios';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toasts } from 'ui';

import { axiosPrivate } from '../api/axios';
import { useAuth } from '../components/authentication';
import { HttpCodes } from '../responseStatusCode/httpCodes';
import { createUUID } from '../utils/common';
import { useHandleBtnStateAtInterceptor } from './useHandleBtnStateAtInterceptor';
import { useRefreshToken } from './useRefreshToken';

export function useAxiosPrivate() {
  const { auth } = useAuth();
  const { refresh } = useRefreshToken();
  const navigate = useNavigate();

  const { disableAllButtons, enableAllButtons } =
    useHandleBtnStateAtInterceptor();

  const sessionworkspaceUUID = window.sessionStorage.getItem('workspaceUUID');
  const localworkspaceUUID = window.localStorage.getItem('workspaceUUID');

  useEffect(() => {
    const requestInterceptor = axiosPrivate.interceptors.request.use(
      (config) => {
        if (
          config.headers.Authorization === '' ||
          _isNil(config.headers.Authorization)
        ) {
          config.headers.Authorization = `Bearer ${auth.accessToken}`;
          config.headers['nected-ws'] =
            sessionworkspaceUUID ?? localworkspaceUUID ?? '';

          config.headers['nected-trace-id'] = createUUID();
        }

        if (config.method !== 'get') {
          disableAllButtons();
        }

        return config;
      },
      async (error) => {
        await Promise.reject(error);

        if (error.config.method !== 'get') {
          enableAllButtons();
        }
      }
    );

    const responseInterceptor = axiosPrivate.interceptors.response.use(
      (response) => {
        if (response.config.method !== 'get') {
          enableAllButtons();
        }

        return response;
      },
      async (error: AxiosError) => {
        let isPreviousRequest = false;
        const previousRequest = error.config;

        if (error.config?.method !== 'get') {
          enableAllButtons();
        }

        if (
          error.response?.status === HttpCodes.UNAUTHORIZED &&
          !isPreviousRequest
        ) {
          try {
            isPreviousRequest = true;
            const newAccessToken = await refresh();

            if (!_isNil(newAccessToken) && !_isNil(previousRequest)) {
              previousRequest.headers.Authorization = `Bearer ${newAccessToken}`;
              previousRequest.headers['nected-trace-id'] = createUUID();

              return await axios(previousRequest);
            }
          } catch {}
        }

        if (
          error.response?.status === HttpCodes.INTERNAL_SERVER_ERROR ||
          error.response?.status === HttpCodes.BAD_REQUEST
        ) {
          toasts.error(
            // @ts-expect-error
            error.response.data?.message ?? error.message,
            'error-400-500'
          );
        }

        if (
          error.response?.status === HttpCodes.BAD_REQUEST &&
          !isPreviousRequest &&
          (error.response?.data as Record<string, string>).code ===
            'workspace_not_accessible'
        ) {
          const wuid = window.localStorage.getItem('workspaceUUID') ?? '';

          if (!_isNil(wuid) && !_isEmpty(wuid)) {
            window.sessionStorage.setItem('workspaceUUID', wuid);
            navigate('/');
          } else {
            window.localStorage.clear();
            window.sessionStorage.clear();

            window.location.href = '/signin';
          }
        }

        if (
          error.response?.status === HttpCodes.PAYLOAD_TOO_LARGE &&
          !isPreviousRequest &&
          (error.response?.data as Record<string, string>).code ===
            'payload_too_large'
        ) {
          toasts.error(
            (error.response?.data as Record<string, string>).message,
            'error'
          );
        }

        return await Promise.reject(error);
      }
    );

    return () => {
      axiosPrivate.interceptors.request.eject(requestInterceptor);
      axiosPrivate.interceptors.response.eject(responseInterceptor);
    };
  }, [auth, refresh, sessionworkspaceUUID, localworkspaceUUID]);

  return { axiosPrivate };
}
