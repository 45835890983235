import { gql, useLazyQuery } from '@apollo/client';

import type { ConnectorAndPluginModel } from '../../types';
import type { PluginModel } from '../plugin/Plugin';

const getConnectorsQuery = gql`
  query connectorList {
    databasePlugins: getPlugin {
      data {
        id
        name
        displayName
        imageUrl
        category
        checksum
      }
    }

    databaseConnector: getConnector(page: 1, perPage: 50) {
      data {
        id
        name
        checksum
        category
        isSource
        staging {
          isTested
          isPublish
        }
        production {
          isTested
          isPublish
        }
        plugin {
          id
          name
          displayName
          imageUrl
        }
      }
    }
  }
`;

export type GetConnectorsQueryResult = {
  databaseConnector: {
    data: ConnectorAndPluginModel[];
  };
  databasePlugins: {
    data: PluginModel[];
  };
};

export function useGetConnectors() {
  return useLazyQuery<GetConnectorsQueryResult>(getConnectorsQuery, {
    errorPolicy: 'all',
  });
}
