import { Inline } from '@bedrock-layout/inline';
import { Gutter } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { ReactNode, useEffect, useState } from 'react';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';

import { IconButton } from '../Button';
import { Image } from '../Image';
import { TooltipReact } from '../TooltipReact';
import { Typography } from '../Typography';
import { Name } from '../Typography/Typography';
import { ContainerStyled } from './Expander.styled';

export type FontWeight = 400 | 700 | 900;

export type ExpanderProps = {
  title: string | ReactNode;
  children: ReactNode;
  collapsed?: boolean;
  fontWeight?: FontWeight;
  onClick?: () => void;
  name?: Name;
  as?: any;
  tooltip?: string;
  iconUrl?: string;
  tooltipForIcon?: string;
  expanderPosition?: 'left' | 'right';
  expanderTitleGap?: Gutter;
  expanderGap?: Gutter;
  childrenWidth?: number;
};

export function Expander({
  title,
  children,
  collapsed,
  fontWeight,
  onClick,
  as,
  name = 'heading3',
  tooltip,
  iconUrl,
  tooltipForIcon,
  expanderPosition = 'right',
  expanderTitleGap,
  expanderGap = 16,
  childrenWidth,
}: ExpanderProps) {
  const [isCollapsed, setIsCollapsed] = useState(collapsed ?? false);

  const hasIconUrl = !_isNil(iconUrl) && !_isEmpty(iconUrl);

  useEffect(() => {
    if (collapsed !== isCollapsed && !_isNil(collapsed)) {
      setIsCollapsed(collapsed ?? false);
    }
  }, [collapsed]);

  return (
    <Stack gutter={expanderGap} as={!_isNil(as) ? as : 'div'}>
      <Inline
        justify="start"
        align="center"
        stretch={1}
        onClick={() =>
          typeof onClick === 'function'
            ? onClick()
            : setIsCollapsed(!isCollapsed)
        }
        gutter={expanderTitleGap}
      >
        {expanderPosition === 'left' && (
          <Inline justify="end">
            <IconButton type="button">
              {isCollapsed ? (
                <BiChevronDown size={24} />
              ) : (
                <BiChevronUp size={24} />
              )}
            </IconButton>
          </Inline>
        )}

        <Inline gutter={8} align="center">
          <Typography fontWeight={fontWeight} name={name}>
            {title}
          </Typography>

          {!_isNil(tooltipForIcon) && !_isEmpty(tooltipForIcon) ? (
            <TooltipReact
              id={tooltipForIcon}
              launcher={
                hasIconUrl ? (
                  <Image
                    src={iconUrl}
                    alt="usage exceeded alert"
                    size="small"
                  />
                ) : undefined
              }
            >
              <Typography>{tooltipForIcon}</Typography>
            </TooltipReact>
          ) : (
            <>
              {hasIconUrl ? (
                <Image src={iconUrl} alt="usage exceeded alert" size="small" />
              ) : (
                ''
              )}
            </>
          )}

          {!_isNil(tooltip) && (
            <TooltipReact id={tooltip}>
              <Typography>{tooltip}</Typography>
            </TooltipReact>
          )}
        </Inline>

        {expanderPosition === 'right' && (
          <Inline justify="end">
            <IconButton type="button">
              {isCollapsed ? (
                <BiChevronDown size={24} />
              ) : (
                <BiChevronUp size={24} />
              )}
            </IconButton>
          </Inline>
        )}
      </Inline>

      <ContainerStyled childrenWidth={childrenWidth} $isCollapsed={isCollapsed}>
        {children}
      </ContainerStyled>
    </Stack>
  );
}
