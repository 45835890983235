import { PadBox } from '@bedrock-layout/padbox';
import styled from 'styled-components';

export const TableColumnStyled = styled(PadBox)<{ width?: string }>`
  width: ${({ width = '25%' }) => width};
  border-inline-end: 1px solid var(--color-whiteSmoke);
  min-block-size: 3rem;

  &:last-child {
    border-inline-end: none;
  }
`;

export const RulesLauncherStyled = styled(PadBox)`
  background: var(--color-whiteSmoke);
  border-radius: 4px;
  cursor: pointer;

  .name {
    word-break: break-word;
  }
`;

export const DotsStyled = styled.div`
  color: var(--color-lightGray);
`;
