import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Spinner } from 'ui';

import { Container } from './OAuth2Redirect.styled';
import { useOAuthVerifyCredential } from './hook/useOAuthRedirectVerify';

export function OAuth2Redirect() {
  const { type } = useParams();

  const { verifyOAuthCredential, isLoading } = useOAuthVerifyCredential();

  const urlParams = new URLSearchParams(window.location.search);
  const state = urlParams.get('state');
  const code = urlParams.get('code');
  const scope = urlParams.get('scope');
  const error = urlParams.get('error');

  useEffect(() => {
    if ((_isNil(error) || _isEmpty(error)) && type === 'oauth') {
      void handleOAuthVerification();
    } else {
      window.opener?.postMessage(
        {
          data: { error },
          source: 'nectedRedirectWindow',
        },
        '*'
      );
      window.close();
    }
  }, []);

  const handleOAuthVerification = async () => {
    verifyOAuthCredential({
      state: state ?? '',
      code: code ?? '',
      scope: scope?.split(' ').join(',') ?? '',
    });
  };

  return (
    <>
      {isLoading && (
        <Container align="center" justify="center">
          <Spinner size="small" />
        </Container>
      )}
    </>
  );
}
