import { motion } from 'framer-motion';
import styled from 'styled-components';

export const CossButtonContainer = styled(motion.div)`
  position: relative;
  margin-top: 0.8rem;
`;

export const CrossButtonStyled = styled.button`
  background-color: var(--color-white);
  border-radius: 4px;
  border: none;
  cursor: pointer;
  line-height: 1;
  padding: 1.3rem;

  &.btn-disabled-interceptor {
    background-color: var(--color-white) !important;
    color: var(--color-black) !important;
    cursor: progress !important;
  }
`;
