import { Inline } from '@bedrock-layout/primitives';
import styled from 'styled-components';

export const RuleMenuLauncher = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  block-size: 3rem;
  inline-size: 3rem;
  cursor: pointer;
`;

export const RuleMenuItem = styled(Inline)`
  min-inline-size: 20rem;
`;

export const MenuItemBlock = styled.div<{ $width: string }>`
  display: flex;
  justify-content: center;
  flex-direction: column;
  min-block-size: 3rem;

  ${({ $width = '20%' }) => `flex-basis: ${$width};`}
`;
