import { useState } from 'react';
import type { UseControllerProps } from 'react-hook-form';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { TextField, Typography } from 'ui';

import type { PluginFieldCommonProps, TextFieldType } from '../../../../types';
import { PluginFieldContainer } from '../../../common/PluginFieldContainer';

type PluginTextFieldProps = UseControllerProps<any> & PluginFieldCommonProps;

export function PluginTextField({
  errorMessage = 'Please enter a valid input',
  control,
  label,
  value,
  name,
  type = 'text',
  required = false,
  regex,
  placeholder,
  disabled = false,
  tooltipText,
  helpText = '',
}: PluginTextFieldProps) {
  const [togglePassword, setTogglePassword] = useState(type === 'password');

  const isTypePassword = type === 'password';

  return (
    <PluginFieldContainer
      label={label}
      required={required}
      tooltipText={tooltipText}
    >
      <TextField
        defaultValue={value}
        name={name}
        showErrorIcon={false}
        control={control}
        placeholder={placeholder}
        disabled={disabled}
        type={
          isTypePassword
            ? togglePassword
              ? 'password'
              : 'text'
            : (type as TextFieldType)
        }
        rules={{
          required: false,
          pattern: {
            value: regex,
            message: errorMessage,
          },
        }}
        icon={
          isTypePassword ? (
            togglePassword ? (
              <AiOutlineEyeInvisible size={20} />
            ) : (
              <AiOutlineEye size={20} />
            )
          ) : null
        }
        onClickInputIcon={
          isTypePassword
            ? () => setTogglePassword((isShow) => !isShow)
            : undefined
        }
      />
      <Typography name="secondary2">{helpText}</Typography>
    </PluginFieldContainer>
  );
}
