import dateFormat from 'dateformat';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';

import { VALID_JSON_REGEX } from '../../utils/regex';
import type { MapToObjToArrInput, MapToObjToArrResult } from './types';

export const mapToObjectArray = (
  inputMap: MapToObjToArrInput
): MapToObjToArrResult[] => {
  const resultArray: MapToObjToArrResult[] = [];

  for (const key in inputMap) {
    if (!_isNil(Object.getOwnPropertyDescriptor(inputMap, key))) {
      const obj = inputMap[key];
      const newObj: MapToObjToArrResult = { ...obj, keyName: key };
      resultArray.push(newObj);
    }
  }

  // Sort the resultArray by the "order" property
  resultArray.sort((a, b) => a.order - b.order);

  return resultArray;
};

export const formatDateTime = (date: string, type: string) => {
  if (type === 'time') {
    return !_isNil(date) ? dateFormat(new Date(date), 'shortTime') : '-';
  } else if (type === 'date') {
    return !_isNil(date) ? dateFormat(new Date(date), 'ddd, mmm d yyyy') : '-';
  } else {
    return '';
  }
};

// Used to get List of all variables present in the expression (data inside {} is variable)
export const getAllVariablesFromExpression = (expression: string) => {
  const variableList: string[] = [];
  let appendString = false;
  let stringId = '';
  for (const char of expression) {
    if (char === '{') {
      appendString = true;
    } else if (char === '}') {
      appendString = false;
      variableList.push(stringId);
      stringId = '';
    } else if (appendString) {
      stringId += char;
    }
  }

  return variableList;
};

// Used to remove curly braces from expression
export const removeCurlyBracesFromExpression = (expression: string) => {
  let updatedExpression = '';
  for (const char of expression) {
    if (char !== '{' && char !== '}') {
      updatedExpression += char;
    }
  }

  return updatedExpression;
};

export const evaluateExpression = (expression: string) => {
  // eslint-disable-next-line @typescript-eslint/no-implied-eval, no-new-func
  return new Function('return ' + expression)();
};

export const getCustomValueFromExpression = (
  expression: string,
  data: Record<string, any>
) => {
  if (expression === '') {
    return '';
  }

  const variableList = getAllVariablesFromExpression(expression);
  let updatedExpression = removeCurlyBracesFromExpression(expression);

  variableList.forEach((variable: string) => {
    updatedExpression = updatedExpression.replace(
      variable,
      getValueFromObject(data, variable)
    );
  });

  return evaluateExpression(updatedExpression);
};

export const getSortValueForGraphQL = (
  value: any,
  sortObject: Record<string, any>
) => {
  Object.keys(value).forEach((sortKey) => {
    sortObject[sortKey] = parseInt(value[sortKey]);
  });

  return sortObject;
};

export const getQueryFilterValueForGraphQL = (
  value: any,
  key: string,
  queryFilter: Record<string, any>,
  filterObject: Record<string, any>
) => {
  const { operator, filterKey, parseValueRequired } = filterObject[key];

  switch (operator) {
    case 'in':
      // eslint-disable-next-line no-case-declarations
      let filterValue: any;

      if ((parseValueRequired as boolean) && typeof value === 'object') {
        filterValue = {};

        Object.keys(value).forEach((key) => {
          // Making a valid JSON
          const validJsonStr = key.replace(VALID_JSON_REGEX, '"$2":');

          // Parse the valid JSON string into an object
          const obj = JSON.parse(validJsonStr);

          Object.keys(obj).forEach((currKey) => {
            if (currKey in filterValue) {
              filterValue[currKey] = [...filterValue[currKey], obj[currKey]];
            } else {
              filterValue[currKey] = [obj[currKey]];
            }
          });
        });

        queryFilter.in = {
          ...queryFilter.in,
          ...filterValue,
        };
      } else {
        filterValue =
          typeof value === 'object'
            ? Object.keys(value)
            : [`/${value as string}/`];

        if (!_isNil(value) && !_isEmpty(value)) {
          queryFilter.in = {
            ...queryFilter.in,
            [filterKey ?? key]: filterValue,
          };
        }
      }

      break;
    case 'gte':
      if (!_isNil(value) && !_isEmpty(value)) {
        queryFilter.gte = {
          ...queryFilter.gte,
          [filterKey ?? key]: value,
        };
      }
      break;
    case 'lte':
      if (!_isNil(value) && !_isEmpty(value)) {
        queryFilter.lte = {
          ...queryFilter.lte,
          [filterKey ?? key]: value,
        };
      }
      break;
    case 'regex':
      if (!_isNil(value) && !_isEmpty(value)) {
        queryFilter.regex = {
          ...queryFilter.regex,
          [filterKey ?? key]: value,
        };
      }
      break;
  }

  return queryFilter;
};

// Here key is n level dot separated, e.g. name.firstName
export const getValueFromObject = (
  data: Record<string, any>,
  key: string
): any => {
  if (key === '' || _isNil(data) || _isEmpty(data)) {
    return '';
  }

  const keyList = key.split('.');
  let value = data;
  for (const k of keyList) {
    if (!_isNil(value) && k in value) {
      value = value[k];
    } else {
      return ''; // Key not found
    }
  }

  return value;
};
