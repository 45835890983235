import { PadBox } from '@bedrock-layout/padbox';
import styled from 'styled-components';

export const DefaultPathStyled = styled(PadBox)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-block-size: 2rem;
  border: 1px solid var(--color-solitude);
  border-radius: 4px;
`;
