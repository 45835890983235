import { Inline, PadBox } from '@bedrock-layout/primitives';
import styled from 'styled-components';

import { StackAsItem } from '../../../../../components/layouts/Stack.styled';

type ActionCountStyledProps = {
  name: string;
};

export const ExecutionTestStyled = styled(StackAsItem)`
  border: 1px solid var(--color-whiteSmoke);
  border-radius: 4px;
  overflow-y: auto;
`;

export const ActionCount = styled(Inline)<ActionCountStyledProps>`
  inline-size: 2rem;
  block-size: 2rem;
  border-radius: 50%;
  background-color: ${({ name }) =>
    name === 'Actions' ? 'var(--color-primary2)' : 'var(--color-primary7)'};
  color: var(--color-primary5);
`;

export const IconContainer = styled(Inline)`
  block-size: 2rem;
`;

export const ActionTabContainer = styled(PadBox)`
  border-block-start: 1px solid var(--color-whiteSmoke);
`;
