export const ruleInitialState = {
  rule_01: {
    operator: '',
    parent: '',
    nodeType: 'group',
    children: ['rule_02'],
    siblingIndex: 1,
  },
  rule_02: {
    operator: '',
    parent: 'rule_01',
    nodeType: 'condition',
    siblingIndex: 1,
    leftNode: ['rule_03'],
    rightNode: ['rule_04'],
  },
  rule_03: {
    nodeType: 'params',
    parent: 'rule_02',
    siblingIndex: 1,
    sourceType: '',
    attribute: '',
  },
  rule_04: {
    siblingIndex: 1,
    nodeType: 'constant',
    value: '',
    parent: 'rule_02',
    dataType: '',
  },
};
