import { useState } from 'react';

import { useAxiosPrivate } from '../../../../hooks';
import { slugify } from '../../../../utils/common';

type handleExportEntityArgs = {
  entityType: string;
  entityId: string;
  preview?: boolean;
  name?: string;
  isLive?: boolean;
  version?: string;
};

export function useExportEntity() {
  const { axiosPrivate } = useAxiosPrivate();
  const [isLoading, setIsLoading] = useState(false);

  const handleExportEntity = async ({
    entityType,
    entityId,
    preview = false,
    name = '',
    isLive = false,
    version,
  }: handleExportEntityArgs) => {
    try {
      const response = await axiosPrivate.post(`/common/${entityType}/export`, {
        entityId,
        preview,
        isLive,
        version,
      });

      if (preview) {
        return response;
      }

      const jsonString = JSON.stringify(response.data, null, 2);
      // Create a blob with the JSON string
      const blob = new Blob([jsonString], { type: 'application/json' });

      // Create a URL for the blob
      const url = window.URL.createObjectURL(blob);
      // Create a link element
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        slugify(`${name ?? entityType}-${new Date().toDateString()}-export`)
      ); // Filename and extension

      // Append the link to the body
      document.body.appendChild(link);
      // Programmatically click the link to trigger the download
      link.click();
      // Remove the link from the document
      document.body.removeChild(link);
    } catch (err) {}
  };

  return {
    isLoading,
    setIsLoading,
    handleExportEntity,
  };
}
