import _isEmpty from 'lodash/isEmpty';
import { Dataset } from 'ui';

import { isValidJson } from '../../../utils/common';
import { REMOVE_JAVASCRIPT_COMMENTS_REGEX } from '../../../utils/regex';
import { sanitizedStringV2 } from '../../Workflow/utils/common';
import type { AdditionalActionFormData } from '../components/DecisionTable/models';
import type { ResultAddDataModel } from '../components/SimpleRule/models';
import { replaceTokenWithCorrespondingData } from './common';

export const validateJsonSyntaxBeforeSaving = (
  resultData: ResultAddDataModel[],
  dataSet: Record<string, Dataset>
) => {
  const syntaxErrorsIndexes: number[] = [];

  resultData.forEach((result, index) => {
    const resultValue = result.value;

    if (result.dataType === 'json' && typeof resultValue === 'string') {
      const stringWithReplacedToken = replaceTokenWithCorrespondingData(
        resultValue,
        dataSet,
        resultData
      );

      if (!isValidJson(stringWithReplacedToken)) {
        syntaxErrorsIndexes.push(index);
      }
    }
  });

  return syntaxErrorsIndexes;
};

export const validateJsSyntaxBeforeSaving = (
  resultData: ResultAddDataModel[],
  dataSet: Record<string, Dataset>
) => {
  const syntaxErrorsIndexes: number[] = [];

  resultData.forEach((result, index) => {
    const resultValue = result.value;

    if (
      result.dataType === 'jsFormula' &&
      typeof resultValue === 'string' &&
      !isCorrectJsSyntax(resultValue, dataSet, resultData)
    ) {
      syntaxErrorsIndexes.push(index);
    }
  });

  return syntaxErrorsIndexes;
};

export const isCorrectJsSyntax = (
  result: string,
  dataSet: Record<string, Dataset>,
  resultData?: ResultAddDataModel[],
  additionalData?: AdditionalActionFormData[]
) => {
  if (!_isEmpty(result)) {
    const newStr = sanitizedStringV2(result, dataSet);

    const outputString = newStr.replaceAll(
      REMOVE_JAVASCRIPT_COMMENTS_REGEX,
      ''
    );

    try {
      // eslint-disable-next-line -- We're disabling the next line for checking validity of the code
      new Function(outputString);

      return true;
    } catch {
      return false;
    }
  }

  return true;
};
