import { ReactNode } from 'react';
import { BiSolidError } from 'react-icons/bi';

import { TooltipReact } from '../TooltipReact';
import { Typography } from '../Typography';
import {
  ErrorIcon,
  TabLayoutSize,
  TabLayoutType,
  TabStyled,
  TabStyledContainer,
} from './Tab.styled';

export type TabProps = {
  children: ReactNode;
  active?: boolean;
  type?: TabLayoutType;
  onClick?: () => void;
  disabled?: boolean;
  size?: TabLayoutSize;
  showErrorIcon?: boolean;
  errorTooltipText?: string;
  tooltipId?: string;
};

export function Tab({
  children,
  active = false,
  type = 'horizontal',
  onClick,
  disabled = false,
  size = 'medium',
  showErrorIcon = false,
  errorTooltipText = '',
  tooltipId = '',
}: TabProps) {
  return (
    <TabStyledContainer
      type={type}
      $active={active}
      onClick={() => {
        if (typeof onClick === 'function' && !disabled) onClick();
      }}
    >
      <TabStyled $active={active} type={type} size={size}>
        <Typography name="paragraph" active={active}>
          {children}
        </Typography>
        {showErrorIcon && (
          <ErrorIcon>
            <TooltipReact
              id={tooltipId}
              launcher={
                <BiSolidError size={18} color="var(--color-fireEngineRed)" />
              }
            >
              <Typography>{errorTooltipText}</Typography>
            </TooltipReact>
          </ErrorIcon>
        )}
      </TabStyled>
    </TabStyledContainer>
  );
}
