import type { Control } from 'react-hook-form';
import { TextField } from 'ui';

import type { AddConfigValueType } from '../../../models';
import type { RemoteConfigFormValues } from '../../RemoteConfig';

type RemoteConfigTextFieldProps = {
  control: Control<RemoteConfigFormValues>;
  name: string;
  value: AddConfigValueType;
  disabled: boolean;
};

export function RemoteConfigTextField({
  value,
  name,
  control,
  disabled,
}: RemoteConfigTextFieldProps) {
  return (
    <TextField
      defaultValue={value}
      name={name}
      rules={{
        required: 'Field is required',
      }}
      showErrorIcon={false}
      control={control}
      disabled={disabled}
    />
  );
}
