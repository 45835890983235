import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { useAtom } from 'jotai';
import { useMemo } from 'react';
import type { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { MdDelete } from 'react-icons/md';
import { Dataset, IconButton } from 'ui';

import { siteConstantsAtom } from '../../../../../../atom';
import { CalenderIconPicker } from '../../../../../../components/CalenderIconPicker/CalenderIconPicker';
import { HowToLink } from '../../../../../../components/HowToLink/HowToLink';
import {
  formatNectedDate,
  generateUid,
  getTooltipText,
  isArrayNotPresent,
} from '../../../../../../utils/common';
import { ResultHeader } from '../../../../../Rules/components/DecisionTable/components/ResultHeader';
import { ResultRhs } from '../../../../../Rules/components/RestltRhs/ResultRhs';
import { RoundIcon } from '../../../../../Rules/components/SimpleRule/RulePopovers/RhsParamPopover.styled';
import { getTypesToAllowForConditionNodes } from '../../../../../Rules/utils/common';
import { isWorkflowReadOnlyAtom } from '../../../../atoms/atoms';
import { ExcelCondition } from './ExcelCondition';
import { JsCondition } from './JsCondition';
import { OperatorSelection } from './OperatorSelection';
import { PathConditionStyled } from './PathBlock.styled';
import { PropertySelection } from './PropertySelection';

type PathConditionProps = {
  groupIndex: number;
  conditionId: string;
  control?: Control<any>;
  setValue: UseFormSetValue<any>;
  name: string;
  remove: (index: number) => void;
  watch: UseFormWatch<any>;
  index: number;
  updatedDataSet: Record<string, Dataset>;
  dataSetLHS?: Record<string, Dataset>;
  pathKey: string; // path group's id
  groupKey: string; // parent group's id
  size: number;
};

export function PathCondition({
  groupIndex,
  index,
  name,
  control,
  remove,
  watch,
  setValue,
  updatedDataSet,
  conditionId,
  groupKey,
  size,
  pathKey,
  dataSetLHS = {},
}: PathConditionProps) {
  const condition = watch(`paths.${groupIndex}.${conditionId}`);
  const currentPath = watch(`paths.${groupIndex}`);

  const [siteConstants] = useAtom(siteConstantsAtom);
  const [isWorkflowReadOnly] = useAtom(isWorkflowReadOnlyAtom);

  const handleRemoveCondition = () => {
    const path = watch(`paths.${groupIndex}`);

    path[groupKey].children = path[groupKey].children.filter(
      (c: string) => c !== conditionId
    );

    path[conditionId] = undefined;

    condition?.leftNode?.forEach((id: string) => {
      path[id] = undefined;
    });

    condition?.rightNode?.forEach((id: string) => {
      path[id] = undefined;
    });

    setValue(`paths.${groupIndex}`, path);
  };

  const finalDataset: Record<string, Dataset> = useMemo(() => {
    return {
      ...updatedDataSet,
      custom: {
        name: 'Custom Functions',
        id: generateUid('param_'),
        order: 4,
        tooltip: (
          <span>
            {getTooltipText(siteConstants, 'rules', 'customFunctionRule')}{' '}
            <HowToLink
              variant="link"
              link={getTooltipText(
                siteConstants,
                'rules',
                'condCustomFunction',
                'howToLinks'
              )}
            />
          </span>
        ),
        attributes: {
          sql: {
            name: 'JS Code',
            dataType: 'jsCondition',
          },
          excelFormula: {
            name: 'Formula',
            dataType: 'excelCondition',
          },
        },
      },
    };
  }, [JSON.stringify(updatedDataSet)]);

  const dataType = watch(`paths.${groupIndex}.${conditionId}.dataType`);
  const operator = watch(`paths.${groupIndex}.${conditionId}.operator`);

  const typesToAllow = useMemo(
    () => getTypesToAllowForConditionNodes(dataType, operator),
    [dataType, operator]
  );

  if (condition.nodeType === 'jsCondition') {
    return (
      <Inline gutter="1rem" align="center">
        <JsCondition
          conditionId={conditionId}
          groupIndex={groupIndex}
          groupKey={groupKey}
          index={index}
          name={name}
          remove={remove}
          setValue={setValue}
          updatedDataSet={updatedDataSet}
          watch={watch}
          control={control}
        />

        {size > 1 && (
          <IconButton
            disabled={isWorkflowReadOnly}
            onClick={handleRemoveCondition}
          >
            <MdDelete />
          </IconButton>
        )}
      </Inline>
    );
  }

  if (condition.nodeType === 'excelCondition') {
    return (
      <Inline gutter="1rem" align="center">
        <ExcelCondition
          conditionId={conditionId}
          groupIndex={groupIndex}
          groupKey={groupKey}
          index={index}
          name={name}
          remove={remove}
          setValue={setValue}
          updatedDataSet={updatedDataSet}
          watch={watch}
          control={control}
        />

        {size > 1 && (
          <IconButton
            disabled={isWorkflowReadOnly}
            onClick={handleRemoveCondition}
          >
            <MdDelete />
          </IconButton>
        )}
      </Inline>
    );
  }

  return (
    <PathConditionStyled>
      {condition?.leftNode.map((paramId: string, pIndex: number) => {
        return (
          <PropertySelection
            name={`paths.${groupIndex}.${paramId}`}
            setValue={setValue}
            control={control}
            conditionName={`paths.${groupIndex}.${conditionId}`}
            key={`paths.${groupIndex}.${paramId}_${pIndex}`}
            dropdownDataSet={finalDataset}
            updatedDataSet={updatedDataSet}
            watch={watch}
            pathIndex={groupIndex}
            conditionId={conditionId}
            dataSetLHS={dataSetLHS}
          />
        );
      })}

      <OperatorSelection
        pathIndex={groupIndex}
        index={index}
        name={`paths.${groupIndex}.${conditionId}`}
        setValue={setValue}
        watch={watch}
        control={control}
        conditionId={conditionId}
      />

      <Stack gutter={8}>
        {condition?.rightNode.map((paramId: string, pIndex: number) => {
          let dataType = currentPath[paramId].dataType;
          const pathKey = `paths.${groupIndex}.${paramId}`;

          if (
            (condition?.dataType === 'string' &&
              ['containsIn', 'notContainsIn'].includes(dataType)) ||
            (!['string'].includes(condition?.dataType) &&
              ['in', 'nin'].includes(dataType))
          ) {
            dataType = 'list';
          }

          const val = watch(`paths.${groupIndex}.${paramId}.value`);

          const onChangeSpecial = (val: any) => {
            let newVal = val;

            try {
              newVal = JSON.parse(val);
            } catch {}

            setValue(`${pathKey}.value`, newVal);

            setValue(`${pathKey}.key`, '');
            setValue(`${pathKey}.dataType`, 'list');
            setValue(`${pathKey}.attribute`, null);
            setValue(`${pathKey}.sourceType`, null);
          };

          return (
            <Inline key={`paths.${groupIndex}.${paramId}`} align="center">
              <ResultRhs
                dataType={condition?.dataType}
                nodeName={`paths.${groupIndex}.${paramId}`}
                allowList
                control={control}
                setValue={setValue}
                isSmall
                updatedDataSet={updatedDataSet}
                sourceKey="sourceType"
                typesToAllow={typesToAllow}
                nodeTypeName={`paths.${groupIndex}.${paramId}.nodeType`}
                otherKeys={{
                  [`paths.${groupIndex}.${paramId}.parent`]: conditionId,
                }}
                disabled={isWorkflowReadOnly}
                header={
                  typesToAllow.includes('list') ? (
                    <ResultHeader
                      dataSet={updatedDataSet}
                      // eslint-disable-next-line
                      isAdd={isArrayNotPresent(val)}
                      nodeName={`paths.${groupIndex}.${paramId}`}
                      resIndex={0}
                      control={control}
                      onChangeSpecial={onChangeSpecial}
                      disabled={isWorkflowReadOnly}
                      dataType={'list'}
                      overrideValue={val}
                    />
                  ) : undefined
                }
              />

              {['dateTime', 'date'].includes(dataType) ? (
                <RoundIcon type="button">
                  <CalenderIconPicker
                    value={val}
                    dataType={dataType}
                    disabled={isWorkflowReadOnly}
                    onPick={(val) => {
                      if (typeof setValue === 'function') {
                        setValue(
                          `${pathKey}.value`,
                          formatNectedDate(val, dataType)
                        );
                        setValue(`${pathKey}.attribute`, null);
                        setValue(`${pathKey}.sourceType`, null);
                      }
                    }}
                  />
                </RoundIcon>
              ) : null}
            </Inline>
          );
        })}
      </Stack>

      {size > 1 && (
        <IconButton
          disabled={isWorkflowReadOnly}
          onClick={handleRemoveCondition}
        >
          <MdDelete />
        </IconButton>
      )}
    </PathConditionStyled>
  );
}
