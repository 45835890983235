import { gql, useMutation } from '@apollo/client';

const createRS = gql`
  mutation CreateRule(
    $name: String!
    $type: String!
    $description: String
    $ruleSetPolicy: String
    $settings: RuleSettingsInput
  ) {
    createRule(
      input: {
        name: $name
        type: $type
        description: $description
        ruleSetPolicy: $ruleSetPolicy
        settings: $settings
      }
    ) {
      id
    }
  }
`;

type RuleSetVariablesModel = {};

export function useCreateRS() {
  return useMutation<any, RuleSetVariablesModel>(createRS);
}
