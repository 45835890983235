import type { CheckSumEntityNames } from '../../types';

export const KEY = `key`;

type ChecksumMappingType = Record<
  string,
  {
    sendChecksum: boolean;
    module: CheckSumEntityNames;
  }
>;

// In case of sendChecksum true -> need to send checksum (from session storage) to API and then update it in session storage.
// In case of sendChecksum false -> need to update checksum in session storage only.

export const CHECKSUM_MAPPING: ChecksumMappingType = {
  createCredential: {
    module: 'credentials',
    sendChecksum: false,
  },
  updateCredential: {
    module: 'credentials',
    sendChecksum: true,
  },
  getCredentials: {
    module: 'credentials',
    sendChecksum: false,
  },
};
