import { Cover, Inline, Stack } from '@bedrock-layout/primitives';
import styled from 'styled-components';

type CoverStyledProps = {
  isPasswordPage?: boolean;
};

export const FixedWidthContainer = styled.div`
  width: 100%;
  max-width: 60rem;
  min-width: 60rem;
  border-radius: 0.8rem;
  box-shadow: -2px 4px 6.4px 0px rgba(111, 111, 111, 0.15);
  padding: 2.5rem 5rem;
  background-color: var(--color-white);

  @media (max-width: 1000px) {
    max-width: 100vw;
    min-width: 100vw;
    border-radius: 0px;
    box-shadow: none;
    padding: 2.5rem;
  }
`;
export const SignupFormContainer = styled.form`
  margin-top: 3rem;
`;

export const CoverStyled = styled(Cover)<CoverStyledProps>`
  block-size: calc(100% - 2rem);

  ${({ isPasswordPage = false }) =>
    !isPasswordPage &&
    `@media (max-width: 1000px) {
        min-block-size: 0px;
      }`}
`;

export const PageContentContainer = styled(Stack)`
  @media (max-width: 375px) {
    gap: 1rem;
  }
`;

export const SocialContainer = styled(Inline)`
  @media (max-width: 1000px) {
    flex-direction: column;
    gap: 1rem;
  }
`;
