import { ApolloError } from '@apollo/client';
import { Inline } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { IoIosInformationCircle } from 'react-icons/io';
import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  Spinner,
  Typography,
  toasts,
  useCurrentLayer,
} from 'ui';

import { checksumWarningAtom } from '../../../atom';
import { CheckSumEntityNames } from '../../../types';
import { handleGetCheckSumByEntityName } from '../../../utils/common';
import { envMap } from '../../../utils/constant';
import { useRollbackEntity } from '../hooks/graphql/useRollbackEntity';
import type { ModalCommonProps } from '../type';
import {
  getChecksumMetaData,
  getEntityTypeForApi,
  getEntityTypeForChecksum,
} from '../utils/common';
import { entityActions } from '../utils/constant';
import {
  ContainerStyled,
  WarningTextContainer,
} from './RollbackEntityModal.styled';

type RollbackEntityModalProps = ModalCommonProps & {
  commitId?: string;
};

export function RollbackEntityModal({
  entityId,
  entityType,
  entityName = '',
  entitySubType = '',
  commitId,
  handleEntityUpdate,
}: RollbackEntityModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const { close } = useCurrentLayer();

  const [, setShowChecksumPopup] = useAtom(checksumWarningAtom);

  const [rollbackEntityMutation, { data, error }] = useRollbackEntity();

  useEffect(() => {
    if (
      !_isNil(data) &&
      !_isNil(data.rollbackEntity) &&
      typeof handleEntityUpdate === 'function'
    ) {
      toasts.success(data.rollbackEntity.message ?? '', 'entity rollbacked');

      handleEntityUpdate({
        fetchEntity: true,
      });
    }
  }, [data]);

  useEffect(() => {
    if (!_isNil(error) && error instanceof ApolloError) {
      if (error?.graphQLErrors[0]?.extensions?.code === 'checksum_mismatched') {
        setShowChecksumPopup({
          showPopup: true,
          metaData: getChecksumMetaData({
            entityId,
            entityName,
            entityType,
            entitySubType,
          }),
        });
      } else {
        toasts.error(error.message, 'error');
      }
    }
  }, [error]);

  const handleRollbackEntity = async () => {
    try {
      setIsLoading(true);
      const checksumEntityName = getEntityTypeForChecksum(entityType);

      await rollbackEntityMutation({
        variables: {
          id: entityId,
          entityType: getEntityTypeForApi(entityType),
          commitId,
          action: entityActions.rollback,
          checksum:
            handleGetCheckSumByEntityName(
              checksumEntityName as CheckSumEntityNames
            ) ?? '',
        },
        fetchPolicy: 'no-cache',
      });
    } catch (error) {
    } finally {
      setIsLoading(false);
      close();
    }
  };

  const updatedEntityName =
    entityType[0].toUpperCase() +
    entityType.substring(1, entityType.length - 1);

  return (
    <Modal
      size="large"
      title={
        <Typography name="heading2">
          <Inline
            justify="center"
            align="center"
            stretch="start"
            gutter={'1rem'}
          >
            <img
              style={{ maxWidth: '4rem' }}
              src={`${envMap.VITE_ASSETS_URL}website/icons/warning_icon.svg`}
              alt="warning image"
            />
            {`Rollback ${updatedEntityName}`}
          </Inline>
        </Typography>
      }
      hideCloseButton
    >
      <ModalContent>
        <ContainerStyled gutter="1rem">
          <Typography name="heading3" fontWeight={700}>
            Your Rule will be reverted back to the selected version. This will
            open the rule in draft. Please re-test and re-publish to make the
            changes live
          </Typography>
          <WarningTextContainer>
            <IoIosInformationCircle
              size={24}
              color="var(--color-progressRed)"
            />
            <Typography name="heading5">
              Your current draft changes will be overridden , please clone the
              rule first if you want to backup the changes
            </Typography>
          </WarningTextContainer>
        </ContainerStyled>
      </ModalContent>

      <ModalFooter justify="center">
        <Button appearance="neutral" onClick={close}>
          Cancel
        </Button>
        <Button appearance="danger" onClick={handleRollbackEntity}>
          {isLoading ? <Spinner size="extraSmall" /> : <>Proceed Further</>}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
