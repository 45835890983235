import { useMutation } from '@apollo/client';

import { emptyMutationQuery } from '../../../../utils/constant';
import { saveDecisionTable } from './useSaveDecisionTable';
import { saveRuleset } from './useSaveRuleset';
import { saveSimpleRule } from './useSaveSimpleRule';

const createRuleQueryBasedOnType = (type?: string) => {
  switch (type) {
    case 'simpleRule':
      return saveSimpleRule;
    case 'decisionTable':
      return saveDecisionTable;
    case 'ruleSet':
      return saveRuleset;
    default:
      return emptyMutationQuery;
  }
};

export function useCreateRuleByType(type?: string) {
  return useMutation(createRuleQueryBasedOnType(type));
}
