import { Inline, PadBox } from '@bedrock-layout/primitives';
import styled from 'styled-components';

export const DateTimeWrapper = styled(Inline)`
  max-inline-size: 25rem;
  position: relative;
`;

export const UTCContainer = styled.div`
  position: absolute;
  right: 4rem;
  top: 50%;
  transform: translateY(-50%);
`;

export const JsonFieldContainer = styled.div`
  block-size: 65rem;
  border: 1px solid var(--color-solitude);
  border-radius: 4px;
  margin: 1rem;
`;

export const JsonPillStyled = styled(PadBox)<{ $hasError: boolean }>`
  min-inline-size: 4rem;
  border-radius: 0.8rem;
  cursor: pointer;
  background: var(--color-lavender);
  outline: 1px solid
    ${({ $hasError }) =>
      $hasError ? 'var(--color-fireEngineRed)' : 'var(--color-lavender)'};
`;
