import { components, MultiValueRemoveProps } from 'react-select';
import { RxCross2 } from 'react-icons/rx';

export function MultiValueClearIndicator(props: MultiValueRemoveProps) {
  return (
    <components.MultiValueRemove {...props}>
      <RxCross2 fontSize={16} />
    </components.MultiValueRemove>
  );
}
