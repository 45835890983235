import { gql, useQuery } from '@apollo/client';

import { GetRemoteConfigResult } from '../models';

const getRemoteConfig = gql`
  query getVariables {
    savedConfig: getVariables(page: 1, perPage: 50) {
      data {
        id
        name
        value
        dataType
        checksum
      }
    }
  }
`;

export function useGetRemoteConfig() {
  return useQuery<GetRemoteConfigResult>(getRemoteConfig, {
    fetchPolicy: 'no-cache',
  });
}
