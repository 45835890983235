import { Id as ToastId, toast } from 'react-toastify';

import { ToastType } from './Toast';

// function createToast(message: string, type: ToastType) {
//   return toast(({ closeToast }) => {
//     return <Toast message={message} type={type} onDismiss={closeToast} />;
//   });
// }

export const toasts: Record<
  ToastType,
  (message: string, toastId: string) => ToastId
> = {
  info: (message: string, toastId: string) =>
    toast.info(message, {
      toastId,
    }),
  infoBlue: (message: string, toastId: string) =>
    toast.info(message, {
      toastId,
    }),
  success: (message: string, toastId: string) =>
    toast.success(message, {
      toastId,
    }),
  error: (message: string, toastId: string) =>
    toast.error(message, {
      toastId,
    }),
  warning: (message: string, toastId: string) =>
    toast.warning(message, {
      toastId,
    }),
  warningBlue: (message: string, toastId: string) =>
    toast.info(message, {
      toastId,
    }),
};
