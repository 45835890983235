import { Inline, PadBox, Stack } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import { useFieldArray, useWatch } from 'react-hook-form';
import type { UseControllerProps, UseFormSetValue } from 'react-hook-form';
import { FiPlusCircle } from 'react-icons/fi';
import { Menu, MenuItem, Typography, useLayer } from 'ui';

import { isRuleReadOnlyAtom } from '../../..';
import { siteConstantsAtom } from '../../../../../atom';
import { AddButton } from '../../../../../components/AddButton';
import { ExcelModal } from '../../../../../components/ExcelLikeFormula/ExcelModal/ExcelModal';
import { getTooltipText } from '../../../../../utils/common';
import { useOpenJsEditorSheet } from '../../../hooks/useOpenJsEditor';
import type { sendEventToGTMType } from '../../../types';
import { dataSetParamsAtom } from '../../CreateRuleSheet/CreateRuleSheet';
import { DecisionTableResultRow } from '../types';
import { AdditionalData } from './AdditionalData';
import {
  AddDataContainer,
  AdditionalDataContainer,
} from './AdditionalOutputData.styled';
import { RowActionsContainer } from './RowsFieldArray.styled';

type AdditionalOutputDataProps = Omit<UseControllerProps, 'name'> & {
  handleSendEventToGTM: (obj: sendEventToGTMType) => void;
  setValue: UseFormSetValue<any>;
};

export function AdditionalOutputData({
  control,
  handleSendEventToGTM,
  setValue,
}: AdditionalOutputDataProps) {
  const { fields, append, remove } = useFieldArray({
    name: 'additionalData',
    control,
  });

  const [isRuleReadOnly] = useAtom(isRuleReadOnlyAtom);
  const [siteConstants] = useAtom(siteConstantsAtom);
  const [dataset] = useAtom(dataSetParamsAtom);
  const { openWithProps: openJsEditor } = useOpenJsEditorSheet({});
  const { openWithProps: openExcelEditor } = useLayer(<ExcelModal name="" />);

  const decisionTableResults: DecisionTableResultRow[] = useWatch({
    control,
    name: 'results',
  });

  const handleAddData = (type: string = 'js-code') => {
    if (isRuleReadOnly) {
      return;
    }

    handleSendEventToGTM({
      action: 'add',
      element: 'additional_data',
      actionName: '',
    });

    const name = 'untitled';
    const index = fields.length;

    append({
      name,
      value: getTooltipText(
        siteConstants,
        'rules',
        'formulaInAdditionalData',
        'otherText'
      ),
      dataType: type === 'js-code' ? 'jsFormula' : 'excelFormula',
      returnType: '',
      executedValue: '',
    });

    if (type === 'js-code') {
      openJsEditor({
        name: `additionalData.${index}.value`,
        control,
        section: 'thenDataParams',
        isAdditionalData: true,
        setOriginalValue: setValue,
        returnTypeName: `additionalData.${index}.returnType`,
        executedValueName: `additionalData.${index}.executedValue`,
        index: (decisionTableResults ?? []).length,
        additionalDataIndex: index,
      });
    } else {
      openExcelEditor({
        name: `additionalData.${index}.value`,
        control,
        section: 'thenDataParams',
        isAdditionalData: true,
        setOriginalValue: setValue,
        returnTypeName: `additionalData.${index}.returnType`,
        executedValueName: `additionalData.${index}.executedValue`,
        index: (decisionTableResults ?? []).length,
        additionalDataIndex: index,
        dataSet: dataset,
      });
    }
  };

  return (
    <Inline gutter={0} align="end">
      <RowActionsContainer />
      <PadBox padding={[0, 8, 0, 8]}>
        <Stack gutter="0.8rem">
          {fields.length > 0 && (
            <AdditionalDataContainer gutter="2.4rem">
              {fields.map(({ id, dataType }: any, index) => {
                return (
                  <AdditionalData
                    handleDeleteData={remove}
                    control={control}
                    index={index}
                    // eslint-disable-next-line
                    key={`then_${index}_${id}`}
                    handleSendEventToGTM={handleSendEventToGTM}
                    setValue={setValue}
                    dataType={dataType}
                  />
                );
              })}
            </AdditionalDataContainer>
          )}
          <AddDataContainer
            $disabled={isRuleReadOnly}
            align="center"
            gutter={5}
          >
            <Menu
              onMenuItemClick={(val) => {
                handleAddData(val);
              }}
              launcher={
                <span
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 8,
                  }}
                >
                  {isRuleReadOnly ? (
                    <FiPlusCircle color="var(--color-darkGray)" />
                  ) : (
                    <AddButton />
                  )}
                  <Typography>Add additional data</Typography>
                </span>
              }
            >
              <MenuItem value="formula">
                <Typography>Formula</Typography>
              </MenuItem>

              <MenuItem value="js-code">
                <Typography>JS Code</Typography>
              </MenuItem>
            </Menu>
          </AddDataContainer>
        </Stack>
      </PadBox>
    </Inline>
  );
}
