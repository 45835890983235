import axiosCreator from 'axios';

import { generateUUID } from '../utils/common';
import { envMap } from '../utils/constant';

const createUUID = () => {
  try {
    return crypto.randomUUID();
  } catch (error) {
    return generateUUID();
  }
};

const api = envMap.VITE_API_HOST;

export const axios = axiosCreator.create({
  baseURL: api,
});

export const axiosPrivate = axiosCreator.create({
  baseURL: api,
  withCredentials: envMap.VITE_WITH_CREDENTIALS === 'true',
  headers: { 'Content-Type': 'application/json' },
});

type NalandaHeadersProps = {
  authorization?: string;
  'nected-ws'?: string;
  'nected-trace-id'?: string;
};

const axiosVidhanPrivateFunc = (url = envMap.VITE_API_FEATURE_FLAG) => {
  const NalandaHeaders: NalandaHeadersProps = {};
  const token = window.localStorage.getItem('accessToken');
  const sessionworkspaceUUID = window.sessionStorage.getItem('workspaceUUID');
  const localworkspaceUUID = window.localStorage.getItem('workspaceUUID');

  if (url.includes('proxy')) {
    NalandaHeaders.authorization = `Bearer ${token as string}`;
    NalandaHeaders['nected-ws'] =
      sessionworkspaceUUID ?? localworkspaceUUID ?? '';
    NalandaHeaders['nected-trace-id'] = createUUID();
  }

  return axiosCreator.create({
    baseURL: url,
    withCredentials: envMap.VITE_WITH_CREDENTIALS === 'true',
    headers: { 'Content-Type': 'application/json', ...NalandaHeaders },
  });
};

export const axiosVidhanPrivate = axiosVidhanPrivateFunc();
