import { Inline } from '@bedrock-layout/primitives';
import styled, { css } from 'styled-components';

export const AddChildStyled = styled(Inline)<{ $disabled: boolean }>`
  cursor: pointer;
  background-color: var(--color-white);
  color: var(--color-primary1);

  ${({ $disabled = false }) =>
    $disabled &&
    css`
      cursor: default;
      color: var(--color-darkGray);
    `}
`;
