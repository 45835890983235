import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  ServerError,
  createHttpLink,
  from,
  fromPromise,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import _isNil from 'lodash/isNil';
import { useNavigate } from 'react-router-dom';
import { toasts } from 'ui';

import { HttpCodes } from '../responseStatusCode/httpCodes';
import { createUUID } from '../utils/common';
import { envMap } from '../utils/constant';
import { useHandleBtnStateAtInterceptor } from './useHandleBtnStateAtInterceptor';
import { useRefreshToken } from './useRefreshToken';

const httpLink = createHttpLink({
  uri: envMap.VITE_GRAPHQL_URL,
});

export function useApolloClient() {
  const { refresh } = useRefreshToken();
  const navigate = useNavigate();

  const { disableAllButtons, enableAllButtons } =
    useHandleBtnStateAtInterceptor();

  const authMiddleware = new ApolloLink((operation, forward) => {
    const token = window.localStorage.getItem('accessToken');
    const sessionworkspaceUUID = window.sessionStorage.getItem('workspaceUUID');
    const localworkspaceUUID = window.localStorage.getItem('workspaceUUID');

    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: `Bearer ${token as string}`,
        'nected-ws': sessionworkspaceUUID ?? localworkspaceUUID ?? '',
        'nected-trace-id': createUUID(),
      },
    }));

    if ((operation.query.definitions[0] as any).operation === 'mutation') {
      disableAllButtons();
    }

    return forward(operation).map((response) => {
      if ((operation.query.definitions[0] as any).operation === 'mutation') {
        enableAllButtons();
      }

      return response;
    });
  });

  const refreshMiddleware = onError(({ networkError, operation, forward }) => {
    if (
      !_isNil(networkError) &&
      (networkError as ServerError).statusCode === HttpCodes.UNAUTHORIZED
    ) {
      return fromPromise(
        refresh().then((accessToken) => {
          return accessToken;
        })
      )
        .filter((value) => Boolean(value))
        .flatMap((accessToken) => {
          const oldHeaders = operation.getContext().headers;

          // modify the operation context with a new token
          operation.setContext({
            headers: {
              ...oldHeaders,
              authorization: `Bearer ${accessToken as string}`,
              'nected-trace-id': createUUID(),
            },
          });
          // retry the request, returning the new observable

          if (
            (operation.query.definitions[0] as any).operation === 'mutation'
          ) {
            disableAllButtons();
          }

          return forward(operation).map((response) => {
            if (
              (operation.query.definitions[0] as any).operation === 'mutation'
            ) {
              enableAllButtons();
            }

            return response;
          });
        });
    }

    if (!_isNil(networkError)) {
      if (
        (networkError as ServerError).statusCode ===
          HttpCodes.INTERNAL_SERVER_ERROR ||
        (networkError as ServerError).statusCode === HttpCodes.BAD_REQUEST
      ) {
        toasts.error(
          ((networkError as ServerError).result as Record<string, any>)
            .message ?? (networkError as ServerError).message,
          'error-400-500'
        );
      }

      if (
        (networkError as ServerError).statusCode === HttpCodes.BAD_REQUEST &&
        ((networkError as ServerError).result as Record<string, any>).code ===
          'workspace_not_accessible'
      ) {
        window.sessionStorage.setItem(
          'workspaceUUID',
          window.localStorage.getItem('workspaceUUID') ?? ''
        );
        toasts.error('You do not have access to workspace', 'error');
        navigate('/');
      }

      if (
        (networkError as ServerError).statusCode === HttpCodes.PAYLOAD_TOO_LARGE
      ) {
        toasts.error(
          ((networkError as ServerError).result as Record<string, any>).message,
          'error'
        );
      }
    }

    return forward(operation).map((response) => {
      if ((operation.query.definitions[0] as any).operation === 'mutation') {
        enableAllButtons();
      }

      return response;
    });
  });

  const client = new ApolloClient({
    link: from([authMiddleware, refreshMiddleware, httpLink]),
    cache: new InMemoryCache(),
  });

  return { client };
}
