import { ReactElement } from 'react';

import { useCheckPermissions } from './hooks/useCheckPermissions';

type PermissionWrapperProps = {
  children: ReactElement;
  allowedPermission: string[];
  entityList: string[];
};

export function PermissionWrapper({
  children,
  allowedPermission,
  entityList,
}: PermissionWrapperProps) {
  const { isHide } = useCheckPermissions({ allowedPermission, entityList });

  if (isHide) {
    return null;
  }

  return children;
}
