import { useAtom } from 'jotai';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { IconButton, Menu, MenuItem, TextButton } from 'ui';

import { isRuleReadOnlyAtom } from '../../../..';
import { DotsStyled } from './TableColumns.styled';

type MenuPopoverProps = {
  index: number;
  handleInsertRow: (index: number) => void;
  handleDeleteRow: (index: number) => void;
};

export function MenuPopover({
  index,
  handleInsertRow,
  handleDeleteRow,
}: MenuPopoverProps) {
  const [isRuleReadOnly] = useAtom(isRuleReadOnlyAtom);
  const launcher = (
    <IconButton padding>
      <DotsStyled>
        <BiDotsVerticalRounded size={22} />
      </DotsStyled>
    </IconButton>
  );

  return (
    <Menu
      onMenuItemClick={(value) => {
        switch (value) {
          case 'addToTop': {
            return handleInsertRow(index);
          }
          case 'addToBottom': {
            return handleInsertRow(index + 1);
          }
          case 'delete': {
            return handleDeleteRow(index);
          }
          default:
            return null;
        }
      }}
      disabled={isRuleReadOnly}
      launcher={launcher}
    >
      <MenuItem value="addToTop">
        <TextButton appearance="secondary" bold={false}>
          Add row above
        </TextButton>
      </MenuItem>
      <MenuItem value="addToBottom">
        <TextButton appearance="secondary" bold={false}>
          Add row below
        </TextButton>
      </MenuItem>
      <MenuItem value="delete">
        <TextButton appearance="secondary" bold={false}>
          Delete
        </TextButton>
      </MenuItem>
    </Menu>
  );
}
