import { PadBox } from '@bedrock-layout/padbox';
import styled from 'styled-components';

export const CodeInfoContainer = styled(PadBox)`
  position: absolute;
  left: 110%;
  top: 0;
  block-size: 100%;
  inline-size: 200%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const CodeActionsContainer = styled(PadBox)`
  position: absolute;
  right: 100%;
  top: 0;
  block-size: 100%;
  inline-size: 65%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  display: none;
`;

export const IconContainer = styled(PadBox)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  block-size: 8rem;
  inline-size: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CodeContainer = styled(PadBox)<{ $isError?: boolean }>`
  block-size: 8rem;
  inline-size: 8rem;
  border-radius: 4px;
  background-color: var(--color-white);
  box-shadow: 0px 0px 17px 4px rgba(0, 0, 0, 0.12);
  display: flex;
  position: relative;
  flex-direction: column;
  cursor: pointer;

  ${({ $isError = false }) =>
    $isError && 'outline: 1px solid var(--color-fireEngineRed);'}

  &:hover ${CodeActionsContainer} {
    display: flex;
  }
`;
