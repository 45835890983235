import { Inline } from '@bedrock-layout/primitives';
import dateFormat from 'dateformat';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useMemo } from 'react';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import { BiSolidChevronRightSquare } from 'react-icons/bi';
import { MdOutlineApi } from 'react-icons/md';
import { TbCalendarTime } from 'react-icons/tb';
import { Typography, useLayer } from 'ui';

import { createRuleSheetAtom } from '../../..';
import { useAxiosPrivate } from '../../../../../hooks';
import { useSetVersionCount } from '../../../../../hooks/useSetVersionCount';
import { ENTITY_ID } from '../../../../../utils/constant';
import { ruleEnvironmentAtom } from '../../CreateRuleSheet/CreateRuleSheet';
import { TriggerSheet } from '../../Triggers/TriggerSheet/TriggerSheet';
import { activePanelAtom, cronInfoAtom } from '../../atom/atom';
import { EnvButtons } from '../EnvButtons/EnvButtons';
import {
  ApiTriggerContainer,
  Container,
  CronContainer,
  TextBlueStyled,
  TextRedStyled,
} from './ApiTriggerAndCron.styled';

type ApiTriggerAndCronInfoProps = {
  control: Control<any>;
  id: string;
  setValue?: UseFormSetValue<any>;
};

export function ApiTriggerAndCronInfo({
  control,
  id,
  setValue,
}: ApiTriggerAndCronInfoProps) {
  const [, setActivePanel] = useAtom(activePanelAtom);
  const status: string = useWatch({
    name: 'status',
    control,
  });

  const [cronInfo, setCronInfo] = useAtom(cronInfoAtom);
  const [ruleEnvironment] = useAtom(ruleEnvironmentAtom);
  const [ruleType] = useAtom(createRuleSheetAtom);

  const { axiosPrivate } = useAxiosPrivate();

  const { open: openTriggerSheet } = useLayer(
    <TriggerSheet
      control={control}
      type={ruleEnvironment}
      ruleType={ruleType}
      setValue={setValue}
    />
  );

  const handleOpenSettingsPanel = () => {
    setActivePanel('settings');
    openTriggerSheet();
  };

  useEffect(() => {
    if (!_isEmpty(id)) {
      void getStatusOfCron();
    }
  }, [id, ruleEnvironment]);

  const getStatusOfCron = async () => {
    try {
      const response = await axiosPrivate.get('/rule/schedule', {
        params: {
          id,
        },
      });

      if (!_isNil(response.data?.data)) {
        setCronInfo({
          ...cronInfo,
          ...response.data.data,
        });
      }
    } catch (error) {}
  };

  const statusValue = useMemo(() => {
    if (!_isNil(cronInfo?.upcoming)) {
      return 'upcoming';
    } else if (
      cronInfo?.cronStatus === 'noCron' ||
      cronInfo?.status === 'NotFound'
    ) {
      return 'notFound';
    } else if (cronInfo?.status === 'Paused') {
      return 'paused';
    } else if (cronInfo?.status === 'Failed') {
      return 'failed';
    }

    return 'notFound';
  }, [cronInfo]);

  const CronStatus = useMemo(() => {
    switch (statusValue) {
      case 'upcoming':
        return (
          <Inline justify="center" align="center" gutter="0.5rem">
            <TbCalendarTime color={'var(--color-primary1)'} />
            <TextBlueStyled>
              Upcoming Cron:{' '}
              {dateFormat(
                new Date(cronInfo?.upcoming[0]),
                "h:MM TT 'on' dd/mm/yyyy"
              )}
            </TextBlueStyled>
          </Inline>
        );
      case 'paused':
        return (
          <Inline justify="center" align="center" gutter="0.5rem">
            <TbCalendarTime color={'var(--color-charcoal)'} />
            <Typography name="secondarySmallDark">
              Cron/schedule has been paused
            </Typography>
            <BiSolidChevronRightSquare color="var(--color-gamboge)" size={20} />
          </Inline>
        );
      case 'failed':
        return (
          <Inline justify="center" align="center" gutter="0.5rem">
            <TbCalendarTime color={'var(--color-statusTextRed)'} />
            <TextRedStyled>Cron/schedule failed</TextRedStyled>
            <BiSolidChevronRightSquare
              color="var(--color-statusTextRed)"
              size={20}
            />
          </Inline>
        );
      default:
        return (
          <Inline justify="center" align="center" gutter="0.5rem">
            <TbCalendarTime color={'var(--color-black)'} />
            <Typography>No upcoming cron/schedule</Typography>
            <BiSolidChevronRightSquare
              color="var(--color-darkGray)"
              size={20}
            />
          </Inline>
        );
    }
  }, [cronInfo, statusValue]);

  const { versionCount } = useSetVersionCount({
    id,
    entityType: ENTITY_ID.rules,
    status,
  });

  return (
    <Container status={statusValue} align="center" gutter="1rem">
      <Inline gutter={8}>
        <ApiTriggerContainer
          justify="center"
          align="center"
          gutter="0.5rem"
          onClick={handleOpenSettingsPanel}
        >
          <MdOutlineApi color={'var(--color-primary1)'} />
          <TextBlueStyled> API trigger</TextBlueStyled>
        </ApiTriggerContainer>
        <CronContainer status={statusValue} onClick={handleOpenSettingsPanel}>
          {CronStatus}
        </CronContainer>
      </Inline>

      <EnvButtons count={versionCount} entityName="rule" />
    </Container>
  );
}
