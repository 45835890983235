import styled from 'styled-components';

export const DurationContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
`;

export const DurationValueContainer = styled.div`
  max-inline-size: 12rem;
`;
