import { useEffect } from 'react';

export function useGetGoogleFont(
  fontName: string,
  weights: string = '400;500;700;900'
) {
  useEffect(() => {
    const link = document.createElement('link');

    if (typeof fontName === 'string') {
      link.href = `https://fonts.googleapis.com/css2?family=${fontName.replace(
        ' ',
        '+'
      )}:wght@${weights}&display=swap`;
      link.rel = 'stylesheet';
      // https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap

      document.head.appendChild(link);
    }

    return () => {
      document.head.removeChild(link);
    };
  }, [fontName, weights]);
}
