import { ApolloError } from '@apollo/client';
import { Inline } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  Spinner,
  Typography,
  toasts,
  useCurrentLayer,
} from 'ui';

import { checksumWarningAtom } from '../../../atom';
import { envMap } from '../../../utils/constant';
import { ContainerStyled } from '../ApprovalFlowEnableWarningModal/ApprovalFlowEnableWarningModal.styled';
import { useRollbackEntity } from '../hooks/graphql/useRollbackEntity';
import { ModalCommonProps } from '../type';
import { getChecksumMetaData, getEntityTypeForApi } from '../utils/common';
import { entityActions } from '../utils/constant';

type RestoreToDraftWarningModalProps = ModalCommonProps & {
  commitId?: string;
};

export function RestoreToDraftWarningModal({
  entityId,
  entityType,
  entityName = '',
  entitySubType = '',
  commitId,
  handleEntityUpdate,
}: RestoreToDraftWarningModalProps) {
  const [isLoading, setIsLoading] = useState(false);

  const [, setShowChecksumPopup] = useAtom(checksumWarningAtom);

  const { close } = useCurrentLayer();

  const [rollbackEntityMutation, { data, error }] = useRollbackEntity();

  useEffect(() => {
    if (
      !_isNil(data) &&
      !_isNil(data.rollbackEntity) &&
      typeof handleEntityUpdate === 'function'
    ) {
      handleEntityUpdate({
        fetchEntity: true,
      });
    }
  }, [data]);

  useEffect(() => {
    if (!_isNil(error) && error instanceof ApolloError) {
      if (error?.graphQLErrors[0]?.extensions?.code === 'checksum_mismatched') {
        setShowChecksumPopup({
          showPopup: true,
          metaData: getChecksumMetaData({
            entityId,
            entityName,
            entityType,
            entitySubType,
          }),
        });
      } else {
        toasts.error(error.message, 'error');
      }
    }
  }, [error]);

  const handleRestoreDraft = async () => {
    try {
      setIsLoading(true);
      await rollbackEntityMutation({
        variables: {
          id: entityId,
          entityType: getEntityTypeForApi(entityType),
          commitId,
          action: entityActions.reset,
        },
        fetchPolicy: 'no-cache',
      });
    } catch (error) {
    } finally {
      setIsLoading(false);
      close();
    }
  };

  return (
    <Modal
      size="large"
      title={
        <Typography name="heading2">
          <Inline
            justify="center"
            align="center"
            stretch="start"
            gutter={'1rem'}
          >
            <img
              style={{ maxWidth: '4rem' }}
              src={`${envMap.VITE_ASSETS_URL}website/icons/warning_icon.svg`}
              alt="warning image"
            />
            Alert
          </Inline>
        </Typography>
      }
      hideCloseButton
    >
      <ModalContent>
        <ContainerStyled>
          <Typography name="heading3" fontWeight={700}>
            This will override your current draft! Do you wish to continue?
          </Typography>
        </ContainerStyled>
      </ModalContent>

      <ModalFooter justify="center">
        <Button appearance="neutral" onClick={close}>
          Cancel
        </Button>
        <Button onClick={handleRestoreDraft}>
          {isLoading ? <Spinner size="extraSmall" /> : <>Move to draft</>}
        </Button>
      </ModalFooter>
    </Modal>
  );
}
