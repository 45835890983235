import { useMutation } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';
import Cookies from 'js-cookie';

import { envMap } from '../utils/constant';
import { useAxios } from './useAxios';

export type LogEventRequestPayload = {
  event: string;
  entityType: string;
  entity: string;
  entityId: string;
  meta: Record<string, any>;
  payload: Record<string, any>;
  workspaceId: string;
  userId: string;
  eventTime: string;
};

export function useLogEvent() {
  const { axios } = useAxios();
  const allowedDomain =
    envMap.VITE_ENVIRONMENT === 'production' ? '.nected.ai' : '.nected.io';

  if (envMap.VITE_ENABLE_EVENT_API === 'true') {
    const { mutate: logEvent, isLoading } = useMutation({
      mutationFn: async (request: LogEventRequestPayload) => {
        return await axios.post('/event', request, {
          headers: {
            'Nected-User-IP': localStorage.getItem('Nected-User-IP') ?? '',
            'X-CSRF-Token': localStorage.getItem('X-CSRF-Token') ?? '',
          },
        });
      },
      onSuccess(
        response: AxiosResponse,
        requestPayload: LogEventRequestPayload
      ) {
        Cookies.set(requestPayload.event, requestPayload.payload.value, {
          expires: 1,
          domain: allowedDomain,
        });
      },
    });

    return {
      logEvent,
      isLoading,
    };
  } else {
    return {
      logEvent: () => {},
      isLoading: false,
    };
  }
}
