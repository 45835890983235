import { Stack } from '@bedrock-layout/stack';
import _isEmpty from 'lodash/isEmpty';
import { Control, UseFormSetValue, useController } from 'react-hook-form';
import { MdEdit } from 'react-icons/md';
import { Dataset, Typography, useLayer } from 'ui';

import { ExcelModal } from '../ExcelModal/ExcelModal';
import { AlignedText, PillStyled } from './ExcelPill.styled';

type ExcelPillProps = {
  control?: Control<any>;
  setValue?: UseFormSetValue<any>;
  name: string;
  dataSet?: Record<string, Dataset>;
  returnTypeName?: string;
  executedValueName?: string;
  isReadOnly?: boolean;
  isAdditionalData?: boolean;
  index?: number;
};

export function ExcelPill({
  control,
  setValue,
  name,
  dataSet,
  executedValueName,
  returnTypeName,
  isReadOnly = false,
  isAdditionalData = false,
  index = 0,
}: ExcelPillProps) {
  const { openWithProps } = useLayer(<ExcelModal name={name} />);

  const { fieldState } = useController({
    name,
    control,
  });

  const errorMessage = fieldState.error?.message;

  return (
    <Stack gutter={2}>
      <PillStyled
        padding={['0.4rem', '1rem']}
        onClick={() =>
          openWithProps({
            name,
            control,
            dataSet,
            setOriginalValue: setValue,
            executedValueName,
            returnTypeName,
            isReadOnly,
            isAdditionalData,
            index,
          })
        }
        $hasError={!_isEmpty(errorMessage)}
      >
        <AlignedText fontWeight={700}>Formula</AlignedText>
        <MdEdit />
      </PillStyled>

      {!_isEmpty(errorMessage) && (
        <Typography name="error">{errorMessage}</Typography>
      )}
    </Stack>
  );
}
