import { useMutation } from '@tanstack/react-query';
import type { AxiosError, AxiosResponse } from 'axios';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import _reduce from 'lodash/reduce';
import { toasts } from 'ui';

import { checksumWarningAtom } from '../../../atom';
import { useAxiosPrivate } from '../../../hooks';
import {
  checksumMessage,
  getResponseErrorMessage,
  handleSetCheckSumByEntityName,
  showErrorToast,
} from '../../../utils/common';
import type { RuleEnvironment } from '../../../utils/constant';
import type { CustomAxiosError } from '../../../utils/response/types';

type GetFilesPayload = {
  method: string;
  params: Record<string, any>;
  queryParams: Record<string, string>;
};

export function useGetGSheetFiles(
  currentEnvironment: RuleEnvironment,
  connectorId: string,
  connectorName: string,
  pluginType: string
) {
  const { axiosPrivate } = useAxiosPrivate();

  const [, setShowChecksumPopup] = useAtom(checksumWarningAtom);

  const {
    mutateAsync: getGSheetFiles,
    isLoading,
    data,
    error,
  } = useMutation({
    mutationFn: async (request: GetFilesPayload) => {
      const { method, queryParams } = request;

      const queryString = _reduce(
        queryParams,
        (queryString: string, value: string, key: string) =>
          (queryString += `${key}=${value}&`),
        ''
      );
      const requestUrl = `/integration/connector/execute?${queryString.slice(
        0,
        -1
      )}`;

      const payload = {
        environment: currentEnvironment,
        id: connectorId,
        method: method ?? 'getFiles',
        params: {
          cr: false,
        },
      };

      return await axiosPrivate.post(requestUrl, payload);
    },
    onSuccess(response: AxiosResponse) {
      if (!_isNil(response.data.checksum)) {
        handleSetCheckSumByEntityName('integrations', response.data.checksum);
      }

      toasts.success('Data fetched successfully', 'success');

      return response;
    },
    onError(error: AxiosError) {
      showErrorToast(error as AxiosError<CustomAxiosError>);

      if (error instanceof Error) {
        const errorMessage = getResponseErrorMessage(
          error as AxiosError<CustomAxiosError>
        );

        if (errorMessage.includes(checksumMessage)) {
          setShowChecksumPopup({
            showPopup: true,
            metaData: {
              connectorId,
              connectorName,
              type: pluginType,
            },
          });
        } else {
          toasts.error(errorMessage, 'error');
        }
      }

      return error;
    },
  });

  return {
    getGSheetFiles,
    isLoading,
    data,
    error,
  };
}
