import { useLazyQuery } from '@apollo/client';

import { getAuthConfigKeysQuery } from '../../../../query';
import type { SavedAuthConfigKeysResult } from '../../../../types';

export function useGetLazyAuthConfigKeys() {
  return useLazyQuery<SavedAuthConfigKeysResult>(getAuthConfigKeysQuery, {
    fetchPolicy: 'no-cache',
  });
}
