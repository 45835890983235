import { Stack } from '@bedrock-layout/stack';
import { useAtom } from 'jotai';
import { UseControllerProps, UseFormSetValue, useWatch } from 'react-hook-form';

import { simpleRuleNodeIdAtom } from '../../..';
import { useSendEventToGTM } from '../../../../../hooks/useSendEventToGTM';
import { Else } from './Else';
import { BlockStyled } from './Results.styled';
import { Then } from './Then';

export type ResultsProps = Omit<UseControllerProps, 'name'> & {
  setValue?: UseFormSetValue<any>;
};

export type ResultType = 'thenDataParams' | 'elseDataParams';
export type ResultActionType = 'thenActionParams' | 'elseActionParams';

export type sendEventToGTMType = {
  action: string;
  element: string;
  actionName: string;
};

export const Results = ({ control, setValue }: ResultsProps) => {
  const [ruleId] = useAtom(simpleRuleNodeIdAtom);

  const { sendEventToGTM } = useSendEventToGTM();

  const ruleName = useWatch({
    control,
    name: 'ruleName',
  });

  const handleSendEventToGTM = ({
    action = '',
    element = '',
    actionName = '',
  }: sendEventToGTMType) => {
    sendEventToGTM({
      event: 'rule',
      ruleId,
      ruleName,
      type: 'simpleRule',
      nec_source: '',
      action,
      element,
      action_name: actionName,
    });
  };

  return (
    <BlockStyled padding={[16, 20, 16, 10]}>
      <Stack gutter={10}>
        <Then
          control={control}
          setValue={setValue}
          handleSendEventToGTM={handleSendEventToGTM}
        />
        <Else
          control={control}
          setValue={setValue}
          handleSendEventToGTM={handleSendEventToGTM}
        />
      </Stack>
    </BlockStyled>
  );
};
