import { useMutation } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import { toasts } from 'ui';

import { useAxios } from '../../../hooks';
import { showErrorToast } from '../../../utils/common';
import type { CustomAxiosError } from '../../../utils/response/types';

export type ForgotPasswordRequestPayload = {
  email: string;
};

export function useForgotPassword() {
  const { axios } = useAxios();

  const { mutate: forgotPassword } = useMutation({
    mutationFn: async (request: ForgotPasswordRequestPayload) => {
      return await axios.post('/auth/password/forget', request, {
        headers: {
          'Nected-User-IP': localStorage.getItem('Nected-User-IP') ?? '',
          'X-CSRF-Token': localStorage.getItem('X-CSRF-Token') ?? '',
        },
      });
    },
    onSuccess() {
      toasts.success(
        'Reset password link has been sent. Please check your email',
        'success'
      );
    },
    onError(error: AxiosError<CustomAxiosError>) {
      showErrorToast(error);
    },
  });

  return {
    forgotPassword,
  };
}
