import { Inline } from '@bedrock-layout/primitives';
import styled from 'styled-components';

export const AcceptTCContainer = styled(Inline)`
  flex-wrap: wrap;
  a {
    margin: 0rem 0.5rem;
    * {
      font-size: 1.2rem;
    }
  }
`;

export const CustomLinks = styled.span`
  cursor: pointer;
  color: var(--color-black);
`;
