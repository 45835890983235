import _isNil from 'lodash/isNil';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import type { PageModel } from '../models';

export const useQuestionnairePages = (pages: PageModel[]) => {
  const [searchParams] = useSearchParams();

  const totalPages = pages.length;

  const pageParam = searchParams.get('page');
  const page = !_isNil(pageParam) ? parseInt(pageParam) : 0;

  const [currentIndex, setCurrentPage] = useState(page);

  const nextPage = () => {
    setCurrentPage((previousPage) => {
      if (previousPage >= totalPages - 1) {
        return previousPage;
      }

      return previousPage + 1;
    });
  };

  const goToPage = (index: number) => {
    setCurrentPage(index);
  };

  const previousPage = () => {
    setCurrentPage((previousPage) => {
      if (previousPage <= 0) {
        return previousPage;
      }

      return previousPage - 1;
    });
  };

  return {
    pages,
    currentPage: pages[currentIndex],
    currentIndex,
    nextPage,
    previousPage,
    goToPage,
    isLastPage: currentIndex === totalPages - 1,
    isFirstPage: currentIndex === 0,
  };
};
