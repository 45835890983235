import { Stack } from '@bedrock-layout/stack';
import { Typography } from 'ui';

import type { TriggerListType } from '../../../../models/models';
import { TriggerItemStyled } from './TriggerPopover.styled';

type TriggerItemProps = {
  trigger: TriggerListType;
  nodeId: string;
  onClick?: (value: any) => void;
};

export function TriggerItem({ trigger, onClick, nodeId }: TriggerItemProps) {
  return (
    <Stack
      onClick={() => {
        if (typeof onClick === 'function') {
          onClick({
            type: trigger.type,
            id: nodeId,
          });
        }
      }}
      gutter={8}
    >
      <TriggerItemStyled>{trigger.icon}</TriggerItemStyled>
      <Typography name="secondaryXs">{trigger.name}</Typography>
    </Stack>
  );
}
