import styled, { css } from 'styled-components';

export const PickerContainer = styled.div<{ $isError?: boolean }>`
  display: flex;
  inline-size: 32rem;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem 1rem;
  border-radius: 0.8rem;
  border: 1px solid var(--color-lightGray);

  ${({ $isError = false }) =>
    $isError &&
    css`
      border: 1px solid var(--color-fireEngineRed);
    `}
`;

export const InputContainer = styled.div`
  inline-size: 12rem;
`;

export const ColorContainer = styled.div<{ $color?: string }>`
  background-color: ${({ $color = '#fff' }) => $color};
  block-size: 3.6rem;
  inline-size: 3.6rem;
  outline: 1px solid var(--color-lightGray);
  border-radius: 0.4rem;
`;

export const LauncherButton = styled.button`
  border: 1px solid var(--color-lightGray);
  block-size: 3.7rem;
  inline-size: 3.7rem;
  border-radius: 0.4rem;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
`;
