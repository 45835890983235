import { ChangeEvent, useEffect, useState } from 'react';
import { BiCollapseAlt } from 'react-icons/bi';

import { useCurrentLayer } from '../../Layer';
import { Modal } from '../../Modal';
import {
  IconContainer,
  InputModalContainer,
  InputModalStyled,
} from './TextInputInModal.styled';

export type TextInputInModalProps = {
  placeholder?: string;
  value?: any;
  onChange?: (e: ChangeEvent<any>) => void;
  disabled?: boolean;
};

export const TextInputInModal = ({
  placeholder = 'Enter Value',
  value,
  onChange,
  disabled = false,
}: TextInputInModalProps) => {
  const { close: closeModal } = useCurrentLayer();

  const [localValue, setLocalValue] = useState<any>();

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  return (
    <Modal size="large" hideCloseButton={true}>
      <InputModalContainer>
        <InputModalStyled
          placeholder={placeholder}
          value={localValue}
          rows={8}
          disabled={disabled}
          onChange={(e) => {
            if (typeof onChange === 'function') {
              onChange(e);
              setLocalValue(e.target.value);
            }
          }}
        />
        <IconContainer onClick={closeModal}>
          <BiCollapseAlt size={12} color={'var(--color-white)'} />
        </IconContainer>
      </InputModalContainer>
    </Modal>
  );
};
