import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import type { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { type Dataset, Toast } from 'ui';

import { AttributeModel } from '../../../../Rules/models';
import { Selector } from '../RuleSheet/Selector/Selector';

type WorkflowMappingProps = {
  control?: Control<any>;
  setValue: UseFormSetValue<any>;
  dataSet: Record<string, Dataset>;
  watch: UseFormWatch<any>;
  customInputs: Record<string, AttributeModel>;
  localData: any;
  idsToNotExpand?: string[];
};

export function WorkflowMapping({
  control,
  setValue,
  dataSet,
  watch,
  customInputs,
  localData,
  idsToNotExpand = [],
}: WorkflowMappingProps) {
  const attributes = watch('attributes');

  if (attributes?.length === 0) {
    return (
      <Inline>
        <Toast
          type="infoBlue"
          message='You do not have any input attributes configured. Please go ahead and
          click on "Test" to test the node.'
        />
      </Inline>
    );
  }

  return (
    <Stack gutter="1.5rem">
      {attributes?.map((attribute: any, index: number) => {
        return (
          <Selector
            control={control}
            setValue={setValue}
            keyName={attribute.keyName}
            nodeName={`attributes.${index}`}
            dataType={attribute.dataType ?? 'string'}
            dataSet={dataSet}
            index={index}
            key={`attributes.${index}`}
            customInputs={customInputs}
            previousCustomInputs={localData.input}
            idsToNotExpand={idsToNotExpand}
          />
        );
      })}
    </Stack>
  );
}
