import { PadBox } from '@bedrock-layout/padbox';
import { Inline, Stack } from '@bedrock-layout/primitives';
import styled from 'styled-components';
import { Typography } from 'ui';

export const ResponseContainer = styled(PadBox)`
  border-top: 1px solid var(--color-lightGray);
  border-bottom: 1px solid var(--color-lightGray);
  background: var(--color-whiteSmoke);
  min-block-size: 10rem;
`;

export const ResponseWrapper = styled(PadBox)`
  border: 1px solid var(--color-lightGray);
  border-radius: 4px;
  min-block-size: 10rem;
  background: var(--color-white);
`;

export const ResponseHeaderContainer = styled.div`
  min-block-size: 3rem;
  border-bottom: 1px solid var(--color-lightGray);
  display: flex;
`;

export const ResponseHeader = styled(PadBox)<{ $width: string }>`
  ${({ $width = '10rem' }) => `inline-size: ${$width};`}
  padding: 1rem;
  &:not(:last-child) {
    border-right: 1px solid var(--color-lightGray);
  }
`;

export const ResponseResult = styled(PadBox)<{ $width: string }>`
  ${({ $width = '10rem' }) => `inline-size: ${$width};`}
  padding: 1rem;

  min-block-size: 5rem;

  &:not(:last-child) {
    border-right: 1px solid var(--color-lightGray);
  }
`;

export const ResponseResultContainer = styled.div`
  min-block-size: 5rem;
  display: flex;

  &:not(:last-child) {
    border-bottom: 1px solid var(--color-lightGray);
  }
`;

export const WrappedName = styled(Typography)`
  word-break: break-all;
`;

export const RowStyled = styled(Inline)`
  border: 1px solid var(--color-solitude);
  border-block-start: none;
`;

export const RowItemStyled = styled.div`
  min-inline-size: 22rem;
  border-inline-end: 1px solid var(--color-solitude);

  .bool-container-style {
    position: relative;
  }

  &:last-child {
    border-inline-end: none;
  }
`;

export const HeaderStyled = styled(Inline)`
  border-radius: 4px 4px 0 0;
  border: 1px solid var(--color-solitude);
  width: fit-content;
`;

export const HeaderItemStyled = styled.div`
  min-inline-size: 22rem;
  border-inline-end: 1px solid var(--color-solitude);

  &:last-child {
    border-inline-end: none;
  }
`;

export const HeaderWrapper = styled(Stack)`
  overflow-x: auto;
`;
