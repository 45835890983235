import { PadBox } from '@bedrock-layout/padbox';
import { Typography } from 'ui';

import { RuleConfigType } from '../../../../../../Rules/types';
import { ruleTypeConfig } from '../../../../../../Rules/utils/constant';
import { getNodeNameByRuleName } from '../../../../../utils/common';

type RuleCreateListProps = {
  onItemClick: (
    type: string,
    entityId?: string,
    metaData?: Record<string, any>
  ) => void;
};

export function RuleCreateList({ onItemClick }: RuleCreateListProps) {
  const handleMenuItemClick = (value: string) => {
    onItemClick('selectedRuleType', undefined, {
      type: getNodeNameByRuleName(value),
    });
  };

  const items: RuleConfigType[] = [
    ruleTypeConfig.simpleRule,
    ruleTypeConfig.decisionTable,
    ruleTypeConfig.ruleSet,
  ];

  return (
    <>
      {items.map((item: RuleConfigType) => (
        <PadBox
          padding={['1rem', '0.5rem']}
          onClick={() => handleMenuItemClick(item.value)}
          style={{
            cursor: 'pointer',
          }}
          key={item.description}
        >
          <Typography fontWeight={700} name="paragraphSmall">
            {item.label}
          </Typography>
          <Typography name="secondaryXs">{item.description}</Typography>
        </PadBox>
      ))}
    </>
  );
}
