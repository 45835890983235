export const queryOptions = [
  {
    label: 'SELECT Query',
    value: 0,
  },
  {
    label: 'INSERT or UPDATE Query',
    value: 1,
  },
];
