import { gql, useLazyQuery } from '@apollo/client';
import _isNil from 'lodash/isNil';
import _sortBy from 'lodash/sortBy';
import { useEffect, useState } from 'react';
import type { Dispatch, SetStateAction } from 'react';

import { handleSetCheckSumByEntityName } from '../../../../utils/common';
import { Environment } from '../../../../utils/constant';
import type {
  ConnectorAndPluginModel,
  PluginParams,
  SortedPluginParamsFieldKeys,
  UpdateConnectorStatusFunction,
} from '../../types';
import {
  getAuthKeyConvertedList,
  getTransformedPluginParamsWithAuthKey,
  getTransformedSavedFormValues,
  getTransformedSortedConnectorConfigParams,
} from '../connector/utils';
import { useGetLazyAuthConfigKeys } from './useGetLazyAuthConfigKeys';
import type { StoreFormValuesFunction } from './useStoreFormValues';

export type GetConnectorsQueryResult = {
  getConnector: {
    data: ConnectorAndPluginModel[];
  };
};

const getConnectorsQuery = gql`
  query getConnector($connectorId: String!) {
    getConnector(id: $connectorId) {
      data {
        id
        name
        checksum
        staging {
          conf
          isTested
          isPublish
        }
        production {
          conf
          isTested
          isPublish
        }
        plugin {
          category
          schema {
            integration {
              params
            }
          }
        }
      }
    }
  }
`;

export function useGetConnector(
  connectorId: string,
  updateConnectorStatus: UpdateConnectorStatusFunction,
  storeFormValues: StoreFormValuesFunction,
  setConnectorName: Dispatch<SetStateAction<string>>
) {
  const [stagingSortedFields, setStagingSortedFields] = useState<
    SortedPluginParamsFieldKeys[]
  >([]);

  const [productionSortedFields, setProductionSortedFields] = useState<
    SortedPluginParamsFieldKeys[]
  >([]);

  const [formJson, setFormJson] = useState<PluginParams>({});
  const [category, setCategory] = useState('');
  const [getAuthKeys, { data: authKeyList, refetch }] =
    useGetLazyAuthConfigKeys();
  const [shouldFormRender, setShouldFormRender] = useState(false);

  const [getCurrentConnector, { data, loading, error }] = useLazyQuery<
    GetConnectorsQueryResult,
    {
      connectorId: string;
    }
  >(getConnectorsQuery);

  // Transform and sort the params to render in ascending order
  useEffect(() => {
    if (!_isNil(data) && !_isNil(data.getConnector.data[0])) {
      const connectorData = data.getConnector.data[0];
      const { category, schema } = connectorData.plugin;

      handleSetCheckSumByEntityName('integrations', connectorData.checksum);

      const pluginParams = _isNil(schema?.integration.params)
        ? {}
        : { ...schema.integration.params };

      const stagingConfig = {
        ...connectorData.staging?.conf,
      };
      const productionConfig = {
        ...connectorData.production?.conf,
      };

      const transformedStagingConfigParams =
        getTransformedSortedConnectorConfigParams(pluginParams, stagingConfig);

      const transformedProductionConfigParams =
        getTransformedSortedConnectorConfigParams(
          pluginParams,
          productionConfig
        );

      const stagingTransformedFormValues = getTransformedSavedFormValues(
        pluginParams,
        stagingConfig
      );

      const productionTransformedFormValues = getTransformedSavedFormValues(
        pluginParams,
        productionConfig
      );

      setCategory(category);

      setConnectorName(connectorData.name);

      updateConnectorStatus(
        Environment.STAGING,
        connectorData.staging.isTested,
        connectorData.staging.isPublish
      );
      updateConnectorStatus(
        Environment.PRODUCTION,
        connectorData.production.isTested,
        connectorData.production.isPublish
      );

      storeFormValues(stagingTransformedFormValues, Environment.STAGING);
      storeFormValues(productionTransformedFormValues, Environment.PRODUCTION);

      setFormJson(pluginParams);

      setStagingSortedFields(_sortBy(transformedStagingConfigParams, 'order'));
      setProductionSortedFields(
        _sortBy(transformedProductionConfigParams, 'order')
      );

      // blocking form rendering for all category except api unless all api got response

      if (category !== 'api') {
        setShouldFormRender(true);
      }
    }
  }, [data]);

  useEffect(() => {
    // calling get auth key api only for category api
    if (category === 'api') {
      void getAuthKeys();
    }
  }, [category]);

  useEffect(() => {
    if (!_isNil(authKeyList) && !_isNil(data)) {
      const authKeys = getAuthKeyConvertedList(
        authKeyList.getAuthConfigKeys.data
      );

      if (!_isNil(data) && !_isNil(data.getConnector.data[0])) {
        const connectorData = data.getConnector.data[0];
        const { schema } = connectorData.plugin;

        const stagingConfig = {
          ...connectorData.staging?.conf,
        };
        const paramsCloned = _isNil(schema?.integration.params)
          ? {}
          : { ...schema.integration.params };

        const productionConfig = {
          ...connectorData.production?.conf,
        };

        for (const key in paramsCloned) {
          const { action } = paramsCloned[key];

          if (action === 'getCredentials') {
            paramsCloned[key] = {
              ...paramsCloned[key],
              options: authKeys,
            };
          }
        }

        const stagingTransformedFormValues = getTransformedSavedFormValues(
          paramsCloned,
          stagingConfig
        );

        const productionTransformedFormValues = getTransformedSavedFormValues(
          paramsCloned,
          productionConfig
        );

        storeFormValues(stagingTransformedFormValues, Environment.STAGING);
        storeFormValues(
          productionTransformedFormValues,
          Environment.PRODUCTION
        );
      }

      const stagingSortedFieldsCloned = getTransformedPluginParamsWithAuthKey(
        authKeys,
        stagingSortedFields
      );

      const productionSortedFieldsCloned =
        getTransformedPluginParamsWithAuthKey(authKeys, productionSortedFields);
      setStagingSortedFields(stagingSortedFieldsCloned);
      setProductionSortedFields(productionSortedFieldsCloned);
      setShouldFormRender(true);
    }
  }, [authKeyList, data]);

  return {
    stagingSortedFields,
    productionSortedFields,
    formJson,
    loading,
    error,
    shouldFormRender,
    getCurrentConnector,
    refetch,
  };
}
