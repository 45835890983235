import { ReactElement, createRef, useEffect } from 'react';
import { TbBug } from 'react-icons/tb';
import { PopoverMethods, PopoverPanel } from 'ui';

import { RuleDebugBtn } from './RuleDebug.styled';
import { RuleDebugPanel } from './RuleDebugPanel';

type RuleDebugProps = {
  warnings?: string[];
  launcher?: ReactElement;
  isRuleReadOnly?: boolean;
};

export function RuleDebug({
  launcher,
  warnings = [],
  isRuleReadOnly = false,
}: RuleDebugProps) {
  const popoverMethods = createRef<PopoverMethods>();
  const hasWarning = warnings.length > 0;

  useEffect(() => {
    if (hasWarning) {
      popoverMethods.current?.show();
    }
  }, [JSON.stringify(warnings.length)]);

  return (
    <PopoverPanel
      padding={0}
      trigger="click"
      launcher={
        launcher ?? (
          <RuleDebugBtn $isError={hasWarning} type="button">
            <TbBug size={20} />
          </RuleDebugBtn>
        )
      }
      ref={popoverMethods}
      placement="auto"
      disabled={isRuleReadOnly}
    >
      <RuleDebugPanel
        warnings={warnings}
        onClose={() => popoverMethods.current?.hide()}
      />
    </PopoverPanel>
  );
}
