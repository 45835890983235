import { VideoDataProps } from '../../pages/Guides';
import { ENTITY_ID, envMap } from '../../utils/constant';

export type SidebarItemType = {
  route: string;
  label: string;
  icon: string;
  activeIcon: string;
  alt: string;
  id: string;
  isBeta?: boolean;
};

export type SidebarItemsType = {
  value: string;
  label: string;
  items: SidebarItemType[];
};

export const sidebarItems: SidebarItemsType[] = [
  {
    value: 'dashboard',
    label: '',
    items: [
      {
        route: '/home',
        label: 'Home',
        icon: `${envMap.VITE_ASSETS_URL}website/icons/home.svg`,
        activeIcon: `${envMap.VITE_ASSETS_URL}website/icons/home_active.svg`,
        alt: 'Home',
        id: ENTITY_ID.home,
      },
    ],
  },
  {
    value: 'features',
    label: 'Features',
    items: [
      {
        route: '/rules',
        label: 'Rules',
        icon: `${envMap.VITE_ASSETS_URL}website/icons/rules.svg`,
        activeIcon: `${envMap.VITE_ASSETS_URL}website/icons/rules_active.svg`,
        alt: 'Rules',
        id: ENTITY_ID.rules,
      },
      {
        isBeta: false,
        route: '/workflow',
        label: 'Workflow',
        icon: `${envMap.VITE_ASSETS_URL}website/icons/workflow.svg`,
        activeIcon: `${envMap.VITE_ASSETS_URL}website/icons/workflow_active.svg`,
        alt: 'Workflow',
        id: ENTITY_ID.workflow,
      },
    ],
  },
  {
    value: 'security',
    label: 'Security',
    items: [
      {
        route: '/onpremise',
        label: 'On Premise',
        icon: `${envMap.VITE_ASSETS_URL}website/icons/onPremise.svg`,
        activeIcon: `${envMap.VITE_ASSETS_URL}website/icons/onPremise_active.svg`,
        alt: 'On Premise',
        id: ENTITY_ID.onPremise,
      },
      {
        route: '/auditlogs',
        label: 'Audit Trail',
        icon: `${envMap.VITE_ASSETS_URL}website/icons/auditTrail.svg`,
        activeIcon: `${envMap.VITE_ASSETS_URL}website/icons/auditTrail_active.svg`,
        alt: 'Logs',
        id: ENTITY_ID.auditLogs,
      },
      {
        route: '/credentials',
        label: 'Authorization',
        icon: `${envMap.VITE_ASSETS_URL}website/icons/credentials.svg`,
        activeIcon: `${envMap.VITE_ASSETS_URL}website/icons/credentials_active.svg`,
        alt: 'Authorization',
        id: ENTITY_ID.credentials,
      },
    ],
  },
  {
    value: 'data',
    label: 'Data/Action',
    items: [
      {
        route: '/integrations',
        label: 'Integrations',
        icon: `${envMap.VITE_ASSETS_URL}website/icons/integrations.svg`,
        activeIcon: `${envMap.VITE_ASSETS_URL}website/icons/integrations_active.svg`,
        alt: 'Integrations',
        id: ENTITY_ID.integrations,
      },
      {
        route: '/datasets',
        label: 'Data Sources',
        icon: `${envMap.VITE_ASSETS_URL}website/icons/datasets.svg`,
        activeIcon: `${envMap.VITE_ASSETS_URL}website/icons/datasets_active.svg`,
        alt: 'Data Sources',
        id: ENTITY_ID.datasets,
      },
      {
        route: '/remoteconfig',
        label: 'Global Variables',
        icon: `${envMap.VITE_ASSETS_URL}website/icons/remoteConfig.svg`,
        activeIcon: `${envMap.VITE_ASSETS_URL}website/icons/remoteConfig_active.svg`,
        alt: 'Global Variables',
        id: ENTITY_ID.remoteConfig,
      },
    ],
  },
  {
    value: 'settings',
    label: 'Settings',
    items: [
      {
        route: '/workspace',
        label: 'Workspace',
        icon: `${envMap.VITE_ASSETS_URL}website/icons/settings.svg`,
        activeIcon: `${envMap.VITE_ASSETS_URL}website/icons/settings_active.svg`,
        alt: 'Workspace',
        id: ENTITY_ID.workspace,
      },
    ],
  },
];

type NectedResourcesProps = {
  title: string;
  id: string;
  link: string;
  icon: string;
  target: string;
};

export const NectedResources: NectedResourcesProps[] = [
  {
    title: 'Documentation',
    id: 'documentation_homepage',
    link: 'https://docs.nected.ai/nected-docs',
    icon: 'home/documentation.png',
    target: '_blank',
  },
  {
    title: 'Contact Support',
    id: 'support_homepage',
    link: '#',
    icon: 'home/support.png',
    target: '_blank',
  },
  {
    title: 'Watch Tutorials',
    id: 'tutorials_homepage',
    link: '/guides',
    icon: 'home/youtube.png',
    target: '_blank',
  },
  {
    title: 'Give us feedback',
    id: 'feedback_homepage',
    link: '#',
    icon: 'home/feedback.png',
    target: '_blank',
  },
];

export const HomePageVideoBlock: VideoDataProps = {
  name: 'Getting started with Nected',
  videoUrl: 'https://www.youtube.com/watch?v=3L-wqaG6m2g',
  time: '1 min',
  documentUrl: '',
  thumbnail: `${envMap.VITE_ASSETS_URL}home/home_video_thumb.png`,
};
