import axiosCreator from 'axios';
import type { AxiosResponse } from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { toasts } from 'ui';

import { useAuth } from '../components/authentication';
import type { Tokens } from '../components/authentication';
import { useAxios } from './useAxios';

export type RefreshTokenResponse = {
  code: string;
  message: string;
  data: Tokens;
};

export function useRefreshToken() {
  const { axios } = useAxios();
  const { auth, setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const refresh = async () => {
    try {
      const response: AxiosResponse<RefreshTokenResponse> = await axios.post(
        '/auth/login/refresh',
        {
          refreshToken: auth.refreshToken,
        }
      );

      const { accessToken, refreshToken } = response.data.data;

      setAuth({ accessToken, refreshToken });

      return accessToken;
    } catch (error) {
      if (
        axiosCreator.isAxiosError<Omit<RefreshTokenResponse, 'data'>>(error)
      ) {
        setAuth({ accessToken: '', refreshToken: '' });

        if (error.response?.data.code === 'refresh_token_expired') {
          toasts.info('Please login again', 'login-again');
          navigate('/signin', { state: { to: location }, replace: true });
        }
      }
    }

    return null;
  };

  return { refresh };
}
