import { Inline, PadBox, Stack } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import { useCallback, useEffect, useState } from 'react';
import type { UseControllerProps, UseFormSetValue } from 'react-hook-form';
import { useFieldArray, useWatch } from 'react-hook-form';
import { toasts, useLayer } from 'ui';

import { ruleLimitsConfigAtom } from '../../..';
import ActionSheet from '../../../../../components/ActionComponents/ActionSheet';
import { useGetConnectorById } from '../../../../../hooks/graphql/useGetConnectorById';
import { getEditorDetailsByPlugin } from '../../../../DataSets/utils';
import type { sendEventToGTMType } from '../../../types';
import { getResultKeyName } from '../../../utils/common';
import { DataActionsType } from '../../DataAddAction/DataActionsType';
import { decisionTableNodeIdAtom } from '../DecisionTable';
import { ActionFieldContainer } from './DataAddActionDecisionTable.styled';
import DecisionTableActions from './DecisionTableActions';
import { RowActionsContainer } from './RowsFieldArray.styled';

type DataAddActionDecisionTableProps = Omit<UseControllerProps, 'name'> & {
  setValue: UseFormSetValue<any>;
  handleSendEventToGTM: (obj: sendEventToGTMType) => void;
};

export function DataAddActionDecisionTable({
  control,
  setValue,
  handleSendEventToGTM,
}: DataAddActionDecisionTableProps) {
  const [open, setOpen] = useState(false);
  const [selectedConnectorId, setSelectedConnectorId] = useState(-1);

  const [id] = useAtom(decisionTableNodeIdAtom);
  const [limitConfig] = useAtom(ruleLimitsConfigAtom);

  const { fields, append, remove } = useFieldArray<any>({
    name: 'thenActionParams',
    control,
  });

  const [getConnectorById] = useGetConnectorById();

  const actionFields = useWatch({
    name: 'thenActionParams',
    control,
  });

  const { openWithProps: openActionSheet } = useLayer(
    <ActionSheet
      index={0}
      section={'thenActionParams'}
      control={control}
      setValue={setValue}
      sheetMode=""
    />
  );

  useEffect(() => {
    if (!open) {
      setSelectedConnectorId(-1);
    }
  }, [open]);

  useEffect(() => {
    if (selectedConnectorId !== -1) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [selectedConnectorId]);

  const handleAddElement = (connectorId: string, key: string) => {
    const name = getResultKeyName(fields, 'action_');

    append({
      name: name.length > 0 ? name : 'action_1',
      connectorId,
      config: {
        query: '',
      },
    });

    handleSendEventToGTM({
      action: 'selection',
      element: 'action',
      actionName: key,
    });
  };

  const handleAddAction = (value: string, id: string) => {
    if (
      !_isEmpty(limitConfig) &&
      fields.length < limitConfig.maxActions.value
    ) {
      handleAddElement(id, value);
      setSelectedConnectorId(fields.length);
      setOpen(true);
    } else if (!_isEmpty(limitConfig.maxActions.message)) {
      toasts.info(limitConfig.maxActions.message, 'info');
    }
  };

  const handleOpenAction = async (connectorId: string) => {
    const data = await getConnectorById({
      variables: {
        connectorId,
      },
    });

    const pluginName = data.data?.getConnector.data[0].plugin.name;

    const editorDetails = getEditorDetailsByPlugin(
      data.data?.getConnector.data[0].plugin
    );

    if (typeof handleSendEventToGTM === 'function' && !open) {
      handleSendEventToGTM({
        action: 'edit',
        element: 'action',
        actionName: pluginName ?? '',
      });
    }

    const sheetIndex = actionFields.length;

    const currField = {
      name,
      connectorId,
      config: {
        query: '',
      },
    };

    openActionSheet({
      section: 'thenActionParams',
      connectorId,
      field: currField,
      suggestions: [],
      suggestionsObjs: [],
      type: editorDetails?.databaseName ?? pluginName ?? 'mysql',
      editorDetails,
      index: sheetIndex,
      connector: data.data?.getConnector.data[0],
      sheetMode: 'create',
    });
  };

  useEffect(() => {
    if (open && fields?.length > 0) {
      // @ts-expect-error
      void handleOpenAction(fields[fields.length - 1].connectorId);

      setOpen(false);
    }
  }, [fields]);

  const addAction = useCallback(
    (type: string, id: string) => {
      handleAddAction(type, id);
    },
    [JSON.stringify(fields)]
  );

  return (
    <Inline gutter={0}>
      <RowActionsContainer />
      <PadBox padding={[0, 8, 0, 8]} as={Stack} gutter={10}>
        {fields.length > 0 && (
          <ActionFieldContainer>
            {fields.map((action: Record<string, any>, index: number, fl) => {
              return (
                <Stack key={`then_action_${index}_${action.id as string}`}>
                  <DecisionTableActions
                    connectorId={action.connectorId}
                    field={action}
                    control={control}
                    handleDeleteElement={(index) => remove(index)}
                    handleAddElement={handleAddElement}
                    key={`then_action_abcd_${index}_${action.id as string}`}
                    index={index}
                    isLast={index === fields.length - 1}
                    section="thenActionParams"
                    setValue={setValue}
                    handleSendEventToGTM={handleSendEventToGTM}
                    open={selectedConnectorId === index}
                    setOpen={setOpen}
                  />
                </Stack>
              );
            })}
          </ActionFieldContainer>
        )}

        {fields.map((action: Record<string, any>, index: number, fl) => {
          const isLast = index === fields.length - 1;

          if (!isLast) {
            return null;
          }

          return (
            <DataActionsType
              ruleId={id}
              control={control}
              addAction={addAction}
              ruleType="decisionTable"
              key={`then_action_efgh_${index}_${action.id as string}`}
            />
          );
        })}

        {fields.length === 0 && (
          <DataActionsType
            ruleId={id}
            control={control}
            addAction={addAction}
            ruleType="decisionTable"
          />
        )}
      </PadBox>
    </Inline>
  );
}
