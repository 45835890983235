import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { toasts } from 'ui';

import { useAxiosPrivate } from '../../../../hooks';
import { showErrorToast } from '../../../../utils/common';
import { CustomAxiosError } from '../../../../utils/response/types';
import type { Role } from '../../component/types';

export type useUpdateAMemberRolePayload = {
  user_uuid: string;
  role: Role;
  type: 'embed' | 'team';
};

export function useUpdateAMemberRole() {
  const { axiosPrivate } = useAxiosPrivate();

  const {
    mutateAsync: updateAMemberRole,
    isLoading,
    data,
    error,
  } = useMutation({
    mutationFn: async (request: useUpdateAMemberRolePayload) => {
      return await axiosPrivate.patch('/workspace/member', request);
    },
    onSuccess() {
      toasts.success('Role updated successfully', 'updated-success');
    },
    onError(error: AxiosError<CustomAxiosError>) {
      showErrorToast(error);
    },
  });

  return {
    updateAMemberRole,
    isLoading,
    data,
    error,
  };
}
