import { DropdownField, DropdownFieldPropTypes } from 'ui';

import { decisionTablePolicies } from '../../utils/constant';

export const policies = [
  {
    label: 'First',
    value: 'first',
  },
  {
    label: 'Unique',
    value: 'unique',
  },
  {
    label: 'Collect',
    value: 'collect',
  },
  {
    label: 'Order',
    value: 'order',
  },
  {
    label: 'Evaluate All',
    value: 'evaluteAll',
  },
];

export function PolicyDropdown(props: DropdownFieldPropTypes<any>) {
  return (
    <DropdownField
      {...props}
      options={decisionTablePolicies}
      menuWidth={400}
      showCustomMenu
    />
  );
}
