import { Center } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { Image, ToolTip } from 'ui';

import { permissionObj } from '../../../../components/PermissionComponent/constant';
import { useCheckPermissions } from '../../../../components/PermissionComponent/hooks/useCheckPermissions';
import { isValidImageURL } from '../../../../utils/common';
import { ENTITY_ID } from '../../../../utils/constant';
import { ConnectAppearance, ConnectButton } from './ConnectButton';
import { IntegrationName } from './IntegrationSheet.styled';

type IntegrationCardDetailsProps = {
  alt: string;
  image: string;
  name: string;
  connectAppearance: ConnectAppearance;
  onClick?: () => void;
};

export function IntegrationCardDetails({
  name,
  image,
  alt,
  connectAppearance,
  onClick,
}: IntegrationCardDetailsProps) {
  const { isHide } = useCheckPermissions({
    allowedPermission: [
      permissionObj.create,
      permissionObj.edit,
      permissionObj.read,
    ],
    entityList: [ENTITY_ID.integrations],
  });

  const { isHide: disableCreateNewConnector } = useCheckPermissions({
    allowedPermission: [permissionObj.create, permissionObj.edit],
    entityList: [ENTITY_ID.integrations],
  });

  return (
    <Stack gutter="1.6rem">
      <Stack gutter="2.8rem">
        <Center centerChildren centerText>
          <Image src={isValidImageURL(image)} alt={alt} size="large" />

          <ToolTip message={name} placement="top">
            <IntegrationName>{name}</IntegrationName>
          </ToolTip>
        </Center>
      </Stack>

      {connectAppearance === 'new' && (
        <ConnectButton
          appearance={connectAppearance}
          onClick={onClick}
          disable={disableCreateNewConnector}
        />
      )}

      {connectAppearance !== 'new' && (
        <ConnectButton
          appearance={connectAppearance}
          onClick={onClick}
          disable={isHide}
        />
      )}
    </Stack>
  );
}
