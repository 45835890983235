import { useAtom } from 'jotai';
import { useRef } from 'react';
import {
  Control,
  UseFormSetValue,
  UseFormWatch,
  useController,
} from 'react-hook-form';
import {
  Dataset,
  OnClickRuleArgs,
  PopoverMethods,
  PopoverPanel,
  RulePopover,
  useLayer,
} from 'ui';

import { allConditionTypes } from '../../../../../Rules/utils/common';
import { isWorkflowReadOnlyAtom } from '../../../../atoms/atoms';
import { ExcelFormulaModal } from '../../SwitchNodeSheet/SwitchForm/ExcelFormulaModal/ExcelFormulaModal';
import { JsNodeSheet } from '../../SwitchNodeSheet/SwitchForm/JsNodeSheet/JsNodeSheet';
import { PopoverContainerStyled } from './PathBlock.styled';
import { PropertyLauncher } from './PropertyLauncher';

type PropertySelectionProps = {
  name: string;
  conditionName: string;
  pathIndex: number;
  setValue: UseFormSetValue<any>;
  watch: UseFormWatch<any>;
  control?: Control<any>;
  updatedDataSet: Record<string, Dataset>;
  dropdownDataSet: Record<string, Dataset>;
  dataSetLHS?: Record<string, Dataset>;
  conditionId: string;
};

export function PropertySelection({
  name,
  conditionName,
  control,
  setValue,
  watch,
  updatedDataSet,
  pathIndex,
  conditionId,
  dropdownDataSet,
  dataSetLHS = {},
}: PropertySelectionProps) {
  const [isWorkflowReadOnly] = useAtom(isWorkflowReadOnlyAtom);

  const { openWithProps: openJsSheet } = useLayer(
    <JsNodeSheet handleChangeValue={setValue} index={pathIndex} />
  );

  const { openWithProps: openExcelModal } = useLayer(
    <ExcelFormulaModal handleChangeValue={setValue} index={pathIndex} />
  );

  const ref = useRef<PopoverMethods>(null);
  const { fieldState } = useController({
    control,
    name,
  });

  const currentProperty = watch(name);
  const currentCondition = watch(conditionName);

  const handleChangeProperty = (onRuleClickArgs: OnClickRuleArgs) => {
    const path = watch(`paths.${pathIndex}`);

    const condition = path[conditionId];

    if (
      (currentProperty?.dataType === onRuleClickArgs.dataType ||
        currentProperty?.dataType === '') &&
      onRuleClickArgs.key !== 'custom'
    ) {
      setValue(`${conditionName}.dataType`, onRuleClickArgs.dataType);
      setValue(`${name}.attribute`, onRuleClickArgs.value);
      setValue(`${name}.sourceType`, onRuleClickArgs.key);
      setValue(`${name}.dataType`, onRuleClickArgs.dataType);
      setValue(`${name}.nodeType`, currentProperty.nodeType);
      setValue(`${name}.value`, null);
    } else if (
      currentProperty?.dataType !== onRuleClickArgs.dataType &&
      onRuleClickArgs.key !== 'custom'
    ) {
      setValue(`${conditionName}.dataType`, onRuleClickArgs.dataType);
      setValue(`${conditionName}.operator`, 'any');

      currentCondition.rightNode.forEach((id: string) => {
        setValue(`paths.${pathIndex}.${id}`, undefined);
      });

      setValue(`${conditionName}.rightNode`, []);

      setValue(`${name}.attribute`, onRuleClickArgs.value);
      setValue(`${name}.sourceType`, onRuleClickArgs.key);
      setValue(`${name}.dataType`, onRuleClickArgs.dataType);
      setValue(`${name}.nodeType`, currentProperty.nodeType);
      setValue(`${name}.value`, null);
    } else if (
      onRuleClickArgs.key === 'custom' &&
      currentCondition?.nodeType !== onRuleClickArgs.dataType
    ) {
      condition?.leftNode?.forEach((id: string) => {
        path[id] = undefined;
      });

      condition?.rightNode?.forEach((id: string) => {
        path[id] = undefined;
      });

      path[conditionId].query = '1===1';
      path[conditionId].name = 'Untitled';
      path[conditionId].nodeType = onRuleClickArgs.dataType;
      path[conditionId].leftNode = [];
      path[conditionId].rightNode = [];

      setValue(`paths.${pathIndex}`, path);

      if (onRuleClickArgs.dataType === 'excelCondition') {
        openExcelModal({
          control,
          updatedDataset: updatedDataSet,
          nodeName: `paths.${pathIndex}.${conditionId}`,
        });
      } else if (onRuleClickArgs.dataType === 'jsCondition') {
        openJsSheet({
          control,
          updatedDataset: updatedDataSet,
          nodeName: `paths.${pathIndex}.${conditionId}`,
        });
      }
    }

    ref.current?.hide();
  };

  return (
    <PopoverPanel
      trigger="click"
      placement="bottom-start"
      launcher={
        <PropertyLauncher
          id={name}
          error={fieldState?.error?.message ?? null}
          text={currentProperty?.attribute}
          propertyValue={''}
          dataType={currentCondition?.dataType ?? currentProperty?.dataType}
        />
      }
      disabled={isWorkflowReadOnly}
      padding="8px"
      ref={ref}
    >
      <PopoverContainerStyled>
        <RulePopover
          dataset={dataSetLHS ?? dropdownDataSet}
          onClick={handleChangeProperty}
          allowList
          typesToAllow={allConditionTypes}
          version="v2"
        />
      </PopoverContainerStyled>
    </PopoverPanel>
  );
}
