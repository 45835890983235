import type { ObservableQuery } from '@apollo/client';
import { Inline, PadBox, Stack } from '@bedrock-layout/primitives';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  ExpandingTextField,
  Sheet,
  TextField,
  TooltipReact,
  Typography,
  toasts,
  useCurrentLayer,
} from 'ui';

import { siteConstantsAtom } from '../../../../atom';
import { HowToLink } from '../../../../components/HowToLink/HowToLink';
import { StackAsItem } from '../../../../components/layouts/Stack.styled';
import type {
  SavedAuthConfigKeys,
  SavedAuthConfigKeysResult,
} from '../../../../types';
import {
  getTooltipText,
  handleGetCheckSumByEntityName,
  showGraphQlErrorToast,
} from '../../../../utils/common';
import { KEY_REGEX } from '../../../../utils/regex';
import { maxFiftyCharactersRule } from '../../../../utils/validation';
import { useSaveAuthConfigKey } from '../hooks/useSaveAuthConfigKeys';
import { useUpdateAuthConfigKey } from '../hooks/useUpdateAuthConfigKey';
import { keySchema } from '../schema/schema';
import {
  AddAuthenticationConfigSheetForm,
  PillStyled,
  SaveButtonContainer,
} from './AuthenticationConfig.styled';

type ApiKeySheetProps = {
  authKey?: SavedAuthConfigKeys;
  refetch?: ObservableQuery<SavedAuthConfigKeysResult>['refetch'];
};

export function ApiKeySheet({ authKey, refetch }: ApiKeySheetProps) {
  const { close } = useCurrentLayer();
  const [siteConstants] = useAtom(siteConstantsAtom);

  const { control, handleSubmit, getValues } = useForm<any>({
    mode: 'onChange',
    defaultValues: {
      name: !_isNil(authKey) ? authKey.name : 'Untitled',
    },
    resolver: zodResolver(keySchema),
  });

  const [saveAuthConfigKey] = useSaveAuthConfigKey();
  const [updateAuthConfigKey] = useUpdateAuthConfigKey();

  const saveAuthKey = async (data: any) => {
    const { name } = data;

    if (!_isNil(authKey) && !_isNil(authKey.id)) {
      try {
        const checksum = handleGetCheckSumByEntityName('credentials');

        const { key, value } = getValues();
        await updateAuthConfigKey({
          variables: {
            id: authKey?.id,
            checksum: checksum ?? '',
            name,
            value: {
              key,
              value,
            },
          },
        });
        close();
        toasts.success('Auth Key Updated', 'success');
      } catch (error: unknown) {
        showGraphQlErrorToast(error);
      }
    } else {
      try {
        const { key, value } = getValues();
        await saveAuthConfigKey({
          variables: {
            name,
            type: 'API',
            authType: 'API_KEY',
            value: {
              key,
              value,
            },
          },
        });
        close();
        toasts.success('Key saved', 'success');
      } catch (error: unknown) {
        showGraphQlErrorToast(error);
      }
    }
  };

  useEffect(() => {
    return () => {
      if (typeof refetch === 'function') {
        void refetch();
      }
    };
  }, []);

  return (
    <Sheet size="small">
      <Stack
        as={AddAuthenticationConfigSheetForm}
        onSubmit={handleSubmit(saveAuthKey)}
      >
        <StackAsItem as={PadBox} padding="1rem" grow={1}>
          <Stack gutter="2rem">
            <Inline stretch="start" align="center">
              <Inline align="center" gutter="0.8rem">
                <Typography name="heading2">
                  <ExpandingTextField control={control} name="name" />
                </Typography>

                <PillStyled padding={['0.2rem', '1rem']}>
                  <Typography name="paragraphSmall">API Key</Typography>
                </PillStyled>
              </Inline>

              <HowToLink
                link={getTooltipText(
                  siteConstants,
                  'credentials',
                  'apiKeyHowTo',
                  'howToLinks'
                )}
              />
            </Inline>

            <Stack gutter="1.2rem">
              <Stack gutter="0.5rem">
                <Inline align="center">
                  <Typography fontWeight={700}>Key</Typography>
                  <TooltipReact id="api-key-name">
                    <Typography>
                      {getTooltipText(siteConstants, 'credentials', 'key')}
                    </Typography>
                  </TooltipReact>
                </Inline>
                <TextField
                  name="key"
                  control={control}
                  defaultValue={
                    !_isNil(authKey) ? authKey.value.key : 'API_KEY'
                  }
                  rules={{
                    pattern: {
                      value: KEY_REGEX,
                      message: 'Invalid key name',
                    },
                    maxLength: maxFiftyCharactersRule,
                    required: 'Field is required',
                  }}
                />
              </Stack>

              <Stack gutter="0.5rem">
                <Inline align="center">
                  <Typography fontWeight={700}>Value</Typography>

                  <TooltipReact id="api-value-name">
                    <Typography>
                      {getTooltipText(siteConstants, 'credentials', 'apiValue')}
                    </Typography>
                  </TooltipReact>
                </Inline>
                <TextField
                  defaultValue={!_isNil(authKey) ? authKey.value.value : ''}
                  name="value"
                  control={control}
                  rules={{
                    required: 'Field is required',
                  }}
                />
              </Stack>
            </Stack>
          </Stack>
        </StackAsItem>

        <SaveButtonContainer padding="1rem">
          <Inline justify="end">
            <Button type="submit">Submit</Button>
          </Inline>
        </SaveButtonContainer>
      </Stack>
    </Sheet>
  );
}
