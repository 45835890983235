import { useState } from 'react';

import { TreeBuilder } from './TreeBuilder';

export type JsonHeroTreeViewerProps = {
  json: Record<string, any>;
  maxDepth?: number;
};

export function JsonHeroTreeViewer({
  json,
  maxDepth = 6,
}: JsonHeroTreeViewerProps) {
  const [expandedState, setExpandedState] = useState<Record<string, boolean>>({
    root: true,
  });

  return (
    <TreeBuilder
      dataset={json}
      value={json}
      currKey={'root'}
      depth={0}
      maxDepth={maxDepth}
      expandedState={expandedState}
      appendedKey={'root'}
      setExpandedState={setExpandedState}
    />
  );
}
