import type { DecisionTablePolicy, RuleConfigType } from '../types';

export const ruleTypeConfig: Record<string, RuleConfigType> = {
  decisionTable: {
    label: 'Decision Table',
    value: 'decisionTable',
    description: ` A decison table are a bunch of AND conditions grouped in table like view having various outputs or actions for different group conditions being true. Typically used in lead scording, allocation, dynamic payouts, discount etc,`,
  },
  simpleRule: {
    label: 'Simple Rule',
    value: 'simpleRule',
    description: ` A Simple rule are a bunch of if/else conditions having results/actions based on the whole rule being true or false.Typically used in customer segmentations, simple plan allocation, referral bonus payout etc.`,
  },
  ruleSet: {
    label: 'Rule Set',
    value: 'ruleSet',
    description: `A ruleset is a collection of rules to be processed all at once.Typically used in complex plan allocation, user scoring etc.`,
  },
};

export const decisionTablePolicies: DecisionTablePolicy[] = [
  {
    label: 'First',
    value: 'first',
    desc: 'Multiple rules can be satisfied. The decision table result contains only the output of the first satisfied rule in the order listed.',
  },
  {
    label: 'Unique',
    value: 'unique',
    desc: 'Only a single rule can be satisfied or no rule at all. The decision table result contains the output entries of the satisfied rule.',
  },
  {
    label: 'Collect',
    value: 'collect',
    desc: 'Multiple rules can be satisfied. The decision table result contains the output of all satisfied rules in an arbitrary order as a list.',
  },
  {
    label: 'Order',
    value: 'order',
    desc: 'Multiple rules can be satisfied. The decision table result contains the output of all satisfied rules in the order of the rules in the decision table.',
  },
  {
    label: 'Evaluate All',
    value: 'evaluteAll',
    desc: 'All rules are executed.The decision table result contains the output entries of all satisfied, unsatisfied and the rules with error in the order of the rule specified in the decision table.',
  },
];
