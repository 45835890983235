import { gql, useMutation } from '@apollo/client';

const rollbackEntityMutation = gql`
  mutation rollbackEntity(
    $id: String!
    $entityType: EntityTypes!
    $commitId: String
    $action: RollBackAction!
    $checksum: String!
    $title: String
    $description: String
    $comment: String
  ) {
    rollbackEntity(
      id: $id
      entityType: $entityType
      commitId: $commitId
      action: $action
      checksum: $checksum
      title: $title
      description: $description
      comment: $comment
    ) {
      entityId
      status
      checksum
      message
    }
  }
`;

export function useRollbackEntity() {
  return useMutation<Record<string, any>>(rollbackEntityMutation);
}
