import { useMutation } from '@tanstack/react-query';
import type { AxiosError, AxiosResponse } from 'axios';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { type RefObject, useEffect } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { toasts } from 'ui';

import { checksumWarningAtom } from '../../../atom';
import { useAxiosPrivate } from '../../../hooks';
import {
  checksumMessage,
  getResponseErrorMessage,
  handleGetCheckSumByEntityName,
  handleSetCheckSumByEntityName,
  showErrorToast,
} from '../../../utils/common';
import type { CustomAxiosError } from '../../../utils/response/types';
import { DataSetModel } from '../models';
import type {
  CreateDataSetFormValues,
  UpdateDataSetStatusFunction,
} from '../types';
import { modifyTestDataResponse } from '../utils';
import { useGetDatasetAfterTest } from './useGetDataSetAfterTest';

type TestDataSetRequest = {
  id: string;
  checksum: string;
};

export type FieldsValues = {
  name: string;
  type: string;
};

export type ColumnValues = Record<string, any>;

export type TestDataSetOutputValues = {
  fields: Record<string, any>;
  rows: ColumnValues[];
  executionTime: string;
};

type TestDataSetOutput = {
  message: string;
  data: TestDataSetOutputValues;
  checksum: string;
};

export type TestDataSetFunction = (
  ref: RefObject<HTMLDivElement>
) => Promise<void>;

type UseTestDataSetReturn = {
  testDataSet: TestDataSetFunction;
  loading: boolean;
  data?: TestDataSetOutputValues;
  error: AxiosError<CustomAxiosError, any>;
};

export function useTestDataSet(
  dataSetId: string,
  updateDataSetStatus: UpdateDataSetStatusFunction,
  datasetObj: DataSetModel | undefined,
  pluginName: string,
  setValue?: UseFormSetValue<CreateDataSetFormValues>
): UseTestDataSetReturn {
  const { axiosPrivate } = useAxiosPrivate();
  const [, setShowChecksumPopup] = useAtom(checksumWarningAtom);

  const [
    getDatasetAfterTest,
    { data: datasetDataAfterTest, loading: getDatasetLoading },
  ] = useGetDatasetAfterTest();

  const {
    isLoading: loading,
    data,
    error,
    mutateAsync: testDataSetMutator,
  } = useMutation({
    mutationFn: async (request: TestDataSetRequest) => {
      const response: AxiosResponse<TestDataSetOutput> =
        await axiosPrivate.post(
          `/integration/data-set/${dataSetId}/execute`,
          request
        );

      return response;
    },
  });

  const testDataSet = async (ref: RefObject<HTMLDivElement>) => {
    try {
      const { data } = await testDataSetMutator({
        id: dataSetId,
        checksum: handleGetCheckSumByEntityName('datasets') ?? '',
      });

      if (!_isNil(data.checksum)) {
        handleSetCheckSumByEntityName('datasets', data.checksum);
      }

      void getDatasetAfterTest({
        variables: {
          id: dataSetId,
        },
      });

      updateDataSetStatus(true);

      toasts.success(data.message, 'tested-success');
    } catch (error: unknown) {
      showErrorToast(error as AxiosError<CustomAxiosError>);

      if (error instanceof Error) {
        if (
          getResponseErrorMessage(
            error as AxiosError<CustomAxiosError>
          ).includes(checksumMessage)
        ) {
          setShowChecksumPopup({
            showPopup: true,
            metaData: {
              datasetId: datasetObj?.id,
              datasetName: datasetObj?.name,
              type: datasetObj?.connector?.plugin.name,
            },
          });
        }
      }
    } finally {
      setTimeout(() => {
        ref.current?.scrollIntoView({
          block: 'nearest',
          behavior: 'smooth',
        });
      }, 0);
    }
  };

  useEffect(() => {
    const data = datasetDataAfterTest?.getDataSet?.data[0];

    if (!_isNil(data) && !getDatasetLoading) {
      handleSetCheckSumByEntityName('datasets', data.checksum);

      if (!_isNil(setValue)) {
        setValue('status', data.status);
      }
    }
  }, [datasetDataAfterTest, getDatasetLoading]);

  return {
    testDataSet,
    loading,
    data:
      pluginName === 'gsheet' && !_isNil(data?.data?.data)
        ? {
            fields: data?.data?.data.fields ?? [],
            executionTime: data?.data?.data.executionTime ?? '',
            rows: modifyTestDataResponse(
              data?.data?.data.fields,
              data?.data?.data.rows ?? []
            ),
          }
        : data?.data?.data,
    error: error as AxiosError<CustomAxiosError, any>,
  };
}
