import { Inline } from '@bedrock-layout/primitives';
import styled from 'styled-components';

export const PillContainer = styled(Inline)`
  background-color: var(--color-solitudeSecondary);
  padding: 0.5rem 0.8rem;
  border-radius: 8px;
  position: relative;
`;

export const ErrorIcon = styled.div`
  position: absolute;
  top: -1rem;
  right: -1rem;
`;
