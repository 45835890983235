import type { FetchResult } from '@apollo/client';
import { gql, useMutation } from '@apollo/client';

import { Environment } from '../../../../utils/constant';
import type { RuleEnvironment } from '../../../../utils/constant';
import type {
  CreateConnectorResult,
  IntegrationFormValues,
  SortedPluginParamsFieldKeys,
} from '../../types';
import { parseFormData } from '../connector/utils';

const createStagingConnectorQuery = gql`
  mutation createConnector(
    $category: String!
    $config: Map!
    $isSource: Boolean!
    $name: String!
    $pluginId: String!
    $environment: Environment!
  ) {
    createConnector(
      input: {
        category: $category
        isSource: $isSource
        name: $name
        pluginId: $pluginId
        stagingConf: $config
      }
      environment: $environment
    ) {
      id
      name
      checksum
      staging {
        conf
      }
    }
  }
`;

const createProductionConnectorQuery = gql`
  mutation createConnector(
    $category: String!
    $config: Map!
    $isSource: Boolean!
    $name: String!
    $pluginId: String!
    $environment: Environment!
  ) {
    createConnector(
      input: {
        category: $category
        isSource: $isSource
        name: $name
        pluginId: $pluginId
        productionConf: $config
      }
      environment: $environment
    ) {
      id
      name
      checksum
      production {
        conf
      }
    }
  }
`;

type CreateConnectorVariables = {
  category: string;
  config: Record<string, any>;
  isSource: boolean;
  name: string;
  pluginId: string;
  environment: RuleEnvironment;
};

export type CreateConnectorFunction = (
  formValues: unknown
) => Promise<FetchResult<CreateConnectorResult>>;

export type UseCreateConnectorReturn = {
  loading: boolean;
  createConnector: CreateConnectorFunction;
};

export function useCreateConnector(
  environment: RuleEnvironment,
  pluginId: string,
  sortedFields: SortedPluginParamsFieldKeys[],
  isSource: boolean,
  category: string
): UseCreateConnectorReturn {
  const [createConnectorMutator, { loading }] = useMutation<
    CreateConnectorResult,
    CreateConnectorVariables
  >(
    environment === Environment.STAGING
      ? createStagingConnectorQuery
      : createProductionConnectorQuery
  );

  const createConnector = async (formValues: unknown) => {
    const { name, ...rest } = formValues as Required<IntegrationFormValues>;

    return await createConnectorMutator({
      variables: {
        category,
        isSource,
        name,
        pluginId,
        environment,
        config: {
          ...parseFormData(sortedFields, rest),
        },
      },
    });
  };

  return {
    loading,
    createConnector,
  };
}
