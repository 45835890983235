import { useMemo } from 'react';
import { Control } from 'react-hook-form';

import { getNodeIdsSortedBySiblingIndex } from '../../utils/common';
import { RuleBlock } from './RuleBlock/RuleBlock';
import { SimpleRuleNodesModel } from './models';

type RuleBlockBuilderProps = {
  ruleId: string;
  rules: Record<string, SimpleRuleNodesModel>;
  control?: Control<any>;
};

export const RuleBlockBuilder = ({
  ruleId,
  rules,
  control,
}: RuleBlockBuilderProps) => {
  const sortedNodesBySiblingIndex = useMemo(
    () => getNodeIdsSortedBySiblingIndex(rules, ruleId),
    [rules]
  );

  return (
    <RuleBlock
      ruleId={ruleId}
      rule={rules[ruleId]}
      key={ruleId}
      control={control}
    >
      {sortedNodesBySiblingIndex.map((child) => (
        <RuleBlockBuilder
          key={child}
          ruleId={child}
          rules={rules}
          control={control}
        />
      ))}
    </RuleBlock>
  );
};
