import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { toasts } from 'ui';

import { usedConnectorMappingAtom } from '../atom';
import { UsedConnectorMappingInEntityType } from '../types';
import { getUpdatedUsedConnectorsMapping } from '../utils/common';
import { useGetMappedConnectorsById } from './graphql/useGetMappedConnectorsData';

export function useUpdateMappedConnectorsData(updateConnMapping = true) {
  const [usedConnectorMapping, setUsedConnectorMapping] = useAtom(
    usedConnectorMappingAtom
  );

  const [isLoading, setIsLoading] = useState(false);

  const [getMappedConnectorsData, { data: mappedConnectorsData }] =
    useGetMappedConnectorsById();

  useEffect(() => {
    if (!_isNil(mappedConnectorsData) && updateConnMapping) {
      if (!_isNil(usedConnectorMapping)) {
        const updatedUsedConnectorMapping = getUpdatedUsedConnectorsMapping(
          mappedConnectorsData,
          usedConnectorMapping
        );

        setUsedConnectorMapping(updatedUsedConnectorMapping);
      }
    }
  }, [mappedConnectorsData]);

  const fetchMappedConnectorsData = async (
    currentUsedConnectors?: UsedConnectorMappingInEntityType
  ) => {
    try {
      setIsLoading(true);

      const connectorMapping = currentUsedConnectors ?? usedConnectorMapping;

      if (!_isNil(connectorMapping)) {
        const mappedIds: string[] = [];

        Object.keys(connectorMapping).forEach((key: string) => {
          mappedIds.push(key);
        });

        const response = await getMappedConnectorsData({
          variables: {
            filters: {
              in: {
                entityId: mappedIds,
              },
            },
          },
          fetchPolicy: 'no-cache',
        });

        setIsLoading(false);

        return response?.data;
      }
    } catch (err) {
      if (err instanceof Error) {
        toasts.error(err.message, 'connectors-status');
      }
    }

    setIsLoading(false);

    return null;
  };

  return {
    fetchMappedConnectorsData,
    mappedConnectorsData,
    isLoading,
  };
}
