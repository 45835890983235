import { Inline, PadBox, Stack } from '@bedrock-layout/primitives';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _map from 'lodash/map';
import _reduce from 'lodash/reduce';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import {
  Button,
  Dataset,
  ExpandingTextField,
  NectedEditorField,
  NectedSuggestionModel,
  Sheet,
  TooltipReact,
  Typography,
  toasts,
  useCurrentLayer,
} from 'ui';

import { siteConstantsAtom } from '../../../../../atom';
import { customAttributesAtom } from '../../../../../components/rules/forms/CustomAttributeSheet/CustomAttributeSheet';
import {
  getTooltipText,
  isCorrectJsSyntaxV2,
} from '../../../../../utils/common';
import { editorDomain } from '../../../../../utils/constant';
import { useGenerateDataset } from '../../../../Workflow/hooks/useGenerateDataset';
import { useUpdateExecutedValueRules } from '../../../hooks/useUpdateExecutedValueRules';
import { isRuleReadOnlyAtom, selectedDataSetAtom } from '../../../index';
import { updateDataSetOnChange } from '../../../utils/common';
import { dataSetParamsAtom } from '../../CreateRuleSheet/CreateRuleSheet';
import { SyntaxErrorContainer } from '../../RuleComponents/RuleComponents.styled';
import { simpleRuleNodeId } from '../../SimpleRule/RulePopovers/RuleParamPopover';
import { SqlEditorContainer } from '../RuleBlock/RuleSqlCondition.styled';
import { simpleRuleNodesAtom } from '../SimpleRule';
import { SqlNodeModel } from '../models';
import { sqlNodeScheme } from '../schema';
import { FooterStyled, NodeContainerStyled } from './JsNodeSheet.styled';

export type JsNodeSheetProps = {
  ruleId: string;
};

export function JsNodeSheet({ ruleId }: JsNodeSheetProps) {
  const [ruleList, setRuleList] = useAtom(simpleRuleNodesAtom);
  const { close: closeSqlNode } = useCurrentLayer();
  const [dataSetVariables] = useAtom(dataSetParamsAtom);
  const [, setDataSetTokens] = useState<string[]>([]);
  const [suggestionObjs, setSuggestionObjs] = useState<NectedSuggestionModel[]>(
    []
  );
  const [isReadOnly] = useAtom(isRuleReadOnlyAtom);
  const [siteConstants] = useAtom(siteConstantsAtom);
  const [customAttributes] = useAtom(customAttributesAtom);
  const [dataSetSelected] = useAtom(selectedDataSetAtom);

  const [isQueryValidNected, setIsQueryValidNected] = useState(true);
  const [isReturnTypeRequired, setIsReturnTypeRequired] = useState(false);
  const [returnType, setReturnType] = useState<string | null>(null);

  const [updatedDataset, setUpdatedDataset] = useState<Record<string, Dataset>>(
    {}
  );

  const [, setSelectedRuleId] = useAtom(simpleRuleNodeId);

  const { tokens } = useGenerateDataset({
    updatedDataset,
  });

  const { executedValue: execValues, handleGetExecutionValues } =
    useUpdateExecutedValueRules({
      updatedDataset,
    });

  const { control, handleSubmit, watch } = useForm<SqlNodeModel>({
    resolver: zodResolver(sqlNodeScheme),
    defaultValues: {
      sqlNodeName:
        _isEmpty(ruleList[ruleId].name) || _isNil(ruleList[ruleId].name)
          ? 'Untitled'
          : ruleList[ruleId].name,
      query: ruleList[ruleId].query,
    },
    mode: 'onSubmit',
  });

  const jsCode = useWatch({
    name: 'query',
    control,
  });

  const updatedQuery = watch('query');

  const onSubmit = (data: SqlNodeModel) => {
    if (!isQueryValidNected) {
      toasts.error(
        'You still have some errors in the editor. Please resolve to proceed',
        'error'
      );

      return;
    }

    setIsReturnTypeRequired(true);
  };

  useEffect(() => {
    setReturnType('');
  }, [updatedQuery]);

  useEffect(() => {
    if (
      !_isNil(returnType) &&
      returnType !== 'undefined' &&
      !_isEmpty(returnType)
    ) {
      setRuleList((prev) => ({
        ...prev,
        [ruleId]: {
          ...prev[ruleId],
          name: watch('sqlNodeName'),
          query: watch('query'),
        },
      }));

      closeSqlNode();
    }
  }, [returnType]);

  useEffect(() => {
    setSuggestionObjs(tokens);
  }, [JSON.stringify(tokens)]);

  useEffect(() => {
    if (!_isNil(dataSetVariables)) {
      const dataSetSuggestions = _reduce(
        dataSetVariables,
        (result: string[], value, key) => {
          if (!_isNil(value.attributes)) {
            return [
              ...result,
              ..._map(value.attributes, (attributeValue, attributeKey) => {
                if (
                  ['string', 'dateTime', 'date'].includes(
                    attributeValue.dataType
                  )
                ) {
                  return `"<<${key}.${attributeKey}>>"`;
                }

                return `<<${key}.${attributeKey}>>`;
              }),
            ];
          }

          return result;
        },
        []
      );

      setUpdatedDataset(
        updateDataSetOnChange(
          customAttributes,
          dataSetVariables,
          dataSetSelected
        )
      );

      setDataSetTokens([...dataSetSuggestions]);
    }
  }, [dataSetVariables]);

  useEffect(() => {
    return () => {
      setSelectedRuleId('');
    };
  }, []);

  return (
    <Sheet size="medium">
      <form onSubmit={handleSubmit(onSubmit)}>
        <NodeContainerStyled gutter="1rem">
          <PadBox padding="1rem">
            <Stack gutter={8}>
              <Inline align="start" gutter={16}>
                <Typography name="heading2">
                  <ExpandingTextField
                    control={control}
                    name="sqlNodeName"
                    disabled={isReadOnly}
                  />
                </Typography>
              </Inline>

              <Inline gutter={8} align="center">
                <Typography>Add Query</Typography>
                <TooltipReact id="js-condition-type">
                  <Typography>
                    {getTooltipText(siteConstants, 'rules', 'customJSRule')}
                  </Typography>
                </TooltipReact>
              </Inline>
              <SqlEditorContainer $readOnly={isReadOnly}>
                <NectedEditorField
                  name="query"
                  control={control}
                  mode="js"
                  customSuggestions={suggestionObjs}
                  readOnly={isReadOnly}
                  onSetEditorValidity={setIsQueryValidNected}
                  setReturnType={(type) => {
                    if (type === 'boolean') {
                      setReturnType(type);
                    } else {
                      setReturnType('undefined');
                    }
                    setIsReturnTypeRequired(false);
                  }}
                  sendReturnType={isReturnTypeRequired}
                  domain={editorDomain}
                  execValues={execValues}
                  handleGetExecData={handleGetExecutionValues}
                />

                {!isCorrectJsSyntaxV2(jsCode, updatedDataset).status && (
                  <SyntaxErrorContainer padding="1rem">
                    <Typography>
                      {isCorrectJsSyntaxV2(jsCode, updatedDataset).message}
                    </Typography>
                  </SyntaxErrorContainer>
                )}

                {returnType === 'undefined' && (
                  <SyntaxErrorContainer padding="1rem">
                    <Typography>
                      The last statement must be a boolean expression
                    </Typography>
                  </SyntaxErrorContainer>
                )}
              </SqlEditorContainer>
            </Stack>
          </PadBox>

          <FooterStyled padding={[4, 8]}>
            <Inline justify="end">
              <Button type="submit" appearance="filled" disabled={isReadOnly}>
                Save
              </Button>
            </Inline>
          </FooterStyled>
        </NodeContainerStyled>
      </form>
    </Sheet>
  );
}
