import { z } from 'zod';

export const jsNodeSchema = z.object({
  nodeName: z
    .string()
    .min(1, 'Node name cannot be empty')
    .max(50, 'Node name cannot be more than 50 characters'),
  nodeQuery: z.string(),
});

export const jsNodeResultQuery = z.object({
  nodeQuery: z.string(),
});

export const ruleEnviornmentConfigSchema = z
  .object({
    startDate: z.nullable(z.date()),
    endDate: z.nullable(z.date()),
    api: z.string(),
    isEnabled: z.boolean(),
    authType: z.nullable(
      z.object({
        label: z.string(),
        value: z.string(),
      })
    ),
    isApiEnabled: z.boolean(),
  })
  .refine(
    (configData) => {
      if (configData.endDate != null && configData.startDate != null) {
        const startDate = new Date(configData.startDate);
        const endDate = new Date(configData.endDate);

        return startDate <= endDate;
      }

      return true;
    },
    {
      path: ['endDate'],
      message: 'End Date must be greater than or equal to start date',
    }
  );
