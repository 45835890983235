import { Inline, Stack } from '@bedrock-layout/primitives';
import { Control } from 'react-hook-form';
import { TextField, Typography } from 'ui';

type SchedulerCronProps = {
  control: Control<any>;
};
export function SchedulerCron({ control }: SchedulerCronProps) {
  return (
    <Stack>
      <Inline>
        <Stack gutter="0.5rem">
          <Typography fontWeight={700}>Min</Typography>
          <TextField
            size="small"
            heightFull
            name="cron.minutes"
            control={control}
          />
        </Stack>

        <Stack gutter="0.5rem">
          <Typography fontWeight={700}>Hours</Typography>
          <TextField
            size="small"
            heightFull
            name="cron.hours"
            control={control}
          />
        </Stack>

        <Stack gutter="0.5rem">
          <Typography fontWeight={700}>Month Days</Typography>
          <TextField
            size="small"
            heightFull
            name="cron.days"
            control={control}
          />
        </Stack>
      </Inline>

      <Inline>
        <Stack gutter="0.5rem">
          <Typography fontWeight={700}>Day of week</Typography>
          <TextField
            size="small"
            heightFull
            name="cron.weekdays"
            control={control}
          />
        </Stack>

        <Stack gutter="0.5rem">
          <Typography fontWeight={700}>Month</Typography>
          <TextField
            size="small"
            heightFull
            name="cron.month"
            control={control}
          />
        </Stack>
      </Inline>
    </Stack>
  );
}
