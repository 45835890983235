import _isEmpty from 'lodash/isEmpty';
import { BiUser } from 'react-icons/bi';
import { Typography } from 'ui';

import type { ProfileModel } from '../../../../types';
import { ProfileIconContainer } from './ProfileIcon.styled';

type ProfileIconProps = {
  profile: ProfileModel;
};

export const ProfileIcon = ({ profile }: ProfileIconProps) => {
  const { firstName = '' } = profile;

  return (
    <ProfileIconContainer align="center" justify="center">
      {_isEmpty(firstName) ? (
        <BiUser />
      ) : (
        <Typography>{firstName[0]?.toUpperCase()}</Typography>
      )}
    </ProfileIconContainer>
  );
};
