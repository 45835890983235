import { PadBox } from '@bedrock-layout/padbox';
import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { useLayer } from 'ui';

import { userProfileAtom } from '../../atom';
import { Header } from '../../components/Header';
import { VerifyEmailPopup } from '../../components/VerifyEmailPopup/VerifyEmailPopup';
import { isUerAllowedToAccessProtectedRoutes } from './utils';

export function EmailNotVerified() {
  const [userProfile] = useAtom(userProfileAtom);

  const { open: openPluginFormSheet } = useLayer(<VerifyEmailPopup />);

  const isUserAllowedToAccessRoutes =
    isUerAllowedToAccessProtectedRoutes(userProfile);

  useEffect(() => {
    if (!isUserAllowedToAccessRoutes) {
      openPluginFormSheet();
    }
  }, [isUserAllowedToAccessRoutes]);

  return (
    <PadBox padding={['3.2rem', '4.8rem']}>
      <Header content={<div></div>} />
    </PadBox>
  );
}
