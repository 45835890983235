import { gql, useLazyQuery } from '@apollo/client';

import { emptyQuery } from '../../../../utils/constant';

const getCrendentialQuery = gql`
  query getCredentials($id: String!) {
    getCredentials(id: $id) {
      data {
        id
        name
        checksum
      }
    }
  }
`;

const getQueryBasedonMethod = (endpoint: string) => {
  // Dont use aliasing while writing the query
  switch (endpoint) {
    case 'getCredentials':
      return getCrendentialQuery;
    default:
      return emptyQuery;
  }
};

export function useGetDependentEntityData(endpoint: string) {
  return useLazyQuery<Record<string, any>, Record<string, any>>(
    getQueryBasedonMethod(endpoint)
  );
}
