import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import dateFormat from 'dateformat';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useMemo, useState } from 'react';
import { BiArrowBack } from 'react-icons/bi';
import { Expander, NectedEditor, Typography } from 'ui';

import { getTableColumns } from '../../../components/Listing/components/getTableColums';
import { ListingStatusPill } from '../../../components/Listing/listing.styled';
import { mapToObjectArray } from '../../../components/Listing/utils';
import { NewTable } from '../../../components/table/NewTable';
import { useAxiosPrivate } from '../../../hooks';
import { editorDomain } from '../../../utils/constant';
import {
  DetailsGoBackButton,
  DetailsTableContainer,
  IOContainer,
  IOWrapper,
  LogIdText,
  LogsDetailContainer,
  MessageText,
} from '../auditlogs.styled';
import type {
  LogDetailsProps,
  RenderLogDetailsProps,
  TableHeaderProps,
} from '../types';

export const RenderLogDetails = ({
  logDetails,
  handleGoBack,
  handleRowClick,
  handleCellClick,
  fetchPaginatedLogDetails,
  entity,
}: RenderLogDetailsProps) => {
  const [tableHeaders, setTableHeaders] = useState<TableHeaderProps[]>([]);
  const logDetailsObj: LogDetailsProps = logDetails;
  const detailObj = logDetailsObj?.details.detail ?? null;
  const { axiosPrivate } = useAxiosPrivate();
  useEffect(() => {
    if (
      _isNil(logDetailsObj?.tableHeaders) ||
      _isEmpty(logDetailsObj?.tableHeaders)
    ) {
      void getAllFilters();
    } else {
      setTableHeaders(logDetailsObj?.tableHeaders ?? []);
    }
  }, [JSON.stringify(logDetailsObj)]);

  const getAllFilters = async () => {
    const configResponse = await axiosPrivate.get(`/common/schema/audit`);

    const updatedListingDetails = {
      tableHeaders: mapToObjectArray(
        configResponse.data.data.headers
      ) as unknown as TableHeaderProps[],
    };

    if (!_isNil(logDetailsObj)) {
      logDetailsObj.tableHeaders = updatedListingDetails.tableHeaders;
      logDetailsObj.allFilters = configResponse.data.data.filters;
      logDetailsObj.endpoint = configResponse.data.data.endpoints;
    }
    setTableHeaders(updatedListingDetails.tableHeaders);
  };

  const onPageChange = (pageSize: number, pageNumber: number) => {
    if (pageNumber !== logDetailsObj?.details.pageNo) {
      fetchPaginatedLogDetails(pageNumber);
    }
  };

  const showInputOutput =
    !_isNil(detailObj) &&
    ((!_isNil(detailObj.input) && !_isEmpty(detailObj.input)) ||
      (!_isNil(detailObj.output) && !_isEmpty(detailObj.output)));

  const statusByType = useMemo(() => {
    const currentTime = new Date();
    const message = detailObj?.message ?? '';

    const lastMessage = message.split(' ')[message.split(' ').length - 1];
    let delayTime: Date | null = null;

    try {
      delayTime = new Date(lastMessage);
    } catch (error) {
      delayTime = currentTime;
    }

    if (_isNil(detailObj)) {
      return null;
    } else if (
      detailObj.type.toLowerCase() === 'delay' &&
      detailObj.status.toLowerCase() === 'success' &&
      !_isNil(delayTime) &&
      currentTime < delayTime
    ) {
      return (
        <Typography>
          <ListingStatusPill status="scheduled">Scheduled</ListingStatusPill>
        </Typography>
      );
    }

    return (
      <Typography>
        <ListingStatusPill status={detailObj.status}>
          {detailObj.status}
        </ListingStatusPill>
        {detailObj.statusTimeMs > 0 &&
          detailObj.type.toLowerCase() !== 'delay' && (
            <ListingStatusPill status={detailObj.status}>
              {`${detailObj.statusTimeMs} ms`}
            </ListingStatusPill>
          )}
      </Typography>
    );
  }, [JSON.stringify(detailObj)]);

  return (
    <>
      <DetailsGoBackButton onClick={handleGoBack}>
        {' '}
        <BiArrowBack /> Back
      </DetailsGoBackButton>
      {!_isNil(logDetailsObj) && !_isNil(detailObj) && (
        <LogsDetailContainer>
          <Inline gutter={'size4'}>
            <Typography name="heading3" fontWeight={700}>
              {detailObj.name}
            </Typography>
            {statusByType}
          </Inline>
          <Inline
            gutter={'size12'}
            style={{
              flexWrap: 'wrap',
              rowGap: '2rem',
            }}
          >
            <Stack gutter={'1.2rem'}>
              <Typography name="heading4" fontWeight={700}>
                Date
              </Typography>
              <Typography>
                {dateFormat(
                  new Date(detailObj.date ?? null),
                  "h:MM TT	 'on' dd/mm/yyyy"
                )}
              </Typography>
            </Stack>
            <Stack gutter={'1.2rem'}>
              <Typography name="heading4" fontWeight={700}>
                Type
              </Typography>
              <Typography>{detailObj.type}</Typography>
            </Stack>
            <Stack gutter={'1.2rem'}>
              <Typography name="heading4" fontWeight={700}>
                Version
              </Typography>
              <Typography>{detailObj.version}</Typography>
            </Stack>
            <Stack gutter={'1.2rem'}>
              <Typography name="heading4" fontWeight={700}>
                Event
              </Typography>
              <Typography>{detailObj.event}</Typography>
            </Stack>
            <Stack gutter={'1.2rem'}>
              <Typography name="heading4" fontWeight={700}>
                Mode
              </Typography>
              <Typography>{detailObj.environment ?? '-'}</Typography>
            </Stack>
            <Stack gutter={'1.2rem'}>
              <Typography name="heading4" fontWeight={700}>
                User
              </Typography>
              <Typography>
                {!_isEmpty(detailObj.user) ? detailObj.user : '-'}
                &nbsp;{' '}
                {!_isNil(detailObj.signed)
                  ? detailObj.signed
                    ? '(Signed URL)'
                    : ''
                  : ''}
              </Typography>
            </Stack>
            <Stack gutter={'1.2rem'}>
              <Typography name="heading4" fontWeight={700}>
                IP
              </Typography>
              <Typography>
                {!_isEmpty(detailObj.ip) ? detailObj.ip : '-'}
              </Typography>
            </Stack>
            <Stack gutter={'1.2rem'}>
              <Typography name="heading4" fontWeight={700}>
                ID
              </Typography>
              <LogIdText>
                {!_isEmpty(detailObj.executionId)
                  ? detailObj.executionId
                  : detailObj.entityId}
              </LogIdText>
            </Stack>
          </Inline>
          <Stack gutter={'1.2rem'}>
            <Typography name="heading4" fontWeight={700}>
              Message
            </Typography>
            <MessageText status={detailObj.status}>
              {!_isEmpty(detailObj.message) ? detailObj.message : '-'}
            </MessageText>
          </Stack>

          {showInputOutput && (
            <Expander
              title="Input & Output"
              fontWeight={700}
              name="heading4"
              as={IOWrapper}
              childrenWidth={100}
            >
              <Inline gutter={'1rem'}>
                <IOContainer>
                  <Typography fontWeight={700}>Input</Typography>
                  <NectedEditor
                    readOnly
                    defaultValue={JSON.stringify(
                      detailObj.input ?? {},
                      null,
                      '\t'
                    )}
                    mode="json"
                    domain={editorDomain}
                  />
                </IOContainer>
                <IOContainer>
                  <Typography fontWeight={700}>Output</Typography>
                  <NectedEditor
                    readOnly
                    defaultValue={JSON.stringify(
                      detailObj.output ?? {},
                      null,
                      '\t'
                    )}
                    mode="json"
                    domain={editorDomain}
                  />
                </IOContainer>
              </Inline>
            </Expander>
          )}

          {logDetailsObj.details.parent.length > 0 && (
            <Stack>
              <Typography name="heading4" fontWeight={700}>
                Parent
              </Typography>
              <DetailsTableContainer>
                <NewTable
                  entity={entity}
                  columns={getTableColumns(entity, {
                    tableHeaders,
                  })}
                  data={logDetailsObj?.details.parent}
                  totalPages={1}
                  currentPage={0}
                  onRowClicked={(row) => {
                    handleRowClick(row, logDetailsObj);
                  }}
                  onCellClicked={(cell, index, listType) => {
                    handleCellClick(cell, index, listType, logDetailsObj);
                  }}
                  listType="SUBTABLE"
                />
              </DetailsTableContainer>
            </Stack>
          )}
          {logDetailsObj.details.children.length > 0 && (
            <Stack>
              <Typography name="heading4" fontWeight={700}>
                Children
              </Typography>
              <DetailsTableContainer>
                <NewTable
                  entity={entity}
                  columns={getTableColumns(entity, {
                    tableHeaders,
                  })}
                  data={logDetailsObj?.details.children}
                  totalPages={logDetailsObj?.details.totalPages}
                  currentPage={0}
                  onRowClicked={(row) => {
                    handleRowClick(row, logDetailsObj);
                  }}
                  onCellClicked={(cell, index, listType) => {
                    handleCellClick(cell, index, listType, logDetailsObj);
                  }}
                  listType="SUBTABLE"
                  onPageChange={onPageChange}
                />
              </DetailsTableContainer>
            </Stack>
          )}
        </LogsDetailContainer>
      )}
    </>
  );
};
