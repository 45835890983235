import { Inline } from '@bedrock-layout/primitives';
import type { UseControllerProps } from 'react-hook-form';
import { CheckboxField, Typography } from 'ui';

import type { PluginFieldCommonProps } from '../../../../types';

type PluginBooleanFieldProps = UseControllerProps<any> & PluginFieldCommonProps;

export function PluginBooleanField({
  control,
  label,
  value = false,
  name,
  disabled = false,
}: PluginBooleanFieldProps) {
  return (
    <Inline gutter=".8rem" align="center">
      <CheckboxField
        defaultValue={value}
        name={name}
        useId={name}
        control={control}
        disabled={disabled}
      />
      <Typography>{label}</Typography>
    </Inline>
  );
}
