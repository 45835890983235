export type GSActionProps = {
  type: 'POPUP' | 'LINK' | 'METHOD';
  name: string;
  target?: string;
  href?: string;
};

export type GSContentProps = {
  title: string;
  icon: string;
  blockType: string;
  styles: Record<string, string>;
  id: string;
  action?: GSActionProps;
};

export type GettingStartedContentBlockProps = Record<string, GSContentProps[]>;

export const entityTitles: Record<string, string> = {
  rules: 'Get started with Rules',
};

export const gettingStartedContentBlock: GettingStartedContentBlockProps = {
  rules: [
    {
      title: 'Start from template',
      id: 'start_from_template',
      icon: 'home/home_rule.svg',
      blockType: 'RULE_TEMPLATE_SELECTOR',
      styles: {
        'background-color': '#FCF8FF',
        'border-radius': '0.8rem',
        padding: '1.5rem',
        gap: '1.5rem',
      },
      action: {
        type: 'POPUP',
        name: 'OPEN_RULE_TEMPLATE',
      },
    },
    {
      title: 'Learn About Nected',
      id: 'learn_about_nected',
      icon: 'home/home_learn.svg',
      blockType: 'LEARN_NECTED_POPUP',
      styles: {
        'background-color': '#FAFCFF',
        'border-radius': '0.8rem',
        padding: '1.5rem',
        gap: '1.5rem',
      },
      action: {
        type: 'POPUP',
        name: 'OPEN_VIDEO_POPUP',
      },
    },
    {
      title: 'I need assistance',
      id: 'i_need_assistance',
      icon: 'home/home_assist.svg',
      blockType: 'RULE_TEMPLATE_SELECTOR',
      styles: {
        'background-color': '#FAFCFF',
        'border-radius': '0.8rem',
        padding: '1.5rem',
        gap: '1.5rem',
      },
    },
  ],
};
