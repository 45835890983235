import { z } from 'zod';

import { translations } from '../../../../translations/en-US';
import { KEY_REGEX } from '../../../../utils/regex';

export const attributesSchema = z.object({
  name: z
    .string()
    .min(1, 'Key name cannot be empty')
    .max(50, translations['validation.max_characters'])
    .regex(
      KEY_REGEX,
      'Attribute name can contain alphabets, numbers, and underscore'
    ),
  dataType: z.nullable(
    z.object(
      {
        label: z.string(),
        value: z.string(),
      },
      {
        invalid_type_error: 'Please select a value',
      }
    )
  ),
  isList: z.boolean(),
  isNullable: z.boolean(),
  isOptional: z.boolean(),
  isCaseSensitive: z.boolean(),
  sourceType: z.optional(z.string()),
  attribute: z.optional(z.string()),
  testValue: z.optional(z.string()),
  selectedType: z.nullable(
    z.object(
      {
        key: z.string(),
        value: z.string(),
        dataType: z.string(),
      },
      {
        invalid_type_error: 'Please select a value',
      }
    )
  ),
  executedValue: z.any(),
  sampleValue: z.any(),
  config: z.any(),
});
