export const initialOnboardingData = {
  createRule: {
    heading: 'Create a rule using a template or from Scratch',
    subHeading: '',
    isCompleted: false,
    buttonName: 'Explore',
    path: '/rules',
    enable: false,
    id: 'createRule',
    subTasks: [
      {
        id: 'add_custom_attributes',
        heading: 'Add Custom Input attributes - including List, JSON types',
        isCompleted: false,
        path: '/rules',
      },
      {
        id: 'add_conditions_and_result',
        heading: 'Define conditions and result in no-code editor',
        isCompleted: false,
        path: '/rules',
      },
      {
        id: 'test_and_publish_rule',
        heading: 'Test in Staging and publish your rule to production',
        isCompleted: false,
        path: '/rules',
      },
      {
        id: 'configure_api_scheduler',
        heading:
          'Configure API or Scheduler in Rule Settings to Trigger the rule from within your code/system(s)',
        isCompleted: false,
        path: '/rules',
      },
    ],
    subId: [''],
  },
  connectDatasource: {
    heading:
      'Feed data directly from your data source (database and/or API) to the rule (optional)',
    subHeading: '',
    isCompleted: false,
    buttonName: 'Connect',
    path: '/datasets',
    enable: false,
    id: 'connectDatasource',
    subId: [''],
    subTasks: [
      {
        id: 'integrations_added',
        heading: 'Connect with your database or or an API in Integrations',
        isCompleted: false,
        path: '/integrations',
      },
      {
        id: 'configure_data_source_query_in_case_of_database',
        heading:
          'Configure data source query in case of database (to selectively pick up data points to be used in rule)',
        isCompleted: false,
        path: '/datasets',
      },
      {
        id: 'map_data_source_inside_add_input_attributes',
        heading: 'Map data source inside Add Input Attributes (for database)',
        isCompleted: false,
        path: '/rules',
      },
      {
        id: 'add_attributes_under_fetch_over_api',
        heading:
          'Add attributes under ‘Fetch over API’ to integrate data points from API',
        isCompleted: false,
        path: '/rules',
      },
    ],
  },
  createWorkflow: {
    heading:
      'Create Workflow to define order of things to be executed before/after rule(s)',
    subHeading: '',
    isCompleted: false,
    buttonName: 'Connect',
    path: '/workflow',
    enable: false,
    id: 'createWorkflow',
    subId: [''],
    subTasks: [
      {
        id: 'define_trigger_and_custom_input_attributes',
        heading:
          'Define Trigger and Custom input Attributes - including List, JSON',
        isCompleted: false,
        path: '/workflow',
      },
      {
        id: 'add_action_workflow',
        heading:
          'Add Action Nodes to call up any rule, workflow or to perform any operations over API / Database',
        isCompleted: false,
        path: '/workflow',
      },
      {
        id: 'add_response_vis_response_node',
        heading:
          'Add Response via Response Node, if you need to return any response',
        isCompleted: false,
        path: '/workflow',
      },
      {
        id: 'test_and_publish_workflow',
        heading:
          'Test in staging and publish your workflowConfigure API in trigger settings to trigger the workflow within your code/system(s)',
        isCompleted: false,
        path: '/workflow',
      },
      {
        id: 'try_control_nodes_delay',
        heading:
          'Try Control Nodes - Delay to schedule any sub-flow from within the whole flow',
        isCompleted: false,
        path: '/workflow',
      },
    ],
  },
  advancedFeatures: {
    heading: 'Advanced features for power users',
    subHeading: '',
    isCompleted: false,
    buttonName: 'Create Rule',
    path: '#',
    enable: false,
    id: 'advancedFeatures',
    subId: [''],
    subTasks: [
      {
        id: 'collaborate_with_larger_group',
        heading:
          'Collaborate with larger group: invite your team / setup role-based access',
        isCompleted: false,
        path: '/workspace/team',
      },
      {
        id: 'inbuilt_custom_code',
        heading:
          'In-built custom code to write any complex conditions or for data transformation/manipulations',
        isCompleted: false,
        path: '/rules',
      },
      {
        id: 'explore_global_attributes',
        heading:
          'Explore Global Attributes to be used anywhere in rule / workflow',
        isCompleted: false,
        path: '/remoteconfig',
      },
      {
        id: 'on_premise_script_to_self_host',
        heading: 'On-premise script to self-host nected and test it out',
        isCompleted: false,
        path: '/onpremise',
      },
      {
        id: 'generate_api_key',
        heading:
          'Explore different authentications to connect with secure APIs',
        isCompleted: false,
        path: '/credentials',
      },
      {
        id: 'onboarding_completed',
        heading: 'Complete onboarding questionnaire to personalize home page',
        isCompleted: false,
        path: '/questionnaire?page=0&fromHome=true',
      },
    ],
  },
};
