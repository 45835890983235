import { gql, useMutation } from '@apollo/client';

import { RuleSetResponse } from '../../components/RuleSet/models';

const discardRule = gql`
  mutation discardRule($id: String!, $checksum: String!) {
    discardRule(id: $id, checksum: $checksum) {
      id
      name
      description
      version
      status
      isLive
      workspaceId
      type
      staticUrl
      ruleChain
      ruleSet
      ruleSetPolicy
      firstRuleChain
      checksum
      warnings
      customInput
      settings {
        startAt
        endAt
        isEnabled
        auditIO
        cache {
          duration
          enabled
          cacheKeys
        }
        api {
          isEnabled
          isPrivate
          authId
        }
        schedule {
          startAt
          endAt
          inputParam
          unit
          spec {
            minute
            hour
            dayOfWeek
            dayOfMonth
            month
          }
          timezone
          isEnabled
        }
      }
      approvalInfo {
        title
        description
        approvers
      }
      versionInfo {
        currentVersion
        currentLiveVersion
        lastVersion
      }
      dependencyMap {
        id
        name
        type
        version
        commitTitle
        subType
        nodeId
      }
    }
  }
`;

export type DiscardRuleSetResponse = {
  discardRule: RuleSetResponse;
};

export function useDiscardRuleSet() {
  return useMutation<
    DiscardRuleSetResponse,
    {
      id: string;
      checksum: string;
    }
  >(discardRule);
}
