import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import styled from 'styled-components';

export const ListContainer = styled(Stack)`
  block-size: 14rem;
  overflow-y: auto;
`;

export const ListItem = styled(Inline)`
  padding: 0.5rem 0.2rem;
  border-radius: 0.3rem;
  cursor: pointer;

  &:hover {
    background-color: var(--color-lightGray);
  }
`;

export const CreateConnectorStyled = styled(Inline)`
  background-color: var(--color-white);
  cursor: pointer;
  color: var(--color-primary1);
`;
