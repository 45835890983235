import type { Control } from 'react-hook-form';
import { SheetSize, useLayer } from 'ui';

import type { ResultType } from '../components/SimpleRule/Results';
import { JsonNodeSheet } from '../components/SimpleRule/Results/JsonNodeSheet';

export type UseOpenJsonEditorProps = {
  name?: string;
  type: string;
  control?: Control<any>;
  index?: number;
  section?: ResultType;
  size?: SheetSize;
  isJsonInCustomInput?: boolean;
  disabled?: boolean;
  from?: string;
};

export function useOpenJsonEditorSheet({
  name = 'string',
  control,
  index = 0,
  section = 'thenDataParams',
  size,
  isJsonInCustomInput,
  type,
  disabled = false,
  from,
}: UseOpenJsonEditorProps) {
  return useLayer(
    <JsonNodeSheet
      type={type}
      name={name}
      control={control}
      index={index}
      section={section}
      size={size}
      isJsonInCustomInput={isJsonInCustomInput}
      disabled={disabled}
      from={from}
    />
  );
}
