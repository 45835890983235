import styled from 'styled-components';
import { Stack } from '@bedrock-layout/stack';
import { Inline } from '@bedrock-layout/inline';

export const FormStyled = styled(Stack)`
  height: 100vh;
  overflow-y: auto;
`;

export const Footer = styled(Inline)`
  position: sticky;
  bottom: 0;
  border-block-start: 1px solid var(--color-solitude);
  inset-inline-start: 0;
  z-index: 1;
  background-color: var(--color-white);
`;
