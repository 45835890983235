import { gql, useLazyQuery } from '@apollo/client';

import type { PluginModel } from '../../pages/Integrations/components/plugin/Plugin';

type PluginResultModel = {
  getPlugin: {
    data: PluginModel[];
  };
};

type UseGetPluginsArgs = {
  isSource?: boolean;
};

export function useGetPlugins({ isSource = true }: UseGetPluginsArgs) {
  const getPluginsQuery = gql`
    query getPlugin {
      getPlugin${isSource ? `(filter: { isSource: true })` : ``} {
        data {
          id
          name
          displayName
          imageUrl
          category
          checksum
        }
      }
    }
  `;

  return useLazyQuery<PluginResultModel>(getPluginsQuery);
}
