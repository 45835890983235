import { Center, Stack } from '@bedrock-layout/primitives';
import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { MdArrowBack } from 'react-icons/md';
import { Button, Image, Link, TextField, Typography } from 'ui';
import { z } from 'zod';

import { generateCaptchaToken, getPublicIP } from '../../../utils/common';
import { emailValidationSchema } from '../../../utils/schema';
import { FormContainer } from './ForgetPassword.styled';
import { useForgotPassword } from './useForgotPassword';

/* eslint-disable-next-line import/no-absolute-path */
import lockKey from '/assets/konark/images/lock-key.svg';

const FormValidationSchema = z.object({
  ...emailValidationSchema,
});

type ForgotPasswordFormValues = {
  email: string;
};

export const ForgotPasswordForm = () => {
  const { forgotPassword } = useForgotPassword();

  const { control, handleSubmit } = useForm<ForgotPasswordFormValues>({
    resolver: zodResolver(FormValidationSchema),
    defaultValues: {
      email: '',
    },
  });

  useEffect(() => {
    void getPublicIP();
  });

  const onSubmit = ({ email }: ForgotPasswordFormValues) => {
    generateCaptchaToken(forgotPassword, { email });
  };

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <Stack gutter="4rem">
        <Center centerChildren>
          <Stack gutter="2.4rem">
            <Center centerChildren>
              <Image
                size="self"
                src={lockKey}
                alt="forgot password lock-key icon"
              />
            </Center>

            <Center centerChildren>
              <Typography name="heading1" element="h1">
                Forgot password?
              </Typography>

              <Typography name="secondary" element="h2">
                No worries, we’ll send you reset instructions.
              </Typography>
            </Center>
          </Stack>
        </Center>

        <Stack gutter="2.4rem">
          <TextField label="Email" name="email" control={control} />

          <Stack gutter="1.6rem">
            <Button size="large" type="submit">
              Submit
            </Button>

            <Center centerChildren>
              <Link iconLeading={<MdArrowBack />} to="/signin">
                Back to Log in
              </Link>
            </Center>
          </Stack>
        </Stack>
      </Stack>
    </FormContainer>
  );
};
