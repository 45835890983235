export const createUUID = () => {
  try {
    return crypto.randomUUID();
  } catch (error) {
    return generateUUID();
  }
};

const generateUUID = () => {
  const hexChars = '0123456789abcdef';
  let uuid = '';
  for (let i = 0; i < 32; i++) {
    const randomIndex = Math.floor(Math.random() * 16);
    uuid += hexChars[randomIndex];
  }
  uuid =
    uuid.slice(0, 8) +
    '-' +
    uuid.slice(8, 12) +
    '-' +
    uuid.slice(12, 16) +
    '-' +
    uuid.slice(16, 20) +
    '-' +
    uuid.slice(20);

  return uuid;
};
