import { gql, useMutation } from '@apollo/client';

const deleteDataSetQuery = gql`
  mutation deleteDataSet($id: String!, $checksum: String!) {
    deleteDataSet(id: $id, checksum: $checksum)
  }
`;

type DeleteDataSetResult = {
  data: {
    deleteDataSet: boolean;
  };
};

export function useDeleteDataSet() {
  return useMutation<DeleteDataSetResult, { id: string; checksum: string }>(
    deleteDataSetQuery
  );
}
