import { Inline } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import { CheckboxField, Dataset, TooltipReact, Typography } from 'ui';

import { ResultRhs } from '../../../../../Rules/components/RestltRhs/ResultRhs';
import { isWorkflowReadOnlyAtom } from '../../../../atoms/atoms';
import { RunInLoopType } from '../../../../models/models';
import { RunInLoopContainer } from './RunInLoop.styled';

type RunInLoopProps = {
  control?: Control<any>;
  name: string;
  setValue: UseFormSetValue<any>;
  updatedDataSet: Record<string, Dataset>;
  localData?: any;
};

export function RunInLoop({
  control,
  name,
  setValue,
  updatedDataSet,
  localData,
}: RunInLoopProps) {
  const [isWorkflowReadOnly] = useAtom(isWorkflowReadOnlyAtom);

  const runInLoop: RunInLoopType | undefined = useWatch({
    name,
    control,
  });

  const isActive = runInLoop?.status ?? false;
  const { loop, ...rest } = updatedDataSet;

  const allowRunInLoop = localData.nodeType !== 'ruleSetNode';

  const RunInLoopComponent = () => {
    return (
      <Inline align="center" gutter="1rem">
        <Typography>Run In Loop</Typography>

        <CheckboxField
          name={`${name}.status`}
          useId={`${name}.status`}
          control={control}
          disabled={isWorkflowReadOnly || !allowRunInLoop}
        />
      </Inline>
    );
  };

  return (
    <RunInLoopContainer>
      {allowRunInLoop ? (
        <RunInLoopComponent />
      ) : (
        <TooltipReact
          id="tooltip-run-in-loop"
          launcher={<RunInLoopComponent />}
        >
          <Typography>Run In Loop isn&apos;t supported in RuleSet</Typography>
        </TooltipReact>
      )}

      <ResultRhs
        control={control}
        setValue={setValue}
        updatedDataSet={rest}
        nodeName={'runInLoop'}
        dataType={'list'}
        isFull
        typesToAllow={['list']}
        showExpandIconIfList={false}
        allowList
        type="workflowDT"
        size="40.8rem"
        disabled={!isActive || isWorkflowReadOnly}
        showTooltip={true}
        onSelect={() => {
          setValue('runInLoop.status', true);
        }}
      />
    </RunInLoopContainer>
  );
}
