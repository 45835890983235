import { gql, useMutation } from '@apollo/client';

const approveEntityMutation = gql`
  mutation approve(
    $id: String!
    $entityType: EntityTypes!
    $comment: String!
    $checksum: String!
  ) {
    approve(
      id: $id
      entityType: $entityType
      comment: $comment
      checksum: $checksum
    ) {
      status
      checksum
      message
    }
  }
`;

export function useApproveEntity() {
  return useMutation<Record<string, any>>(approveEntityMutation);
}
