import { Inline } from '@bedrock-layout/primitives';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useRef } from 'react';
import { VscTriangleDown } from 'react-icons/vsc';
import {
  Dataset,
  Menu,
  MenuItem,
  PopoverMethods,
  PopoverPanel,
  RulePopover,
  Typography,
} from 'ui';

import {
  CorrectValues,
  removeCustomFunction,
} from '../../pages/Rules/utils/common';
import { DurationLauncher } from './DurationLauncher';
import {
  ContainerStyled,
  DurationInputContainer,
} from './RelativeDateField.styled';
import { opOptions, unitOptions } from './constant';

type RelativeDateFieldsProps = {
  isDisabled?: boolean;
  value: Record<string, any>;
  dataset: Record<string, Dataset>;
  durationFieldError?: string | null;
  onClick?: (key: string, value: any) => void;
  updateOnChangeDurationFieldError?: (obj: CorrectValues) => void;
};

export const RelativeDateFields = ({
  isDisabled = false,
  value,
  durationFieldError,
  dataset,
  onClick,
  updateOnChangeDurationFieldError,
}: RelativeDateFieldsProps) => {
  const ref = useRef<PopoverMethods>(null);
  const menuOpRef = useRef<PopoverMethods>(null);
  const menuUnitRef = useRef<PopoverMethods>(null);

  const { subOp, unit, duration } = value;

  const Launcher = (text?: string) => {
    return (
      <ContainerStyled padding={[3, 8]}>
        <Inline stretch={1} align="center">
          <Typography>
            {!_isNil(text) && !_isEmpty(text) ? text : '-'}
          </Typography>
          <VscTriangleDown size={12} />
        </Inline>
      </ContainerStyled>
    );
  };

  return (
    <Inline>
      <Menu
        launcher={Launcher(
          opOptions.find((currentOp) => currentOp.value === subOp)?.label
        )}
        ref={menuOpRef}
        disabled={isDisabled}
        onMenuItemClick={(value) => {
          if (typeof onClick === 'function') {
            onClick('subOp', value);
          }

          menuOpRef.current?.hide();
        }}
      >
        {opOptions.map((currentOp) => (
          <MenuItem
            key={currentOp.value}
            disabled={isDisabled}
            value={currentOp.value}
          >
            <Inline>
              <Typography>{currentOp.label}</Typography>
            </Inline>
          </MenuItem>
        ))}
      </Menu>

      <PopoverPanel
        trigger="click"
        placement="bottom-start"
        disabled={isDisabled}
        launcher={
          <span>
            <DurationLauncher
              isDisabled={isDisabled}
              value={duration}
              dataset={dataset}
              onClick={onClick}
              error={durationFieldError}
              updateOnChangeDurationFieldError={
                updateOnChangeDurationFieldError
              }
            />
          </span>
        }
        ref={ref}
        padding="8px"
      >
        <DurationInputContainer>
          <RulePopover
            dataset={removeCustomFunction(dataset)}
            allowList={true}
            version="v2"
            typesToAllow={['numeric']}
            onClick={({ value: id, key, dataType: selectedDataType }) => {
              if (typeof onClick === 'function') {
                onClick('duration', {
                  attribute: id,
                  sourceType: key,
                  selectedDataType,
                });
              }

              ref.current?.hide();
            }}
          />
        </DurationInputContainer>
      </PopoverPanel>

      <Menu
        launcher={Launcher(
          unitOptions.find((currentUnit) => currentUnit.value === unit)?.label
        )}
        ref={menuUnitRef}
        disabled={isDisabled}
        onMenuItemClick={(value) => {
          if (typeof onClick === 'function') {
            onClick('unit', value);
          }

          menuUnitRef.current?.hide();
        }}
      >
        {unitOptions.map((currentUnit) => (
          <MenuItem
            key={currentUnit.value}
            disabled={isDisabled}
            value={currentUnit.value}
          >
            <Inline>
              <Typography>{currentUnit.label}</Typography>
            </Inline>
          </MenuItem>
        ))}
      </Menu>
    </Inline>
  );
};
