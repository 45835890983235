import type { Control } from 'react-hook-form';
import { DateTimePickerField } from 'ui';

import type { RemoteConfigFormValues } from '../../RemoteConfig';

type RemoteConfigDateFieldProps = {
  control: Control<RemoteConfigFormValues>;
  name: string;
  value: Date;
  disabled: boolean;
};

export function RemoteConfigDateField({
  control,
  name,
  value,
  disabled,
}: RemoteConfigDateFieldProps) {
  return (
    <DateTimePickerField
      control={control}
      name={name}
      defaultValue={value}
      rules={{
        required: 'Field is required',
      }}
      showCustomInput
      showTimeInput={false}
      placeholderText="Enter date"
    />
  );
}
