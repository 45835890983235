import styled from 'styled-components';

export const EditorPanelContainer = styled.div`
  overflow: auto;
  block-size: inherit;
  inline-size: 62%;
  display: flex;
  flex: 1;
  border-inline-end: 1px solid var(--color-darkGray);
  position: relative;

  @media screen and (min-width: 1500px) {
    inline-size: 65%;
  }
`;

export const RulesContainerStyled = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const SidePanelContainer = styled.div`
  block-size: inherit;
  inline-size: 5%;
  max-inline-size: 4.5rem;
  min-inline-size: 5.5rem;
`;
