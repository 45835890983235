import { PadBox } from '@bedrock-layout/padbox';
import styled from 'styled-components';

export const CreateActionContainerStyled = styled(PadBox)`
  inline-size: 30rem;
  max-block-size: 30rem;
  overflow-y: auto;
  background-color: var(--color-white);
  border-radius: 4px;
`;
