import { ErrorPopover } from './ErrorPopover';
import { PositionedErrorContainer } from './ErrorPopoverPositioned.styled';

type ErrorPopoverPositionedProps = {
  error: string;
};

export function ErrorPopoverPositioned({ error }: ErrorPopoverPositionedProps) {
  return (
    <PositionedErrorContainer>
      <ErrorPopover error={error} />
    </PositionedErrorContainer>
  );
}
