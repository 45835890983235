import { Inline } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import { useRef } from 'react';
import { HiDotsVertical } from 'react-icons/hi';
import { useEdges, useNodes } from 'reactflow';
import { PopoverMethods, PopoverPanel, TextButton, toasts } from 'ui';

import { isWorkflowReadOnlyAtom } from '../../../../atoms/atoms';
import { getDirectParentsSkippingType } from '../../../../utils/common';

type DelayActionProps = {
  id: string;
  data: Record<string, any>;
};

export function DelayAction({ id, data }: DelayActionProps) {
  const ref = useRef<PopoverMethods>(null);
  const nodes = useNodes();
  const edges = useEdges();
  const [isWorkflowReadOnly] = useAtom(isWorkflowReadOnlyAtom);

  const checkIfParentResponse = () => {
    const parents = getDirectParentsSkippingType(nodes, edges, id, 'addNode');

    return parents.every((node) => node.type === 'responseNode');
  };

  const onDeleteClick = () => {
    if (checkIfParentResponse()) {
      return toasts.warning(
        'Please delete the parent response node',
        'delete-parent-response'
      );
    }

    data.deleteNode({
      id,
      type: data.nodeType ?? 'delayNode',
    });

    ref.current?.hide();
  };

  return (
    <PopoverPanel
      ref={ref}
      trigger="click"
      launcher={
        <Inline>
          <HiDotsVertical />
        </Inline>
      }
    >
      <TextButton disabled={isWorkflowReadOnly} onClick={onDeleteClick}>
        Delete
      </TextButton>
    </PopoverPanel>
  );
}
