import { Stack } from '@bedrock-layout/stack';
import { JsonHeroTreeViewer, NectedEditor } from 'ui';

import { copyToClipboard } from '../../../utils/common';
import { envMap } from '../../../utils/constant';
import { ExecutionView } from './ExecutionView';
import {
  JsonContainer,
  JsonOutputContainer,
  JsonTreeContainer,
} from './RuleExecutionOutput.styled';

type RuleExecutionOutputProps = {
  executionTime: string;
  responseJson: string;
  isTesting: boolean;
  forceStatus?: boolean;
};

export const RuleExecutionOutput = ({
  executionTime,
  responseJson = '',
  isTesting,
  forceStatus,
}: RuleExecutionOutputProps) => {
  let val: any = responseJson;

  try {
    val = JSON.parse(val);
  } catch {}

  return (
    <JsonOutputContainer padding="1.6rem">
      <Stack gutter={8}>
        <ExecutionView
          onCopy={() => {
            void copyToClipboard(responseJson, 'Key copied');
          }}
        >
          {!isTesting && (
            <JsonContainer>
              <NectedEditor
                domain={envMap.VITE_EDITOR_URL}
                mode="json"
                readOnly
                defaultValue={responseJson}
              />
            </JsonContainer>
          )}

          {!isTesting && (
            <JsonTreeContainer>
              <JsonHeroTreeViewer json={val} />
            </JsonTreeContainer>
          )}
        </ExecutionView>
      </Stack>
    </JsonOutputContainer>
  );
};
