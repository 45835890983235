import _isEmpty from 'lodash/isEmpty';
import { useController } from 'react-hook-form';
import type { FieldValues, UseControllerProps } from 'react-hook-form';
import { GSheetPicker, GSheetPickerProps } from 'ui';

import { Field } from '../Field';

export type GSheetPickerFieldPropTypes<P extends FieldValues> =
  UseControllerProps<P> & GSheetPickerProps;

export const GSheetPickerField = <P extends FieldValues>(
  props: GSheetPickerFieldPropTypes<P>
) => {
  const { name, control, ...rest } = props;
  const { field, fieldState } = useController({
    name,
    control,
  });

  return (
    <Field
      fieldState={fieldState}
      showError={!_isEmpty(fieldState.error?.message)}
    >
      <GSheetPicker
        hasError={!_isEmpty(fieldState.error?.message)}
        {...field}
        {...rest}
      />
    </Field>
  );
};
