import { Inline, PadBox } from '@bedrock-layout/primitives';
import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { Dataset, useLayer } from 'ui';

import { JsonPillStyled } from '../../../RuleSheet/Selector/Selector.styled';
import { ExcelFormulaModal } from '../ExcelFormulaModal/ExcelFormulaModal';
import { PropertySelectCustom } from './PropertySelectCustom';

type ExcelConditionProps = {
  groupIndex: number;
  conditionId: string;
  control?: Control<any>;
  setValue: UseFormSetValue<any>;
  name: string;
  remove: (index: number) => void;
  watch: UseFormWatch<any>;
  index: number;
  updatedDataSet: Record<string, Dataset>;
  groupKey: string;
};

export function ExcelCondition({
  conditionId,
  groupIndex,
  groupKey,
  index,
  name,
  remove,
  setValue,
  updatedDataSet,
  watch,
  control,
}: ExcelConditionProps) {
  const { openWithProps } = useLayer(
    <ExcelFormulaModal handleChangeValue={setValue} index={index} />
  );

  return (
    <PadBox padding="1rem">
      <Inline gutter="1rem" align="center">
        <PropertySelectCustom
          conditionId={conditionId}
          conditionName={`paths.${groupIndex}.${conditionId}`}
          dataType="Formula"
          name={`paths.${groupIndex}.${conditionId}`}
          pathIndex={groupIndex}
          setValue={setValue}
          text="Formula"
          updatedDataSet={updatedDataSet}
          watch={watch}
          control={control}
        />
        <JsonPillStyled
          onClick={() => {
            openWithProps({
              control,
              updatedDataset: updatedDataSet,
              nodeName: `paths.${groupIndex}.${conditionId}`,
            });
          }}
        >
          Formula
        </JsonPillStyled>
      </Inline>
    </PadBox>
  );
}
