import { PadBox } from '@bedrock-layout/padbox';
import styled from 'styled-components';

export const FooterStyled = styled(PadBox)`
  position: sticky;
  bottom: 0;
  margin-top: auto;
  border-top: 1px solid var(--color-lightGray);
  background-color: var(--color-white);
`;

export const JsonTreeContainer = styled(PadBox)`
  block-size: 40rem;
  overflow-y: auto;
  background-color: var(--color-white);
`;

export const OutputContainer = styled(PadBox)`
  min-block-size: 50rem;
  background-color: var(--color-lightGray6);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
