type AddIconProps = {
  width?: number;
  height?: number;
  color?: string;
};

export function AddIcon(props: AddIconProps) {
  const { width = 16, height = 16, color = 'var(--color-lightGray)' } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none">
      <path
        d="M8.18164 4.36365V12"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M12 8.18188L4.36364 8.18188"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
