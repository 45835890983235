import { Stack } from '@bedrock-layout/stack';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { RxCross2 } from 'react-icons/rx';
// eslint-disable-next-line import/no-named-default
import { default as _ReactPlayer } from 'react-player';
import { ReactPlayerProps } from 'react-player/types/lib';
import {
  IconButton,
  Modal,
  ModalContent,
  ModalFooter,
  Typography,
  useCurrentLayer,
} from 'ui';

import { CustomClose } from '../../components/DemoRulePopup/demoRule.styled';

const ReactPlayer = _ReactPlayer as unknown as React.FC<ReactPlayerProps>;

export type SelectedVideoProps = {
  name: string;
  videoUrl: string;
  time: string;
  documentationUrl: string;
};

export type VidePopupProps = {
  videoObj?: SelectedVideoProps;
};

export const VideoPopup = ({ videoObj }: VidePopupProps) => {
  const { close: closePopup } = useCurrentLayer();
  const customClosePopup = () => {
    closePopup();
  };
  const [selectedVideo, setSelectedVideo] = useState<SelectedVideoProps | null>(
    null
  );

  useEffect(() => {
    if (!_isNil(videoObj)) {
      setSelectedVideo(videoObj);
    }
  }, [videoObj]);

  return (
    <Modal hideCloseButton={true} size="doubleExtraLarge">
      <CustomClose>
        <IconButton onClick={customClosePopup}>
          <RxCross2 size={16} />
        </IconButton>
      </CustomClose>
      <ModalContent>
        <Stack gutter={'2.5rem'}>
          {!_isNil(selectedVideo) && (
            <>
              <Typography name="heading2">{selectedVideo.name}</Typography>
              <Stack gutter={'0.5rem'}>
                <ReactPlayer
                  url={selectedVideo.videoUrl}
                  playing={true}
                  pip
                  controls
                  width={'100%'}
                  height={'546px'}
                  config={{
                    youtube: {
                      playerVars: { showinfo: 1 },
                    },
                  }}
                />
              </Stack>
            </>
          )}
        </Stack>
      </ModalContent>
      <ModalFooter></ModalFooter>
    </Modal>
  );
};
