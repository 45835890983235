import { PadBox } from '@bedrock-layout/padbox';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

import { NectedLogo } from '../logo';

const LogoAbsolute = styled.div`
  display: flex;
  justify-content: flex-start;
  position: absolute;

  @media (max-width: 768px) {
    justify-content: flex-start;
    padding-left: 1.6rem;
  }
`;

const LogoContainer = styled(PadBox)`
  width: 20rem;
`;

export function CoveredLogo() {
  return (
    <>
      <LogoAbsolute>
        <LogoContainer padding={['2rem', '2rem']}>
          <NectedLogo />
        </LogoContainer>
      </LogoAbsolute>
      <Outlet />
    </>
  );
}
