import { Typography } from 'ui';

import { BlueTextStyled, Container } from './EntityAccessBlock.styled';

type EntityAccessBlockProps = {
  isVisible: boolean;
};

export function EntityAccessBlock({ isVisible }: EntityAccessBlockProps) {
  return (
    <Container isVisible={isVisible}>
      <img src="/assets/konark/icons/shield_lock.svg" />
      <BlueTextStyled name="heading2" fontWeight={700}>
        {`You don’t have access to this file`}
      </BlueTextStyled>
      <Typography>
        Ask the owner or admin to provide access to this file
      </Typography>
    </Container>
  );
}
