import { Inline, PadBox, Stack } from '@bedrock-layout/primitives';
import styled from 'styled-components';

export const VideoGuideContainer = styled(Stack)`
  gap: 4rem;
  padding: 4rem;
`;
export const VideoBlockContainer = styled(Inline)`
  gap: 3rem;
  flex-wrap: wrap;
  height: auto;
  margin-top: 1rem;
`;
export const VideoBlock = styled(PadBox)`
  background: var(--color-white);
  display: flex;
  width: calc(25% - 3rem);
  padding: 2.6rem;
  flex-direction: column;
  border-radius: 0.8rem;
  border: 0.1rem solid var(--color-lightGray6);
  gap: 4rem;
  box-shadow: 0px 0px 17px 0px rgba(0, 0, 0, 0.08);
`;

export const VideoButtons = styled.button`
  border: none;
  width: 9rem;
  border-radius: 6px;
  padding: 0.5rem;
  cursor: pointer;
  font-weight: 700;
  display: flex;
  justify-content: space-evenly;
  -webkit-box-align: center;
  align-items: center;
  font-size: 1.4rem;
  background: var(--color-lavender);
  color: #535050;
`;

export const VideoButtonContainer = styled(Inline)`
  align-items: center;
  justify-content: space-between;
`;
