import { Inline } from '@bedrock-layout/primitives';
import styled from 'styled-components';

export const LauncherContainer = styled(Inline)<{ $isEnabled: boolean }>`
  justify-content: space-between;
  gap: 1rem;
  border-radius: 0.4rem;
  padding: 0.8rem;
  cursor: pointer;
  min-inline-size: 11rem;
  align-items: center;
  min-block-size: 3.6rem;

  ${({ $isEnabled }) =>
    $isEnabled
      ? 'border: 1px solid var(--color-black)'
      : 'border: 1px solid var(--color-darkGray)'};
`;

export const OpenEntityLauncher = styled(Inline)`
  justify-content: space-between;
  gap: 1rem;
  border-radius: 0.4rem;
  padding: 0.8rem;
  cursor: pointer;
  min-inline-size: 11rem;
  align-items: center;
  min-block-size: 3.6rem;
  background-color: var(--color-primary2);
  color: var(--color-primary1);
  font-size: 1rem;
  line-height: 1.33;
  font-weight: 400;
`;
