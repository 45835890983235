import { useAtom } from 'jotai';
import _capitalize from 'lodash/capitalize';
import _isNil from 'lodash/isNil';
import { useEffect, useMemo, useState } from 'react';

import { siteConstantsAtom } from '../../../atom';
import { getTooltipText } from '../../../utils/common';
import { getCategoryWiseConnectors, getCategoryWisePlugin } from '../utils';
import { ConnectorsContainer } from './Connectors.styled';
import { IntegrationRow } from './IntegrationRow';
import { Connector } from './connector';
import type { GetConnectorsQueryResult } from './hooks/useGetConnectors';
import { useGetConnectors } from './hooks/useGetConnectors';
import { Plugin } from './plugin/Plugin';

export function Connectors() {
  const [getConnectorList, { data, error, refetch }] = useGetConnectors();

  const [listData, setListData] = useState<
    GetConnectorsQueryResult | undefined
  >();
  const [siteConstants] = useAtom(siteConstantsAtom);

  const categoryTooltipMap: Record<string, string> = {
    database: 'listDatabase',
    api: 'listApi',
    'third-party': 'listThirdParty',
  };

  const categoryWiseIntegrationData = useMemo(() => {
    if (!_isNil(listData)) {
      const pluginsCategories = [
        ...new Set(
          listData.databasePlugins?.data.map((plugin) => plugin.category)
        ),
      ];

      const categoryWisePlugins = getCategoryWisePlugin(
        listData.databasePlugins?.data ?? []
      );

      const categoryWiseConnectors = getCategoryWiseConnectors(
        listData.databaseConnector?.data ?? []
      );

      return pluginsCategories.map((category) => {
        return {
          title: _capitalize(category),
          tooltip: getTooltipText(
            siteConstants,
            'integrations',
            categoryTooltipMap[category]
          ),
          connectors: categoryWiseConnectors[category] ?? [],
          plugins: categoryWisePlugins[category] ?? [],
        };
      });
    }

    return [];
  }, [JSON.stringify(listData), JSON.stringify(siteConstants)]);

  useEffect(() => {
    void getConnectorList();
  }, []);

  useEffect(() => {
    if (!_isNil(data)) setListData(data);
  }, [data]);

  if (_isNil(listData)) {
    return <div>loading</div>;
  }

  if (!_isNil(error) && _isNil(listData)) {
    return <div>error</div>;
  }

  return (
    <ConnectorsContainer>
      {categoryWiseIntegrationData.map(
        ({ title, connectors, plugins, tooltip }) => {
          return (
            <IntegrationRow key={title} title={title} tooltip={tooltip}>
              {connectors.map((connector, index) => (
                <Connector
                  connector={connector}
                  key={index}
                  refetch={refetch}
                />
              ))}
              {plugins.map((plugin, index) => (
                <Plugin plugin={plugin} key={index} refetch={refetch} />
              ))}
            </IntegrationRow>
          );
        }
      )}
    </ConnectorsContainer>
  );
}
