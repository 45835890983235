import { Stack } from '@bedrock-layout/stack';
import { Typography } from 'ui';

type SalutationProps = {
  from: string;
};

export function Salutation({ from }: SalutationProps) {
  return (
    <Stack gutter=".2rem">
      <Typography name="heading1" fontWeight={700} element="h1">
        {from === 'signup' ? 'Sign up for free now!' : 'Sign In'}
      </Typography>

      <Typography name="secondary" element="h2">
        {from === 'signup'
          ? 'No Credit Card Required'
          : 'Continue with your account'}
      </Typography>
    </Stack>
  );
}
