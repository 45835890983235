import { useMutation } from '@tanstack/react-query';
import type { AxiosError, AxiosResponse } from 'axios';

import { useAxiosPrivate } from '../../../hooks';
import type { CustomAxiosError } from '../../../utils/response/types';

export type VerfiyCredentialRequestPayload = {
  code: string;
  state: string;
  scope: string;
};

export function useOAuthVerifyCredential() {
  const { axiosPrivate } = useAxiosPrivate();

  const { mutate: verifyOAuthCredential, isLoading } = useMutation({
    mutationFn: async (request: VerfiyCredentialRequestPayload) => {
      return await axiosPrivate.post('/credential/oauth/verify', request);
    },
    onSuccess(
      response: AxiosResponse,
      requestPayload: VerfiyCredentialRequestPayload
    ) {
      const { data, checksum, code } = response.data;

      const postData = {
        ...data,
        checksum,
        code,
      };

      window.opener?.postMessage(
        {
          data: postData,
          source: 'nectedRedirectWindow',
        },
        '*'
      );
      window.close();
    },
    onError(
      error: AxiosError<CustomAxiosError>,
      requestPayload: VerfiyCredentialRequestPayload
    ) {
      window.opener?.postMessage(
        {
          data: {
            error: error?.response?.data.message ?? error?.message,
          },
          source: 'nectedRedirectWindow',
        },
        '*'
      );
      window.close();
    },
  });

  return {
    verifyOAuthCredential,
    isLoading,
  };
}
