import { gql, useLazyQuery } from '@apollo/client';

const getDataSetByIdQuery = gql`
  query getDataSet($id: String!, $live: Boolean) {
    getDataSet(id: $id, live: $live) {
      data {
        id
        status
        isLive
        checksum
        publishedAt
        versionInfo {
          currentVersion
          currentLiveVersion
          lastVersion
        }
      }
    }
  }
`;

export function useGetDatasetByIdOnPublish() {
  return useLazyQuery(getDataSetByIdQuery, {
    fetchPolicy: 'no-cache',
  });
}
