import { Inline } from '@bedrock-layout/primitives';
import styled, { css } from 'styled-components';

export const StyledTextContainer = styled.div`
  inline-size: 50%;
`;

export const AddFieldStyled = styled(Inline)<{ $disabled?: boolean }>`
  cursor: pointer;
  color: var(--color-primary1);
  background-color: var(--color-white);
  inline-size: 30%;

  ${({ $disabled = false }) =>
    $disabled &&
    css`
      cursor: none;
      color: var(--color-darkGray);
    `}
`;
