import { gql, useMutation } from '@apollo/client';

const deleteConnector = gql`
  mutation deleteConnector($id: String!, $checksum: String!) {
    deleteConnector(id: $id, checksum: $checksum)
  }
`;

type deleteConnectorResult = {
  data: {
    deleteConnector: boolean;
  };
};

export function useDeleteConnector() {
  return useMutation<deleteConnectorResult, { id: string; checksum: string }>(
    deleteConnector
  );
}
