import { Helmet } from 'react-helmet';

import { AuthPagesLayout } from '../../components/authentication';
import { useGetSiteMeta } from '../../hooks/useGetSiteMeta';
import { ForgotPasswordForm } from './components/ForgotPassword';

export const WLForgotPassword = () => {
  const { siteMeta } = useGetSiteMeta();

  return (
    <>
      <Helmet>
        <title>
          {siteMeta[window.location.pathname.substring(1)]?.title ?? ''}
        </title>
        <meta
          name="description"
          content={
            siteMeta[window.location.pathname.substring(1)]?.description ?? ''
          }
        />
      </Helmet>
      <AuthPagesLayout
        isPasswordPage={true}
        form={<ForgotPasswordForm />}
        from="forgotPassword"
      />
    </>
  );
};
