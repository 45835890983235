import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';

import { getEntityTypeForApi } from '../components/Modals/utils/common';
import { useGetListOfVersions } from '../components/VersionControl/hooks/graphql/useGetListOfVersions';
import type { EnvButtonsCount } from '../types';

type useSetVersionCountArgs = {
  id?: string | null;
  entityType: string;
  status?: string;
};

export function useSetVersionCount({
  id,
  entityType,
  status = '',
}: useSetVersionCountArgs) {
  const [versionCount, setVersionCount] = useState<EnvButtonsCount>(() => ({
    live: 0,
    draft: 0,
    inReview: 0,
  }));

  const [
    getListOfVersionsQuery,
    {
      // eslint-disable-next-line
      data: listVersionData,
    },
  ] = useGetListOfVersions(entityType);

  const handleGetInitialData = async () => {
    const payload = {
      id,
      entityType: getEntityTypeForApi(entityType),
      filtersDraft: {
        in: {
          status: ['draft', 'tested'],
        },
      },
      filtersInReview: {
        in: {
          status: ['inreview'],
        },
      },
    };

    await getListOfVersionsQuery({
      variables: payload,
      fetchPolicy: 'no-cache',
    });
  };

  useEffect(() => {
    if (!_isNil(id) && id !== '') {
      void handleGetInitialData();
    }
  }, [id, status]);

  useEffect(() => {
    if (!_isNil(listVersionData)) {
      setVersionCount({
        draft: listVersionData.draftData.paginationInfo.totalRecord,
        inReview: listVersionData.inReviewData.paginationInfo.totalRecord,
        live: listVersionData.liveData.paginationInfo.totalRecord,
      });
    }
  }, [listVersionData]);

  return {
    versionCount,
  };
}
