import { Inline } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _reduce from 'lodash/reduce';
import { useEffect, useMemo } from 'react';
import type { UseControllerProps, UseFormSetValue } from 'react-hook-form';
import { ToolTip, TooltipReact, Typography, toasts } from 'ui';

import { isRuleReadOnlyAtom } from '../../..';
import { PolicyDropdown } from '../../RuleComponents/PolicyDropdown';
import { errorInRuleAtom } from '../../atom/atom';
import { decisionTableErrorConfig } from '../DecisionTable';
import {
  ErrorMessage,
  HeaderConatiner,
  PolicyText,
  RowNumContainer,
  RuleInfoContainer,
} from './DecisionTableHeaders.styled';
import PropertyFieldArray from './PropertyFieldArray';
import ResultFieldArray from './ResultFieldArray';
import { RowActionsContainer } from './RowsFieldArray.styled';

type DecisionTableHeadersProps = Omit<UseControllerProps, 'name'> & {
  setValue: UseFormSetValue<any>;
};

export default function DecisionTableHeaders({
  control,
  setValue,
}: DecisionTableHeadersProps) {
  const [isRuleReadOnly] = useAtom(isRuleReadOnlyAtom);
  const [errorConfigData] = useAtom(decisionTableErrorConfig);

  const [errorInRule] = useAtom(errorInRuleAtom);

  const errorRowsCount = Object.keys(errorConfigData).length;

  useEffect(() => {
    if (errorRowsCount > 0) {
      toasts.error(`Error in ${errorMessage}`, 'error');
    }
  }, [errorRowsCount]);

  const errorMessage = useMemo(() => {
    let error = '';

    error = _reduce(
      errorConfigData,
      (acc: string, value, key) => {
        const uniqueValueSet = new Set(value.sort((a, b) => a - b));

        let rowError = `R:${parseInt(key) + 1} `;
        for (const colIndex of uniqueValueSet) {
          rowError += `C:${colIndex + 1} `;
        }

        acc += rowError + ', ';

        return acc;
      },
      ''
    );

    error.slice(0, -2);

    error +=
      (!_isEmpty(error) ? `, ` : '') +
      _reduce(
        errorInRule,
        (acc, value, key) => {
          if (value) {
            acc += key + ', ';
          }

          return acc;
        },
        ''
      );

    return error.slice(0, -2);
  }, [errorConfigData, errorInRule]);

  return (
    <Inline gutter={0}>
      <RowActionsContainer />

      <HeaderConatiner>
        <RuleInfoContainer align="center">
          <Inline align="center" gutter={'1.6rem'}>
            <Inline align="center">
              <ToolTip message="The policy in which the rules will be processed and output to be shown">
                <PolicyText fontWeight={700}>Rule Policy</PolicyText>
              </ToolTip>

              <PolicyDropdown
                name="rulePolicy"
                rules={{ required: 'Required' }}
                control={control}
                placeholder="Policy"
                showError={false}
                isDisabled={isRuleReadOnly}
              />
            </Inline>

            {!_isEmpty(errorMessage) && (
              <TooltipReact
                id="errorMessage"
                placement="right-start"
                launcher={
                  <Inline align="center" gutter="0.4rem">
                    <Typography name="error">{`Error in`}</Typography>

                    <ErrorMessage name="error" fontWeight={700}>
                      {errorMessage}
                    </ErrorMessage>
                  </Inline>
                }
              >
                {`Error in ${errorMessage}`}
              </TooltipReact>
            )}
          </Inline>
        </RuleInfoContainer>

        <Inline align="stretch" gutter={0}>
          <RowNumContainer />
          <PropertyFieldArray control={control} setValue={setValue} />

          <ResultFieldArray control={control} setValue={setValue} />
        </Inline>
      </HeaderConatiner>
    </Inline>
  );
}
