import { Inline, PadBox } from '@bedrock-layout/primitives';
import _isUndefined from 'lodash/isUndefined';
import { ChangeEvent, useState } from 'react';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { CiSearch } from 'react-icons/ci';
import { Spinner, TextInput, Typography } from 'ui';

import {
  EmptyListContainer,
  FileBodyContainer,
  FileHeader,
  FileItem,
  StyledFileList,
} from './FileList.styled';

export type FileItemType = {
  label: string;
  value: string;
};

type FileListProps = {
  label: string;
  searchEnabled?: boolean;
  icon?: string;
  data: FileItemType[];
  selectedFile?: FileItemType;
  emptyDataMessage?: string;
  isLoading?: boolean;
  paginationEnabled?: boolean;
  handlePagination?: () => void;
  onSearch?: (text: string) => void;
  onFileClick?: (file: FileItemType) => void;
};

export function FileList({
  label,
  searchEnabled = true,
  icon,
  selectedFile,
  data = [],
  emptyDataMessage = '',
  paginationEnabled = true,
  isLoading = false,
  handlePagination = () => {},
  onSearch = () => {},
  onFileClick,
}: FileListProps) {
  const [searchValue, setSearchValue] = useState('');

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const text = e.target.value;
    setSearchValue(text);

    if (!_isUndefined(onSearch)) {
      onSearch(text);
    }
  };

  const srcollRef = useBottomScrollListener(() => {
    if (paginationEnabled) {
      handlePagination();
    }
  });

  return (
    <StyledFileList gutter={'0rem'}>
      <FileHeader align="center">
        <Typography fontWeight={700}>{label}</Typography>
        {searchEnabled && (
          <TextInput
            placeholder="Search"
            value={searchValue}
            startIcon={<CiSearch size={18} color="var(--color-suvaGray)" />}
            onChange={handleSearch}
          />
        )}
      </FileHeader>
      <FileBodyContainer
        ref={srcollRef as React.RefObject<HTMLDivElement>}
        hasData={data.length > 0}
      >
        {data.length > 0 ? (
          <div>
            {data.map((file) => (
              <FileItem
                gutter={'1.5rem'}
                key={file.value}
                fileSelected={
                  !_isUndefined(selectedFile)
                    ? file.value === selectedFile.value
                    : false
                }
                onClick={() => !_isUndefined(onFileClick) && onFileClick(file)}
              >
                {!_isUndefined(icon) && (
                  <div>
                    <img src={icon} alt="file icon" />
                  </div>
                )}
                <Typography>{file.label}</Typography>
              </FileItem>
            ))}
            {isLoading && (
              <Inline
                as={PadBox}
                padding={'1rem'}
                justify="center"
                align="center"
              >
                <Spinner size="extraSmall" />
              </Inline>
            )}
          </div>
        ) : (
          <Inline as={EmptyListContainer} justify="center" align="center">
            {isLoading ? <Spinner size="extraSmall" /> : emptyDataMessage}
          </Inline>
        )}
      </FileBodyContainer>
    </StyledFileList>
  );
}
