import { gql, useLazyQuery } from '@apollo/client';

const workflowByIdQuery = gql`
  query getWorkflow($id: String) {
    getWorkflow(id: $id) {
      data {
        id
        status
        isLive
        publishedAt
        checksum
        versionInfo {
          currentVersion
          currentLiveVersion
          lastVersion
        }
      }
    }
  }
`;

export function useGetWorkflowByIdOnPublish() {
  return useLazyQuery(workflowByIdQuery, {
    fetchPolicy: 'no-cache',
  });
}
