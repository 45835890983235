import _isNil from 'lodash/isNil';
import { CSSProperties } from 'react';
import styled, { css } from 'styled-components';
import theme from 'styled-theming';

import type { FontWeight } from './Typography';

const commonFont = css`
  font-size: 1.4rem;
  line-height: 1.57;
  font-weight: 400;

  @media (max-width: 480px) {
    font-size: 1.2rem;
  }
`;

const smallFont = css`
  font-size: 1.2rem;
  line-height: 1.33;
  font-weight: 400;

  @media (max-width: 480px) {
    font-size: 1rem;
  }
`;

const xsFont = css`
  font-size: 1rem;
  line-height: 1.33;
  font-weight: 400;

  @media (max-width: 480px) {
    font-size: 0.875rem;
  }
`;

const smFont = css`
  font-size: 1.2rem;
  line-height: 1.33;
  font-weight: 400;

  @media (max-width: 480px) {
    font-size: 1rem;
  }
`;

const namedTypography = theme('name', {
  xLarge: css`
    font-size: 4rem;
    line-height: 1.5;
    font-weight: 900;

    @media (max-width: 480px) {
      font-size: 3rem;
    }
  `,
  paragraphXs: css`
    ${xsFont}
  `,
  heading1: css`
    font-size: 2.4rem;
    line-height: 1.5;
    font-weight: 400;

    @media (max-width: 480px) {
      font-size: 2rem;
    }
  `,
  heading2: css`
    font-size: 2rem;
    line-height: 1.5;
    font-weight: 700;

    @media (max-width: 480px) {
      font-size: 1.6rem;
    }
  `,
  heading3: css`
    font-size: 1.6rem;
    line-height: 1.5;
    font-weight: 400;

    @media (max-width: 480px) {
      font-size: 1.4rem;
    }
  `,
  heading4: css`
    ${commonFont}
  `,
  heading5: css`
    ${smallFont}
  `,
  heading6: css`
    font-size: 1rem;
    line-height: 1.6;
    font-weight: 400;

    @media (max-width: 480px) {
      font-size: 0.8rem;
    }
  `,
  paragraph: css`
    ${commonFont}
  `,
  paragraphSmall: css`
    font-size: 1.2rem;
    line-height: 1.57;
    font-weight: 400;

    @media (max-width: 480px) {
      font-size: 1rem;
    }
  `,
  secondary: css`
    font-size: 1.6rem;
    line-height: 1.5;
    font-weight: 400;
    color: var(--color-darkGray);

    @media (max-width: 480px) {
      font-size: 1.4rem;
    }
  `,
  secondarySmall: css`
    font-size: 1.4rem;
    line-height: 1.5;
    font-weight: 400;
    color: var(--color-darkGray);

    @media (max-width: 480px) {
      font-size: 1.2rem;
    }
  `,
  secondarySmallDark: css`
    font-size: 1.4rem;
    line-height: 1.5;
    font-weight: 400;
    color: var(--color-charcoal);

    @media (max-width: 480px) {
      font-size: 1.2rem;
    }
  `,
  secondaryXsDark: css`
    font-size: 1.2rem;
    line-height: 1.5;
    font-weight: 400;
    color: var(--color-charcoal);

    @media (max-width: 480px) {
      font-size: 1.2rem;
    }
  `,
  secondary2: css<{ active: boolean }>`
    ${commonFont};

    color: ${({ active }) =>
      active ? 'var(--color-charcoal)' : 'var(--color-darkGray)'};
  `,
  secondaryXs: css`
    ${xsFont}
    color: var(--color-darkGray);

    @media (max-width: 480px) {
      font-size: 1rem;
    }
  `,
  secondarySm: css`
    ${smFont}
    color: var(--color-darkGray);

    @media (max-width: 480px) {
      font-size: 1rem;
    }
  `,
  listTitle: css`
    font-size: 1.2rem;
    line-height: 2;
    font-weight: 400;
    color: var(--color-darkGray);
    text-transform: uppercase;

    @media (max-width: 480px) {
      font-size: 1rem;
    }
  `,
  sidebar: css<{ active: boolean }>`
    ${commonFont};

    color: ${({ active }) =>
      active ? 'var(--color-primary1)' : 'var(--color-charcoal)'};
  `,
  error: css`
    ${smallFont};
    color: var(--color-fireEngineRed);
  `,
  errorXs: css`
    font-size: 1rem;
    color: var(--color-fireEngineRed);
  `,
  success: css`
    ${smallFont};
    color: var(--color-green);
  `,
});

export const TypographyStyled = styled.p<{
  active: boolean;
  fontWeight?: FontWeight;
  textDecoration: CSSProperties['textDecoration'];
}>`
  ${namedTypography};

  text-decoration: ${({ textDecoration }) => textDecoration};

  ${({ fontWeight }) =>
    !_isNil(fontWeight) &&
    css`
      font-weight: ${fontWeight};
    `};
`;
