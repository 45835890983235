import { PadBox, Stack } from '@bedrock-layout/primitives';
import type { Gutter } from '@bedrock-layout/primitives';
import { ReactNode } from 'react';

type PagesBodyContainerProps = {
  children: ReactNode;
  gutter?: Gutter;
};

export function PagesBodyContainer({
  children,
  gutter = 0,
}: PagesBodyContainerProps) {
  return (
    <PadBox padding={['4rem', '4rem']}>
      <Stack gutter={gutter}>{children}</Stack>
    </PadBox>
  );
}
