import { Center } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { useEffect } from 'react';
import { Image, Typography } from 'ui';

import { useScreenSize } from '../../hooks/useScreenSize';
import { envMap } from '../../utils/constant';
import { CenterStyled, StackStyled } from './SwitchToDesktopUi.styled';

export function SwitchToDesktopUi() {
  const screenSize = useScreenSize();

  const isSmallScreen = screenSize.width <= 1000;

  useEffect(() => {
    if (!isSmallScreen) {
      window.history.back();
    }
  }, [isSmallScreen]);

  return (
    <StackStyled gutter="1.6rem">
      <Center>
        <Image
          src={`${envMap.VITE_ASSETS_URL}website/icons/desktopIcon.svg`}
          alt="please use desktop"
          size="self"
        />
      </Center>

      <Stack gutter=".8rem">
        <Center>
          <Typography name="heading1">Switch to Desktop</Typography>
        </Center>

        <CenterStyled>
          <Typography name="secondary2">
            Nected is best experienced on a desktop. To proceed, Log in from a
            desktop browser.
          </Typography>
        </CenterStyled>
      </Stack>
    </StackStyled>
  );
}
