import { ReactNode, useState } from 'react';

import { SectionButton, SectionContainer } from './TabSelectionApiHook.styled';

type TabSelectionApiHookProps = {
  children: ReactNode[];
};

export function TabSelectionApiHook({ children }: TabSelectionApiHookProps) {
  const [tab, setTab] = useState(0);

  return (
    <>
      <SectionContainer gutter={0}>
        <SectionButton
          type="button"
          $active={tab === 0}
          onClick={(e) => {
            e.stopPropagation();
            setTab(0);
          }}
        >
          API
        </SectionButton>
        <SectionButton
          type="button"
          $active={tab === 1}
          onClick={(e) => {
            e.stopPropagation();
            setTab(1);
          }}
        >
          Webhook
        </SectionButton>
      </SectionContainer>

      {tab === 0 ? children[0] : children[1]}
    </>
  );
}
