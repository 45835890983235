import { Stack } from '@bedrock-layout/stack';
import { IoIosWarning } from 'react-icons/io';
import { RxCrossCircled } from 'react-icons/rx';
import { IconButton, Typography } from 'ui';

import {
  RulePanel,
  RulePanelBodyItem,
  RulePanelHeader,
} from './RuleDebug.styled';

type RuleDebugPanelProps = {
  warnings: string[];
  onClose: () => void;
};

export function RuleDebugPanel({ warnings, onClose }: RuleDebugPanelProps) {
  return (
    <RulePanel>
      <RulePanelHeader padding={['0.5rem', '1rem']}>
        <Typography fontWeight={700}>Debug</Typography>
        <IconButton onClick={onClose}>
          <RxCrossCircled size={20} />
        </IconButton>
      </RulePanelHeader>
      <Stack>
        {warnings.map((warning, i) => (
          <RulePanelBodyItem
            padding={['0.5rem', '1rem']}
            key={`${warning}_${i}`}
          >
            <span>
              <IoIosWarning color="orange" />
            </span>
            <Typography name="paragraphSmall">{warning}</Typography>
          </RulePanelBodyItem>
        ))}
      </Stack>
    </RulePanel>
  );
}
