import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';

type UseExpandedNodesArgs = {
  idsToExpand?: string[];
  idsToNotExpand?: string[];
  isExpanded?: boolean;
};

export function useExpandedNodes({
  isExpanded = true,
  idsToExpand = [],
  idsToNotExpand = [],
}: UseExpandedNodesArgs) {
  const [expandedNodes, setExpandedNodes] = useState<Record<string, boolean>>(
    {}
  );

  const toggleNode = (id: string) => {
    setExpandedNodes((prev) => ({
      ...prev,
      [id]: _isNil(expandedNodes[id]) ? false : !expandedNodes[id],
    }));
  };

  useEffect(() => {
    const nodesToExpand: Record<string, boolean> = {};

    if (isExpanded) {
      idsToExpand.forEach((id) => {
        nodesToExpand[id] = true;
      });

      idsToNotExpand.forEach((id) => {
        nodesToExpand[id] = false;
      });
    } else {
      idsToExpand.forEach((id) => {
        nodesToExpand[id] = false;
      });
    }

    setExpandedNodes(nodesToExpand);
  }, [isExpanded, JSON.stringify(idsToExpand)]);

  return [expandedNodes, toggleNode] as const;
}
