import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import React, { useEffect } from 'react';
// eslint-disable-next-line import/no-named-default
import { default as _ReactPlayer } from 'react-player';
import { ReactPlayerProps } from 'react-player/types/lib';
import { useSearchParams } from 'react-router-dom';
import { IconButton, Typography, useLayer } from 'ui';

import { userProfileAtom } from '../../../atom';
import { StepperSteps } from '../../../components/sidebar/StepperSteps';
import { useSendEventToGTM } from '../../../hooks/useSendEventToGTM';
import { convertObjecttoStringCSS } from '../../../utils/common';
import {
  ArrowRightStyled,
  CardBlocksTitle,
  ContentContainerStyles,
  InlineStyled,
  StackTitleContainer,
  SubHeaderContainer,
  TitleContainer,
  WigdetContainer,
} from './WigdetContainer.styled';
import { CardBlocks } from './sub-components/CardBlocks';
import { PopoupWidgets } from './sub-components/PopoupWidgets';

const ReactPlayer = _ReactPlayer as unknown as React.FC<ReactPlayerProps>;

interface VideoObject {
  id: string;
  styles: object;
  width: string;
  height: string;
  type: string;
  title: string;
  subTitle: string;
  icon: string;
  action: {
    type: string;
    href: string;
    target: string;
  };
}

export function Wigdets({ homePageActionData, user }: any) {
  const [userProfile] = useAtom(userProfileAtom);
  const { openWithProps: openPopupWidget } = useLayer(
    <PopoupWidgets user={user} />
  );

  const [searchParams] = useSearchParams();
  const { sendEventToGTM } = useSendEventToGTM();

  useEffect(() => {
    getVideoWidgetData();
    const action = searchParams.get('nec_action');

    if (!_isNil(action) && action === 'open_demo_rule') {
      getDemoPopupOpening();
    }
  }, []);

  const getDemoPopupOpening = () => {
    const demoRuleData: any = [];
    homePageActionData.forEach((item: any) => {
      if (item.type === 'ACTION_BLOCK') {
        demoRuleData.push(item);
      }
    });

    if (demoRuleData.length > 0) {
      detectActionType(demoRuleData[0].action, demoRuleData[0].state);
    }
  };

  const getVideoWidgetData = () => {
    const videoObj: VideoObject[] = [];
    homePageActionData.forEach((item: any) => {
      if (
        item.type === 'TITLE_BLOCK' &&
        item?.content?.type === 'VIDEO' &&
        item?.content?.openOnPageLoad === true
      ) {
        videoObj.push(item);
      }
    });

    if (videoObj.length > 0) {
      openPopupWidget({ popupData: videoObj[0], state: 'video' });
    }
  };

  const sendEventOnDetectAction = (state: string) => {
    switch (state) {
      case 'demo_rule_created':
        sendEventToGTM({
          event: 'widget',
          type: 'demo',
          type_selected: '',
          popup: '',
        });
        break;
      case 'new_rule_created':
        sendEventToGTM({
          event: 'widget',
          type: 'scratch',
          type_selected: '',
          popup: '',
        });
        break;
      case 'document':
        sendEventToGTM({
          event: 'widget',
          type: 'document',
          type_selected: '',
          popup: '',
        });
        break;
      case 'video':
        sendEventToGTM({
          event: 'widget',
          type: 'video',
          type_selected: '',
          popup: '',
        });
        break;
    }
  };

  function detectActionType(action: any, state: string) {
    sendEventOnDetectAction(state);

    switch (true) {
      case Boolean(action.type === 'LINK'):
        window.open(action.url, action.target);
        break;
      case Boolean(action.type === 'POPUP'):
        openPopupWidget({ popupData: action.data, state });
        break;
      default:
    }
  }

  function renderContent(content: any, index: number) {
    switch (true) {
      case Boolean(content.type === 'VIDEO'):
        return (
          <ReactPlayer
            key={index}
            url={replacePlaceholders(content.data)}
            playing={false}
            pip
            controls
            width={content.settings.width}
            height={content.settings.height}
            config={{
              youtube: {
                playerVars: { showinfo: 1 },
              },
            }}
          />
        );
      case Boolean(content.type === 'HTML'):
        return (
          <div
            style={{ width: '100%' }}
            key={index}
            onClick={() => sendEventOnDetectAction('document')}
            dangerouslySetInnerHTML={{
              __html: replacePlaceholders(content.data),
            }}
          />
        );
      default:
        return <></>;
    }
  }

  const replacePlaceholders = (content: string) => {
    if (!_isNil(userProfile)) {
      const sanitizedContent = content
        .replace('{EMAIL}', userProfile.email)
        .replace('{USERID}', window.localStorage.getItem('userUUID') as string)
        .replace('{NAME}', `${userProfile.firstName} ${userProfile.lastName}`)
        .replace('{REFERRAL}', window.location.href);

      return sanitizedContent;
    }

    return '';
  };

  function renderWidgets(widget: any, index: number) {
    switch (true) {
      case Boolean(widget.type === 'HEADER_BLOCK'):
        return (
          <WigdetContainer
            key={`${widget.id as string}_${index}`}
            width={`calc(${widget.width as string} - 2rem)`}
            height={widget.height}
            styles={convertObjecttoStringCSS(widget.styles)}
          >
            <SubHeaderContainer gutter="1rem">
              <Typography name="heading3" fontWeight={700}>
                {widget.title}
              </Typography>
              <Typography name="secondarySmallDark">
                {widget.subTitle}
              </Typography>
            </SubHeaderContainer>
          </WigdetContainer>
        );
      case Boolean(widget.type === 'STEPPER_STEPS'):
        return (
          <WigdetContainer
            key={`${widget.id as string}_${index}`}
            width={`calc(${widget.width as string} - 2rem)`}
            height={widget.height}
            styles={convertObjecttoStringCSS(widget.styles)}
          >
            <StepperSteps />
          </WigdetContainer>
        );
      case Boolean(widget.type === 'ACTION_BLOCK'):
        return (
          <WigdetContainer
            key={`${widget.id as string}_${index}`}
            padding="2rem"
            width={`calc(${widget.width as string} - 2rem)`}
            height={widget.height}
            styles={convertObjecttoStringCSS(widget.styles)}
          >
            <Inline
              align="center"
              gutter="2rem"
              onClick={() => detectActionType(widget.action, widget.state)}
            >
              <img src={widget.icon} alt="" />

              <StackTitleContainer>
                <TitleContainer fontWeight={700}>{widget.title}</TitleContainer>
                <TitleContainer name="secondarySmall">
                  {widget.subTitle}
                </TitleContainer>
              </StackTitleContainer>
              <IconButton>
                <ArrowRightStyled />
              </IconButton>
            </Inline>
          </WigdetContainer>
        );
      case Boolean(widget.type === 'SPACE_BLOCK'):
        return (
          <WigdetContainer
            key={`${widget.id as string}_${index}`}
            padding="1rem"
            width={`calc(${widget.width as string} - 2rem)`}
            height={widget.height}
            styles={convertObjecttoStringCSS(widget.styles)}
          />
        );
      case Boolean(widget.type === 'CREATE_BLOCK'):
        return (
          <WigdetContainer
            key={`${widget.id as string}_${index}`}
            padding="1rem"
            width={`calc(${widget.width as string} - 2rem)`}
            height={widget.height}
            styles={convertObjecttoStringCSS(widget.styles)}
          >
            <InlineStyled
              align="center"
              onClick={() => detectActionType(widget.action, widget.state)}
            >
              <Stack gutter="1rem">
                <img src={widget.icon} alt="" />
                <TitleContainer>{widget.title}</TitleContainer>
              </Stack>
            </InlineStyled>
          </WigdetContainer>
        );
      case Boolean(widget.type === 'TITLE_BLOCK'):
        return (
          <WigdetContainer
            key={`${widget.id as string}_${index}`}
            padding="1rem"
            width={`calc(${widget.width as string} - 2rem)`}
            height={widget.height}
            styles={convertObjecttoStringCSS(widget.styles)}
          >
            <InlineStyled align="center">
              <Stack>
                <CardBlocksTitle fontWeight={700}>
                  {widget.title}
                </CardBlocksTitle>
              </Stack>
            </InlineStyled>
            <ContentContainerStyles>
              {renderContent(widget.content, index)}
            </ContentContainerStyles>
          </WigdetContainer>
        );
      case Boolean(widget.type === 'CARD_BLOCK'):
        return (
          <WigdetContainer
            key={`${widget.id as string}_${index}`}
            padding="1rem"
            width={`calc(${widget.width as string} - 2rem)`}
            height={widget.height}
            styles={convertObjecttoStringCSS(widget.styles)}
          >
            <InlineStyled align="center">
              <Stack>
                <CardBlocksTitle fontWeight={700}>
                  {widget.title}
                </CardBlocksTitle>
              </Stack>
            </InlineStyled>
            <ContentContainerStyles>
              <CardBlocks
                user={user}
                state={widget.state}
                queryData={''}
                icon={widget.icon}
                detectActionType={detectActionType}
                createData={widget.createData}
              />
            </ContentContainerStyles>
          </WigdetContainer>
        );
      default:
        return null;
    }
  }

  return (
    <>
      {homePageActionData.map((widget: any, index: number) => (
        <React.Fragment key={index}>
          {renderWidgets(widget, index)}
        </React.Fragment>
      ))}
    </>
  );
}
