import { gql, useLazyQuery } from '@apollo/client';

import { emptyQuery } from '../../../../utils/constant';

const getRuleVersionList = gql`
  query getListOfDataQueries(
    $id: String!
    $filtersDraft: QueryOperators
    $filtersInReview: QueryOperators
  ) {
    liveData: getRule(id: $id, live: true, page: 1, perPage: 1) {
      paginationInfo {
        totalRecord
      }
    }

    draftData: getRule(
      id: $id
      live: false
      filters: $filtersDraft
      page: 1
      perPage: 1
    ) {
      paginationInfo {
        totalRecord
      }
    }

    inReviewData: getRule(
      id: $id
      live: false
      filters: $filtersInReview
      page: 1
      perPage: 1
    ) {
      paginationInfo {
        totalRecord
      }
    }
  }
`;

const getDatasetVersionList = gql`
  query getListOfDataQueries(
    $id: String!
    $filtersDraft: QueryOperators
    $filtersInReview: QueryOperators
  ) {
    liveData: getDataSet(id: $id, live: true, page: 1, perPage: 1) {
      paginationInfo {
        totalRecord
      }
    }

    draftData: getDataSet(
      id: $id
      live: false
      filters: $filtersDraft
      page: 1
      perPage: 1
    ) {
      paginationInfo {
        totalRecord
      }
    }

    inReviewData: getDataSet(
      id: $id
      live: false
      filters: $filtersInReview
      page: 1
      perPage: 1
    ) {
      paginationInfo {
        totalRecord
      }
    }
  }
`;

const getWorkflowVersionList = gql`
  query getListOfDataQueries(
    $id: String!
    $filtersDraft: QueryOperators
    $filtersInReview: QueryOperators
  ) {
    liveData: getWorkflow(id: $id, live: true, page: 1, perPage: 1) {
      paginationInfo {
        totalRecord
      }
    }

    draftData: getWorkflow(
      id: $id
      live: false
      filters: $filtersDraft
      page: 1
      perPage: 1
    ) {
      paginationInfo {
        totalRecord
      }
    }

    inReviewData: getWorkflow(
      id: $id
      live: false
      filters: $filtersInReview
      page: 1
      perPage: 1
    ) {
      paginationInfo {
        totalRecord
      }
    }
  }
`;

const getQueryBasedOnEntity = (entityType: string) => {
  switch (entityType) {
    case 'rules':
      return getRuleVersionList;
    case 'datasets':
      return getDatasetVersionList;
    case 'workflow':
      return getWorkflowVersionList;
    default:
      return emptyQuery;
  }
};

export function useGetListOfVersions(entityType: string) {
  return useLazyQuery<Record<string, any>, Record<string, any>>(
    getQueryBasedOnEntity(entityType)
  );
}
