import { Inline } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import { UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { TextButton, Typography } from 'ui';

import { AddButton } from '../../../../../../../components/AddButton';
import { generateUid } from '../../../../../../../utils/common';
import { isWorkflowReadOnlyAtom } from '../../../../../atoms/atoms';

type ConvertToGroupProps = {
  setValue: UseFormSetValue<any>;
  watch: UseFormWatch<any>;
  parentKey: string;
  pathIndex: number;
  conditionKey: string;
  show?: boolean;
};
export function ConvertToGroup({
  setValue,
  watch,
  pathIndex,
  parentKey,
  conditionKey,
  show = false,
}: ConvertToGroupProps) {
  const [isWorkflowReadOnly] = useAtom(isWorkflowReadOnlyAtom);

  const handleConvertToGroup = () => {
    const path = watch(`paths.${pathIndex}`);
    const condition = watch(`paths.${pathIndex}.${conditionKey}`);

    const groupId = generateUid('group_');

    const group = {
      parent: condition?.parent,
      nodeType: 'group',
      operator: '',
      children: [conditionKey],
    };

    path[conditionKey].parent = groupId;
    path[parentKey].children = path[parentKey].children.map((child: string) => {
      if (conditionKey === child) {
        return groupId;
      }

      return child;
    });

    path[groupId] = group;

    setValue(`paths.${pathIndex}`, path);
  };

  if (!show) {
    return null;
  }

  return (
    <TextButton onClick={handleConvertToGroup} disabled={isWorkflowReadOnly}>
      <Inline align="center" gutter="0.5rem">
        <AddButton />
        <Typography>Convert to group</Typography>
      </Inline>
    </TextButton>
  );
}
