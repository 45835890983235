import styled from 'styled-components';

export const WeekDayButton = styled.button<{ $isActive?: boolean }>`
  border: 1px solid var(--color-lightGray);
  block-size: 3rem;
  inline-size: 3rem;
  border-radius: 50%;
  background-color: var(--color-whiteSmoke);
  color: var(--color-black);
  font-size: 1.4rem;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  ${({ $isActive = false }) =>
    $isActive &&
    `
    background-color: var(--color-cyprus);
    color: blue;
  border: 1px solid var(--color-cyprus);
  `}
`;
