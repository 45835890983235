import { Inline } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import { useRef } from 'react';
import { HiDotsVertical } from 'react-icons/hi';
import { PopoverMethods, PopoverPanel, TextButton, useLayer } from 'ui';

import { DeleteBranchModal } from '../../../../../../components/DeleteBranchModal/DeleteBranchModal';
import { isWorkflowReadOnlyAtom } from '../../../../atoms/atoms';

type SwitchActionProps = {
  id: string;
  data: Record<string, any>;
};

export function SwitchAction({ id, data }: SwitchActionProps) {
  const ref = useRef<PopoverMethods>(null);
  const [isWorkflowReadOnly] = useAtom(isWorkflowReadOnlyAtom);
  const { openWithProps } = useLayer(<DeleteBranchModal />);

  return (
    <PopoverPanel
      ref={ref}
      trigger="click"
      launcher={
        <Inline>
          <HiDotsVertical />
        </Inline>
      }
    >
      <TextButton
        disabled={isWorkflowReadOnly}
        onClick={() => {
          openWithProps({ localData: data, id });
          ref.current?.hide();
        }}
      >
        Delete
      </TextButton>
    </PopoverPanel>
  );
}
