import { PadBox } from '@bedrock-layout/padbox';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { Typography } from 'ui';

import { useGetWorkflowList } from '../../../../../hooks/graphql/useGetWorkflowList';
import {
  WorkflowCreateButton,
  WorkflowItem,
  WorkflowListContainer,
} from './WorkflowList.styled';

type WorkflowListProps = {
  onWorkflowSelect: (item: any) => void;
  onItemClick: (type: string, entityId?: string) => void;
};

export function WorkflowList({
  onWorkflowSelect,
  onItemClick,
}: WorkflowListProps) {
  const [getWorkflowList] = useGetWorkflowList();
  const [workflowList, setWorkflowList] = useState<any[]>([]);

  const handleGetWorkflowList = async () => {
    try {
      const { data } = await getWorkflowList({
        variables: {
          page: 1,
          perPage: 500,
        },
        fetchPolicy: 'no-cache',
      });

      if (!_isNil(data)) {
        setWorkflowList(data.getWorkflow.data);
      }
    } catch {}
  };

  useEffect(() => {
    void handleGetWorkflowList();
  }, []);

  return (
    <PadBox padding="1.6rem">
      <WorkflowListContainer gutter="0">
        {workflowList.map((workflow) => (
          <WorkflowItem
            key={workflow.id}
            padding={[4, 6, 4, 6]}
            onClick={() => onWorkflowSelect(workflow)}
          >
            <Typography name="paragraphXs">{workflow.name}</Typography>
          </WorkflowItem>
        ))}

        <WorkflowCreateButton
          type="button"
          onClick={() => onItemClick('createWorkflow')}
        >
          <AiOutlinePlus />
          <Typography fontWeight={700} name="paragraphXs">
            Create New Workflow
          </Typography>
        </WorkflowCreateButton>
      </WorkflowListContainer>
    </PadBox>
  );
}
