import { Inline } from '@bedrock-layout/inline';
import styled, { css } from 'styled-components';

export type TabLayoutType = 'horizontal' | 'vertical';
export type TabLayoutSize = 'extraSmall' | 'small' | 'medium';

type TabStyledProps = {
  $active: boolean;
  type: TabLayoutType;
  size: TabLayoutSize;
};

type TabListStyledProps = {
  type: TabLayoutType;
};

const getTabStylesBasedOnType = (type: TabLayoutType, $active: boolean) => {
  if (type === 'vertical') {
    return css`
      background-color: ${$active ? 'var(--color-primary2)' : ''};
      display: block;
      inline-size: 100%;
      border-radius: 6px;
    `;
  }

  return css``;
};

const getTabSizeStyles = (size: TabLayoutSize, type: TabLayoutType) => {
  if (type === 'horizontal') {
    switch (size) {
      case 'extraSmall':
        return css`
          padding: 0.5rem 2rem;
          width: max-content;
        `;
      case 'small':
        return css`
          padding: var(--padding-buttonSmallVertical)
            var(--padding-buttonMediumHorizontal);
        `;
      case 'medium':
      default:
        return css`
          padding: var(--padding-buttonMediumVertical)
            var(--padding-buttonLargeHorizontal);
        `;
    }
  } else {
    return css`
      padding: 1rem 2rem;
    `;
  }
};

const getTabListStylesBasedonType = (type: TabLayoutType) => {
  if (type === 'vertical') {
    return css`
      flex-direction: column;
      flex-wrap: nowrap;
    `;
  } else {
    return css`
      border-block-end: #e3e3e3 solid 1px;
    `;
  }
};

export const TabListStyled = styled(Inline)<TabListStyledProps>`
  ${({ type }) => getTabListStylesBasedonType(type)}
`;

export const TabStyledContainer = styled(Inline)<TabStyledProps>`
  ${({ type, $active }) => getTabStylesBasedOnType(type, $active)}
`;

export const TabStyled = styled.div<TabStyledProps>`
  position: relative;
  cursor: pointer;

  ${({ size, type }) => getTabSizeStyles(size, type)}

  ${({ $active, type }) =>
    $active &&
    type === 'horizontal' &&
    `&::before {
          content:'';
          width: 100%;
          height: 3px;
          background-color: var(--color-primary1);
          position: absolute;
          bottom: 0;
          inset-inline-start: 0;
      }
    `}
`;

export const ErrorIcon = styled.div`
  position: absolute;
  top: -1rem;
  right: 5rem;
`;
