import { Typography } from 'ui';
import { CenteredStack } from './ErrorMessage.styled';

type ErrorMessageProps = {
  title: string;
  subTitle: string;
};

export function ErrorMessage({ title, subTitle }: ErrorMessageProps) {
  return (
    <CenteredStack gutter="2rem">
      <Typography fontWeight={700} name="heading1">
        {title}
      </Typography>
      <Typography name="secondary2">{subTitle}</Typography>
    </CenteredStack>
  );
}
