import { gql, useMutation } from '@apollo/client';

const deleteRemoteConfig = gql`
  mutation deleteConfig($id: String!, $checksum: String!) {
    deleteVariable(id: $id, checksum: $checksum)
  }
`;

type DeleteConfigResult = {
  data: {
    deleteVariable: boolean;
  };
};

export function useDeleteConfig() {
  return useMutation<DeleteConfigResult, { id: string; checksum: string }>(
    deleteRemoteConfig
  );
}
