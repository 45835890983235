import { gql, useLazyQuery } from '@apollo/client';

import { emptyQuery } from '../../../../utils/constant';

const getRuleVersionList = gql`
  query getRule(
    $id: String!
    $page: Int
    $perPage: Int
    $sort: Map
    $live: Boolean
    $filters: QueryOperators
  ) {
    getRule(
      id: $id
      perPage: $perPage
      page: $page
      sort: $sort
      live: $live
      filters: $filters
    ) {
      data {
        version
      }
    }
  }
`;

const getWorkflowVersionList = gql`
  query getWorkflow(
    $id: String!
    $page: Int
    $perPage: Int
    $sort: Map
    $live: Boolean
    $filters: QueryOperators
  ) {
    getWorkflow(
      id: $id
      perPage: $perPage
      page: $page
      sort: $sort
      live: $live
      filters: $filters
    ) {
      data {
        version
      }
    }
  }
`;

const getDatasetVersionList = gql`
  query getDataSet(
    $id: String!
    $page: Int
    $perPage: Int
    $sort: Map
    $live: Boolean
    $filters: QueryOperators
  ) {
    getDataSet(
      id: $id
      perPage: $perPage
      page: $page
      sort: $sort
      live: $live
      filters: $filters
    ) {
      data {
        version
      }
    }
  }
`;

const getQueryBasedOnEntity = (entityType: string) => {
  switch (entityType) {
    case 'rules':
      return getRuleVersionList;
    case 'datasets':
      return getDatasetVersionList;
    case 'workflow':
      return getWorkflowVersionList;
    default:
      return emptyQuery;
  }
};

export function useFetEntityVersionData(entityType: string) {
  return useLazyQuery<Record<string, any>, Record<string, any>>(
    getQueryBasedOnEntity(entityType)
  );
}
