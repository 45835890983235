import { Column, Inline } from '@bedrock-layout/primitives';
import styled from 'styled-components';

export const ColumnStyled = styled(Column)`
  block-size: 25rem;
`;

export const PluginContainer = styled(Inline)`
  padding: 0.8rem 1.6rem;
  border: 1px solid var(--color-solitude);
  border-radius: 0.4rem;
`;

export const BodyContainer = styled.div`
  max-height: 31rem;
  height: 31rem;
`;
