import { Inline } from '@bedrock-layout/primitives';
import { useMemo } from 'react';
import { BsCheckCircleFill } from 'react-icons/bs';
import { FaExclamationTriangle } from 'react-icons/fa';
import { Spinner, Typography } from 'ui';

import { StatusCode } from '../../../../utils/response/statusCode';
import { ActionInfoObject } from '../../types';
import { ActionCount, ErrorIconContainer } from './RuleExecutionTest.styled';

type ActionTitleProps = {
  actionInfo: ActionInfoObject;
};

export function GetActionTitle({ actionInfo }: ActionTitleProps) {
  const { count, status } = actionInfo;

  const getStatusBasedIcon = useMemo(() => {
    switch (status) {
      case StatusCode.RUNNING:
        return <Spinner size="extraSmall" />;
      case StatusCode.FAILED:
        return (
          <ErrorIconContainer align="center" justify="center">
            <FaExclamationTriangle fill="var(--color-fireEngineRed)" />
          </ErrorIconContainer>
        );
      case StatusCode.COMPLETED:
        return <BsCheckCircleFill fill="green" />;
      default:
        return <></>;
    }
  }, [status]);

  return (
    <Inline gutter={10} align="center">
      Actions
      <ActionCount justify="center" align="center">
        <Typography name="heading6">{count}</Typography>
      </ActionCount>
      {count > 0 && <>{getStatusBasedIcon}</>}
    </Inline>
  );
}
