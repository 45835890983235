import type { Control } from 'react-hook-form';
import { Dataset, useLayer } from 'ui';

import type { ResultType } from '../components/SimpleRule/Results';
import { JsNodeSheet } from '../components/SimpleRule/Results/JsNodeSheet';

export type UseOpenJsEditorSheetProps = {
  control?: Control<any>;
  index?: number;
  isAdditionalData?: boolean;
  name?: string;
  section?: ResultType;
  dataSet?: Record<string, Dataset>;
};

export function useOpenJsEditorSheet({
  control,
  index = 0,
  isAdditionalData,
  name = 'key_name_1',
  section = 'thenDataParams',
  dataSet,
}: UseOpenJsEditorSheetProps) {
  return useLayer(
    <JsNodeSheet
      name={name}
      control={control}
      index={index}
      section={section}
      isAdditionalData={isAdditionalData}
      dataSet={dataSet}
    />
  );
}
