import { ReactNode } from 'react';

import { InlineChildrenContainerStyled } from './Workspace.styled';

type InlineChildrenContainerProps = {
  children: ReactNode;
};

export function InlineChildrenContainer({
  children,
}: InlineChildrenContainerProps) {
  return (
    <InlineChildrenContainerStyled align="center" gutter={0}>
      {children}
    </InlineChildrenContainerStyled>
  );
}
