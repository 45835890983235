import { Inline, PadBox } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { useMemo } from 'react';
import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { Dataset } from 'ui';

import {
  getConditionLength,
  getRuleNodeFromPath,
} from '../../../../utils/common';
import { AddCondition } from './AddCondition';
import {
  PathBlockProperty,
  PathBlockPropertyMajor,
  PathContainer,
} from './PathBlock.styled';
import { PathCondition } from './PathCondition';
import PathGroup from './PathGroup';
import { PathOperator } from './PathOperator';

type PathBlockProps = {
  control?: Control<any>;
  setValue: UseFormSetValue<any>;
  name: string;
  remove: (index: number) => void;
  watch: UseFormWatch<any>;
  index: number;
  path: Record<string, any>;
  updatedDataSet: Record<string, Dataset>;
  dataSetLHS?: Record<string, Dataset>;
  size: number;
};

export function PathBlock({
  remove,
  control,
  name,
  index,
  setValue,
  watch,
  path,
  updatedDataSet,
  size,
  dataSetLHS = {},
}: PathBlockProps) {
  const groupNode = getRuleNodeFromPath(path);

  const conditionSize = useMemo(() => {
    return getConditionLength(path);
  }, [path]);

  return (
    <PathContainer>
      <PathBlockProperty>
        <PathBlockPropertyMajor>
          <Stack gutter="1rem">
            {groupNode.value?.children.map((id: string, i: number) => {
              const nodeType = watch(`paths.${index}.${id}.nodeType`);

              return (
                <Stack key={`${name}_condition`} gutter="0.5rem">
                  {nodeType === 'group' ? (
                    <PathGroup
                      groupId={id}
                      groupIndex={index}
                      groupName={`${name}.${id}`}
                      index={i}
                      setValue={setValue}
                      name={name}
                      path={path}
                      remove={remove}
                      watch={watch}
                      updatedDataSet={updatedDataSet}
                      control={control}
                      groupKey={id}
                      parentId={groupNode.key}
                      pathKey={groupNode.key}
                      size={conditionSize}
                    />
                  ) : (
                    <PathCondition
                      groupIndex={index}
                      index={i}
                      name={`${name}.${id}`}
                      conditionId={id}
                      remove={remove}
                      setValue={setValue}
                      watch={watch}
                      control={control}
                      updatedDataSet={updatedDataSet}
                      groupKey={groupNode.key}
                      pathKey={groupNode.key}
                      size={conditionSize}
                      dataSetLHS={dataSetLHS}
                    />
                  )}

                  {i !== groupNode.value?.children.length - 1 && (
                    <PadBox
                      padding={{
                        left: '1rem',
                      }}
                    >
                      <PathOperator
                        setValue={setValue}
                        groupName={`paths.${index}.${groupNode.key}`}
                        operator={groupNode.value.operator}
                      />
                    </PadBox>
                  )}
                </Stack>
              );
            })}
          </Stack>

          <PadBox padding={['0.5rem', '1rem']}>
            <Inline>
              <AddCondition
                watch={watch}
                groupIndex={index}
                isAlone={(groupNode.value?.children?.length ?? 0) <= 1}
                setValue={setValue}
                control={control}
                groupKey={groupNode.key}
                size={conditionSize}
                groupName={`paths.${index}.${groupNode.key}`}
              />
            </Inline>
          </PadBox>
        </PathBlockPropertyMajor>
      </PathBlockProperty>
    </PathContainer>
  );
}
