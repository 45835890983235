import { ApolloError, type ObservableQuery } from '@apollo/client';
import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import _isEmpty from 'lodash/isEmpty';
import { TextButton, ToolTip, toasts, useLayer } from 'ui';

import { PermissionWrapper } from '../../../../components/PermissionComponent';
import { permissionObj } from '../../../../components/PermissionComponent/constant';
import type { SavedAuthConfigKeys } from '../../../../types';
import {
  handleGetCheckSumByEntityName,
  handleSetCheckSumByEntityName,
} from '../../../../utils/common';
import { ENTITY_ID } from '../../../../utils/constant';
import { DeleteAuthenticationKeyButton } from '../../common/DeleteAuthenticationKeyButton';
import { useDeleteAuthConfigKey } from '../hooks/useDeleteAuthConfigKey';
import { ApiKeySheet } from './ApiKeySheet';
import {
  AuthConfigTypeContainer,
  AuthenticationConfigKeysContainer,
  AuthenticationConfigValuesContainer,
  GrantTypeStyled,
} from './AuthenticationConfig.styled';
import { BasicAuthSheet } from './BasicAuth';
import { BearerTokenSheet } from './BearerTokenSheet';
import { OAuthSheet } from './oAuth';

type AuthenticationConfigListProps = {
  authenticationKeys: SavedAuthConfigKeys[];
  setAuthenticationKeys: (authKeys: SavedAuthConfigKeys[]) => void;
  refetch: ObservableQuery<any>['refetch'];
};

export function AuthenticationConfigList({
  authenticationKeys,
  setAuthenticationKeys,
  refetch,
}: AuthenticationConfigListProps) {
  const { openWithProps: openBearerTokenSheet } = useLayer(
    <BearerTokenSheet refetch={refetch} />
  );
  const { openWithProps: openApiKeySheet } = useLayer(
    <ApiKeySheet refetch={refetch} />
  );

  const { openWithProps: openOAuthSheet } = useLayer(<OAuthSheet />);

  const { openWithProps: openBasicAuthSheet } = useLayer(<BasicAuthSheet />);

  const [deleteCredential] = useDeleteAuthConfigKey();

  const deleteAuthConfigKey = async (authKeyId: string) => {
    try {
      const checksum = handleGetCheckSumByEntityName('credentials');

      await deleteCredential({
        variables: {
          id: authKeyId,
          checksum: checksum ?? '',
        },
      });

      void refetch();

      toasts.success('Authentication key deleted successfully', 'success');
    } catch (error) {
      if (error instanceof ApolloError) {
        if (error?.graphQLErrors[0]?.extensions?.code === 'server_error') {
          toasts.error('Not able to delete', 'error');
        } else {
          toasts.error(error.message, 'error');
        }
      }
    }
  };

  function openUpdateSheet(
    type: string,
    authKey: SavedAuthConfigKeys,
    checksum: string
  ) {
    handleSetCheckSumByEntityName('credentials', checksum);

    if (type === 'BEARER_TOKEN') {
      openBearerTokenSheet({ authKey });
    } else if (type === 'API_KEY') {
      openApiKeySheet({ authKey });
    } else if (type === 'OAUTH') {
      openOAuthSheet({ authKey });
    } else if (type === 'BASIC') {
      openBasicAuthSheet({ authKey });
    }
  }

  return (
    <Stack gutter="2rem">
      {authenticationKeys.length > 0 && (
        <Inline gutter="2rem">
          <AuthenticationConfigKeysContainer name="heading3" fontWeight={700}>
            Name
          </AuthenticationConfigKeysContainer>

          <AuthenticationConfigValuesContainer name="heading3" fontWeight={700}>
            Authentication Type
          </AuthenticationConfigValuesContainer>
        </Inline>
      )}

      {authenticationKeys?.map((authKey) => {
        const grantType =
          authKey.authType.toLowerCase() === 'oauth'
            ? authKey.value.grantType.split('_').join(' ')
            : '';

        return (
          <Inline gutter="2rem" key={authKey.id}>
            <ToolTip size="large" placement="top-start" message={authKey.name}>
              <AuthenticationConfigKeysContainer
                fontWeight={700}
                name="heading3"
              >
                {authKey.name}
              </AuthenticationConfigKeysContainer>
            </ToolTip>

            <AuthConfigTypeContainer align="center">
              <AuthenticationConfigValuesContainer>
                {authKey.authType.replace('_', ' ').toLowerCase()}
              </AuthenticationConfigValuesContainer>

              <GrantTypeStyled name="heading5">
                {!_isEmpty(grantType) && `  (${grantType as string})`}
              </GrantTypeStyled>
            </AuthConfigTypeContainer>

            <PermissionWrapper
              allowedPermission={[permissionObj.create, permissionObj.edit]}
              entityList={[ENTITY_ID.credentials]}
            >
              <>
                <DeleteAuthenticationKeyButton
                  deleteAuthKey={deleteAuthConfigKey}
                  authKeyId={authKey.id}
                  checksum={authKey.checksum}
                  title="Delete Config Key"
                  messageTitle="Config Key"
                />
                <TextButton
                  onClick={() => {
                    openUpdateSheet(
                      authKey.authType,
                      authKey,
                      authKey.checksum
                    );
                  }}
                >
                  Update
                </TextButton>
              </>
            </PermissionWrapper>
          </Inline>
        );
      })}
    </Stack>
  );
}
