import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { useRef } from 'react';
import { BiChevronDown } from 'react-icons/bi';
import { Menu, MenuItem, PopoverMethods, Typography } from 'ui';

import {
  OperatorColumnStyled,
  PropertyMenuItem,
} from './DecisionTableBlock.styled';

type OperatorSelectionProps = {
  showMenu?: boolean;
  operator: string;
  onOperatorChange: (operator: string) => void;
  disabled?: boolean;
  marginTop?: string;
};

export function OperatorSelection({
  showMenu = false,
  operator,
  onOperatorChange,
  disabled = false,
  marginTop = '0rem',
}: OperatorSelectionProps) {
  const launcher = (
    <OperatorColumnStyled
      padding={[2, 5]}
      $operator={operator.toLowerCase()}
      $marginTop={marginTop}
    >
      <Typography>{operator}</Typography>
      {showMenu ? <BiChevronDown color="var(--color-black)" /> : null}
    </OperatorColumnStyled>
  );

  const menuRef = useRef<PopoverMethods>(null);

  if (!showMenu) {
    return launcher;
  }

  return (
    <Menu
      onMenuItemClick={(val) => {
        onOperatorChange(val);
        menuRef.current?.hide();
      }}
      launcher={launcher}
      disabled={disabled}
      ref={menuRef}
    >
      <MenuItem value="and">
        <PropertyMenuItem>
          <Stack>
            <Inline justify="end">
              <Typography>And</Typography>
            </Inline>
            <Typography name="paragraphXs">
              All condition must be satisfied
            </Typography>
          </Stack>
        </PropertyMenuItem>
      </MenuItem>
      <MenuItem value="or">
        <PropertyMenuItem>
          <Stack>
            <Inline justify="end">
              <Typography>Or</Typography>
            </Inline>
            <Typography name="paragraphXs">
              At least one condition must be satisfied
            </Typography>
          </Stack>
        </PropertyMenuItem>
      </MenuItem>
    </Menu>
  );
}
