import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import _isEmpty from 'lodash/isEmpty';
import { Control, useController, useWatch } from 'react-hook-form';
import { MdEdit } from 'react-icons/md';
import { TextInput, Typography, useLayer } from 'ui';

import { AlignedText } from '../../../../../components/ExcelLikeFormula/ExcelPill/ExcelPill.styled';
import { convertArrayToString } from '../../../../../utils/common';
import { PillStyledList } from '../../../../Rules/components/RuleSet/Ruleset.styled';
import { JsonPillStyled } from './Fields.styled';
import { JsonEditor } from './JsonEditor';

type RemoteConfigJsonFieldProps = {
  control?: Control<any>;
  name: string;
  defaultValue?: string;
  type: string;
};
export function RemoteConfigJsonField({
  control,
  name,
  defaultValue,
  type,
}: RemoteConfigJsonFieldProps) {
  const { openWithProps } = useLayer(
    <JsonEditor
      type={type}
      name={name}
      control={control}
      defaultValue={defaultValue}
    />
  );
  const { fieldState } = useController({
    name,
    control,
  });

  const errorMessage = fieldState.error?.message;
  const fieldValue = useWatch({
    control,
    name,
  });

  const getFieldValue = () => {
    try {
      if (typeof fieldValue === 'string') {
        return JSON.parse(fieldValue);
      }

      return fieldValue;
    } catch (error) {
      return [];
    }
  };

  return (
    <Stack>
      {type === 'json' && (
        <Inline
          onClick={() => {
            openWithProps({ type });
          }}
          as={JsonPillStyled}
          align="center"
          justify="center"
          padding={[2, 12]}
          $hasError={!_isEmpty(errorMessage)}
        >
          <Typography>Json Editor</Typography>
          <MdEdit />
        </Inline>
      )}

      {type === 'list' && (
        <TextInput
          readOnly
          placeholder="Enter values"
          name={name}
          value={convertArrayToString(getFieldValue())}
          icon={
            <Stack gutter={2}>
              <PillStyledList
                padding={['0.4rem', '1rem']}
                onClick={() => {
                  openWithProps({
                    type,
                  });
                }}
                $hasError={!_isEmpty(errorMessage)}
              >
                <AlignedText>List</AlignedText>
                <MdEdit />
              </PillStyledList>
            </Stack>
          }
        />
      )}

      {!_isEmpty(errorMessage) && (
        <Typography name="error">{errorMessage}</Typography>
      )}
    </Stack>
  );
}
