import { Center } from '@bedrock-layout/primitives';
import { ReactNode } from 'react';

import { Google, Microsoft } from '../social';
import {
  CoverStyled,
  FixedWidthContainer,
  PageContentContainer,
  SocialContainer,
} from './AuthPagesLayout.styled';
import { Salutation } from './Salutation';

export type LayoutProps = {
  salutation?: boolean;
  google?: boolean;
  microsoft?: boolean;
  form: ReactNode;
  isPasswordPage?: boolean;
  from: string;
  isAuthFormEnabled?: boolean;
  isWhiteLabled?: boolean;
};

export function AuthPagesLayout({
  salutation = false,
  google = false,
  microsoft = false,
  form,
  isPasswordPage = false,
  from,
  isAuthFormEnabled = true,
  isWhiteLabled = true,
}: LayoutProps) {
  return (
    <CoverStyled isPasswordPage={isPasswordPage}>
      <Center centerChildren>
        <FixedWidthContainer>
          <PageContentContainer>
            <PageContentContainer gutter="3rem">
              {salutation && <Salutation from={from} />}
              <SocialContainer gutter="1.6rem">
                {google && <Google />}
                {microsoft && <Microsoft />}
              </SocialContainer>
            </PageContentContainer>
            {isAuthFormEnabled ? form : ''}
          </PageContentContainer>
        </FixedWidthContainer>
      </Center>
    </CoverStyled>
  );
}
