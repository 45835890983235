import _isNil from 'lodash/isNil';
import { Image, Typography } from 'ui';

import { isValidImageURL } from '../../../../utils/common';
import { DatasetTypeContainer } from './DatasetTypePill.styled';

type DatasetTypePillProps = {
  type: string;
  iconUrl?: string;
};

export function DatasetTypePill({ type, iconUrl }: DatasetTypePillProps) {
  return (
    <DatasetTypeContainer justify="center" align="center" gutter="1rem">
      {!_isNil(iconUrl) && (
        <Image src={isValidImageURL(iconUrl)} alt="minus" size="xxs" round />
      )}
      <Typography name="secondaryXsDark">
        {type[0].toUpperCase()}
        {type.substring(1)}
      </Typography>
    </DatasetTypeContainer>
  );
}
