import { Inline } from '@bedrock-layout/primitives';
import { useState } from 'react';
import type { UseControllerProps, UseFormSetValue } from 'react-hook-form';
import { useFieldArray, useWatch } from 'react-hook-form';

import PropertyBlock from './PropertyHeaderBlock';

type PropertyFieldArrayProps = Omit<UseControllerProps, 'name'> & {
  setValue: UseFormSetValue<any>;
};

export default function PropertyFieldArray({
  control,
  setValue,
}: PropertyFieldArrayProps) {
  const properties = useWatch({
    name: 'properties',
    control,
  });
  const { fields } = useFieldArray({
    name: 'properties',
    control,
  });

  const [indexToObserve, setIndexToObserve] = useState(-1);
  const [groupToObserve, setGroupToObserve] = useState<string>();
  const [renderedGroups, setRenderedGroups] = useState<string[]>([]);

  return (
    <Inline align="stretch">
      {fields.map((field: any, index: number) => (
        <PropertyBlock
          id={field.id}
          isFirst={index === 0}
          isLast={index === properties.length - 1}
          key={field.id}
          control={control}
          setValue={setValue}
          index={index}
          length={properties.length}
          indexToObserve={indexToObserve}
          setIndexToObserve={setIndexToObserve}
          groupToObserve={groupToObserve}
          setGroupToObserve={setGroupToObserve}
          renderedGroups={renderedGroups}
          setRenderedGroups={setRenderedGroups}
        />
      ))}
    </Inline>
  );
}
