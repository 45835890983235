import styled from 'styled-components';

export const MSLoginButton = styled.button`
  border: none;
  width: fit-content;
  padding: 0px;
  background: transparent;
  img {
    cursor: pointer;
    max-width: 93%;
  }
`;
