import { FieldValues } from 'react-hook-form';
import { DropdownField, DropdownFieldPropTypes } from 'ui';

export const authTypes = [
  {
    label: 'None',
    value: 'none',
  },
  {
    label: 'Private',
    value: 'private',
  },
];

export function AuthenticationDropDown<P extends FieldValues>(
  props: DropdownFieldPropTypes<P>
) {
  return <DropdownField {...props} options={authTypes} />;
}
