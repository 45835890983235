import React, { memo } from 'react';
import { Handle, Position } from 'reactflow';

import { ArrowNodeContainer } from './ArrowNode.styled';

export const ReverseArrowNode = memo(({ data, isConnectable, id }: any) => {
  return (
    <React.Fragment>
      <Handle
        type="source"
        position={Position.Bottom}
        style={{ background: '#DCDCDC', height: 1, width: 1 }}
        isConnectable={isConnectable}
      />

      <Handle
        type="target"
        position={Position.Top}
        style={{ background: '#DCDCDC', height: 1, width: 1 }}
        isConnectable={isConnectable}
      />
      <ArrowNodeContainer>
        <svg width="18" height="16" viewBox="0 0 18 16" fill="none">
          <path d="M9 0L18 16H0L9 0Z" fill="#aaa" />
        </svg>
      </ArrowNodeContainer>
    </React.Fragment>
  );
});

ReverseArrowNode.displayName = 'ReverseArrowNode';
