import { PadBox } from '@bedrock-layout/padbox';
import styled, { css } from 'styled-components';

type PageContainerStyledProps = {
  $showVerifyEmailUi: boolean;
};

export const PageContainerStyled = styled(PadBox)<PageContainerStyledProps>`
  ${({ $showVerifyEmailUi }) =>
    $showVerifyEmailUi
      ? css`
          block-size: calc(100vh - 5rem);
        `
      : css`
          block-size: 100vh;
        `};
  overflow-y: auto;
  background-color: var(--color-lavender);
  position: relative;
`;
