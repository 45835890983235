import { PadBox } from '@bedrock-layout/padbox';
import styled from 'styled-components';

export const AuthKeyTypeContainer = styled(PadBox)`
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    background-color: var(--color-whiteSmoke);
    transition: 0.3s ease;
  }
`;
