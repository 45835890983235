import { Inline } from '@bedrock-layout/primitives';
import { AiOutlinePlus } from 'react-icons/ai';
import { BsCheck2 } from 'react-icons/bs';
import { ThemeProvider } from 'styled-components';

import {
  ButtonStyled,
  StatusButtonIcon,
  StatusText,
} from './ConnectButton.styled';

const statusText = {
  new: 'Add',
  connected: 'Connected',
  pending: 'Draft',
  tested: 'Tested',
};

const statusIcon = {
  new: <AiOutlinePlus />,
  connected: <BsCheck2 />,
  pending: null,
  tested: null,
};

export type ConnectAppearance = keyof typeof statusText;

export type ConnectorButtonProps = {
  appearance: ConnectAppearance;
  onClick?: () => void;
  disable: boolean;
};

export function ConnectButton({
  appearance,
  onClick,
  disable,
}: ConnectorButtonProps) {
  return (
    <ThemeProvider theme={{ appearance }}>
      <ButtonStyled
        disabled={disable}
        onClick={() => {
          if (typeof onClick === 'function' && !disable) {
            onClick();
          }
        }}
      >
        <Inline align="center" gutter="0.4rem" justify="center">
          <StatusButtonIcon>{statusIcon[appearance]}</StatusButtonIcon>

          <StatusText>{statusText[appearance]}</StatusText>
        </Inline>
      </ButtonStyled>
    </ThemeProvider>
  );
}
