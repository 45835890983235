import { gql, useLazyQuery } from '@apollo/client';

import {
  UseGetRuleListDataModel,
  UseGetRuleVariableModel,
} from '../../components/RuleSet/models';

const getRuleListQuery = gql`
  query getRule($page: Int, $perPage: Int) {
    getRule(perPage: $perPage, page: $page) {
      data {
        id
        name
        type
        action {
          then {
            outputData
          }
          else {
            outputData
          }
        }
        type
        customInput
      }
    }
  }
`;

export function useGetRuleList() {
  return useLazyQuery<UseGetRuleListDataModel, UseGetRuleVariableModel>(
    getRuleListQuery
  );
}
