import { useController } from 'react-hook-form';
import type { FieldValues, UseControllerProps } from 'react-hook-form';
import { RulesPopover } from 'ui';
import type { RulesPopoverProps } from 'ui';

import { Field } from '../Field';

export type RulesPopoverFieldProps<P extends FieldValues> =
  UseControllerProps<P> &
    RulesPopoverProps & {
      showError?: boolean;
      onChange?: (e: any) => void;
    };

export const RulesPopoverField = <P extends FieldValues>(
  props: RulesPopoverFieldProps<P>
) => {
  const { name, control, rules, defaultValue, showError, onChange, ...rest } =
    props;
  const { field, fieldState } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  return (
    <Field fieldState={fieldState} widthFull showError={showError}>
      <RulesPopover
        {...field}
        {...rest}
        onChange={typeof onChange === 'function' ? onChange : field.onChange}
        showError={showError}
      />
    </Field>
  );
};
