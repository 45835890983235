import { PadBox } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import {
  ColumnDef,
  PaginationState,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable,
} from '@tanstack/react-table';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import React, { useEffect, useRef, useState } from 'react';
import { useVirtual } from 'react-virtual';
import { IconButton, Pagination } from 'ui';

import type { SubscriptionPlanType } from '../../types';
import { envMap } from '../../utils/constant';
import type { ListingDetailsProps } from '../Listing/types';
import { renderUsageProgress } from '../sidebar/Sidebar';
import {
  ListingTBody,
  ListingTBodyTR,
  ListingTD,
  ListingTHead,
  ListingTable,
  ListingThead,
  PaginationContainer,
  THeadInlineStyle,
  UsageContainer,
} from './NewTable.styled';
import type { ColumnDefType } from './types';

export type NewTableProps = {
  columns: Array<ColumnDef<any>>;
  data: Array<Record<string, any>>;
  totalPages: number;
  currentPage: number;
  onPageChange?: (pageSize: number, pageNumber: number) => void;
  rowsPerPage?: number;
  handleFiltersChange?: (event: any, name: string, type: string) => void;
  listingDetails?: ListingDetailsProps;
  onRowClicked: (row: Record<string, any>) => void;
  onCellClicked: (
    row: Record<string, any>,
    index: number,
    listType: string
  ) => void;
  showPagination?: boolean;
  listType?: 'TABLE' | 'SUBTABLE';
  gettingStartedCollapsed?: boolean;
  entity: string;
  subscriptionPlan?: SubscriptionPlanType | null;
};

const limitMap: Record<string, string> = {
  workflow: 'workflowLimit',
  rules: 'ruleLimit',
};

export const NewTable = React.memo(
  ({
    columns,
    data,
    totalPages,
    currentPage = 0,
    rowsPerPage = 5,
    onPageChange,
    onRowClicked,
    onCellClicked,
    showPagination = true,
    handleFiltersChange,
    listingDetails,
    listType = 'TABLE',
    gettingStartedCollapsed,
    entity,
    subscriptionPlan,
  }: NewTableProps) => {
    const table = useReactTable({
      data,
      columns,
      getCoreRowModel: getCoreRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
    });
    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
      pageIndex: currentPage,
      pageSize: rowsPerPage,
    });

    const [state, setState] = useState(table.initialState);
    table.setOptions((prev) => ({
      ...prev,
      state,
      onStateChange: setState,
      manualPagination: true,
      pageCount: totalPages,
      onPaginationChange: setPagination,
    }));

    const isPaginationEnabled = typeof onPageChange === 'function';

    useEffect(() => {
      if (isPaginationEnabled) {
        onPageChange(pageSize, pageIndex);
      }
    }, [pageIndex, pageSize]);

    useEffect(() => {
      setPagination({
        pageIndex: currentPage,
        pageSize: rowsPerPage,
      });
    }, [currentPage]);

    const { rows } = table.getRowModel();

    const tableContainerRef = useRef<HTMLDivElement>(null);

    const rowVirtualizer = useVirtual({
      parentRef: tableContainerRef,
      size: rows.length,
      overscan: 10,
    });
    const { virtualItems: virtualRows } = rowVirtualizer;

    const getSortIconName = (key: string) => {
      if (
        !_isNil(listingDetails?.appliedFilters) &&
        !_isEmpty(listingDetails?.appliedFilters)
      ) {
        const { sort } = listingDetails?.appliedFilters;

        if (!_isNil(sort) && sort[key] === '1') {
          return 'sort_asc.svg';
        } else if (!_isNil(sort) && sort[key] === '-1') {
          return 'sort_desc.svg';
        }
      }

      return 'sort_default.svg';
    };

    return (
      <Stack gutter={0} ref={tableContainerRef}>
        <ListingTable listType={listType}>
          <ListingThead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <ListingTHead
                    key={header.id}
                    colSpan={header.id === 'name' ? 2 : header.colSpan}
                    width={(header.column.columnDef as ColumnDefType).width}
                  >
                    <THeadInlineStyle align="center">
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}

                      {(header.column.columnDef as unknown as ColumnDefType)
                        .isSort && (
                        <IconButton
                          onClick={() => {
                            if (typeof handleFiltersChange === 'function') {
                              handleFiltersChange(null, header.id, 'sort');
                            }
                          }}
                        >
                          <img
                            src={`${
                              envMap.VITE_ASSETS_URL
                            }website/icons/${getSortIconName(header.id)}`}
                            alt={`sort_icon_${header.id}`}
                            style={{ minWidth: '16px', height: '16px' }}
                          />
                        </IconButton>
                      )}
                    </THeadInlineStyle>
                  </ListingTHead>
                ))}
              </tr>
            ))}
          </ListingThead>
          <ListingTBody
            listType={listType}
            gettingStartedCollapsed={gettingStartedCollapsed ?? false}
          >
            {virtualRows.map((virtualRow) => {
              const row = rows[virtualRow.index];

              return (
                <ListingTBodyTR
                  key={row.id}
                  onClick={() => onRowClicked(row.original)}
                >
                  {row.getVisibleCells().map((cell, index) => (
                    <ListingTD
                      key={cell.id}
                      colSpan={cell.column.id === 'name' ? 2 : 1}
                      width={(cell.column.columnDef as ColumnDefType).width}
                      onClick={(e) => {
                        if (cell.column.id !== 'action') {
                          onCellClicked(cell, index, listType);
                        } else {
                          e.stopPropagation();
                        }
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </ListingTD>
                  ))}
                </ListingTBodyTR>
              );
            })}
          </ListingTBody>
        </ListingTable>

        <PaginationContainer>
          {!_isNil(subscriptionPlan) && (
            <UsageContainer padding={'1.6rem'}>
              {subscriptionPlan.plan[limitMap[entity]] !== 0 && (
                <>{renderUsageProgress(subscriptionPlan, entity)}</>
              )}
            </UsageContainer>
          )}
          {isPaginationEnabled && showPagination && (
            <PadBox padding={'1.6rem'}>
              <Pagination
                canNextPage={table.getCanNextPage()}
                canPreviousPage={table.getCanPreviousPage()}
                nextPage={table.nextPage}
                previousPage={table.previousPage}
                pageCount={table.getPageCount()}
                pageIndex={pageIndex}
                goToPage={table.setPageIndex}
              />
            </PadBox>
          )}
        </PaginationContainer>
      </Stack>
    );
  }
);

NewTable.displayName = 'New Table';
