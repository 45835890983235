import { PadBox } from '@bedrock-layout/padbox';
import { Stack } from '@bedrock-layout/stack';
import styled from 'styled-components';

export const RuleDebugBtn = styled.button<{ $isError?: boolean }>`
  block-size: 2.8rem;
  inline-size: 2.8rem;
  background-color: var(--color-solitude);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 0.4rem;
  ${({ $isError = true }) =>
    $isError && `background-color: var(--color-fireEngineRed); color: white;`}

  &.btn-disabled-interceptor {
    background: var(--color-solitude) !important;
    color: var(--color-black) !important;
    cursor: progress !important;
  }
`;

export const RulePanel = styled(Stack)`
  background: var(--color-white);
  block-size: 20rem;
  inline-size: 50rem;
  max-block-size: 20rem;
  max-inline-size: 50rem;
  border-radius: 0.8rem;
  overflow-y: auto;
`;

export const RulePanelHeader = styled(PadBox)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--color-solitude);
`;

export const RulePanelBodyItem = styled(PadBox)`
  border-bottom: 1px solid var(--color-solitude);
  display: flex;
  gap: 0.5rem;
  align-items: center;
`;
