import dateFormat from 'dateformat';
import _isNil from 'lodash/isNil';

export const formatNectedDate = (value: string | Date, type: string) => {
  let updatedValue = value;

  if (value === '') {
    return value;
  }

  if (value === 'NOW') {
    updatedValue = new Date();
  }

  const formattedDate =
    typeof updatedValue === 'string'
      ? customParseDate(updatedValue)
      : updatedValue;
  const formatString =
    type === 'date' ? 'yyyy-mm-dd' : "yyyy-mm-dd'T'HH:MM:ssp";
  let finalValue = '';
  try {
    finalValue = dateFormat(formattedDate, formatString);
  } catch (err) {
    return updatedValue;
  }

  return finalValue;
};

export const isInvalidDate = (dateString: string | null) => {
  if (_isNil(dateString)) {
    return null;
  }
  const date = new Date(dateString);

  return !(date instanceof Date && !isNaN(date as unknown as number));
};

export const customParseDate = (input: string | Date): Date | any => {
  if (typeof input === 'string' && input.length > 0) {
    // Regular expressions for different date formats
    const dateFormat1 = /^(\d{2})\/(\d{2})\/(\d{4})$/; // dd/mm/yyyy
    const dateFormat2 = /^(\d{4})\/(\d{2})\/(\d{2})$/; // yyyy/mm/dd
    const dateFormat3 = /^(\d{2})-(\d{2})-(\d{4})$/; // dd-mm-yyyy
    const dateFormat4 = /^(\d{4})-(\d{2})-(\d{2})$/; // yyyy-mm-dd

    const dateTimeFormat1 =
      /^(\d{2})[/-](\d{2})[/-](\d{4}) (\d{2}):(\d{2}):(\d{2})$/; // dd/MM/yyyy HH:mm:ss
    const dateTimeFormat2 =
      /^(\d{4})[/-](\d{2})[/-](\d{2}) (\d{2}):(\d{2}):(\d{2})$/; // yyyy/MM/dd HH:mm:ss
    const dateTimeFormat3 = /^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})$/; // yyyy-MM-dd HH:mm:ss
    const dateTimeFormat4 = /^(\d{2})-(\d{2})-(\d{4}) (\d{2}):(\d{2}):(\d{2})$/; // dd-MM-yyyy HH:mm:ss

    const dateTimeTZFormat =
      /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})Z$/; // yyyy-MM-ddTHH:mm:ssZ

    const rfc3339Format =
      /^(\d{4})-(\d{2})-(\d{2})(?:T|\s)(\d{2}):(\d{2}):(\d{2})(?:\.(\d{1,3}))?(?:(Z)|(?:([+-])(\d{2}):(\d{2})))$/; // RFC 3339 with optional milliseconds

    let match;

    // Check for dd/mm/yyyy format
    if ((match = input.match(dateFormat1)) != null) {
      return new Date(
        parseInt(match[3], 10),
        parseInt(match[2], 10) - 1,
        parseInt(match[1], 10)
      );
    }

    // Check for yyyy/mm/dd format
    if ((match = input.match(dateFormat2)) != null) {
      return new Date(
        parseInt(match[1], 10),
        parseInt(match[2], 10) - 1,
        parseInt(match[3], 10)
      );
    }

    // Check for dd-mm-yyyy format
    if ((match = input.match(dateFormat3)) != null) {
      return new Date(
        parseInt(match[3], 10),
        parseInt(match[2], 10) - 1,
        parseInt(match[1], 10)
      );
    }

    // Check for yyyy-mm-dd format
    if ((match = input.match(dateFormat4)) != null) {
      return new Date(
        parseInt(match[1], 10),
        parseInt(match[2], 10) - 1,
        parseInt(match[3], 10)
      );
    }

    // Check for dd/MM/yyyy HH:mm:ss format
    if ((match = input.match(dateTimeFormat1)) != null) {
      return new Date(
        parseInt(match[3], 10),
        parseInt(match[2], 10) - 1,
        parseInt(match[1], 10),
        parseInt(match[4], 10),
        parseInt(match[5], 10),
        parseInt(match[6], 10)
      );
    }

    // Check for yyyy/MM/dd HH:mm:ss format
    if ((match = input.match(dateTimeFormat2)) != null) {
      return new Date(
        parseInt(match[1], 10),
        parseInt(match[2], 10) - 1,
        parseInt(match[3], 10),
        parseInt(match[4], 10),
        parseInt(match[5], 10),
        parseInt(match[6], 10)
      );
    }

    // Check for yyyy-MM-dd HH:mm:ss format
    if ((match = input.match(dateTimeFormat3)) != null) {
      return new Date(
        parseInt(match[1], 10),
        parseInt(match[2], 10) - 1,
        parseInt(match[3], 10),
        parseInt(match[4], 10),
        parseInt(match[5], 10),
        parseInt(match[6], 10)
      );
    }

    // Check for dd-MM-yyyy HH:mm:ss format
    if ((match = input.match(dateTimeFormat4)) != null) {
      return new Date(
        parseInt(match[3], 10),
        parseInt(match[2], 10) - 1,
        parseInt(match[1], 10),
        parseInt(match[4], 10),
        parseInt(match[5], 10),
        parseInt(match[6], 10)
      );
    }

    // Check for yyyy-MM-ddTHH:mm:ssZ format (UTC)
    if ((match = input.match(dateTimeTZFormat)) != null) {
      return new Date(input);
    }

    // Check for RFC 3339 format
    if ((match = input.match(rfc3339Format)) != null) {
      return new Date(input);
    }

    return new Date(input);
  } else if (input instanceof Date) {
    return input; // If input is already a Date object, return it as is
  } else {
    return input; // Return current date for invalid input
  }
};
