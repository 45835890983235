import { useMutation } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';

import { useAxiosPrivate } from '../../../../hooks';
import { showErrorToast } from '../../../../utils/common';
import type { CustomAxiosError } from '../../../../utils/response/types';
import type { Member, SearchQueryParams } from '../../component/types';

export function useGetWorkspaceMemberList() {
  const { axiosPrivate } = useAxiosPrivate();

  const {
    isLoading,
    data,
    error,
    mutateAsync: getWorkspaceMemberList,
  } = useMutation({
    mutationFn: async ({
      search = '',
      roles = '',
      users = '',
      inviteType = 'team',
      approvalFlowEnabled,
    }: SearchQueryParams) => {
      let apiUrl = '/workspace/member/list?';

      if (!_isNil(search) && !_isEmpty(search)) {
        apiUrl += `${apiUrl.slice(-1) === '?' ? '' : '&'}search=${search}`;
      }

      if (!_isNil(roles) && !_isEmpty(roles)) {
        apiUrl += `${apiUrl.slice(-1) === '?' ? '' : '&'}roles=${roles}`;
      }

      if (!_isNil(users) && !_isEmpty(users)) {
        apiUrl += `${apiUrl.slice(-1) === '?' ? '' : '&'}users=${users}`;
      }

      if (!_isNil(approvalFlowEnabled)) {
        apiUrl += `${apiUrl.slice(-1) === '?' ? '' : '&'}approvalFlow=${
          approvalFlowEnabled as unknown as string
        }`;
      }

      if (!_isNil(inviteType)) {
        apiUrl += `${apiUrl.slice(-1) === '?' ? '' : '&'}type=${
          inviteType as unknown as string
        }`;
      }

      const response = await axiosPrivate.get<{
        data: Member[];
      }>(apiUrl);

      return response.data.data;
    },
    onError(error: AxiosError<CustomAxiosError>) {
      showErrorToast(error);
    },
  });

  return {
    isLoading,
    data,
    error,
    getWorkspaceMemberList,
  };
}
