import { PadBox } from '@bedrock-layout/padbox';
import { Stack } from '@bedrock-layout/stack';
import type { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { Dataset } from 'ui';

import { DefaultPath } from './DefaultPath/DefaultPath';
import { ElseButton } from './ElseButton/ElseButton';
import { PathBlock } from './PathBlock/PathBlock';

type SwitchFormProps = {
  control?: Control<any>;
  setValue: UseFormSetValue<any>;
  name: string;
  append: () => void;
  remove: (index: number) => void;
  watch: UseFormWatch<any>;
  updatedDataSet: Record<string, Dataset>;
};

export function SwitchForm({
  control,
  append,
  name,
  remove,
  setValue,
  watch,
  updatedDataSet,
}: SwitchFormProps) {
  const values = watch(name);

  return (
    <PadBox padding="1rem">
      <Stack gutter="1rem">
        {values.map((val: any, i: number) => {
          return (
            <PathBlock
              name={`${name}`}
              key={`index_${i}`}
              index={i}
              remove={remove}
              setValue={setValue}
              control={control}
              watch={watch}
              path={val}
              updatedDataSet={updatedDataSet}
              size={values.length}
            />
          );
        })}

        <ElseButton append={append} size={values.length ?? 0} />

        <DefaultPath control={control} />
      </Stack>
    </PadBox>
  );
}
