import { PadBox } from '@bedrock-layout/padbox';
import { Stack } from '@bedrock-layout/stack';
import styled from 'styled-components';

export const RuleListContainer = styled(Stack)`
  max-block-size: 24rem;
  overflow-y: auto;
`;

export const RuleItem = styled(PadBox)`
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  &:hover {
    background-color: var(--color-primary2);
  }
`;

export const RuleCreateButton = styled.button`
  padding: 0.5rem 1rem;
  display: flex;
  border-radius: 0.4rem;
  background-color: var(--color-primary2);
  cursor: pointer;
  border: none;
  position: sticky;
  bottom: 0;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  &.btn-disabled-interceptor {
    background-color: var(--color-primary2) !important;
    color: var(--color-black) !important;
  }
`;
