import { Inline } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import { Button, toasts } from 'ui';

import { MAX_NUMBER_OF_PATHS_SWITCHER } from '../../../../../../../utils/constant';
import { isWorkflowReadOnlyAtom } from '../../../../../atoms/atoms';

type ElseButtonProps = {
  append: () => void;
  size: number;
};

export function ElseButton({ append, size }: ElseButtonProps) {
  const [isWorkflowReadOnly] = useAtom(isWorkflowReadOnlyAtom);

  return (
    <Inline>
      <Button
        disabled={isWorkflowReadOnly}
        onClick={() => {
          if (size === MAX_NUMBER_OF_PATHS_SWITCHER) {
            return toasts.warning(
              `Cannot add more than ${size} paths!`,
              'warning-path-size'
            );
          }

          append();
        }}
      >
        Add Else If
      </Button>
    </Inline>
  );
}
