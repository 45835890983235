import { PadBox } from '@bedrock-layout/padbox';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { ReactNode } from 'react';
import { Control, useWatch } from 'react-hook-form';
import { AiOutlineDelete } from 'react-icons/ai';
import { RxDragHandleDots2 } from 'react-icons/rx';
import { Typography } from 'ui';

import { AddButton } from '../../../../../components/AddButton';
import { useSendEventToGTM } from '../../../../../hooks/useSendEventToGTM';
import { useHandleRuleMenuActions } from '../../../hooks/useHandleRuleMenuActions';
import { isRuleReadOnlyAtom } from '../../../index';
import {
  handleAddChildByGroup,
  handleDeleteElement,
  isFirstChild,
  isUnGroupAble,
} from '../../../utils/common';
import { sendEventToGTMType } from '../Results';
import { RuleAddMenu } from '../RuleAddMenu/RuleAddMenu';
import { simpleRuleNodesAtom, simpleStartNodeAtom } from '../SimpleRule';
import { SimpleRuleNodesModel } from '../models';
import { GroupOperatorSelection } from './GroupOperatorSelection';
import {
  AddChildStyled,
  BlockStyled,
  GroupBtnStyled,
  GroupOperatorStyled,
  RuleDeleteStyled,
  RuleGroupBlock,
  RuleOperator,
} from './RuleBlock.styled';
import { RuleMenu } from './RuleMenu';
import { DragContainer } from './RuleSqlCondition.styled';

export type RuleGroupProps = {
  ruleId: string;
  rule: SimpleRuleNodesModel;
  children: ReactNode;
  control?: Control<any>;
  handleSendEventToGTM?: (obj: sendEventToGTMType) => void;
};

export const RuleGroup = ({
  rule,
  children,
  ruleId,
  control,
  handleSendEventToGTM,
}: RuleGroupProps) => {
  const [rules, setRules] = useAtom(simpleRuleNodesAtom);
  const [startNodeId] = useAtom(simpleStartNodeAtom);
  const [isRuleReadOnly] = useAtom(isRuleReadOnlyAtom);

  const { sendEventToGTM } = useSendEventToGTM();

  const parent = !_isNil(rule.parent) ? rule.parent : '';

  const groupChildren = !_isNil(rule.children) ? rule.children : [];

  const operator =
    !_isEmpty(parent) && !_isNil(rules[parent].operator)
      ? rules[parent].operator
      : '';

  const siblings =
    !_isEmpty(rule.parent) && !_isNil(rule.parent)
      ? rules[rule.parent].children
      : null;

  const numberOfSiblings = !_isNil(siblings) ? siblings.length : 0;

  const ruleName = useWatch({
    control,
    name: 'ruleName',
  });

  const {
    handleDelete,
    unGroupAnElement,
    convertGroupToGroup,
    handleUnGroupAGroup,
  } = useHandleRuleMenuActions({
    rule,
    ruleId,
  });

  return (
    <>
      {!isFirstChild(rules, ruleId) && <PadBox padding={5} />}
      <RuleGroupBlock padding={[0, 0, 10, 0]}>
        <GroupOperatorStyled gutter={36} align="center">
          {(!isFirstChild(rules, ruleId) || !_isEmpty(rules[ruleId].parent)) &&
            !_isNil(operator) &&
            !_isEmpty(operator) && (
              <RuleOperator>
                <GroupOperatorSelection id={rule.parent} operator={operator} />
              </RuleOperator>
            )}

          {!_isEmpty(parent) && (
            <DragContainer>
              <RxDragHandleDots2 size={25} color={'var(--color-lightGray)'} />
            </DragContainer>
          )}

          {numberOfSiblings > 1 && !isRuleReadOnly && (
            <RuleDeleteStyled
              onClick={() => {
                setRules(handleDeleteElement(ruleId, rules));

                if (typeof handleSendEventToGTM === 'function') {
                  handleSendEventToGTM({
                    action: 'delete',
                    element: 'group',
                    actionName: '',
                  });
                }
              }}
            >
              <AiOutlineDelete color="#aaa" size={20} />
            </RuleDeleteStyled>
          )}

          <BlockStyled padding={[28, 20, 20, 28]}>{children}</BlockStyled>
          {
            // eslint-disable-next-line
            !!parent && (
              <RuleMenu
                showDelete={numberOfSiblings > 1}
                convertIntoGroup
                handleConvertIntoGroup={() => convertGroupToGroup(rule, ruleId)}
                handleDelete={() => handleDelete('group')}
                unGroupItem={isUnGroupAble(rules, ruleId)}
                handleUnConvert={unGroupAnElement}
                currentNode="group"
                unGroupGroup
                handleUnGroupAGroup={handleUnGroupAGroup}
              />
            )
          }

          {groupChildren.length > 1 && (
            <GroupBtnStyled
              align="center"
              gutter={10}
              $isStartNode={ruleId === startNodeId}
            >
              <AddChildStyled
                align="center"
                gutter={5}
                onClick={() => {
                  if (!isRuleReadOnly) {
                    setRules(handleAddChildByGroup(ruleId, 'condition', rules));

                    sendEventToGTM({
                      event: 'rule',
                      ruleId,
                      ruleName,
                      type: 'simpleRule',
                      action: 'selection',
                      element: 'condition',
                      nec_source: '',
                      action_name: rule.operator,
                    });
                  }
                }}
              >
                <AddButton />

                <Typography>Add Condition</Typography>
              </AddChildStyled>

              <AddChildStyled
                align="center"
                gutter={5}
                onClick={() => {
                  if (!isRuleReadOnly) {
                    setRules(handleAddChildByGroup(ruleId, 'group', rules));

                    sendEventToGTM({
                      event: 'rule',
                      ruleId,
                      ruleName,
                      type: 'simpleRule',
                      action: 'selection',
                      element: 'group',
                      nec_source: '',
                      action_name: rule.operator,
                    });
                  }
                }}
              >
                <AddButton />

                <Typography>Add Group</Typography>
              </AddChildStyled>
            </GroupBtnStyled>
          )}

          {groupChildren.length === 1 && (
            <GroupBtnStyled gutter={10} $isStartNode={ruleId === startNodeId}>
              <RuleAddMenu
                ruleId={ruleId}
                type="condition"
                rule={rule}
                control={control}
              />

              <RuleAddMenu
                ruleId={ruleId}
                type="group"
                rule={rule}
                control={control}
              />
            </GroupBtnStyled>
          )}
        </GroupOperatorStyled>
      </RuleGroupBlock>
    </>
  );
};
