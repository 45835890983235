import { PadBox } from '@bedrock-layout/padbox';
import { Stack } from '@bedrock-layout/stack';
import { JsonHeroTreeViewer, NectedEditor } from 'ui';

import { ExecutionView } from '../../../../../components/jsonOutputField/components/ExecutionView';
import { editorDomain } from '../../../../../utils/constant';
import { JsonTreeContainer, OutputContainer } from './SwitchNodeSheet.styled';

type SwitchNodeTestProps = {
  outputValue?: Record<string, any>;
};

export function SwitchNodeTest({
  outputValue = {
    _comment: 'Please test the node first',
  },
}: SwitchNodeTestProps) {
  return (
    <PadBox padding={8}>
      <OutputContainer>
        <Stack gutter="1rem">
          <ExecutionView>
            <NectedEditor
              domain={editorDomain}
              mode="json"
              defaultValue={JSON.stringify(outputValue, null, 2)}
              readOnly
            />

            <JsonTreeContainer>
              <JsonHeroTreeViewer json={outputValue} />
            </JsonTreeContainer>
          </ExecutionView>
        </Stack>
      </OutputContainer>
    </PadBox>
  );
}
