import { Inline } from '@bedrock-layout/inline';
import styled from 'styled-components';

export const SelectFileContainer = styled(Inline)`
  block-size: inherit;
  flex: 1;
  gap: 1.2rem;
  justify-content: start;
`;

export const FileItem = styled(Inline)<{
  hasData?: boolean;
  hasError?: boolean;
}>`
  padding: 1.2rem 2.4rem;
  border-radius: 4px;
  background: var(--color-primary3);
  inline-size: 27.5rem;

  ${({ hasData = false }) =>
    hasData
      ? 'justify-content: space-between; gap: 24px;'
      : 'justify-content: center'};

  ${({ hasError = false }) =>
    hasError && `border: 1px solid var(--color-fireEngineRed)`}
`;
