import { Stack } from '@bedrock-layout/stack';
import styled from 'styled-components';
import { Typography } from 'ui';

export const VCContainer = styled(Stack)`
  flex: 1;

  .tabListStyled {
    overflow-x: auto;
    flex-wrap: nowrap;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const StyledTextBlue = styled(Typography)`
  color: var(--color-primary1);
  cursor: pointer;
`;

export const StyledEmptyListImg = styled.img`
  inline-size: 12rem;
`;
