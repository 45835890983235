import _isNil from 'lodash/isNil';
import { useEffect, useMemo, useRef, useState } from 'react';
import { RiArrowDropDownFill } from 'react-icons/ri';
import { ButtonWithPopover, PopoverMethods } from 'ui';

import { useCheckPermissions } from '../PermissionComponent/hooks/useCheckPermissions';
import { buttonOptionIdsObj, defaultButtonOptions } from './constant';
import type { ButtonOptionItemType, ButtonOptionsIdsType } from './type';

type ButtonWithOptionsProps = {
  id?: string;
  entityType: string;
  entityStatus?: string;
  buttonOptionList?: ButtonOptionItemType[];
  defaultSelectedOptionId?: ButtonOptionsIdsType;
  onClick?: (selectedOptionId: string) => void;
  entityAccessRole?: string;
};

export function ButtonWithOptions({
  id,
  entityType,
  entityStatus,
  buttonOptionList,
  defaultSelectedOptionId,
  onClick,
  entityAccessRole,
}: ButtonWithOptionsProps) {
  const ref = useRef<PopoverMethods>(null);
  const optionList = !_isNil(buttonOptionList)
    ? buttonOptionList
    : defaultButtonOptions;

  const [selectedOptionId, setSelectedOptionId] = useState<string>();

  const filterOptionListBasedOnPermissions = useMemo(() => {
    return optionList.filter((option) => {
      if (entityStatus === 'inreview') {
        return [buttonOptionIdsObj.publish, buttonOptionIdsObj.reject].includes(
          option.id
        );
      }

      return [
        buttonOptionIdsObj.publish,
        buttonOptionIdsObj.requestReview,
      ].includes(option.id);
    });
  }, [optionList, entityStatus]);

  const selectedOption =
    filterOptionListBasedOnPermissions.find(
      (option) => option.id === selectedOptionId
    ) ?? filterOptionListBasedOnPermissions[0];

  const { isHide: disable } = useCheckPermissions({
    allowedPermission: selectedOption?.permissionList ?? [],
    entityList: [entityType],
    entityStatus,
    statusShouldBe: selectedOption?.statusShouldBe ?? [],
    entityAccessRole,
  });

  useEffect(() => {
    if (!_isNil(defaultSelectedOptionId)) {
      setSelectedOptionId(defaultSelectedOptionId);
    }
  }, [defaultSelectedOptionId]);

  const onOptionChange = (optionId: string) => {
    setSelectedOptionId(optionId);
    ref?.current?.hide();
  };

  const handleButtonClick = () => {
    if (typeof onClick === 'function' && !_isNil(selectedOption)) {
      onClick(selectedOption.id);
    }
  };

  return (
    <ButtonWithPopover
      trailingIcon={
        <RiArrowDropDownFill size={31} color="var(--color-white)" />
      }
      buttonOptionList={filterOptionListBasedOnPermissions}
      onOptionChange={onOptionChange}
      onClick={handleButtonClick}
      disabled={disable}
      ref={ref}
      selectedOptionId={selectedOption.id + (id ?? '')}
      hoverText={selectedOption?.subTitle}
    >
      {selectedOption?.label}
    </ButtonWithPopover>
  );
}
