import { atom, useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { useRef } from 'react';
import { UseControllerProps, UseFormSetValue, useWatch } from 'react-hook-form';
import { BsThreeDots } from 'react-icons/bs';
import { MdOutlineGroups } from 'react-icons/md';
import { TbDownload, TbUpload } from 'react-icons/tb';
import {
  IconButton,
  Menu,
  MenuItem,
  PopoverMethods,
  Typography,
  WorkflowNodeSheetHeader,
  useLayer,
} from 'ui';

import { EntityHeader } from '../../../../components/EntityHeader/EntityHeader';
import { getValueFromObject } from '../../../../components/Listing/utils';
import { EntityLevelAccessModal } from '../../../../components/Modals/EntityLevelAccessModal/EntityLevelAccessModal';
import { ExportEntityModal } from '../../../../components/Modals/ExportEntityModal/ExportEntityModal';
import { ImportEntityModal } from '../../../../components/Modals/ImportEntityModal/ImportEntityModal';
import { permissionObj } from '../../../../components/PermissionComponent/constant';
import { useCheckPermissions } from '../../../../components/PermissionComponent/hooks/useCheckPermissions';
import {
  ENTITY_ID,
  editEntityAccessDisableRoles,
} from '../../../../utils/constant';
import {
  activePanelAtom,
  isRuleLiveAtom,
  vcListTabIndexRuleAtom,
  versionInfoRuleAtom,
} from '../atom/atom';
import { MenuItemInlineStyled } from './RuleComponents.styled';

export type RuleSheetHeaderProps = Omit<UseControllerProps, 'name'> & {
  title: 'simpleRule' | 'ruleset' | 'decisionTable';
  onLiveButtonClick?: () => void;
  ruleVersion: string;
  isReadOnly: boolean;
  showLiveButton?: boolean;
  id?: string;
  setValue?: UseFormSetValue<any>;
  handleFetchRule?: () => void;
};

export const ruleStatusAtom = atom<string>('');

export function RuleSheetHeader({
  title,
  control,
  ruleVersion,
  onLiveButtonClick,
  showLiveButton = true,
  isReadOnly,
  id,
  setValue,
  handleFetchRule,
}: RuleSheetHeaderProps) {
  const menuRef = useRef<PopoverMethods>(null);

  const [versionInfoRule] = useAtom(versionInfoRuleAtom);
  const [isRuleLive] = useAtom(isRuleLiveAtom);

  const [, setActivePanel] = useAtom(activePanelAtom);
  const [, setVcListTabIndex] = useAtom(vcListTabIndexRuleAtom);

  const { isHide: isImportDisable } = useCheckPermissions({
    allowedPermission: [permissionObj.create],
    entityList: [ENTITY_ID.rules],
  });

  const { openWithProps: openImportModal } = useLayer(
    <ImportEntityModal entityType="rule" />
  );

  const { openWithProps: openExportModal } = useLayer(
    <ExportEntityModal entityId={id ?? ''} entityType="rule" />
  );

  const { openWithProps: openEntityAccessModal } = useLayer(
    <EntityLevelAccessModal />
  );

  const plan = JSON.parse(window.sessionStorage.getItem('userPlan') ?? '{}');

  const status = useWatch({
    control,
    name: 'status',
  });

  const ruleName = useWatch({
    control,
    name: 'ruleName',
  });

  const accessRoleValue = useWatch({
    control,
    name: 'accessRole',
  });

  const isMenuDisabled = !isRuleLive && status === 'published';

  const isEditAccessDisable =
    editEntityAccessDisableRoles.includes(accessRoleValue);

  const openPublishedVersions = () => {
    setActivePanel('versionControl');
    setVcListTabIndex(2);
  };

  const handleMenuItemClick = (value: string) => {
    if (value === 'export' && !_isNil(id)) {
      openExportModal({
        isLive: isRuleLive,
        version: versionInfoRule?.currentVersion,
      });
    } else if (value === 'import') {
      openImportModal({
        handleFetchEntity: handleFetchRule,
        entityName: ruleName,
      });
    } else if (value === 'editAccess') {
      openEntityAccessModal({
        entityInfo: {
          id,
          name: ruleName,
          type: 'rule',
          status,
          accessRole: accessRoleValue,
        },
      });
    }

    menuRef.current?.hide();
  };

  return (
    <div>
      <WorkflowNodeSheetHeader>
        <EntityHeader
          nameFieldKey={'ruleName'}
          descriptionFieldKey={'ruleDescription'}
          createdAtFieldKey="createdAt"
          publishedAtFieldKey="publishedAt"
          entityName="Rule"
          status={status}
          versionInfo={versionInfoRule}
          type={title}
          control={control}
          setValue={setValue}
          isReadOnly={isReadOnly}
          openPublishedVersions={openPublishedVersions}
        />

        <Menu
          launcher={
            <IconButton>
              <BsThreeDots />
            </IconButton>
          }
          ref={menuRef}
          onMenuItemClick={handleMenuItemClick}
        >
          <MenuItem
            disabled={isEditAccessDisable}
            value="editAccess"
            key="editAccess"
            tooltipText={
              isEditAccessDisable
                ? `You don't have permission to edit access of this rule. Contact owner or admin`
                : ''
            }
          >
            <MenuItemInlineStyled align="center" gutter="1rem">
              <Typography>Edit Access</Typography>
              <MdOutlineGroups />
            </MenuItemInlineStyled>
          </MenuItem>
          <MenuItem
            disabled={isMenuDisabled}
            tooltipText="Only draft and live versions can be exported, as of now."
            value="export"
            key="export"
          >
            <MenuItemInlineStyled
              id={`inside-${
                getValueFromObject(plan, 'plan.export.componentId') as string
              }`}
              data-premium-component-id={`inside-${
                getValueFromObject(plan, 'plan.export.componentId') as string
              }`}
              data-premium-component-trigger={getValueFromObject(
                plan,
                'plan.export.trigger'
              )}
              align="center"
              gutter="1rem"
            >
              <Typography>Export</Typography>
              <TbUpload />
            </MenuItemInlineStyled>
          </MenuItem>
          <MenuItem
            disabled={isImportDisable || isMenuDisabled}
            value="import"
            key="import"
            tooltipText={
              isMenuDisabled
                ? 'Import is enabled on draft and live versions.'
                : isImportDisable
                ? `You don't have permission to import rule. Contact owner or admin`
                : ''
            }
          >
            <MenuItemInlineStyled
              id={`inside-${
                getValueFromObject(plan, 'plan.import.componentId') as string
              }`}
              data-premium-component-id={getValueFromObject(
                plan,
                'plan.import.componentId'
              )}
              data-premium-component-trigger={getValueFromObject(
                plan,
                'plan.import.trigger'
              )}
              align="center"
              gutter="1rem"
            >
              <Typography>Import</Typography>
              <TbDownload />
            </MenuItemInlineStyled>
          </MenuItem>
        </Menu>
      </WorkflowNodeSheetHeader>
    </div>
  );
}
