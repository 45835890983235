import { Inline, Stack } from '@bedrock-layout/primitives';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _isUndefined from 'lodash/isUndefined';
import _reduce from 'lodash/reduce';
import _sortBy from 'lodash/sortBy';
import { useEffect, useState } from 'react';
import {
  type UseControllerProps,
  type UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import {
  Dataset,
  NectedSuggestionModel,
  OnClickRuleArgs,
  Typography,
} from 'ui';

import { PluginFieldContainer } from '../../../pages/Integrations/components/common/PluginFieldContainer';
import type { PluginFieldCommonProps } from '../../../pages/Integrations/types';
import { getDataTypesByAllowedTypes } from '../../../utils/common';
import { extractKeyDataFromObject } from '../utlis';
import { GSheetColumnPopover } from './GSheetColumnPopover/GSheetColumnPopover';

type WebGSheetColumnFieldProps = UseControllerProps<any> &
  PluginFieldCommonProps & {
    setValue?: UseFormSetValue<any>;
    formJson?: Record<string, any>;
    parentFormData?: Record<string, any>;
    newCustomSuggestions?: NectedSuggestionModel[];
    dataSet?: Record<string, Dataset>;
  };

export const WebGSheetColumnField = ({
  control,
  name,
  disabled,
  required = false,
  tooltipText,
  regex,
  newCustomSuggestions = [],
  setValue,
  formJson,
  parentFormData = {},
  formKeyPrefix = '',
  dataSet = {},
}: WebGSheetColumnFieldProps) => {
  const fieldValue = useWatch({
    name,
    control,
  });

  const formValues = useWatch({
    control,
  });

  const [columnFields, setColumnFields] = useState<Array<Record<string, any>>>(
    []
  );

  useEffect(() => {
    const hasHeaders = extractKeyDataFromObject(
      formValues,
      `${formKeyPrefix}hasHeaders`
    );

    if (
      _isNil(hasHeaders) ||
      (!_isNil(hasHeaders) && _isNil(hasHeaders.value))
    ) {
      setColumnFields([]);
    } else if (!_isNil(fieldValue)) {
      const updatedColumnFields = _reduce(
        fieldValue,
        (columnArray: Array<Record<string, any>>, field, key) => {
          return [
            ...columnArray,
            {
              ...field,
              key,
              columnName:
                hasHeaders.value === 'yes'
                  ? _isEmpty(field.columnName)
                    ? key
                    : field.columnName
                  : key,
            },
          ];
        },
        []
      );
      const sortedColumnFields = _sortBy(updatedColumnFields, 'order');

      if (!_isUndefined(setValue)) {
        setValue(
          `${formKeyPrefix}columnList`,
          sortedColumnFields.map((column) => column.key)
        );
      }

      setColumnFields(sortedColumnFields);
    }
  }, [fieldValue, JSON.stringify(formValues)]);

  if (columnFields.length === 0) {
    return null;
  }

  return (
    <Stack gutter="0.4rem">
      <PluginFieldContainer
        label="COLUMNS"
        required={required}
        tooltipText={tooltipText}
      >
        <Stack gutter="1rem">
          {columnFields.map((field, index) => (
            <div key={index}>
              <Inline gutter={8}>
                <Typography fontWeight={700}>{field.columnName}</Typography>
              </Inline>
              <GSheetColumnPopover
                control={control}
                name={`${formKeyPrefix}data.0.${field.key as string}`}
                onClick={(args: OnClickRuleArgs) => {
                  if (typeof setValue === 'function') {
                    if (args.key === 'nected') {
                      setValue(
                        `${formKeyPrefix}data.0.${field.key as string}`,
                        `<<${args.value}>>`
                      );
                    } else {
                      setValue(
                        `${formKeyPrefix}data.0.${field.key as string}`,
                        `{{.${args.key}.${args.value}}}`
                      );
                    }
                  }
                }}
                typesToAllow={[
                  ...getDataTypesByAllowedTypes(field.dataType),
                  'generic',
                  'nected',
                ]}
                dataSet={dataSet}
              />
            </div>
          ))}
        </Stack>
      </PluginFieldContainer>
    </Stack>
  );
};
