import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledLink = styled(Link)`
  font-size: 1.4rem;
  font-weight: 500;
  cursor: pointer;
  color: var(--color-dodgerBlue);
  text-decoration: underline;
`;
