import { PadBox, Split } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { userProfileAtom } from '../../atom';
import { currentWorkspaceDetailAtom } from '../../pages/Workspace/atom';
import { Sidebar } from '../sidebar/Sidebar';
import { PageContainerStyled } from './Dashboard.styled';
import { ResendEmailVerificationLink } from './ResendEmailVerificationLink';

export function Dashboard() {
  const [currWorkspaceDetails] = useAtom(currentWorkspaceDetailAtom);
  const [userProfile] = useAtom(userProfileAtom);
  const $showVerifyEmailUi =
    !_isNil(userProfile) &&
    !userProfile.emailVerified &&
    userProfile.linkExpCountDown > 0;

  const plan =
    JSON.parse(window.sessionStorage.getItem('userPlan') ?? '{}') ?? {};

  const pathName = window.location.pathname;
  const route = pathName.split('/')[1];

  const listingPlanInfo = plan?.plan?.[route];
  const trigger = listingPlanInfo?.trigger ?? 'onLoad';
  const componentId = listingPlanInfo?.componentId ?? '';

  const isSmallScreen = window.innerWidth <= 800;

  if (isSmallScreen) {
    return <Navigate to="/switch-to-desktop" />;
  }
  const [sideBarDisabled, setSideBarDisabled] = useState(true);
  const isInIframe = window !== window.parent;

  const isFromSignedURL = isInIframe || currWorkspaceDetails?.type === 'EUS';

  useEffect(() => {
    setSideBarDisabled(isFromSignedURL);
  }, [isFromSignedURL]);

  return (
    <>
      {$showVerifyEmailUi && !sideBarDisabled && (
        <ResendEmailVerificationLink
          verificationDaysLeft={userProfile?.linkExpCountDown}
        />
      )}
      <PadBox>
        <Split fraction="auto-start" minItemWidth={232}>
          {!sideBarDisabled && <Sidebar />}
          <PageContainerStyled
            id={componentId}
            data-premium-component-id={componentId}
            data-premium-component-trigger={trigger}
            $showVerifyEmailUi={$showVerifyEmailUi}
          >
            <Outlet />
          </PageContainerStyled>
        </Split>
      </PadBox>
    </>
  );
}
