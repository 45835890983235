import { useMutation } from '@tanstack/react-query';
import type { AxiosError, AxiosResponse } from 'axios';

import { useAxiosPrivate } from '../../../../hooks';
import { showErrorToast } from '../../../../utils/common';
import { CustomAxiosError } from '../../../../utils/response/types';
import type { WorkSpaceDetailsType } from '../../component/types';

export type UpdateCurrentWorkspacePayload = {
  params: WorkSpaceDetailsType;
};

export function useUpdateCurrentWorkspace() {
  const { axiosPrivate } = useAxiosPrivate();

  const {
    mutate: updateCurrentWorkspace,
    isLoading,
    data: workspaceData,
    error,
  } = useMutation({
    mutationFn: async (request: UpdateCurrentWorkspacePayload) => {
      return await axiosPrivate.patch(`/workspace`, request.params);
    },
    onSuccess(response: AxiosResponse) {
      return response;
    },
    onError(error: AxiosError<CustomAxiosError>) {
      showErrorToast(error);
    },
  });

  return {
    updateCurrentWorkspace,
    isLoading,
    workspaceData,
    error,
  };
}
