import styled from 'styled-components';

export const FallbackContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const FallBackLayer = styled.div`
  position: fixed;
  z-index: 1000;
  background-color: var(--color-white);
  height: 100vh;
  width: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
`;
