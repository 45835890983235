import { PadBox } from '@bedrock-layout/padbox';
import styled from 'styled-components';

export const OutputContainer = styled(PadBox)`
  min-block-size: 20rem;
  background-color: var(--color-solitudeSecondary);
  border-radius: 4px;
`;

export const JsonContainer = styled(PadBox)`
  block-size: 65rem;
`;

export const JsonTreeContainer = styled(PadBox)`
  block-size: 40rem;
  overflow-y: auto;
  background-color: var(--color-white);
`;
