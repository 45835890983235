import {
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form';

import { NectedEditor, NectedEditorProps } from '../../NectedEditor';
import { Field } from '../Field';

export type NectedEditorFieldProps<P extends FieldValues> =
  UseControllerProps<P> &
    NectedEditorProps & {
      showError?: boolean;
    };

export const NectedEditorField = <P extends FieldValues>({
  name,
  control,
  rules,
  defaultValue,
  showError,
  customSuggestions,
  ...rest
}: NectedEditorFieldProps<P>) => {
  const { field, fieldState } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  return (
    <Field fieldState={fieldState} showError={showError} heightFull>
      <NectedEditor
        customSuggestions={customSuggestions}
        defaultValue={defaultValue}
        {...field}
        {...rest}
      />
    </Field>
  );
};
