import { useMutation } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { toasts } from 'ui';

import { useAxios } from '../../../hooks';
import { showErrorToast } from '../../../utils/common';
import type { CustomAxiosError } from '../../../utils/response/types';

export type ResetPasswordRequestPayload = {
  password: string;
  confirmPassword: string;
  resetToken: string;
};

export function useResetPassword() {
  const navigate = useNavigate();
  const { axios } = useAxios();

  const { mutate: resetPassword, isLoading } = useMutation({
    mutationFn: async (request: ResetPasswordRequestPayload) => {
      return await axios.post('/auth/password/reset', request, {
        headers: {
          'Nected-User-IP': localStorage.getItem('Nected-User-IP') ?? '',
          'X-CSRF-Token': localStorage.getItem('X-CSRF-Token') ?? '',
        },
      });
    },
    onSuccess() {
      toasts.success('Password has been reset successfully.', 'success');

      navigate('/signin', { replace: true });
    },
    onError(error: AxiosError<CustomAxiosError>) {
      showErrorToast(error);
    },
  });

  return {
    resetPassword,
    isLoading,
  };
}
