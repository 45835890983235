import { Helmet } from 'react-helmet';

import { AuthPagesLayout } from '../../components/authentication';
import { useGetSiteMeta } from '../../hooks/useGetSiteMeta';
import { envMap } from '../../utils/constant';
import { SigninForm } from './components/Signin';

export const Signin = () => {
  const isGoogleEnabled = envMap.VITE_ENABLE_GOOGLE_LOGIN === 'true';
  const isMsLoginEnabled = envMap.VITE_ENABLE_MS_LOGIN === 'true';
  const isAuthFormEnabled = envMap.VITE_ENABLE_AUTH_FORM === 'true';
  const isWhiteLabeled = envMap.VITE_FULL_WHITELABELLED === 'true';
  const isFormEnabled = envMap.VITE_SIGNUP_FORM_ENABLED === 'true';

  const { siteMeta } = useGetSiteMeta();

  return (
    <>
      <Helmet>
        <title>
          {siteMeta[window.location.pathname.substring(1)]?.title ?? ''}
        </title>
        <meta
          name="description"
          content={
            siteMeta[window.location.pathname.substring(1)]?.description ?? ''
          }
        />
      </Helmet>
      <AuthPagesLayout
        from="signin"
        salutation
        isWhiteLabled={isWhiteLabeled}
        google={isGoogleEnabled}
        microsoft={isMsLoginEnabled}
        isAuthFormEnabled={isAuthFormEnabled}
        form={<SigninForm isWhiteLabeled={isWhiteLabeled} isFormEnabled={isFormEnabled}  />}
      />
    </>
  );
};
