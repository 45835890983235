import { gql, useMutation } from '@apollo/client';

const createDT = gql`
  mutation CreateRule(
    $name: String!
    $type: String!
    $description: String
    $policy: String
    $conditions: ConditionsInput
    $decisionTable: Map
    $settings: RuleSettingsInput
  ) {
    createRule(
      input: {
        name: $name
        type: $type
        description: $description
        policy: $policy
        conditions: $conditions
        decisionTable: $decisionTable
        settings: $settings
      }
    ) {
      id
    }
  }
`;

export function useCreateDT() {
  return useMutation(createDT);
}
