import { ApolloError } from '@apollo/client';
import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import dateFormat from 'dateformat';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useRef, useState } from 'react';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { useSearchParams } from 'react-router-dom';
import {
  Button,
  Expander,
  IconButton,
  Menu,
  MenuItem,
  PopoverMethods,
  PublishedStatusLabel,
  Spinner,
  Typography,
  toasts,
} from 'ui';

import { showGraphQlErrorToast } from '../../../../utils/common';
import { ButtonWithOptions } from '../../../ButtonWithOptions/ButtonWithOptions';
import { buttonOptionIdsObj } from '../../../ButtonWithOptions/constant';
import type { ButtonOptionsIdsType } from '../../../ButtonWithOptions/type';
import type {
  CardDataType,
  CommentDataType,
  EntityInfoType,
  PaginationType,
} from '../../VersionControl';
import { pageSize } from '../../constant';
import { useGetComments } from '../../hooks/graphql/useGetComments';
import {
  ButtonWrapper,
  CardContainer,
  CommentBoxStyled,
  CommentListContainer,
  CommentTextContainer,
  CreatedInfoContainer,
  MenuItemText,
  NameIconStyled,
  Separator,
  SmallFontText,
  StyledInline,
} from './Card.styled';

type CardProps = {
  index: number;
  data: CardDataType;
  entityInfo: EntityInfoType;
  tabName: string;
  handleButtonClick: (btnId: string, metaData?: Record<string, any>) => void;
  selectedOptionId?: ButtonOptionsIdsType;
};

export function Card({
  index,
  data,
  entityInfo,
  tabName,
  selectedOptionId,
  handleButtonClick,
}: CardProps) {
  const [searchParams] = useSearchParams();

  const { type, status: entityStatus, accessRole } = entityInfo;

  const [comments, setComments] = useState<CommentDataType[]>([]);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [paginationInfo, setPaginationInfo] = useState<PaginationType>();
  const [canAppendData, setAppendData] = useState(false);

  const [getCommentQuery, { data: commentData, error }] = useGetComments();

  const { commitId, isLive = false, version, status, approvalInfo } = data;

  const { title, author = '', description = '', timeStamp } = approvalInfo;

  const menuRef = useRef<PopoverMethods>(null);

  const vcActiveTabParams = searchParams.get('activeVcTab');

  useEffect(() => {
    if (
      !_isNil(vcActiveTabParams) &&
      !_isEmpty(vcActiveTabParams) &&
      index === 0
    ) {
      void handleGetComments();
    }
  }, [vcActiveTabParams]);

  useEffect(() => {
    if (!_isNil(commentData) && !_isNil(commentData.getComments)) {
      const { data, paginationInfo } = commentData.getComments;
      setComments(
        canAppendData ? [...comments, ...(data ?? [])] : [...(data ?? [])]
      );

      setPaginationInfo(paginationInfo);
    }
  }, [commentData]);

  useEffect(() => {
    if (error instanceof ApolloError) {
      toasts.error(error.message, 'error');
    }
  }, [error]);

  const handleMenuItemClick = (value: string) => {
    if (value === 'archived') {
      handleButtonClick(buttonOptionIdsObj.archived, { commitId });
    }
  };

  const handleGetComments = async () => {
    if (isCollapsed) {
      setIsCollapsed(false);

      try {
        setIsLoading(true);

        await getCommentQuery({
          variables: {
            commitId,
            perPage: pageSize,
            page: 1,
          },
        });
      } catch (error) {
        if (error instanceof Error) {
          showGraphQlErrorToast(error);
        }
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsCollapsed(true);
      setAppendData(false);
    }
  };

  const handleLoadMoreComments = async () => {
    try {
      setAppendData(true);
      setIsLoading(true);
      await getCommentQuery({
        variables: {
          commitId,
          page: (paginationInfo?.currentPage ?? 0) + 1,
          perPage: pageSize,
        },
      });
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const commitIdParams = searchParams.get('commitId');
  const isViewMode = searchParams.get('type') === 'view';

  const isCardSelected = commitId === commitIdParams;

  const showMoreBtn =
    !_isNil(paginationInfo) &&
    paginationInfo.currentPage < paginationInfo.totalPage;

  return (
    <CardContainer $isSelected={isCardSelected} gutter={0}>
      <PadBox padding="1rem">
        <Stack gutter="1rem">
          <StyledInline>
            <Inline justify="center" align="center" gutter="0.5rem">
              <Typography fontWeight={700} name="heading5">
                {title}
              </Typography>
            </Inline>
            <Inline align="center" gutter={8}>
              {!_isNil(status) && isLive && tabName === 'published' && (
                <PublishedStatusLabel
                  textStyleName="paragraphXs"
                  publishedStatus={'latest'}
                  currentVersion={version}
                />
              )}

              {!_isNil(status) &&
                (tabName === 'published' || tabName !== 'published') && (
                  <PublishedStatusLabel
                    textStyleName="paragraphXs"
                    publishedStatus={status}
                    currentVersion={version}
                  />
                )}
            </Inline>
          </StyledInline>

          {!_isNil(description) && !_isEmpty(description) && (
            <CommentTextContainer>{description}</CommentTextContainer>
          )}
          <CreatedInfoContainer>
            <Inline align="center" justify="center" gutter="0.5rem">
              <NameIconStyled align="center" justify="center">
                {!_isEmpty(author) ? author[0].toUpperCase() : ''}
              </NameIconStyled>
              <Typography name={'heading5'}>{author}</Typography>
            </Inline>
            {!_isNil(timeStamp) && (
              <Typography name="secondaryXs">
                {dateFormat(
                  new Date(timeStamp),
                  `ddd , mmm dd yyyy 'at' HH:MM:ss TT`
                )}
              </Typography>
            )}
          </CreatedInfoContainer>

          <StyledInline>
            <ButtonWrapper>
              {tabName !== 'draft' &&
                tabName !== 'inreview' &&
                (!isViewMode || !isCardSelected) && (
                  <Button
                    appearance="filled"
                    onClick={() =>
                      handleButtonClick(buttonOptionIdsObj.preview, {
                        commitId,
                      })
                    }
                  >
                    Preview
                  </Button>
                )}

              {tabName === 'published' && !isLive && (
                <Button
                  onClick={() =>
                    handleButtonClick(buttonOptionIdsObj.rollback, { commitId })
                  }
                >
                  Rollback
                </Button>
              )}

              {/* Will be uncommented later on  */}
              {/* {tabName === 'archived' && (
                <Button
                  onClick={() =>
                    handleButtonClick(buttonOptionIdsObj.restoreToDraft, {
                      commitId,
                    })
                  }
                >
                  Restore to Draft
                </Button>
              )} */}

              {/* Will be uncommented later on  */}
              {/* {tabName === 'draft' && (
                <Button
                  onClick={() =>
                    handleButtonClick(buttonOptionIdsObj.resetEditor)
                  }
                >
                  Reset Editor
                </Button>
              )} */}

              {(tabName === 'draft' || tabName === 'inreview') && (
                <ButtonWithOptions
                  id="vc-card"
                  entityType={type}
                  entityStatus={entityStatus}
                  defaultSelectedOptionId={selectedOptionId}
                  onClick={handleButtonClick}
                  entityAccessRole={accessRole}
                />
              )}
            </ButtonWrapper>
            {(tabName === 'draft' || tabName === 'inreview') && (
              <Menu
                launcher={
                  <IconButton>
                    <BsThreeDotsVertical />
                  </IconButton>
                }
                ref={menuRef}
                onMenuItemClick={handleMenuItemClick}
              >
                <MenuItem value="archived" key="archived">
                  <MenuItemText>Archive</MenuItemText>
                </MenuItem>
              </Menu>
            )}
          </StyledInline>

          {tabName === 'inreview' && (
            <SmallFontText name="error">Waiting for approval*</SmallFontText>
          )}
        </Stack>
      </PadBox>
      <CommentBoxStyled>
        <Expander
          collapsed={isCollapsed}
          title={
            <PadBox padding="1rem">
              <Typography name="paragraphXs">View Comments</Typography>
            </PadBox>
          }
          onClick={handleGetComments}
        >
          <CommentListContainer $hasComments={comments.length !== 0}>
            {!isLoading &&
              comments.map((data, index) => (
                <Stack key={index}>
                  <Stack gutter="1rem">
                    <CreatedInfoContainer>
                      {!_isNil(data.author) && (
                        <Inline align="center" justify="center" gutter="0.5rem">
                          <NameIconStyled align="center" justify="center">
                            {data.author[0].toUpperCase()}
                          </NameIconStyled>
                          <Typography name={'heading5'}>
                            {data.author}
                          </Typography>
                        </Inline>
                      )}
                      {!_isNil(data.timeStamp) && (
                        <Typography name="secondaryXs">
                          {dateFormat(
                            new Date(data.timeStamp),
                            `ddd , mmm dd yyyy 'at' HH:MM:ss TT`
                          )}
                        </Typography>
                      )}
                    </CreatedInfoContainer>

                    {!_isNil(data.comment) && (
                      <CommentTextContainer>
                        {data.comment}
                      </CommentTextContainer>
                    )}
                  </Stack>
                  {index !== comments.length - 1 && <Separator />}
                </Stack>
              ))}

            {!isLoading && comments.length === 0 && (
              <Typography name="heading5">No comments yet</Typography>
            )}

            {isLoading && (
              <Inline justify="center" align="center">
                <Spinner />
              </Inline>
            )}

            {showMoreBtn && (
              <Inline justify="center">
                <Button onClick={handleLoadMoreComments}>
                  Load more comments
                </Button>
              </Inline>
            )}
          </CommentListContainer>
        </Expander>
      </CommentBoxStyled>
    </CardContainer>
  );
}
