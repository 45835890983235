import _isNil from 'lodash/isNil';
import { ReactNode } from 'react';
import { Button } from 'ui/src/Button';
import { useCurrentLayer } from 'ui/src/Layer';
import { Modal, ModalContent, ModalFooter } from 'ui/src/Modal';
import { Typography } from 'ui/src/Typography';

type PropertyChangeModalProps = {
  title: string;
  onPropertyChange?: () => void;
  onKeepPropertyChange?: () => void;
  description?: ReactNode;
};

export const PropertyChangeModal = ({
  title,
  onPropertyChange,
  onKeepPropertyChange,
  description,
}: PropertyChangeModalProps) => {
  const { close: closeModal } = useCurrentLayer();

  return (
    <Modal
      size="large"
      title={title}
      onClose={() => {
        closeModal();

        if (typeof onKeepPropertyChange === 'function') {
          onKeepPropertyChange();
        }
      }}
    >
      <ModalContent>
        {!_isNil(description) && (
          <Typography name="paragraph">{description}</Typography>
        )}
      </ModalContent>

      <ModalFooter>
        <Button
          appearance="neutral"
          onClick={() => {
            closeModal();

            if (typeof onKeepPropertyChange === 'function') {
              onKeepPropertyChange();
            }
          }}
        >
          Retain Existing Property
        </Button>

        <Button
          onClick={() => {
            closeModal();

            if (typeof onPropertyChange === 'function') {
              onPropertyChange();
            }
          }}
          appearance="danger"
        >
          Change and Lose Conditions
        </Button>
      </ModalFooter>
    </Modal>
  );
};
