import { FaExclamationTriangle } from 'react-icons/fa';
import { ToolTip } from 'ui';

type ErrorPopoverProps = {
  error: string;
};

export function ErrorPopover({ error }: ErrorPopoverProps) {
  return (
    <ToolTip message={error} placement="right-end">
      <FaExclamationTriangle fill="var(--color-fireEngineRed)" />
    </ToolTip>
  );
}
