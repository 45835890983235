import { Inline } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import { UseControllerProps, UseFormSetValue, useWatch } from 'react-hook-form';

import { assetsInDraftMapAtom } from '../../atom/atom';
import { TableRowContainerStyled } from './RuleSetRow.styled';
import { MenuColumn } from './TableColumns/MenuColumn';
import { ResultColumn } from './TableColumns/ResultColumn';
import { RulesColumn } from './TableColumns/RulesColumn';

export type RuleSetRowProps = Omit<UseControllerProps, 'name'> & {
  index: number;
  handleInsertRow: (index: number) => void;
  handleDeleteRow: (index: number) => void;
  setValue: UseFormSetValue<any>;
};

export function RuleSetRow({
  control,
  index,
  handleInsertRow,
  handleDeleteRow,
  setValue,
}: RuleSetRowProps) {
  const id: string = useWatch({
    control,
    name: `ruleList.${index}.ruleId`,
  });

  const [assetsInDraftMap] = useAtom(assetsInDraftMapAtom);

  return (
    <TableRowContainerStyled $hasError={assetsInDraftMap?.[id] ?? false}>
      <Inline className="rule-set-table-row" align="center">
        <RulesColumn control={control} index={index} setValue={setValue} />
        <ResultColumn index={index} control={control} />
        <MenuColumn
          handleInsertRow={handleInsertRow}
          handleDeleteRow={handleDeleteRow}
          control={control}
          index={index}
        />
      </Inline>
    </TableRowContainerStyled>
  );
}
