import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { useAtom } from 'jotai';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import { CheckboxField, TextField, TooltipReact, Typography } from 'ui';

import { isWorkflowReadOnlyAtom } from '../../../atoms/atoms';
import { RuleActionContainer, Separator } from './RuleSheet.styled';

type RuleSettingsProps = {
  control?: Control<any>;
  name: string;
  setValue: UseFormSetValue<any>;
  ruleType?: string;
};

export function RuleSettings({
  control,
  name,
  setValue,
  ruleType,
}: RuleSettingsProps) {
  const [isWorkflowReadOnly] = useAtom(isWorkflowReadOnlyAtom);
  const runAction: boolean = useWatch({
    control,
    name: 'settings.runAction',
  });

  return (
    <PadBox padding="2rem" className="rule-settings-content">
      <Stack gutter="1rem">
        <Inline align="center" gutter={8}>
          <Typography>Timeout after (s)</Typography>
          <TextField
            name={`${name}.timeout`}
            type="number"
            size="small"
            control={control}
          />
        </Inline>

        {ruleType !== 'simpleRule' && (
          <Inline align="center" gutter={8}>
            <Typography fontWeight={700}>Continue on error?</Typography>
            <CheckboxField
              name={`${name}.errorContinue`}
              control={control}
              disabled={isWorkflowReadOnly}
              appearance="switch"
              useId={`${name}.errorContinue`}
            />
          </Inline>
        )}
        <RuleActionContainer gutter="0">
          <PadBox padding="1rem">
            <Inline align="center" gutter={12}>
              <Typography>Run Action</Typography>
              <CheckboxField
                name={`${name}.runAction`}
                useId={`${name}.runAction`}
                control={control}
                appearance="switch"
                disabled={isWorkflowReadOnly}
                onChangeCustom={(e) => {
                  setValue(`${name}.actionInSync`, e.target.checked);
                }}
              />
            </Inline>
          </PadBox>

          <Separator />

          <PadBox padding="1rem">
            <Inline align="center" gutter={12}>
              <Typography name={runAction ? 'paragraph' : 'secondary2'}>
                Wait for action(s) to finish
              </Typography>

              <TooltipReact id="run-action-in-sync" defaultIsOpen>
                Turn it on if you need action response immediately
              </TooltipReact>

              <CheckboxField
                disabled={!runAction || isWorkflowReadOnly}
                name={`${name}.actionInSync`}
                useId={`${name}.actionInSync`}
                control={control}
                appearance="switch"
              />
            </Inline>
          </PadBox>
        </RuleActionContainer>
      </Stack>
    </PadBox>
  );
}
