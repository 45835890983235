import { Control } from 'react-hook-form';
import { MonthDayField, TextField, WeekDayField } from 'ui';

type SchedulerComponentsProps = {
  control: Control<any>;
  name: string;
  type: string;
};

export function SchedulerComponents({
  control,
  name,
  type,
}: SchedulerComponentsProps) {
  if (type === 'hourly') {
    return (
      <TextField
        name="hours"
        control={control}
        type="number"
        size="small"
        heightFull
        shouldUnregister
      />
    );
  }

  if (type === 'minute') {
    return (
      <TextField
        name="minutes"
        control={control}
        type="number"
        size="small"
        heightFull
        shouldUnregister
      />
    );
  }

  if (type === 'weekly') {
    return <WeekDayField name={name} control={control} />;
  }

  if (type === 'monthly') {
    return <MonthDayField control={control} name={name} />;
  }

  return null;
}
