import { Inline } from '@bedrock-layout/primitives';
import styled from 'styled-components';

export const Container = styled(Inline)`
  justify-content: space-between;
  gap: 1rem;
`;

export const ChildNodeContainer = styled(Inline)<{
  marginLeft?: string;
  hasBorder?: boolean;
}>`
  margin-left: ${({ marginLeft = '1rem' }) => marginLeft};

  ${({ hasBorder = true }) =>
    hasBorder && `border-left:  1px solid var(--color-darkGray);`}
`;
