import { z } from 'zod';

export const requestReviewFormSchema = z.object({
  title: z
    .string()
    .min(1, 'Title cannot be empty')
    .max(100, 'Title cannot be more than 100 characters'),
  description: z
    .string()
    .max(1000, 'Description cannot be more than 1000 characters'),
  approvers: z
    .array(
      z.object({
        label: z.string(),
        value: z.string(),
      })
    )
    .min(1, 'Approvers cannot be empty'),
});

export const approveChangesFormSchema = z.object({
  title: z
    .string()
    .min(1, 'Title cannot be empty')
    .max(100, 'Title cannot be more than 100 characters'),
  description: z
    .string()
    .min(1, 'Description cannot be empty')
    .max(1000, 'Description cannot be more than 1000 characters'),
});

export const rejectFormSchema = z.object({
  description: z
    .string()
    .min(1, 'Description cannot be empty')
    .max(1000, 'Description cannot be more than 1000 characters'),
});

export const arhiveFormSchema = z.object({
  title: z
    .string()
    .min(1, 'Title cannot be empty')
    .max(100, 'Title cannot be more than 100 characters'),
  description: z
    .string()
    .min(1, 'Description cannot be empty')
    .max(1000, 'Description cannot be more than 1000 characters'),
});
