import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import { useRef } from 'react';
import { useController, useWatch } from 'react-hook-form';
import type { Control } from 'react-hook-form';
import { PopoverMethods, PopoverPanel, Typography } from 'ui';

import { PopoverContainerStyled } from '../../../../../pages/Rules/components/SimpleRule/RulePopovers/RuleParamPopover.styled';
import { DataSourceListItem } from './DataSourceAttribute.styled';
import { SelectInputAttrLauncher } from './SelectInputAttrLauncher';

type SelectInputAttrPopoverProps = {
  control: Control<any>;
  inputAttributesFields: any[];
  name: string;
  showErrorIcon: boolean;
  disabled: boolean;
  handleInputAttrSelection: (index: number) => void;
};

export function SelectInputAttrPopover({
  control,
  inputAttributesFields,
  name,
  handleInputAttrSelection,
  disabled,
}: SelectInputAttrPopoverProps) {
  const ref = useRef<PopoverMethods>(null);

  const text = useWatch({
    control,
    name,
  });

  const { fieldState } = useController({
    name,
    control,
  });

  return (
    <PopoverPanel
      ref={ref}
      trigger="click"
      padding={0}
      placement="bottom-start"
      disabled={disabled}
      launcher={
        <SelectInputAttrLauncher
          text={text}
          error={fieldState.error?.message}
        />
      }
    >
      <PopoverContainerStyled>
        {inputAttributesFields.map(({ name, dataType }, index) => {
          return (
            <PadBox padding={['0.4rem', '1.6rem']} key={`dataSet_${index}`}>
              <DataSourceListItem
                gutter={16}
                align="center"
                onClick={() => {
                  handleInputAttrSelection(index);
                  ref.current?.hide();
                }}
              >
                <Typography name="heading3" element="p">
                  {name}
                </Typography>
                <Typography name="secondarySmall" element="p">
                  {dataType?.label ?? '-'}
                </Typography>
              </DataSourceListItem>
            </PadBox>
          );
        })}

        {inputAttributesFields.length === 0 && (
          <Inline as={PadBox} justify="center" align="center" padding={'1rem'}>
            <Typography>No Input Attributes</Typography>
          </Inline>
        )}
      </PopoverContainerStyled>
    </PopoverPanel>
  );
}
