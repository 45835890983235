import _isNil from 'lodash/isNil';

export function useHandleBtnStateAtInterceptor() {
  const disableAllButtons = () => {
    const btnElements = document.querySelectorAll('button');

    if (!_isNil(btnElements)) {
      btnElements.forEach((currBtn, index) => {
        if (!currBtn.disabled) {
          currBtn.disabled = true;
          currBtn.classList.add('btn-disabled-interceptor');
          currBtn.title = 'Please wait...';
        }
      });
    }
  };

  const enableAllButtons = () => {
    const btnElements = document.querySelectorAll('button');

    if (!_isNil(btnElements)) {
      btnElements.forEach((currBtn, index) => {
        if (currBtn.classList.contains('btn-disabled-interceptor')) {
          currBtn.disabled = false;
          currBtn.classList.remove('btn-disabled-interceptor');
          currBtn.title = '';
        }
      });
    }
  };

  return {
    disableAllButtons,
    enableAllButtons,
  };
}
