import { gql, useLazyQuery } from '@apollo/client';

const getConnectorsActionMethodsQuery = gql`
  query getConnector($connectorId: String!) {
    getConnector(id: $connectorId) {
      data {
        id
        name
        plugin {
          category
          schema {
            action {
              methods
            }
          }
        }
      }
    }
  }
`;

type ActionMethodsModel = {
  id: string;
  name: string;
  plugin: {
    category: string;
    schema: {
      action: {
        methods: Record<string, any>;
      };
    };
  };
};

type GetActionMethodsQueryResult = {
  getConnector: {
    data: ActionMethodsModel[];
  };
};

export const useGetActionMethods = () => {
  return useLazyQuery<
    GetActionMethodsQueryResult,
    {
      connectorId: string;
    }
  >(getConnectorsActionMethodsQuery);
};
