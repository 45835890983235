import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { useEffect, useMemo, useState } from 'react';
import { type Control, type UseFormSetValue, useWatch } from 'react-hook-form';
import { Attributes, Dataset, NectedSuggestionModel, useLayer } from 'ui';

import { isRuleReadOnlyAtom } from '..';
import { ExcelModal } from '../../../components/ExcelLikeFormula/ExcelModal/ExcelModal';
import { createResultDataset } from '../../../utils/common';
import { TokenScores } from '../../../utils/constant';
import { dataSetParamsAtom } from '../components/CreateRuleSheet/CreateRuleSheet';
import { ResultAddDataModel } from '../components/SimpleRule/models';
import { getEvaluatedExValueForResult } from '../utils/common';
import { useOpenJsEditorSheet } from './useOpenJsEditor';
import { useOpenJsonEditorSheet } from './useOpenJsonEditor';

type UseOpenNonPrimitiveDataTypeSheet = {
  control?: Control<any>;
  fields: Array<Record<'id', string>>;
  section: string;
  setValue?: UseFormSetValue<any>;
};

export function useOpenNonPrimitiveDataType({
  control,
  fields,
  section,
  setValue,
}: UseOpenNonPrimitiveDataTypeSheet) {
  const field = useWatch({
    name: section,
    control,
  });

  const [dataset] = useAtom(dataSetParamsAtom);
  const [isRuleReadOnly] = useAtom(isRuleReadOnlyAtom);

  const updatedDataset = useMemo(() => {
    const newDs = createResultDataset({ ...dataset });

    const suggestionName = 'resultData';

    if (
      !_isNil(newDs.dataSet) &&
      !_isNil(newDs.dataSet.attributes) &&
      _isNil(newDs.dataSet.attributes[suggestionName])
    ) {
      const suggestionPayload: Attributes = {
        name: suggestionName,
        dataType: 'list',
        executedValue: [
          Object.keys(newDs.dataSet.attributes).reduce(
            (acc: Record<string, any>, key: string) => {
              acc[key] = newDs.dataSet.attributes[key].executedValue;

              return acc;
            },
            {}
          ),
        ],
      };
      newDs.dataSet.attributes = {
        ...newDs.dataSet.attributes,
        [suggestionName]: {
          ...suggestionPayload,
        },
      };
    }

    const outputData: Dataset = {
      name: 'Output Data',
      id: 'outputData',
      attributes: fields.reduce((acc: any, curr: any, i) => {
        if (i >= fields.length) {
          return acc;
        }

        return {
          ...acc,
          [curr.keyName]: {
            name: curr.keyName,
            dataType: curr.dataType,
            executedValue: curr.executedValue,
          },
        };
      }, {}),
    };

    return {
      ...newDs,
      outputData,
    };
  }, [JSON.stringify(fields), JSON.stringify(dataset)]);

  const { openWithProps: openJsonEditor } = useOpenJsonEditorSheet({
    control,
    type: 'json',
  });

  const [suggestionsObj, setSuggestionsObj] = useState<NectedSuggestionModel[]>(
    []
  );

  const { openWithProps: openJsEditor } = useOpenJsEditorSheet({
    control,
  });

  const { openWithProps: openExcelEditor } = useLayer(<ExcelModal name="" />);

  useEffect(() => {
    const suggestionObjList: NectedSuggestionModel[] = [];

    field.forEach((f: ResultAddDataModel) => {
      const source = field.source ?? null;
      const attribute = field.attribute ?? null;
      let executedValue = field.executedValue;

      if (
        _isNil(source) &&
        _isNil(attribute) &&
        !['jsFormula', 'json', 'excelFormula', 'list'].includes(
          field.dataType ?? ''
        )
      ) {
        executedValue = getEvaluatedExValueForResult(
          field.value,
          field.dataType
        );
      }

      if (f.dataType === 'string' || f.dataType === 'date') {
        suggestionObjList.push({
          name: `"<<outputData.${f.keyName}>>"`,
          value: `"<<outputData.${f.keyName}>>"`,
          meta: f.dataType,
          score: TokenScores.outputData,
          executedValue,
        });
      } else {
        suggestionObjList.push({
          name: `<<outputData.${f.keyName}>>`,
          value: `<<outputData.${f.keyName}>>`,
          meta:
            f.dataType === 'jsFormula' ? f.returnType ?? 'unknown' : f.dataType,
          score: TokenScores.outputData,
          executedValue,
        });
      }
    });

    setSuggestionsObj(suggestionObjList);
  }, [fields]);

  const handleAddNonPrimitiveDataType = (dataType: string) => {
    const lastIndex = fields.length;
    const editorParams = {
      index: lastIndex,
      name: `${section}.${lastIndex}.value`,
      returnTypeName: `${section}.${lastIndex}.returnType`,
      executedValueName: `${section}.${lastIndex}.executedValue`,
      section,
      control,
    };

    if (dataType === 'jsFormula') {
      openJsEditor({
        ...editorParams,
        hideOptionalCustomAttributes: true,
        setOriginalValue: setValue,
        suggestionObjs: suggestionsObj,
        dataSet: updatedDataset,
      });
    }

    if (dataType === 'json' || dataType === 'list') {
      openJsonEditor({
        ...editorParams,
        hideOptionalCustomAttributes: true,
        setOriginalValue: setValue,
        suggestionObjs: suggestionsObj,
        type: dataType,
        dataSet: updatedDataset,
      });
    }

    if (dataType === 'excelFormula') {
      openExcelEditor({
        ...editorParams,
        hideOptionalCustomAttributes: true,
        setOriginalValue: setValue,
        suggestionObjs: suggestionsObj,
        dataSet: updatedDataset,
        isReadOnly: isRuleReadOnly,
      });
    }

    if (dataType === 'excelFormula') {
      openExcelEditor({
        ...editorParams,
        hideOptionalCustomAttributes: true,
        setOriginalValue: setValue,
        suggestionObjs: suggestionsObj,
        dataSet: updatedDataset,
        isReadOnly: isRuleReadOnly,
      });
    }
  };

  return { handleAddNonPrimitiveDataType };
}
