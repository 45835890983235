import { IoHelp } from 'react-icons/io5';
import { MdCable } from 'react-icons/md';
import { TbClockCog } from 'react-icons/tb';
import { VscSettingsGear } from 'react-icons/vsc';

export const panelList = [
  {
    Icon: VscSettingsGear,
    id: 'settings',
  },
  {
    Icon: TbClockCog,
    id: 'versionControl',
  },
  // Will be uncommented later on
  // {
  //   Icon: IoBulbOutline,
  //   id: 'info',
  // },
  {
    Icon: MdCable,
    id: 'dependencyUsage',
  },
  {
    Icon: IoHelp,
    id: 'faqMenu',
  },
];
