import { Inline, Stack } from '@bedrock-layout/primitives';
import styled from 'styled-components';

export const ActionBlockContainer = styled(Inline)`
  flex-wrap: wrap;
  align-items: stretch;
  max-height: 30rem;
  overflow-y: scroll;
`;
export const ActionBlock = styled(Inline)`
  width: calc(50% - 0.5rem);
  border: 1px solid var(--color-whiteSmoke);
  border-radius: 6px;
  cursor: pointer;
`;

export const CardBlock = styled(Inline)`
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  background: rgb(251, 247, 255);
  -webkit-box-pack: justify;
  justify-content: space-between;
  width: calc(50% - 0.5rem);
  align-items: center;
`;
export const CreateBlock = styled(Inline)`
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  background: #f7faff;
  -webkit-box-pack: justify;
  width: calc(50% - 0.5rem);
  align-items: center;
`;

export const ExploreTemplateBlock = styled(Inline)`
  border-radius: 6px;
  cursor: pointer;
  display: flex;
  background: #f7faff;
  -webkit-box-pack: justify;
  align-items: center;
  margin-bottom: 4rem;
`;

export const CustomClose = styled.div`
  position: absolute;
  top: 1rem;
  right: 2rem;
`;

export const CreateButtonContainer = styled(Stack)`
  width: 67%;
  text-align: left;
`;

export const ORBlock = styled.div`
  color: rgba(0, 0, 0, 0.48);
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 600;
  margin: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IconContainer = styled.div`
  width: 10rem;
`;
