import { PadBox } from '@bedrock-layout/padbox';
import { Stack } from '@bedrock-layout/stack';
import styled from 'styled-components';

export const TriggerContainer = styled(PadBox)`
  block-size: 8rem;
  inline-size: 8rem;
  border-radius: 4px;
  background-color: var(--color-white);
  box-shadow: 0px 0px 17px 4px rgba(0, 0, 0, 0.12);
  display: flex;
  position: relative;
  flex-direction: column;
  cursor: pointer;
`;

export const TriggerInfoContainer = styled(PadBox)`
  position: absolute;
  left: 110%;
  top: 0;
  block-size: 100%;
  display: flex;
  flex-direction: column;
`;

export const APIContainer = styled(Stack)`
  margin-top: auto;
  min-inline-size: 20rem;
  position: relative;
`;

export const IconContainer = styled(PadBox)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  block-size: 8rem;
  inline-size: 8rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ApiWebhookInput = styled.input`
  max-inline-size: 20rem;
  border: 1px solid var(--color-lightGray6);
  block-size: 2rem;
  background-color: #eee;
  font-size: 1rem;
  padding: 0.2rem 2rem 0.2rem 0.2rem;
`;

export const IconContainerRight = styled.div`
  position: absolute;
  right: 0.3rem;
  top: 1.3rem;
`;
