import { Inline } from '@bedrock-layout/inline';
import { ReactNode, Ref, forwardRef } from 'react';

import { ListItemStyled } from './ListItem.styled';

export type ListItemProps = {
  onClick?: () => void;
  children: ReactNode[];
  active: boolean;
  metaInfo?: Record<string, any>;
};

export const ListItem = forwardRef(
  (
    { onClick, children, active = false, metaInfo }: ListItemProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const id = metaInfo?.id;
    const trigger = metaInfo?.trigger;

    return (
      <ListItemStyled
        padding={11}
        onClick={() => {
          if (typeof onClick === 'function') {
            onClick();
          }
        }}
        $active={active}
        ref={ref}
      >
        <Inline
          id={id}
          data-premium-component-id={id}
          data-premium-component-trigger={trigger}
          gutter={11}
          align="center"
          minItemWidth={22}
        >
          {children}
        </Inline>
      </ListItemStyled>
    );
  }
);

ListItem.displayName = 'ListItem';
