import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { MdEdit } from 'react-icons/md';
import { Typography, useLayer } from 'ui';

import { JsNodeSheet } from '../JsNodeSheet/JsNodeSheet';
import { SqlPillStyled } from './RuleSqlCondition.styled';

export type JsNodePillProps = {
  ruleId: string;
  name?: string;
};

export function JsNodePill({ ruleId, name }: JsNodePillProps) {
  const { open: openCodeNode } = useLayer(<JsNodeSheet ruleId={ruleId} />);

  return (
    <SqlPillStyled onClick={openCodeNode} padding={[2, 12]}>
      <Typography>
        {_isNil(name) || _isEmpty(name) ? 'Untitled' : name}
      </Typography>

      <MdEdit />
    </SqlPillStyled>
  );
}
