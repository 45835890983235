import styled, { css } from 'styled-components';
import theme from 'styled-theming';

import type { TextButtonAppearance } from './TextButton';

const disabledCssRules = css`
  cursor: not-allowed;
  color: var(--color-darkGray);
`;

const textButtonAppearance = theme('appearance', {
  primary: css`
    color: var(--color-primary1);

    &.btn-disabled-interceptor {
      cursor: progress;
    }

    &:not(.btn-disabled-interceptor):disabled {
      ${disabledCssRules};
    }
  `,
  secondary: css`
    color: var(--color-charcoal);

    &.btn-disabled-interceptor {
      cursor: progress;
    }

    &:not(.btn-disabled-interceptor):disabled {
      ${disabledCssRules};
    }
  `,
  danger: css`
    color: var(--color-fireEngineRed);

    &.btn-disabled-interceptor {
      cursor: progress;
    }

    &:not(.btn-disabled-interceptor):disabled {
      ${disabledCssRules};
    }
  `,
});

type TextButtonStyledProps = {
  appearance: TextButtonAppearance;
};

export const TextButtonStyled = styled.button.attrs({
  type: 'button',
})<TextButtonStyledProps>`
  ${textButtonAppearance}
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 0;
`;
