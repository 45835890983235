import { Inline, PadBox, Stack } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  ExpandingTextField,
  Sheet,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Typography,
  toasts,
  useCurrentLayer,
  useLayer,
} from 'ui';

import { stopPropagate } from '../../../../../utils/form';
import {
  changedNodeIdsAtom,
  isWorkflowReadOnlyAtom,
  isWorkflowTestOnlyAtom,
  workflowErrorByNodeAtom,
  workflowNodeSavingAtom,
  workflowNodesAtom,
} from '../../../atoms/atoms';
import { useTestLoopNode } from '../../../hooks/useTestLoopNode';
import type { LoopNodeForm } from '../../../models/models';
import {
  checkIfNameExists,
  getExecutedValueAndStatus,
  transformLoopNodeData,
} from '../../../utils/common';
import {
  nodeNameValidationBeforeSave,
  validateLoopNode,
  validateTimeoutOnCheck,
} from '../../../utils/validations';
import {
  SheetFooterStyled,
  WorkflowSheetFormStyled,
  WorkflowSheetTabContentStyled,
} from '../../CommonStyles/CommonStyles.styled';
import { RuleSheetCloseModal } from '../../Modals/RuleSheetCloseModal/RuleSheetCloseModal';
import { LoopNodeMapping } from './LoopNodeMapping';
import { LoopSettings } from './LoopNodeSettings';
import { LoopNodeTest } from './LoopNodeTest';

type LoopNodeSheetProps = {
  data?: any;
  id?: string;
};

export function LoopNodeSheet({
  data: localData,
  id = '',
}: LoopNodeSheetProps) {
  const [workflowNodes] = useAtom(workflowNodesAtom);
  const [isWorkflowReadOnly] = useAtom(isWorkflowReadOnlyAtom);
  const [isWorkflowTestOnly] = useAtom(isWorkflowTestOnlyAtom);

  const [, setWorkflowErrorByNode] = useAtom(workflowErrorByNodeAtom);
  const [, setChangedNodeIds] = useAtom(changedNodeIdsAtom);
  const [workflowNodeSaving, setIsWorkflowNodeSaving] = useAtom(
    workflowNodeSavingAtom
  );

  const [counter, setCounter] = useState(0);
  const [initialFormRender, setInitialFormRender] = useState(false);

  const [isSaving, setIsSaving] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [nodeTestData, setNodeTestData] = useState<Record<string, any>>({});

  const workflowNode = workflowNodes.find((wn) => wn.id === id);

  const { control, handleSubmit, setValue, watch, setError, clearErrors } =
    useForm<LoopNodeForm>({
      defaultValues: {
        input: localData?.input ?? {},
        settings: {
          timeout: 1000,
          ...(localData.settings ?? {}),
        },
        name: localData.name,
      },
    });

  const {
    testData,
    loopData,
    loopError,
    updatedDataSet,
    currentStatus,
    isTesting,
    setIsTesting,
    setCurrentStatus,
    executionId,
  } = useTestLoopNode({
    id,
    localData,
  });

  const { close } = useCurrentLayer();

  const { open: openSheetCloseModal } = useLayer(
    <RuleSheetCloseModal onClose={close} title="Close loop sheet" />
  );

  const formValues = watch();

  useEffect(() => {
    if (initialFormRender) {
      setCounter((count) => count + 1);
      clearErrors();
    }
  }, [JSON.stringify(formValues), initialFormRender]);

  useEffect(() => {
    if (counter > 1) {
      setCurrentStatus('');
    }
  }, [counter]);

  useEffect(() => {
    const { name, input, settings } = localData;

    setValue('input', input);
    setValue('settings', settings);
    setValue('name', name);

    setInitialFormRender(true);
  }, []);

  useEffect(() => {
    if (!workflowNodeSaving && isTesting) {
      void handleTestNode();
    }
  }, [workflowNodeSaving, isTesting]);

  const onSubmit = async (dt: LoopNodeForm, test: boolean = false) => {
    setIsSaving(true);

    clearErrors();
    setWorkflowErrorByNode((prev) => ({
      ...prev,
      [id]: undefined,
    }));

    const isNameValid = nodeNameValidationBeforeSave(dt.name, setError);

    if (!isNameValid) {
      setIsSaving(false);
      setIsTesting(false);

      return;
    }

    const isTimeoutValid = validateTimeoutOnCheck(
      dt.settings,
      'settings',
      setError
    );

    if (!isTimeoutValid.valid) {
      setIsSaving(false);

      return toasts.error(isTimeoutValid.message, 'timeout-validity');
    }

    const sheetData = transformLoopNodeData(dt);

    if (!_isNil(workflowNode)) {
      const newWorkflowNode = workflowNode;
      newWorkflowNode.data.status = currentStatus;

      if (
        dt.name !== localData.name &&
        typeof localData.updateOnNameChange === 'function'
      ) {
        const doesNameExist = checkIfNameExists(
          workflowNodes,
          dt.name,
          newWorkflowNode
        );

        if (doesNameExist) {
          setError('name', {
            message: 'Duplicate name provided',
          });

          setIsSaving(false);
          setIsTesting(false);

          return null;
        }

        localData.updateOnNameChange({
          id,
          name: localData.name,
          newName: dt.name,
        });
      }

      newWorkflowNode.data.name = sheetData.name;

      newWorkflowNode.data.settings = sheetData.settings;

      newWorkflowNode.data.input = sheetData.input;

      newWorkflowNode.data.executedValue =
        currentStatus !== ''
          ? !_isNil(loopData) && !_isNil(loopData?.data)
            ? getExecutedValueAndStatus(loopData).executedValue
            : newWorkflowNode.data.executedValue ?? {}
          : null;

      if (counter > 1) {
        setChangedNodeIds([id]);
        setIsWorkflowNodeSaving(true);
      }

      localData.onWorkflowNodeChange(newWorkflowNode);
    }

    if (!test) {
      close();
    } else {
      const isValid = validateLoopNode(dt, updatedDataSet, setError);

      if (isValid) {
        setNodeTestData(sheetData.input);
        setIsTesting(true);
        setCounter(2);
      }
    }

    setIsSaving(false);

    return null;
  };

  const handleSaveData = stopPropagate(
    handleSubmit(async (data) => await onSubmit(data, false))
  );

  const handleSaveDataAndTest = stopPropagate(
    handleSubmit(async (data) => await onSubmit(data, true))
  );

  const handleTestNode = async () => {
    try {
      await testData(nodeTestData, () => {
        setCurrentTab(1);
        setIsTesting(false);
        setNodeTestData({});
      });
    } catch (error) {}
    setIsTesting(false);
  };

  const isLoading = workflowNodeSaving || isTesting || isSaving;

  return (
    <Sheet size="small" onClose={counter > 2 ? openSheetCloseModal : close}>
      <WorkflowSheetFormStyled>
        <Inline stretch="start">
          <Stack as={PadBox} gutter={48} padding={[16, 24]}>
            <Inline stretch="start">
              <Stack gutter={8}>
                <Inline align="center" gutter="1.6rem" justify="start">
                  <Typography name="heading2">
                    <ExpandingTextField
                      control={control}
                      name="name"
                      disabled={isWorkflowReadOnly}
                    />
                  </Typography>
                </Inline>
              </Stack>
            </Inline>
          </Stack>
        </Inline>

        <Tabs defaultOpen={currentTab} onTabChange={(i) => setCurrentTab(i)}>
          <TabList>
            <Tab>
              <Typography fontWeight={700}>Input Params</Typography>
            </Tab>

            <Tab>
              <Typography fontWeight={700} name={'paragraph'}>
                Test Results
              </Typography>
            </Tab>

            <Tab>
              <Typography fontWeight={700}>Settings</Typography>
            </Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <WorkflowSheetTabContentStyled>
                <PadBox padding="2rem">
                  <LoopNodeMapping
                    control={control}
                    setValue={setValue}
                    watch={watch}
                    dataSet={updatedDataSet}
                  />
                </PadBox>
              </WorkflowSheetTabContentStyled>
            </TabPanel>
            <TabPanel>
              <LoopNodeTest
                outputValue={
                  !_isNil(loopData?.data?.data)
                    ? getExecutedValueAndStatus(loopData).executedValue
                    : loopError?.response?.data ?? localData.executedValue
                }
                executionId={executionId}
              />
            </TabPanel>
            <TabPanel>
              <LoopSettings control={control} name="settings" />
            </TabPanel>
          </TabPanels>
        </Tabs>

        <SheetFooterStyled>
          <Inline
            style={{
              padding: '0.8rem',
            }}
            justify="end"
            gutter="1rem"
          >
            <Button
              appearance="filled"
              disabled={isLoading || !isWorkflowTestOnly}
              onClick={handleSaveDataAndTest}
            >
              {isLoading ? (
                <Spinner size="extraSmall" />
              ) : (
                <Inline>Test</Inline>
              )}
            </Button>

            <Button
              appearance="contained"
              onClick={handleSaveData}
              disabled={isWorkflowReadOnly || isLoading}
            >
              {isLoading && !isTesting ? (
                <Spinner size="extraSmall" />
              ) : (
                <Inline>Save</Inline>
              )}
            </Button>
          </Inline>
        </SheetFooterStyled>
      </WorkflowSheetFormStyled>
    </Sheet>
  );
}
