import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _isUndefined from 'lodash/isUndefined';
import _map from 'lodash/map';
import _reduce from 'lodash/reduce';
import { useEffect, useState } from 'react';
import {
  UseControllerProps,
  UseFormSetValue,
  useForm,
  useWatch,
} from 'react-hook-form';
import {
  Attributes,
  Button,
  DataTypes,
  Dataset,
  NectedEditorField,
  NectedSuggestionModel,
  Sheet,
  Typography,
  toasts,
  useCurrentLayer,
  useLayer,
} from 'ui';

import { siteConstantsAtom } from '../../../../../atom';
import { customAttributesAtom } from '../../../../../components/rules/forms/CustomAttributeSheet/CustomAttributeSheet';
import {
  convertCaSampleValues,
  getDataTypeByReturnType,
  getOutputDataByExecutedValue,
  getPropertyIfExists,
  getTooltipText,
  isCorrectJsSyntaxV2,
} from '../../../../../utils/common';
import {
  TokenDataTypeByDataType,
  TokenDataTypeByDataTypeSingle,
  TokenScores,
  editorDomain,
} from '../../../../../utils/constant';
import { useGenerateDataset } from '../../../../Workflow/hooks/useGenerateDataset';
import { useUpdateExecutedValueRules } from '../../../hooks/useUpdateExecutedValueRules';
import {
  createRuleSheetAtom,
  isRuleReadOnlyAtom,
  selectedDataSetAtom,
} from '../../../index';
import { jsNodeResultQuery } from '../../../schema';
import {
  filterDataSetSuggestionObjBySection,
  filterDataSetSuggestionsBySection,
  getDatasetWithoutReactCode,
  getEvaluatedExValueForResult,
  getFilteredDataSetObjWithoutOptional,
  getFilteredDataSetWithoutOptional,
  getRequiredKey,
  updateDataSetOnChange,
} from '../../../utils/common';
import { getDtOutputRows } from '../../../utils/decisionTable';
import {
  dataSetParamsAtom,
  sectionAtom,
} from '../../CreateRuleSheet/CreateRuleSheet';
import { DecisionTableResultRow } from '../../DecisionTable/types';
import { SyntaxErrorContainer } from '../../RuleComponents/RuleComponents.styled';
import { ResultAddDataModel } from '../models';
import { suggestionsObjAtom } from './JsNodePill';
import {
  JsBlockContainer,
  JsFieldContainer,
  SaveButtonContainer,
} from './JsNodeSheet.styled';
import { suggestionsAtom } from './JsonNodePill';
import { OnCloseNodeModal } from './OnCloseNodeModal';
import type { ResultType } from './Results';
import { SaveNodeModal } from './SaveNodeModal';

export type JsNodeSheetProps = UseControllerProps & {
  index: number;
  section: ResultType;
  isAdditionalData?: boolean;
  isDecisionTableAction?: boolean;
  hideOptionalCustomAttributes?: boolean;
  suggestionObjs?: NectedSuggestionModel[];
  setOriginalValue?: UseFormSetValue<any>;
  returnTypeName?: string;
  executedValueName?: string;
  additionalDataIndex?: number;
  dataSet?: Record<string, Dataset>;
  disabled?: boolean;
};

type JsNodeSheetResult = {
  nodeQuery: string;
};

export function JsNodeSheet({
  name,
  control,
  index,
  section,
  isAdditionalData = false,
  isDecisionTableAction = false,
  hideOptionalCustomAttributes = false,
  suggestionObjs = [],
  setOriginalValue,
  returnTypeName,
  executedValueName,
  additionalDataIndex = 0,
  dataSet = {},
  disabled = false,
}: JsNodeSheetProps) {
  const [dataSetVar] = useAtom(dataSetParamsAtom);
  const dataSetVariables = !_isEmpty(dataSet) ? dataSet : dataSetVar;
  const [suggestions, setSuggestions] = useAtom(suggestionsAtom);
  const [, setSuggestionsObj] = useAtom(suggestionsObjAtom);
  const [isReadOnly] = useAtom(isRuleReadOnlyAtom);
  const [currentSection] = useAtom(sectionAtom);
  const [ruleType] = useAtom(createRuleSheetAtom);
  const [siteConstants] = useAtom(siteConstantsAtom);
  const [customAttributes] = useAtom(customAttributesAtom);
  const [dataSetSelected] = useAtom(selectedDataSetAtom);

  const [, setDataSetTokens] = useState<string[]>([]);
  const [hasChanged, setHasChanged] = useState(false);
  const [returnType, setReturnType] = useState<string | null>(null);
  const [executedValue, setExecutedValue] = useState<any | null>(null);
  const [isReturnTypeRequired, setIsReturnTypeRequired] = useState(false);
  const [isQueryValidNected, setIsQueryValidNected] = useState(true);
  const [datasetTokensObj, setDataSetTokensObjs] = useState<
    NectedSuggestionModel[]
  >([]);
  const [, setDataSetResults] = useState<ResultAddDataModel[]>([]);
  const [isMounted, setIsMounted] = useState(false);

  const [updatedDataset, setUpdatedDataset] = useState<Record<string, Dataset>>(
    {}
  );

  const nodeQuery = useWatch({ control, name });

  const {
    control: localControl,
    handleSubmit,
    watch,
  } = useForm<JsNodeSheetResult>({
    resolver: zodResolver(jsNodeResultQuery),
    defaultValues: {
      nodeQuery:
        _isEmpty(nodeQuery) || _isNil(nodeQuery)
          ? getTooltipText(
              siteConstants,
              'rules',
              'formulaInCondition',
              'otherText'
            )
          : nodeQuery,
    },
    mode: 'onSubmit',
  });

  const localQuery = watch('nodeQuery');

  const results: DecisionTableResultRow[] = useWatch({
    name: 'results',
    control,
  });

  const additionalData = useWatch({ control, name: 'additionalData' });

  const rows = useWatch({ control, name: 'rows' });

  const { close } = useCurrentLayer();

  const fields = useWatch({ name: currentSection, control });

  const { tokens: dataSetSuggestionsObj } = useGenerateDataset({
    updatedDataset,
  });

  const { executedValue: execValues, handleGetExecutionValues } =
    useUpdateExecutedValueRules({
      updatedDataset,
    });

  useEffect(() => {
    let filteredDataSetVariablesObj = filterDataSetSuggestionObjBySection(
      dataSetSuggestionsObj,
      section
    );

    if (hideOptionalCustomAttributes) {
      filteredDataSetVariablesObj = getFilteredDataSetObjWithoutOptional(
        filteredDataSetVariablesObj,
        customAttributes
      );
    }

    setDataSetTokensObjs([
      ...filteredDataSetVariablesObj,
      ...dataSetSuggestionsObj,
    ]);
  }, [JSON.stringify(dataSetSuggestionsObj)]);

  useEffect(() => {
    const additionalDataAttributes: Record<string, Attributes> = {};

    additionalData?.forEach((aData: any, aIndex: number) => {
      if (aIndex < additionalDataIndex) {
        additionalDataAttributes[aData.name] = {
          name: aData.name,
          dataType: aData.returnType ?? 'unknown',
          executedValue: aData.executedValue ?? undefined,
        };
      }
    });

    setUpdatedDataset((prev) => ({
      ...prev,
      additionalData: {
        attributes: additionalDataAttributes,
        name: 'Additional Data Attributes',
        id: 'additional_data_attributes',
      },
    }));
  }, [additionalData]);

  useEffect(() => {
    setUpdatedDataset((prev) => {
      return {
        ...prev,
        ...dataSet,
      };
    });
  }, [JSON.stringify(getDatasetWithoutReactCode(dataSet))]);

  useEffect(() => {
    if (!_isNil(dataSetVariables)) {
      const dataSetSuggestions = _reduce(
        dataSetVariables,
        (result: string[], value, key) => {
          if (!_isNil(value.attributes)) {
            return [
              ...result,
              ..._map(value.attributes, (attributeValue, attributeKey) => {
                if (
                  ['string', 'dateTime', 'date'].includes(
                    attributeValue.dataType
                  )
                ) {
                  return `"<<${key}.${attributeKey}>>"`;
                }

                return `<<${key}.${attributeKey}>>`;
              }),
            ];
          }

          return result;
        },
        []
      );

      setUpdatedDataset((prev) => ({
        ...prev,
        ...updateDataSetOnChange(
          customAttributes,
          dataSetVariables,
          dataSetSelected,
          false,
          true
        ),
      }));

      let tokenSuggestions = suggestions.reduce<string[]>(
        (result, curr, currentIndex) => {
          if (ruleType === 'simpleRule' && index > currentIndex) {
            return [...result, curr];
          } else if (ruleType === 'decisionTable') {
            return [...result, curr];
          }

          return result;
        },
        []
      );

      let filteredDataSetVariables = filterDataSetSuggestionsBySection(
        dataSetSuggestions,
        section
      );

      if (hideOptionalCustomAttributes) {
        filteredDataSetVariables = getFilteredDataSetWithoutOptional(
          filteredDataSetVariables,
          customAttributes
        );

        tokenSuggestions = getFilteredDataSetWithoutOptional(
          tokenSuggestions,
          customAttributes
        );
      }

      setDataSetTokens([...filteredDataSetVariables, ...tokenSuggestions]);
    }
  }, [
    JSON.stringify(getDatasetWithoutReactCode(dataSetVariables)),
    suggestions,
    index,
    customAttributes,
  ]);

  useEffect(() => {
    const suggestionList: string[] = [];
    const suggestionListObjs: NectedSuggestionModel[] = [];

    const outputDataAttributes: Record<string, Attributes> = {};

    fields?.forEach((field: ResultAddDataModel, currIndex: number) => {
      const source = field.source ?? null;
      const attribute = field.attribute ?? null;
      let executedValue = field.executedValue;

      if (
        _isNil(source) &&
        _isNil(attribute) &&
        !['jsFormula', 'json', 'excelFormula', 'list'].includes(
          field.dataType ?? ''
        )
      ) {
        executedValue = getEvaluatedExValueForResult(
          field.value,
          field.dataType
        );
      }

      if (currIndex < index) {
        outputDataAttributes[field.keyName] = {
          name: field.keyName,
          dataType: ['jsFormula', 'excelFormula'].includes(field.dataType)
            ? // eslint-disable-next-line
              (!!field.returnType as boolean)
              ? field.returnType
              : 'unknown'
            : field.dataType,
          executedValue,
        };
      }

      if (
        field.dataType === 'string' ||
        field.dataType === 'date' ||
        (['jsFormula', 'excelFormula'].includes(field.dataType) &&
          (field.returnType === 'string' ||
            field.returnType === 'date' ||
            field.returnType === 'datetime'))
      ) {
        suggestionList.push(`"<<outputData.${field.keyName}>>"`);

        suggestionListObjs.push({
          name: `"<<outputData.${field.keyName}>>"`,
          value: `"<<outputData.${field.keyName}>>"`,
          score: TokenScores.outputData,
          meta: ['jsFormula', 'excelFormula'].includes(field.dataType)
            ? // eslint-disable-next-line
              !!field.returnType
              ? field.returnType
              : 'unknown'
            : field.dataType,
          executedValue,
        });
      } else {
        suggestionList.push(`<<outputData.${field.keyName}>>`);

        suggestionListObjs.push({
          name: `<<outputData.${field.keyName}>>`,
          value: `<<outputData.${field.keyName}>>`,
          score: TokenScores.outputData,
          meta: ['jsFormula', 'excelFormula'].includes(field.dataType)
            ? // eslint-disable-next-line
              (!!field.returnType as boolean)
              ? field.returnType
              : 'unknown'
            : field.dataType,
          executedValue,
        });
      }
    });

    if (ruleType === 'simpleRule') {
      setDataSetResults(fields);
      setSuggestions(suggestionList);
      setSuggestionsObj(suggestionListObjs);

      setUpdatedDataset((prev) => ({
        ...prev,
        outputData: {
          attributes: outputDataAttributes,
          name: 'Output Data Attributes',
          id: 'output_data_attributes',
        },
      }));
    }
  }, [fields, currentSection, ruleType]);

  useEffect(() => {
    const suggestionList: string[] = [];
    const suggestionListObj: NectedSuggestionModel[] = [];

    const resultAddDataModels: ResultAddDataModel[] = [];

    const outputDataAttributes: Record<string, Attributes> = {};

    results?.forEach((field, currIndex) => {
      const fieldKey = getRequiredKey(field, ['id']);
      const currentResult = field[fieldKey];

      const rowKey = getRequiredKey(rows[0], ['id']);
      const firstRow = rows[0][rowKey];

      const ruleResultKey = getRequiredKey(firstRow.ruleResult[currIndex], [
        'id',
      ]);

      const firstOutput = firstRow.ruleResult[currIndex]?.[ruleResultKey] ?? {};

      const { source, attribute } = firstOutput;

      const mappedValue =
        !_isNil(source) &&
        !_isNil(attribute) &&
        !_isNil(dataSetVariables[source])
          ? getPropertyIfExists(
              JSON.parse(
                JSON.stringify(
                  Object.keys(dataSetVariables[source].attributes).reduce(
                    (acc, curr) => {
                      return {
                        ...acc,
                        [curr]:
                          dataSetVariables[source].attributes[`${curr}`]
                            .executedValue,
                      };
                    },
                    {}
                  )
                )
              ) ?? {},
              attribute
            )
          : firstOutput.value;

      if (
        currentResult.dataType !== 'json' &&
        currentResult.dataType !== 'jsFormula' &&
        currentResult.dataType !== 'excelFormula'
      ) {
        if (isAdditionalData) {
          suggestionList.push(`<<outputDataList.${currentResult.keyName}>>`);
          suggestionListObj.push({
            name: `<<outputDataList.${currentResult.keyName}>>`,
            value: `<<outputDataList.${currentResult.keyName}>>`,
            meta: `array[${TokenDataTypeByDataType[currentResult.dataType]}]`,
            score: TokenScores.outputDataList,
            executedValue: [
              convertCaSampleValues(currentResult.dataType, firstOutput.value),
            ],
          });

          if (currIndex !== index) {
            // TODO: Might need to change dataType and add all executed values depending on editor support
            outputDataAttributes[currentResult.keyName] = {
              name: currentResult.keyName,
              dataType: `array[${currentResult.dataType}]` as DataTypes,
              executedValue: [
                convertCaSampleValues(currentResult.dataType, mappedValue),
              ],
            };
          }
        } else {
          suggestionList.push(`"<<outputData.${currentResult.keyName}>>"`);
          suggestionListObj.push({
            name: `"<<outputData.${currentResult.keyName}>>"`,
            value: `"<<outputData.${currentResult.keyName}>>"`,
            meta: currentResult.dataType,
            score: TokenScores.outputData,
            executedValue: firstOutput.value,
          });

          if (currIndex < index) {
            outputDataAttributes[currentResult.keyName] = {
              name: currentResult.keyName,
              dataType: currentResult.dataType as DataTypes,
              executedValue: convertCaSampleValues(
                currentResult.dataType,
                mappedValue
              ),
            };
          }
        }
      } else {
        suggestionList.push(
          `<<outputData${isAdditionalData ? 'List' : ''}.${
            currentResult.keyName
          }>>`
        );

        suggestionListObj.push({
          name: `<<outputData${isAdditionalData ? 'List' : ''}.${
            currentResult.keyName
          }>>`,
          value: `<<outputData${isAdditionalData ? 'List' : ''}.${
            currentResult.keyName
          }>>`,
          meta: isAdditionalData
            ? `array[${
                ['jsFormula', 'excelFormula'].includes(currentResult.dataType)
                  ? (getDataTypeByReturnType(firstOutput) as string)
                  : TokenDataTypeByDataType[currentResult.dataType]
              }]`
            : TokenDataTypeByDataTypeSingle[currentResult.dataType],
          score: TokenScores[`outputData${isAdditionalData ? 'List' : ''}`],
          executedValue: getOutputDataByExecutedValue(
            currentResult,
            isAdditionalData,
            firstOutput
          ),
        });

        if (currIndex < index) {
          const calculatedDataType = (
            isAdditionalData
              ? `array[${
                  ['jsFormula', 'excelFormula'].includes(currentResult.dataType)
                    ? (getDataTypeByReturnType(firstOutput) as string)
                    : TokenDataTypeByDataType[currentResult.dataType]
                }]`
              : ['jsFormula', 'excelFormula'].includes(currentResult.dataType)
              ? (getDataTypeByReturnType(firstOutput) as string)
              : TokenDataTypeByDataType[currentResult.dataType]
          ) as DataTypes;

          // TODO: Might need to change dataType and add all executed values depending on editor support
          outputDataAttributes[currentResult.keyName] = {
            name: currentResult.keyName,
            dataType: calculatedDataType,
            executedValue: getOutputDataByExecutedValue(
              currentResult,
              isAdditionalData,
              firstOutput
            ),
          };
        }
      }

      resultAddDataModels.push({
        keyName: currentResult.keyName,
        dataType: currentResult.dataType,
        value: '',
      });
    });

    if (ruleType === 'decisionTable') {
      setSuggestionsObj(suggestionListObj);
      setDataSetResults(resultAddDataModels);
      setSuggestions(suggestionList);

      if (isAdditionalData) {
        const finalRows = getDtOutputRows(results, rows, dataSetVariables);

        setUpdatedDataset((prev) => ({
          ...prev,
          outputData: {
            attributes: {
              output: {
                dataType: 'list',
                name: 'output',
                executedValue: finalRows,
              },
            },
            name: 'Output Data Attributes',
            id: 'output_data_attributes',
          },
        }));
      } else {
        setUpdatedDataset((prev) => ({
          ...prev,
          [isAdditionalData ? 'outputDataList' : 'outputData']: {
            attributes: outputDataAttributes,
            name: 'Output Data Attributes',
            id: 'output_data_attributes',
          },
        }));
      }
    }
  }, [
    results,
    rows,
    ruleType,
    JSON.stringify(getDatasetWithoutReactCode(dataSetVariables)),
  ]);

  useEffect(() => {
    if (
      !_isEmpty(localQuery) &&
      isMounted &&
      typeof setOriginalValue === 'function'
    ) {
      if (
        !_isNil(executedValueName) &&
        !_isEmpty(executedValueName) &&
        !_isNil(executedValue)
      ) {
        setOriginalValue(executedValueName, executedValue);
      }
    }

    if (
      !_isEmpty(localQuery) &&
      !_isNil(returnType) &&
      returnType !== 'undefined' &&
      typeof setOriginalValue === 'function'
    ) {
      if (!_isNil(returnTypeName) && !_isEmpty(returnTypeName)) {
        setOriginalValue(returnTypeName, returnType);
      }

      setOriginalValue(name, watch('nodeQuery'));
      close();
    } else if (
      isMounted &&
      returnType !== 'undefined' &&
      returnType !== undefined
    ) {
      toasts.error(
        'The type of the last statement is not supported',
        'type-last-statement-not-supported'
      );
    }
  }, [returnType, executedValue]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const { openWithProps: openSaveNodeModal } = useLayer(<SaveNodeModal />);
  const { open: onCloseModal } = useLayer(
    <OnCloseNodeModal onClose={() => close()} />
  );

  const onSubmit = (data: any) => {
    if (!isQueryValidNected) {
      toasts.error('Code is invalid or empty', 'code-invalid-empty');

      return;
    }

    setIsReturnTypeRequired(true);
  };

  const onExecutionValueSave = (execValue: any) => {
    if (!_isUndefined(execValue)) {
      setExecutedValue(execValue);
    } else {
      setExecutedValue(undefined);
      openSaveNodeModal({
        onSave: () => {
          if (!_isNil(setOriginalValue)) {
            if (!_isNil(returnTypeName) && !_isEmpty(returnTypeName)) {
              setOriginalValue(returnTypeName, undefined);
            }

            if (!_isNil(executedValueName) && !_isEmpty(executedValueName)) {
              setOriginalValue(executedValueName, undefined);
            }

            setOriginalValue(name, watch('nodeQuery'));
          }

          close();
        },
      });
    }
  };

  const onSetReturnType = (type: any) => {
    if (type === 'undefined' || type === null) {
      setReturnType('undefined');
    }

    setReturnType(type);
    setIsReturnTypeRequired(false);
  };

  return (
    <Sheet
      size="medium"
      onClose={() => {
        if (hasChanged) {
          onCloseModal();
        } else {
          close();
        }
      }}
    >
      <JsBlockContainer as="form" onSubmit={handleSubmit(onSubmit)}>
        <PadBox padding="1rem">
          <Stack gutter="2rem">
            <Typography name="heading1">JS Code Editor</Typography>
            <JsFieldContainer>
              <NectedEditorField
                name="nodeQuery"
                control={localControl}
                showError={false}
                customSuggestions={datasetTokensObj}
                defaultValue={getTooltipText(
                  siteConstants,
                  'rules',
                  'formulaInResult',
                  'otherText'
                )}
                setHasEditorChanged={setHasChanged}
                onSetEditorValidity={setIsQueryValidNected}
                mode="js"
                setReturnType={onSetReturnType}
                setExecutedValue={onExecutionValueSave}
                sendReturnType={isReturnTypeRequired}
                domain={editorDomain}
                execValues={execValues}
                handleGetExecData={handleGetExecutionValues}
                readOnly={isReadOnly || disabled}
              />
            </JsFieldContainer>

            {!isQueryValidNected && (
              <SyntaxErrorContainer padding="1rem">
                <Typography>
                  {isCorrectJsSyntaxV2(localQuery, updatedDataset).message}
                </Typography>
              </SyntaxErrorContainer>
            )}
          </Stack>
        </PadBox>
        <SaveButtonContainer padding={[4, 8]}>
          <Inline justify="end">
            <Button
              appearance="contained"
              type="submit"
              disabled={isReadOnly || disabled}
            >
              Save
            </Button>
          </Inline>
        </SaveButtonContainer>
      </JsBlockContainer>
    </Sheet>
  );
}
