import { PadBox } from '@bedrock-layout/padbox';
import { Inline, InlineCluster } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import _isNil from 'lodash/isNil';
import { ReactNode } from 'react';
import { TooltipReact, Typography } from 'ui';

export type ConnectorsRowProps = {
  title: string;
  children: ReactNode[];
  tooltip?: string;
};

const connectorName: Record<string, string> = {
  Database: 'Database',
  Api: 'API',
  'Third-party': 'Third-party',
};

export function IntegrationRow({
  title,
  children,
  tooltip,
}: ConnectorsRowProps) {
  return (
    <PadBox padding={['3.6rem', '1.6rem', '1.6rem', '0rem']}>
      <Stack gutter="1.6rem">
        <Inline gutter={8} align="center">
          <Typography name="heading1">{connectorName[title]}</Typography>
          {!_isNil(tooltip) && (
            <TooltipReact id={title}>
              <Typography>{tooltip}</Typography>
            </TooltipReact>
          )}
        </Inline>
        <InlineCluster gutter="2.4rem">{children}</InlineCluster>
      </Stack>
    </PadBox>
  );
}
