import { components, DropdownIndicatorProps } from 'react-select';
import { IoIosArrowDown } from 'react-icons/io';

export function DropdownIndicator(props: DropdownIndicatorProps) {
  return (
    <components.DropdownIndicator {...props}>
      <IoIosArrowDown fontSize={18} />
    </components.DropdownIndicator>
  );
}
