import { PadBox } from '@bedrock-layout/padbox';

import type { Plugin } from '../../types';
import type { ConnectAppearance } from '../common/ConnectButton';
import { IntegrationCardDetails } from '../common/IntegrationCardDetails';
import { ConnectorCardStyled } from '../connector/ConnectorCard.styled';

export type PluginCardProps = {
  plugin: Plugin;
  connectAppearance: ConnectAppearance;
  onClick?: () => void;
};

export function PluginCard({
  plugin,
  connectAppearance,
  onClick,
}: PluginCardProps) {
  return (
    <ConnectorCardStyled>
      <PadBox padding={['4.9rem', '0.8rem', '0.8rem']}>
        <IntegrationCardDetails
          alt={plugin.displayName}
          image={plugin.imageUrl}
          name={plugin.name}
          connectAppearance={connectAppearance}
          onClick={onClick}
        />
      </PadBox>
    </ConnectorCardStyled>
  );
}
