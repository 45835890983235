import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import styled, { css } from 'styled-components';

export const SelectVersionContainer = styled(Stack)`
  overflow-y: auto;
  max-block-size: 40rem;
  min-block-size: 6rem;
  inline-size: 28rem;
`;

export const VersionItem = styled(Inline)<{
  $isSelected?: boolean;
  $showHoverStyle?: boolean;
}>`
  align-items: center;
  justify-content: start;
  padding: 0.5rem;
  font-size: 1rem;
  gap: 1rem;

  background-color: ${({ $isSelected = false }) =>
    $isSelected ? 'var(--color-primary2)' : 'var(--color-white)'};

  ${({ $showHoverStyle = true }) =>
    $showHoverStyle &&
    `:hover {
        background-color: var(--color-primary8);
        cursor: pointer;
  }`}

  .curr-version-cont {
    inline-size: 20%;
  }

  .draft-name-styled {
    flex: 1;
  }

  .auto-select-version-cont {
    flex: 1;

    .auto-selection-styled {
      background-color: var(--color-lightGreen);
      color: var(--color-waterCourse);
      padding: 0.2rem 0.8rem;
      border-radius: 0.5rem;
      max-inline-size: 15rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
`;

const getLauncherSize = (size: string) => {
  switch (size) {
    case 'medium':
      return css`
        padding: 0.8rem;

        .text-styled {
          font-size: 1.4rem;
        }
      `;
    case 'small':
    default:
      return css`
        padding: 0.5rem;

        .text-styled {
          font-size: 1.2rem;
        }
      `;
  }
};

export const LauncherContainer = styled(Inline)<{
  size: string;
  version: string;
  $showError: boolean;
}>`
  inline-size: 15rem;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  border-radius: 0.5rem;
  border: 1px solid var(--color-lightGray);
  cursor: pointer;

  ${({ $showError }) =>
    $showError && `border-color: var(--color-fireEngineRed);`}

  ${({ size }) => getLauncherSize(size)} .text-styled {
    flex: 1;
    text-transform: capitalize;
  }
`;
