import { Inline, Stack } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import { Dataset, DateTimePickerField, DropdownField, Typography } from 'ui';

import {
  DELAY_OPTION_LIST,
  INTERVAL_OPTION_LIST,
} from '../../../../../../utils/constant';
import { ResultRhs } from '../../../../../Rules/components/RestltRhs/ResultRhs';
import { isWorkflowReadOnlyAtom } from '../../../../atoms/atoms';
import { DateTimeWrapper } from './DelayMapping.styled';

type DelayMappingProps = {
  control?: Control<any>;
  setValue: UseFormSetValue<any>;
  dataset: Record<string, Dataset>;
};

export function DelayMapping({
  control,
  setValue,
  dataset,
}: DelayMappingProps) {
  const [isWorkflowReadOnly] = useAtom(isWorkflowReadOnlyAtom);

  const action = useWatch({
    control,
    name: 'action',
  });

  const showDateTime = action?.value === 'AST';
  const showAmount = action?.value === 'ATI';

  return (
    <Stack gutter={16}>
      <Stack gutter={8}>
        <Inline>
          <Typography fontWeight={700}>Action</Typography>
        </Inline>
        <DropdownField
          control={control}
          name="action"
          showCustomMenu
          options={DELAY_OPTION_LIST}
          disabled={isWorkflowReadOnly}
        />
      </Stack>
      <Stack>
        {showDateTime && (
          <Stack>
            <Inline>
              <Typography fontWeight={700}>Date and Time</Typography>
            </Inline>

            <DateTimeWrapper>
              <DateTimePickerField
                control={control}
                name="dateTime"
                showCustomInput
                minDate={new Date()}
                defaultValue={new Date()}
                disabled={isWorkflowReadOnly}
              />
            </DateTimeWrapper>
          </Stack>
        )}

        {showAmount && (
          <>
            <Stack>
              <Inline>
                <Typography fontWeight={700}>Delay Unit</Typography>
              </Inline>

              <DropdownField
                control={control}
                name="unit"
                options={INTERVAL_OPTION_LIST}
                disabled={isWorkflowReadOnly}
              />
            </Stack>

            <Stack>
              <Inline>
                <Typography fontWeight={700}>Delay Amount</Typography>
              </Inline>

              <ResultRhs
                control={control}
                dataType="numeric"
                nodeName="amount"
                allowList
                typesToAllow={['numeric']}
                setValue={setValue}
                updatedDataSet={dataset}
                disabled={isWorkflowReadOnly}
                isFull
              />
            </Stack>
          </>
        )}
      </Stack>
    </Stack>
  );
}
