import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import _reduce from 'lodash/reduce';
import { useEffect, useRef, useState } from 'react';
import {
  Control,
  UseFormSetValue,
  UseFormWatch,
  useController,
} from 'react-hook-form';
import {
  OperatorOnChange,
  OperatorsPopover,
  OperatorsProps,
  PopoverMethods,
  PopoverPanel,
} from 'ui';

import { generateUid } from '../../../../../../utils/common';
import { OperatorsLauncher } from '../../../../../Rules/components/DecisionTable/TableNodes/OperatorsLauncher';
import { operatorsLookup } from '../../../../../Rules/fixtures/operators';
import { isWorkflowReadOnlyAtom } from '../../../../atoms/atoms';
import { getBlankRhs } from '../../../../utils/common';
import { PopoverContainerStyled } from './PathBlock.styled';

type PathConditionProps = {
  control?: Control<any>;
  setValue: UseFormSetValue<any>;
  name: string;
  pathIndex: number;
  conditionId: string;
  watch: UseFormWatch<any>;
  index: number;
};

export function OperatorSelection({
  name,
  control,
  setValue,
  watch,
  index,
  pathIndex,
  conditionId,
}: PathConditionProps) {
  const ref = useRef<PopoverMethods>(null);
  const [isWorkflowReadOnly] = useAtom(isWorkflowReadOnlyAtom);

  const [filteredOperators, setFilteredOperators] = useState<OperatorsProps>(
    {}
  );

  const { fieldState } = useController({
    name,
    control,
  });

  const currentNode = watch(name);
  const currentOperator = currentNode?.operator;

  const onOperatorClick = ({
    value,
    rightOperands,
    leftOperands,
    type,
  }: OperatorOnChange) => {
    setValue(`paths.${pathIndex}.${conditionId}.operator`, value);

    const newIds: string[] = [];

    for (let i = 0; i < rightOperands; i++) {
      const newId = generateUid('rhs_');

      newIds.push(newId);

      let updatedDataType = currentNode.dataType;

      if (
        (currentNode.dataType === 'string' &&
          ['containsIn', 'notContainsIn'].includes(value)) ||
        (!['list', 'string'].includes(currentNode.dataType) &&
          ['in', 'nin'].includes(value))
      ) {
        updatedDataType = 'list';
      }

      setValue(
        `paths.${pathIndex}.${newId}`,
        getBlankRhs(newId, updatedDataType, conditionId)
      );
    }

    currentNode?.rightNode.forEach((rId: string) => {
      setValue(`paths.${pathIndex}.${rId}`, undefined);
    });

    setValue(`${name}.rightNode`, newIds);

    ref.current?.hide();
  };

  useEffect(() => {
    setFilteredOperators(
      _reduce(
        operatorsLookup,
        (result: OperatorsProps, value, key) => {
          if (key === currentNode?.dataType || key === 'generic') {
            return { ...result, [key]: operatorsLookup[key] };
          }

          return result;
        },
        {}
      )
    );
  }, [JSON.stringify(currentNode)]);

  return (
    <PopoverPanel
      trigger="click"
      placement="bottom-start"
      launcher={
        <OperatorsLauncher
          error={fieldState.error?.message}
          text={!_isNil(currentOperator) ? currentOperator : 'Operator'}
          dataType={currentNode?.type}
        />
      }
      disabled={isWorkflowReadOnly}
      ref={ref}
    >
      <PopoverContainerStyled>
        <OperatorsPopover
          operators={filteredOperators}
          onClick={onOperatorClick}
        />
      </PopoverContainerStyled>
    </PopoverPanel>
  );
}
