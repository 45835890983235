import { gql, useLazyQuery } from '@apollo/client';

const getPluginByNameQuery = gql`
  query getPlugin($filters: QueryOperators) {
    getPlugin(filters: $filters) {
      data {
        id
        name
        displayName
        imageUrl
        category
        isSource
      }
    }
  }
`;

export function useGetPluginByName() {
  return useLazyQuery(getPluginByNameQuery);
}
