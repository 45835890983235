import { AuditLogs } from '../pages/AuditTrail';
import { Credentials } from '../pages/Credentials/Credentials';
import { DataSets } from '../pages/DataSets';
import { VideoGuide } from '../pages/Guides';
import { Home } from '../pages/Home/components/Home';
import { IntegrationsPage } from '../pages/Integrations';
import { OnPremise } from '../pages/OnPremise';
import { Rules } from '../pages/Rules';
import { Workflow } from '../pages/Workflow';
import { Workspace } from '../pages/Workspace';
import { RemoteConfig } from '../pages/remoteConfig/components/RemoteConfig';
import type { RouteItem } from '../types';
import { ENTITY_ID } from '../utils/constant';

export const ALL_ROUTE: RouteItem[] = [
  {
    path: '/home',
    id: ENTITY_ID.home,
    element: Home,
  },
  {
    path: '/rules/:ruleId?/:viewLive?',
    id: ENTITY_ID.rules,
    element: Rules,
  },
  {
    path: '/workflow/:workflowId?/:viewLive?',
    id: ENTITY_ID.workflow,
    element: Workflow,
  },
  {
    path: '/integrations/:actionId?/:accessType?',
    id: ENTITY_ID.integrations,
    element: IntegrationsPage,
  },
  {
    path: '/credentials',
    id: ENTITY_ID.credentials,
    element: Credentials,
  },
  {
    path: '/datasets/:datasetId?/:viewLive?',
    id: ENTITY_ID.datasets,
    element: DataSets,
  },
  {
    path: '/remoteconfig',
    id: ENTITY_ID.remoteConfig,
    element: RemoteConfig,
  },
  {
    path: '/workspace/:tabName?',
    id: ENTITY_ID.workspace,
    element: Workspace,
  },
  {
    path: '/auditlogs/:logId?',
    id: ENTITY_ID.auditLogs,
    element: AuditLogs,
  },
  {
    path: '/onpremise',
    id: ENTITY_ID.onPremise,
    element: OnPremise,
  },
  {
    path: '/guides/:entity?',
    id: ENTITY_ID.guides,
    element: VideoGuide,
  },
];
