import { NectedLogoPadded } from '../logo';
import { BannerContainer } from './AuthBanner.styled';

export const AuthBanner = () => {
  return (
    <BannerContainer>
      <NectedLogoPadded />
    </BannerContainer>
  );
};
