import { ApolloError } from '@apollo/client';
import { Stack } from '@bedrock-layout/stack';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useMemo, useState } from 'react';
import { FaArrowDownLong } from 'react-icons/fa6';
import { IoMdArrowDropdown, IoMdArrowDropup } from 'react-icons/io';
import { LuArrowRightToLine } from 'react-icons/lu';
import { Button, IconButton, TooltipReact, Typography, toasts } from 'ui';

import {
  generateEntityLink,
  getEntityTypeForUI,
  showGraphQlErrorToast,
} from '../../../../utils/common';
import { PAGE_SIZE } from '../../../../utils/constant';
import { PaginationType } from '../../../VersionControl/VersionControl';
import { StackAsItem } from '../../../layouts/Stack.styled';
import { useGetUsedByInternalData } from '../../hooks/graphql/useGetUsedByInternalData';
import {
  BlueTextStyled,
  CardStyled,
  InlineStyled,
  StyledName,
} from '../CommonStyles.styled';
import {
  CurrVersionContainer,
  VCInfoItem,
  VersionNameStyled,
  VersionTextStyled,
} from './EntityUsedByCard.styled';

export type UsageListType = {
  entityId: string;
  entityType: string;
  entityVersion: string;
  dependencyId: string;
  dependencyType: string;
  dependencyVersion: string;
  dependencyCommitTitle: string;
};

export type EntityUsedByCardData = {
  entityId: string;
  entityType: string;
  dependencyId: string;
  dependencyName: string;
  dependencyType: string;
  dependencySubType: string;
  activeCount: number;
};

type EntityUsedByCardProps = {
  data: EntityUsedByCardData;
  selectedVersion?: string;
};

export function EntityUsedByCard({
  data,
  selectedVersion,
}: EntityUsedByCardProps) {
  const {
    entityId,
    dependencyId,
    dependencyName,
    dependencyType,
    activeCount,
    dependencySubType,
  } = data;

  const [usageList, setUsageList] = useState<UsageListType[]>([]);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [canAppendData, setAppendData] = useState(false);
  const [paginationInfo, setPaginationInfo] = useState<PaginationType>();

  const [getusedByInternalDataQuery, { data: usedByInternalData, error }] =
    useGetUsedByInternalData();

  const entityType = useMemo(
    () => getEntityTypeForUI(dependencyType),
    [dependencyType]
  );

  useEffect(() => {
    if (
      !_isNil(usedByInternalData) &&
      !_isNil(usedByInternalData.getUsedByInternalList)
    ) {
      const { data, paginationInfo } = usedByInternalData.getUsedByInternalList;
      setUsageList(
        canAppendData ? [...usageList, ...(data ?? [])] : [...(data ?? [])]
      );

      setPaginationInfo(paginationInfo);
    }
  }, [usedByInternalData]);

  useEffect(() => {
    if (error instanceof ApolloError) {
      toasts.error(error.message, 'error');
    }
  }, [error]);

  const handleOpenEntity = () => {
    const link = generateEntityLink({
      entityId: dependencyId,
      entityType: dependencyType,
      category: dependencySubType,
    });

    window.open(link, '_blank');
  };

  const handleOpenEntityVersion = (version: string) => {
    const link = generateEntityLink({
      entityId: dependencyId,
      entityType: dependencyType,
      category: dependencySubType,
      version,
    });

    window.open(link, '_blank');
  };

  const handleGetUsageList = async () => {
    if (isCollapsed) {
      setIsCollapsed(false);

      try {
        setIsLoading(true);
        setAppendData(false);

        const payload: Record<string, any> = {
          entityId,
          dependencyId,
          perPage: PAGE_SIZE,
          page: 1,
        };

        if (selectedVersion !== 'all') {
          payload.version = selectedVersion;
        }

        await getusedByInternalDataQuery({
          variables: payload,
          fetchPolicy: 'no-cache',
        });
      } catch (error) {
        if (error instanceof Error) {
          showGraphQlErrorToast(error);
        }
      } finally {
        setIsLoading(false);
      }
    } else {
      setIsCollapsed(true);
      setAppendData(false);
    }
  };

  const handleLoadMore = async () => {
    try {
      setAppendData(true);
      setIsLoading(true);

      const payload: Record<string, any> = {
        entityId,
        dependencyId,
        page: (paginationInfo?.currentPage ?? 0) + 1,
        perPage: PAGE_SIZE,
      };

      if (selectedVersion !== 'all') {
        payload.version = selectedVersion;
      }

      await getusedByInternalDataQuery({
        variables: payload,
        fetchPolicy: 'no-cache',
      });
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const showMoreBtn =
    !_isNil(paginationInfo) &&
    paginationInfo.currentPage < paginationInfo.totalPage;

  return (
    <CardStyled gutter={0}>
      <InlineStyled $hasBottomBorder={false}>
        <Stack gutter="0.5rem">
          <TooltipReact
            id={dependencyId}
            launcher={
              <StyledName name="paragraphSmall">{dependencyName}</StyledName>
            }
          >
            <StyledName name="paragraphSmall">{dependencyName}</StyledName>
          </TooltipReact>
          <BlueTextStyled fontWeight={700}>{entityType}</BlueTextStyled>
        </Stack>
        <Button
          size="xs"
          appearance="filled"
          trailingIcon={<LuArrowRightToLine />}
          onClick={handleOpenEntity}
        >
          View
        </Button>
      </InlineStyled>
      <InlineStyled
        $hasTopBorder={false}
        $hasBottomBorder={!isCollapsed}
        bgColor="var(--color-primary3)"
        onClick={handleGetUsageList}
      >
        <BlueTextStyled>{`Active in ${activeCount} ${
          activeCount > 1 ? 'Versions' : 'Version'
        }`}</BlueTextStyled>

        <IconButton>
          {isCollapsed ? (
            <IoMdArrowDropdown color="var(--color-primary1)" />
          ) : (
            <IoMdArrowDropup color="var(--color-primary1)" />
          )}
        </IconButton>
      </InlineStyled>
      {!isCollapsed && (
        <Stack>
          <VCInfoItem className="header" gutter="1rem">
            <Typography className="curr-version-cont" name="secondaryXs">
              Version
            </Typography>
            <Typography className="commit-title-cont" name="secondaryXs">
              Commit Title
            </Typography>
            <Typography className="usedBy-env-ver-cont" name="secondaryXs">
              Used in Version
            </Typography>
            <div className="arrow-icon" />
          </VCInfoItem>
          {!isLoading &&
            usageList?.map((currentVersionInfo, index) => {
              const {
                dependencyVersion,
                dependencyCommitTitle,
                entityVersion,
              } = currentVersionInfo;

              const entityEnv =
                _isNil(entityVersion) ||
                _isEmpty(entityVersion) ||
                entityVersion === 'draft'
                  ? 'staging'
                  : 'production';

              const dependendencyEnv =
                _isNil(dependencyVersion) ||
                _isEmpty(dependencyVersion) ||
                dependencyVersion === 'draft'
                  ? 'staging'
                  : 'production';

              return (
                <VCInfoItem key={index} gutter="1rem">
                  <CurrVersionContainer
                    env={entityEnv}
                    className="curr-version-cont"
                  >
                    <div className="version-text-styled">{entityVersion}</div>
                  </CurrVersionContainer>

                  <div className="commit-title-cont">
                    <TooltipReact
                      id={`${dependencyCommitTitle}_${index}`}
                      launcher={
                        <VersionNameStyled>
                          {dependencyCommitTitle ?? 'draft'}
                        </VersionNameStyled>
                      }
                    >
                      {dependencyCommitTitle ?? 'draft'}
                    </TooltipReact>
                  </div>

                  <VersionTextStyled
                    env={dependendencyEnv}
                    className="usedBy-env-ver-cont"
                  >
                    {!_isNil(dependencyVersion) && !_isEmpty(dependencyVersion)
                      ? `${dependendencyEnv} | ${dependencyVersion}`
                      : 'draft'}
                  </VersionTextStyled>

                  <div className="arrow-icon">
                    <IconButton
                      onClick={() => handleOpenEntityVersion(dependencyVersion)}
                    >
                      <LuArrowRightToLine
                        size={14}
                        color="var(--color-primary1)"
                      />
                    </IconButton>
                  </div>
                </VCInfoItem>
              );
            })}

          {showMoreBtn && !isLoading && (
            <VCInfoItem>
              <StackAsItem grow={1}>
                <Button
                  appearance="filled"
                  leadingIcon={<FaArrowDownLong size={12} />}
                  onClick={handleLoadMore}
                >
                  Click here to load more
                </Button>
              </StackAsItem>
            </VCInfoItem>
          )}
        </Stack>
      )}
    </CardStyled>
  );
}
