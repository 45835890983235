import { Inline } from '@bedrock-layout/primitives';
import { useMemo } from 'react';
import { BsCheck2 } from 'react-icons/bs';
import { FaExclamationTriangle } from 'react-icons/fa';
import { Spinner, Typography } from 'ui';

import { StackAsItem } from '../../../../../components/layouts/Stack.styled';
import { StatusCode } from '../../../../../utils/response/statusCode';
import { ActionInfoObject } from '../../../types';
import { ActionCount, IconContainer } from './ExecutionTest.styled';

type ActionTitleProps = {
  name: string;
  actionInfo: ActionInfoObject;
  statusPosition: string;
};

export function GetActionTitle({
  name,
  actionInfo,
  statusPosition,
}: ActionTitleProps) {
  const { count = 0, status = '' } = actionInfo;

  const statusBasedIcon = useMemo(() => {
    switch (status) {
      case StatusCode.RUNNING:
        return <Spinner size="extraSmall" />;
      case StatusCode.FAILED:
        return (
          <IconContainer align="center" justify="center">
            <FaExclamationTriangle fill="var(--color-fireEngineRed)" />
          </IconContainer>
        );
      case StatusCode.COMPLETED:
        return (
          <IconContainer align="center" justify="center">
            <BsCheck2 fill="green" />
          </IconContainer>
        );
      default:
        return null;
    }
  }, [status]);

  return (
    <Inline gutter={10} align="center">
      {statusPosition === 'start' && count > 0 && statusBasedIcon}
      {name}
      <StackAsItem as={Inline} grow={1} gutter={5} justify="end">
        <ActionCount name={name} justify="center" align="center">
          <Typography name="heading6">{count}</Typography>
        </ActionCount>
        {statusPosition === 'end' && count > 0 && statusBasedIcon}
      </StackAsItem>
    </Inline>
  );
}
