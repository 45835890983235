import { useState } from 'react';

import type {
  DataSetPublishedStatus,
  UpdateDataSetStatusFunction,
} from '../types';

export const dataSetInitialState: DataSetPublishedStatus = {
  isTested: false,
  isPublished: false,
};

export type UseUpdateDataSetStatusReturn = {
  dataSetStatus: DataSetPublishedStatus;
  updateDataSetStatus: UpdateDataSetStatusFunction;
};

export function useUpdateDataSetStatus(): UseUpdateDataSetStatusReturn {
  const [dataSetStatus, setDataSetStatus] = useState(dataSetInitialState);

  const updateDataSetStatus = (
    isTested: boolean = false,
    isPublished: boolean = false,
    isInitialState: boolean = false
  ) => {
    if (isTested) {
      setDataSetStatus((prev) => ({
        ...prev,
        isTested,
      }));
    }

    if (isPublished) {
      setDataSetStatus((prev) => ({
        ...prev,
        isPublished,
      }));
    }

    if (isInitialState) {
      setDataSetStatus(dataSetInitialState);
    }
  };

  return {
    dataSetStatus,
    updateDataSetStatus,
  };
}
