import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import styled from 'styled-components';

export const TestStyled = styled.div``;

export const WSHeaderContainer = styled(PadBox)`
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);

  .wfNodeContainer {
    position: relative;
  }
`;

export const StyledHeaderContainer = styled(Inline)`
  align-items: flex-start;
`;

export const StyledEnvActionContainer = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
`;

export const MenuItemInlineStyled = styled(Inline)`
  justify-content: space-between;
`;
