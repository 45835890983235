import { z } from 'zod';

import { PASSWORD_REGEX } from './regex';

export const emailValidationSchema = {
  email: z.string().email('Please enter a valid email'),
};

export const passwordValidationSchema = {
  password: z
    .string()
    .min(8, 'Password must contain at least 8 characters.')
    .regex(
      PASSWORD_REGEX,
      'Password requires uppercase, lowercase, numbers and special character, no spaces.'
    ),
  confirmPassword: z
    .string()
    .min(8, 'Confirm password must contain at least 8 characters.')
    .regex(
      PASSWORD_REGEX,
      'Confirm Password requires uppercase, lowercase and special character, no spaces.'
    ),
};
