import { Stack } from '@bedrock-layout/stack';
import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  Typography,
  useCurrentLayer,
} from 'ui';

type RuleSheetCloseModalProps = {
  onClose?: () => void;
  title?: string;
};

export function RuleSheetCloseModal({
  onClose,
  title,
}: RuleSheetCloseModalProps) {
  const { close: closeModal } = useCurrentLayer();

  return (
    <Modal size="large" title={title ?? 'Close rule sheet'}>
      <ModalContent>
        <Stack gutter="1rem">
          <Typography>
            If you have modified any attributes, then it&apos;ll not be saved.
          </Typography>
          <Typography>
            {' '}
            Are you sure you want to close without saving?
          </Typography>
        </Stack>
      </ModalContent>
      <ModalFooter>
        <Button onClick={closeModal} appearance="neutral">
          Back
        </Button>

        <Button
          appearance="danger"
          onClick={() => {
            if (typeof onClose === 'function') {
              onClose();
              closeModal();
            }
          }}
        >
          Close and loose changes
        </Button>
      </ModalFooter>
    </Modal>
  );
}
