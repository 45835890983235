import { translations } from '../translations/en-US';

type MaxCharactersValidationRule = {
  value: number;
  message: string;
};

export const maxFiftyCharactersRule: MaxCharactersValidationRule = {
  value: 50,
  message: translations['validation.max_characters'],
};

export const isValidDate = (date: string, from = ''): boolean => {
  return isValidDateorDateTime(date, from).isValid;
};

export const isValidDateTime = (dateTime: string, from = ''): boolean => {
  return (
    isValidDateorDateTime(dateTime, from).isValid &&
    isValidDateorDateTime(dateTime, from).type === 'dateTime'
  );
};
type DateValidationResult = {
  isValid: boolean;
  type: 'date' | 'dateTime' | 'string';
};

const isValidDateorDateTime = (
  inputString: string,
  from = ''
): DateValidationResult => {
  // Regular expressions for different date formats
  const dateFormat1 = /^(\d{2})\/(\d{2})\/(\d{4})$/; // dd/mm/yyyy
  const dateFormat2 = /^(\d{4})\/(\d{2})\/(\d{2})$/; // yyyy/mm/dd
  const dateFormat3 = /^(\d{2})-(\d{2})-(\d{4})$/; // dd-mm-yyyy
  const dateFormat4 = /^(\d{4})-(\d{2})-(\d{2})$/; // yyyy-mm-dd

  const dateTimeFormat1 =
    /^(\d{2})[/-](\d{2})[/-](\d{4}) (\d{2}):(\d{2}):(\d{2})$/; // dd/MM/yyyy HH:mm:ss
  const dateTimeFormat2 =
    /^(\d{4})[/-](\d{2})[/-](\d{2}) (\d{2}):(\d{2}):(\d{2})$/; // yyyy/MM/dd HH:mm:ss
  const dateTimeFormat3 = /^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})$/; // yyyy-MM-dd HH:mm:ss
  const dateTimeFormat4 = /^(\d{2})-(\d{2})-(\d{4}) (\d{2}):(\d{2}):(\d{2})$/; // dd-MM-yyyy HH:mm:ss

  const dateTimeTZFormat = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})Z$/; // yyyy-MM-ddTHH:mm:ssZ

  const dateTimeTZMilliSecFormat =
    /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d{1,9})?Z$/; // yyyy-MM-ddTHH:mm:ss.aaaaaaaaaZ

  const rfc3339Format =
    /^(\d{4})-(\d{2})-(\d{2})(?:T|\s)(\d{2}):(\d{2}):(\d{2})(?:\.(\d{1,3}))?(?:(Z)|(?:([+-])(\d{2}):(\d{2})))$/; // RFC 3339 with optional milliseconds

  // Helper function to check if a date object is valid
  const isValidDate = (date: Date): boolean => !isNaN(date.getTime());

  // Helper to test date formats and validate the date
  const testAndValidate = (
    regex: RegExp,
    type: 'date' | 'dateTime'
  ): DateValidationResult | null => {
    if (regex.test(inputString)) {
      const date = new Date(inputString);

      return isValidDate(date)
        ? { isValid: true, type }
        : { isValid: true, type: 'string' };
    }

    return null;
  };

  // Define the array of formats and iterate over them
  const formats: Array<{ regex: RegExp; type: 'date' | 'dateTime' }> = [
    { regex: dateFormat1, type: 'date' },
    { regex: dateFormat2, type: 'date' },
    { regex: dateFormat3, type: 'date' },
    { regex: dateFormat4, type: 'date' },
    { regex: dateTimeFormat1, type: 'dateTime' },
    { regex: dateTimeFormat2, type: 'dateTime' },
    { regex: dateTimeFormat3, type: 'dateTime' },
    { regex: dateTimeFormat4, type: 'dateTime' },
    { regex: dateTimeTZFormat, type: 'dateTime' },
    { regex: dateTimeTZMilliSecFormat, type: 'dateTime' },
    { regex: rfc3339Format, type: 'dateTime' },
  ];

  // Iterate over all formats and validate
  for (const { regex, type } of formats) {
    const result = testAndValidate(regex, type);

    if (result != null) {
      return result;
    }
  }

  // If no valid date format matches, return invalid

  if (from === 'validation') {
    return { isValid: false, type: 'string' };
  }

  return { isValid: true, type: 'string' };
};

export const isValidDateTimeSecondary = (
  dateTimeSecondary: string
): boolean => {
  return (
    isValidDateorDateTime(dateTimeSecondary).isValid &&
    isValidDateorDateTime(dateTimeSecondary).type === 'dateTime'
  );
};
