import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _sortBy from 'lodash/sortBy';
import _transform from 'lodash/transform';
import type { UseFormSetError } from 'react-hook-form';

import { parseExpression } from '../../../../components/Form/utlis';
import { MapType, SaveAuthConfigKeys } from '../../../../types';
import type {
  IntegrationFormValues,
  Option,
  PluginParams,
  SortedPluginParamsFieldKeys,
} from '../../types';
import { KEY } from './form/fields/HeaderFieldsArray';

export function convertFieldArrayValueToMap(arrayData?: MapType[]) {
  if (_isNil(arrayData) || !Array.isArray(arrayData)) {
    return undefined;
  }

  return arrayData;
}

export function parseFormData(
  pluginConfig: SortedPluginParamsFieldKeys[],
  pluginFormValues: Omit<IntegrationFormValues, 'name'>
) {
  const pluginFormValueClone = structuredClone(pluginFormValues);

  pluginConfig.forEach((config) => {
    const { type, key } = config;

    if (type === 'number') {
      pluginFormValueClone[key] = parseInt(pluginFormValues[key], 10);
    }

    if (type === 'map') {
      pluginFormValueClone[key] = convertFieldArrayValueToMap(
        pluginFormValues[key]
      );
    }

    if (type === 'dropdown') {
      if (_isNil(pluginFormValueClone[key])) {
        pluginFormValueClone[key] = '';

        return;
      }

      pluginFormValueClone[key] = pluginFormValueClone[key].value;
    }

    if (type === 'multi-select') {
      if (_isNil(pluginFormValueClone[key])) {
        pluginFormValueClone[key] = [];

        return;
      }

      pluginFormValueClone[key] = pluginFormValueClone[key].map(
        (valueObject: MapType) => valueObject.value
      );
    }
  });

  return pluginFormValueClone;
}

export function convertMapTypeFieldToArray(
  mapTypeData: Record<string, string> | Array<Record<string, string>>
) {
  if (_isNil(mapTypeData) || typeof mapTypeData !== 'object') {
    return undefined;
  }

  if (Array.isArray(mapTypeData)) {
    return mapTypeData;
  }

  return Object.keys(mapTypeData).map((keyName) => ({
    key: keyName,
    value: mapTypeData[keyName],
  }));
}

function getTransformedParamsKeys(paramKeys: SortedPluginParamsFieldKeys) {
  const clonedParamKeys = { ...paramKeys };
  const { type, defaultValue, options, value } = clonedParamKeys;

  if (type === 'map') {
    const { keyDef } = paramKeys;
    const { regex } = keyDef ?? {};

    if (!_isNil(regex)) {
      clonedParamKeys.regex = regex;
    }
  } else if (type === 'boolean') {
    if (_isNil(value)) {
      clonedParamKeys.value = defaultValue === 'true';
    } else {
      clonedParamKeys.value = value;
    }
  } else if (type === 'dropdown') {
    const defaultValueObj = {
      label: options?.find((option: Option) => option.value === defaultValue)
        ?.label,
      value: defaultValue,
    };

    clonedParamKeys.value = defaultValueObj;
  } else if (type === 'multi-select') {
    const defaultValueObj =
      options?.filter(
        (option: Option) =>
          value?.find(
            (currentValue: string) => currentValue === option.value
          ) ?? false
      ) ?? [];

    clonedParamKeys.value = defaultValueObj;
  } else {
    clonedParamKeys.value = defaultValue;
  }

  if (type === 'multi-select') {
    const defaultValueObj =
      options?.filter(
        (option: Option) =>
          value?.find(
            (currentValue: string) => currentValue === option.value
          ) ?? false
      ) ?? [];

    clonedParamKeys.value = defaultValueObj;
  }

  return clonedParamKeys;
}

export function getTransformedSortedConnectorConfigParams(
  params: PluginParams,
  environmentConfig: Record<string, any>
) {
  const transformedParams = _transform(
    params,
    (result: Record<string, SortedPluginParamsFieldKeys>, paramKeys, key) => {
      const value = environmentConfig[key];

      const fieldKeysCloned = getTransformedParamsKeys({
        ...paramKeys,
        key,
        value,
      });

      result[key] = {
        ...fieldKeysCloned,
      };

      return result;
    }
  );

  return _sortBy(transformedParams, 'order');
}

export function getTransformedSortedPluginParams(params: PluginParams) {
  const transformedParams = _transform(
    params,
    (result: Record<string, SortedPluginParamsFieldKeys>, paramKeys, key) => {
      const fieldKeysCloned = getTransformedParamsKeys({
        ...paramKeys,
        key,
      });

      result[key] = {
        ...fieldKeysCloned,
      };

      return result;
    }
  );

  return _sortBy(transformedParams, 'order');
}

export function getTransformedSavedFormValues(
  params: PluginParams,
  environmentConfig: Record<string, any>
) {
  return _transform(params, (result: Record<string, any>, paramKeys, key) => {
    const { type } = paramKeys;
    let value = environmentConfig[key];

    if (type === 'map') {
      value = convertMapTypeFieldToArray(value);
    }

    if (type === 'dropdown') {
      const { options = [] } = params[key];

      value = options.find((option: Option) => option.value === value);
    }

    if (type === 'multi-select') {
      const { options = [] } = params[key];

      value = options.filter(
        (option: Option) =>
          value?.find(
            (currentValue: string) => currentValue === option.value
          ) ?? false
      );
    }

    result[key] = value;

    return result;
  });
}

export function getTransformedPluginParamsWithAuthKey(
  authKeyList: Option[],
  sortedFields: SortedPluginParamsFieldKeys[]
) {
  return sortedFields.map((field) => {
    const { action } = field;

    if (!_isNil(action) && action === 'getCredentials') {
      return {
        ...field,
        options: authKeyList,
        defaultValue: '',
      };
    }

    return field;
  });
}

export function validateConnectorForm(
  formValues: Record<string, any>,
  sortedFields: SortedPluginParamsFieldKeys[],
  setError: UseFormSetError<Record<string, any>>
) {
  let isFormValid = true;
  const errorMessage = 'This field is required';

  sortedFields.forEach((field) => {
    const { type, key, required, isRequired, hidden } = field;
    const formValueAgainstAKey = formValues[key];

    const isHidden: boolean = parseExpression(formValues, {}, hidden);

    const isRequiredDynamic =
      (parseExpression(formValues, {}, isRequired) as boolean) ||
      (required && !isHidden);

    if (
      isRequiredDynamic &&
      (type === 'text' || type === 'password') &&
      (_isEmpty(formValueAgainstAKey) || _isNil(formValueAgainstAKey))
    ) {
      isFormValid = false;
      setError(key, {
        type: 'empty',
        message: errorMessage,
      });
    }

    if (
      isRequiredDynamic &&
      type === 'number' &&
      ((typeof formValueAgainstAKey === 'number' &&
        isNaN(formValueAgainstAKey)) ||
        (typeof formValueAgainstAKey === 'string' &&
          _isEmpty(formValueAgainstAKey)) ||
        _isNil(formValueAgainstAKey))
    ) {
      isFormValid = false;
      setError(key, {
        type: 'empty',
        message: errorMessage,
      });
    }

    if (type === 'map') {
      const mapTypeValues: MapType[] = formValueAgainstAKey;

      if (!_isNil(mapTypeValues) && Array.isArray(mapTypeValues)) {
        mapTypeValues.forEach((keys, index) => {
          const keyName = `${key}.${index}.${KEY}`;

          if (_isEmpty(keys.key) && isRequiredDynamic) {
            isFormValid = false;
            setError(keyName, {
              type: 'empty',
              message: errorMessage,
            });
          }
        });
      }
    }

    if (
      isRequiredDynamic &&
      type === 'dropdown' &&
      (_isEmpty(formValueAgainstAKey) || _isNil(formValueAgainstAKey))
    ) {
      isFormValid = false;
      setError(key, {
        type: 'empty',
        message: errorMessage,
      });
    }

    if (
      isRequiredDynamic &&
      type === 'dropdown' &&
      (_isEmpty(formValueAgainstAKey) || _isNil(formValueAgainstAKey))
    ) {
      isFormValid = false;
      setError(key, {
        type: 'empty',
        message: errorMessage,
      });
    }
  });

  return isFormValid;
}

export function getAuthKeyConvertedList(
  authKeyList: SaveAuthConfigKeys[] = []
) {
  const authKeys = authKeyList.map(({ id = '', name, value }) => {
    return {
      label: name,
      value: id,
    };
  });

  return [{ label: 'None', value: '' }].concat(_sortBy(authKeys, 'label'));
}
