import { useAtom } from 'jotai';
import { UseControllerProps, UseFormSetValue, useWatch } from 'react-hook-form';
import { AiOutlineDelete } from 'react-icons/ai';
import { CheckboxField, IconButton, Typography } from 'ui';

import { isRuleReadOnlyAtom } from '../../..';
import { getCronExpressionByData } from '../../../../../utils/common';
import { RuleCronScheduleModel } from '../../SimpleRule/models';
import { IconButtonStyled, SchedulerBox } from './SchedulerItem.styled';

type ScheduleItemProps = Omit<UseControllerProps, 'name'> & {
  setValue?: UseFormSetValue<any>;
  index: number;
};

export function ScheduleItem({
  setValue,
  control,
  index = 0,
}: ScheduleItemProps) {
  const [isRuleReadOnly] = useAtom(isRuleReadOnlyAtom);

  const schedules: RuleCronScheduleModel[] = useWatch({
    name: 'productionConfig.schedule',
    control,
  });

  const currentSchedule: RuleCronScheduleModel = useWatch({
    name: `productionConfig.schedule.${index}`,
    control,
  });

  const handleDeleteSchedule = () => {
    if (typeof setValue === 'function' && !isRuleReadOnly) {
      setValue(
        'productionConfig.schedule',
        schedules.filter((schedule, i) => i !== index)
      );
    }
  };

  return (
    <SchedulerBox>
      <Typography name="paragraphSmall">
        {getCronExpressionByData(currentSchedule)}
      </Typography>

      <CheckboxField
        appearance="switch"
        control={control}
        disabled={isRuleReadOnly}
        name={`productionConfig.schedule.${index}.isEnabled`}
        useId={`productionConfig.schedule.${index}.isEnabled`}
      />

      <IconButtonStyled type="button" onClick={handleDeleteSchedule}>
        <IconButton disabled={isRuleReadOnly}>
          <AiOutlineDelete color="#666" />
        </IconButton>
      </IconButtonStyled>
    </SchedulerBox>
  );
}
