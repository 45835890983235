import { PadBox } from '@bedrock-layout/padbox';
import styled from 'styled-components';

export const CreateRuleContainer = styled(PadBox)`
  height: 100vh;
  position: relative;

  .dtTabListStyle {
    border-block-end: none;
  }
`;

export const SaveButtonContainer = styled(PadBox)`
  border-block-start: 1px solid var(--color-darkGray);
  border-block-end: 1px solid var(--color-darkGray);
`;
