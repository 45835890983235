import { gql, useLazyQuery } from '@apollo/client';

import type { DependencyUsedByModel } from '../../model';

const getDependencyUsedByQuery = gql`
  query getUsedBy(
    $entityId: String
    $perPage: Int
    $page: Int
    $version: String
  ) {
    getUsedBy(
      entityId: $entityId
      perPage: $perPage
      page: $page
      version: $version
    ) {
      data {
        entityId
        entityType
        dependencyId
        dependencyName
        dependencyType
        dependencySubType
        activeCount
      }
      paginationInfo {
        perPage
        currentPage
        totalPage
        totalRecord
      }
    }
  }
`;

export function useGetDependencyUsedByData() {
  return useLazyQuery<DependencyUsedByModel, Record<string, any>>(
    getDependencyUsedByQuery
  );
}
