import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _isUndefined from 'lodash/isUndefined';
import { Ref, forwardRef, useEffect, useRef, useState } from 'react';
import { ControllerRenderProps } from 'react-hook-form';

import { SearchableTextInput } from '../Form/SearchableTextInput/SearchableTextInput';
import { toasts } from '../Toast';
import { NectedEditorIframeWrapper } from './NectedEditor.styled';
import { formatCustomAttributes } from './helper';

const ALLOWED_DOMAINS: string[] = [
  'https://editor.dev.nected.io',
  'https://editor.stage.nected.io',
  'https://editor.nected.io',
  'https://editor.nected.ai',
  'http://localhost:3001',
];

type NectedEditorRef = HTMLIFrameElement & {
  postMessage: (message: any) => void;
};

type PostMessagePayload = {
  type: string;
  data: any;
  mode: string;
};

const NectedDocumentationLink: string = 'https://docs.nected.ai/nected-docs/';

const DocumentationModeMap: Record<string, string> = {
  js: 'code-and-database-queries/custom-javascript-editor',
  list: 'code-and-database-queries/custom-javascript-editor',
  json: 'code-and-database-queries/custom-javascript-editor',
};

export type EditorLanguages =
  | 'js'
  | 'mongo'
  | 'mongodb'
  | 'mysql'
  | 'pgsql'
  | 'bigquery'
  | 'redshift'
  | 'json'
  | 'text'
  | 'sqlserver'
  | 'singleLineText'
  | 'list'
  | 'formula'
  | 'snowflake'
  | 'oracle'
  | '';
export type EditorVariant = 'single' | 'multi';
export type EditorMethods = 'read' | 'write' | 'read,write';

export type NectedSuggestionModel = {
  name?: string;
  value: string;
  meta: string;
  score: number;
  version?: string;
  executedValue?: any;
  display?: boolean;
};

export type RestAPIEditorPayload = {
  name: string;
  value: string;
  type: string;
  prodBaseURL?: string;
  stagBaseURL?: string;
};

export type NectedEditorProps = Partial<Omit<ControllerRenderProps, 'ref'>> & {
  name?: string;
  value?: string;
  readOnly?: boolean;
  defaultValue?: string | null;
  customSuggestions?: NectedSuggestionModel[];
  minimap?: boolean;
  noSemanticValidation?: boolean;
  noSyntaxValidation?: boolean;
  editorBackground?: string;
  mode?: EditorLanguages;
  variant?: EditorVariant;
  domain?: (typeof ALLOWED_DOMAINS)[number];
  methods?: EditorMethods;
  onSetEditorValidity?: (state: boolean) => void;
  sendReturnType?: boolean;
  setReturnType?: (type: any) => void;
  setExecutedValue?: (execValue: any) => void;
  setHasEditorChanged?: (state: boolean) => void;
  restApiPayload?: RestAPIEditorPayload[];
  handleGetExecData?: (state: any) => void;
  execValues?: Record<string, any>;
  resetValue?: any;
  onReset?: (resetState?: any) => void;
  id?: string;
};

export const NectedEditor = forwardRef(
  (
    {
      name,
      mode = '',
      variant = 'multi',
      onChange,
      value,
      readOnly = false,
      customSuggestions = [],
      domain = 'https://editor.stage.nected.io',
      methods = 'read,write',
      onSetEditorValidity,
      defaultValue,
      sendReturnType = false,
      setReturnType,
      setExecutedValue,
      setHasEditorChanged,
      restApiPayload = [],
      handleGetExecData,
      execValues = {},
      resetValue = null,
      onReset,
      id = 'nected-editor-iframe',
    }: NectedEditorProps,
    ref: Ref<NectedEditorRef>
  ) => {
    const editorRef = useRef<HTMLIFrameElement>(null);
    const [cacheBust] = useState(new Date().toISOString());
    const [isIframeLoaded, setIsIframeLoaded] = useState(false);
    useEffect(() => {
      if (mode !== 'singleLineText') {
        window.addEventListener('message', receivedMessage, false);
      }

      return () => {
        window.removeEventListener('message', receivedMessage, false);
      };
    }, []);

    const handleEditorChange = (value?: string, isValid: boolean = true) => {
      if (!_isUndefined(value) && !_isUndefined(onChange)) {
        onChange(value);

        if (typeof onSetEditorValidity === 'function') {
          onSetEditorValidity(isValid);
        }
      }
    };
    const iframeCurrent = editorRef.current;

    useEffect(() => {
      iframeCurrent?.addEventListener('load', () => {});

      return () => {
        iframeCurrent?.removeEventListener('load', () => {});
      };
    }, [iframeCurrent]);

    const sendPostMessage = (message: PostMessagePayload) => {
      setTimeout(() => {
        if (!_isNil(editorRef?.current?.contentWindow)) {
          editorRef.current?.contentWindow.postMessage(message, '*');
        }
      }, 100);
    };

    const receivedMessage = (event: MessageEvent) => {
      const parentDomain = window.location.host
        .split('.')
        .splice(
          window.location.host.split('.').length - 2,
          window.location.host.split('.').length - 1
        )
        .join('.');

      const frameDomain = new URL(event.origin).host
        .split('.')
        .splice(
          new URL(event.origin).host.split('.').length - 2,
          new URL(event.origin).host.split('.').length - 1
        )
        .join('.');

      if (parentDomain === frameDomain || _isEmpty(parentDomain)) {
        if (event.data.type === 'ONCHANGE') {
          handleEditorChange(event.data.data, event.data.isValid);

          if (typeof setHasEditorChanged === 'function') {
            setHasEditorChanged(true);
          }
        } else if (event.data.type === 'EXECUTION_COMPLETED') {
          if (typeof setReturnType === 'function') {
            setReturnType(event.data.dataType);
          }

          if (typeof setExecutedValue === 'function') {
            if (
              event.data.status === 'ERROR' &&
              typeof event.data.data === 'string' &&
              event.data.data.toLowerCase() !== 'failed to fetch'
            ) {
              toasts.error(event.data.data ?? 'Unable to save', 'toast-error');
            }
            setExecutedValue(
              event.data.status !== 'ERROR' ? event.data.data : undefined
            );
          }
        } else if (event.data.type === 'FIND_EXEC_VALUE') {
          if (typeof handleGetExecData === 'function') {
            handleGetExecData(event.data.data);
          }
        }
      }
    };

    useEffect(() => {
      if ((_isEmpty(value) || _isNil(value)) && !_isEmpty(defaultValue)) {
        sendPostMessage({ type: 'RESET_VALUE', data: defaultValue, mode });
      }
    }, [defaultValue]);

    useEffect(() => {
      if (!_isNil(resetValue)) {
        sendPostMessage({ type: 'RESET_VALUE', data: defaultValue, mode });

        if (!_isNil(onReset)) {
          onReset(resetValue);
        }
      }
    }, [JSON.stringify(resetValue)]);

    useEffect(() => {
      if (!_isEmpty(execValues)) {
        sendPostMessage({ type: 'FOUND_EXEC_VALUE', data: execValues, mode });
      }
    }, [JSON.stringify(execValues)]);

    const handleIframeLoad = () => {
      setIsIframeLoaded(false);
      setTimeout(() => {
        sendPostMessage({
          type: 'SET_AUTH',
          data: {
            at: `Bearer ${
              window.localStorage.getItem('accessToken') as string
            }`,
            ws:
              window.sessionStorage.getItem('workspaceUUID') ??
              window.localStorage.getItem('workspaceUUID'),
          },
          mode,
        });

        sendPostMessage({
          type: 'REST_CONNECTORS',
          data: restApiPayload,
          mode,
        });
        setIsIframeLoaded(true);

        sendPostMessage({
          type: 'SET_VALUE',
          data: !_isEmpty(value) ? value : defaultValue,
          mode,
        });
        sendPostMessage({
          type: 'EDITOR_INFO',
          data: {
            documentation: `${NectedDocumentationLink}${DocumentationModeMap[mode]}`,
          },
          mode,
        });
      }, 250);
    };

    useEffect(() => {
      if (customSuggestions.length !== 0 && isIframeLoaded) {
        sendPostMessage({
          type: 'SUGGESTION',
          data: formatCustomAttributes(customSuggestions),
          mode,
        });
      } else if (customSuggestions.length === 0 && isIframeLoaded) {
        sendPostMessage({
          type: 'SUGGESTION',
          data: [],
          mode,
        });
      }
    }, [JSON.stringify(customSuggestions), isIframeLoaded]);

    useEffect(() => {
      if (
        sendReturnType &&
        typeof setReturnType === 'function' &&
        (mode === 'js' ||
          mode === 'json' ||
          mode === 'list' ||
          mode === 'formula')
      ) {
        sendPostMessage({
          type: 'EXECUTE_JS_FORMULA',
          data: {
            suggestions: formatCustomAttributes(customSuggestions),
          },
          mode,
        });
      }
    }, [sendReturnType]);

    useEffect(() => {
      if (restApiPayload.length > 0) {
        sendPostMessage({
          type: 'REST_CONNECTORS',
          data: restApiPayload,
          mode,
        });
      }
    }, [restApiPayload]);

    const getEditorComponent = () => {
      switch (mode) {
        case 'singleLineText':
          return (
            <SearchableTextInput
              name={mode}
              value={value}
              updateValue={(value) => {
                if (!_isUndefined(onChange)) {
                  onChange(value);
                }
              }}
              options={customSuggestions.filter(
                (token) => token.name !== '{{.custom.sql}}'
              )}
              readOnly={readOnly}
            />
          );
        default:
          return (
            <NectedEditorIframeWrapper
              id="nected-editor-iframe"
              ref={editorRef}
              src={`${domain}?mode=${mode}&variant=${variant}&readOnly=${
                readOnly as unknown as string
              }&methods=${methods}&_cb=${cacheBust}`}
              onLoad={handleIframeLoad}
            />
          );
      }
    };

    return <>{getEditorComponent()}</>;
  }
);

NectedEditor.displayName = 'NectedEditor';
