import { motion } from 'framer-motion';
import { FiRefreshCcw } from 'react-icons/fi';

import { RefreshStyled } from './RefreshIconComponent.styled';

export type RotateIconButtonProps = {
  isLoading: boolean;
  onClick: () => void;
};

type RefreshIconComponentProps = {
  isLoading?: boolean;
  isReadOnly?: boolean;
  onClick?: () => void;
};

export function RefreshIconComponent({
  isLoading = false,
  isReadOnly = false,
  onClick,
}: RefreshIconComponentProps) {
  const RotateIconButton = ({ isLoading, onClick }: RotateIconButtonProps) => {
    return (
      <motion.div
        style={{ display: 'inline-block', transformOrigin: 'center' }}
        animate={{
          rotate: isLoading ? 360 : 0,
        }}
        transition={{
          duration: isLoading ? 2 : 0,
          repeat: isLoading ? Infinity : 0,
          ease: 'linear',
        }}
      >
        <div onClick={onClick}>
          <FiRefreshCcw />
        </div>
      </motion.div>
    );
  };

  return (
    <RefreshStyled $isDisabled={isReadOnly}>
      <RotateIconButton
        isLoading={isLoading}
        onClick={() => {
          if (!isReadOnly && typeof onClick === 'function') {
            onClick();
          }
        }}
      />
    </RefreshStyled>
  );
}
