import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { ThemeProvider } from 'styled-components';

import { Name, Typography } from '../Typography';
import { PublishedStatusContainer } from './PublishedStatus.styled';

export const PublishedStatus = {
  new: 'New',
  draft: 'Staging | Draft',
  published: 'Production',
  tested: 'Staging | Tested',
  live: 'Live',
  inreview: 'Staging | In Review',
  archived: 'Archive',
  latest: 'Latest',
};

export type PublishedStatusType = keyof typeof PublishedStatus;

export type PublishedStatusLabelProps = {
  publishedStatus: PublishedStatusType;
  currentVersion?: string;
  textStyleName?: Name;
};

export function PublishedStatusLabel({
  publishedStatus,
  currentVersion,
  textStyleName = 'paragraphSmall',
}: PublishedStatusLabelProps) {
  const showCurrentVersion = ['published'].includes(publishedStatus);

  return (
    <ThemeProvider theme={{ status: publishedStatus }}>
      <PublishedStatusContainer padding={['0.5rem', '1rem']}>
        <Typography fontWeight={700} name={textStyleName}>
          {PublishedStatus[publishedStatus]}
        </Typography>
        {showCurrentVersion &&
          !_isNil(currentVersion) &&
          !_isEmpty(currentVersion) && (
            <Typography name={textStyleName}>| {currentVersion}</Typography>
          )}
      </PublishedStatusContainer>
    </ThemeProvider>
  );
}
