import { PadBox } from '@bedrock-layout/padbox';
import { AxiosError } from 'axios';
import { useState } from 'react';
import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  Spinner,
  Typography,
  useCurrentLayer,
} from 'ui';

import { useAxiosPrivate } from '../../../hooks';
import { showErrorToast } from '../../../utils/common';
import type { UpdatedAccessObj } from './EntityLevelAccessModal';
import { ModalTitleStyled } from './EntityLevelAccessModal.styled';

type AccessUpdateWarningModalProps = {
  entityAccessData?: UpdatedAccessObj;
  handleAccessUpdateError?: (data: Record<string, any>) => void;
};

export function AccessUpdateWarningModal({
  entityAccessData,
  handleAccessUpdateError,
}: AccessUpdateWarningModalProps) {
  const [isLoading, setIsLoading] = useState(false);

  const { close } = useCurrentLayer();
  const { axiosPrivate } = useAxiosPrivate();

  const handleRestrictAccess = async () => {
    try {
      setIsLoading(true);
      const { data } = await axiosPrivate.post(
        '/entity-access',
        entityAccessData
      );

      if (typeof handleAccessUpdateError === 'function') {
        handleAccessUpdateError(data);
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        showErrorToast(error);
      }
    } finally {
      setIsLoading(false);
      close();
    }
  };

  return (
    <Modal
      size="large"
      title={
        <ModalTitleStyled name="heading2">
          Are you sure you want to change this role and restrict access? This
          Rule/workflow will no longer inherit role from the workspace level.
        </ModalTitleStyled>
      }
      hideCloseButton
    >
      <ModalContent>
        <PadBox padding="1.5rem">
          <Typography name="heading3">
            Admin will still be able to restore permissions from restricted
            pages.
          </Typography>
        </PadBox>
      </ModalContent>
      <ModalFooter justify="center">
        <Button
          appearance="danger"
          size="medium"
          onClick={handleRestrictAccess}
        >
          {isLoading ? <Spinner size="extraSmall" /> : 'Restrict Access'}
        </Button>
        <Button appearance="neutral" size="medium" onClick={close}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
}
