import { useMemo } from 'react';

import { ThresholdEntity } from '../components/Threshold/Threshold';
import { getThresholdStatusColorTextByState } from '../utils/common';

type UseGetThresholdElementProps = {
  max: number;
  current: number;
  entity: ThresholdEntity;
};

export default function useGetThresholdElement({
  max,
  current,
  entity,
}: UseGetThresholdElementProps) {
  const localState = useMemo(() => {
    return getMessageByThresholdEntity(max, current, entity);
  }, [max, current]);

  return {
    ...localState,
  };
}

export const getMessageByThresholdEntity = (
  max: number,
  current: number,
  entity: string = 'workflow'
) => {
  const { color, state, text, percentage } = getThresholdStatusColorTextByState(
    current,
    max,
    entity
  );

  return {
    text: <p style={{ color, fontSize: '1.4rem' }}>{text}</p>,
    state,
    color,
    percentage,
  };
};
