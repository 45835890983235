import React from 'react';
import { TooltipReact, Typography } from 'ui';

type TestValueProps = {
  value?: string;
};

export function TestValue({ value = '' }: TestValueProps) {
  if (value.length <= 40) {
    return <Typography name="secondarySmall">{value}</Typography>;
  }

  return (
    <TooltipReact
      id={value}
      customStyles={{
        wordBreak: 'break-word',
      }}
      launcher={
        <span>
          <Typography name="secondarySmall">{value.slice(0, 39)}...</Typography>
        </span>
      }
    >
      <Typography name="paragraphSmall">{value}</Typography>
    </TooltipReact>
  );
}
