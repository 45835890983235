import type { ObservableQuery } from '@apollo/client';
import { Inline, PadBox, Stack } from '@bedrock-layout/primitives';
import { zodResolver } from '@hookform/resolvers/zod';
import { atom, useAtom } from 'jotai';
import _forEach from 'lodash/forEach';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import {
  Attributes,
  Dataset,
  NectedSuggestionModel,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  toasts,
  useLayer,
} from 'ui';

import {
  checksumWarningAtom,
  usedConnectorMappingAtom,
} from '../../../../atom';
import { publishedConnectorsAtom } from '../../../../components/CreateAction/CreateAction';
import { EntityAccessBlock } from '../../../../components/EntityAccessBlock/EntityAccessBlock';
import { permissionObj } from '../../../../components/PermissionComponent/constant';
import { useCheckPermissions } from '../../../../components/PermissionComponent/hooks/useCheckPermissions';
import { StackAsItem } from '../../../../components/layouts/Stack.styled';
import { customAttributesAtom } from '../../../../components/rules/forms/CustomAttributeSheet/CustomAttributeSheet';
import { getUserState } from '../../../../hooks/getUserState';
import { useHandleRequests } from '../../../../hooks/useHandleRequests';
import { useUpdateMappedConnectorsData } from '../../../../hooks/useUpdateMappedConnectorsData';
import type {
  DependencyUsingMapType,
  UsedConnectorMappingInEntityType,
} from '../../../../types';
import {
  checksumMessage,
  createResultDataset,
  detectType,
  executeJSParallelPostRequests,
  getDataSetSuggestionsObj,
  getUpdatedUsedConnectorsMapping,
  handleGetCheckSumByEntityName,
  handleSetCheckSumByEntityName,
  isOnboardingCompleted,
  prepareCodeStringForExecution,
} from '../../../../utils/common';
import {
  ENTITY_ID,
  actionsAdded,
  addConditionAndResult,
  attachedDatabaseRule,
  useJSCode,
} from '../../../../utils/constant';
import { handleKeyDown } from '../../../../utils/form';
import { timeToExpireUnits } from '../../../DataSets/utils';
import { updateWidgetState } from '../../../Home/components/sub-components/UpdateWidgetState';
import { ruleInitialState } from '../../fixtures/ruleInitialState';
import type { TableResponseModel } from '../../hooks/graphql/useGetTableData';
import { useSaveSimpleRule } from '../../hooks/graphql/useSaveSimpleRule';
import { useUpdateAction } from '../../hooks/graphql/useUpdateAction';
import { useUpdateConditions } from '../../hooks/graphql/useUpdateConditions';
import { useUpdateCustomInput } from '../../hooks/graphql/useUpdateCustomInput';
import { useUpdateNameDesc } from '../../hooks/graphql/useUpdateNameDesc';
import { useUpdateSimpleRule } from '../../hooks/graphql/useUpdateSimpleRule';
import { useUpdateTriggers } from '../../hooks/graphql/useUpdateTriggers';
import { useEditSimpleRule } from '../../hooks/useEditSimpleRule';
import { useInitializeSimpleRule } from '../../hooks/useInitializeSimpleRule';
import {
  dataSetFieldsByIdAtom,
  isLiveNodeSheetClosedAtom,
  isRulePublishOnlyAtom,
  isRuleReadOnlyAtom,
  isRuleTestOnlyAtom,
  selectedDataSetAtom,
  simpleRuleNodeIdAtom,
} from '../../index';
import { ExecuteResult, SaveType } from '../../models';
import {
  getOutputAddedDataset,
  isRuleNameValid,
  transformSimpleRuleToRequestPayload,
  updateDataSetOnChange,
  validateAction,
  validateCustomAttributesBeforeTest,
  validateKeyNameBeforeSaving,
  validateOutput,
  validateScheduleBeforeSaving,
  validateSimpleRuleBeforeSaving,
  validateTokensBeforeSaving,
  validateTriggerBeforeTest,
} from '../../utils/common';
import { getSuggestionsOnRuleLoad } from '../../utils/decisionTable';
import '../../utils/simpleRule';
import {
  CreateRuleSheet,
  dataSetParamsAtom,
  enableCronTestButtonAtom,
  firstCustomAttributeAtom,
  isRulePublishableAtom,
  ruleEnvironmentAtom,
  ruleSavingErrorAtom,
} from '../CreateRuleSheet/CreateRuleSheet';
import { ApiTriggerAndCronInfo } from '../RuleComponents/ApiTriggerAndCronInfo/ApiTriggerAndCronInfo';
import { RuleSheetFooter } from '../RuleComponents/RuleSheetFooter';
import { RuleSheetHeader } from '../RuleComponents/RuleSheetHeader';
import { RuleLoader } from '../RuleLoader/RuleLoader';
import { authTypes } from '../Triggers/AuthenticationDropDown';
import {
  approvalInfoRuleAtom,
  datasetDetailsInRuleAtom,
  errorInRuleAtom,
  hasConnectorErrorInCustomAttrSheetAtom,
  isRuleLiveAtom,
  versionInfoRuleAtom,
  versionMappingInfoAtom,
} from '../atom/atom';
import { RuleEditor } from './RuleEditor';
import { RuleEditorContainer } from './RuleEditor/RuleEditor.styled';
import { CreateRuleContainer } from './SimpleRule.styled';
import { TestNodeSheet } from './TestNodeSheet/TestNodeSheet';
import type {
  ErrorByNodeId,
  ResultAddDataModel,
  SimpleRuleModel,
  SimpleRuleNodesModel,
  ThenDataExecutionModel,
} from './models';
import { resultDataSchema } from './schema';

export type SimpleRuleProps = {
  ruleName?: string;

  // This variable RuleId comes from the top level in case the rule has already
  // been saved and comes in case of rule edit and clone
  ruleId?: string;
  isClone?: boolean;
  refetch?: ObservableQuery<TableResponseModel>['refetch'];
  isLive?: boolean;
  commitId?: string;
};

export const simpleRuleNodesAtom = atom<Record<string, SimpleRuleNodesModel>>(
  structuredClone(ruleInitialState)
);

export const simpleStartNodeAtom = atom<string>('');

export const simpleNodeErrors = atom<ErrorByNodeId>({});

export const tokensAtom = atom<string[]>([]);

export const SimpleRule = ({
  ruleId,
  ruleName = 'Untitled',
  refetch,
  isClone = false,
  isLive = false,
  commitId,
}: SimpleRuleProps) => {
  const [customAttributes] = useAtom(customAttributesAtom);

  const [enableAutoSave, setEnableAutoSave] = useState(false);
  const [, setShowChecksumPopup] = useAtom(checksumWarningAtom);
  // This Id is local variable and default value of this Id is 'Undefined'
  const [id, setId] = useAtom(simpleRuleNodeIdAtom);
  const [isTesting, setIsTesting] = useState(false);

  const [, setIsRulePublishable] = useAtom(isRulePublishableAtom);
  const [, setRuleSavingError] = useAtom(ruleSavingErrorAtom);
  const [isLiveNodeSheetClosed, setIsLiveNodeSheetClosed] = useAtom(
    isLiveNodeSheetClosedAtom
  );

  const [dataset] = useAtom(dataSetParamsAtom);
  const [tokens] = useAtom(tokensAtom);
  const [simpleRuleEnvironment] = useAtom(ruleEnvironmentAtom);

  const [isRuleReadOnly, setIsRuleReadOnly] = useAtom(isRuleReadOnlyAtom);

  const [dataSetSelected] = useAtom(selectedDataSetAtom);
  const [dataSetFieldById] = useAtom(dataSetFieldsByIdAtom);
  const [dataSetVariables] = useAtom(dataSetParamsAtom);
  const [publishedConnectors] = useAtom(publishedConnectorsAtom);
  const [, setEnableCronTest] = useAtom(enableCronTestButtonAtom);
  const [firstCustomAttribute] = useAtom(firstCustomAttributeAtom);

  const [, setIsRuleTestOnly] = useAtom(isRuleTestOnlyAtom);
  const [, setIsRulePublishOnly] = useAtom(isRulePublishOnlyAtom);

  const [, setIsRuleLive] = useAtom(isRuleLiveAtom);
  const [, setVersionInfoRule] = useAtom(versionInfoRuleAtom);
  const [, setApprovalInfoRule] = useAtom(approvalInfoRuleAtom);
  const [versionMappingInfo, setVersionMappingInfo] = useAtom(
    versionMappingInfoAtom
  );
  const [, setErrorInRule] = useAtom(errorInRuleAtom);
  const [datasetDetailsInRule, setDatasetDetailsInRule] = useAtom(
    datasetDetailsInRuleAtom
  );

  const [, setUsedConnectorMapping] = useAtom(usedConnectorMappingAtom);
  const [, setHasConnectorError] = useAtom(
    hasConnectorErrorInCustomAttrSheetAtom
  );

  const { fetchMappedConnectorsData } = useUpdateMappedConnectorsData(false);

  const { openWithProps: openTestNodeSheet } = useLayer(
    <TestNodeSheet ruleName={ruleName} />
  );

  const { control, handleSubmit, setValue, setError, watch } = useForm<any>({
    resolver: zodResolver(resultDataSchema),
    defaultValues: {
      ruleName,
      ruleDescription: '',
      thenDataParams: [],
      elseDataParams: [],
      thenActionParams: [],
      elseActionParams: [],
      productionConfig: {
        staticUrl: '',
        order: 0,
        startDate: null,
        endDate: null,
        api: '',
        auditIO: false,
        isEnabled: true,
        authType: authTypes.find((authType) => authType.label === 'Private'),
        isApiEnabled: true,
        schedule: null,
        cache: {
          enabled: false,
          cacheKeys: '',
          duration: {
            unit: timeToExpireUnits[0],
            value: 0,
          },
        },
      },
      createdAt: null,
      publishedAt: null,
      status: '',
      editMode: false,
    },
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const name = watch('ruleName');
  const description = watch('ruleDescription');

  const thenParams = useWatch({
    control,
    name: 'thenDataParams',
  });

  const thenActionParams = useWatch({
    control,
    name: 'thenActionParams',
  });

  const elseActionParams = useWatch({
    control,
    name: 'elseActionParams',
  });

  const elseParams = useWatch({
    control,
    name: 'elseDataParams',
  });

  const simpleRuleName = useWatch({
    control,
    name: 'ruleName',
  });

  const simpleRuleDescription = useWatch({
    control,
    name: 'ruleDescription',
  });

  const stagingConfiguration = useWatch({
    control,
    name: 'stagingConfig',
  });

  const productionConfiguration = useWatch({
    control,
    name: 'productionConfig',
  });

  const statusValue = useWatch({
    control,
    name: 'status',
  });

  const accessRoleValue = useWatch({
    control,
    name: 'accessRole',
  });

  const { isHide: isEditDisable } = useCheckPermissions({
    allowedPermission: [permissionObj.create, permissionObj.edit],
    entityList: [ENTITY_ID.rules],
    entityStatus: statusValue,
    entityAccessRole: accessRoleValue,
  });

  const { isHide: testDisable } = useCheckPermissions({
    allowedPermission: [permissionObj.test],
    entityList: [ENTITY_ID.rules],
    entityStatus: statusValue,
    entityAccessRole: accessRoleValue,
  });

  const { isHide: publishDisable } = useCheckPermissions({
    allowedPermission: [permissionObj.publish],
    entityList: [ENTITY_ID.rules],
    entityStatus: statusValue,
    entityAccessRole: accessRoleValue,
  });

  const { openWithProps: openLiveNodeSheet } = useLayer(
    <CreateRuleSheet ruleId={ruleId} isLive={true} from="viewLive" />
  );

  const [, setSimpleNodeErrors] = useAtom(simpleNodeErrors);

  const [createRule, { loading: isRuleCreating }] = useSaveSimpleRule();
  const [updateRule, { loading: isRuleUpdating }] = useUpdateSimpleRule();
  const [updateRuleName, { loading: isRuleUpdatingName }] = useUpdateNameDesc();
  const [updateCustomInput, { loading: isRuleUpdatingCI }] =
    useUpdateCustomInput();
  const [updateTriggers, { loading: isRuleUpdatingTriggers }] =
    useUpdateTriggers();
  const [updateAction, { loading: isRuleUpdatingAction }] = useUpdateAction();
  const [updateConditions, { loading: isRuleUpdatingConditions }] =
    useUpdateConditions();

  const isUpdating =
    isRuleUpdating ||
    isRuleUpdatingName ||
    isRuleUpdatingCI ||
    isRuleUpdatingTriggers ||
    isRuleUpdatingAction ||
    isRuleUpdatingConditions;

  const isMutating = isRuleCreating || isUpdating;

  const handlePostUpdate = (
    data?: Record<string, any> | null,
    type?: string
  ) => {
    handleSetCheckSumByEntityName('rule', data?.updateRule.checksum);
    setId(data?.updateRule.id);

    setValue('status', data?.updateRule.status ?? 'draft');
    setValue('publishedAt', data?.updateRule.publishedAt);

    setVersionInfoRule(data?.updateRule.versionInfo);
    setIsRuleLive(data?.updateRule.isLive ?? false);
    setApprovalInfoRule(data?.updateRule.approvalInfo);

    setVersionMappingInfo(
      data?.updateRule.dependencyMap?.map(
        (currMapping: DependencyUsingMapType) => ({
          entityId: currMapping.id,
          type: currMapping.type,
          version: currMapping.version,
          nodeId: currMapping.nodeId,
        })
      ) ?? []
    );

    setRuleSavingError('');

    if (type === 'customInp') {
      setDatasetDetailsInRule(data?.updateRule.datasetDetail);
    }
  };

  const onSubmit = async (
    data: SimpleRuleModel,
    test: boolean = false,
    notSave: boolean = false,
    type: SaveType = 'all'
  ) => {
    const selectedDataSet = dataSetSelected[0];
    let isActionValid = true;
    let isOutputDataValid = true;

    if (test) {
      setIsTesting(true);
    }

    const isNameValid = isRuleNameValid(data.ruleName, setError);
    const isCacheValid = validateTriggerBeforeTest(
      data.productionConfig.cache ?? {},
      setError,
      'productionConfig',
      dataset
    );

    const customAttributeValidationErrors = validateCustomAttributesBeforeTest(
      customAttributes,
      dataSetFieldById,
      selectedDataSet
    );

    const updatedDataSet = () => {
      const newDs = createResultDataset({ ...dataset });

      const suggestionName = 'resultData';

      if (
        !_isNil(newDs.dataSet) &&
        !_isNil(newDs.dataSet.attributes) &&
        _isNil(newDs.dataSet.attributes[suggestionName])
      ) {
        const suggestionPayload: Attributes = {
          name: suggestionName,
          dataType: 'list',
          executedValue: [
            Object.keys(newDs.dataSet.attributes).reduce(
              (acc: Record<string, any>, key: string) => {
                acc[key] = newDs.dataSet.attributes[key].executedValue;

                return acc;
              },
              {}
            ),
          ],
        };
        newDs.dataSet.attributes = {
          ...newDs.dataSet.attributes,
          [suggestionName]: {
            ...suggestionPayload,
          },
        };
      }

      return {
        ...newDs,
      };
    };

    const currErrorInRule = {
      action: false,
      condition: false,
      outputData: false,
      additionalData: false,
    };

    const updatedFilteredDataSet: Record<string, Dataset> =
      updateDataSetOnChange(
        customAttributes,
        dataset,
        dataSetSelected,
        false,
        true
      );

    const errorByNodeId = validateSimpleRuleBeforeSaving(
      ruleList,
      startNodeId,
      updatedFilteredDataSet
    );

    let isConnectorError = true;

    if (test) {
      isConnectorError = await validateUsedConnectors(currErrorInRule);
    }

    const { thenErrors, elseErrors } = await validateTokensBeforeSaving(
      data,
      updatedDataSet(),
      tokens
    );

    isActionValid = await validateAction(
      data.thenActionParams,
      dataSetVariables,
      publishedConnectors,
      data.thenDataParams,
      test ? setError : undefined,
      'thenActionParams',
      undefined,
      tokens
    );

    if (isActionValid) {
      isActionValid = await validateAction(
        data.elseActionParams,
        dataSetVariables,
        publishedConnectors,
        data.elseDataParams,
        test ? setError : undefined,
        'elseActionParams',
        undefined,
        tokens
      );
    }

    if (test) {
      if (customAttributeValidationErrors.length > 0) {
        toasts.error(customAttributeValidationErrors[0].message, 'error');
      }

      setSimpleNodeErrors(errorByNodeId);

      thenErrors.forEach(({ index, message }) => {
        setError(`thenDataParams.${index as string}.value`, {
          message,
          type: 'validate',
        });
      });

      elseErrors.forEach(({ index, message }) => {
        setError(`elseDataParams.${index as string}.value`, {
          message,
          type: 'validate',
        });
      });

      data.thenDataParams.forEach((res, i) => {
        const thenDataSet = getOutputAddedDataset(data.thenDataParams, dataset);

        const valid = validateOutput(
          `thenDataParams.${i}.value`,
          res.value,
          res.dataType,
          res.source,
          res.attribute,
          tokens,
          setError,
          thenDataSet
        );

        if (!valid) {
          isOutputDataValid = false;
        }
      });

      data.elseDataParams.forEach((res, i) => {
        const elseDataSet = getOutputAddedDataset(data.elseDataParams, dataset);

        const valid = validateOutput(
          `elseDataParams.${i}.value`,
          res.value,
          res.dataType,
          res.source,
          res.attribute,
          tokens,
          setError,
          elseDataSet
        );

        if (!valid) {
          isOutputDataValid = false;
        }
      });
    }

    const { thenKeyNameSet, elseKeyNameSet } =
      validateKeyNameBeforeSaving(data);

    let uniqueOutputKeys = true;

    _forEach(thenKeyNameSet, (indexes) => {
      if (indexes.length > 1) {
        uniqueOutputKeys = false;

        indexes.forEach((index) => {
          setError(`thenDataParams.${index}.keyName`, {
            message: 'Key name must be unique',
            type: 'validate',
          });
        });
      }
    });

    const isTriggerValid = validateScheduleBeforeSaving(
      data.productionConfig,
      'productionConfig',
      customAttributes,
      setError
    );

    _forEach(elseKeyNameSet, (indexes) => {
      if (indexes.length > 1) {
        uniqueOutputKeys = false;

        indexes.forEach((index) => {
          setError(`elseDataParams.${index}.keyName`, {
            message: 'Key name must be unique',
            type: 'validate',
          });
        });
      }
    });

    setIsTesting(false);

    const ruleJson = transformSimpleRuleToRequestPayload(
      data,
      customAttributes,
      ruleList,
      startNodeId,
      firstCustomAttribute
    );

    const testSuccessful =
      _isEmpty(errorByNodeId) &&
      thenErrors.length === 0 &&
      elseErrors.length === 0 &&
      uniqueOutputKeys &&
      customAttributeValidationErrors.length === 0 &&
      isActionValid &&
      isNameValid &&
      isTriggerValid &&
      isOutputDataValid &&
      !isConnectorError &&
      isCacheValid;

    const isTestingValid = testSuccessful && test;

    if (testSuccessful) {
      setEnableCronTest(true);
    } else {
      setEnableCronTest(false);
    }

    if (test) {
      if (!isActionValid) {
        currErrorInRule.action = true;
      }

      if (
        !uniqueOutputKeys ||
        !isOutputDataValid ||
        thenErrors.length > 0 ||
        elseErrors.length > 0
      ) {
        currErrorInRule.outputData = true;
      }

      if (!_isEmpty(errorByNodeId)) {
        currErrorInRule.condition = true;
      }
      setErrorInRule(currErrorInRule);
    }

    if (!test && !notSave) {
      if (_isNil(id) || _isEmpty(id)) {
        try {
          const data = await createRule({
            variables: {
              type: ruleJson.type,
              name: ruleJson.name,
              description: ruleJson.description,
              customInput: ruleJson.customInput,
              action: ruleJson.rule.action,
              conditions: ruleJson.rule.conditions,
              settings: ruleJson.trigger.settings,
              dataSetId: dataSetSelected[0] ?? '',
              firstCustomInput: firstCustomAttribute,
              dependencyMap: versionMappingInfo,
            },
          });

          setId(data.data.createRule.id);
          handleSetCheckSumByEntityName('rule', data.data.createRule.checksum);

          setValue(
            'productionConfig.staticUrl',
            data.data.createRule.staticUrl
          );
          setValue('stagingConfig.staticUrl', data.data.createRule.staticUrl);
          setIsRulePublishable(false);
          setRuleSavingError('');
        } catch (error: unknown) {
          if (error instanceof Error) {
            setRuleSavingError(error.message);

            if (error.message.includes(checksumMessage)) {
              setShowChecksumPopup({
                showPopup: true,
                metaData: { ruleId: id, ruleName, type: 'simpleRule' },
              });
            } else {
              toasts.error(error.message, 'error');
            }
          }
        }
      } else {
        if (type === 'all') {
          try {
            const checksum = handleGetCheckSumByEntityName('rule');

            const { data } = await updateRule({
              variables: {
                id: id ?? ruleId,
                name: ruleJson.name,
                description: ruleJson.description,
                customInput: ruleJson.customInput,
                action: ruleJson.rule.action,
                conditions: ruleJson.rule.conditions,
                settings: ruleJson.trigger.settings,
                dataSetId: dataSetSelected[0] ?? '',
                checksum: checksum ?? '',
                firstCustomInput: firstCustomAttribute,
                editMode: ruleJson.editMode,
                dependencyMap: versionMappingInfo,
              },
            });

            handlePostUpdate(data);
          } catch (error: unknown) {
            if (error instanceof Error) {
              setRuleSavingError(error.message);

              if (error.message.includes(checksumMessage)) {
                setShowChecksumPopup({
                  showPopup: true,
                  metaData: { ruleId: id, ruleName, ruleType: 'simpleRule' },
                });
              } else {
                toasts.error(error.message, 'error');
              }
            }
          }
        } else if (type === 'nameDesc') {
          try {
            const checksum = handleGetCheckSumByEntityName('rule');

            const { data } = await updateRuleName({
              variables: {
                id: id ?? ruleId,
                name: ruleJson.name,
                description: ruleJson.description,
                editMode: ruleJson.editMode,
                checksum: checksum ?? '',
                dataSetId: dataSetSelected[0] ?? '',
                dependencyMap: versionMappingInfo,
              },
            });

            handlePostUpdate(data);
          } catch (error: unknown) {
            if (error instanceof Error) {
              setRuleSavingError(error.message);

              if (error.message.includes(checksumMessage)) {
                setShowChecksumPopup({
                  showPopup: true,
                  metaData: { ruleId: id, ruleName, ruleType: 'simpleRule' },
                });
              } else {
                toasts.error(error.message, 'error');
              }
            }
          }
        } else if (type === 'customInp') {
          try {
            const checksum = handleGetCheckSumByEntityName('rule');

            const { data } = await updateCustomInput({
              variables: {
                id: id ?? ruleId,
                editMode: ruleJson.editMode,
                checksum: checksum ?? '',
                customInput: ruleJson.customInput,
                dataSetId: dataSetSelected[0] ?? '',
                firstCustomInput: firstCustomAttribute,
                dependencyMap: versionMappingInfo,
              },
            });

            handlePostUpdate(data, type);
          } catch (error: unknown) {
            if (error instanceof Error) {
              setRuleSavingError(error.message);

              if (error.message.includes(checksumMessage)) {
                setShowChecksumPopup({
                  showPopup: true,
                  metaData: { ruleId: id, ruleName, ruleType: 'simpleRule' },
                });
              } else {
                toasts.error(error.message, 'error');
              }
            }
          }
        } else if (type === 'triggers') {
          try {
            const checksum = handleGetCheckSumByEntityName('rule');

            const { data } = await updateTriggers({
              variables: {
                id: id ?? ruleId,
                editMode: ruleJson.editMode,
                checksum: checksum ?? '',
                settings: ruleJson.trigger.settings,
                dataSetId: dataSetSelected[0] ?? '',
                dependencyMap: versionMappingInfo,
              },
            });

            handlePostUpdate(data);
          } catch (error: unknown) {
            if (error instanceof Error) {
              setRuleSavingError(error.message);

              if (error.message.includes(checksumMessage)) {
                setShowChecksumPopup({
                  showPopup: true,
                  metaData: { ruleId: id, ruleName, ruleType: 'simpleRule' },
                });
              } else {
                toasts.error(error.message, 'error');
              }
            }
          }
        } else if (type === 'action') {
          try {
            const checksum = handleGetCheckSumByEntityName('rule');

            const { data } = await updateAction({
              variables: {
                id: id ?? ruleId,
                editMode: ruleJson.editMode,
                checksum: checksum ?? '',
                action: ruleJson.rule.action,
                decisionTable: {},
                dataSetId: dataSetSelected[0] ?? '',
                dependencyMap: versionMappingInfo,
              },
            });

            handlePostUpdate(data);
          } catch (error: unknown) {
            if (error instanceof Error) {
              setRuleSavingError(error.message);

              if (error.message.includes(checksumMessage)) {
                setShowChecksumPopup({
                  showPopup: true,
                  metaData: { ruleId: id, ruleName, ruleType: 'simpleRule' },
                });
              } else {
                toasts.error(error.message, 'error');
              }
            }
          }
        } else if (type === 'conditions') {
          try {
            const checksum = handleGetCheckSumByEntityName('rule');

            const { data } = await updateConditions({
              variables: {
                id: id ?? ruleId,
                editMode: ruleJson.editMode,
                checksum: checksum ?? '',
                conditions: ruleJson.rule.conditions,
                decisionTable: {},
                dataSetId: dataSetSelected[0] ?? '',
                dependencyMap: versionMappingInfo,
              },
            });

            handlePostUpdate(data);
          } catch (error: unknown) {
            if (error instanceof Error) {
              setRuleSavingError(error.message);

              if (error.message.includes(checksumMessage)) {
                setShowChecksumPopup({
                  showPopup: true,
                  metaData: { ruleId: id, ruleName, ruleType: 'simpleRule' },
                });
              } else {
                toasts.error(error.message, 'error');
              }
            }
          }
        }
      }

      removeRequest();
    }
    setTimeout(() => {
      if (
        !_isEmpty(ruleJson.rule.action.then.firstActionNode) ||
        !_isEmpty(ruleJson.rule.action.else.firstActionNode)
      ) {
        if (!isOnboardingCompleted(actionsAdded)) {
          updateWidgetState(actionsAdded)
            .then(() => {
              void getUserState();
            })
            .catch((err) => {
              // eslint-disable-next-line no-console
              console.log(err);
            });
        }
      }

      if (
        !_isEmpty(ruleJson.rule.action.then.firstActionNode) ||
        !_isEmpty(ruleJson.rule.action.else.firstActionNode)
      ) {
        if (!isOnboardingCompleted(actionsAdded)) {
          updateWidgetState(actionsAdded)
            .then(() => {
              void getUserState();
            })
            .catch((err) => {
              // eslint-disable-next-line no-console
              console.log(err);
            });
        }
      }

      if (
        !_isNil(ruleJson.rule.conditions) &&
        !_isEmpty(ruleJson.rule.conditions.nodes) &&
        (Object.keys(ruleJson.rule.action.then.outputData).length > 0 ||
          Object.keys(ruleJson.rule.action.else.outputData).length > 0)
      ) {
        if (!isOnboardingCompleted(addConditionAndResult)) {
          updateWidgetState(addConditionAndResult)
            .then(() => {
              void getUserState();
            })
            .catch((err) => {
              // eslint-disable-next-line no-console
              console.log(err);
            });
        }
      }

      if (
        Object.keys(ruleJson.rule.action.then.outputData).some((key) =>
          key.includes('aggData')
        ) ||
        Object.keys(ruleJson.rule.action.else.outputData).some((key) =>
          key.includes('aggData')
        )
      ) {
        if (!isOnboardingCompleted(useJSCode)) {
          updateWidgetState(useJSCode)
            .then(() => {
              void getUserState();
            })
            .catch((err) => {
              // eslint-disable-next-line no-console
              console.log(err);
            });
        }
      }

      if (
        dataSetSelected.length > 0 &&
        dataSetSelected[0] !== '' &&
        !_isNil(dataSetSelected[0]) &&
        !isOnboardingCompleted(attachedDatabaseRule)
      ) {
        updateWidgetState(attachedDatabaseRule)
          .then(() => {
            void getUserState();
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.log(err);
          });
      }
    }, 2000);

    if (isTestingValid) {
      openTestNodeSheet({ ruleName: simpleRuleName, setValue });
    }
  };

  const {
    nodesStartId,
    nodeList,
    customDataParams,
    thenDataParams,
    elseDataParams,
    stagingConfig,
    productionConfig,
    discardRuleById,
    ruleName: updatedRuleName,
    ruleDescription: updatedRuleDescription,
    ruleLoading,
    ruleVersion,
    selectedDataSets,
    thenActionResponse,
    elseActionResponse,
    loadingData,
    createdAt,
    publishedAt,
    accessRole,
    status,
    handleGetRuleAfterStateTransition,
  } = useEditSimpleRule({
    isClone,
    ruleId: ruleId ?? id,
    isLive,
    ruleIdExist: !_isNil(ruleId) && !_isEmpty(ruleId),
    isLiveNodeSheetClosed,
    commitId,
  });

  const {
    ruleList,
    startNodeId,
    loading: dataLoading,
  } = useInitializeSimpleRule({
    customDataParams,
    elseDataParams,
    nodeList,
    nodesStartId,
    setValue,
    thenDataParams,
    stagingConfig,
    productionConfig,
    updatedRuleName,
    updatedRuleDescription,
    selectedDataSets,
    thenActionResponse,
    elseActionResponse,
    createdAt,
    publishedAt,
    status,
    accessRole,
  });

  const validateUsedConnectors = async (
    currErrorInRule: Record<string, boolean>
  ) => {
    const currentUsedConnectors: UsedConnectorMappingInEntityType = {};

    const connectorPresentInActions =
      [...thenActionParams, ...elseActionParams]?.reduce(
        (connObj: Record<string, boolean>, currAction: Record<string, any>) => {
          currentUsedConnectors[currAction.connectorId] = {
            status: true,
            source: ['action'],
          };

          return {
            ...connObj,
            [currAction.connectorId]: true,
          };
        },
        {}
      ) ?? {};

    const mappedDatasetConnId = datasetDetailsInRule?.connector.id;

    if (!_isNil(mappedDatasetConnId)) {
      const originalSource =
        currentUsedConnectors?.[mappedDatasetConnId]?.source ?? [];

      const source = originalSource?.includes('dataset')
        ? originalSource
        : [...originalSource, 'dataset'];

      currentUsedConnectors[mappedDatasetConnId] = {
        status: true,
        source,
      };
    }

    const restAPIConnectorKeys: Record<string, boolean> = {};

    Object.keys(customDataParams).forEach((key) => {
      const currCustomInput = customDataParams[key];
      const id = currCustomInput.attribute;

      if (
        currCustomInput.dataType?.value === 'restAPI' &&
        !_isNil(id) &&
        !_isEmpty(id)
      ) {
        restAPIConnectorKeys[id] = true;

        const originalSource = currentUsedConnectors?.[id]?.source ?? [];

        const source = originalSource?.includes('restApi')
          ? originalSource
          : [...originalSource, 'restApi'];

        currentUsedConnectors[id] = {
          status: true,
          source,
        };
      }
    });

    let error = false;
    let source = '';
    try {
      const response = await fetchMappedConnectorsData(currentUsedConnectors);

      if (!_isNil(response)) {
        const updatedUsedConnectorMapping = getUpdatedUsedConnectorsMapping(
          response,
          currentUsedConnectors
        );

        setUsedConnectorMapping(updatedUsedConnectorMapping);

        const connectorList = response.getConnector.data;

        for (let i = 0; i < connectorList.length; i++) {
          const connector = connectorList[i];

          error =
            !(connector.staging.isTested as boolean) ||
            !(connector.staging.isPublish as boolean);

          if (connector.id in connectorPresentInActions) {
            currErrorInRule.action = error;
            source = 'action';
          }

          if (connector.id === mappedDatasetConnId) {
            setHasConnectorError((prev) => ({
              ...prev,
              dataset: error,
            }));

            source = _isEmpty(source)
              ? 'Custom Input (Datasource)'
              : `${source}, Custom Input (Datasource)`;
          }

          if (connector.id in restAPIConnectorKeys) {
            setHasConnectorError((prev) => ({
              ...prev,
              restAPI: error,
            }));

            source = _isEmpty(source)
              ? 'Custom Input (Rest API)'
              : source.includes('Custom Input')
              ? source.substring(0, source.length - 1).concat(', Rest API)')
              : `${source}, Custom Input (Rest API)`;
          }

          if (error) {
            toasts.error(
              `Seems integration used in the ${source} is not published with staging config, Publish your integration staging config before testing this rule.`,
              'rule-connector-staging-not-published'
            );

            break;
          }
        }
      }
    } catch (err) {}

    return error;
  };

  const ruleIsLoading = ruleLoading || loadingData;

  useEffect(() => {
    setId(isClone ? undefined : ruleId);

    return () => {
      if (typeof refetch === 'function') {
        void refetch();
      }

      if (!isLive) {
        setId(undefined);
      }
    };
  }, [ruleId, isLive]);

  const getExecutedValue = async (
    models: ResultAddDataModel[],
    suggestions: NectedSuggestionModel[],
    section: 'thenDataParams' | 'elseDataParams'
  ) => {
    const items: ThenDataExecutionModel[] = [];

    models.forEach((model, modIndex) => {
      if (
        (_isNil(model.executedValue) ||
          model.executedValue === '' ||
          model.executedValue === 'undefined') &&
        (model.dataType === 'json' || model.dataType === 'jsFormula')
      ) {
        items.push({
          index: modIndex,
          code: prepareCodeStringForExecution(
            model.value as string,
            model.dataType
          ),
        });
      }
    });

    try {
      const data = await executeJSParallelPostRequests(
        items.map((value) => ({
          payload: {
            snippet: value.code,
            language: 'JS',
          },
        })),
        suggestions
      );

      setTimeout(() => {
        (data as ExecuteResult[]).forEach((val, index) => {
          if (val.code === 'success') {
            setValue(
              // eslint-disable-next-line
              `${section}.${items[index].index}.executedValue`,
              val.data.result
            );

            setValue(
              // eslint-disable-next-line
              `${section}.${items[index].index}.returnType`,
              detectType(val.data.result ?? null)
            );
          }
        });
      }, 1000);
    } catch (error) {}
  };

  const submitForm = handleSubmit(async (data) => await onSubmit(data));
  const submitFormNameDesc = handleSubmit(
    async (data) => await onSubmit(data, false, false, 'nameDesc')
  );
  const submitFormCI = handleSubmit(
    async (data) => await onSubmit(data, false, false, 'customInp')
  );
  const submitFormTriggers = handleSubmit(
    async (data) => await onSubmit(data, false, false, 'triggers')
  );
  const submitFormAction = handleSubmit(
    async (data) => await onSubmit(data, false, false, 'action')
  );
  const submitFormConditions = handleSubmit(
    async (data) => await onSubmit(data, false, false, 'conditions')
  );

  const submitAndTestForm = handleSubmit(
    async (data) => await onSubmit(data, true)
  );

  const onSubmitAndTestCron = handleSubmit(
    async (data) => await onSubmit(data, false, true)
  );

  const handleEditButton = handleSubmit(
    async (data) => await onSubmit({ ...data, editMode: true })
  );

  const { appendRequest, removeRequest } = useHandleRequests({
    onResolve: async (resArgs: { type: string }) => {
      if (resArgs.type === 'conditions') {
        await submitFormConditions();
      } else if (resArgs.type === 'triggers') {
        await submitFormTriggers();
      } else if (resArgs.type === 'nameDesc') {
        await submitFormNameDesc();
      } else if (resArgs.type === 'customInp') {
        await submitFormCI();
      } else if (resArgs.type === 'action') {
        await submitFormAction();
      } else {
        await submitForm();
      }
    },
    hasActivity: isMutating,
  });

  useEffect(() => {
    let submitTimeout: ReturnType<typeof setTimeout>;

    if (
      !_isNil(ruleList) &&
      !_isEmpty(ruleList) &&
      !dataLoading &&
      !ruleIsLoading &&
      !isLive &&
      (!isRuleReadOnly || isClone)
    ) {
      submitTimeout = setTimeout(() => {
        setEnableAutoSave(true);

        if (!enableAutoSave) {
          const dataSetSuggestionsObj = getDataSetSuggestionsObj(
            updateDataSetOnChange(
              customAttributes,
              dataSetVariables,
              dataSetSelected
            )
          );

          const suggestionsThen = getSuggestionsOnRuleLoad(
            dataSetSuggestionsObj,
            undefined,
            undefined,
            undefined,
            thenDataParams
          );

          const suggestionsElse = getSuggestionsOnRuleLoad(
            dataSetSuggestionsObj,
            undefined,
            undefined,
            undefined,
            elseDataParams
          );

          void getExecutedValue(
            thenDataParams,
            suggestionsThen,
            'thenDataParams'
          );

          void getExecutedValue(
            elseDataParams,
            suggestionsElse,
            'elseDataParams'
          );

          void onSubmitAndTestCron();
        }

        if ((enableAutoSave || isClone) && _isNil(id)) {
          appendRequest({ type: 'all' });
        }
      }, 1000);
    }

    return () => {
      if (!_isNil(submitTimeout)) {
        clearTimeout(submitTimeout);
      }
    };
  }, [
    JSON.stringify(ruleList),
    JSON.stringify(customAttributes),
    JSON.stringify(thenParams),
    JSON.stringify(elseParams),
    JSON.stringify(simpleRuleName),
    JSON.stringify(simpleRuleDescription),
    JSON.stringify(stagingConfiguration),
    JSON.stringify(productionConfiguration),
    JSON.stringify(dataSetSelected),
    JSON.stringify(thenActionParams),
    JSON.stringify(elseActionParams),
    JSON.stringify(versionMappingInfo),
    ruleIsLoading,
    dataLoading,
    isRuleReadOnly,
  ]);

  useEffect(() => {
    setIsRuleReadOnly(isEditDisable);
  }, [isEditDisable]);

  useEffect(() => {
    setIsRuleTestOnly(!testDisable);
  }, [testDisable]);

  useEffect(() => {
    setIsRulePublishOnly(!publishDisable);
  }, [publishDisable]);

  useEffect(() => {
    let submitTimeout: ReturnType<typeof setTimeout>;

    if (enableAutoSave && !_isNil(id)) {
      submitTimeout = setTimeout(() => {
        // void submitFormNameDesc();
        appendRequest({ type: 'nameDesc' });
      }, 500);
    }

    return () => {
      if (!_isNil(submitTimeout)) {
        clearTimeout(submitTimeout);
      }
    };
  }, [name, description]);

  useEffect(() => {
    let submitTimeout: ReturnType<typeof setTimeout>;

    if (enableAutoSave && !_isNil(id)) {
      submitTimeout = setTimeout(() => {
        appendRequest({ type: 'customInp' });
      }, 500);
    }

    return () => {
      if (!_isNil(submitTimeout)) {
        clearTimeout(submitTimeout);
      }
    };
  }, [
    JSON.stringify(customAttributes),
    JSON.stringify(dataSetSelected),
    JSON.stringify(versionMappingInfo),
  ]);

  useEffect(() => {
    let submitTimeout: ReturnType<typeof setTimeout>;

    if (enableAutoSave && !_isNil(id)) {
      submitTimeout = setTimeout(() => {
        appendRequest({ type: 'triggers' });
      }, 500);
    }

    return () => {
      if (!_isNil(submitTimeout)) {
        clearTimeout(submitTimeout);
      }
    };
  }, [
    JSON.stringify(stagingConfiguration),
    JSON.stringify(productionConfiguration),
  ]);

  useEffect(() => {
    let submitTimeout: ReturnType<typeof setTimeout>;

    if (enableAutoSave && !_isNil(id)) {
      submitTimeout = setTimeout(() => {
        appendRequest({ type: 'action' });
      }, 500);
    }

    return () => {
      if (!_isNil(submitTimeout)) {
        clearTimeout(submitTimeout);
      }
    };
  }, [
    JSON.stringify(thenParams),
    JSON.stringify(elseParams),
    JSON.stringify(thenActionParams),
    JSON.stringify(elseActionParams),
  ]);

  useEffect(() => {
    let submitTimeout: ReturnType<typeof setTimeout>;

    if (enableAutoSave && !_isNil(id)) {
      submitTimeout = setTimeout(() => {
        appendRequest({ type: 'conditions' });
      }, 500);
    }

    return () => {
      if (!_isNil(submitTimeout)) {
        clearTimeout(submitTimeout);
      }
    };
  }, [JSON.stringify(ruleList)]);

  const handleDiscardRule = async () => {
    if (!_isNil(id)) {
      const checksum = handleGetCheckSumByEntityName('rule');

      try {
        await discardRuleById({
          variables: { id, checksum: checksum ?? '' },
        });
        toasts.success('Rule reverted to live version successfully', 'success');
      } catch (err) {}
    }

    setEnableAutoSave(false);
  };

  const handleFetchRule = () => {
    setEnableAutoSave(false);
    void handleGetRuleAfterStateTransition();
  };

  if (ruleIsLoading) {
    return <RuleLoader />;
  }

  return (
    <form onKeyDown={handleKeyDown}>
      <CreateRuleContainer>
        <EntityAccessBlock isVisible={accessRoleValue === 'restricted'} />
        <PadBox padding={[10, 20]}>
          <Stack gutter={10}>
            <RuleSheetHeader
              id={id}
              control={control}
              title="simpleRule"
              onLiveButtonClick={() => {
                setIsRuleReadOnly(true);
                openLiveNodeSheet({ ruleId: id, from: 'viewLive' });
                setEnableAutoSave(false);
                setIsLiveNodeSheetClosed(false);
              }}
              ruleVersion={ruleVersion}
              showLiveButton={!isLive}
              setValue={setValue}
              isReadOnly={isLive || isRuleReadOnly}
              handleFetchRule={handleFetchRule}
            />
          </Stack>
        </PadBox>

        <ApiTriggerAndCronInfo
          id={id ?? ''}
          control={control}
          setValue={setValue}
        />

        <Tabs>
          <TabList styleClassName="srTabListStyle">
            <Tab>Editor</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              {!_isEmpty(startNodeId) && !ruleIsLoading ? (
                <RuleEditor
                  startNodeId={startNodeId}
                  rules={ruleList}
                  control={control}
                  simpleRuleId={id}
                  simpleRuleName={simpleRuleName}
                  discardRule={handleDiscardRule}
                  isReadOnly={isLive || isRuleReadOnly}
                  setValue={setValue}
                  isClone={isClone}
                  handleFetchRule={handleFetchRule}
                />
              ) : (
                <RuleEditorContainer align="center">
                  <Inline
                    as={StackAsItem}
                    grow={1}
                    align="center"
                    justify="center"
                  >
                    <Spinner size="small" />
                  </Inline>
                </RuleEditorContainer>
              )}
            </TabPanel>
          </TabPanels>
        </Tabs>

        <RuleSheetFooter
          isLive={isLive}
          isRuleCreating={isRuleCreating}
          isRuleUpdating={isUpdating}
          ruleEnvironment={simpleRuleEnvironment}
          submitAndTestForm={submitAndTestForm}
          id={id}
          ruleName={simpleRuleName}
          isClone={isClone}
          status={statusValue}
          accessRole={accessRoleValue}
          setValue={setValue}
          handleEditButton={handleEditButton}
          isTesting={isTesting}
        />
      </CreateRuleContainer>
    </form>
  );
};
