import { Inline } from '@bedrock-layout/primitives';
import styled, { css } from 'styled-components';

export type StatusPillType = 'published' | 'draft' | 'tested';

type StatusPillProps = {
  status: StatusPillType;
};

export const DataSetStatusPillStyled = styled.span<StatusPillProps>`
  padding: 0.3rem 2rem;
  border-radius: 1rem;

  ${({ status }) => {
    switch (status) {
      case 'published':
        return css`
          background: var(--color-honeydew);
          color: var(--color-malachite);
        `;
      case 'draft':
        return css`
          background: var(--color-earlyDawn);
          color: var(--color-tangerine);
        `;
      case 'tested':
        return css`
          background: var(--color-primary2);
          color: var(--color-primary1);
        `;
      default:
        return null;
    }
  }}
`;

export const DatasetEditorContainer = styled(Inline)<{ $isLive: boolean }>`
  border-block-start: 1px solid var(--color-darkGray);
  block-size: ${({ $isLive }) =>
    $isLive ? 'calc(100vh - 14rem)' : 'calc(100vh - 18rem)'};
`;
