import { useMutation } from '@tanstack/react-query';
import type { AxiosError, AxiosResponse } from 'axios';
import _isNil from 'lodash/isNil';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAxiosPrivate } from '../../../../hooks';
import { showErrorToast } from '../../../../utils/common';
import { CustomAxiosError } from '../../../../utils/response/types';

export type GenerateEntityIdRequestPayload = {
  usecaseId: string;
  url: string;
  target: string;
};

export function useGenrateEntityId() {
  const { axiosPrivate } = useAxiosPrivate();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const { mutate: generateEntityId } = useMutation({
    mutationFn: async (request: GenerateEntityIdRequestPayload) => {
      const url = request.url.split('/')?.[1] ?? '';
      const entity = url.includes('rules') ? 'rule' : 'workflow';

      return await axiosPrivate.post(`demo/${entity}`, request);
    },
    onSuccess(
      response: AxiosResponse,
      requestPayload: GenerateEntityIdRequestPayload
    ) {
      const { data } = response.data;
      let ruleURL = requestPayload.url.replace('ID', data.entityId);

      if (!_isNil(searchParams.get('step'))) {
        ruleURL = `${ruleURL}&step=${searchParams.get('step') as string}`;
      }
      navigate(ruleURL);
    },
    onError(error: AxiosError<CustomAxiosError>) {
      showErrorToast(error);
    },
  });

  return {
    generateEntityId,
  };
}
