import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useRef, useState } from 'react';
import { BiPlus } from 'react-icons/bi';
import {
  Button,
  MenuItem,
  PopoverMethods,
  Typography,
  toasts,
  useLayer,
} from 'ui';

import { PermissionWrapper } from '../../../../components/PermissionComponent';
import { permissionObj } from '../../../../components/PermissionComponent/constant';
import { getUserState } from '../../../../hooks/getUserState';
import { useGetAuthKeyList } from '../../../../hooks/useGetAuthKeyList';
import type { SavedAuthConfigKeys } from '../../../../types';
import { isOnboardingCompleted } from '../../../../utils/common';
import { ENTITY_ID, generateApiKey } from '../../../../utils/constant';
import { updateWidgetState } from '../../../Home/components/sub-components/UpdateWidgetState';
import { credentialLimitsConfigAtom } from '../../Credentials';
import { useGetAuthConfigKeys } from '../hooks/useGetAuthConfigKeys';
import { ApiKeySheet } from './ApiKeySheet';
import {
  AuthenticationConfigMenu,
  MenuItemContainer,
} from './AuthenticationConfig.styled';
import { AuthenticationConfigList } from './AuthenticationConfigList';
import { BasicAuthSheet } from './BasicAuth';
import { BearerTokenSheet } from './BearerTokenSheet';
import { OAuthSheet } from './oAuth';

export type UpdateCredentialsFormValues = Record<string, string>;

export function AuthenticationConfig() {
  const ref = useRef<PopoverMethods>(null);

  const { authKeyList } = useGetAuthKeyList();

  const [limitConfig] = useAtom(credentialLimitsConfigAtom);
  const [authenticationKeys, setAuthenticationKeys] = useState<
    SavedAuthConfigKeys[]
  >([]);

  const { data, refetch } = useGetAuthConfigKeys();
  const { open: openBearerTokenSheet } = useLayer(
    <BearerTokenSheet refetch={refetch} />
  );

  const { open: openApiKeySheet } = useLayer(<ApiKeySheet refetch={refetch} />);

  const { open: openOAuthSheet } = useLayer(<OAuthSheet refetch={refetch} />);

  const { open: openBasicAuthSheet } = useLayer(
    <BasicAuthSheet refetch={refetch} />
  );

  useEffect(() => {
    if (!_isNil(data)) {
      if (
        data?.getAuthConfigKeys.data.length > 0 &&
        !isOnboardingCompleted(generateApiKey)
      ) {
        updateWidgetState(generateApiKey)
          .then(() => {
            void getUserState();
          })
          .catch((err) => {
            // eslint-disable-next-line no-console
            console.log(err);
          });
      }

      setAuthenticationKeys(data?.getAuthConfigKeys.data);
    }
  }, [data]);

  const openMenuItem = (value: string) => {
    if (
      !_isEmpty(limitConfig) &&
      authenticationKeys.length < limitConfig.maxConnectorConfig.value
    ) {
      if (value === 'bearerToken') {
        openBearerTokenSheet();
      } else if (value === 'ApiKey') {
        openApiKeySheet();
      } else if (value === 'oAuth') {
        openOAuthSheet();
      } else if (value === 'basicAuth') {
        openBasicAuthSheet();
      }
    } else if (!_isEmpty(limitConfig.maxConnectorConfig.message)) {
      toasts.info(limitConfig.maxConnectorConfig.message, 'success');
    }

    ref.current?.hide();
  };

  return (
    <Stack gutter="3rem">
      <Stack gutter="0.8rem">
        <Typography name="heading2">Authorization</Typography>

        <Typography>
          APIs use authorization to ensure that client requests access data
          securely.
        </Typography>
      </Stack>

      <PermissionWrapper
        allowedPermission={[permissionObj.create, permissionObj.edit]}
        entityList={[ENTITY_ID.credentials]}
      >
        <Inline>
          <AuthenticationConfigMenu
            launcher={
              <Button leadingIcon={<BiPlus size={20} />}>
                New Authentication
              </Button>
            }
            ref={ref}
            onMenuItemClick={(value) => openMenuItem(value)}
            placement="bottom-start"
          >
            {authKeyList.map((authKey) => (
              <MenuItem value={authKey.id} key={authKey.id}>
                <MenuItemContainer>
                  <Typography>{authKey.title}</Typography>
                  <Typography name="secondarySmall">{authKey.desc}</Typography>
                </MenuItemContainer>
              </MenuItem>
            ))}
          </AuthenticationConfigMenu>
        </Inline>
      </PermissionWrapper>

      <AuthenticationConfigList
        authenticationKeys={authenticationKeys}
        setAuthenticationKeys={setAuthenticationKeys}
        refetch={refetch}
      />
    </Stack>
  );
}
