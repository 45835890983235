import styled, { css } from 'styled-components';

type MenuItemStyledProps = {
  selected?: boolean;
  removeHover?: boolean;
  minWidth?: string;
  $disabled?: boolean;
};

const disabledCssRules = css`
  cursor: not-allowed;
  color: var(--color-darkGray);
`;

export const MenuItemStyled = styled.button<MenuItemStyledProps>`
  padding-block: 0.2rem;
  border: none;
  cursor: pointer;
  max-inline-size: 30rem;
  text-align: left;

  background: ${({ selected = false }) =>
    selected ? 'var(--color - solitude)' : 'transparent'};

  min-inline-size: ${({ minWidth = '10rem' }) => minWidth};

  ${({ $disabled = false }) => $disabled && disabledCssRules}

  &.btn-disabled-interceptor {
    cursor: progress !important;
    color: var(--color-black) !important;

    background: ${({ selected = false }) =>
      selected ? 'var(--color - solitude)' : 'transparent'};
  }

  &:hover {
    ${({ removeHover = false }) =>
      !removeHover && `background: var(--color-solitude);`}
  }
`;
