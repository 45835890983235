export const tabList = [
  {
    label: 'Draft',
    value: 'draft',
  },
  {
    label: 'In Review',
    value: 'inreview',
  },
  {
    label: 'Published',
    value: 'published',
  },
  {
    label: 'Archive',
    value: 'archived',
  },
];

export const pageSize = 10;
