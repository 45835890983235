import { PadBox } from '@bedrock-layout/padbox';
import { Stack } from '@bedrock-layout/stack';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  TextAreaField,
  TextField,
  Typography,
  useCurrentLayer,
} from 'ui';

import { entiyDataSchema } from './schema';

export type EntityInfoModalFormData = {
  name: string;
  description?: string;
};

type EnityInfoUpdateModalProps = {
  entityName: string;
  name: string;
  description?: string;
  onSave?: (data: EntityInfoModalFormData) => void;
};

export function EntityInfoUpdateModal({
  entityName,
  name,
  description,
  onSave,
}: EnityInfoUpdateModalProps) {
  const { close: closeModal } = useCurrentLayer();

  const { control, handleSubmit } = useForm<EntityInfoModalFormData>({
    resolver: zodResolver(entiyDataSchema),
    defaultValues: {
      name,
      description,
    },
  });

  const onSubmit = (data: EntityInfoModalFormData) => {
    if (typeof onSave === 'function') {
      onSave(data);
    }

    closeModal();
  };

  return (
    <Modal size="extraLarge" title={`Edit ${entityName}`} overflow="none">
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalContent>
          <Stack as={PadBox} gutter="1.5rem" padding={['1.5rem', 0, '4rem', 0]}>
            <TextField
              control={control}
              label={`${entityName} Name`}
              placeholder="Enter Name"
              name="name"
              showErrorIcon={false}
            />

            <Stack gutter="0.8rem">
              <Typography>{`${entityName} Description`}</Typography>

              <TextAreaField
                control={control}
                placeholder="Enter Desciption"
                name="description"
                rows={4}
              />
            </Stack>
          </Stack>
        </ModalContent>

        <ModalFooter>
          <Button onClick={closeModal} appearance="neutral">
            Close
          </Button>

          <Button type="submit">Save</Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
