import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _reduce from 'lodash/reduce';
import { useEffect, useState } from 'react';

import { enablePermissionApiAtom, userProfileAtom } from '../../atom';
import { useAuth } from '../../components/authentication';
import { roleJsonAtom } from '../../components/authentication/router/AuthProvider';
import { useAxiosVidhanPrivate } from '../../hooks/useAxiosVidhanPrivate';
import { useSendEventToGTM } from '../../hooks/useSendEventToGTM';
import { currentWorkspaceDetailAtom } from '../../pages/Workspace/atom';
import { RoleJsonType } from '../../types';
import { getRoleJson, updateRoleJson } from '../../utils/common';
import {
  ENTITY_ID,
  FEATURE_FLAG_API_CONFIG,
  envMap,
} from '../../utils/constant';

type RoutePermissionsProps = {
  roleJson: RoleJsonType;
  routeId: string;
  currentRoutePath: string;
};

export function useGetRoutePermisisons({
  roleJson = {},
  routeId = '',
  currentRoutePath = '',
}: RoutePermissionsProps) {
  const [, setRoleJson] = useAtom(roleJsonAtom);

  const { isLoggedIn } = useAuth();
  const [currentWorkspace] = useAtom(currentWorkspaceDetailAtom);
  const [enablePermissionApi] = useAtom(enablePermissionApiAtom);

  const [userProfile] = useAtom(userProfileAtom);
  const email = !_isEmpty(userProfile) ? userProfile?.email : '';

  const [apiConfig, setApiConfig] = useState(FEATURE_FLAG_API_CONFIG);
  const [currentRouteId, setRouteId] = useState('');

  const { axiosVidhanPrivate } = useAxiosVidhanPrivate();
  const { sendEventToGTM } = useSendEventToGTM();

  const rJson = getRoleJson();

  const fetchPermissionJson = async () => {
    const response = await axiosVidhanPrivate.post(
      apiConfig.url,
      apiConfig.payload
    );
    const data = response?.data?.data?.output ?? [];

    if (data.length > 0) {
      let updatedRoleJson = {};

      const rJson = getRoleJson();

      updatedRoleJson = {
        flags: rJson?.flags ?? {},
        internals: {
          ...rJson.internals,
          [currentRouteId]: data[0]?.data?.internals ?? {},
        },
      };

      setRoleJson(updatedRoleJson);
      updateRoleJson(updatedRoleJson);
    }
  };

  useEffect(() => {
    const environment =
      envMap.VITE_ENVIRONMENT === 'development' ? 'staging' : 'production';

    setApiConfig({
      ...apiConfig,
      payload: {
        ...apiConfig.payload,
        environment,
      },
    });
  }, []);

  useEffect(() => {
    let updatedCurrentRouteId = '';

    if (!_isEmpty(currentRoutePath)) {
      if (currentRoutePath === '/') {
        updatedCurrentRouteId = 'home';
      } else {
        updatedCurrentRouteId = _reduce(
          ENTITY_ID,
          (acc, curr) => {
            return currentRoutePath.includes(curr) ? curr : acc;
          },
          ''
        );
      }
    }

    setRouteId(updatedCurrentRouteId);
  }, [currentRoutePath]);

  useEffect(() => {
    if (currentRouteId === 'home' && enablePermissionApi) {
      setRoleJson(rJson);
      updateRoleJson(rJson);
    }

    const { user, role } = apiConfig.payload.params;

    if (
      isLoggedIn &&
      !_isEmpty(user) &&
      !_isEmpty(role) &&
      currentRouteId !== 'home' &&
      currentRouteId === routeId &&
      enablePermissionApi &&
      _isNil(rJson?.internals?.[currentRouteId])
    ) {
      void fetchPermissionJson();
    }

    if (
      isLoggedIn &&
      !_isEmpty(user) &&
      !_isEmpty(role) &&
      currentRouteId === routeId
    ) {
      sendEventToGTM({
        event: 'page_view',
        page_path: currentRoutePath,
        page_title:
          currentRouteId.charAt(0).toUpperCase() + currentRouteId.slice(1),
        page_location: window.location.href,
      });
    }
  }, [
    isLoggedIn,
    JSON.stringify(apiConfig),
    enablePermissionApi,
    JSON.stringify(rJson),
  ]);

  useEffect(() => {
    const role = currentWorkspace?.role ?? '';
    const wid = currentWorkspace?.uuid ?? '';

    if (!_isEmpty(email) || !_isEmpty(role)) {
      setApiConfig({
        ...apiConfig,
        payload: {
          ...apiConfig.payload,
          params: {
            ...apiConfig.payload.params,
            user: window.btoa(email ?? ''),
            role,
            wid,
            entity: currentRouteId,
          },
        },
      });
    }
  }, [email, currentWorkspace, currentRouteId]);
}
