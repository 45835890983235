import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { Ref, forwardRef } from 'react';
import { VscTriangleDown } from 'react-icons/vsc';
import { Typography } from 'ui';

import {
  ContainerStyled,
  DataSourceContainer,
  DataSourceValue,
} from './DataSourceLauncher.styled';

type SelectInputAttrProps = {
  text: string;
  error?: string;
};

export const SelectInputAttrLauncher = forwardRef(
  ({ text = 'Select', error }: SelectInputAttrProps, ref: Ref<HTMLElement>) => {
    return (
      <Stack gutter="0.4rem">
        <ContainerStyled
          as={Inline}
          align="center"
          justify="center"
          padding={[3, 8]}
          ref={ref}
        >
          <DataSourceContainer stretch="start" align="center">
            <DataSourceValue>
              {!_isEmpty(text) ? text : 'Select Attribute'}
            </DataSourceValue>

            <VscTriangleDown size={12} />
          </DataSourceContainer>
        </ContainerStyled>

        {!_isNil(error) && <Typography name="error">{error}</Typography>}
      </Stack>
    );
  }
);

SelectInputAttrLauncher.displayName = 'SelectInputAttrLauncher';
