import { Inline } from '@bedrock-layout/inline';
import styled, { css } from 'styled-components';
import theme from 'styled-theming';

import { Typography } from '../Typography';

type ButtonProps = {
  disabled?: boolean;
};

const disabledCssRules = css`
  cursor: not-allowed;
  color: var(--color-darkGray);
`;

const buttonAppearance = theme('appearance', {
  contained: css`
    border-color: var(--color-primary1);
    background-color: var(--color-primary1);
    color: var(--color-white);

    &.btn-disabled-interceptor {
      cursor: progress;
    }

    &:not(.btn-disabled-interceptor):disabled {
      ${disabledCssRules};
      background-color: var(--color-solitude);
      border-color: var(--color-solitude);
    }
  `,
  bordered: css`
    border-color: var(--color-primary1);
    background-color: var(--color-white);
    color: var(--color-primary1);

    &.btn-disabled-interceptor {
      cursor: progress;
    }

    &:not(.btn-disabled-interceptor):disabled {
      ${disabledCssRules};
      border-color: var(--color-solitude);
    }
  `,
  borderedNeutral: css`
    border-color: var(--color-whiteSmoke);
    background-color: var(--color-white);
    color: var(--color-black);

    &.btn-disabled-interceptor {
      cursor: progress;
    }

    &:not(.btn-disabled-interceptor):disabled {
      ${disabledCssRules};
      border-color: var(--color-solitude);
    }
  `,
  'bordered-black': css`
    border-color: var(--color-solitude);
    background-color: var(--color-white);
    color: var(--color-black);

    &.btn-disabled-interceptor {
      cursor: progress;
    }

    &:not(.btn-disabled-interceptor):disabled {
      ${disabledCssRules};
      background-color: var(--color-whiteSmoke);
    }
  `,
  filled: css`
    border-color: var(--color-primary2);
    background-color: var(--color-primary2);
    color: var(--color-primary1);

    &.btn-disabled-interceptor {
      cursor: progress;
    }

    &:not(.btn-disabled-interceptor):disabled {
      ${disabledCssRules};
      background-color: var(--color-white);
      border-color: var(--color-solitude);
    }
  `,
  text: css`
    border-color: transparent;
    background: var(--color-white);
    color: var(--color-primary1);

    &.btn-disabled-interceptor {
      cursor: progress;
    }

    &:not(.btn-disabled-interceptor):disabled {
      ${disabledCssRules}
    }
  `,
  danger: css`
    border-color: var(--color-fireEngineRed);
    background-color: var(--color-fireEngineRed);
    color: var(--color-white);

    &.btn-disabled-interceptor {
      cursor: progress;
    }

    &:not(.btn-disabled-interceptor):disabled {
      ${disabledCssRules};
      background-color: var(--color-white);
      border-color: var(--color-solitude);
    }
  `,
  neutral: css`
    border-color: var(--color-whiteSmoke);
    background-color: var(--color-whiteSmoke);
    color: var(--color-black);

    &.btn-disabled-interceptor {
      cursor: progress;
    }

    &:not(.btn-disabled-interceptor):disabled {
      ${disabledCssRules};
      background-color: var(--color-white);
      border-color: var(--color-solitude);
    }
  `,
});

const buttonSize = theme('size', {
  small: css`
    font-size: 1.4rem;
    padding: 0.5rem 2rem;
  `,
  medium: css`
    font-size: 1.4rem;
    padding: 1rem 3rem;
  `,
  large: css`
    font-size: 1.6rem;
    padding: 1.5rem 4rem;
  `,
});

const buttonIconSize = theme('size', {
  small: css`
    font-size: 1.4rem;
    padding: 0rem 0.5rem;
  `,
  medium: css`
    font-size: 1.4rem;
    padding: 0.5rem 1rem;
  `,
  large: css`
    font-size: 1.6rem;
    padding: 1rem 2rem;
  `,
});

export const ButtonTextStyled = styled.button<ButtonProps>`
  cursor: pointer;
  border-top-left-radius: 0.4rem;
  border-bottom-left-radius: 0.4rem;
  border-width: 1px;
  border-style: solid;

  ${buttonAppearance}
  ${buttonSize}
`;

export const ButtonIconStyled = styled.div<ButtonProps>`
  cursor: pointer;
  border-top-right-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
  border-width: 1px;
  border-style: solid;

  ${buttonAppearance}
  ${buttonIconSize}
`;

export const PopoverListContainer = styled.div`
  overflow-y: auto;
  max-block-size: 20rem;
  inline-size: 20rem;
`;

export const ListItemStyled = styled(Inline)<{ isSelected?: boolean }>`
  border-bottom: 1px solid var(--color-lightGray);
  padding: 0.8rem;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;

  ${({ isSelected = false }) =>
    isSelected ? 'background-color: var(--color-primary2)' : ''}
`;

export const LabelStyled = styled(Typography)`
  color: var(--color-primary1);
`;

export const SubtitleStyled = styled(Typography)`
  color: var(--color-darkGray);
`;
