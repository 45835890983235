import { Navigate, Outlet } from 'react-router-dom';

import { useAuth } from './AuthProvider';

export function AuthenticationRequired() {
  const { isLoggedIn } = useAuth();
  const redirectURL = `${window.location.pathname}${window.location.search}`;
  const signInURL = `/signin?redirect=${redirectURL}`;

  return isLoggedIn ? <Outlet /> : <Navigate to={signInURL} />;
}
