import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import React, { CSSProperties, ReactElement } from 'react';

import { TooltipReact } from '../TooltipReact';
import { Typography } from '../Typography';
import { MenuItemStyled } from './MenuItem.styled';

export type MenuItemProps = {
  children: ReactElement;
  value: any;
  onClick?: (value: any) => void;
  selected?: boolean;
  removeHover?: boolean;
  minWidth?: string;
  disabled?: boolean;
  style?: CSSProperties;
  tooltipText?: string;
};

export const MenuItem = ({
  onClick,
  children,
  value,
  selected,
  removeHover = false,
  minWidth,
  disabled = false,
  style,
  tooltipText,
}: MenuItemProps) => {
  if (disabled && !_isNil(tooltipText) && !_isEmpty(tooltipText)) {
    return (
      <TooltipReact
        id={value}
        launcher={
          <MenuItemStyled
            type="button"
            minWidth={minWidth}
            $disabled={disabled}
            onClick={() => {
              if (typeof onClick === 'function' && !disabled) {
                onClick(value);
              }
            }}
            selected={selected}
            removeHover={removeHover}
            style={style}
          >
            {children}
          </MenuItemStyled>
        }
      >
        <Typography>{tooltipText}</Typography>
      </TooltipReact>
    );
  }

  return (
    <MenuItemStyled
      type="button"
      minWidth={minWidth}
      $disabled={disabled}
      onClick={() => {
        if (typeof onClick === 'function' && !disabled) {
          onClick(value);
        }
      }}
      selected={selected}
      removeHover={removeHover}
      style={style}
    >
      {children}
    </MenuItemStyled>
  );
};
