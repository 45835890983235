import type { Dataset } from 'ui';

import { generateUid } from '../../../utils/common';

export const dataset: Record<string, Dataset> = {
  custom: {
    name: 'Custom Functions',
    id: generateUid('param_'),
    order: 4,
    attributes: {
      sql: {
        name: 'Formula',
        dataType: 'jsCondition',
      },
    },
  },
  customInput: {
    name: 'Input Attributes',
    id: generateUid('constant_'),
    order: 1,
    attributes: {},
  },
};
