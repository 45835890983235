import { Typography, useLayer } from 'ui';
import { CodeNodeSheet } from '../CodeNodeSheet/CodeNodeSheet';
import { SqlPillStyled } from './RuleSqlCondition.styled';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';

export type SqlNodePillProps = {
  ruleId: string;
  name?: string;
};

export function SqlNodePill({ ruleId, name }: SqlNodePillProps) {
  const { open: openCodeNode } = useLayer(<CodeNodeSheet ruleId={ruleId} />);

  return (
    <SqlPillStyled onClick={openCodeNode} padding={[2, 12]}>
      <Typography>
        {_isNil(name) || _isEmpty(name) ? 'Untitled' : name}
      </Typography>
    </SqlPillStyled>
  );
}
