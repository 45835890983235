import { z } from 'zod';

export const keySchema = z.object({
  name: z
    .string()
    .nonempty('Required Field')
    .min(1, 'Invalid name')
    .max(50, 'Maximum 50 characters allowed')
    .refine((value) => value.trim() !== '', {
      message: 'Only spaces not allowed',
    }),
});
