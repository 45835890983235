import { PadBox } from '@bedrock-layout/padbox';
import { Inline, Stack } from '@bedrock-layout/primitives';
import styled from 'styled-components';

export const JsonContainer = styled(PadBox)`
  position: relative;
  block-size: 25rem;
  margin-bottom: 1rem;
`;

export const StyledLink = styled.a`
  font-size: 1.8rem;
  font-weight: 700;
  cursor: pointer;
  color: var(--color-primary1);
  text-decoration: none;
`;

export const Curl = styled(Inline)`
  overflow-x: auto;
`;

export const ApiContainer = styled(Inline)`
  background-color: var(--color-whiteSmoke);
  border-radius: 0.4rem;
  padding: 2rem;
  overflow-y: auto;
  block-size: 30rem;
`;

export const ApiWebhookContainer = styled(Stack)`
  background-color: var(--color-lightGray2);
  padding: 1rem 0.5rem 0.5rem 0.5rem;
  border: 1px solid var(--color-lightGray8);
  border-radius: 0.4rem;
  inline-size: 50vw;
`;

export const ResponseContainer = styled.div`
  inline-size: 50%;
`;
