import { gql, useMutation } from '@apollo/client';

import type { AddConfigModel } from '../models';

const saveConfigQuery = gql`
  mutation saveConfig($name: String!, $dataType: String!, $value: Any!) {
    saveConfig: createVariable(
      input: { name: $name, dataType: $dataType, value: $value }
    ) {
      id
      name
      value
      dataType
    }
  }
`;

export function useSaveConfig() {
  return useMutation<AddConfigModel, AddConfigModel>(saveConfigQuery);
}
