import { Inline, PadBox } from '@bedrock-layout/primitives';
import styled from 'styled-components';

type ConvertIntoGroupButton = {
  $active: boolean;
};

type GroupBtnStyledProps = {
  $isStartNode?: boolean;
};

export const BlockStyled = styled(PadBox)`
  border-inline: 1.5px solid var(--color-darkGray);
  border-block: 1px dashed var(--color-lightGray);
  min-block-size: 5rem;
  min-inline-size: 40rem;
  border-radius: 6px;
  position: relative;

  &:before {
    content: '';
    inline-size: 1rem;
    block-size: 1rem;
    background-color: var(--color-white);
    border-block-start: 1px solid var(--color-darkGray);
    border-inline-start: 1px solid var(--color-darkGray);
    position: absolute;
    inset-block-start: 50%;
    inset-inline-start: -6px;
    transform: translateY(-50%) rotate(315deg);
  }

  &:after {
    content: '';
    inline-size: 1rem;
    block-size: 1rem;
    background-color: var(--color-white);
    border-block-start: 1px solid var(--color-darkGray);
    border-inline-start: 1px solid var(--color-darkGray);
    position: absolute;
    inset-block-start: 50%;
    inset-inline-end: -6.5px;
    transform: translateY(-50%) rotate(135deg);
  }
`;

export const ConditionStyled = styled(PadBox)`
  min-inline-size: 20rem;
`;

export const OperatorStyled = styled.span<{ operator: string }>`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  min-inline-size: 4.5rem;
  padding: 0.2rem 0.8rem;
  border-radius: 100px;
  text-align: center;
  text-transform: capitalize;
  z-index: 10;
  cursor: pointer;

  background-color: ${({ operator }) =>
    operator === 'and' ? 'var(--color-magnolia)' : 'var(--color-papayaWhip)'};
`;

export const GroupBtnStyled = styled(Inline)<GroupBtnStyledProps>`
  position: absolute;
  bottom: -10px;
  inset-inline-start: ${({ $isStartNode = false }) =>
    $isStartNode ? '30px' : '75px'};
`;

export const AddChildStyled = styled(Inline)`
  background-color: var(--color-white);
  cursor: pointer;
  color: var(--color-primary1);
`;

export const RuleOperator = styled.div`
  position: absolute;
  inset-inline-start: 29px;
`;

export const RuleDeleteStyled = styled(Inline)`
  position: absolute;
  inset-inline-start: 26px;
  inset-block-start: calc(50% + 15px);
`;

export const GroupOperatorStyled = styled(Inline)`
  position: relative;
`;

export const ConvertIntoGroupButtonStyled = styled(
  Inline
)<ConvertIntoGroupButton>`
  background-color: var(--color-white);
  opacity: 0;
  color: var(--color-primary1);
  cursor: pointer;
  pointer-events: none;
  ${({ $active }) => $active && 'opacity: 1; pointer-events: auto;'}
`;

export const DeleteIconStyled = styled(Inline)<ConvertIntoGroupButton>`
  opacity: 0;
  pointer-events: none;
  color: var(--color-darkGray);
  ${({ $active }) => $active && 'opacity: 1; pointer-events: auto;'}
`;

export const RuleGroupBlock = styled(PadBox)<{ $isReadOnly?: boolean }>`
  position: relative;
  min-inline-size: 70rem;

  ${({ $isReadOnly = false }) => $isReadOnly && 'pointer-events: none;'}
`;

export const RuleConditionStyled = styled(PadBox)<{ $isReadOnly?: boolean }>`
  min-inline-size: 40rem;

  ${({ $isReadOnly = false }) => $isReadOnly && 'pointer-events: none;'}
`;

export const RuleSqlConditionStyled = styled(PadBox)<{ $isReadOnly?: boolean }>`
  min-inline-size: 40rem;

  ${({ $isReadOnly = false }) => $isReadOnly && 'pointer-events: none;'}
`;
