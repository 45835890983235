import { Inline, Stack } from '@bedrock-layout/primitives';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import {
  UseControllerProps,
  UseFormSetValue,
  useForm,
  useWatch,
} from 'react-hook-form';
import {
  Button,
  Dataset,
  ExpandingTextField,
  Modal,
  ModalContent,
  ModalFooter,
  NectedEditorField,
  NectedSuggestionModel,
  Typography,
  toasts,
  useCurrentLayer,
  useLayer,
} from 'ui';

import { siteConstantsAtom } from '../../../../../../../atom';
import { getTooltipText } from '../../../../../../../utils/common';
import { editorDomain } from '../../../../../../../utils/constant';
import { SyntaxErrorContainer } from '../../../../../../DataSets/components/DataSetForm.styled';
import { JsNodeValues } from '../../../../../../Rules/components/DecisionTable/models';
import { OnCloseNodeModal } from '../../../../../../Rules/components/SimpleRule/Results/OnCloseNodeModal';
import { useUpdateExecutedValueRules } from '../../../../../../Rules/hooks/useUpdateExecutedValueRules';
import { jsNodeSchema } from '../../../../../../Rules/schema';
import { isCorrectJsSyntax } from '../../../../../../Rules/utils/simpleRule';
import { isWorkflowReadOnlyAtom } from '../../../../../atoms/atoms';
import { useGenerateDataset } from '../../../../../hooks/useGenerateDataset';
import { ExcelEditorContainer } from './ExcelFormulaModal.styled';

type ExcelFormulaModalProps = Omit<UseControllerProps, 'name'> & {
  handleChangeValue: UseFormSetValue<any>;
  index: number;
  nodeName?: string;
  updatedDataset?: Record<string, Dataset>;
};

export function ExcelFormulaModal({
  handleChangeValue,
  index,
  control: formControl,
  nodeName = '',
  updatedDataset = {},
}: ExcelFormulaModalProps) {
  const [isReadOnly] = useAtom(isWorkflowReadOnlyAtom);
  const [siteConstants] = useAtom(siteConstantsAtom);
  const [hasEditorChanged, setHasEditorChanged] = useState(false);

  const { close: closeExcelNode } = useCurrentLayer();
  const { open: onCloseModal } = useLayer(
    <OnCloseNodeModal onClose={() => closeExcelNode()} />
  );

  const [isQueryValidNected, setIsQueryValidNected] = useState(true);
  const [suggestionObjs, setSuggestionObjs] = useState<NectedSuggestionModel[]>(
    []
  );
  const [isReturnTypeRequired, setIsReturnTypeRequired] = useState(false);
  const [returnType, setReturnType] = useState<string | null>(null);

  const nodeValue = useWatch({ name: nodeName, control: formControl });

  const { control, handleSubmit, setValue, watch } = useForm<JsNodeValues>({
    resolver: zodResolver(jsNodeSchema),
    defaultValues: {
      nodeName:
        _isEmpty(nodeValue?.name) || _isNil(nodeValue?.name)
          ? 'Untitled'
          : nodeValue.name,
      nodeQuery:
        _isEmpty(nodeValue?.query) || _isNil(nodeValue?.query)
          ? getTooltipText(
              siteConstants,
              'rules',
              'formulaInCondition',
              'otherText'
            )
          : nodeValue.query,
    },
    mode: 'onSubmit',
  });

  const jsCode = useWatch({
    name: 'nodeQuery',
    control,
  });

  const { tokens: dataSetSuggestionsObj } = useGenerateDataset({
    updatedDataset,
  });

  const { executedValue: execValues, handleGetExecutionValues } =
    useUpdateExecutedValueRules({
      updatedDataset,
    });
  useEffect(() => {
    const name = watch('nodeName');

    if (name === 'Any' && hasEditorChanged) {
      setValue('nodeName', 'Formula');
    }
  }, [hasEditorChanged]);

  const onSubmit = (data: JsNodeValues) => {
    if (!isQueryValidNected) {
      toasts.error(
        'You still have some errors in the editor. Please resolve to proceed',
        'error'
      );

      return;
    }

    setIsReturnTypeRequired(true);
  };

  useEffect(() => {
    setSuggestionObjs([...dataSetSuggestionsObj]);
  }, [dataSetSuggestionsObj]);

  const updatedQuery = watch('nodeQuery');

  useEffect(() => {
    if (
      !_isNil(returnType) &&
      !_isEmpty(returnType) &&
      returnType !== 'undefined'
    ) {
      handleChangeValue(`${nodeName}.name`, watch('nodeName'));
      handleChangeValue(`${nodeName}.query`, watch('nodeQuery'));
      closeExcelNode();
    }
  }, [returnType]);

  useEffect(() => {
    setReturnType('');
  }, [updatedQuery]);

  return (
    <Modal size="extraLargeXS" hideHeader padding="1rem">
      <ModalContent>
        <Stack as="form" gutter={8} onSubmit={handleSubmit(onSubmit)}>
          <Stack gutter={8}>
            <Inline align="start" gutter={16}>
              <Typography name="heading2">
                <ExpandingTextField
                  control={control}
                  name="nodeName"
                  disabled={isReadOnly}
                />
              </Typography>
            </Inline>
            <ExcelEditorContainer $readOnly={isReadOnly}>
              <NectedEditorField
                name="nodeQuery"
                control={control}
                mode="js"
                customSuggestions={suggestionObjs}
                readOnly={isReadOnly}
                onSetEditorValidity={setIsQueryValidNected}
                setReturnType={(type) => {
                  if (type === 'boolean') {
                    setReturnType(type);
                  } else {
                    setReturnType('undefined');
                  }
                  setIsReturnTypeRequired(false);
                }}
                sendReturnType={isReturnTypeRequired}
                domain={editorDomain}
                restApiPayload={[]}
                execValues={execValues}
                handleGetExecData={handleGetExecutionValues}
                setHasEditorChanged={setHasEditorChanged}
              />

              {!_isEmpty(jsCode) &&
                !isCorrectJsSyntax(jsCode, updatedDataset) && (
                  <SyntaxErrorContainer padding="1rem">
                    <Typography>
                      The statement written here is syntactically incorrect
                    </Typography>
                  </SyntaxErrorContainer>
                )}

              {returnType === 'undefined' && (
                <SyntaxErrorContainer padding="1rem">
                  <Typography>
                    The last statement must be a boolean expression
                  </Typography>
                </SyntaxErrorContainer>
              )}
            </ExcelEditorContainer>
          </Stack>

          <ModalFooter>
            <Button
              type="button"
              appearance="neutral"
              onClick={() => {
                if (hasEditorChanged) {
                  onCloseModal();
                } else {
                  closeExcelNode();
                }
              }}
            >
              Close
            </Button>
            <Button type="submit">Save & Close</Button>
          </ModalFooter>
        </Stack>
      </ModalContent>
    </Modal>
  );
}
