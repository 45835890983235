import { Inline, Stack } from '@bedrock-layout/primitives';
import styled, { css } from 'styled-components';
import { Typography } from 'ui';

import { DemoRuleProps, RuleTypePillProps, StatusPillProps } from './types';

export const ListingContainer = styled.div`
  border-radius: 0.8rem;
  border: 0.1rem solid rgba(0, 0, 0, 0.08);
  background: var(--color-white);
  margin-top: 2rem;
`;

export const ListingPagaination = styled.div`
  position: fixed;
  bottom: 0;
`;

export const FilterContainer = styled(Inline)`
  justify-content: space-between;
  padding: 1.6rem;
`;

export const FilterDropdownContainer = styled(Inline)`
  padding: 0.9rem;
  border-radius: 0.55rem;
  background: var(--color-lightGray2);
  color: var(--color-darkGray);
`;

export const LinkText = styled(Typography)`
  text-decoration: underline;
`;

export const DateContainer = styled(Typography)`
  word-wrap: break-word;
`;

export const TimeAgoContainer = styled(Stack)`
  word-wrap: break-word;
`;

export const CustomTimeAgoTitle = styled(Typography)`
  font-size: 1.4rem;
  color: var(--color-black);
`;

export const StringContainer = styled(Typography)<{ styles: string | null }>`
  word-wrap: break-word;
  color: var(--color-black);

  ${({ styles }) => styles}
`;

export const ActionMenuContainer = styled.div`
  text-align: end;
  padding-right: 3.2rem;
`;

export const ActionMenuItem = styled(Typography)`
  padding: 1rem 2.8rem 0.8rem 1.4rem;
`;

export const SelectedFilterCountContainer = styled.div`
  border-radius: 2rem;
  color: var(--color-black);
  position: absolute;
  top: -0.9rem;
  right: -0.6rem;
  width: 2rem;
  height: 2rem;
  background: var(--color-primary1);
`;

export const SelectedFilterCount = styled.p`
  color: var(--color-white);
  font-size: 1rem;
  line-height: 2rem;
  text-align: center;
`;

export const AppliedFilterContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const AppliedFilterBox = styled.div`
  font-size: 1.4rem;
  line-height: 1.8rem;
  text-align: center;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
  border: 0.2rem solid rgb(246, 246, 249);
  border-radius: 0.6rem;
  color: var(--color-darkGray);
  padding: 0.5rem;
  cursor: pointer;
`;

export const AppliedFilterLayout = styled.div`
  font-size: 1.4rem;
  line-height: 1.8rem;
  text-align: center;
  display: flex;
  -webkit-box-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
  flex-wrap: wrap;
`;

export const NoDataContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  block-size: calc(100vh - 45rem);
`;

const getStatusCSS = (status: string) => {
  switch (status.toLowerCase()) {
    case 'success':
      return css`
        background: var(--color-statusGreen);
        color: var(--color-green);
      `;
    case 'error':
      return css`
        background: var(--color-statusRed);
        color: var(--color-statusTextRed);
      `;
    case 'completed':
      return css`
        background: var(--color-statusGreen);
        color: var(--color-green);
      `;
    case 'failed':
      return css`
        background: var(--color-statusRed);
        color: var(--color-statusTextRed);
      `;
    case 'publish':
      return css`
        background: var(--color-statusGreen);
        color: var(--color-green);
      `;
    case 'published':
      return css`
        background: var(--color-statusGreen);
        color: var(--color-green);
      `;
    case 'draft':
      return css`
        background: var(--color-earlyDawn);
        color: var(--color-tangerine);
      `;
    case 'tested':
      return css`
        background: var(--color-aliceColor);
        color: var(--color-inReviewBg);
      `;
    case 'scheduled':
      return css`
        background: var(--color-artyClickOrange);
        color: var(--color-white);
      `;
    case 'has draft':
      return css`
        background: var(--color-lightBrown);
        color: var(--color-textBrown);
      `;
    case 'has tested':
      return css`
        background: var(--color-aliceColor);
        color: var(--color-inReviewBg);
      `;
    case 'in review':
      return css`
        background: var(--color-inReviewBg);
        color: var(--color-white);
      `;
    default:
      return css``;
  }
};

const getRuleTypeCSS = (type: string) => {
  switch (type) {
    case 'decisionTable':
      return css`
        background: var(--color-lightlilac);
        color: var(--color-lilac);
      `;
    case 'simpleRule':
      return css`
        background: var(--color-lightPink);
        color: var(--color-pink);
      `;
    case 'ruleSet':
      return css`
        background: var(--color-lightGreen);
        color: var(--color-darkGreen);
      `;
    default:
      return css``;
  }
};

const ruleBlockBackground = (type: string) => {
  switch (type) {
    case 'decisionTable':
      return css`
        background: var(--color-smokeBlue);
      `;
    case 'simpleRule':
      return css`
        background: var(--color-smokePink);
      `;
    case 'ruleSet':
      return css`
        background: var(--color-smokeGreen);
      `;
    default:
      return css``;
  }
};

export const ListingStatusPill = styled.span<StatusPillProps>`
  font-size: 1.2rem;
  border-radius: 0.5rem;
  padding: 0.5rem;
  font-weight: 700;
  ${({ status }) => getStatusCSS(status)}
`;

export const CustomClose = styled(Inline)`
  position: absolute;
  top: 1rem;
  right: 3rem;
  align-items: center;
  gap: 1rem;
`;

export const EntityName = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const NameContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
`;

export const RuleSearchInputContainer = styled.div`
  position: relative;
  width: 30rem;
`;

export const SearchContainerStyled = styled(Inline)`
  border: 1px solid var(--color-gainsboro);
  border-radius: 4px;
  margin-bottom: 1rem;
`;

export const DemoRuleBlock = styled(Inline)<DemoRuleProps>`
  width: calc(50% - 0.5rem);
  border-radius: 0.8rem;
  border: 1px solid var(--color-whiteSmoke);
  cursor: pointer;
  ${({ selected = false }) =>
    selected
      ? `border: 2px solid var(--color-darkGray);`
      : `border: 2px solid var(--color-whiteSmoke);`}
`;

export const VideoBlock = styled(Inline)`
  width: calc(50% - 1rem);
  border-radius: 0.8rem;
  cursor: pointer;
  background: var(--gray-white, #fff);
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.3),
    0px 8px 12px 0px rgba(0, 0, 0, 0.1);
  align-items: center;
`;

export const VideoBlockContainer = styled(Inline)`
  flex-wrap: wrap;
  max-height: 500px;
  overflow-y: scroll;
  padding: 1rem;
`;

export const CreateRuleBlock = styled(Inline)<RuleTypePillProps>`
  width: calc(33% - 0.5rem);
  border-radius: 0.8rem;
  cursor: pointer;
  min-height: 21rem;
  ${({ type }) => ruleBlockBackground(type)};
  ${({ selected = false }) =>
    selected
      ? `border: 2px solid var(--color-darkGray);`
      : `border: 2px solid var(--color-whiteSmoke);`}
`;

export const RuleTypePill = styled(Typography)<RuleTypePillProps>`
  padding: 0rem 1.4rem;
  border-radius: 0.4rem;
  width: fit-content;

  ${({ type }) => getRuleTypeCSS(type)}
`;

export const NoDemoRules = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  width: 100%;
`;

export const RuleTemplateFooterContainer = styled(Inline)`
  justify-content: space-between;
  gap: 2rem;
  width: 100%;
`;
