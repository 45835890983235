import { useLocalStorageObserve } from 'hooks';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { SITE_META } from '../utils/common';

export function useGetSiteMeta() {
  const [siteMeta, setSiteMeta] = useState(SITE_META);
  const theme = useLocalStorageObserve('local-theme');

  useEffect(() => {
    setSiteMeta((meta) => {
      const newMeta = structuredClone(meta);
      let title = import.meta.env.VITE_COMPANY_NAME ?? 'Nected';
      
      if (!_isNil(theme)) {
        try {
          const pTheme = JSON.parse(theme);
          if (!_isNil(pTheme?.title) && pTheme?.title !== '') {
            title = pTheme.title;
          }
        } catch {}
      }

      Object.keys(newMeta).forEach((key) => {
        newMeta[key].title = newMeta[key].title.replace('Nected', title);
      });
      
      return newMeta;
    });
  }, [theme]);

  return {
    siteMeta,
  };
}