import { PadBox } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { Typography, toasts, useCurrentLayer } from 'ui';

import { getUserState } from '../../../../hooks/getUserState';
import { useSendEventToGTM } from '../../../../hooks/useSendEventToGTM';
import { isOnboardingCompleted } from '../../../../utils/common';
import { demoRuleCreated, envMap } from '../../../../utils/constant';
import { currentWorkspaceDetailAtom } from '../../../Workspace/atom';
import {
  ActionBlockContainer,
  CardBlock,
  CreateBlock,
  CreateButtonContainer,
  IconContainer,
} from './PopupWidget.styled';
import { updateWidgetState } from './UpdateWidgetState';
import { useGenrateEntityId } from './useGenerateEntityId';

type PopoupWidgetsProps = {
  queryData?: any;
  state: string;
  user: {
    email: string;
  };
  icon: string;
  detectActionType: (action: any, state: string) => void;
  createData: Record<string, any>;
};

export function CardBlocks({
  queryData,
  user,
  state,
  icon,
  detectActionType,
  createData,
}: PopoupWidgetsProps) {
  const { close: closePopup } = useCurrentLayer();
  const [currentWorkspace] = useAtom(currentWorkspaceDetailAtom);
  const role = currentWorkspace?.role ?? '';
  const { generateEntityId } = useGenrateEntityId();
  const { sendEventToGTM } = useSendEventToGTM();

  function linkRedirect(link: string, target: string) {
    window.open(link, target);
  }

  const sendEventOnActionClick = (title: string, type: string) => {
    switch (state) {
      case 'demo_rule_created':
        sendEventToGTM({
          event: 'widget',
          type: 'demo',
          type_selected: title,
          popup: '',
        });

        sendEventToGTM({
          event: 'demo_rule_clicked',
          title,
        });

        sendEventToGTM({
          event: 'rule',
          source: 'demo',
          type,
          element: '',
          action: 'add_click',
        });
        break;
      case 'new_rule_created':
        sendEventToGTM({
          event: 'widget',
          type: 'scratch',
          type_selected: title,
          popup: '',
        });

        sendEventToGTM({
          event: 'rule',
          source: 'scratch',
          type: title,
          element: '',
          action: 'add_click',
        });
        break;
    }
  };

  const handleActionBlockClick = (actionContainer: any) => {
    const { url, target, permission, message, entityId, id } =
      actionContainer.action;
    const { title, type } = actionContainer;
    sendEventOnActionClick(title, type);

    if (
      _isNil(permission) ||
      (!_isNil(permission) && (permission as string[]).includes(role))
    ) {
      if (!_isNil(entityId) && !_isEmpty(entityId)) {
        linkRedirect(url, target);
      } else if (_isNil(entityId) && _isNil(id)) {
        linkRedirect(url, target);
      } else {
        void updateUserState();
        void createEntityAndOpen(id, url, target);
      }
    } else {
      toasts.error(message ?? '', 'error');
      closePopup();
    }
  };

  const updateUserState = () => {
    if (!isOnboardingCompleted(demoRuleCreated)) {
      updateWidgetState(demoRuleCreated)
        .then(() => {
          void getUserState();
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    }
  };

  const createEntityAndOpen = (id: string, url: string, target: string) => {
    generateEntityId({
      usecaseId: id,
      url,
      target,
    });
  };

  const getPopUpData = (queryData: Record<string, any>, icon: string) => {
    const actionData: PopoupWidgetsProps[] = [];
    const popupObject = {
      action: {
        entityId: '',
        id: '',
        target: '_self',
        type: 'LINK',
        url: `/rules/ID?type=edit&stage=staging&ruleType=RULETYPE&closeSheet=true`,
      },
      content: '',
      type: '',
      icon,
      title: '',
    };

    if (!_isNil(queryData)) {
      queryData.data.forEach((item: any) => {
        const obj = JSON.parse(JSON.stringify(popupObject));

        if (!_isEmpty(item.entityId)) {
          obj.action.url = obj.action.url.replace('ID', item.entityId);
        }
        obj.action.url = obj.action.url.replace('RULETYPE', item.type);
        obj.title = item.name;
        obj.type = item.type;
        obj.content = item.description;
        obj.action.entityId = item.entityId;
        obj.action.id = item.id;
        actionData.push(obj);
      });
    }

    return actionData;
  };

  return (
    <Stack gutter={'3rem'}>
      <Stack gutter={'0.5rem'}>
        <ActionBlockContainer gutter="1rem">
          {getPopUpData(queryData, icon).map(
            (actionContainer: any, index: number) => (
              <PadBox
                key={index}
                onClick={() => handleActionBlockClick(actionContainer)}
                as={CardBlock}
                padding="1.5rem"
                gutter="1.5rem"
              >
                <IconContainer>
                  <img
                    src={`${envMap.VITE_ASSETS_URL}website/icons/${
                      actionContainer.action.id as string
                    }.png`}
                    alt="icon"
                    style={{ maxWidth: '10rem' }}
                  />
                </IconContainer>

                <Stack gutter={'1rem'}>
                  <Typography fontWeight={700} name={'heading4'}>
                    {actionContainer.title}
                  </Typography>
                  <Typography fontWeight={400}>
                    {actionContainer.content}
                  </Typography>
                </Stack>
              </PadBox>
            )
          )}
          {/* <ORBlock>
            <Typography>OR</Typography>
          </ORBlock> */}
          <PadBox
            onClick={() =>
              detectActionType(createData.action, createData.state)
            }
            as={CreateBlock}
            padding="1.5rem"
            gutter="1.5rem"
          >
            <div style={{ minWidth: '10rem' }}>
              <img
                src={`${envMap.VITE_ASSETS_URL}home/create.svg`}
                alt="icon"
                style={{ maxWidth: '10rem' }}
              />
            </div>

            <CreateButtonContainer gutter={'1rem'}>
              <Typography name={'heading4'} fontWeight={700}>
                Create your own Use Case
              </Typography>
            </CreateButtonContainer>
          </PadBox>
        </ActionBlockContainer>
      </Stack>
    </Stack>
  );
}
