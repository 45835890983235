import { Spinner, Typography } from 'ui';
import { useVerifyEmail } from '../hooks/useVerifyEmail';
import { Center, Cover } from '@bedrock-layout/primitives';

export function VerifyEmail() {
  const { isLoading } = useVerifyEmail();

  return (
    <Cover>
      <Center centerChildren>
        {isLoading && <Spinner />}

        <Typography name="heading1">Verifying your email</Typography>
      </Center>
    </Cover>
  );
}
