import styled from 'styled-components';

export const RunInLoopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PopoverContainerStyled = styled.div`
  overflow-y: auto;
  max-block-size: 30rem;
  inline-size: 40.8rem;
`;
