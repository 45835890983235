import { PadBox } from '@bedrock-layout/padbox';
import styled from 'styled-components';

import { FullHeightStack } from '../../pages/DataSets/components/DataSetForm.styled';

export const ActionPill = styled(PadBox)<{ $hasError: boolean }>`
  min-inline-size: 6rem;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  background: var(--color-solitudeSecondary);
  outline: 1px solid
    ${({ $hasError }) =>
      $hasError
        ? 'var(--color-fireEngineRed)'
        : 'var(--color-solitudeSecondary)'};
`;

export const EditorContainer = styled(FullHeightStack)`
  border: 1px solid var(--color-lightGray);
  border-radius: 6px;
  min-block-size: 25rem;
  max-block-size: 31rem;
`;

export const ErrorIcon = styled.div`
  position: absolute;
  top: -0.5rem;
  right: -1rem;
`;
