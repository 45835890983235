import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { atom, useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import { useEffect } from 'react';
import { useController, useWatch } from 'react-hook-form';
import type { UseControllerProps, UseFormSetValue } from 'react-hook-form';
import { MdEdit } from 'react-icons/md';
import {
  Dataset,
  NectedSuggestionModel,
  SheetSize,
  TextInput,
  Typography,
} from 'ui';

import { AlignedText } from '../../../../../components/ExcelLikeFormula/ExcelPill/ExcelPill.styled';
import {
  convertArrayAsInput,
  convertArrayToString,
} from '../../../../../utils/common';
import { useOpenJsonEditorSheet } from '../../../hooks/useOpenJsonEditor';
import { sectionAtom } from '../../CreateRuleSheet/CreateRuleSheet';
import { PillStyledList } from '../../RuleSet/Ruleset.styled';
import { suggestionsObjAtom } from './JsNodePill';
import { JsonPillStyled } from './JsonNodePill.styled';
import type { ResultType } from './Results';

export type JsonNodePillProps = UseControllerProps & {
  name: string;
  index?: number;
  suggestions?: string[];
  section?: ResultType;
  hideOptionalCustomAttributes?: boolean;
  suggestionObjs?: NectedSuggestionModel[];
  setOriginalValue?: UseFormSetValue<any>;
  returnTypeName?: string;
  executedValueName?: string;
  hideSuggestions?: boolean;
  size?: SheetSize;
  isJsonInCustomInput?: boolean;
  disabled?: boolean;
  type: string;
  from?: string;
  dataSet?: Record<string, Dataset>;
};

export const suggestionsAtom = atom<string[]>([]);

export function JsonNodePill({
  type = 'json',
  name,
  control,
  index = 0,
  suggestions = [],
  section = 'thenDataParams',
  hideOptionalCustomAttributes = false,
  suggestionObjs = [],
  executedValueName,
  returnTypeName,
  setOriginalValue,
  hideSuggestions = false,
  disabled = false,
  size,
  isJsonInCustomInput,
  from,
  dataSet,
}: JsonNodePillProps) {
  const [, setSectionAtom] = useAtom(sectionAtom);

  const { openWithProps: openJsonEditor } = useOpenJsonEditorSheet({
    name,
    control,
    index,
    section,
    type,
  });

  const [, setSuggestions] = useAtom(suggestionsAtom);
  const [, setSuggestionsObj] = useAtom(suggestionsObjAtom);

  const { fieldState } = useController({
    name,
    control,
  });

  const errorMessage = fieldState.error?.message;

  useEffect(() => {
    setSuggestions(suggestions);
  }, [JSON.stringify(suggestions)]);

  useEffect(() => {
    setSuggestionsObj(suggestionObjs);
  }, [JSON.stringify(suggestionObjs)]);

  const fieldValue = useWatch({
    control,
    name,
  });

  const getFieldValue = () => {
    try {
      if (typeof fieldValue === 'string') {
        return convertArrayAsInput(fieldValue);
      }

      return fieldValue;
    } catch (error) {
      return [];
    }
  };

  const getPadding = () => {
    if (name.includes('ruleResult')) {
      return { size: true, pill: ['0rem', '0.6rem'] };
    }

    return { size: false, pill: ['0.4rem', '1rem'] };
  };

  return (
    <Stack>
      {type === 'json' && (
        <Inline
          onClick={() => {
            openJsonEditor({
              hideOptionalCustomAttributes,
              suggestionObjs,
              executedValueName,
              returnTypeName,
              setOriginalValue,
              hideSuggestions,
              size,
              isJsonInCustomInput,
              type,
              disabled,
              from,
              dataSet,
            });
            setSectionAtom(section);
          }}
          as={JsonPillStyled}
          align="center"
          justify="center"
          padding={[2, 12]}
          gutter={8}
          $hasError={!_isEmpty(errorMessage)}
        >
          <Typography>JSON Editor</Typography>
          <MdEdit />
        </Inline>
      )}
      {type === 'list' && (
        <TextInput
          readOnly
          placeholder="Enter values"
          name={name}
          isSmallInput={getPadding().size}
          value={convertArrayToString(getFieldValue())}
          icon={
            <Stack gutter={2}>
              <PillStyledList
                padding={getPadding().pill}
                onClick={() => {
                  openJsonEditor({
                    hideOptionalCustomAttributes,
                    suggestionObjs,
                    executedValueName,
                    returnTypeName,
                    setOriginalValue,
                    hideSuggestions,
                    size,
                    isJsonInCustomInput,
                    type,
                    disabled,
                    from,
                    dataSet,
                  });
                  setSectionAtom(section);
                }}
                $hasError={!_isEmpty(errorMessage)}
              >
                <AlignedText>List</AlignedText>
                <MdEdit />
              </PillStyledList>
            </Stack>
          }
        />
      )}

      {!_isEmpty(errorMessage) && (
        <Typography name="error">{errorMessage}</Typography>
      )}
    </Stack>
  );
}
