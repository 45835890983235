import { Inline, PadBox, Stack } from '@bedrock-layout/primitives';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import {
  UseControllerProps,
  UseFormSetValue,
  useForm,
  useWatch,
} from 'react-hook-form';
import {
  Button,
  Dataset,
  ExpandingTextField,
  NectedEditorField,
  NectedSuggestionModel,
  Sheet,
  Typography,
  toasts,
  useCurrentLayer,
} from 'ui';

import { siteConstantsAtom } from '../../../../../../../atom';
import { getTooltipText } from '../../../../../../../utils/common';
import { editorDomain } from '../../../../../../../utils/constant';
import { SyntaxErrorContainer } from '../../../../../../DataSets/components/DataSetForm.styled';
import { JsNodeValues } from '../../../../../../Rules/components/DecisionTable/models';
import { useUpdateExecutedValueRules } from '../../../../../../Rules/hooks/useUpdateExecutedValueRules';
import { jsNodeSchema } from '../../../../../../Rules/schema';
import { isCorrectJsSyntax } from '../../../../../../Rules/utils/simpleRule';
import { isWorkflowReadOnlyAtom } from '../../../../../atoms/atoms';
import { useGenerateDataset } from '../../../../../hooks/useGenerateDataset';
import {
  FooterStyled,
  JsEditorContainer,
  NodeContainerStyled,
} from './JsNodeSheet.styled';

type JsNodeSheetProps = Omit<UseControllerProps, 'name'> & {
  handleChangeValue: UseFormSetValue<any>;
  index: number;
  nodeName?: string;
  updatedDataset?: Record<string, Dataset>;
};

export function JsNodeSheet({
  handleChangeValue,
  index,
  control: formControl,
  nodeName = '',
  updatedDataset = {},
}: JsNodeSheetProps) {
  const [isReadOnly] = useAtom(isWorkflowReadOnlyAtom);
  const [siteConstants] = useAtom(siteConstantsAtom);
  const [hasEditorChanged, setHasEditorChanged] = useState(false);

  const { close: closeSqlNode } = useCurrentLayer();

  const [isQueryValidNected, setIsQueryValidNected] = useState(true);
  const [suggestionObjs, setSuggestionObjs] = useState<NectedSuggestionModel[]>(
    []
  );
  const [isReturnTypeRequired, setIsReturnTypeRequired] = useState(false);
  const [returnType, setReturnType] = useState<string | null>(null);

  const nodeValue = useWatch({ name: nodeName, control: formControl });

  const { control, handleSubmit, setValue, watch } = useForm<JsNodeValues>({
    resolver: zodResolver(jsNodeSchema),
    defaultValues: {
      nodeName:
        _isEmpty(nodeValue?.name) || _isNil(nodeValue?.name)
          ? 'Untitled'
          : nodeValue?.name,
      nodeQuery:
        _isEmpty(nodeValue?.query) || _isNil(nodeValue?.query)
          ? getTooltipText(
              siteConstants,
              'rules',
              'formulaInCondition',
              'otherText'
            )
          : nodeValue.query,
    },
    mode: 'onSubmit',
  });

  const jsCode = useWatch({
    name: 'nodeQuery',
    control,
  });

  const { tokens: dataSetSuggestionsObj } = useGenerateDataset({
    updatedDataset,
  });

  const { executedValue: execValues, handleGetExecutionValues } =
    useUpdateExecutedValueRules({
      updatedDataset,
    });
  useEffect(() => {
    const name = watch('nodeName');

    if (name === 'Any' && hasEditorChanged) {
      setValue('nodeName', 'Formula');
    }
  }, [hasEditorChanged]);

  const onSubmit = (data: JsNodeValues) => {
    if (!isQueryValidNected) {
      toasts.error(
        'You still have some errors in the editor. Please resolve to proceed',
        'error'
      );

      return;
    }

    setIsReturnTypeRequired(true);
  };

  useEffect(() => {
    setSuggestionObjs([...dataSetSuggestionsObj]);
  }, [dataSetSuggestionsObj]);

  const updatedQuery = watch('nodeQuery');

  useEffect(() => {
    if (
      !_isNil(returnType) &&
      !_isEmpty(returnType) &&
      returnType !== 'undefined'
    ) {
      handleChangeValue(`${nodeName}.name`, watch('nodeName'));
      handleChangeValue(`${nodeName}.query`, watch('nodeQuery'));
      closeSqlNode();
    }
  }, [returnType]);

  useEffect(() => {
    setReturnType('');
  }, [updatedQuery]);

  return (
    <Sheet size="medium">
      <form onSubmit={handleSubmit(onSubmit)}>
        <NodeContainerStyled gutter="1rem">
          <PadBox padding="1rem">
            <Stack gutter={8}>
              <Inline align="start" gutter={16}>
                <Typography name="heading2">
                  <ExpandingTextField
                    control={control}
                    name="nodeName"
                    disabled={isReadOnly}
                  />
                </Typography>
              </Inline>
              <JsEditorContainer>
                <NectedEditorField
                  name="nodeQuery"
                  control={control}
                  mode="js"
                  customSuggestions={suggestionObjs}
                  readOnly={isReadOnly}
                  onSetEditorValidity={setIsQueryValidNected}
                  setReturnType={(type) => {
                    if (type === 'boolean') {
                      setReturnType(type);
                    } else {
                      setReturnType('undefined');
                    }
                    setIsReturnTypeRequired(false);
                  }}
                  sendReturnType={isReturnTypeRequired}
                  domain={editorDomain}
                  restApiPayload={[]}
                  execValues={execValues}
                  handleGetExecData={handleGetExecutionValues}
                  setHasEditorChanged={setHasEditorChanged}
                />

                {!_isEmpty(jsCode) &&
                  !isCorrectJsSyntax(jsCode, updatedDataset) && (
                    <SyntaxErrorContainer padding="1rem">
                      <Typography>
                        The statement written here is syntactically incorrect
                      </Typography>
                    </SyntaxErrorContainer>
                  )}

                {returnType === 'undefined' && (
                  <SyntaxErrorContainer padding="1rem">
                    <Typography>
                      The last statement must be a boolean expression
                    </Typography>
                  </SyntaxErrorContainer>
                )}
              </JsEditorContainer>
            </Stack>
          </PadBox>

          <FooterStyled padding={[4, 8]}>
            <Inline justify="end">
              <Button type="submit" appearance="filled" disabled={isReadOnly}>
                Save
              </Button>
            </Inline>
          </FooterStyled>
        </NodeContainerStyled>
      </form>
    </Sheet>
  );
}
