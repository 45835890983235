import { useMutation } from '@tanstack/react-query';

import { useAxiosPrivate } from '../../../hooks';

type UpdateQuestionsRequest = {
  pages: [
    {
      questions: Array<Record<string, string>>;
    }
  ];
};

export function useUpdateQuestionnaire() {
  const { axiosPrivate } = useAxiosPrivate();

  const { mutateAsync: updateQuestion, isLoading } = useMutation({
    mutationFn: async (request: UpdateQuestionsRequest) => {
      return await axiosPrivate.post('/user/signup/questionnaire', request);
    },
  });

  return {
    updateQuestion,
    isLoading,
  };
}
