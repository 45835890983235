import { useAtom } from 'jotai';
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _reduce from 'lodash/reduce';
import { useEffect, useMemo, useState } from 'react';
import { Expander, Typography } from 'ui';

import { StatusCode } from '../../../../../../utils/response/statusCode';
import { ExecutionTest } from '../../../../../Rules/components/RuleSet/ExecutionTest/ExecutionTest';
import { useServerResponse } from '../../../../../Rules/hooks/useServerResponse';
import {
  RuleSetActionInfoObject,
  RuleSetTestActionFetchResponseType,
  RuleSetTestActionResponse,
  RuleSetTestOutputType,
  RuleTestActionObject,
} from '../../../../../Rules/types';
import {
  getActionInfoForRuleTestActions,
  getAllInProgressActions,
  getAllRuleTestActions,
  getFormattedJsonForRuleTestActions,
} from '../../../../../Rules/utils/common';
import { ruleListWorkflowAtom } from '../../../../atoms/atoms';
import {
  RuleTestActionPollingPayload,
  WorkerReceiveMessageProp,
} from './workerTypes';

type WorkflowResultRsProps = {
  result: Record<string, any>;
  state: boolean;
  time?: number | null;
};

export function WorkflowResultRs({
  result,
  state,
  time,
}: WorkflowResultRsProps) {
  const accessToken = window.localStorage.getItem('accessToken') ?? '';
  const workspaceUUID = window.localStorage.getItem('workspaceUUID') ?? '';

  const [ruleListWorkflow] = useAtom(ruleListWorkflowAtom);

  const [ruleOutputMapping, setRuleOutputMapping] =
    useState<RuleSetTestOutputType>({});

  const [pollingPayload, setPollingPayload] = useState<
    RuleTestActionPollingPayload[]
  >([]);
  const [ruleActionResponse, setRuleActionResponse] =
    useState<RuleSetTestActionResponse>({});
  const [isInitiateWorker, setIsInitiateWorker] = useState(false);

  const [actionResponse, setActionResponse] =
    useState<RuleSetTestActionFetchResponseType>({
      actionError: {},
      actionData: {},
      actionLoading: false,
    });

  const { actionError, actionData } = actionResponse;

  const actionPollingWorker: Worker = new Worker(
    new URL('./ActionPollingWorker.tsx', import.meta.url),
    {
      type: 'module',
    }
  );

  useEffect(() => {
    if (pollingPayload.length > 0) {
      setIsInitiateWorker(true);
    } else {
      setIsInitiateWorker(false);
    }
  }, [pollingPayload]);

  useEffect(() => {
    if (actionPollingWorker instanceof Worker) {
      actionPollingWorker.postMessage({
        pollingPayload,
        accessToken,
        workspaceUUID,
        type: isInitiateWorker ? 'start' : 'terminate',
      });
    }
  }, [isInitiateWorker]);

  useEffect(() => {
    if (actionPollingWorker instanceof Worker) {
      actionPollingWorker.onmessage = (
        e: MessageEvent<WorkerReceiveMessageProp>
      ) => {
        const { data } = e;
        setActionResponse(data);
      };
    }
  }, [actionPollingWorker]);

  // eslint-disable-next-line
  const [actionInfo, setActionInfo] = useState<RuleSetActionInfoObject>({});

  const { serverResponse, setServerResponse } = useServerResponse();

  useEffect(() => {
    const json = !_isNil(result?.output) ? result?.output : null;

    if (!_isNil(json) || !_isNil(result?.error)) {
      setServerResponse({
        json: JSON.stringify(json ?? result, null, 2),
        executionTime: '0',
      });

      const output = result?.output ?? [];

      const currRuleActionResponse: RuleSetTestActionResponse = _reduce(
        output,
        (accum, currData: any) => {
          const { ruleId } = currData;

          return {
            ...accum,
            [ruleId]: {
              ruleName:
                ruleListWorkflow.find((r) => r.id === ruleId)?.name ??
                'Untitled',
              ruleId,
              actionResponse: !_isNil(currData.action)
                ? getAllRuleTestActions(currData.action)
                : [],
            },
          };
        },
        {}
      );

      const currOutputRule = _reduce(
        output,
        (accum, currRule) => {
          return {
            ...accum,
            [currRule.ruleId]: currRule.output,
          };
        },
        {}
      );

      const allActionList = _reduce(
        currRuleActionResponse,
        (accum: RuleTestActionObject[], currRule) => {
          return [...accum, ...currRule.actionResponse];
        },
        []
      );

      setRuleActionResponse(currRuleActionResponse);
      setPollingPayload(getAllInProgressActions(allActionList));
      setRuleOutputMapping(currOutputRule);
    }
  }, [JSON.stringify(result)]);

  useEffect(() => {
    const actionInfoData =
      !_isNil(actionData) && !_isEmpty(actionData) ? actionData.data : {};

    if (!_isNil(actionInfoData.data)) {
      const data = actionInfoData.data;

      const currRuleActionResponse = _reduce(
        ruleActionResponse,
        (accum, currRule) => {
          const ruleId = currRule.ruleId;

          currRule.actionResponse.forEach((action: RuleTestActionObject) => {
            const { executionId } = action.actionData;

            if (_has(data, executionId)) {
              const { executionTime, output, status } = data[executionId];
              const hasError =
                !_isNil(output?.error) && !_isEmpty(output?.error);

              action.actionData = {
                isCollapsed: true,
                executionTime,
                status,
                executionId,
                hasError,
                json: getFormattedJsonForRuleTestActions(
                  hasError ? output?.error : output?.Result
                ),
                ruleOutput: getFormattedJsonForRuleTestActions(
                  ruleOutputMapping[ruleId]
                ),
              };
            }
          });

          return {
            ...accum,
            [ruleId]: {
              ...currRule,
              actionResponse: currRule.actionResponse,
            },
          };
        },
        {}
      );

      let currPollingPayload = structuredClone(pollingPayload);
      currPollingPayload = currPollingPayload.filter(
        (actionPayload: RuleTestActionPollingPayload) =>
          _has(data, actionPayload.executionId) &&
          data[actionPayload.executionId].status === StatusCode.RUNNING
      );

      setRuleActionResponse(currRuleActionResponse);
      setPollingPayload(currPollingPayload);
    }
  }, [JSON.stringify(actionData)]);

  useEffect(() => {
    if (!_isEmpty(actionError)) {
      const { status, data } = actionError;
      const message = !_isEmpty(data) ? data.message : '';

      if (
        !_isNil(status) &&
        (status === 400 || status === 401 || status >= 500)
      ) {
        const updatedRuleActionResponse = _reduce(
          ruleActionResponse,
          (accum, currRule) => {
            const ruleId = currRule.ruleId;

            const updatedActionResponse = currRule.actionResponse.map(
              (action: RuleTestActionObject) => {
                if (action.actionData.status === StatusCode.RUNNING) {
                  action.actionData.status = StatusCode.FAILED;
                  action.actionData.json = message;
                  action.actionData.hasError = true;
                  action.actionData.ruleOutput =
                    getFormattedJsonForRuleTestActions(
                      ruleOutputMapping[ruleId]
                    );
                }

                return action;
              }
            );

            return {
              ...accum,
              [ruleId]: {
                ...currRule,
                actionResponse: updatedActionResponse,
              },
            };
          },
          {}
        );
        setRuleActionResponse(updatedRuleActionResponse);
        setPollingPayload([]);
      }
    }
  }, [actionError]);

  const handleExpandIconClick = (actionName: string, ruleId: string) => {
    const updatedRuleActionResponse: RuleSetTestActionResponse = _reduce(
      ruleActionResponse,
      (accum, currRule) => {
        const actionResponse = currRule.actionResponse.map((action) => {
          if (currRule.ruleId === ruleId && action.actionName === actionName) {
            action.actionData.isCollapsed = !action.actionData.isCollapsed;
          } else {
            action.actionData.isCollapsed = true;
          }

          return action;
        });

        return {
          ...accum,
          [currRule.ruleId]: {
            ...currRule,
            actionResponse,
          },
        };
      },
      {}
    );

    setRuleActionResponse(updatedRuleActionResponse);
  };

  useEffect(() => {
    const currActionInfo: RuleSetActionInfoObject = _reduce(
      ruleActionResponse,
      (accum, currRule) => {
        return {
          ...accum,
          [currRule.ruleId]: getActionInfoForRuleTestActions(
            currRule.actionResponse
          ),
        };
      },
      {}
    );

    setActionInfo(currActionInfo);
  }, [JSON.stringify(ruleActionResponse)]);

  useEffect(() => {
    return () => {
      actionPollingWorker.terminate();
    };
  }, []);

  // TITLE: Action
  const title = useMemo(() => {
    if (state) {
      return (
        <Typography fontWeight={700} name="success">{`Success ${
          !_isNil(time) ? `. ${time}ms` : ''
        }`}</Typography>
      );
    }

    return (
      <Typography name="error" fontWeight={700}>{`Error ${
        !_isNil(time) ? `. ${time}ms` : ''
      }`}</Typography>
    );
  }, [time, state]);

  return (
    <Expander title={title}>
      <ExecutionTest
        serverResponse={serverResponse}
        isTesting={false}
        ruleActionResponse={ruleActionResponse}
        actionInfo={actionInfo}
        handleExpandIconClick={handleExpandIconClick}
        {...(!_isNil(result?.error) ? { forceStatus: true } : {})}
      />
    </Expander>
  );
}
