import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import _isUndefined from 'lodash/isUndefined';
import { useEffect, useMemo, useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { Image, Typography } from 'ui';

import { roleJsonAtom } from '../../../../../../../components/authentication/router/AuthProvider';
import { isValidImageURL } from '../../../../../../../utils/common';
import { useGetPublishedConnectors } from '../../../../../../DataSets/hooks/useGetPublishedConnectors';
import type { ConnectorAndPluginModel } from '../../../../../../Integrations/types';
import {
  ConnectorCreateButton,
  ConnectorItem,
  ConnectorListContainer,
} from './ConnectorList.styled';

type ConnectorListProps = {
  onConnectorSelect: (item: ConnectorAndPluginModel) => void;
  onItemClick: (type: string, entityId?: string) => void;
  pluginName?: string;
  metaData?: Record<string, any>;
  doRefetch?: number;
};

export function ConnectorList({
  onConnectorSelect,
  onItemClick,
  pluginName = '',
  metaData = {},
  doRefetch = 0,
}: ConnectorListProps) {
  const { data, refetch } = useGetPublishedConnectors(false);
  const [connectorList, setConnectorList] = useState<ConnectorAndPluginModel[]>(
    []
  );

  const [roleJson] = useAtom(roleJsonAtom);

  useEffect(() => {
    if (!_isNil(data)) {
      const publishedConnectors = data.getConnector.data.filter(
        (connector) => connector.plugin.name === pluginName
      );

      setConnectorList(publishedConnectors);
    }
  }, [data, pluginName]);

  const handleCreateConnector = () => {
    if (!_isUndefined(metaData.pluginId)) {
      window.open(
        `/integrations/${metaData?.pluginId as string}`,
        window !== window.parent ? '_self' : '_blank'
      );
    }
  };

  const addNewConnectorName = useMemo(() => {
    if (['database'].includes(metaData.category)) {
      return `${pluginName} DB`;
    }

    return pluginName;
  }, [metaData]);

  useEffect(() => {
    if (doRefetch > 0) {
      void refetch();
    }
  }, [doRefetch]);

  const integrationsPermissions = roleJson.internals?.integrations?.permissions;

  const isCreateIntegration =
    !_isNil(integrationsPermissions) &&
    (integrationsPermissions.create as boolean);

  return (
    <PadBox padding="1.6rem">
      <ConnectorListContainer gutter="0">
        {connectorList.map((connector) => (
          <ConnectorItem
            key={connector.id}
            padding={[4, 6, 4, 6]}
            onClick={() => onConnectorSelect(connector)}
          >
            <Inline justify="start" align="center">
              <Image
                src={isValidImageURL(connector.plugin.imageUrl)}
                alt="minus"
                size="small"
                round
              />
              <Typography name="paragraphXs">{connector.name}</Typography>
            </Inline>
            <Typography name="secondaryXs">{connector.category}</Typography>
          </ConnectorItem>
        ))}
        {connectorList.length === 0 && (
          <Typography name="paragraphXs">No Connector Found</Typography>
        )}

        <ConnectorCreateButton
          disabled={!isCreateIntegration}
          type="button"
          onClick={handleCreateConnector}
        >
          <AiOutlinePlus />
          <Typography fontWeight={700} name="paragraphXs">
            {addNewConnectorName}
          </Typography>
        </ConnectorCreateButton>
      </ConnectorListContainer>
    </PadBox>
  );
}
