import { Stack } from '@bedrock-layout/stack';
import { motion } from 'framer-motion';
import { memo, useEffect, useState } from 'react';
import { FiRefreshCcw } from 'react-icons/fi';
import { IconButton, TreeViewer, Typography } from 'ui';

import type { UseGetDataSetSchemaReturn } from '../hooks/useGetDataSetSchema';
import {
  RefreshSchemaContainer,
  TreeViewerContainer,
} from './DataSetForm.styled';

type RotateIconButtonProps = {
  isLoading: boolean;
  onClick: (isRefresh: boolean) => void;
};

const RotateIconButton = ({ isLoading, onClick }: RotateIconButtonProps) => {
  return (
    <motion.div
      animate={{
        rotate: isLoading ? 360 : 0,
      }}
      transition={{
        duration: isLoading ? 2 : 0,
        repeat: isLoading ? Infinity : 0,
        ease: 'linear',
      }}
    >
      <IconButton onClick={onClick}>
        <FiRefreshCcw />
      </IconButton>
    </motion.div>
  );
};

export function DataSetSchema({
  schemas,
  getDataSetSchema,
}: UseGetDataSetSchemaReturn) {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (Object.keys(schemas).length > 0) {
      setIsLoading(false);
    }
  }, [JSON.stringify(schemas)]);

  return (
    <Stack
      gutter="1.5rem"
      style={{
        overflow: 'hidden',
      }}
    >
      <RefreshSchemaContainer>
        <Typography fontWeight={700}>Schema</Typography>
        <RotateIconButton
          isLoading={isLoading}
          onClick={() => {
            setIsLoading(true);
            getDataSetSchema(true);
          }}
        />
      </RefreshSchemaContainer>

      <TreeViewerContainer>
        <TreeViewer dataset={schemas} isExpanded={false} />
      </TreeViewerContainer>
    </Stack>
  );
}

export const DataSetSchemaMemoized = memo(DataSetSchema);
