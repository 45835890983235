import { Inline } from '@bedrock-layout/primitives';
import styled from 'styled-components';

export const RuleEditorContainer = styled(Inline)`
  border-block-start: 1px solid var(--color-darkGray);
  block-size: calc(100vh - 23.2rem);
`;

export const RulesContainerStyled = styled.div`
  flex: 1;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  justify-content: space-between;
`;

export const TestButtonContainer = styled(Inline)`
  position: absolute;
  right: 1rem;
  bottom: 1rem;
`;
