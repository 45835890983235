type TrashIconProps = {
  width?: number;
  height?: number;
  color?: string;
};

export function TrashIcon(props: TrashIconProps) {
  const { width = 16, height = 16, color = 'var(--color-lightGray)' } = props;

  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none">
      <path
        d="M3.33398 5.03641H4.38748H12.8155"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6306 5.39255V12.2666C11.6306 12.5495 11.5236 12.8208 11.3331 13.0209C11.1426 13.2209 10.8842 13.3333 10.6148 13.3333H5.5354C5.26598 13.3333 5.00759 13.2209 4.81707 13.0209C4.62656 12.8208 4.51953 12.5495 4.51953 12.2666V5.39255M6.04334 4.79996V3.73329C6.04334 3.4504 6.15037 3.17908 6.34088 2.97905C6.5314 2.77901 6.78979 2.66663 7.05921 2.66663H9.09096C9.36039 2.66663 9.61878 2.77901 9.80929 2.97905C9.9998 3.17908 10.1068 3.4504 10.1068 3.73329V4.79996"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
