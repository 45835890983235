import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import React, { useMemo } from 'react';
import { Control, useWatch } from 'react-hook-form';
import { IoMdArrowDropdown } from 'react-icons/io';
import { TooltipReact, Typography } from 'ui';

import { siteConstantsAtom } from '../../atom';
import { getTooltipText } from '../../utils/common';
import {
  LauncherContainer,
  OpenEntityLauncher,
} from './ActionMenuLauncher.styled';
import type { DependencyConfig } from './EntityDependencyView';
import { entityActionOptions } from './constant';

type ActionMenuLauncherProps = {
  id: string;
  isSummaryView: boolean;
  config: DependencyConfig;
  parentId?: string;
  control?: Control<any>;
  entityType?: string;
  nodeId?: string;
};

export const ActionMenuLauncher = React.memo(
  ({
    id,
    parentId,
    control,
    config,
    isSummaryView,
    entityType = '',
    nodeId = '',
  }: ActionMenuLauncherProps) => {
    const [siteConstant] = useAtom(siteConstantsAtom);

    const { entityId, entity, category } = config;

    const actionValue = useWatch({
      name: `dependencyJson.ref.${id}.action`,
      control,
    });

    const parentActionValue = !_isNil(parentId)
      ? useWatch({
          name: `dependencyJson.ref.${parentId}.action`,
          control,
        })
      : '';

    const isNameConflict: boolean = useWatch({
      name: `dependencyJson.ref.${id}.isNameConflict`,
      control,
    });

    const isCategoryConflict: boolean = useWatch({
      name: `dependencyJson.ref.${id}.isCategoryConflict`,
      control,
    });

    const isEnabled = isNameConflict && !isCategoryConflict;

    // Used to check if parent action is useExisting then
    // its children action will be disabled;
    const isNodeDisabled = _isNil(parentId)
      ? false
      : ['useExisting'].includes(parentActionValue);

    const hasSelectedAction = !_isNil(actionValue) && !_isEmpty(actionValue);

    const label = hasSelectedAction
      ? entityActionOptions.find((actionObj) => actionObj.value === actionValue)
          ?.label
      : 'Take Action';

    const showBtn = !_isEmpty(entityId) && actionValue === 'create';

    const handleOpenEntity = () => {
      let link = '';
      const wsid = window.sessionStorage.getItem('workspaceUUID');

      if (!_isNil(entityId) && !_isEmpty(entityId)) {
        if (entity === 'rule' && !_isNil(wsid) && !_isEmpty(wsid)) {
          link = `/rules/${entityId}?type=edit&stage=staging&ruleType=${category}&wsit=${wsid}`;
        } else if (entity === 'dataSet') {
          link = `datasets/${entityId}?type=edit&stage=staging&editor=${category}`;
        } else if (entity === 'connector') {
          link = `/integrations/${entityId}?stage=staging&connector=${category}`;
        } else if (entity === 'variable') {
          link = `/remoteconfig`;
        } else if (entity === 'workflow' && !_isNil(wsid) && !_isEmpty(wsid)) {
          link = `/workflow/${entityId}?type=edit&stage=staging&wsid=${wsid}`;
        }

        window.open(link, window !== window.parent ? '_self' : '_blank');
      }
    };

    const btnLabel = useMemo(() => {
      switch (entity) {
        case 'variable':
          return 'Open Global Variable';
        case 'rule':
          return 'Open Rule';
        case 'dataSet':
          return 'Open Datasource';
        case 'connector':
          return 'Open Integration';
        case 'workflow':
          return 'Open Workflow';
        default:
          return '';
      }
    }, [entity]);

    const entiyName = useMemo(() => {
      switch (entity) {
        case 'dataset':
          return 'datasource';
        case 'rule':
          return 'rule';
        case 'workflow':
          return 'workflow';
        case 'connector':
          return 'integration';
        case 'variable':
          return 'global variable';
        default:
          return '';
      }
    }, [entity]);

    const ToolTipText = useMemo(() => {
      if (isNodeDisabled) {
        return (
          <Typography>
            {`No Dependencies would be imported and the ${entiyName} would be used as existing
            one.`}
          </Typography>
        );
      } else if (!isEnabled && entityType === 'connector') {
        return (
          <Typography>
            {getTooltipText(siteConstant, 'integrations', 'dummyConnectorText')}
          </Typography>
        );
      }

      return null;
    }, [isNodeDisabled, isEnabled, entityType, JSON.stringify(siteConstant)]);

    return (
      <>
        {showBtn ? (
          <OpenEntityLauncher onClick={handleOpenEntity}>
            {btnLabel}
          </OpenEntityLauncher>
        ) : (
          <TooltipReact
            id={nodeId}
            placement="bottom-start"
            launcher={
              <LauncherContainer $isEnabled={isEnabled && !isSummaryView}>
                <Typography
                  name={
                    isEnabled && !isNodeDisabled && !isSummaryView
                      ? 'paragraphXs'
                      : 'secondaryXs'
                  }
                >
                  {label}
                </Typography>

                {isEnabled && !isNodeDisabled && !isSummaryView && (
                  <IoMdArrowDropdown />
                )}
              </LauncherContainer>
            }
          >
            {ToolTipText}
          </TooltipReact>
        )}
      </>
    );
  }
);

ActionMenuLauncher.displayName = 'ActionMenuLauncher';
