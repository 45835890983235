import { useQuery } from '@tanstack/react-query';

import type { ProfileModel } from '../types';
import { useAxiosPrivate } from './useAxiosPrivate';

export function useProfile(enabled: boolean) {
  const { axiosPrivate } = useAxiosPrivate();

  return useQuery<ProfileModel>({
    queryKey: ['/user/profile'],
    queryFn: async () => {
      const response = await axiosPrivate.get(`/user/profile`);

      return response.data.data;
    },
    enabled,
  });
}
