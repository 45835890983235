import { Inline } from '@bedrock-layout/primitives';
import styled, { css } from 'styled-components';
import { Typography } from 'ui';

const getVersionAppearance = (env: string) => {
  switch (env) {
    case 'staging':
      return css`
        color: var(--color-tangerine);
        background: var(--color-earlyDawn);
      `;
    case 'production':
    default:
      return css`
        background-color: var(--color-lightGreen);
        color: var(--color-waterCourse);
      `;
  }
};

export const EnvVersionContainer = styled(Inline)<{ env: string }>`
  align-items: center;
  justify-content: center;
  min-inline-size: 8.5rem;
  border-radius: 0.5rem;
  padding: 0.5rem;

  ${({ env }) => getVersionAppearance(env)}
`;

export const VersionTextStyled = styled(Typography)<{ status?: string }>`
  font-size: 1rem;
  text-transform: capitalize;
`;
