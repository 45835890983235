import { useAtom } from 'jotai';
import React, { memo, useEffect, useRef, useState } from 'react';
import { Handle, Position } from 'reactflow';
import { PopoverMethods, PopoverPanel } from 'ui';

import {
  isWorkflowReadOnlyAtom,
  workflowEdgesAtom,
  workflowNodesAtom,
} from '../../../atoms/atoms';
import { checkNodeDisabled, filterWorkflowNodes } from '../../../utils/common';
import { AddNodeMdContainer } from './AddNode.styled';
import { AddNodeButton } from './AddNodeButton';
import { ControlPopover } from './ControlPopover/ControlPopover';

export const LoopDoneNode = memo(({ data, isConnectable, id }: any) => {
  const popoverRef = useRef<PopoverMethods>(null);
  const [workflowNodes] = useAtom(workflowNodesAtom);
  const [workflowEdges] = useAtom(workflowEdgesAtom);
  const [isWorkflowReadOnly] = useAtom(isWorkflowReadOnlyAtom);

  const [isNodeDisabled, setIsNodeDisabled] = useState(false);
  const [message, setMessage] = useState<string>();
  const workflowNodesUpdated = filterWorkflowNodes(workflowNodes);

  useEffect(() => {
    const checkDisabled = checkNodeDisabled(
      data.rootId ?? '',
      id,
      workflowNodes,
      workflowEdges
    );

    if (checkDisabled.isDisabled) {
      setMessage(checkDisabled.message);
    } else {
      setMessage(undefined);
    }
    setIsNodeDisabled(checkDisabled.isDisabled || isWorkflowReadOnly);
  }, [workflowNodesUpdated, isWorkflowReadOnly]);

  return (
    <React.Fragment>
      <Handle
        type="source"
        position={Position.Bottom}
        style={{ background: '#eee', height: 1, width: 1 }}
        isConnectable={isConnectable}
      />

      <Handle
        type="target"
        position={Position.Top}
        style={{ background: '#eee', height: 1, width: 1 }}
        isConnectable={isConnectable}
      />

      <PopoverPanel
        launcher={
          <AddNodeMdContainer>
            <AddNodeButton
              id={id}
              isDisabled={isNodeDisabled}
              disabledMessage={message}
            />
          </AddNodeMdContainer>
        }
        padding="0rem"
        trigger="click"
        ref={popoverRef}
        placement="bottom"
        disabled={isNodeDisabled}
      >
        <ControlPopover
          data={data}
          id={id}
          onClose={() => {
            popoverRef.current?.hide();
          }}
        />
      </PopoverPanel>
    </React.Fragment>
  );
});

LoopDoneNode.displayName = 'LoopDoneNode';
