import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import _isEmpty from 'lodash/isEmpty';
import { Ref, forwardRef } from 'react';
import { BiChevronDown, BiUser } from 'react-icons/bi';
import { ToolTip, Typography } from 'ui';

import type { WorkSpaceDetailsModel } from '../../types';
import { StackAsItem } from '../layouts/Stack.styled';
import {
  ArrowIconContainer,
  NameIconContainer,
  UserCount,
  WorkspaceName,
  Wrapper,
} from './WorkspaceComponent.styled';

type WorkspaceComponentProps = WorkSpaceDetailsModel & {
  showArrowIcon?: boolean;
  onClick?: (workspaceId: string) => void;
  bgcolor?: string;
  showBorder?: boolean;
  isSelected?: boolean;
};

export const WorkspaceComponent = forwardRef(
  (
    {
      name,
      uuid,
      userCount,
      showArrowIcon = true,
      onClick,
      bgcolor,
      showBorder = false,
      isSelected = false,
    }: WorkspaceComponentProps,
    ref: Ref<HTMLButtonElement>
  ) => {
    const handleClick = () => {
      if (typeof onClick === 'function') {
        onClick(uuid);
      }
    };

    return (
      <PadBox
        onClick={handleClick}
        align="center"
        gutter={'1.2rem'}
        padding={'0.8rem'}
        as={Wrapper}
        ref={ref}
        bgcolor={bgcolor}
        $showBorder={showBorder}
      >
        <StackAsItem grow={1} as={Inline} justify="start">
          <NameIconContainer align="center" justify="center">
            {_isEmpty(name) ? (
              <BiUser />
            ) : (
              <Typography>{name[0].toUpperCase()}</Typography>
            )}
          </NameIconContainer>

          <Stack gutter={0}>
            <ToolTip message={name}>
              <WorkspaceName fontWeight={700}>{name}</WorkspaceName>
            </ToolTip>

            <UserCount name="paragraphSmall" fontWeight={700}>
              {userCount} Users
            </UserCount>
          </Stack>
        </StackAsItem>

        {showArrowIcon && (
          <ArrowIconContainer align="center" justify="center">
            <BiChevronDown />
          </ArrowIconContainer>
        )}
      </PadBox>
    );
  }
);

WorkspaceComponent.displayName = 'Workspace Component';
