import { PadBox } from '@bedrock-layout/padbox';
import { Stack } from '@bedrock-layout/stack';
import styled from 'styled-components';

type FieldContainerProps = {
  widthRequired?: boolean;
};

export const RemoteConfigContainer = styled(PadBox)`
  background-color: var(--color-white);
  border-radius: 0.5rem;
  margin-top: 4rem;
`;

export const Table = styled.table`
  border-radius: 6px;
  inline-size: 100%;
  text-align: left;
  border-spacing: 0;
`;

export const TableHeaderStyled = styled.th``;

export const TableDataStyled = styled.td`
  max-inline-size: 16rem;
`;
export const HorizontalLine = styled.div`
  border: 1px solid var(--color-lightGray);
  height: 1px;
`;

export const UlStyled = styled.ul`
  list-style: none;
`;

export const StackStyled = styled(Stack)`
  width: 100%;
`;

export const FieldContainer = styled.div<FieldContainerProps>`
  min-block-size: 3.7rem;

  ${({ widthRequired = true }) => widthRequired && 'width: 30%;'}
`;

export const HeaderDataStyled = styled.div`
  width: 31%;
`;
