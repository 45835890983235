import _isNil from 'lodash/isNil';
import { HexColorPicker } from 'react-colorful';
import { CgColorPicker } from 'react-icons/cg';

import { TextInput } from '../Form/TextInput';
import { Popover } from '../Popover';
import { Typography } from '../Typography';
import {
  ColorContainer,
  InputContainer,
  LauncherButton,
  PickerContainer,
} from './ColorPicker.styled';

export type ColorPickerProps = {
  name?: string;
  value?: string;
  onChange?: (value: any) => void;
  error?: string;
  disabled?: boolean;
};

export function ColorPicker({
  error,
  name,
  onChange,
  value,
  disabled = false,
}: ColorPickerProps) {
  // eslint-disable-next-line
  const hasError = !!error;

  return (
    <PickerContainer $isError={hasError}>
      <Typography fontWeight={700}>HEX code:</Typography>

      <InputContainer>
        <TextInput
          value={value}
          onChange={(e) => {
            if (!_isNil(onChange)) {
              onChange(e.target.value);
            }
          }}
          disabled={disabled}
          hasError={hasError}
          showErrorIcon={false}
          size="small"
        />
      </InputContainer>

      <ColorContainer $color={value} />

      <Popover
        trigger="click"
        placement="bottom-end"
        launcher={
          <span>
            <Launcher />
          </span>
        }
      >
        <HexColorPicker color={value} onChange={onChange} />
      </Popover>
    </PickerContainer>
  );
}

const Launcher = () => {
  return (
    <LauncherButton type="button">
      <CgColorPicker fontSize={20} />
    </LauncherButton>
  );
};
