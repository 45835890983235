import { Inline } from '@bedrock-layout/primitives';
import { atom, useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect } from 'react';
import {
  UseControllerProps,
  UseFormSetValue,
  useFieldArray,
  useWatch,
} from 'react-hook-form';
import { Typography, toasts } from 'ui';

import { useAddVersionMappingOfEntity } from '../../../../../hooks/useAddVersionMappingOfEntity';
import { generateRandomHex } from '../../../../../utils/common';
import { isRuleReadOnlyAtom, ruleLimitsConfigAtom } from '../../../index';
import { assetsInDraftMapAtom, versionMappingInfoAtom } from '../../atom/atom';
import { AddRuleMenu } from '../AddRuleMenu/AddRuleMenu';
import {
  customAttributesByIdAtom,
  rulesListAtom,
} from '../RuleSetEditor/RuleSetEditor';
import type { CustomAttributeByRuleId, RuleSetNodesModel } from '../models';
import { RuleSetRow } from './RuleSetRow';
import {
  TableContainerStyled,
  TableFooterContainerStyled,
  TableHeaderContainerStyled,
  TableHeaderStyled,
  TableRowContainerStyled,
} from './RuleSetTable.styled';

export type RuleSetTableProps = Omit<UseControllerProps, 'name'> & {
  setValue: UseFormSetValue<any>;
};

const headers = [
  {
    title: 'Rules',
    width: '50%',
  },
  {
    title: 'Result',
    width: '30%',
  },
  {
    title: '',
    width: '20%',
  },
];

export const customAttributesInFieldsAtom = atom<
  Record<string, CustomAttributeByRuleId[]>
>({});

export function RuleSetTable({ control, setValue }: RuleSetTableProps) {
  const [, setCustomAttributesInFields] = useAtom(customAttributesInFieldsAtom);
  const [customAttributesById] = useAtom(customAttributesByIdAtom);
  const [isRuleReadOnly] = useAtom(isRuleReadOnlyAtom);

  const [ruleOptions] = useAtom(rulesListAtom);
  const [, setVersionMappingInfo] = useAtom(versionMappingInfoAtom);
  const [, setAssetsInDraftMap] = useAtom(assetsInDraftMapAtom);

  const [limitConfig] = useAtom(ruleLimitsConfigAtom);

  const { addVersionMapping } = useAddVersionMappingOfEntity();

  const { fields, append, insert, remove } = useFieldArray({
    name: 'ruleList',
    control,
  });

  const rules: RuleSetNodesModel[] = useWatch({
    control,
    name: `ruleList`,
  });

  const handleInsertRow = (index: number) => {
    insert(index, {
      isEnabled: true,
      ruleId: `00000000${generateRandomHex(16)}`,
    });
  };

  useEffect(() => {
    setAssetsInDraftMap(undefined);
  }, [rules]);

  useEffect(() => {
    setCustomAttributesInFields(
      rules.reduce<Record<string, CustomAttributeByRuleId[]>>(
        (attributeObject, field) => {
          if (!_isNil(customAttributesById[field.ruleId]) && field.isEnabled) {
            return {
              ...attributeObject,
              [field.ruleId]: customAttributesById[field.ruleId],
            };
          }

          return attributeObject;
        },
        {}
      )
    );
  }, [rules, customAttributesById]);

  const handleVersionMapping = async (entityId: string) => {
    const currentRule = ruleOptions.find((rule) => rule.id === entityId);

    const versionInfo = await addVersionMapping({
      entityId,
      type: 'rules',
      name: currentRule?.name ?? '',
      subType: currentRule?.type,
      nodeId: entityId,
    });

    if (!_isNil(versionInfo)) {
      setVersionMappingInfo((versionMappingList) => [
        ...(versionMappingList ?? []),
        versionInfo,
      ]);
    }
  };

  const appendElements = (ruleId: string) => {
    if (!_isEmpty(limitConfig) && fields.length < limitConfig.maxSrRs.value) {
      void handleVersionMapping(ruleId);

      append({
        isEnabled: true,
        ruleId,
      });
    } else if (!_isEmpty(limitConfig.maxSrRs.message)) {
      toasts.info(limitConfig.maxSrRs.message, 'info');
    }
  };

  return (
    <TableContainerStyled gutter={0}>
      <TableHeaderContainerStyled as={Inline} gutter={0}>
        {headers.map((header, index) => (
          <TableHeaderStyled
            width={header.width}
            key={`rule_${index}`}
            padding={[5, 10]}
          >
            <Typography>{header.title}</Typography>
          </TableHeaderStyled>
        ))}
      </TableHeaderContainerStyled>

      <TableRowContainerStyled>
        {fields.map((rule, index) => (
          <RuleSetRow
            handleInsertRow={handleInsertRow}
            handleDeleteRow={(index: number) => {
              setVersionMappingInfo(
                (prev) =>
                  prev?.filter(
                    (currInfo) => currInfo.nodeId !== rules[index].ruleId
                  ) ?? []
              );

              remove(index);
            }}
            setValue={setValue}
            control={control}
            key={index}
            index={index}
          />
        ))}
      </TableRowContainerStyled>

      <TableFooterContainerStyled padding={[5, 10]}>
        {!isRuleReadOnly && (
          <AddRuleMenu control={control} onClick={appendElements} />
        )}
      </TableFooterContainerStyled>
    </TableContainerStyled>
  );
}
