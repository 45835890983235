import type { ObservableQuery } from '@apollo/client';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _isUndefined from 'lodash/isUndefined';
import { useEffect, useState } from 'react';
import {
  type UseControllerProps,
  UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import { DropdownField, NectedSuggestionModel } from 'ui';

import { PluginFieldContainer } from '../../../pages/Integrations/components/common/PluginFieldContainer';
import type { PluginFieldCommonProps } from '../../../pages/Integrations/types';
import type { Option } from '../types';
import { extractKeyDataFromObject } from '../utlis';
import { useGetDataFromRestApi } from './hook/useGetDataFromRestApi';
import { useResetFieldsBasedOnConfig } from './hook/useResetFieldsBasedOnConfig';

type WebDropdownFieldProps = UseControllerProps<any> &
  PluginFieldCommonProps & {
    options?: Option[];
    refetchAuthKeys?: ObservableQuery<any>['refetch'];
    footer?: JSX.Element;
    isMulti?: boolean;
    onLoadSpec?: Record<string, any>;
    onSelectionSpec?: Record<string, any>;
    parentFormData?: Record<string, any>;
    formJson: Record<string, any>;
    setValue?: UseFormSetValue<any>;
    newCustomSuggestions?: NectedSuggestionModel[];
    showTokens?: boolean;
    reset?: string[];
  };

export function WebDropdownField({
  errorMessage = 'Please enter a valid input',
  control,
  label,
  value,
  name,
  required = false,
  regex,
  placeholder,
  disabled = false,
  options = [],
  isMulti = false,
  showTokens = false,
  footer,
  tooltipText,
  onLoadSpec,
  onSelectionSpec,
  formJson,
  parentFormData = {},
  formKeyPrefix = '',
  setValue,
  reset = [],
  newCustomSuggestions = [],
}: WebDropdownFieldProps) {
  const [dropdownOptions, setDropdownOptions] = useState<Option[]>([
    ...options,
  ]);
  const [dependentValuesForOnLoadSpec, setDependentValuesForOnLoadSpec] =
    useState<string[]>([]);

  const { fetchDataFromRestApi } = useGetDataFromRestApi({
    control,
    name,
    formJson,
    parentFormData,
    formKeyPrefix,
  });

  const { resetConfigFieldData } = useResetFieldsBasedOnConfig({
    reset,
    formKeyPrefix,
    parentFormData,
    setValue,
  });

  const formValues = useWatch({
    control,
  });

  const fieldValue = useWatch({
    name,
    control,
  });

  const formatNewCustomSuggestions = (
    newCustomSuggestions: NectedSuggestionModel[]
  ) => {
    const updatedSuggestions = newCustomSuggestions.map((suggestion) => {
      if (suggestion.meta === 'string') {
        return {
          label: suggestion.value.slice(1, suggestion.value.length - 1) ?? '',
          value: suggestion.value.slice(1, suggestion.value.length - 1),
        };
      }

      return {
        label: suggestion.name ?? '',
        value: suggestion.value,
      };
    });

    return updatedSuggestions;
  };

  useEffect(() => {
    if (showTokens) {
      const suggestionList = formatNewCustomSuggestions(newCustomSuggestions);

      setDropdownOptions([...suggestionList, ...options]);
    }
  }, [JSON.stringify(newCustomSuggestions)]);

  useEffect(() => {
    if (
      dropdownOptions.length > 0 &&
      !_isNil(fieldValue) &&
      fieldValue !== '' &&
      typeof fieldValue !== 'object' &&
      !_isUndefined(setValue)
    ) {
      setValue(
        name,
        dropdownOptions.find((option) => option.value === fieldValue) ??
          fieldValue
      );
    }
  }, [dropdownOptions]);

  useEffect(() => {
    if (!_isNil(onLoadSpec)) {
      const dependentKeys: string[] = onLoadSpec.dependencies ?? [];

      // Get the values from formValues of the dependent keys
      const dependentValues = dependentKeys.map((key) =>
        extractKeyDataFromObject(formValues, formKeyPrefix + key)
      );

      // Check if any value is null or undefined of dependent keys so that API is not triggered
      const allValuesNotExist = dependentValues.some(
        (value) => value === undefined || value === null
      );

      // Need to check if the values of dependent keys are changed on form values change to avoid duplicate API calls
      const isDependentValuesChanged =
        JSON.stringify(dependentValuesForOnLoadSpec) !==
        JSON.stringify(dependentValues);

      setDependentValuesForOnLoadSpec(dependentValues);

      if (!allValuesNotExist && isDependentValuesChanged) {
        void getOptions();
      }

      // If any of the dependent keys value is null or undefined then clear the dropdown options
      if (allValuesNotExist) {
        const suggestionList = formatNewCustomSuggestions(newCustomSuggestions);
        setDropdownOptions(
          showTokens ? [...options, ...suggestionList] : [...options]
        );
      }
    }
  }, [onLoadSpec, JSON.stringify(formValues)]);

  useEffect(() => {
    // type check is added because initially dropdown value is not object in some scenarios
    if (typeof fieldValue === 'object') {
      resetConfigFieldData();
    }

    if (!_isNil(fieldValue) && !_isEmpty(fieldValue)) {
      void makeApiCallOnFieldSelection();
    }
  }, [JSON.stringify(fieldValue)]);

  const getOptions = async () => {
    if (!_isNil(onLoadSpec)) {
      try {
        const response: any = await fetchDataFromRestApi(onLoadSpec);

        const suggestionList = formatNewCustomSuggestions(newCustomSuggestions);

        if (response?.data.code === 'success') {
          const optionsFromApi = response?.data?.data?.result ?? [];

          setDropdownOptions(
            showTokens ? [...optionsFromApi, ...suggestionList] : optionsFromApi
          );
        }
      } catch (err) {}
    }
  };

  const makeApiCallOnFieldSelection = async () => {
    if (!_isNil(onSelectionSpec)) {
      try {
        const response: any = await fetchDataFromRestApi(onSelectionSpec);
        const { responseToMapWith } = onSelectionSpec;

        if (response?.data.code === 'success' && !_isUndefined(setValue)) {
          setValue(
            formKeyPrefix + (responseToMapWith as string),
            response?.data?.data?.result ?? []
          );
        }
      } catch (err) {}
    }
  };

  return (
    <PluginFieldContainer
      label={label}
      required={required}
      tooltipText={tooltipText}
    >
      <DropdownField
        defaultValue={value}
        name={name}
        options={dropdownOptions}
        rules={{
          required: {
            value: required,
            message: 'Please select a value',
          },
          pattern: {
            value: regex,
            message: errorMessage,
          },
        }}
        control={control}
        placeholder={placeholder}
        disabled={disabled}
        MenuListFooter={footer}
        isMulti={isMulti}
      />
    </PluginFieldContainer>
  );
}
