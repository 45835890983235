import { PadBox } from '@bedrock-layout/padbox';
import styled from 'styled-components';

export const JsonPillStyled = styled(PadBox)<{ $hasError: boolean }>`
  min-inline-size: 4rem;
  border-radius: 0.8rem;
  cursor: pointer;
  background: var(--color-gainsboro);
  ${({ $hasError }) =>
    $hasError ? 'var(--color-fireEngineRed)' : 'var(--color-lavender)'};
`;