import { useEffect, useState } from 'react';
import { Dataset } from 'ui';

type UseGetIdsToBeNotExpandedProps = {
  directParents: any[];
  dataSet: Record<string, Dataset>;
};

export function useGetIdsToBeNotRendered({
  directParents,
  dataSet,
}: UseGetIdsToBeNotExpandedProps) {
  const [idsToNotExpand, setIdsToNotExpand] = useState<string[]>([]);

  useEffect(() => {
    const parentNames = directParents.map((node) => node.data.name);

    const noExpand = Object.keys(dataSet)
      .filter((p) => {
        if (!parentNames.includes(p)) {
          return true;
        }

        return false;
      })
      .map((node) => dataSet[node]?.id);

    // eslint-disable-next-line
    setIdsToNotExpand(noExpand.filter((e) => !!e));
  }, [JSON.stringify(directParents), JSON.stringify(dataSet)]);

  return {
    idsToNotExpand,
  };
}
