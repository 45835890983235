import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { ListingComponent } from '../../components/Listing';
import { ListingDetailsProps } from '../../components/Listing/types';
import { PagesBodyContainer } from '../../components/layouts/PagesBodyContainer';
import { useAxiosPrivate } from '../../hooks';
import { useGetSiteMeta } from '../../hooks/useGetSiteMeta';
import { ENTITY_MAP } from '../../utils/constant';
import { RenderLogDetails } from './components/RenderLogDetails';
import type { LogDetailsProps, TableHeaderProps } from './types';

type AuditLogProps = {
  subModules: Record<string, boolean>;
};

export function AuditLogs({ subModules }: AuditLogProps) {
  const { axiosPrivate } = useAxiosPrivate();
  const navigate = useNavigate();
  const routeParams = useParams();
  const [searchParams] = useSearchParams();
  const { logId } = routeParams;
  const [logDetails, setLogDetails] = useState<LogDetailsProps>(null);
  const [allFilters, setAllFilters] = useState<Record<string, any>>({});
  const entity = ENTITY_MAP[window.location.pathname.split('/')[1]];
  const { siteMeta } = useGetSiteMeta();

  const handleRowClick = (row: any, details: ListingDetailsProps) => {
    return null;
  };

  useEffect(() => {
    const wsid = searchParams.get('wsId');

    if (!_isNil(wsid) && !_isEmpty(wsid)) {
      sessionStorage.setItem('workspaceUUID', wsid);
    }
  }, []);

  const handleCellClick = (
    cell: any,
    index: number,
    listType: string,
    details: ListingDetailsProps
  ) => {
    if (listType === 'TABLE') {
      setAllFilters(details);
      handleRedirection(cell, details, index);
    } else {
      handleRedirection(
        cell,
        Object.keys(allFilters).length > 0 ? allFilters : details,
        index
      );
    }
  };

  const handleRedirection = (
    cell: any,
    details: Record<string, any>,
    index: number
  ) => {
    const { original } = cell.row;
    const cellType = details.tableHeaders[index].keyName;
    const isRootNode = cellType === 'rootNode';
    const logID = isRootNode ? original.rootNode.logId : original.logId;
    let apiURL = details.endpoint?.detail
      .replace('{module}', entity)
      .replace('{logID}', logID);
    apiURL = `${apiURL as string}?pageSize=10&pageNo=0`;
    const tableHeaders = details.tableHeaders;
    const filterObj = JSON.stringify({
      apiURL,
      tableHeaders,
    });

    if (!_isEmpty(logID)) {
      navigate(
        `/auditlogs/${logID as string}?filter=${window.btoa(filterObj)}`
      );
    }
  };

  useEffect(() => {
    const obj = searchParams.get('filter');
    let filterObj = null;
    let apiURL = '';
    let tableHeaders = [];

    if (!_isNil(obj)) {
      filterObj = JSON.parse(window.atob(obj));
      apiURL = filterObj.apiURL;
      tableHeaders = filterObj.tableHeaders ?? [];
    }

    if (!_isEmpty(logId) && !_isEmpty(apiURL)) {
      void fetchLogDetails(apiURL, tableHeaders);
    }
  }, [logId]);

  const handleGoBack = () => {
    if (window.history.length === 2) {
      navigate('/auditlogs');
    } else {
      window.history.back();
    }
  };

  const fetchLogDetails = async (
    apiUrl: string,
    tableHeaders: TableHeaderProps[]
  ) => {
    try {
      const logDetails = await axiosPrivate.get(apiUrl);
      const details = logDetails.data.data;
      details.totalPages = logDetails.data.totalPages;
      setLogDetails({ details, tableHeaders });
    } catch (error) {
      if (error instanceof Error) {
        // eslint-disable-next-line no-console
        console.log(error.message);
      }
    }
  };

  const fetchPaginatedLogDetails = (pageNumber: number) => {
    const obj = searchParams.get('filter');
    let filterObj = null;
    let apiURL = '';
    let tableHeaders = [];

    if (!_isNil(obj)) {
      filterObj = JSON.parse(window.atob(obj));
      const urlObject = new URL(filterObj.apiURL, window.location.href);
      urlObject.searchParams.set('pageNo', (pageNumber + 1).toString());
      const updatedUrl = `${urlObject.pathname}${urlObject.search}`;
      apiURL = `${updatedUrl}`;
      tableHeaders = filterObj.tableHeaders;
    }

    if (!_isEmpty(logId) && !_isEmpty(apiURL)) {
      void fetchLogDetails(apiURL, tableHeaders);
    }
  };

  return (
    <PagesBodyContainer>
      <Helmet>
        <title>
          {siteMeta[window.location.pathname.substring(1)]?.title ?? ''}
        </title>
        <meta
          name="description"
          content={
            siteMeta[window.location.pathname.substring(1)]?.description ?? ''
          }
        />
      </Helmet>
      {_isEmpty(logId) && (
        <ListingComponent
          entity={entity}
          headerTitle="Audit Trail"
          callOnRowClick={handleRowClick}
          callOnCellClick={handleCellClick}
          errorTitle="No logs found"
          subModules={subModules}
        />
      )}
      {!_isEmpty(logId) &&
        !_isNil(logDetails) &&
        !_isNil(logDetails.details) && (
          <RenderLogDetails
            entity={entity}
            logDetails={logDetails}
            handleGoBack={handleGoBack}
            handleRowClick={handleRowClick}
            handleCellClick={handleCellClick}
            fetchPaginatedLogDetails={fetchPaginatedLogDetails}
          />
        )}
    </PagesBodyContainer>
  );
}
