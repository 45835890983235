import { Inline, Stack } from '@bedrock-layout/primitives';
import _isEmpty from 'lodash/isEmpty';

import type { ProfileModel } from '../../../../types';
import {
  LinkStyled,
  ProfileEmail,
  ProfileName,
  ProfileNameContainer,
} from './ProfileContainer.styled';
import { ProfileIcon } from './ProfileIcon';

type ProfileDetailsMiniProps = {
  profile: ProfileModel;
};

export const ProfileDetailsMini = ({ profile }: ProfileDetailsMiniProps) => {
  const { email, firstName = '', lastName = '' } = profile;

  return (
    <ProfileNameContainer padding={[10]}>
      <Inline gutter={10}>
        <LinkStyled to="/questionnaire?fromHome=true">
          <ProfileIcon profile={profile} />
        </LinkStyled>

        <Stack gutter={0}>
          {_isEmpty(firstName) ? (
            <LinkStyled to="/questionnaire?fromHome=true">
              Add Name
              <ProfileEmail>{email}</ProfileEmail>
            </LinkStyled>
          ) : (
            <LinkStyled to="/questionnaire?fromHome=true">
              <ProfileName>{[firstName, lastName].join(' ')}</ProfileName>
              <ProfileEmail>{email}</ProfileEmail>
            </LinkStyled>
          )}
        </Stack>
      </Inline>
    </ProfileNameContainer>
  );
};
