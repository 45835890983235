import { Inline } from '@bedrock-layout/inline';
import { PadBox } from '@bedrock-layout/padbox';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { IconButton } from 'ui';

export function ConnectorMenuLauncher() {
  return (
    <Inline justify="end" gutter="0.8rem" align="center">
      <IconButton>
        <PadBox padding=".4rem">
          <BsThreeDotsVertical color="var(--color-darkGray)" />
        </PadBox>
      </IconButton>
    </Inline>
  );
}
