import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  Typography,
  useCurrentLayer,
} from 'ui';

type DeleteRuleModalProps = {
  onDeleteClick?: () => Promise<void>;
  title?: string;
  description?: string;
};

export function DeleteRuleModal({
  onDeleteClick,
  title = 'Delete Rule',
  description = 'Are you sure you want to delete the rule? It might be used in any ruleset that might fail.',
}: DeleteRuleModalProps) {
  const { close } = useCurrentLayer();

  return (
    <Modal size="medium" title={title}>
      <ModalContent>
        <Typography>{description}</Typography>
      </ModalContent>
      <ModalFooter>
        <Button onClick={close} appearance="neutral">
          Cancel
        </Button>

        <Button
          onClick={async () => {
            if (typeof onDeleteClick === 'function') {
              await onDeleteClick();
            }
            close();
          }}
          appearance="danger"
        >
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  );
}
