import { Inline } from '@bedrock-layout/primitives';
import { AxiosError } from 'axios';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _reduce from 'lodash/reduce';
import { type ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { BiError } from 'react-icons/bi';
import { MdOutlineCloudDone, MdOutlineSummarize } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  FileUploadField,
  Modal,
  ModalContent,
  ModalFooter,
  Toast,
  Typography,
  toasts,
  useCurrentLayer,
} from 'ui';

import { useAxiosPrivate } from '../../../hooks';
import {
  DependencyConfig,
  EntityDependencyView,
} from '../../EntityDependencyView/EntityDependencyView';
import { StackAsItem } from '../../layouts/Stack.styled';
import { Container, TextBlue } from './ImportEntityModal.styled';

export type FromType =
  | 'export_sheet'
  | 'export_list'
  | 'import_sheet'
  | 'import_list';

type ImportEntityModalProps = {
  entityType: string;
  handleFetchEntity?: () => void;
  entityName?: string;
  from?: FromType;
};

export function ImportEntityModal({
  entityType,
  handleFetchEntity,
  entityName,
  from = 'import_sheet',
}: ImportEntityModalProps) {
  const navigate = useNavigate();

  const [isFileUploading, setIsFileUploading] = useState(false);
  const [isSummaryView, setIsSummaryView] = useState(false);
  const [isImported, setIsImported] = useState(false);
  const [isImportSummaryView, setIsImportSummaryView] = useState(false);
  const [isConnectorCreated, setIsConnectorCreated] = useState(false);
  const [isImportDisable, setIsImportDisable] = useState(false);
  const [fileProgress, setFileProgress] = useState(0);
  const [dependencyData, setDependencyData] = useState<Record<string, any>>();

  const { axiosPrivate } = useAxiosPrivate();
  const { close: closeModal, closeAllLayers } = useCurrentLayer();

  const { control, setValue } = useForm<any>({
    defaultValues: {
      fileIds: null,
    },
  });

  const fileIds = useWatch({
    control,
    name: 'fileIds',
  });

  const files = useWatch({
    control,
    name: 'files',
  });

  const dependencyJson = useWatch({
    control,
    name: 'dependencyJson',
  });

  useEffect(() => {
    if (!_isNil(dependencyJson) && !_isNil(dependencyJson.ref)) {
      const dependencyKeyList = Object.keys(dependencyJson.ref);

      // This loop is used to check if there is any new connector
      // being created while importing dependencies
      // checkIsConnectorCreated(dependencyJson.rootId);

      let currIsConnectorCreated = false;
      for (const key of dependencyKeyList) {
        const { action, entity } = dependencyJson.ref[key];

        if (['create', 'replace'].includes(action) && entity === 'connector') {
          currIsConnectorCreated = true;
          break;
        }
      }
      setIsConnectorCreated(currIsConnectorCreated);

      // This loop is used to check if we have name and category
      // both conflicting in case of global variable then
      // import is disabled
      for (const key of dependencyKeyList) {
        const { isNameConflict, isCategoryConflict, entity } =
          dependencyJson.ref[key];

        const isConflict =
          (isNameConflict as boolean) && (isCategoryConflict as boolean);

        if (isConflict && entity === 'variable') {
          setIsImportDisable(true);
          break;
        }
      }
    }
  }, [JSON.stringify(dependencyJson)]);

  const title = useMemo(() => {
    let modalName = `Import ${
      entityType[0].toUpperCase() + entityType.substring(1)
    }`;

    let icon = null;

    if (isSummaryView && !isImported) {
      modalName = 'Dependency Summary';
      icon = <MdOutlineSummarize size={24} color="var(--color-dodgerBlue)" />;
    }

    if (
      !_isNil(dependencyJson) &&
      !_isNil(dependencyJson.ref) &&
      !isSummaryView
    ) {
      modalName = 'Manage Dependencies';
      icon = <BiError size={24} color="var(--color-darkGoldenrod)" />;
    }

    if (isImported) {
      modalName = 'Import Successful';
    }

    if (!_isNil(icon)) {
      return (
        <Inline align="center" justify="start" gutter="1rem">
          {icon}
          <Typography name="heading2">{modalName} </Typography>
        </Inline>
      );
    }

    return <Typography name="heading2">{modalName} </Typography>;
  }, [JSON.stringify(dependencyJson), isImported, isSummaryView]);

  const onFileUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    try {
      const filesLength = !_isNil(e.target.files)
        ? Array.from({ length: e.target.files.length }, (_, i) => i)
        : [];

      setIsFileUploading(true);

      const formData = new FormData();

      let fileName = '';

      filesLength.forEach((i: number) => {
        if (!_isNil(e.target.files)) {
          const file = e.target.files?.[i];

          fileName = file.name;
          formData.append('file', file);
        }
      });

      const response = await axiosPrivate.post(
        `/common/${entityType}/import/upload`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (progressEvent) => {
            if (!_isNil(progressEvent.total)) {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              setFileProgress(percentCompleted);
            } else {
              // Assuming progress is completed if progressEvent.total is null or undefined
              setFileProgress(100);
            }
          },
        }
      );

      if (!_isNil(response) && !_isNil(response.data?.data)) {
        setValue('fileIds', response.data.data.id);
        setValue('files', fileName);
      }
    } catch (err) {
    } finally {
      setIsFileUploading(false);
      e.target.files = null;
    }
  };

  const getDependencyActions = () => {
    return _reduce(
      dependencyJson.ref,
      (actionJson: Record<string, string>, currRef, key) => {
        return {
          ...actionJson,
          [key]: currRef.action,
        };
      },
      {}
    );
  };

  const checkIfImportedEntityCanBeOverride = (json: Record<string, any>) => {
    const { name, isNameConflict } = json.ref[json.rootId] as DependencyConfig;

    if (!_isNil(entityName) && name !== entityName && isNameConflict) {
      toasts.error(
        `Cannot import this ${entityType} as there is already a ${entityType} with same name in your workspace`,
        'import-override'
      );

      return false;
    }

    return true;
  };

  const handleValidateFileUploaded = async () => {
    try {
      const response = await axiosPrivate.post(
        `/common/${entityType}/import/validate`,
        {
          id: fileIds,
        }
      );

      if (
        !_isNil(response) &&
        !_isNil(response.data?.data) &&
        checkIfImportedEntityCanBeOverride(response.data.data)
      ) {
        setValue('dependencyJson', response.data.data);
        setDependencyData(response.data.data);
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        toasts.error(err.message, 'import-validate-error');
      }
    }
  };

  const handleBackBtn = () => {
    setIsSummaryView(false);
  };

  const handleOpenEntity = () => {
    let link = '';
    const wsid = window.sessionStorage.getItem('workspaceUUID');

    if (!_isNil(dependencyJson)) {
      const { entityId, category } = dependencyJson.ref[
        dependencyJson.rootId
      ] as DependencyConfig;

      if (!_isNil(entityId) && !_isEmpty(entityId)) {
        if (entityType === 'rule' && !_isNil(wsid) && !_isEmpty(wsid)) {
          link = `/rules/${entityId}?type=edit&stage=staging&ruleType=${category}&wsid=${wsid}`;
        } else if (
          entityType === 'workflow' &&
          !_isNil(wsid) &&
          !_isEmpty(wsid)
        ) {
          link = `/workflow/${entityId}?type=edit&stage=staging&wsid=${wsid}`;
        }

        closeAllLayers();
        navigate(link);
      }
    }
  };

  const handleContinueBtn = async () => {
    if (isImported) {
      closeModal();

      if (typeof handleFetchEntity === 'function' && from === 'import_sheet') {
        void handleFetchEntity();
      } else if (from === 'import_list') {
        handleOpenEntity();
      }
    } else if (!isSummaryView) {
      setIsSummaryView(true);
    } else if (isSummaryView) {
      try {
        const payload = {
          id: fileIds,
          actions: getDependencyActions(),
        };

        const response = await axiosPrivate.post(
          `/common/${entityType}/import/apply`,
          payload
        );

        if (!_isNil(response) && !_isNil(response.data?.data)) {
          setValue('dependencyJson', response.data.data);
        }

        setIsImported(true);
      } catch (err) {}
    }
  };

  const handleDeleteFile = (value: string) => {
    setValue('files', undefined);
    setValue('fileIds', null);
  };

  const name: string =
    dependencyJson?.ref?.[dependencyJson?.rootId]?.name ?? entityType;

  const rootDependencies =
    dependencyJson?.ref?.[dependencyJson?.rootId]?.dependencies;

  const hasChildNodes =
    !_isNil(rootDependencies) && !_isEmpty(rootDependencies);

  return (
    <Modal size="extraLarge" title={title} overflow="none">
      <ModalContent>
        {!isImported && (
          <Container gutter="1rem">
            {isSummaryView ? (
              <Typography>
                Following actions will be applied to the mentioned dependencies,
                Do you wish to continue ?
              </Typography>
            ) : (
              !_isNil(dependencyJson) && (
                <>
                  {hasChildNodes ? (
                    <Typography>{`Multiple dependencies have been detected , they will be affected when you import ${name}, kindly take action`}</Typography>
                  ) : (
                    <Typography>No dependency has been detected</Typography>
                  )}
                </>
              )
            )}

            {!_isNil(dependencyJson) ? (
              <EntityDependencyView
                rootId={dependencyJson.rootId}
                data={dependencyJson.ref}
                control={control}
                setValue={setValue}
                isSummaryView={isSummaryView}
                from={from}
                defaultDepenedencyData={dependencyData}
              />
            ) : (
              <Inline
                as={StackAsItem}
                grow={1}
                justify={_isNil(files) ? 'center' : 'start'}
                align="center"
              >
                <FileUploadField
                  control={control}
                  name="files"
                  accept=".json"
                  onFileUpload={onFileUpload}
                  handleDeleteFile={handleDeleteFile}
                  isLoading={isFileUploading}
                  widthFull={true}
                  multiple={false}
                  entityName={entityType}
                  fileProgress={fileProgress}
                />
              </Inline>
            )}
          </Container>
        )}

        {isImported && (
          <Container gutter="1rem">
            {isImportSummaryView ? (
              <>
                <Typography>
                  This is a comprehensive summary of the import
                </Typography>
                <EntityDependencyView
                  rootId={dependencyJson.rootId}
                  data={dependencyJson.ref}
                  control={control}
                  isSummaryView={true}
                  from={from}
                />
              </>
            ) : (
              <Inline align="center" justify="center" as={StackAsItem} grow={1}>
                <MdOutlineCloudDone color="var(--color-primary2)" size={108} />
                <Inline align="center" justify="center" gutter="0.5rem">
                  <TextBlue name="heading2">{`${name}`}</TextBlue>
                  <Typography name="heading2" fontWeight={400}>
                    imported successfuly
                  </Typography>
                </Inline>
              </Inline>
            )}
          </Container>
        )}

        {isConnectorCreated && !isImported && (
          <Toast
            type={isSummaryView ? 'warningBlue' : 'warning'}
            message="Sensitive details like passwords etc. are not imported with integrations. Please re-enter and publish after importing."
          />
        )}

        {isConnectorCreated && isImported && (
          <Toast
            type="warningBlue"
            message="Some integrations were imported without sensitive details like passwords etc. Please re-enter and publish them."
          />
        )}

        {isImportDisable && (
          <Toast
            type={isSummaryView ? 'warningBlue' : 'warning'}
            message="Some Global variables have conflict, please resolve them to import"
          />
        )}
      </ModalContent>
      <ModalFooter>
        {!isSummaryView && _isNil(dependencyJson) && (
          <Button
            disabled={_isNil(fileIds) || _isEmpty(fileIds)}
            type="submit"
            onClick={handleValidateFileUploaded}
          >
            Continue
          </Button>
        )}

        {isSummaryView && !isImported && (
          <Button appearance="filled" onClick={handleBackBtn}>
            Go Back
          </Button>
        )}

        {isImported && !isImportSummaryView && (
          <Button
            appearance="filled"
            onClick={() => setIsImportSummaryView(true)}
          >
            View Summary
          </Button>
        )}

        {(isSummaryView || isImported || !_isNil(dependencyJson)) && (
          <Button
            disabled={isImportDisable}
            type="submit"
            onClick={handleContinueBtn}
          >
            {isImported
              ? 'Continue'
              : isSummaryView
              ? 'Continue with Import'
              : 'Continue to Summary'}
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
}
