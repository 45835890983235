import { ApolloError } from '@apollo/client';
import { PadBox } from '@bedrock-layout/padbox';
import { Stack } from '@bedrock-layout/stack';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  Spinner,
  TextAreaField,
  TextField,
  Typography,
  toasts,
  useCurrentLayer,
} from 'ui';

import { checksumWarningAtom } from '../../../atom';
import { currentWorkspaceDetailAtom } from '../../../pages/Workspace/atom';
import type { CheckSumEntityNames } from '../../../types';
import { handleGetCheckSumByEntityName } from '../../../utils/common';
import { ENTITY_ID } from '../../../utils/constant';
import { useApproveEntity } from '../hooks/graphql/useApproveEntity';
import { usePublishEntity } from '../hooks/graphql/usePublishEntity';
import { approveChangesFormSchema } from '../schema';
import type { ModalCommonProps } from '../type';
import {
  getChecksumMetaData,
  getEntityTypeForApi,
  getEntityTypeForChecksum,
} from '../utils/common';

type PublishModalFormData = {
  title: string;
  description?: string;
};

type PublishModalProps = ModalCommonProps & {
  defaultFormValues?: PublishModalFormData;
};

export function PublishModal({
  entityType,
  entityId,
  title = 'Publish the Changes',
  entityStatus,
  entityName = '',
  entitySubType = '',
  handleEntityUpdate,
  defaultFormValues,
}: PublishModalProps) {
  const [, setShowChecksumPopup] = useAtom(checksumWarningAtom);
  const [currentWorkspace] = useAtom(currentWorkspaceDetailAtom);

  const [isLoading, setIsLoading] = useState(false);

  const { close: closeModal } = useCurrentLayer();

  const approvalFlowEnabled = currentWorkspace?.approvalFlowEnabled ?? false;

  const [
    approveEntityQuery,
    { data: approveEntityData, error: approveEntityError },
  ] = useApproveEntity();

  const [
    publishEntityQuery,
    { data: publishEntityData, error: publishEntityError },
  ] = usePublishEntity(entityType);

  const { control, handleSubmit } = useForm<PublishModalFormData>({
    resolver: zodResolver(approveChangesFormSchema),
    defaultValues: defaultFormValues,
  });

  const handlePublishChanges = async (data: PublishModalFormData) => {
    try {
      setIsLoading(true);
      const checksumEntityName = getEntityTypeForChecksum(entityType);

      const checksum =
        handleGetCheckSumByEntityName(
          checksumEntityName as CheckSumEntityNames
        ) ?? '';

      const { title, description = '' } = data;

      if (approvalFlowEnabled || entityStatus === 'inreview') {
        const payload = {
          id: entityId,
          entityType: getEntityTypeForApi(entityType),
          comment: description,
          checksum,
        };

        await approveEntityQuery({
          variables: payload,
          fetchPolicy: 'no-cache',
        });
      } else {
        await publishEntityQuery({
          variables: {
            id: entityId,
            checksum,
            title,
            description,
          },
        });
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
      closeModal();
    }
  };

  useEffect(() => {
    if (
      typeof handleEntityUpdate === 'function' &&
      !_isNil(approveEntityData) &&
      !_isNil(approveEntityData.approve)
    ) {
      toasts.success(
        approveEntityData.approve.message ?? '',
        'entity approved'
      );

      handleEntityUpdate({
        ...approveEntityData.approve,
        entityPublished: true,
      });
    }
  }, [approveEntityData]);

  const methodName = useMemo(() => {
    switch (entityType) {
      case 'rules':
        return 'publishRule';
      case 'datasets':
        return 'publishDataSet';
      case 'workflow':
        return 'publishWorkflow';
      default:
        return null;
    }
  }, [entityType]);

  useEffect(() => {
    if (
      typeof handleEntityUpdate === 'function' &&
      !_isNil(publishEntityData) &&
      !_isNil(methodName) &&
      !_isNil(publishEntityData[methodName])
    ) {
      toasts.success(
        publishEntityData[methodName].message ?? '',
        'entity published'
      );

      handleEntityUpdate({
        ...publishEntityData[methodName],
        entityPublished: true,
      });
      closeModal();
    }
  }, [publishEntityData]);

  useEffect(() => {
    if (approveEntityError instanceof ApolloError) {
      const errorCode = approveEntityError?.graphQLErrors[0]?.extensions?.code;

      if (errorCode === 'checksum_mismatched') {
        setShowChecksumPopup({
          showPopup: true,
          metaData: getChecksumMetaData({
            entityId,
            entityName,
            entityType,
            entitySubType,
          }),
        });
      } else {
        toasts.error(approveEntityError.message, 'error');
      }

      if (
        entityType === ENTITY_ID.workflow &&
        typeof handleEntityUpdate === 'function'
      ) {
        handleEntityUpdate({ workflowNodesError: approveEntityError });
      }
    }
  }, [approveEntityError]);

  useEffect(() => {
    if (publishEntityError instanceof ApolloError) {
      const errorCode = publishEntityError?.graphQLErrors[0]?.extensions?.code;

      if (
        errorCode === 'approval_required' &&
        typeof handleEntityUpdate === 'function'
      ) {
        handleEntityUpdate({
          modalType: 'approvalFlowEnabled',
        });
      } else if (errorCode === 'checksum_mismatched') {
        setShowChecksumPopup({
          showPopup: true,
          metaData: getChecksumMetaData({
            entityId,
            entityName,
            entityType,
            entitySubType,
          }),
        });
      } else {
        toasts.error(publishEntityError.message, `error`);
      }

      if (
        entityType === ENTITY_ID.workflow &&
        typeof handleEntityUpdate === 'function'
      ) {
        handleEntityUpdate({ workflowNodesError: publishEntityError });
      }
    }
  }, [publishEntityError]);

  return (
    <Modal size="extraLarge" title={title} overflow="none">
      <form onSubmit={handleSubmit(handlePublishChanges)}>
        <ModalContent>
          <Stack as={PadBox} gutter="1.5rem" padding={['1.5rem', 0, '4rem', 0]}>
            <Stack gutter="0.8rem">
              <Typography name="heading3">Title</Typography>
              <TextField
                control={control}
                placeholder="Describe title"
                name="title"
                showErrorIcon={false}
                disabled={entityStatus !== 'draft' && entityStatus !== 'tested'}
              />
            </Stack>

            <Stack gutter="0.8rem">
              <Typography name="heading3">Describe changes</Typography>

              <TextAreaField
                control={control}
                placeholder="Explain in detail"
                name="description"
                rows={4}
              />
            </Stack>
          </Stack>
        </ModalContent>
        <ModalFooter>
          <Button onClick={closeModal} appearance="neutral">
            Cancel
          </Button>

          <Button type="submit">
            {isLoading ? <Spinner size="extraSmall" /> : <>Publish</>}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
}
