import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  Typography,
  useCurrentLayer,
} from 'ui';

type CustomAttrExitModalProps = {
  onClose?: () => void;
  onSaveAndClose?: (actionName: string) => void;
};

export function CustomAttrExitModal({
  onClose,
  onSaveAndClose,
}: CustomAttrExitModalProps) {
  const { close } = useCurrentLayer();

  const handleCloseAndLoose = () => {
    close();

    if (typeof onClose === 'function') {
      onClose();
    }
  };

  const handleSaveAndClose = () => {
    close();

    if (typeof onSaveAndClose === 'function') {
      onSaveAndClose('closeSheet');
    }
  };

  return (
    <Modal size="medium" title="Unsaved Changes">
      <ModalContent>
        <Typography>
          You have some unsaved changes. Are you sure you want to Close?
        </Typography>
      </ModalContent>
      <ModalFooter>
        <Button appearance="danger" onClick={handleCloseAndLoose}>
          Close &amp; Lose Changes
        </Button>

        <Button onClick={handleSaveAndClose} appearance="neutral">
          Save &amp; Close
        </Button>
      </ModalFooter>
    </Modal>
  );
}
