import { ReactNode } from 'react';
import { Control, useWatch } from 'react-hook-form';

import { useSendEventToGTM } from '../../../../../hooks/useSendEventToGTM';
import { SimpleRuleNodesModel } from '../models';
import { RuleCondition } from './RuleCondition';
import { RuleExcelCondition } from './RuleExcelCondition';
import { RuleGroup } from './RuleGroup';
import { RuleJsCondition } from './RuleJsCondition';
import { RuleSqlCondition } from './RuleSqlCondition';

export type RuleBlockProps = {
  ruleId: string;
  rule: SimpleRuleNodesModel;
  children: ReactNode;
  control?: Control<any>;
};

export type sendEventToGTMType = {
  action: string;
  element: string;
  actionName: string;
};

export const RuleBlock = ({
  rule,
  children,
  ruleId,
  control,
}: RuleBlockProps) => {
  const { sendEventToGTM } = useSendEventToGTM();

  const ruleName = useWatch({
    control,
    name: 'ruleName',
  });

  const handleSendEventToGTM = ({
    action = '',
    element = '',
    actionName = '',
  }: sendEventToGTMType) => {
    sendEventToGTM({
      event: 'rule',
      ruleId,
      ruleName,
      type: 'simpleRule',
      nec_source: '',
      action,
      element,
      action_name: actionName,
    });
  };

  if (rule.nodeType === 'group') {
    return (
      <RuleGroup
        rule={rule}
        ruleId={ruleId}
        control={control}
        handleSendEventToGTM={handleSendEventToGTM}
      >
        {children}
      </RuleGroup>
    );
  } else if (rule.nodeType === 'condition') {
    return (
      <RuleCondition
        rule={rule}
        ruleId={ruleId}
        handleSendEventToGTM={handleSendEventToGTM}
      />
    );
  } else if (rule.nodeType === 'sqlCondition') {
    return (
      <RuleSqlCondition
        rule={rule}
        ruleId={ruleId}
        handleSendEventToGTM={handleSendEventToGTM}
      />
    );
  } else if (rule.nodeType === 'jsCondition') {
    return (
      <RuleJsCondition
        rule={rule}
        ruleId={ruleId}
        handleSendEventToGTM={handleSendEventToGTM}
      />
    );
  } else if (rule.nodeType === 'excelCondition') {
    return (
      <RuleExcelCondition
        rule={rule}
        ruleId={ruleId}
        handleSendEventToGTM={handleSendEventToGTM}
      />
    );
  }

  return null;
};
