import { useAtom } from 'jotai';

import { siteConstantsAtom } from '../atom';
import { getTooltipText } from '../utils/common';

export function useGetAuthKeyList() {
  const [siteConstants] = useAtom(siteConstantsAtom);

  const authKeyList = [
    {
      title: 'Bearer Token',
      desc: getTooltipText(siteConstants, 'credentials', 'bearerToken'),
      id: 'bearerToken',
    },
    {
      title: 'API Key',
      desc: getTooltipText(siteConstants, 'credentials', 'apiKey'),
      id: 'ApiKey',
    },
    {
      title: 'Basic Auth',
      desc: getTooltipText(siteConstants, 'credentials', 'basicAuth'),
      id: 'basicAuth',
    },
    {
      title: 'OAuth 2',
      desc: getTooltipText(siteConstants, 'credentials', 'oAuth'),
      id: 'oAuth',
    },
  ];

  return {
    authKeyList,
  };
}
