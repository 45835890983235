import { z } from 'zod';

import { workspaceSettingsObject } from './constant';

const {
  WORKSPACE_NAME,
  APPROVAL_FLOW_ENABLED,
  WORKSPACE_THEME,
  WORKSPACE_THEME_ENABLED,
} = workspaceSettingsObject;

export const settingsFormSchema = z.object({
  [WORKSPACE_NAME]: z.string().min(1, 'Workspace name cannot be empty'),
  [APPROVAL_FLOW_ENABLED]: z.boolean().optional(),
  [WORKSPACE_THEME]: z.any(),
  [WORKSPACE_THEME_ENABLED]: z.any(),
});
