import { Inline } from '@bedrock-layout/inline';
import styled from 'styled-components';

export const EditorContainer = styled(Inline)`
  border-radius: 4px;
  border: 1px solid var(--color-gainsboro);
  font-size: 1.4rem;
  max-width: 100%;
  width: 100%;
  padding: 0.8rem 1.6rem;
  outline: 1px solid var(--color-solitude);
  background-color: var(--color-white);
`;
