import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import styled, { css } from 'styled-components';
import { Typography } from 'ui';

export const PathContainer = styled(PadBox)`
  min-block-size: 10rem;
  border: 1px solid var(--color-solitude);
  border-radius: 0.4rem;
`;

export const PathBlockProperty = styled.div`
  display: flex;
`;

export const PathBlockPropertyMajor = styled.div`
  flex: 1;
`;
export const PathBlockPropertyMinor = styled.div`
  flex-basis: 3rem;
  border-right: 1px solid var(--color-solitude);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PathBlockOrder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;
`;

export const PathHeader = styled(PadBox)`
  min-block-size: 2rem;
  border-bottom: 1px solid var(--color-solitude);
  display: flex;
  justify-content: space-between;
  background-color: var(--color-solitude);
`;

export const PathConditionStyled = styled(Inline)<{ hasSwitchField: boolean }>`
  padding: ${({ hasSwitchField }) =>
    hasSwitchField ? '3.6rem 1rem 1rem 1rem' : '1rem'};
`;

export const PopoverContainerStyled = styled.div`
  overflow-y: auto;
  max-block-size: 30rem;
  inline-size: 32rem;
`;

export const ContainerStyled = styled(PadBox)`
  min-block-size: 2.5rem;
  background-color: var(--color-solitudeSecondary);
  border-radius: 0.4rem;
  cursor: pointer;
  position: relative;
`;

export const PropertyContainer = styled(Inline)`
  inline-size: 10rem;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const RuleLauncherLabel = styled(Typography)`
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;

export const AddChildStyled = styled(Inline)<{ $disabled: boolean }>`
  background-color: var(--color-white);
  cursor: pointer;
  color: var(--color-primary1);

  ${({ $disabled = false }) =>
    $disabled &&
    css`
      cursor: none;
      color: var(--color-darkGray);
    `}
`;

export const OperatorStyled = styled(Inline)<{ operator: string }>`
  padding: 0.2rem 1rem;
  border-radius: 0.4rem;
  background-color: ${({ operator }) =>
    operator === 'and' ? 'var(--color-magnolia)' : 'var(--color-papayaWhip)'};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PathGroupContainer = styled(PadBox)`
  border-radius: 0.4rem;
  border: 1px solid gray;
  border-top: 1px dashed gray;
  border-bottom: 1px dashed gray;
`;

export const PathRhsContainer = styled.div`
  display: flex;
  justify-content: end;
  position: relative;
`;
