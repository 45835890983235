import type { ObservableQuery } from '@apollo/client';

import type { ConnectorAndPluginModel } from '../../types';
import type { GetConnectorsQueryResult } from '../hooks/useGetConnectors';
import { ConnectorCard } from './ConnectorCard';

export type ConnectorProps = {
  connector: ConnectorAndPluginModel;
  refetch: ObservableQuery<GetConnectorsQueryResult>['refetch'];
};

export function Connector({ connector, refetch }: ConnectorProps) {
  return <ConnectorCard connector={connector} refetch={refetch} />;
}
