import { PadBox } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import { UseControllerProps, UseFormSetValue } from 'react-hook-form';

import { StackAsItem } from '../../../../components/layouts/Stack.styled';
import { ruleEnvironmentAtom } from '../CreateRuleSheet/CreateRuleSheet';
import { TriggerForm } from './TriggerForm';

type TriggerProps = Omit<UseControllerProps, 'name'> & {
  setValue?: UseFormSetValue<any>;
};

export function Triggers({ control, setValue }: TriggerProps) {
  const [simpleRuleEnvironment] = useAtom(ruleEnvironmentAtom);

  return (
    <StackAsItem grow={1} gutter="1.6rem" as={PadBox} padding="1.6rem">
      {simpleRuleEnvironment === 'staging' && (
        <TriggerForm control={control} type="staging" setValue={setValue} />
      )}
      {simpleRuleEnvironment === 'production' && (
        <TriggerForm control={control} type="production" setValue={setValue} />
      )}
    </StackAsItem>
  );
}
