import { Inline } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import type { UseControllerProps, UseFormSetValue } from 'react-hook-form';
import { useWatch } from 'react-hook-form';
import {
  IconButton,
  Menu,
  MenuItem,
  TooltipReact,
  Typography,
  toasts,
} from 'ui';

import { isRuleReadOnlyAtom, ruleLimitsConfigAtom } from '../../..';
import { siteConstantsAtom } from '../../../../../atom';
import { AddIcon } from '../../../../../components/icons/AddIcon';
import { useSendEventToGTM } from '../../../../../hooks/useSendEventToGTM';
import { generateUid, getTooltipText } from '../../../../../utils/common';
import {
  getDefaultValueByDataTypeForRhsOutput,
  getRequiredKey,
} from '../../../utils/common';
import { decisionTableNodeIdAtom } from '../DecisionTable';

type ResultAddMenuProps = Omit<UseControllerProps, 'name'> & {
  setValue: UseFormSetValue<any>;
  index: number;
  total: number;
  setIndexToObserve: (index: number) => void;
};

export function ResultAddMenu({
  setValue,
  index,
  control,
  total,
  setIndexToObserve,
}: ResultAddMenuProps) {
  const results = useWatch({ control, name: 'results' });
  const rows = useWatch({ control, name: 'rows' });

  const [isRuleReadOnly] = useAtom(isRuleReadOnlyAtom);
  const [siteConstants] = useAtom(siteConstantsAtom);
  const [limitConfig] = useAtom(ruleLimitsConfigAtom);

  const [ruleId] = useAtom(decisionTableNodeIdAtom);

  const { sendEventToGTM } = useSendEventToGTM();

  const menuItems = [
    { value: 'boolean', label: 'Boolean' },
    { value: 'numeric', label: 'Numeric' },
    { value: 'string', label: 'String' },
    { value: 'date', label: 'Date' },
    { value: 'dateTime', label: 'DateTime' },
    { value: 'list', label: 'List' },
    {
      value: 'json',
      label: 'JSON',
      tooltip: getTooltipText(siteConstants, 'rules', 'customJsonRule'),
    },
    {
      value: 'jsFormula',
      label: 'JS Code',
      tooltip: getTooltipText(siteConstants, 'rules', 'customJSRule'),
    },
    {
      value: 'excelFormula',
      label: 'Formula',
      tooltip: getTooltipText(siteConstants, 'rules', 'customExcelRule'),
    },
  ];

  const ruleName = useWatch({
    control,
    name: 'ruleName',
  });

  const handleResultMenuOpen = () => {
    sendEventToGTM({
      event: 'rule',
      ruleId,
      ruleName,
      type: 'decisionTable',
      action: 'add',
      element: 'result',
      action_name: '',
      nec_source: '',
    });
  };

  const launcher = (
    <IconButton disabled={isRuleReadOnly} onClick={handleResultMenuOpen}>
      <AddIcon color="var(--color-saddleBrown)" />
    </IconButton>
  );

  const handleAddResult = (dataType: string) => {
    if (!_isEmpty(limitConfig) && total < limitConfig.maxOutputVars.value) {
      const newResultId = generateUid('result_');

      sendEventToGTM({
        event: 'rule',
        ruleId,
        ruleName,
        type: 'decisionTable',
        action: 'selection',
        element: 'result',
        action_name: dataType,
        nec_source: '',
      });

      rows?.forEach((row: any, i: number) => {
        const newOutputId = generateUid('output_');

        const rowKey = getRequiredKey(row, ['id']);

        const currentGroup = row[rowKey];

        const updatedRuleResult = currentGroup.ruleResult;
        const ruleResultToInsert = {
          [newOutputId]: {
            dataType,
            value: getDefaultValueByDataTypeForRhsOutput(dataType),
          },
        };

        updatedRuleResult.splice(index + 1, 0, ruleResultToInsert);

        if (!_isNil(currentGroup) && !_isEmpty(currentGroup)) {
          setValue(`rows.${i}.${rowKey}.ruleResult`, updatedRuleResult);
        }
      });

      const resultObjToInsert = {
        [newResultId]: {
          dataType,
          keyName: 'keyName',
        },
      };
      results.splice(index + 1, 0, resultObjToInsert);

      setValue('results', results);

      setIndexToObserve(index + 1);
    } else if (!_isEmpty(limitConfig.maxOutputVars.message)) {
      toasts.info(limitConfig.maxOutputVars.message, 'info');
    }
  };

  return (
    <Menu
      launcher={launcher}
      onMenuItemClick={(value) => {
        handleAddResult(value);
      }}
      disabled={isRuleReadOnly}
    >
      {menuItems.map((menuItem) => (
        <MenuItem value={menuItem.value} key={menuItem.value}>
          <Inline align="center" gutter={8}>
            <Typography>{menuItem.label}</Typography>

            {!_isNil(menuItem.tooltip) && (
              <TooltipReact id={`dataType-${menuItem.value}`}>
                <Typography>{menuItem.tooltip}</Typography>
              </TooltipReact>
            )}
          </Inline>
        </MenuItem>
      ))}
    </Menu>
  );
}
