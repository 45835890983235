import { useAtom } from 'jotai';
import { Typography } from 'ui';

import type { EnvButtonsCount } from '../../../../../types';
import {
  activePanelDatasetAtom,
  vcListTabIndexDatasetAtom,
} from '../../../../DataSets/atom/atom';
import {
  activePanelWorkflowAtom,
  vcListTabIndexWorkflowAtom,
} from '../../../../Workflow/atoms/atoms';
import { activePanelAtom, vcListTabIndexRuleAtom } from '../../atom/atom';
import {
  EnvButtonContainer,
  EnvButtonLayer,
  EnvContainer,
  StatusDisplay,
  StatusNumberDisplay,
} from './EnvButtons.styled';

type EnvButtonsProps = {
  count: EnvButtonsCount;
  entityName: 'rule' | 'workflow' | 'dataset';
};

export function EnvButtons({ count, entityName }: EnvButtonsProps) {
  const [, setActivePanelWf] = useAtom(activePanelWorkflowAtom);
  const [, setActivePanelRule] = useAtom(activePanelAtom);
  const [, setActivePanelDS] = useAtom(activePanelDatasetAtom);

  const [, setVcListTabIndexRule] = useAtom(vcListTabIndexRuleAtom);
  const [, setVCListTabIndexWf] = useAtom(vcListTabIndexWorkflowAtom);
  const [, setVCListTabIndexDS] = useAtom(vcListTabIndexDatasetAtom);

  const handleSetActivePanelByEntity = (panelIndex: number = 0) => {
    switch (entityName) {
      case 'rule':
        setActivePanelRule('versionControl');
        setVcListTabIndexRule(panelIndex);
        break;
      case 'dataset':
        setActivePanelDS('versionControl');
        setVCListTabIndexDS(panelIndex);
        break;
      case 'workflow':
      default:
        setActivePanelWf('versionControl');
        setVCListTabIndexWf(panelIndex);
    }
  };

  return (
    <EnvContainer>
      <EnvButtonLayer>
        <Typography>Staging </Typography>
        <EnvButtonContainer
          type="button"
          onClick={() => handleSetActivePanelByEntity(0)}
          $type="draft"
        >
          <StatusDisplay> Draft </StatusDisplay>
          <StatusNumberDisplay> {count.draft} </StatusNumberDisplay>
        </EnvButtonContainer>
        <EnvButtonContainer
          type="button"
          onClick={() => handleSetActivePanelByEntity(1)}
          $type="in-review"
        >
          <StatusDisplay $type="in-review"> In Review </StatusDisplay>
          <StatusNumberDisplay $type="in-review">
            {' '}
            {count.inReview}{' '}
          </StatusNumberDisplay>
        </EnvButtonContainer>
      </EnvButtonLayer>

      <EnvButtonLayer>
        <Typography>Production </Typography>
        <EnvButtonContainer
          type="button"
          onClick={() => handleSetActivePanelByEntity(2)}
          $type="publish"
        >
          <StatusDisplay $type="publish"> Publish </StatusDisplay>
          <StatusNumberDisplay $type="publish">
            {' '}
            {count.live}{' '}
          </StatusNumberDisplay>
        </EnvButtonContainer>
      </EnvButtonLayer>
    </EnvContainer>
  );
}
