import { BiChevronDown } from 'react-icons/bi';
import { Typography } from 'ui';

import {
  FilterDropdownContainer,
  SelectedFilterCount,
  SelectedFilterCountContainer,
} from '../listing.styled';

export const renderFilterLauncher = (name: string, count: number) => {
  return (
    <FilterDropdownContainer gutter=".8rem" align="center">
      {count > 0 && (
        <SelectedFilterCountContainer>
          <SelectedFilterCount>{count}</SelectedFilterCount>
        </SelectedFilterCountContainer>
      )}

      <Typography>{name}</Typography>
      <BiChevronDown />
    </FilterDropdownContainer>
  );
};
