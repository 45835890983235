import { Stack } from '@bedrock-layout/stack';
import styled from 'styled-components';

export const StyledContainer = styled(Stack)`
  min-block-size: 22rem;
  padding: 1.5rem 0rem;

  .label-style {
    text-transform: capitalize;
  }
`;
