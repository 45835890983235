import { Inline, PadBox } from '@bedrock-layout/primitives';
import styled, { css } from 'styled-components';

export const BlockStyled = styled(PadBox)`
  border-inline: 1.5px solid var(--color-darkGray);
  border-block: 1px dashed var(--color-lightGray);
  min-block-size: 5rem;
  min-inline-size: 40rem;
  border-radius: 6px;
  position: relative;

  &:before {
    content: '';
    inline-size: 1rem;
    block-size: 1rem;
    background-color: var(--color-white);
    border-block-start: 1px solid var(--color-darkGray);
    border-inline-start: 1px solid var(--color-darkGray);
    position: absolute;
    inset-inline-end: -6.5px;
    inset-block-start: 50%;
    transform: translateY(-50%) rotate(135deg);
  }

  &:after {
    content: '';
    inline-size: 1rem;
    block-size: 1rem;
    background-color: var(--color-white);
    border-block-start: 1px solid var(--color-darkGray);
    border-inline-start: 1px solid var(--color-darkGray);
    position: absolute;
    inset-block-start: 50%;
    inset-inline-start: -6px;
    transform: translateY(-50%) rotate(315deg);
    z-index: 0;
  }
`;

export const AddChildStyled = styled(Inline)<{ $disabled: boolean }>`
  cursor: pointer;
  color: var(--color-primary1);
  background-color: var(--color-white);

  ${({ $disabled = false }) =>
    $disabled &&
    css`
      cursor: default;
      color: var(--color-darkGray);
    `}
`;
