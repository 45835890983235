import { Inline, Stack } from '@bedrock-layout/primitives';
import styled from 'styled-components';

export const Form = styled.form`
  block-size: 100vh;
  display: flex;
  flex-direction: column;
`;

export const FormFooter = styled(Inline)`
  padding: 1rem;
  border-top: 1px solid var(--color-solitude);
  margin-top: auto;
  position: sticky;
  bottom: 0;
`;

export const FormContainer = styled(Stack)`
  padding: 1rem;
  flex: 1;
`;
