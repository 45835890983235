import { useAtom } from 'jotai';
import type { UseControllerProps, UseFormSetValue } from 'react-hook-form';
import { useWatch } from 'react-hook-form';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { IconButton, Menu, MenuItem, Typography } from 'ui';

import { isRuleReadOnlyAtom } from '../../../index';
import { sendEventToGTMType } from '../../../types';
import { deleteDecisionTableNodes } from '../../../utils/decisionTable';
import { decisionTableNodesAtom } from '../DecisionTable';

type RowActionsProps = Omit<UseControllerProps, 'name'> & {
  setValue: UseFormSetValue<any>;
  index: number;
  rowKey: string;
  addRowAtIndex: (index: number) => void;
  duplicateRowHandler: (index: number) => void;
  isLast: boolean;
  handleSendEventToGTM?: (obj: sendEventToGTMType) => void;
};

export function RowActions({
  control,
  setValue,
  index,
  rowKey,
  addRowAtIndex,
  duplicateRowHandler,
  isLast,
  handleSendEventToGTM,
}: RowActionsProps) {
  const rows = useWatch({ name: 'rows', control });

  const [isRuleReadOnly] = useAtom(isRuleReadOnlyAtom);
  const [, setNodes] = useAtom(decisionTableNodesAtom);

  const menuItems = [
    {
      label: 'Add Row Above',
      value: 'addAbove',
    },
    {
      label: 'Add Row Below',
      value: 'addBelow',
    },
    {
      label: 'Delete',
      value: 'delete',
    },
  ];

  const handleRowDelete = () => {
    const newRows = rows?.filter(
      (row: any, rowIndex: number) => rowIndex !== index
    );

    setValue('rows', newRows);

    setNodes((prev) => deleteDecisionTableNodes(rowKey, prev));

    if (typeof handleSendEventToGTM === 'function') {
      handleSendEventToGTM({
        action: 'delete',
        element: 'row',
        actionName: '',
      });
    }
  };

  const launcher = (
    <IconButton>
      <BiDotsVerticalRounded size={22} color={'var(--color-lightGray)'} />
    </IconButton>
  );

  return (
    <Menu
      launcher={launcher}
      onMenuItemClick={(value) => {
        if (value === 'delete' && !isLast) {
          handleRowDelete();
        } else if (value === 'addAbove') {
          addRowAtIndex(index);
        } else if (value === 'addBelow') {
          addRowAtIndex(index + 1);
        } else if (value === 'duplicate') {
          duplicateRowHandler(index);
        }
      }}
      disabled={isRuleReadOnly}
    >
      {menuItems.map((menuItem) => (
        <MenuItem value={menuItem.value} key={menuItem.value}>
          <Typography>{menuItem.label}</Typography>
        </MenuItem>
      ))}
    </Menu>
  );
}
