import { Stack } from '@bedrock-layout/stack';
import styled from 'styled-components';

export const CreateDataSetButton = styled.button`
  border-color: var(--color-whiteSmoke);
  background-color: var(--color-whiteSmoke);
  color: var(--color-black);
  font-size: 1.4rem;
  padding: 0.6rem 1.2rem;
  cursor: pointer;
  border-radius: 0.4rem;
  border: none;

  &.btn-disabled-interceptor {
    background: var(--color-whiteSmoke) !important;
    color: var(--color-black) !important;
    cursor: progress !important;
  }
`;

export const AddSourceList = styled(Stack)`
  max-block-size: 18rem;
  overflow-y: auto;
`;
