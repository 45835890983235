import styled from 'styled-components';

export const FormContainer = styled.form`
  @media (max-width: 360px) {
    padding: 0px 20px;
  }

  @media (min-width: 361px) and (max-width: 480px) {
    padding: 0px 30px;
  }
`;
