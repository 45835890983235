import { useEffect, useState } from 'react';
import { DateTimePickerInput } from 'ui';

import { customParseDate } from '../../pages/Rules/utils/common';

type CalenderIconPickerProps = {
  onPick?: (val: any) => void;
  value: any;
  dataType: string;
  disabled?: boolean;
};

export function CalenderIconPicker({
  value,
  onPick,
  dataType,
  disabled = false,
}: CalenderIconPickerProps) {
  const [date, setDate] = useState(new Date(Date.now()));

  useEffect(() => {
    try {
      if (value !== '') {
        const formattedDate = customParseDate(value);

        setDate(formattedDate);
      }
    } catch {}
  }, [value]);

  return (
    <DateTimePickerInput
      appendText=""
      value={date}
      showIconInput
      showTimeInput={dataType === 'dateTime'}
      disabled={disabled}
      onChange={(v) => {
        if (typeof onPick === 'function') {
          onPick(v);
        }
      }}
      showIcon={false}
    />
  );
}
