import { gql, useMutation } from '@apollo/client';

export const saveRuleset = gql`
  mutation CreateRule(
    $name: String!
    $type: String!
    $description: String
    $settings: RuleSettingsInput
    $ruleChain: Map
    $ruleSetPolicy: String
    $firstRuleChain: String
    $ruleSet: [String!]
    $customInput: Map
    $dependencyMap: [DependencyInput!]
  ) {
    createRule(
      input: {
        name: $name
        type: $type
        description: $description
        settings: $settings
        ruleChain: $ruleChain
        ruleSetPolicy: $ruleSetPolicy
        firstRuleChain: $firstRuleChain
        ruleSet: $ruleSet
        customInput: $customInput
        dependencyMap: $dependencyMap
      }
    ) {
      id
      name
      type
      staticUrl
      checksum
    }
  }
`;

type RuleSetVariablesModel = {};

export function useSaveRuleset() {
  return useMutation<any, RuleSetVariablesModel>(saveRuleset);
}
