import { ObservableQuery } from '@apollo/client';
import { Inline } from '@bedrock-layout/primitives';
import { createRef } from 'react';
import { Menu, MenuItem, PopoverMethods, Typography, useLayer } from 'ui';

import { handleSetCheckSumByEntityName } from '../../../../../utils/common';
import { GetConnectorsQueryResult } from '../../hooks/useGetConnectors';
import { DeleteConnectorModal } from '../DeleteConnectorModal';
import { ConnectorMenuLauncher } from './ConnectorMenuLauncher';

type ConnectorMenuProps = {
  openConnectorFormSheet: () => void;
  connectorId: string;
  isEditDisabled: boolean;
  isDeleteDisabled: boolean;
  refetch: ObservableQuery<GetConnectorsQueryResult>['refetch'];
  checksum: string;
};

export function ConnectorMenu({
  openConnectorFormSheet,
  connectorId,
  refetch,
  isEditDisabled,
  isDeleteDisabled,
  checksum,
}: ConnectorMenuProps) {
  const popoverMethods = createRef<PopoverMethods>();

  const { openWithProps: openConnectorDeleteModal } = useLayer(
    <DeleteConnectorModal refetch={refetch} />
  );

  const editClickHandler = () => {
    popoverMethods.current?.hide();

    openConnectorFormSheet();
  };

  const deleteClickHandler = (connectorId: string) => {
    popoverMethods.current?.hide();

    handleSetCheckSumByEntityName('integrations', checksum);

    openConnectorDeleteModal({
      connectorId,
    });
  };

  const launcher = (
    <Inline>
      <ConnectorMenuLauncher />
    </Inline>
  );

  const items: any = [];

  if (!isEditDisabled) {
    items.push({
      value: 'Edit',
      label: 'Edit',
    });
  }

  if (!isDeleteDisabled) {
    items.push({
      value: 'Delete',
      label: 'Delete',
    });
  }

  return (
    <Menu
      onMenuItemClick={(value) => {
        if (value === 'Edit') {
          return editClickHandler();
        } else if (value === 'Delete') {
          return deleteClickHandler(connectorId);
        }

        return null;
      }}
      placement="bottom-start"
      launcher={launcher}
      ref={popoverMethods}
    >
      {items.map((item: any) => (
        <MenuItem value={item.value} key={item.value}>
          <Typography>{item.label}</Typography>
        </MenuItem>
      ))}
    </Menu>
  );
}
