import { Inline } from '@bedrock-layout/primitives';
import styled from 'styled-components';

export const TestNodeFooterContainer = styled(Inline)`
  bottom: 0;
  position: sticky;
  left: 0;
  right: 0;
  border-top: solid var(--color-solitude);
  background-color: var(--color-white);
`;
