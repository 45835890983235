import { gql, useMutation } from '@apollo/client';

const deleteRuleMutation = gql`
  mutation deleteRule($id: String!, $checksum: String!) {
    deleteRule(id: $id, checksum: $checksum)
  }
`;

export function useDeleteRule() {
  return useMutation(deleteRuleMutation);
}
