import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { useAtom } from 'jotai';
import { Control, UseFormSetValue } from 'react-hook-form';
import { CheckboxField, Dataset, TextField, Typography } from 'ui';

import { ResponseCaching } from '../../../../../components/ResponseCaching/ResponseCaching';
import { isWorkflowReadOnlyAtom, workflowIdAtom } from '../../../atoms/atoms';

type RestApiSettingsProps = {
  control?: Control<any>;
  name: string;
  setValue: UseFormSetValue<any>;
  dataset: Record<string, Dataset>;
};

export function RestApiSettings({
  control,
  name,
  setValue,
  dataset,
}: RestApiSettingsProps) {
  const [isWorkflowReadOnly] = useAtom(isWorkflowReadOnlyAtom);
  const [workflowId] = useAtom(workflowIdAtom);

  const responseParams = {
    type: 'workflow',
    entityId: workflowId ?? undefined,
  };

  return (
    <PadBox padding="2rem">
      <Stack gutter="1rem">
        <Inline align="center" gutter={8}>
          <Typography>Timeout after (s)</Typography>
          <TextField
            name={`${name}.timeout`}
            type="number"
            size="small"
            control={control}
            disabled={isWorkflowReadOnly}
          />
        </Inline>
        <Inline align="center" gutter={8}>
          <Typography>Continue on error?</Typography>
          <CheckboxField
            name={`${name}.errorContinue`}
            control={control}
            disabled={isWorkflowReadOnly}
            appearance="switch"
            useId={`${name}.errorContinue`}
          />
        </Inline>

        <ResponseCaching
          name="settings.cache"
          control={control}
          setValue={setValue}
          dataset={dataset}
          responseParams={responseParams}
          disabled={isWorkflowReadOnly}
        />
      </Stack>
    </PadBox>
  );
}
