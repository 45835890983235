import { ObservableQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useLayer } from 'ui';

import type { PluginParamsFieldKeys, Plugin as PluginType } from '../../types';
import type { GetConnectorsQueryResult } from '../hooks/useGetConnectors';
import { PluginCard } from './PluginCard';
import { PluginFormSheet } from './PluginFormSheet';

export type PluginModel = PluginType & {
  schema: {
    integration: {
      params: Record<string, PluginParamsFieldKeys>;
    };
  };
  checksum: string;
};

export type PluginProps = {
  plugin: PluginModel;
  refetch: ObservableQuery<GetConnectorsQueryResult>['refetch'];
};

export function Plugin({ plugin, refetch }: PluginProps) {
  const params = useParams();
  const navigate = useNavigate();
  const { open: openPluginFormSheet } = useLayer(
    <PluginFormSheet
      plugin={plugin}
      refetch={refetch}
      currentStage={params.stage}
    />
  );

  useEffect(() => {
    if (params.actionId === plugin.id) {
      openPluginFormSheet();
    }
  }, [JSON.stringify(params)]);

  const handlePluginClickEvent = () => {
    navigate(`/integrations/${plugin.id}`);
  };

  return (
    <PluginCard
      plugin={plugin}
      connectAppearance="new"
      onClick={handlePluginClickEvent}
    />
  );
}
