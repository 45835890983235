import { memo } from 'react';

import { AddNodeLarge } from './AddNodeLarge';
import { AddNodeMd } from './AddNodeMd';
import { LoopDoneNode } from './LoopDoneNode';

export const AddNode = memo(({ data, isConnectable, id }: any) => {
  if (data.nodeType === 'addNodeMd') {
    return <AddNodeMd id={id} data={data} isConnectable={isConnectable} />;
  }

  if (data.nodeType === 'addNodeLg') {
    return <AddNodeLarge id={id} data={data} isConnectable={isConnectable} />;
  }

  if (data.nodeType === 'loopDoneNode') {
    return <LoopDoneNode id={id} data={data} isConnectable={isConnectable} />;
  }

  return null;
});

AddNode.displayName = 'AddNode';
