import { gql, useMutation } from '@apollo/client';

import type { SaveAuthConfigKeys } from '../../../../types';
import type { CreateAuthConfigKeyResult } from '../models';

const saveAuthConfigKey = gql`
  mutation saveAuthKey(
    $name: String!
    $type: CredentialTypes!
    $value: Map!
    $authType: AuthenticationTypes!
  ) {
    saveAuthConfigKey: createCredential(
      input: { name: $name, type: $type, value: $value, authType: $authType }
    ) {
      id
      type
      name
      value
      checksum
      isValid
      authCodeUrl
    }
  }
`;

export function useSaveAuthConfigKey() {
  return useMutation<CreateAuthConfigKeyResult, SaveAuthConfigKeys>(
    saveAuthConfigKey
  );
}
