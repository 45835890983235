import '@bedrock-layout/css/lib/bedrock-layout.min.css';
import { useGetGoogleFont, useLocalStorageObserve } from 'hooks';
import _isNil from 'lodash/isNil';
import { ReactNode, useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';

import { LayerProvider } from '../Layer';
import { Toaster } from '../Toast/Toaster';
import { GlobalStyles } from './GlobalStyles';

export type UIProviderProps = {
  children: ReactNode;
};

type ColorPropType = {
  name: string;
  key: string;
  value: string;
};

type LocalThemeType = {
  title: string;
  logoUrl: string;
  favicon: string;
  baseFontFamily: string;
  baseFontSize?: number;
  colors: Record<string, ColorPropType[]>;
};

const handleSetTheme = (theme: LocalThemeType) => {
  const newTheme: any = {
    palette: {},
    font: {
      family: theme.baseFontFamily ?? 'Lato',
      size: theme.baseFontSize,
    },
  };

  Object.keys(theme.colors).forEach((col) => {
    const appendString = col + '__';
    theme.colors[col].forEach((c) => {
      if (!_isNil(newTheme.palette)) {
        newTheme.palette[appendString + c.key] = c.value;
      }
    });
  });

  return newTheme;
};

export function UIProvider({ children }: UIProviderProps) {
  const defaultTheme: any = {
    palette: {},
    font: {
      family: 'Lato',
    },
  };

  const theme = useLocalStorageObserve('local-theme');
  const [parsedTheme, setParsedTheme] = useState<LocalThemeType | null>(null);

  const [currentTheme, setCurrentTheme] = useState<any>(defaultTheme);

  useGetGoogleFont(parsedTheme?.baseFontFamily ?? 'Lato');

  useEffect(() => {
    if (!_isNil(theme) && theme !== '') {
      try {
        const pTheme = JSON.parse(theme);
        setParsedTheme(pTheme);
        setCurrentTheme(handleSetTheme(pTheme));
      } catch {}
    }
  }, [JSON.stringify(theme)]);

  return (
    <ThemeProvider theme={currentTheme}>
      <GlobalStyles />
      <LayerProvider>{children}</LayerProvider>
      <Toaster />
    </ThemeProvider>
  );
}
