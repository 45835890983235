import { gql, useMutation } from '@apollo/client';

import type { RuleUpdateResponseModel } from '../../components/SimpleRule/models';

const updateTriggersMutation = gql`
  mutation updateRule(
    $id: String!
    $settings: RuleSettingsInput
    $checksum: String!
    $editMode: Boolean
    $dataSetId: String
    $dependencyMap: [DependencyInput!]
  ) {
    updateRule(
      input: {
        id: $id
        settings: $settings
        checksum: $checksum
        editMode: $editMode
        dataSetId: $dataSetId
        dependencyMap: $dependencyMap
      }
    ) {
      id
      checksum
      status
      isLive
      publishedAt
      approvalInfo {
        title
        description
        approvers
      }
      versionInfo {
        currentVersion
        currentLiveVersion
        lastVersion
      }
      dependencyMap {
        id
        name
        type
        version
        commitTitle
        subType
        nodeId
      }
    }
  }
`;

export function useUpdateTriggers() {
  return useMutation<RuleUpdateResponseModel>(updateTriggersMutation);
}
