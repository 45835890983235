import { Inline } from '@bedrock-layout/primitives';
import styled from 'styled-components';

export const CreateConnector = styled(Inline)`
  background-color: var(--color-white);
  cursor: pointer;
  color: var(--color-primary1);
  padding: 0.5rem 1rem;
  position: sticky;
  bottom: 0;
`;

export const ActionIcon = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 0.5rem;
  block-size: 4rem;
  inline-size: 4rem;
  border-radius: 0.4rem;
  border: 1px solid var(--color-solitude);
  background: var(--color-white);
  cursor: pointer;
  position: relative;

  &.btn-disabled-interceptor {
    background: var(--color-white) !important;
    color: var(--color-black) !important;
    cursor: progress !important;
  }
`;

export const ErrorIcon = styled.div`
  position: absolute;
  top: -1rem;
  right: -1rem;
`;
