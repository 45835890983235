import { Inline } from '@bedrock-layout/primitives';
import styled from 'styled-components';

export const SearchContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  padding: 1.6rem;
`;

export const DataSourceListItem = styled(Inline)`
  cursor: pointer;
  word-break: break-word;
`;

export const CreateDataSourceContainer = styled.div`
  position: sticky;
  bottom: 0;
  z-index: 1;
  padding: 0.4rem 1.6rem;
`;

export const EditDatasetPill = styled(Inline)`
  min-inline-size: 4rem;
  border-radius: 0.8rem;
  cursor: pointer;
  background: var(--color-white);
  outline: 1px solid var(--color-lightGray);
  position: relative;
  padding: 0.8rem 1.2rem;
`;

export const ErrorIcon = styled.div`
  position: absolute;
  top: -1rem;
  right: -0.5rem;
`;
