import { Inline } from '@bedrock-layout/primitives';
import styled from 'styled-components';

export const WorkflowSheetFormStyled = styled.form`
  block-size: 100vh;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow-y: auto;
`;

export const WorkflowSheetTabContentStyled = styled.div`
  flex: 1;
  position: relative;
  overflow-y: auto;
`;

export const SheetFooterStyled = styled(Inline)`
  border-top: 1px solid var(--color-lightGray);
  background-color: var(--color-white);
  justify-content: end;
  padding: 0.8rem;
  margin-top: auto;
`;
