import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import styled, { css } from 'styled-components';
import { Typography } from 'ui';

type TypographyContainerProps = {
  isCompleted?: boolean;
};

type SubStepContainerProps = {
  isCompleted?: boolean;
};

type TodoContainerProps = {
  isOpened: boolean;
};

export const TodoContainer = styled(PadBox)<TodoContainerProps>`
  background-color: ${({ isOpened }) =>
    isOpened ? 'var(--color-primary2)' : 'var(--color-white)'};
  padding: 0rem 1.6rem;
  border-radius: 0.8rem;

  &:hover {
    border-radius: 0.5rem;
    cursor: pointer;
  }
`;

export const NextStepContainer = styled.div`
  margin-inline-start: auto;
`;

export const WigetsContainer = styled(Stack)`
  display: flex;
  gap: 2rem;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

export const TypographyContainer = styled(Stack)<TypographyContainerProps>`
  padding: 1rem;
  text-decoration: ${({ isCompleted = false }) =>
    isCompleted ? 'line-through var(--color-darkGray)' : 'none'};

  &:hover {
    border-radius: 0.5rem;
    background: none;
    cursor: ${({ isCompleted = false }) => (isCompleted ? 'auto' : 'pointer')};
  }
`;

export const StepsContainer = styled(Inline)`
  gap: 2rem;
  align-items: center;
  padding: 1rem;
`;

export const StepsContainerLeft = styled(Inline)`
  inline-size: 80%;
  align-items: center;
`;
export const StepsContainerRight = styled(Inline)`
  inline-size: 20%;
  align-items: center;
  justify-content: flex-end;
  gap: 4rem;
`;

export const SubTaskNumbering = styled(Typography)`
  inline-size: 2rem;
  height: 2rem;
  line-height: 1.7rem;
  border-radius: 10rem;
  background: var(--color-whiteSmoke);
  text-align: center;
`;

export const SubStepsContainer = styled(Inline)<SubStepContainerProps>`
  gap: 2rem;
  -webkit-box-align: center;
  align-items: center;
  padding: 0rem 1rem;
  border: 1px solid var(--color-whiteSmoke);
  margin-bottom: 1rem;
  color: var(--color-darkGray);
  border-radius: 0.5rem;

  &:hover {
    ${({ isCompleted = false }) =>
      isCompleted
        ? css`
            cursor: 'not-allowed';
            background: 'var(--color-whiteSmoke)';
            border: '1px solid var(--color-whiteSmoke)';
          `
        : css`
            cursor: 'pointer';
            background: 'var(--color-hoverBlue)';
            border: '1px solid var(--color-lightBlue)';
          `}
  }
`;

export const SubTaskContainer = styled(PadBox)`
  padding: 1rem;
  border-radius: 0.8rem;
  border: 1px solid var(--color-whiteSmoke);
  margin-bottom: 2rem;
  background: var(--color-white);
`;

export const SubStepsContainerLeft = styled(Inline)`
  inline-size: 80%;
  align-items: center;
`;
export const SubStepsContainerRight = styled(Inline)`
  inline-size: 20%;
  align-items: center;
  justify-content: flex-end;
  gap: 4rem;
`;
