import { PadBox } from '@bedrock-layout/padbox';
import { Stack } from '@bedrock-layout/stack';
import styled from 'styled-components';

export const SaveButtonContainer = styled(PadBox)`
  position: sticky;
  bottom: 0;
  width: 100%;
  background-color: var(--color-white);
`;

export const FormFieldsContainer = styled(PadBox)`
  flex-grow: 1;
`;

export const FormFieldsStack = styled(Stack)`
  overflow-y: auto;
`;

export const WhitelistIpsContainer = styled(PadBox)`
  background-color: var(--color-blueChalk);
  border-radius: 4px;
`;
