import { useEffect, useRef } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

import { ENTITY_ID } from '../utils/constant';

export type EntityType = keyof typeof ENTITY_ID;

type UseLayerCloseOnPathProps = {
  entityName: EntityType;
  onRouteChange?: () => void;
};

export function useLayerCloseOnPath({
  entityName,
  onRouteChange,
}: UseLayerCloseOnPathProps) {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const previousLocationRef = useRef(location.pathname);

  useEffect(() => {
    if (location.pathname !== previousLocationRef.current) {
      if (typeof onRouteChange === 'function') {
        onRouteChange();
      }
    }

    previousLocationRef.current = location.pathname;

    return () => {
      if (
        ['rules', 'datasets', 'workflow'].includes(entityName) &&
        searchParams.get('type') === 'create'
      ) {
        if (typeof onRouteChange === 'function') {
          onRouteChange();
        }
      }
    };
  }, [location.pathname, searchParams]);
}
