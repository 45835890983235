export const entityActionOptions = [
  {
    label: 'Create',
    value: 'create',
  },
  {
    label: 'Use Existing',
    value: 'useExisting',
  },
  {
    label: 'Replace',
    value: 'replace',
  },
  {
    label: 'None',
    value: 'none',
  },
];

// export const
