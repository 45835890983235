import React from 'react';
import { Spinner } from 'ui';

import { RuleLoaderStyled } from './RuleLoader.styled';

export function RuleLoader() {
  return (
    <RuleLoaderStyled>
      <Spinner />
    </RuleLoaderStyled>
  );
}
