import { Inline } from '@bedrock-layout/primitives';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _isUndefined from 'lodash/isUndefined';
import { useEffect, useState } from 'react';
import useDrivePicker from 'react-google-drive-picker';
import type { Control, UseFormSetValue } from 'react-hook-form';
import { useWatch } from 'react-hook-form';
import {
  Button,
  FileItemType,
  FileList,
  Image,
  TextButton,
  Typography,
} from 'ui';

import { StackAsItem } from '../../../components/layouts/Stack.styled';
import { useAxiosPrivate } from '../../../hooks';
import { RuleEnvironment, envMap } from '../../../utils/constant';
import { ConnectorAndPluginModel } from '../../Integrations/types';
import { useGetGSheetFiles } from '../hooks/useGetGSheetFiles';
import { CreateDataSetFormValues } from '../types';
import {
  FileBodyContent,
  FileHeader,
  FileItem,
  SpreadsheetColumn,
  WorksheetColumn,
} from './FileExplorer.styled';

type FileExplorerProps = {
  control: Control<CreateDataSetFormValues>;
  currentEnvironment: RuleEnvironment;
  connector?: ConnectorAndPluginModel;
  setValue: UseFormSetValue<any>;
  readOnly: boolean;
};

export function FileExplorer({
  control,
  setValue,
  currentEnvironment,
  connector,
  readOnly,
}: FileExplorerProps) {
  const connectorId = connector?.id ?? '';
  const connectorName = connector?.name ?? '';
  const pluginType = connector?.plugin.name ?? '';
  const { axiosPrivate } = useAxiosPrivate();
  const supportedMethods = {
    getFiles: 'getFiles',
    getSheets: 'getSheets',
  };

  const params = useWatch({
    name: 'params',
    control,
  });

  const selectedFile = params?.file;
  const selectedSpreadsheet = params?.sheet;

  const [worksheetList, setWorksheetList] = useState<FileItemType[]>([]);
  const [sheetDataLoading, setSheetDataLoading] = useState(false);
  const [isFirstRender, setIsFirstRender] = useState(true);

  const [openPicker] = useDrivePicker();

  const { getGSheetFiles } = useGetGSheetFiles(
    currentEnvironment,
    connectorId,
    connectorName,
    pluginType
  );

  useEffect(() => {
    if (!_isUndefined(selectedFile) && !_isEmpty(selectedFile.value)) {
      if (!isFirstRender) {
        setValue('params.sheet', {});
      }

      setIsFirstRender(false);

      void makeWorksheetRequest({ fileId: selectedFile.value });
    }
  }, [JSON.stringify(selectedFile)]);

  useEffect(() => {
    if (
      !_isUndefined(selectedSpreadsheet) &&
      !_isEmpty(selectedSpreadsheet.value)
    ) {
      setValue('params.sheet', selectedSpreadsheet);
    }
  }, [JSON.stringify(selectedSpreadsheet)]);

  const getGooglePickerInfo = async () => {
    try {
      const response = await axiosPrivate.get(
        `credential/token?id=${connectorId}&env=${currentEnvironment}`
      );

      if (response?.data.code === 'success') {
        const { clientId, accessToken, apiKey } = response.data.data;

        return {
          clientId,
          token: accessToken,
          developerKey: apiKey,
        };
      }
    } catch (err) {}

    return null;
  };

  const handleOpenPicker = async () => {
    const pickerInfo = await getGooglePickerInfo();

    if (!_isNil(pickerInfo)) {
      openPicker({
        clientId: pickerInfo.clientId,
        developerKey: pickerInfo.developerKey,
        token: pickerInfo.token,
        showUploadView: false,
        showUploadFolders: true,
        supportDrives: false,
        multiselect: false,
        viewMimeTypes: 'application/vnd.google-apps.spreadsheet',
        callbackFunction: (data) => {
          if (data.action === 'cancel') {
            /*eslint-disable */
            console.log('User clicked cancel/close button');
          }
          /*eslint-disable */
          if (!_isNil(data.docs) && data.docs.length > 0) {
            setValue('params.file', {
              label: data.docs[0].name,
              value: data.docs[0].id,
            });

            setValue('params.isShared', data.docs[0].isShared ?? false);
          }
        },
      });
    }
  };

  const makeWorksheetRequest = async (
    queryParams: Record<string, string> = {}
  ) => {
    try {
      setSheetDataLoading(true);
      setWorksheetList([]);
      const response = await getGSheetFiles({
        method: supportedMethods.getSheets,
        params: {
          cr: false,
        },
        queryParams,
      });

      if (response.data.code === 'success') {
        const data = response.data.data;

        setWorksheetList(data.result ?? []);
      }
    } catch (err) {
      setWorksheetList([]);
    }

    setSheetDataLoading(false);
  };

  const handleDeleteFile = () => {
    setValue('params.file', undefined);
    setValue('params.sheet', undefined);
    setValue('params.isShared', null);

    setWorksheetList([]);
  };

  const handleSpreadsheetClick = (file: FileItemType) => {
    if (readOnly) {
      return;
    }
    setValue('params.sheet', file);
  };

  return (
    <>
      <SpreadsheetColumn>
        <StackAsItem grow={1}>
          <FileHeader>
            <Typography fontWeight={700}>Spreadsheet</Typography>
          </FileHeader>
          <FileBodyContent justify="center" align="center">
            <FileItem hasData={!_isUndefined(selectedFile)}>
              {_isUndefined(selectedFile) ? (
                <Typography>Click ‘Add Sheet’ to upload a sheet</Typography>
              ) : (
                <>
                  <Inline gutter="1.5rem" justify="center" align="center">
                    <img
                      src={`${envMap.VITE_ASSETS_URL}website/icons/file-icon.svg`}
                      alt="file icon"
                    />
                    <Typography fontWeight={700}>
                      {selectedFile.label}
                    </Typography>
                  </Inline>
                  <TextButton disabled={readOnly} onClick={handleDeleteFile}>
                    <Image
                      src={`${envMap.VITE_ASSETS_URL}website/icons/Cross.svg`}
                      alt="minus"
                      size="xs"
                    />
                  </TextButton>
                </>
              )}
            </FileItem>
            <Button disabled={readOnly} onClick={handleOpenPicker}>{`${
              _isUndefined(selectedFile) ? 'Add' : 'Update'
            } Sheet`}</Button>
          </FileBodyContent>
        </StackAsItem>
      </SpreadsheetColumn>
      <WorksheetColumn>
        <FileList
          label="Sheet Name"
          searchEnabled={false}
          data={worksheetList}
          icon={`${envMap.VITE_ASSETS_URL}website/icons/file-icon.svg`}
          selectedFile={selectedSpreadsheet}
          emptyDataMessage="Select a Spreadsheet to see your available worksheets"
          onFileClick={handleSpreadsheetClick}
          isLoading={sheetDataLoading}
        />
      </WorksheetColumn>
    </>
  );
}
