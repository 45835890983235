import { Stack } from '@bedrock-layout/primitives';
import styled from 'styled-components';

export const StackAsItem = styled(Stack)<{
  grow: number;
  shrink: number;
  basis: string;
}>`
  flex: ${({ grow = 0, shrink = 1, basis = 'auto' }) =>
    `${grow} ${shrink} ${basis}`};
`;
