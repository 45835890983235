import { useWatch } from 'react-hook-form';
import type { UseControllerProps } from 'react-hook-form';
import { PhoneNumberField, RadioListField, TextField } from 'ui';

import { DropdownField } from '../../../../../../packages/ui/src/Form/DropdownField/DropdownField';
import type { QuestionModel } from '../models';
import { Question } from './Question';

function getRequiredMessage(isRequired: boolean) {
  return isRequired ? 'This field is required' : false;
}

type QuestionComponentProps = Omit<UseControllerProps, 'name'> & {
  question: QuestionModel;
};

function TextQuestion({
  question: { key, question, value, required, regex },
  control,
}: QuestionComponentProps) {
  return (
    <Question key={key} question={`${question}${required ? '*' : ''}`}>
      <TextField
        defaultValue={value}
        control={control}
        name={key}
        rules={{
          required: getRequiredMessage(required),
          pattern: {
            value: new RegExp(`^${regex}$`),
            message:
              'Text should only contains a maximum of 25 characters or numbers.',
          },
        }}
        showErrorIcon={false}
      />
    </Question>
  );
}

function PhoneQuestion({
  question: { key, question, value, required },
  control,
}: QuestionComponentProps) {
  const country = JSON.parse(
    window.localStorage.getItem('source') ?? '{}'
  ).utm_country?.toLowerCase();

  return (
    <Question key={key} question={`${question}${required ? '*' : ''}`}>
      <PhoneNumberField
        country={country}
        defaultValue={value}
        control={control}
        name={key}
        rules={{
          required: getRequiredMessage(required),
        }}
      />
    </Question>
  );
}

function RadioQuestion({
  question: { key, question, value, required, options, otherValue },
  control,
}: QuestionComponentProps) {
  const radioFieldValue: string = useWatch({ control, name: key });

  return (
    <Question question={`${question}${required ? '*' : ''}`}>
      <RadioListField
        control={control}
        name={key}
        options={options}
        defaultValue={value}
        rules={{
          required: getRequiredMessage(required),
        }}
      />

      {radioFieldValue === 'others' && (
        <TextField
          control={control}
          name={key}
          placeholder="Please enter"
          defaultValue={otherValue ?? ''}
          rules={{
            required: getRequiredMessage(required),
          }}
        />
      )}
    </Question>
  );
}

function MultiCheckboxQuestion({
  question: { key, question, value = '', required, options },
  control,
}: QuestionComponentProps) {
  const defaultValues: Record<string, any> = [];
  options.forEach((option: Record<string, any>) => {
    value.split(',').forEach((singleId: string) => {
      if (singleId === option.value) {
        defaultValues.push(option);
      }
    });
  });

  return (
    <Question question={`${question}${required ? '*' : ''}`}>
      <DropdownField
        control={control}
        name={key}
        options={options}
        isMulti={true}
        defaultValue={defaultValues}
        placeholder="Select option"
      />
    </Question>
  );
}

function SingleSelect({
  question: { key, question, value = '', required, options },
  control,
}: QuestionComponentProps) {
  return (
    <Question question={`${question}${required ? '*' : ''}`}>
      <DropdownField
        control={control}
        name={key}
        options={options}
        defaultValue={options.filter((i) => i.value === value)[0]}
        placeholder="Select option"
      />
    </Question>
  );
}

export const questionComponents = {
  text: TextQuestion,
  phone: PhoneQuestion,
  radio: RadioQuestion,
  multi: MultiCheckboxQuestion,
  'select-single': SingleSelect,
};
