import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _isUndefined from 'lodash/isUndefined';
import { useEffect } from 'react';
import {
  type UseControllerProps,
  type UseFormSetValue,
  useWatch,
} from 'react-hook-form';
import { Button, toasts } from 'ui';

import type {
  PluginFieldCommonProps,
  RequestType,
} from '../../../pages/Integrations/types';
import {
  handleGetCheckSumByEntityName,
  handleSetCheckSumByEntityName,
} from '../../../utils/common';
import { CHECKSUM_MAPPING } from '../constant';
import { convertBodyParams, getValueBasedOnFieldType } from '../utlis';
import { useGetButtonSubmitAction } from './hook/useGetButtonSubmitAction';

type WebButtonFieldProps = UseControllerProps<any> &
  PluginFieldCommonProps & {
    target?: string;
    submitAction?: {
      method: RequestType;
      endpoint: string;
      requestType: string;
      bodyParams: Record<string, any>;
      fetchParent: Record<string, any>;
    };
    onSuccess?: Record<string, any>;
    setValue?: UseFormSetValue<any>;
    formJson?: Record<string, any>;
    parentFormData?: Record<string, any>;
  };

let childWindow: Window | null = null;

export function WebButtonField({
  control,
  label = '',
  disabled = false,
  submitAction,
  onSuccess = {},
  setValue,
  formJson = {},
  parentFormData = {},
}: WebButtonFieldProps) {
  const { method, requestType, bodyParams, endpoint, fetchParent } =
    submitAction ?? {
      method: 'GET',
      endpoint: '',
      requestType: '',
      bodyParams: {},
      fetchParent: {},
    };

  const formValues = useWatch({
    control,
  });

  const checksumObj = CHECKSUM_MAPPING[endpoint];

  const [submitActionQuery, { data: queryResponseData }] =
    useGetButtonSubmitAction(requestType === 'graphQL' ? endpoint : '', method);

  useEffect(() => {
    window.addEventListener('message', receivedMessage, false);

    return () => {
      window.removeEventListener('message', receivedMessage, false);
      childWindow?.close();
    };
  }, []);

  useEffect(() => {
    if (!_isNil(queryResponseData)) {
      const data = queryResponseData[endpoint] ?? {};

      if (!_isEmpty(data.authCodeUrl)) {
        if (!_isNil(checksumObj)) {
          handleSetCheckSumByEntityName(checksumObj.module, data.checksum);
        }

        openChildWindow(data.authCodeUrl);
      } else {
        handleUpdateDataonSuccess(data);
      }
    }
  }, [JSON.stringify(queryResponseData)]);

  const openChildWindow = (authCodeUrl: string) => {
    const childWindowWidth = 720;
    const childWindowHeight = 720;

    const left = (window.innerWidth - childWindowWidth) / 2 + window.screenX;
    const top = (window.innerHeight - childWindowHeight) / 2 + window.screenY;

    childWindow = window.open(
      authCodeUrl,
      'nectedRedirectWindow',
      `width=${childWindowWidth},height=${childWindowHeight},left=${left},top=${top},resizable=yes,scrollbars=yes,toolbar=0`
    );
  };

  const receivedMessage = (payload: any) => {
    if (payload.data.source === 'nectedRedirectWindow') {
      const { data } = payload.data;

      if (data?.code === 'success') {
        toasts.success('Connected successfully', 'success');

        handleUpdateDataonSuccess(data);
      } else if (!_isNil(data.error)) {
        toasts.error(data.error, 'error');
      }
    }
  };

  const handleUpdateDataonSuccess = (data: Record<string, any>) => {
    if (!_isUndefined(onSuccess) && !_isEmpty(onSuccess)) {
      // Update Form fields with api response data based on keys present in update object
      if (
        !_isUndefined(onSuccess.update) &&
        !_isEmpty(onSuccess.update) &&
        !_isUndefined(setValue)
      ) {
        onSuccess.update.forEach((key: string) => {
          setValue(key, data[key]);
        });
      }

      // Reset Form fields based on keys present in reset object
      if (
        !_isUndefined(onSuccess.reset) &&
        !_isEmpty(onSuccess.reset) &&
        !_isUndefined(setValue) &&
        !_isUndefined(formJson)
      ) {
        onSuccess.reset.forEach((key: string) => {
          // field is used to check the data type of current key
          const field = formJson[key];
          const resetValue = getValueBasedOnFieldType(field);
          setValue(key, resetValue);
        });
      }

      // Update Checksum
      if (!_isNil(checksumObj)) {
        handleSetCheckSumByEntityName(checksumObj.module, data.checksum);
      }
    }
  };

  const constructPayload = () => {
    const payload = convertBodyParams(
      bodyParams,
      fetchParent,
      formValues,
      formJson,
      parentFormData
    );

    if (!_isNil(checksumObj) && checksumObj.sendChecksum) {
      const checksumValue = handleGetCheckSumByEntityName(checksumObj.module);

      payload.checksum = checksumValue;
    }

    return payload;
  };

  const makeGraphQLRequest = async () => {
    if (!_isEmpty(method)) {
      const payload = constructPayload();
      try {
        await submitActionQuery({
          variables: payload,
          fetchPolicy: 'no-cache',
        });
      } catch (error: unknown) {
        if (error instanceof Error) {
          toasts.error(error.message, 'error');
        }
      }
    }
  };

  const handleClick = () => {
    if (!_isUndefined(submitAction) && requestType === 'graphQL') {
      void makeGraphQLRequest();
    }
  };

  return (
    <Button
      appearance="filled"
      size="medium"
      disabled={disabled}
      onClick={handleClick}
    >
      {label}
    </Button>
  );
}
