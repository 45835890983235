import { gql, useLazyQuery } from '@apollo/client';

import type { UseGetRuleAttributeDataModel } from '../../components/RuleSet/models';

const getRuleAttributesQuery = gql`
  query getRule(
    $id: String
    $live: Boolean
    $filters: QueryOperators
    $parentInfo: ParentInfo
  ) {
    getRule(id: $id, live: $live, filters: $filters, parentInfo: $parentInfo) {
      data {
        id
        name
        type
        action {
          then {
            outputData
          }
          else {
            outputData
          }
        }
        type
        customInput
      }
    }
  }
`;

export function useGetRuleAttributesById() {
  return useLazyQuery<UseGetRuleAttributeDataModel, any>(
    getRuleAttributesQuery
  );
}
