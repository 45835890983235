import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import type { UseControllerProps, UseFormSetValue } from 'react-hook-form';
import { useWatch } from 'react-hook-form';
import { IconButton, useLayer } from 'ui';

import { isRuleReadOnlyAtom } from '../../..';
import { TrashIcon } from '../../../../../components/icons/Trash';
import { useSendEventToGTM } from '../../../../../hooks/useSendEventToGTM';
import { getRequiredKey } from '../../../utils/common';
import { decisionTableNodeIdAtom } from '../DecisionTable';
import { DeleteConfirmModal } from './DeleteConfirmModal';

type ResultActionsProps = Omit<UseControllerProps, 'name'> & {
  setValue: UseFormSetValue<any>;
  index: number;
};

export function ResultActions({
  control,
  setValue,
  index,
}: ResultActionsProps) {
  const rows = useWatch({ name: 'rows', control });
  const results = useWatch({ name: 'results', control });
  const [isRuleReadOnly] = useAtom(isRuleReadOnlyAtom);

  const [ruleId] = useAtom(decisionTableNodeIdAtom);

  const { sendEventToGTM } = useSendEventToGTM();

  const { open: openDeleteModal } = useLayer(
    <DeleteConfirmModal
      onCloseConfirm={handleDeleteResultColumn}
      title="Delete Result"
    />
  );

  const ruleName = useWatch({
    control,
    name: 'ruleName',
  });

  function handleDeleteResultColumn() {
    if (isRuleReadOnly) {
      return;
    }

    sendEventToGTM({
      event: 'rule',
      ruleId,
      ruleName,
      type: 'decisionTable',
      action: 'delete',
      element: 'result',
      action_name: '',
      nec_source: '',
    });

    rows?.forEach((row: any, rowIndex: number) => {
      const rowKey = getRequiredKey(row, ['id']);

      const rowValues = structuredClone(row[rowKey]);

      let ruleRes = [];

      if (!_isNil(rowValues)) {
        ruleRes = rowValues.ruleResult.filter(
          (result: any, resultIndex: number) => resultIndex !== index
        );
      }

      setValue(`rows.${rowIndex}.${rowKey}.ruleResult`, ruleRes);

      return {
        [rowKey]: {
          ...rowValues,
        },
      };
    });

    setValue(
      'results',
      results.filter((res: any, resultIndex: number) => resultIndex !== index)
    );
  }

  return (
    <IconButton disabled={isRuleReadOnly} onClick={openDeleteModal}>
      <TrashIcon color="var(--color-saddleBrown)" />
    </IconButton>
  );
}
