import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { Control } from 'react-hook-form';
import { DropdownField, TextField, TooltipReact, Typography } from 'ui';

import { timeToExpireUnits } from '../../../pages/DataSets/utils';
import { DurationContainer, DurationValueContainer } from './Duration.styled';

type DurationProps = {
  control?: Control<any>;
  name: string;
};

export function Duration({ name, control }: DurationProps) {
  return (
    <Stack>
      <Inline gutter="1rem" align="center">
        <Typography>Cache Expiry Time</Typography>
        <TooltipReact id={`${name}-duration`}>
          <span>
            Time limit for how long the cached information will be stored,
            before being refreshed again
          </span>
        </TooltipReact>
      </Inline>
      <DurationContainer>
        <DurationValueContainer>
          <TextField
            name={`${name}.value`}
            control={control}
            type="number"
            isIntegerOnly
            size="small"
          />
        </DurationValueContainer>

        <DropdownField
          control={control}
          name={`${name}.unit`}
          options={timeToExpireUnits}
        />
      </DurationContainer>
    </Stack>
  );
}
