import _isNil from 'lodash/isNil';
import { FaCirclePlus } from 'react-icons/fa6';
import { IconButton, TooltipReact } from 'ui';

type AddNodeButtonProps = {
  id: string;
  isDisabled?: boolean;
  disabledMessage?: string;
};

export function AddNodeButton({
  id,
  isDisabled = false,
  disabledMessage,
}: AddNodeButtonProps) {
  if (!_isNil(disabledMessage)) {
    return (
      <TooltipReact
        id={id}
        launcher={
          <IconButton>
            <FaCirclePlus
              size={20}
              color={isDisabled ? '#DCDCDC' : 'var(--color-primary1)'}
            />
          </IconButton>
        }
      >
        {disabledMessage}
      </TooltipReact>
    );
  }

  return (
    <IconButton>
      <FaCirclePlus
        size={20}
        color={isDisabled ? '#DCDCDC' : 'var(--color-primary1)'}
      />
    </IconButton>
  );
}
