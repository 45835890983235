import { PadBox } from '@bedrock-layout/padbox';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import { Typography } from 'ui';

import { RuleListDataModel } from '../../../../../../Rules/components/RuleSet/models';
import { useGetRuleList } from '../../../../../hooks/graphql/useGetRuleList';
import {
  RuleCreateButton,
  RuleItem,
  RuleListContainer,
} from './RuleList.styled';

type RuleListProps = {
  onRuleSelect: (item: RuleListDataModel) => void;
  onItemClick: (type: string, entityId?: string) => void;
  ruleTypes?: string[];
};

export function RuleList({
  onRuleSelect,
  onItemClick,
  ruleTypes = ['decisionTable', 'simpleRule', 'ruleSet'],
}: RuleListProps) {
  const [getRuleList] = useGetRuleList();
  const [ruleList, setRuleList] = useState<RuleListDataModel[]>([]);

  const handleGetRuleList = async () => {
    try {
      const { data } = await getRuleList({
        variables: {
          page: 1,
          perPage: 500,
          filters: { in: { type: ruleTypes } },
        },
        fetchPolicy: 'no-cache',
      });

      if (!_isNil(data)) {
        setRuleList(data.getRule.data);
      }
    } catch {}
  };

  useEffect(() => {
    void handleGetRuleList();
  }, []);

  return (
    <PadBox padding="1.6rem">
      <RuleListContainer gutter="0">
        {ruleList.map((rule) => (
          <RuleItem
            key={rule.id}
            padding={[4, 6, 4, 6]}
            onClick={() => onRuleSelect(rule)}
          >
            <Typography name="paragraphXs">{rule.name}</Typography>
            <Typography name="secondaryXs">{rule.type}</Typography>
          </RuleItem>
        ))}

        <RuleCreateButton
          type="button"
          onClick={() => onItemClick('createRule')}
        >
          <AiOutlinePlus />
          <Typography fontWeight={700} name="paragraphXs">
            Create New Rule
          </Typography>
        </RuleCreateButton>
      </RuleListContainer>
    </PadBox>
  );
}
