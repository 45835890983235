import { PadBox } from '@bedrock-layout/padbox';
import styled from 'styled-components';

export const ContainerStyled = styled(PadBox)<{
  $isCollapsed: boolean;
  childrenWidth: number;
}>`
  ${({ $isCollapsed }) => $isCollapsed && 'display: none;'}
  ${({ childrenWidth }) =>
    childrenWidth !== 0 && `inline-size: ${childrenWidth}%`}
`;
