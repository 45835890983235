import { Inline } from '@bedrock-layout/primitives';
import styled from 'styled-components';

export const InputContainer = styled(Inline)`
  position: relative;
`;

export const InputStyled = styled.input<{
  $hasError: boolean;
  $isSmall: boolean;
  $isFull?: boolean;
  $showExpandIcon?: boolean;
  isActiveDate?: boolean;
}>`
  padding: 0.9rem 1.8rem;
  min-inline-size: 16rem;
  outline: 1px solid var(--color-lightGray);
  border: none;
  border-radius: 4px;
  text-overflow: ellipsis;
  font-size: 14px;

  ${({ $showExpandIcon = false }) =>
    $showExpandIcon && 'padding-right: 3.8rem;'}

  &::placeholder {
    text-overflow: clip;
  }
  outline: ${({ $hasError }) =>
    `1px solid ${
      $hasError ? 'var(--color-fireEngineRed)' : 'var(--color-white)'
    }`};
  background-color: ${({ $hasError }) =>
    $hasError ? 'var( --color-snow)' : 'var(--color-solitudeSecondary)'};

  ${({ $isSmall = false }) =>
    $isSmall &&
    `
    max-inline-size: 13rem;
    padding: 0.2rem 1.8rem;
  `}

  ${({ $isFull = false }) =>
    $isFull && 'inline-size: 100%; max-inline-size: 100%;'}

  ${({ isActiveDate = true, $isFull = false, $isSmall = false }) =>
    isActiveDate &&
    !$isFull &&
    !$isSmall &&
    `max-inline-size: 23.8rem; inline-size: 23.8rem;`}
`;

export const TextAreaStyled = styled.textarea<{
  $hasError: boolean;
  $isSmall: boolean;
  $isFull?: boolean;
  $showExpandIcon?: boolean;
  $isToken?: boolean;
}>`
  padding: 0.9rem 1.8rem;
  min-inline-size: 15rem;
  outline: 1px solid var(--color-lightGray);
  border: none;
  border-radius: 4px;
  text-overflow: ellipsis;
  font-size: 14px;
  resize: none;

  ${({ $isToken = false }) =>
    $isToken &&
    `  white-space: pre;
  overflow-wrap: normal;
  overflow-x: auto;`}

  ${({ $showExpandIcon = false }) =>
    $showExpandIcon && 'padding-right: 3.8rem;'}

  &::placeholder {
    text-overflow: clip;
  }
  outline: ${({ $hasError }) =>
    `1px solid ${
      $hasError ? 'var(--color-fireEngineRed)' : 'var(--color-white)'
    }`};
  background-color: ${({ $hasError }) =>
    $hasError ? 'var( --color-snow)' : 'var(--color-solitudeSecondary)'};

  ${({ $isSmall = false }) =>
    $isSmall &&
    `
    max-inline-size: 13rem;
    padding: 0.2rem 1.8rem;
  `}

  ${({ $isFull = false }) =>
    $isFull && 'inline-size: 100%; max-inline-size: 100%;'}
`;

export const InputBrackets = styled.div<{
  direction: 'left' | 'right';
  $showExpandIcon?: boolean;
  $hasRightIcon?: boolean;
}>`
  position: absolute;
  ${({ direction = 'left', $showExpandIcon = false, $hasRightIcon = false }) =>
    direction === 'left'
      ? 'left: 0.5rem;'
      : `right: ${
          $showExpandIcon ? '3rem' : $hasRightIcon ? '2.5rem' : '1.5rem'
        }`};
  color: var(--color-lightGray);

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export const RhsInputContainer = styled.div<{ $size?: string }>`
  max-block-size: 40rem;
  inline-size: ${({ $size = '34rem' }) => $size};
  overflow-y: auto;
`;

export const ExpandIconContainer = styled.div`
  inline-size: 1.8rem;
  block-size: 1.8rem;
  background-color: var(--color-gainsboro);
  border-radius: 0.4rem;
  position: absolute;
  inset-inline-end: 0.4rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RightIconContainer = styled.div`
  position: absolute;
  inset-inline-end: 0.4rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;
