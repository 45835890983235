import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  Typography,
  useCurrentLayer,
} from 'ui';

import { useLogout } from '../hooks/useLogout';

export const LogoutModal = () => {
  const { close: closeModal } = useCurrentLayer();
  const { logout } = useLogout(closeModal);

  return (
    <Modal size="medium" title="Logout">
      <ModalContent>
        <Typography name="paragraph">
          Are you sure you want to logout?
        </Typography>
      </ModalContent>

      <ModalFooter>
        <Button appearance="neutral" onClick={closeModal}>
          Cancel
        </Button>

        <Button onClick={logout} appearance="danger">
          Logout
        </Button>
      </ModalFooter>
    </Modal>
  );
};
