import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  padding: 2rem;
  border: 2px dashed var(--color-lightGray);
  border-radius: 1rem;
  width: 30rem;
  background-color: var(--color-primary3);
`;

export const Title = styled.h2`
  font-size: 1.5rem;
  color: var(--color-black);
  margin: 0;
`;

export const Input = styled.input`
  display: none;
`;

export const UploadButton = styled.label`
  background-color: var(--color-primary1);
  color: var(--color-white);
  padding: 1rem 2rem;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
`;

export const ImagePreview = styled.img`
  max-width: 20rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px var(--color-lightGray);
`;

export const Loader = styled.div`
  font-size: 1rem;
  color: var(--color-primary1);
  font-weight: bold;
`;

export const Alert = styled.div`
  color: var(--color-fireEngineRed);
  font-size: 0.9rem;
  text-align: center;
`;
