import { Inline } from '@bedrock-layout/primitives';
import type { Control } from 'react-hook-form';

import { checkDateValue } from '../../../../utils/common';
import { dataTypes } from '../../constant';
import { AddConfigValueType, RemoteConfigFieldsDataTypes } from '../../models';
import { FieldContainer } from '../RemoteConfig.styled';
import { RemoteConfigBooleanField } from './fields/RemoteConfigBooleanField';
import { RemoteConfigDateField } from './fields/RemoteConfigDateField';
import { RemoteConfigDateTimeField } from './fields/RemoteConfigDateTimeField';
import { RemoteConfigJsonField } from './fields/RemoteConfigJsonField';
import { RemoteConfigNumberField } from './fields/RemoteConfigNumberField';
import { RemoteConfigTextField } from './fields/RemoteConfigTextField';

export function RemoteConfigField({
  type,
  control,
  name,
  value,
  disabled,
}: {
  type: RemoteConfigFieldsDataTypes;
  control: Control<any>;
  name: string;
  value: AddConfigValueType;
  disabled: boolean;
}) {
  if (type === dataTypes.string) {
    return (
      <RemoteConfigTextField
        control={control}
        name={name}
        value={value}
        disabled={disabled}
      />
    );
  }

  if (type === dataTypes.date) {
    return (
      <RemoteConfigDateField
        control={control}
        name={name}
        value={checkDateValue(value as string | Date)}
        disabled={disabled}
      />
    );
  }

  if (type === dataTypes.dateTime) {
    return (
      <RemoteConfigDateTimeField
        control={control}
        name={name}
        value={new Date(value as string)}
        disabled={disabled}
      />
    );
  }

  if (type === dataTypes.numeric) {
    return (
      <RemoteConfigNumberField
        control={control}
        name={name}
        value={value}
        disabled={disabled}
      />
    );
  }

  if (type === dataTypes.boolean) {
    return (
      <Inline as={FieldContainer} align="center">
        <RemoteConfigBooleanField
          control={control}
          name={name}
          value={value}
          disabled={disabled}
        />
      </Inline>
    );
  }

  if (type === dataTypes.json || type === dataTypes.list) {
    return (
      <RemoteConfigJsonField
        name={name}
        control={control}
        defaultValue={value as string}
        type={type}
      />
    );
  }

  return null;
}
