import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { toasts } from 'ui';

import { handleSetCheckSumByEntityName } from '../../../utils/common';
import { TESTABLE_NODES } from '../../../utils/constant';
import {
  changedNodeIdsAtom,
  isWorkflowLiveAtom,
  workflowEdgesAtom,
  workflowIdAtom,
  workflowNodesAtom,
  workflowStatusAtom,
} from '../atoms/atoms';
import {
  getAllParentLoopNodesForCurrentNode,
  getAllPredecessorsSkippingType,
  getExecutedValueAndStatus,
  getExecutedValueByName,
  getExecutedValueOfUsedAttributes,
} from '../utils/common';
import { statusListForDataUpdate } from '../utils/constant';
import { useGetWorkflowById } from './graphql/useGetWorkflowById';
import { useTestWorkflow } from './restApi/useTestWorkflow';
import { useGenerateDataset } from './useGenerateDataset';
import { useGetDataset } from './useGetDataset';
import { WorkflowNodeType } from './useOpenWorkflow';

type UseTestLoopNodeProps = {
  localData: any;
  id: string;
};

export function useTestLoopNode({ localData, id }: UseTestLoopNodeProps) {
  // eslint-disable-next-line
  const [workflowId] = useAtom(workflowIdAtom);
  const [workflowNodes] = useAtom(workflowNodesAtom);
  const [workflowEdges] = useAtom(workflowEdgesAtom);
  const [workflowStatus, setWorkflowStatus] = useAtom(workflowStatusAtom);
  const [, setChangedNodeIds] = useAtom(changedNodeIdsAtom);
  const [, setIsWorkflowLive] = useAtom(isWorkflowLiveAtom);

  const [getWorkflowById] = useGetWorkflowById();

  const [executionId, setExecutionId] = useState<string>();
  const [parentNodes, setParentNodes] = useState<WorkflowNodeType[]>([]);
  const [parentLoopNodes, setParentLoopNodes] = useState<WorkflowNodeType[]>(
    []
  );

  // eslint-disable-next-line
  const { testWorkflow, data: loopData, error: loopError } = useTestWorkflow();

  const [isTesting, setIsTesting] = useState(false);
  const [currentStatus, setCurrentStatus] = useState<string>(
    localData.status ?? ''
  );

  const workflowNode = workflowNodes.find((wn) => wn.id === id);

  const { updatedDataSet } = useGetDataset({
    parentNodes,
    parentLoopNodes,
  });

  const { tokens } = useGenerateDataset({
    id,
    updatedDataset: updatedDataSet,
  });

  useEffect(() => {
    void onTestSuccess();

    if (!_isNil(loopData)) {
      if (
        !_isNil(workflowNode) &&
        statusListForDataUpdate.includes(workflowStatus)
      ) {
        const newWorkflowNode = workflowNode;
        const exec = getExecutedValueAndStatus(loopData);
        setCurrentStatus(exec.status);

        newWorkflowNode.data.status = exec.status;
        newWorkflowNode.data.executedValue = exec.executedValue;

        setChangedNodeIds([]);

        setCurrentStatus(exec.status);
        setExecutionId(exec.executionId);
        setTimeout(() => {
          localData.onWorkflowNodeChange(newWorkflowNode);
        }, 100);
      }

      setIsTesting(false);
    }
  }, [loopData]);

  useEffect(() => {
    if (!_isNil(loopError)) {
      setIsTesting(false);
    }
  }, [loopError]);

  const onTestSuccess = async () => {
    try {
      const data = await getWorkflowById({
        variables: { id: workflowId },
      });
      const newNodes = data.data.getWorkflow.data[0].nodes;

      setWorkflowStatus((prev) =>
        !_isNil(data.data.getWorkflow.data[0])
          ? data.data.getWorkflow.data[0].status
          : prev
      );
      void setChangedNodeIds([]);

      handleSetCheckSumByEntityName(
        'workflow',
        data.data.getWorkflow.data[0].checksum
      );

      setIsWorkflowLive(data.data.getWorkflow.data[0]?.isLive ?? false);

      setTimeout(() => {
        newNodes.forEach((node: WorkflowNodeType) =>
          localData.onWorkflowNodeChange(node)
        );
      }, 100);
    } catch (error) {}
  };

  const testData = async (
    attr?: Record<string, any>,
    onSuccess?: () => void
  ) => {
    if (
      parentNodes.find(
        (node) =>
          TESTABLE_NODES.includes(node.type ?? '') &&
          node.data.status !== 'success'
      ) != null
    ) {
      setIsTesting(false);

      return toasts.error(
        'Previous node(s) are  not tested. Please test the previous node(s) before proceeding',
        'parent-node'
      );
    }

    const attributes = attr ?? {};

    try {
      testWorkflow({
        id: workflowId ?? '',
        nodeId: id,
        params: {
          chunkSize: attributes?.chunkSize.value,
          concurrency: attributes?.concurrency.value,
          ...getExecutedValueOfUsedAttributes(
            {
              inputValue:
                !_isNil(attributes.inputValue) &&
                !_isEmpty(attributes.inputValue)
                  ? { ...attributes.inputValue, keyName: 'inputValue' }
                  : { keyName: 'inputValue' },
            },
            updatedDataSet,
            false
          ),
        },
        additionalParams: {
          executedValue: getExecutedValueByName(updatedDataSet),
        },
      });

      if (typeof onSuccess === 'function') {
        onSuccess();
      }
    } catch (error) {}
  };

  useEffect(() => {
    const directParents = getAllPredecessorsSkippingType(
      id,
      workflowNodes,
      workflowEdges,
      'addNode'
    );

    const parentLoopNodes = getAllParentLoopNodesForCurrentNode(
      id,
      workflowNodes
    );

    setParentNodes(directParents);
    setParentLoopNodes(parentLoopNodes);
  }, [JSON.stringify(workflowNodes)]);

  return {
    testData,
    loopData,
    loopError,
    updatedDataSet,
    tokens,
    parentNodes,
    currentStatus,
    isTesting,
    setIsTesting,
    setCurrentStatus,
    executionId,
  };
}
