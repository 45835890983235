import { useEffect } from 'react';

export function useResetForm(callback: () => void) {
  useEffect(() => {
    return () => {
      setTimeout(() => {
        // Run this cleanup function body on the next event loop as we
        // need to run it after all the child components have finished
        // running their cleanup functions in the current event loop.
        callback();
      });
    };
  }, []);
}
