import { Inline } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { Control, useWatch } from 'react-hook-form';
import { FiPlusCircle } from 'react-icons/fi';
import { Menu, MenuItem, TooltipReact, Typography } from 'ui';

import { isRuleReadOnlyAtom, simpleRuleNodeIdAtom } from '../../..';
import { siteConstantsAtom } from '../../../../../atom';
import { AddButton } from '../../../../../components/AddButton';
import { useSendEventToGTM } from '../../../../../hooks/useSendEventToGTM';
import { getTooltipText } from '../../../../../utils/common';
import { AddChildStyled } from './Results.styled';

type DataParamsTypeProps = {
  handleAddDataParam: (type: string) => void;
  control?: Control<any>;
};

export function DataParamsType({
  handleAddDataParam,
  control,
}: DataParamsTypeProps) {
  const [siteConstants] = useAtom(siteConstantsAtom);
  const [ruleId] = useAtom(simpleRuleNodeIdAtom);

  const { sendEventToGTM } = useSendEventToGTM();

  const ruleName = useWatch({
    control,
    name: 'ruleName',
  });

  const menuItems = [
    { value: 'boolean', label: 'Boolean' },
    { value: 'numeric', label: 'Numeric' },
    { value: 'string', label: 'String' },
    { value: 'date', label: 'Date' },
    { value: 'dateTime', label: 'DateTime' },
    { value: 'list', label: 'List' },
    {
      value: 'json',
      label: 'JSON',
      tooltip: getTooltipText(siteConstants, 'rules', 'customJsonRule'),
    },
    {
      value: 'jsFormula',
      label: 'JS Code',
      tooltip: getTooltipText(siteConstants, 'rules', 'customJSRule'),
    },
    {
      value: 'excelFormula',
      label: 'Formula',
      tooltip: getTooltipText(siteConstants, 'rules', 'customExcelRule'),
    },
  ];

  const [isRuleReadOnly] = useAtom(isRuleReadOnlyAtom);

  const handleAddData = () => {
    sendEventToGTM({
      event: 'rule',
      ruleId,
      ruleName,
      type: 'simpleRule',
      action: 'add',
      element: 'data',
      nec_source: '',
      action_name: '',
    });
  };

  const launcher = (
    <AddChildStyled
      $disabled={isRuleReadOnly}
      align="center"
      gutter={5}
      onClick={handleAddData}
    >
      {isRuleReadOnly ? (
        <FiPlusCircle color="var(--color-darkGray)" />
      ) : (
        <AddButton />
      )}
      <Typography>Add Result</Typography>
    </AddChildStyled>
  );

  return (
    <Menu
      launcher={launcher}
      onMenuItemClick={(value) => {
        handleAddDataParam(value);
      }}
      disabled={isRuleReadOnly}
    >
      {menuItems.map((menuItem) => (
        <MenuItem value={menuItem.value} key={menuItem.value}>
          <Inline align="center" gutter={8}>
            <Typography>{menuItem.label}</Typography>

            {!_isNil(menuItem.tooltip) && (
              <TooltipReact id={`dataType-${menuItem.value}`}>
                <Typography>{menuItem.tooltip}</Typography>
              </TooltipReact>
            )}
          </Inline>
        </MenuItem>
      ))}
    </Menu>
  );
}
