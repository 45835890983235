import { MdApi } from 'react-icons/md';
import { Typography } from 'ui';

import { TriggerListType } from '../../../../models/models';
import { TriggerItem } from './TriggerItem';
import {
  TriggerHeader,
  TriggerItems,
  TriggerListContainer,
} from './TriggerPopover.styled';

type TriggerPopoverProps = {
  data?: any;
  id: string;
};

const listOfTriggers: TriggerListType[] = [
  {
    name: 'API Trigger',
    type: 'apiTrigger',
    icon: <MdApi size={24} />,
  },
];

export function TriggerPopover({ data = {}, id }: TriggerPopoverProps) {
  return (
    <TriggerListContainer padding={0}>
      <TriggerHeader padding="1rem">
        <Typography>Add Trigger</Typography>
      </TriggerHeader>

      <TriggerItems>
        {listOfTriggers.map((trigger) => (
          <TriggerItem
            trigger={trigger}
            nodeId={id}
            onClick={data.addNode}
            key={trigger.type}
          />
        ))}
      </TriggerItems>
    </TriggerListContainer>
  );
}
