import { Inline, Stack } from '@bedrock-layout/primitives';
import styled from 'styled-components';

export const IconButtonStyled = styled(Stack)`
  position: absolute;
  left: calc(100% - 10rem);
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: 0.3s ease;
  padding: 1.4rem;
  background: radial-gradient(
    var(--color-whiteSmoke),
    var(--color-whiteSmoke),
    transparent
  );
`;

export const SchedulerBox = styled(Inline)`
  padding: 1rem 16px;
  background-color: var(--color-whiteSmoke);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  width: 100%;
  position: relative;
  overflow: hidden;
  min-block-size: 5rem;
  align-items: center;

  &:hover ${IconButtonStyled} {
    opacity: 1;
  }
`;
