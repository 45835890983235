export const WEEKDAY_LIST = [
  {
    label: 'S',
    value: 0,
  },
  {
    label: 'M',
    value: 1,
  },
  {
    label: 'T',
    value: 2,
  },
  {
    label: 'W',
    value: 3,
  },
  {
    label: 'T',
    value: 4,
  },
  {
    label: 'F',
    value: 5,
  },
  {
    label: 'S',
    value: 6,
  },
];
