import { Inline } from '@bedrock-layout/primitives';
import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  Typography,
  useCurrentLayer,
} from 'ui';

import { envMap } from '../../../../../utils/constant';
import { ErrorContainer } from './SaveNodeModal.styled';

type SaveNodeModalProps = {
  onSave?: () => void;
};

export function SaveNodeModal({ onSave }: SaveNodeModalProps) {
  const { close } = useCurrentLayer();

  return (
    <Modal
      size="large"
      title={
        <Typography name="heading2">
          <Inline
            justify="center"
            align="center"
            stretch="start"
            gutter={'1rem'}
          >
            <img
              style={{ maxWidth: '4rem' }}
              src={`${envMap.VITE_ASSETS_URL}website/icons/warning_icon.svg`}
              alt="warning image"
            />
            Alert
          </Inline>
        </Typography>
      }
      hideCloseButton
    >
      <ModalContent>
        <ErrorContainer>
          <Typography name="heading3" fontWeight={700}>
            Are you sure you want to save with errors?
          </Typography>
        </ErrorContainer>
      </ModalContent>

      <ModalFooter justify="center">
        <Button
          onClick={() => {
            if (typeof onSave === 'function') {
              onSave();
              close();
            }
          }}
        >
          Save & Close
        </Button>
        <Button appearance="neutral" onClick={close}>
          Continue Editing
        </Button>
      </ModalFooter>
    </Modal>
  );
}
