import { Inline, PadBox, Stack } from '@bedrock-layout/primitives';
import styled from 'styled-components';

export const AuthenticationContainer = styled(Inline)`
  width: 100vw;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: var(--color-lightBlueGrey);
  @media (min-width: 1280px) and (max-width: 1440px) {
  }
  @media (min-width: 300px) and (max-width: 1279px) {
    border: none;
    background: var(--color-white);
    width: 100vw;
    align-items: flex-start;
    height: auto;
  }
  @media screen and (min-width: 1250px) and (max-width: 1366px) and (min-height: 600px) and (max-height: 730px) {
    height: 85vh;
  }
  @media screen and (min-width: 1250px) and (max-width: 1366px) and (min-height: 600px) and (max-height: 730px) {
    height: 98vh;
  }
`;

export const AuthConatinerWrapper = styled(Inline)<{ isScrollable: boolean }>`
  width: 100vw;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: var(--color-lightGray3);
  @media (min-width: 300px) and (max-width: 1279px) {
    border: none;
    background: var(--color-white);
    ${({ isScrollable }) =>
      isScrollable
        ? ` height : 120vh;
    align-items: flex-start`
        : ''}
  }
`;

export const PadBoxStyled = styled(PadBox)`
  padding-top: 2rem;
  padding-left: 1rem;
  padding-bottom: 2rem;
  @media (max-width: 360px) {
    padding-left: 1rem;
  }

  @media (min-width: 361px) and (max-width: 600px) {
    flex-basis: 100%;
    padding-left: 2rem;
  }

  @media (min-width: 601px) and (max-width: 1000px) {
    padding-left: 3rem;
  }
`;

export const AuthBannerContainer = styled.div`
  width: 50%;
  flex-basis: 50%;
  background-color: var(--color-lightBlueGrey);
  @media (max-width: 1000px) {
    display: none;
  }
  height: 100vh;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  border-bottom-left-radius: 1.6rem;
  border-top-left-radius: 1.6rem;
`;

export const PageContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 50%;
  height: 100%;
  justify-content: center;
  background-color: var(--color-lightBlueGrey);

  @media (max-width: 360px) {
    flex-basis: 100%;
  }

  @media (min-width: 361px) and (max-width: 600px) {
    flex-basis: 100%;
  }

  @media (min-width: 601px) and (max-width: 1000px) {
    flex-basis: 100%;
    padding: 0 3rem;
  }
`;

export const LogoContainer = styled(Inline)`
  @media (min-width: 1000px) {
    display: none;
  }
`;

export const PageContentWithLogo = styled(Stack)`
  flex: 1;
`;

export const SlickContainer = styled.div`
  flex: 1;
`;
