import { useAtom } from 'jotai';
import { Control, useWatch } from 'react-hook-form';
import { FiPlusCircle } from 'react-icons/fi';
import { Typography } from 'ui';

import { isRuleReadOnlyAtom } from '../..';
import { AddButton } from '../../../../components/AddButton';
import { CreateAction } from '../../../../components/CreateAction/CreateAction';
import { useSendEventToGTM } from '../../../../hooks/useSendEventToGTM';
import { AddChildStyled } from '../SimpleRule/Results/Results.styled';

type DataActionsTypeProps = {
  addAction: (type: string, id: string) => void;
  control?: Control<any>;
  ruleId?: string;
  ruleType?: string;
};

export function DataActionsType({
  addAction,
  control,
  ruleId,
  ruleType = '',
}: DataActionsTypeProps) {
  const { sendEventToGTM } = useSendEventToGTM();
  const [isRuleReadOnly] = useAtom(isRuleReadOnlyAtom);

  const ruleName = useWatch({
    control,
    name: 'ruleName',
  });

  const handleAddAction = () => {
    sendEventToGTM({
      event: 'rule',
      ruleId,
      ruleName,
      type: ruleType,
      action: 'add',
      element: 'action',
      action_name: '',
      nec_source: '',
    });
  };

  const launcher = (
    <AddChildStyled
      $disabled={isRuleReadOnly}
      align="center"
      gutter={5}
      onClick={handleAddAction}
    >
      {isRuleReadOnly ? (
        <FiPlusCircle color="var(--color-darkGray)" />
      ) : (
        <AddButton />
      )}
      <Typography>Add Action</Typography>
    </AddChildStyled>
  );

  return (
    <CreateAction
      addAction={(value, id) => addAction(value, id)}
      launcher={launcher}
    />
  );
}
