import { PadBox } from '@bedrock-layout/padbox';
import { Stack } from '@bedrock-layout/stack';
import styled from 'styled-components';

export const JsonFieldContainer = styled.div`
  block-size: 65rem;
  border: 1px solid var(--color-solitude);
  border-radius: 4px;
  margin: 1rem;
`;

export const SaveButtonContainer = styled(PadBox)`
  border-top: 1px solid var(--color-darkGray);
  position: sticky;
  margin-top: auto;
  bottom: 0;
`;

export const JsonBlockContainer = styled(Stack)<{ $readOnly?: boolean }>`
  block-size: 100vh;

  ${({ $readOnly = false }) => $readOnly && 'pointer-events: none;'}
`;
