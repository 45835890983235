import { memo } from 'react';

import { ReverseArrowNode } from './ReverseArrowNode';

export const ArrowNode = memo(({ data, isConnectable, id }: any) => {
  if (data.nodeType === 'reverseArrowNode') {
    return (
      <ReverseArrowNode id={id} data={data} isConnectable={isConnectable} />
    );
  }

  return null;
});

ArrowNode.displayName = 'ArrowNode';
