import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import type { ReactNode } from 'react';
import { createRef, useEffect, useMemo, useState } from 'react';
import { BiArrowBack } from 'react-icons/bi';
import { IoIosLogOut } from 'react-icons/io';
import { MdChatBubbleOutline } from 'react-icons/md';
import { SlBookOpen } from 'react-icons/sl';
import { useLocation, useNavigate } from 'react-router-dom';
import { Image, PopoverMethods, PopoverPanel, Typography, useLayer } from 'ui';

import { siteConstantsAtom, userProfileAtom } from '../../../atom';
import { DetailsGoBackButton } from '../../../pages/AuditTrail/auditlogs.styled';
import { currentWorkspaceDetailAtom } from '../../../pages/Workspace/atom';
import { checkIfThemeEnabled, getTooltipText } from '../../../utils/common';
import { ENTITY_ID, envMap } from '../../../utils/constant';
import { HowToLink } from '../../HowToLink/HowToLink';
import { roleJsonAtom } from '../../authentication/router/AuthProvider';
import { InviteButton, ProfileActionButton } from './Header.styled';
import { LogoutModal } from './LogoutModal';
import { ProfileDetailsMini } from './profile/ProfileDetailsMini';
import { ProfileIcon } from './profile/ProfileIcon';
import { ProfileIconSkeleton } from './profile/ProfileIconSkeleton';

export type HeaderProps = { content: ReactNode };

export function Header({ content }: HeaderProps) {
  const [currentWorkspace] = useAtom(currentWorkspaceDetailAtom);

  const { open: openModal } = useLayer(<LogoutModal />);
  const popoverMethods = createRef<PopoverMethods>();
  const navigate = useNavigate();
  const location = useLocation();
  const isInIframe = window !== window.parent;

  const isFromSignedURL = isInIframe || currentWorkspace?.type === 'EUS';
  const [sideBarDisabled, setSideBarDisabled] = useState(isFromSignedURL);

  const [userProfile] = useAtom(userProfileAtom);
  const [roleJson] = useAtom(roleJsonAtom);
  const [siteConstants] = useAtom(siteConstantsAtom);

  const isWhiteLabelled = checkIfThemeEnabled(
    currentWorkspace?.whiteLabelEnabled,
    envMap.VITE_FULL_WHITELABELLED === 'true'
  );

  useEffect(() => {
    setSideBarDisabled(isFromSignedURL);
  }, [isFromSignedURL]);

  const howToLinkOnHeader: string | undefined = useMemo(() => {
    switch (location.pathname) {
      case '/integrations':
        return getTooltipText(
          siteConstants,
          'integrations',
          'howToLinkHeader',
          'howToLinks'
        );
      case '/rules':
        return getTooltipText(
          siteConstants,
          'rules',
          'howToLinkHeader',
          'howToLinks'
        );
      case '/datasets':
        return getTooltipText(
          siteConstants,
          'datasets',
          'howToLinkHeader',
          'howToLinks'
        );
      case '/auditlogs':
        return getTooltipText(
          siteConstants,
          'auditlogs',
          'howToLinkHeader',
          'howToLinks'
        );
      case '/credentials':
        return getTooltipText(
          siteConstants,
          'credentials',
          'howToLinkHeader',
          'howToLinks'
        );
      case '/remoteconfig':
        return getTooltipText(
          siteConstants,
          'remoteconfig',
          'howToLinkHeader',
          'howToLinks'
        );
      case '/workspace/settings':
        return getTooltipText(
          siteConstants,
          'workspace',
          'howToLinkHeader',
          'howToLinks'
        );
      case '/workspace/team':
        return getTooltipText(
          siteConstants,
          'workspace',
          'howToLinkHeader',
          'howToLinks'
        );
      default:
        return undefined;
    }
  }, [location.pathname, siteConstants]);

  const logoutClickHandler = () => {
    popoverMethods.current?.hide();
    openModal();
  };

  const showInviteButton = useMemo(() => {
    return (
      !_isNil(roleJson) &&
      !_isNil(roleJson.flags) &&
      !!roleJson.flags[ENTITY_ID.workspace] &&
      !location.pathname.includes('/workspace/team')
    );
  }, [roleJson, location.pathname]);

  if (_isNil(userProfile)) {
    return (
      <Inline stretch="start">
        {content}
        <ProfileIconSkeleton />
      </Inline>
    );
  }

  return (
    <Inline stretch="start" gutter="1.6rem" align="center">
      {content}

      {sideBarDisabled && !location.pathname.includes('/home') && (
        <DetailsGoBackButton onClick={() => window.history.back()}>
          <BiArrowBack /> Back
        </DetailsGoBackButton>
      )}

      {!sideBarDisabled && (
        <Inline align="center" gutter="0.8rem">
          {showInviteButton && (
            <InviteButton onClick={() => navigate('/workspace/team')}>
              <Inline align="center" gutter=".6rem">
                <Image
                  src="/assets/konark/icons/invite.svg"
                  alt="invite"
                  size="self"
                />
                <Typography>Invite Team</Typography>
              </Inline>
            </InviteButton>
          )}

          {!_isNil(howToLinkOnHeader) && !_isEmpty(howToLinkOnHeader) && (
            <HowToLink link={howToLinkOnHeader} />
          )}
        </Inline>
      )}

      {!sideBarDisabled && (
        <PopoverPanel
          padding={5}
          trigger="click"
          launcher={
            <span>
              <ProfileIcon profile={userProfile} />
            </span>
          }
          ref={popoverMethods}
        >
          <Stack gutter={10}>
            <ProfileDetailsMini profile={userProfile} />
            <Stack gutter={5}>
              {!isWhiteLabelled && (
                <>
                  <ProfileActionButton>
                    <SlBookOpen />
                    <Typography id="documentation_link">
                      Documentation
                    </Typography>
                  </ProfileActionButton>
                  <ProfileActionButton>
                    <MdChatBubbleOutline />
                    <Typography id="give_feedback">Give us feedback</Typography>
                  </ProfileActionButton>
                </>
              )}

              <ProfileActionButton onClick={logoutClickHandler} value="Logout">
                <IoIosLogOut />
                <Typography>Logout</Typography>
              </ProfileActionButton>
            </Stack>
          </Stack>
        </PopoverPanel>
      )}
    </Inline>
  );
}
