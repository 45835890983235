import { useAtom } from 'jotai';
import React, { useRef } from 'react';
import { Control, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import {
  Dataset,
  OnClickRuleArgs,
  PopoverMethods,
  PopoverPanel,
  RulePopover,
} from 'ui';

import { allConditionTypes } from '../../../../../../Rules/utils/common';
import { isWorkflowReadOnlyAtom } from '../../../../../atoms/atoms';
import { getBlankConditions } from '../../../../../utils/common';
import { PopoverContainerStyled } from './PathBlock.styled';
import { PropertyLauncher } from './PropertyLauncher';

type PropertySelectCustomProps = {
  text: string;
  dataType: string;
  name: string;
  updatedDataSet: Record<string, Dataset>;
  conditionName: string;
  pathIndex: number;
  setValue: UseFormSetValue<any>;
  watch: UseFormWatch<any>;
  control?: Control<any>;
  conditionId: string;
};
export function PropertySelectCustom({
  text,
  dataType,
  name,
  updatedDataSet,
  conditionId,
  conditionName,
  pathIndex,
  setValue,
  watch,
  control,
}: PropertySelectCustomProps) {
  const ref = useRef<PopoverMethods>(null);
  const [isWorkflowReadOnly] = useAtom(isWorkflowReadOnlyAtom);

  const currentCondition = watch(conditionName);

  const handleChangeProperty = (onRuleClickArgs: OnClickRuleArgs) => {
    const path = watch(`paths.${pathIndex}`);

    const condition = path[conditionId];

    if (
      onRuleClickArgs.key === 'custom' &&
      currentCondition?.nodeType !== onRuleClickArgs.dataType
    ) {
      condition.query = '1===1';
      condition.name = 'Untitled';
      condition.nodeType = onRuleClickArgs.dataType;

      setValue(`paths.${pathIndex}`, path);
    } else if (onRuleClickArgs.key !== 'custom') {
      const newCondition = getBlankConditions(conditionId, condition.parent);
      setValue(`paths.${pathIndex}`, { ...path, ...newCondition });
    }

    ref.current?.hide();
  };

  return (
    <PopoverPanel
      trigger="click"
      placement="bottom-start"
      launcher={
        <PropertyLauncher
          id={name}
          error={null}
          text={text}
          propertyValue={''}
          dataType={dataType}
        />
      }
      padding="8px"
      disabled={isWorkflowReadOnly}
      ref={ref}
    >
      <PopoverContainerStyled>
        <RulePopover
          dataset={updatedDataSet}
          onClick={handleChangeProperty}
          allowList
          typesToAllow={allConditionTypes}
        />
      </PopoverContainerStyled>
    </PopoverPanel>
  );
}
