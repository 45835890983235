import { useMutation } from '@tanstack/react-query';
import type { AxiosError, AxiosResponse } from 'axios';
import _isNil from 'lodash/isNil';
import { useCallback, useEffect, useMemo } from 'react';

import { useAxiosPrivate } from '../../../hooks';
import { showErrorToast } from '../../../utils/common';
import type { RuleEnvironment } from '../../../utils/constant';
import type { CustomAxiosError } from '../../../utils/response/types';
import type { SchemasValue, TableColumnValues } from '../types';
import { convertResponseObjectToSchemas } from '../utils';

type GetDataSetSchemaRequest = {
  id: string;
  environment: RuleEnvironment;
  method: 'getSchema';
  params: {
    refresh: boolean;
  };
};

type GetSchemaValues = {
  columns: TableColumnValues[];
  name: string;
};

export type GetDataSetSchemas = Record<string, GetSchemaValues>;

export type GetDataSetSchemaResponse = {
  data: {
    result: GetDataSetSchemas;
  };
};

export type GetDataSetSchemaFunction = (environment: RuleEnvironment) => void;

export type UseGetDataSetSchemaReturn = {
  schemas: SchemasValue;
  getDataSetSchema: (isRefresh: boolean) => void;
  isEnabled?: boolean;
};

export function useGetDataSetSchema(
  connectorId: string,
  currentEnvironment: RuleEnvironment,
  isEnabled: boolean = true
): UseGetDataSetSchemaReturn {
  const { axiosPrivate } = useAxiosPrivate();

  const { data, mutate: getDataSetSchemaMutator } = useMutation({
    mutationFn: async (request: GetDataSetSchemaRequest) => {
      const response: AxiosResponse<GetDataSetSchemaResponse> =
        await axiosPrivate.post('/integration/connector/execute', request);

      return response;
    },
  });

  const getDataSetSchema = useCallback(
    (isRefresh: boolean = false) => {
      try {
        getDataSetSchemaMutator({
          id: connectorId,
          environment: currentEnvironment,
          method: 'getSchema',
          params: { refresh: isRefresh },
        });
      } catch (error: unknown) {
        showErrorToast(error as AxiosError<CustomAxiosError>);
      }
    },
    [connectorId]
  );

  useEffect(() => {
    if (isEnabled) {
      void getDataSetSchema();
    }
  }, [connectorId]);

  const schemas = useMemo(() => {
    if (!_isNil(data) && !_isNil(data?.data?.data?.result)) {
      return convertResponseObjectToSchemas(data.data.data.result);
    }

    return {};
  }, [data]);

  return {
    schemas,
    getDataSetSchema,
  };
}
