import { PadBox } from '@bedrock-layout/padbox';
import { Inline, Stack } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  ExpandingTextField,
  Sheet,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Typography,
  toasts,
  useCurrentLayer,
  useLayer,
} from 'ui';

import { stopPropagate } from '../../../../../utils/form';
import {
  changedNodeIdsAtom,
  isWorkflowReadOnlyAtom,
  isWorkflowTestOnlyAtom,
  workflowErrorByNodeAtom,
  workflowNodeSavingAtom,
  workflowNodesAtom,
} from '../../../atoms/atoms';
import { useTestCodeNode } from '../../../hooks/useTestCodeNode';
import { useUpdateExecutedValue } from '../../../hooks/useUpdateExecutedValue';
import {
  checkIfNameExists,
  formatCodeInWorkflow,
  getExecutedValueAndStatus,
  transformCodeInWorkflow,
} from '../../../utils/common';
import {
  nodeNameValidationBeforeSave,
  validateCodeNode,
  validateTimeoutOnCheck,
} from '../../../utils/validations';
import {
  SheetFooterStyled,
  WorkflowSheetFormStyled,
  WorkflowSheetTabContentStyled,
} from '../../CommonStyles/CommonStyles.styled';
import { RuleSheetCloseModal } from '../../Modals/RuleSheetCloseModal/RuleSheetCloseModal';
import { CodeMapping } from './CodeMapping/CodeMapping';
import { CodeNodeTest } from './CodeNodeTest/CodeNodeTest';
import { CodeSettings } from './CodeSettings';

type CodeNodeSheetProps = {
  data?: any;
  id?: string;
};

export function CodeNodeSheet({
  data: localData,
  id = '',
}: CodeNodeSheetProps) {
  // eslint-disable-next-line
  const [isResultDisabled] = useState(false);
  const [workflowNodes] = useAtom(workflowNodesAtom);
  const [isWorkflowReadOnly] = useAtom(isWorkflowReadOnlyAtom);
  const [isWorkflowTestOnly] = useAtom(isWorkflowTestOnlyAtom);

  const [, setWorkflowErrorByNode] = useAtom(workflowErrorByNodeAtom);
  const [, setChangedNodeIds] = useAtom(changedNodeIdsAtom);
  const [workflowNodeSaving, setWorkflowNodeSaving] = useAtom(
    workflowNodeSavingAtom
  );

  const {
    testData,
    codeError,
    codeData,
    tokens,
    updatedDataSet,
    parentNodes,
    isTesting,
    setIsTesting,
  } = useTestCodeNode({
    localData,
    id,
  });

  const [counter, setCounter] = useState(0);
  const [currentStatus, setCurrentStatus] = useState<string>(
    localData.status ?? ''
  );
  const [isSaving, setIsSaving] = useState(false);

  const { close } = useCurrentLayer();

  const { open: openSheetCloseModal } = useLayer(
    <RuleSheetCloseModal onClose={close} title="Close code sheet" />
  );

  const [currentTab, setCurrentTab] = useState(0);

  const workflowNode = workflowNodes.find((wn) => wn.id === id);

  const { control, clearErrors, watch, handleSubmit, setError, setValue } =
    useForm({
      defaultValues: {
        language: {
          label: 'JavaScript',
          value: 'JS',
        },
        query: localData?.input?.snippet?.value ?? '',
        settings: {
          timeout: 10,
          errorContinue: false,
          ...(localData.settings ?? {}),
        },
        name: localData.name,
      },
    });

  // eslint-disable-next-line
  const onSubmit = async (dt: any, test: boolean = false) => {
    setIsSaving(true);
    clearErrors();
    setWorkflowErrorByNode((prev) => ({
      ...prev,
      [id]: undefined,
    }));

    const isTimeoutValid = validateTimeoutOnCheck(
      dt.settings,
      'settings',
      setError
    );

    if (!isTimeoutValid.valid) {
      setIsSaving(false);

      return toasts.error(isTimeoutValid.message, 'timeout-validity');
    }

    if (test) {
      const isValid = validateCodeNode(dt, setError);

      if (!isValid) {
        setCurrentTab(2);
        setIsSaving(false);

        return;
      }

      setIsTesting(true);
    }

    const isNameValid = nodeNameValidationBeforeSave(dt.name, setError);

    if (!isNameValid) {
      setIsTesting(false);
      setIsSaving(false);

      return;
    }

    if (!_isNil(workflowNode)) {
      const sheetData = transformCodeInWorkflow(dt);
      const newWorkflowNode = workflowNode;
      newWorkflowNode.data.status = currentStatus;

      if (
        dt.name !== localData.name &&
        typeof localData.updateOnNameChange === 'function'
      ) {
        const doesNameExist = checkIfNameExists(
          workflowNodes,
          dt.name,
          newWorkflowNode
        );

        if (doesNameExist) {
          setError('name', {
            message: 'Duplicate name provided',
          });

          return null;
        }

        localData.updateOnNameChange({
          id,
          name: localData.name,
          newName: dt.name,
        });
      }

      newWorkflowNode.data.name = sheetData.name;
      newWorkflowNode.data.input = {
        language: sheetData.language,
        snippet: sheetData.query,
      };

      newWorkflowNode.data.executedValue =
        currentStatus !== ''
          ? !_isNil(codeData) && !_isNil(codeData?.data)
            ? getExecutedValueAndStatus(codeData).executedValue
            : newWorkflowNode.data.executedValue ?? {}
          : null;

      newWorkflowNode.data.settings = sheetData.settings;

      if (counter > 1) {
        setChangedNodeIds([id]);
        setWorkflowNodeSaving(true);
      }

      localData.onWorkflowNodeChange(newWorkflowNode);
    }

    if (!test) {
      close();
    }

    if (test) {
      setCounter(1);
    }

    setIsSaving(false);

    return null;
  };

  useEffect(() => {
    const value = formatCodeInWorkflow(localData);

    setValue('language', value.language);
    setValue('query', value.query);
    setValue('name', value.name);
  }, []);

  useEffect(() => {
    if (!workflowNodeSaving && isTesting) {
      void handleTestNode();
    }
  }, [workflowNodeSaving, isTesting]);

  const handleTestNode = async () => {
    await testData(formValues.query, formValues.language.value, () => {
      setTimeout(() => {
        setCurrentTab(0);
        setCurrentTab(1);
        setIsTesting(false);
      }, 100);
    });
  };

  const handleSaveData = stopPropagate(
    handleSubmit(async (data) => await onSubmit(data, false))
  );

  const handleSaveDataAndTest = stopPropagate(
    handleSubmit(async (data) => await onSubmit(data, true))
  );

  useEffect(() => {
    if (!_isNil(codeData)) {
      if (!_isNil(workflowNode)) {
        const newWorkflowNode = workflowNode;
        const exec = getExecutedValueAndStatus(codeData);
        setCurrentStatus(exec.status);

        newWorkflowNode.data.status = exec.status;
        newWorkflowNode.data.executedValue = exec.executedValue;

        setChangedNodeIds([]);

        setTimeout(() => {
          localData.onWorkflowNodeChange(newWorkflowNode);
        }, 100);
      }
    }
  }, [codeData]);

  const formValues = watch();

  useEffect(() => {
    setCounter((count) => count + 1);
  }, [JSON.stringify(formValues)]);

  useEffect(() => {
    if (counter > 1) {
      setCurrentStatus('');
    }
  }, [counter]);

  const { executedValue, handleGetExecutionValues } = useUpdateExecutedValue({
    parentNodes,
    updatedDataset: updatedDataSet,
  });

  const isLoading = isTesting || workflowNodeSaving || isSaving;

  return (
    <Sheet size="small" onClose={counter > 2 ? openSheetCloseModal : close}>
      <WorkflowSheetFormStyled>
        <Inline stretch="start">
          <Stack as={PadBox} gutter={48} padding={[16, 24]}>
            <Inline stretch="start">
              <Stack gutter={8}>
                <Inline align="center" gutter="1.6rem" justify="start">
                  <Typography name="heading2">
                    <ExpandingTextField
                      control={control}
                      name="name"
                      disabled={isWorkflowReadOnly}
                    />
                  </Typography>
                </Inline>
              </Stack>
            </Inline>
          </Stack>
        </Inline>

        <Tabs defaultOpen={currentTab} onTabChange={(i) => setCurrentTab(i)}>
          <TabList>
            <Tab>
              <Typography fontWeight={700}>Input Params</Typography>
            </Tab>

            <Tab disabled={isResultDisabled}>
              <Typography
                fontWeight={700}
                name={isResultDisabled ? 'secondarySmall' : 'paragraph'}
              >
                Test Results
              </Typography>
            </Tab>

            <Tab>
              <Typography fontWeight={700}>Settings</Typography>
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <WorkflowSheetTabContentStyled className="code-node-input-container">
                <PadBox padding="2rem">
                  <CodeMapping
                    control={control}
                    tokens={tokens}
                    handleGetExecutionValues={handleGetExecutionValues}
                    executedValue={executedValue}
                  />
                </PadBox>
              </WorkflowSheetTabContentStyled>
            </TabPanel>
            <TabPanel>
              {(!_isNil(codeData?.data?.data) ||
                !_isNil(codeError?.response?.data)) && (
                <CodeNodeTest
                  output={
                    !_isNil(codeData?.data?.data)
                      ? getExecutedValueAndStatus(codeData, watch('name'))
                      : codeError?.response?.data ?? localData.executedValue
                  }
                />
              )}
            </TabPanel>
            <TabPanel>
              <WorkflowSheetTabContentStyled className="code-node-settings-container">
                <CodeSettings control={control} name="settings" />
              </WorkflowSheetTabContentStyled>
            </TabPanel>
          </TabPanels>
        </Tabs>

        <SheetFooterStyled>
          <Button
            appearance="filled"
            disabled={isLoading || !isWorkflowTestOnly}
            onClick={handleSaveDataAndTest}
          >
            {isLoading ? <Spinner size="extraSmall" /> : <Inline>Test</Inline>}
          </Button>

          <Button
            disabled={isWorkflowReadOnly || isLoading}
            appearance="contained"
            onClick={handleSaveData}
          >
            {isLoading && !isTesting ? (
              <Spinner size="extraSmall" />
            ) : (
              <Inline>Save</Inline>
            )}
          </Button>
        </SheetFooterStyled>
      </WorkflowSheetFormStyled>
    </Sheet>
  );
}
