import { PadBox } from '@bedrock-layout/padbox';
import styled from 'styled-components';

export const RestNodePillBox = styled(PadBox)<{ $hasError: boolean }>`
  min-inline-size: 4rem;
  border-radius: 0.8rem;
  cursor: pointer;
  background: var(--color-white);
  outline: 1px solid var(--color-lightGray);
  position: relative;
  margin: 0.8rem 0rem;
  ${({ $hasError }) =>
    $hasError ? 'var(--color-fireEngineRed)' : 'var(--color-lavender)'};
`;

export const ErrorIcon = styled.div`
  position: absolute;
  top: -0.5rem;
  right: -1rem;
`;
