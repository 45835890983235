import { Inline, PadBox } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import { Toast } from 'ui';

import { ruleWarningsAtom } from '../../../index';

export function RuleTestWarnings() {
  const [ruleWarnings] = useAtom(ruleWarningsAtom);

  if (ruleWarnings.length === 0) {
    return null;
  }

  return (
    <Inline as={PadBox} padding="2rem">
      <Toast
        type="warning"
        message={`You have ${ruleWarnings.length} warnings would you like to review them?`}
      />
    </Inline>
  );
}
