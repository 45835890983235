import { gql, useMutation } from '@apollo/client';

import { RuleResponse } from '../../components/SimpleRule/models';

const discardRule = gql`
  mutation discardRule($id: String!, $checksum: String!) {
    discardRule(id: $id, checksum: $checksum) {
      id
      name
      description
      version
      status
      isLive
      workspaceId
      type
      staticUrl
      checksum
      firstCustomInput
      warnings
      settings {
        startAt
        endAt
        isEnabled
        auditIO
        cache {
          duration
          enabled
          cacheKeys
        }
        api {
          isEnabled
          isPrivate
          authId
        }
        schedule {
          startAt
          endAt
          inputParam
          unit
          spec {
            minute
            hour
            dayOfWeek
            dayOfMonth
            month
          }
          timezone
          isEnabled
        }
      }
      conditions {
        startNode
        nodes
      }
      action {
        then {
          outputData
          firstOutputData
          firstActionNode
          actionNode
        }
        else {
          outputData
          firstOutputData
          firstActionNode
          actionNode
        }
      }
      customInput
      dataSetId
      approvalInfo {
        title
        description
        approvers
      }
      versionInfo {
        currentVersion
        currentLiveVersion
        lastVersion
      }
      dependencyMap {
        id
        name
        type
        version
        commitTitle
        subType
        nodeId
      }
    }
  }
`;

export type DiscardSimpleRuleResponse = {
  discardRule: RuleResponse;
};

export function useDiscardSimpleRule() {
  return useMutation<
    DiscardSimpleRuleResponse,
    {
      id: string;
      checksum: string;
    }
  >(discardRule);
}
