import { Inline } from '@bedrock-layout/inline';
import { PadBox } from '@bedrock-layout/padbox';
import { Stack } from '@bedrock-layout/stack';
import { motion } from 'framer-motion';
import _isNil from 'lodash/isNil';
import { useState } from 'react';
import { FaMinus, FaPlus } from 'react-icons/fa';

import { ExecutedValueTooltip } from '../../ExecutedValueTooltip/ExecutedValueTooltip';
import { Typography } from '../../Typography';
import { NodeLeaf } from '../components';
import { NodeStyled } from '../components/Node.styled';
import { StyledText } from '../components/NodeLeaf.styled';
import { IconByDataType } from './IconByDataType';
import { ListViewer } from './ListViewer';
import { NodeAccordion } from './ListViewer.styled';
import { getDataTypeNected } from './helpers/search';

type JsonViewerProps = {
  execValue: Record<string, any>;
  depth: number;
  nodeKey: string;
  onLeafClick: (
    value: string,
    node: string,
    dataType: string,
    executedValue?: any
  ) => void;
  searchedLeaves: string[];
  kName: string;
  currentDatatype: string;
  typesToAllow?: string[];
  allowList?: boolean;
  showTooltip?: boolean;
  searchInput?: string;
  version?: 'v1' | 'v2';
  viewName?: string;
};

const LIST_KEY_REGEX = /\[\d+\]$/;

export function JsonViewer({
  execValue,
  depth,
  onLeafClick,
  nodeKey,
  searchedLeaves,
  kName,
  currentDatatype,
  typesToAllow = [],
  allowList = false,
  showTooltip = false,
  searchInput = '',
  version = 'v1',
  viewName,
}: JsonViewerProps) {
  const [areChildrenVisible, setAreChildrenVisible] = useState(true);

  let clickJson = false;

  if (typesToAllow.includes('json')) {
    clickJson = true;
  }

  const handleJsonClick = () => {
    if (clickJson) {
      onLeafClick(kName, nodeKey, 'json');
    }
  };

  if (
    !_isNil(execValue) &&
    typeof execValue === 'object' &&
    Object.keys(execValue ?? {}).length > 0
  ) {
    return (
      <PadBox padding={[0, 0, 4, 0]}>
        <Inline stretch="start">
          <Inline gutter={4} align="center">
            {showTooltip ? (
              <ExecutedValueTooltip
                attribute={kName}
                value={execValue}
                dataType="json"
                id={nodeKey + kName}
              >
                <NodeStyled
                  align="center"
                  gutter={8}
                  onClick={handleJsonClick}
                  $showHover={clickJson}
                  $padding={version === 'v2' ? '4px 0px 4px 10px' : undefined}
                  $version={version}
                >
                  {version === 'v2' && (
                    <span>
                      <IconByDataType
                        dataType={'json'}
                        color={
                          clickJson ? 'var(--color-paleDodgerBlue)' : 'black'
                        }
                      />
                    </span>
                  )}
                  <StyledText
                    name={'secondaryXsDark'}
                    fontWeight={clickJson ? 700 : 400}
                    $version={clickJson ? version : undefined}
                  >
                    {viewName ?? kName}
                  </StyledText>
                  <Typography name="secondaryXs">json</Typography>
                </NodeStyled>
              </ExecutedValueTooltip>
            ) : (
              <NodeStyled
                align="center"
                gutter={8}
                onClick={handleJsonClick}
                $showHover={clickJson}
                $padding={version === 'v2' ? '4px 0px 4px 10px' : undefined}
                $version={version}
              >
                {version === 'v2' && (
                  <span>
                    <IconByDataType
                      dataType={'json'}
                      color={
                        clickJson ? 'var(--color-paleDodgerBlue)' : 'black'
                      }
                    />
                  </span>
                )}
                <StyledText
                  name={'secondaryXsDark'}
                  fontWeight={clickJson ? 700 : 400}
                  $version={clickJson ? version : undefined}
                >
                  {viewName ?? kName}
                </StyledText>
                <Typography name="secondaryXs">json</Typography>
              </NodeStyled>
            )}

            <NodeAccordion
              onClick={() => {
                setAreChildrenVisible((prev) => !prev);
              }}
            >
              {areChildrenVisible ? <FaMinus size={7} /> : <FaPlus size={7} />}
            </NodeAccordion>
          </Inline>
        </Inline>

        {areChildrenVisible && (
          <motion.div>
            <Stack
              gutter="0px"
              style={{
                paddingLeft: 16,
              }}
            >
              {Object.keys(execValue).map((k, ix) => {
                const execType = getDataTypeNected(execValue[k]);

                if (execType === 'list' && allowList) {
                  return (
                    <ListViewer
                      exeVal={execValue[k]}
                      kName={`${kName}.${k}`}
                      viewName={k}
                      currentDatatype="list"
                      nodeKey={nodeKey}
                      depth={depth + 1}
                      onLeafClick={onLeafClick}
                      searchedLeaves={searchedLeaves}
                      key={`${kName}[${ix}]`}
                      typesToAllow={typesToAllow}
                      showTooltip={showTooltip}
                      searchInput={searchInput}
                      version={version}
                    />
                  );
                } else if (execType === 'json') {
                  return (
                    <JsonViewer
                      onLeafClick={onLeafClick}
                      execValue={execValue[k]}
                      kName={`${kName}.${k}`}
                      depth={depth + 1}
                      nodeKey={nodeKey}
                      key={ix}
                      viewName={k}
                      searchedLeaves={searchedLeaves}
                      allowList={allowList}
                      typesToAllow={typesToAllow}
                      currentDatatype={'json'}
                      showTooltip={showTooltip}
                      searchInput={searchInput}
                      version={version}
                    />
                  );
                }

                if (!typesToAllow.includes(execType)) {
                  return null;
                }

                return (
                  <NodeLeaf
                    key={`${kName}.${k}`}
                    title={k}
                    dataType={execType}
                    executedValue={execValue[k]}
                    showTooltip={showTooltip}
                    version={version}
                    onClick={() =>
                      onLeafClick(
                        `${kName}${
                          LIST_KEY_REGEX.test(kName) || execType === 'list'
                            ? '.'
                            : '.'
                        }${k}`,
                        nodeKey,
                        execType
                      )
                    }
                  />
                );
              })}
            </Stack>
          </motion.div>
        )}
      </PadBox>
    );
  }

  return null;
}
