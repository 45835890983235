import { getEntityTypeForApi } from '../components/Modals/utils/common';
import {
  useGetRuleVersionList,
  useGetWorkflowVersionList,
} from './graphql/useGetEntityVersionList';

type HandleVersionMappingParams = {
  entityId: string;
  type: string;
  name: string;
  nodeId: string;
  subType?: string;
};

export const useAddVersionMappingOfEntity = () => {
  // eslint-disable-next-line
  const [getRuleVersionListQuery] = useGetRuleVersionList();
  // eslint-disable-next-line
  const [getWorkflowVersionListQuery] = useGetWorkflowVersionList();

  const addVersionMapping = async ({
    entityId,
    type,
    name,
    subType,
    nodeId,
  }: HandleVersionMappingParams) => {
    // Please do not remove below code
    // As of now draft version is selected on adding new node

    // const payload: Record<string, any> = {
    //   id: entityId,
    //   entityType: getEntityTypeForApi(type),
    //   page: 1,
    //   perPage: 10,
    //   live: true,
    //   filters: {
    //     in: {
    //       status: ['published'],
    //     },
    //   },
    //   sort: {
    //     updatedAt: -1,
    //   },
    // };

    // try {
    //   const response =
    //     type === 'rules'
    //       ? await getRuleVersionListQuery({
    //           variables: payload,
    //           fetchPolicy: 'no-cache',
    //         })
    //       : await getWorkflowVersionListQuery({
    //           variables: payload,
    //           fetchPolicy: 'no-cache',
    //         });

    //   const methodName = getMethodNameForApi(type);

    //   if (!_isNil(response) && !_isNil(response.data) && !_isNil(methodName)) {
    //     const data = response.data[methodName].data;

    //     const defaultVersion: string = 'draft';

    //     return {
    //       entityId,
    //       type: getEntityTypeForApi(type),
    //       version: defaultVersion ?? '',
    //       nodeId,
    //     };
    //   }
    // } catch (err) {
    //   if (err instanceof ApolloError) {
    //     showGraphQlErrorToast(err);
    //   }
    // }

    // return null;

    const defaultVersion: string = 'draft';

    return {
      entityId,
      type: getEntityTypeForApi(type),
      version: defaultVersion ?? '',
      nodeId,
    };
  };

  return {
    addVersionMapping,
  };
};
