import { AllowedDatatypesProps } from '../../../types';

export const dataTypeRules: AllowedDatatypesProps[] = [
  {
    type: ['list'],
    operators: ['contains', 'notContains'],
    includes: [
      'string',
      'numeric',
      'date',
      'dateTime',
      'boolean',
      'json',
      'restAPI',
      'list',
      'generic',
    ],
  },
  {
    type: ['string'],
    operators: ['contains', 'notContains'],
    includes: ['string', 'generic'],
  },
  {
    type: ['list', 'string'],
    operators: ['empty', 'nempty'],
    includes: ['list', 'string', 'generic'],
  },
  {
    type: [
      'string',
      'numeric',
      'date',
      'dateTime',
      'boolean',
      'json',
      'restAPI',
      'list',
    ],
    operators: ['containsIn', 'notContainsIn'],
    includes: ['list', 'generic'],
  },
  {
    type: ['numeric', 'date', 'dateTime', 'boolean', 'json', 'restAPI', 'list'],
    operators: ['nin', 'in'],
    includes: ['list', 'generic'],
  },
  {
    type: ['json'],
    operators: ['nin', 'in'],
    includes: ['list', 'string', 'json', 'generic'],
  },
  {
    type: ['string'],
    operators: ['nin', 'in'],
    includes: ['list', 'generic'],
  },
];
