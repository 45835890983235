import _isEmpty from 'lodash/isEmpty';
import _isUndefined from 'lodash/isUndefined';
import { useEffect, useState } from 'react';
import type { UseFormSetValue } from 'react-hook-form';

type UseResetFieldsBasedOnConfigProps = {
  reset: string[];
  formKeyPrefix: string;
  parentFormData: Record<string, any>;
  setValue?: UseFormSetValue<any>;
};

export function useResetFieldsBasedOnConfig({
  reset,
  formKeyPrefix,
  parentFormData,
  setValue,
}: UseResetFieldsBasedOnConfigProps) {
  const [resetFields, setResetFields] = useState(false);

  useEffect(() => {
    if (!_isEmpty(parentFormData)) {
      const { formType = 'create' } = parentFormData;
      setResetFields(formType === 'create');
    }
  }, [parentFormData]);

  const resetConfigFieldData = () => {
    if (resetFields && !_isUndefined(setValue) && reset.length > 0) {
      reset.forEach((keyToReset) => {
        setValue(`${formKeyPrefix}${keyToReset}`, null);
      });
    } else if (!resetFields && reset.length > 0) {
      setResetFields(true);
    }
  };

  return { resetConfigFieldData };
}
