import { Center, Cover } from '@bedrock-layout/primitives';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Spinner, Typography } from 'ui';

import { showErrorToast } from '../../../utils/common';
import {
  SourceRedirectionPayloadProps,
  useVerifyWSInviteToken,
} from '../hooks/useVerifyWSInviteToken';

export function VerifyWorkspaceInvite() {
  const [seachParams] = useSearchParams();
  const navigate = useNavigate();
  const { inviteToken } = useParams();
  const { callTokenVerificationAPI, verificationData, isLoading, error } =
    useVerifyWSInviteToken();
  const userUUID = window.localStorage.getItem('userUUID');
  useEffect(() => {
    // check if user is loggedin and invite token is present then call verification API

    const sourceRedirectPayload: SourceRedirectionPayloadProps = {
      home: {
        apiUrl: `/workspace/member/${inviteToken as string}/verify`,
        method: 'PATCH',
        redirectUri: '',
      },
    };

    if (!_isNil(userUUID) && !_isNil(inviteToken)) {
      // call verification API and show error message if the logged in user is different from the invited user else supress the success message
      callTokenVerificationAPI(sourceRedirectPayload);
    } else {
      const userExists: boolean = seachParams.get('userExists') === 'true';
      // check if invited user is existing user or not
      // if existing redirect the user to login page else redirect to signup page with action as query params.

      if (userExists) {
        navigate(
          `/signin?sourceRedirect=${window.btoa(
            JSON.stringify(sourceRedirectPayload)
          )}`
        );
      } else {
        navigate(
          `/signup?sourceRedirect=${window.btoa(
            JSON.stringify(sourceRedirectPayload)
          )}`
        );
      }
    }
  }, []);

  useEffect(() => {
    if (!isLoading) {
      // if error is present show error toast and redirect to home page
      // if the user is logged in redirect to home page
      if (!_isEmpty(error)) {
        showErrorToast(error);

        if (!_isNil(userUUID)) {
          navigate(`/home`);
        }
      }
      // if the token verification is successfull then
      // set the invited workspace id in localstorage
      // redirect the user to the homepage and reload the page.

      if (!_isEmpty(verificationData)) {
        window.sessionStorage.setItem(
          'workspaceUUID',
          verificationData.data.data.workspaceUUID
        );
        window.open(`/home`, '_self');
      }
    }
  }, [isLoading]);

  return (
    <Cover>
      <Center centerChildren>
        <Spinner />
        <Typography name="heading1">
          Please wait while we redirect you to invited workspace ...
        </Typography>
      </Center>
    </Cover>
  );
}
