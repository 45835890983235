export const opOptions = [
  {
    label: 'Next',
    value: 'next',
  },
  {
    label: 'Past',
    value: 'past',
  },
];

export const unitOptions = [
  {
    label: 'Day',
    value: 'd',
  },
  {
    label: 'Week',
    value: 'w',
  },
  {
    label: 'Month',
    value: 'm',
  },
  {
    label: 'Year',
    value: 'y',
  },
];
