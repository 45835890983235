import { useAtom } from 'jotai';
import { Control } from 'react-hook-form';
import { CheckboxField, Typography } from 'ui';

import { isWorkflowReadOnlyAtom } from '../../../../../atoms/atoms';
import { DefaultPathStyled } from './DefaultPath.styled';

type DefaultPathProps = {
  control?: Control<any>;
};
export function DefaultPath({ control }: DefaultPathProps) {
  const [isWorkflowReadOnly] = useAtom(isWorkflowReadOnlyAtom);

  return (
    <DefaultPathStyled padding={['1rem', '1rem']}>
      <Typography>Default</Typography>
      <CheckboxField
        name="default"
        control={control}
        useId="default"
        appearance="switch"
        disabled={isWorkflowReadOnly}
      />
    </DefaultPathStyled>
  );
}
