import { Inline } from '@bedrock-layout/primitives';
import styled, { css } from 'styled-components';
import { Typography } from 'ui';

export const VCInfoItem = styled(Inline)`
  align-items: center;
  padding: 0.5rem 1rem;
  border-bottom: 1px solid var(--color-lightGray5);

  &:last-child {
    border-bottom: none;
  }

  .curr-version-cont {
    inline-size: 25%;

    padding: 0rem 0.5rem;
  }

  .commit-title-cont {
    flex: 1;
    padding: 0rem 0.5rem;
  }

  .usedBy-env-ver-cont {
    inline-size: 30%;
    padding: 0rem 0.5rem;
  }

  .arrow-icon {
    inline-size: 5%;
  }

  &.header {
    .curr-version-cont,
    .commit-title-cont {
      padding: 0rem;
      border-right: 1px solid var(--color-lightGray);
    }
  }
`;

export const VersionNameStyled = styled(Typography)`
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 1rem;
`;

export const getEnvAndVersionAppearance = (env: string) => {
  switch (env) {
    case 'staging':
      return css`
        color: var(--color-tangerine);
      `;
    case 'production':
    default:
      return css`
        color: var(--color-waterCourse);
      `;
  }
};

const getVersionAppearance = (status?: string) => {
  switch (status) {
    case 'staging':
      return css`
        background: var(--color-earlyDawn);
        color: var(--color-tangerine);
      `;
    case 'production':
    default:
      return css`
        background: var(--color-lightGreen);
        border: 1px solid var(--color-lightGreen);
        color: var(--color-waterCourse);
      `;
  }
};

export const CurrVersionContainer = styled.div<{ env?: string }>`
  max-inline-size: 8rem;

  .version-text-styled {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-lightGreen);
    color: var(--color-waterCourse);
    border-radius: 0.5rem;
    padding: 0.2rem 0.8rem;
    inline-size: 6rem;
    font-size: 1rem;
    ${({ env }) => getVersionAppearance(env)}
  }
`;

export const VersionTextStyled = styled(Typography)<{ env: string }>`
  font-size: 1rem;
  text-transform: capitalize;
  ${({ env }) => getEnvAndVersionAppearance(env)};
`;
