import { gql, useMutation } from '@apollo/client';

import type { RuleUpdateResponseModel } from '../../components/SimpleRule/models';

const updateCustomInputMutation = gql`
  mutation updateRule(
    $id: String!
    $customInput: Map
    $checksum: String!
    $firstCustomInput: String
    $dataSetId: String
    $editMode: Boolean
    $dependencyMap: [DependencyInput!]
  ) {
    updateRule(
      input: {
        id: $id
        customInput: $customInput
        checksum: $checksum
        firstCustomInput: $firstCustomInput
        editMode: $editMode
        dataSetId: $dataSetId
        dependencyMap: $dependencyMap
      }
    ) {
      id
      checksum
      status
      isLive
      publishedAt
      approvalInfo {
        title
        description
        approvers
      }
      versionInfo {
        currentVersion
        currentLiveVersion
        lastVersion
      }
      dependencyMap {
        id
        name
        type
        version
        commitTitle
        subType
        nodeId
      }
      datasetDetail {
        connector {
          id
        }
      }
    }
  }
`;

export function useUpdateCustomInput() {
  return useMutation<RuleUpdateResponseModel>(updateCustomInputMutation);
}
