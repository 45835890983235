import styled from 'styled-components';

export const TooltipHeader = styled.div<{ $showExecutedValue?: boolean }>`
  display: flex;
  justify-content: start;
  gap: 4px;
  align-items: center;
  padding: 0.5rem;

  ${({ $showExecutedValue = true }) =>
    $showExecutedValue && 'border-bottom: 1px solid #e3e3e3;'}

  .attribute-text-style {
    word-break: break-word;
  }

  .datatype-text {
    text-transform: capitalize;
  }
`;

export const ToolTipContent = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 0.5rem;
  background-color: #fffff6;
  overflow: auto;
  font-size: 1rem;
  word-break: break-word;
`;

export const StyledExecutedValueContainer = styled.div`
  overflow-y: auto;
  block-size: inherit;
  max-block-size: 30rem;
  display: flex;
  flex-direction: column;
  min-inline-size: 15rem;
  border-radius: 0.8rem;
`;
