import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { Control, UseFormSetValue, useWatch } from 'react-hook-form';
import { NectedEditorField, NectedSuggestionModel } from 'ui';

import { capitalizeHeaderKey } from '../../../../../../utils/common';
import { editorDomain } from '../../../../../../utils/constant';
import { HEADER_TOKEN_REGEX } from '../../../../../../utils/regex';

type ActionTextValueProps = {
  name: string;
  keyName: string;
  index: number;
  autoChange: boolean;
  control?: Control<any>;
  setValue?: UseFormSetValue<any>;
  customSuggestions?: NectedSuggestionModel[];
  readOnly?: boolean;
  handleGetExecData?: (data: Record<string, any>) => void;
  execValues?: Record<string, any>;
};

export default function ActionTextValue({
  control,
  name,
  index,
  keyName,
  setValue,
  customSuggestions = [],
  autoChange,
  readOnly = false,
  handleGetExecData,
  execValues,
}: ActionTextValueProps) {
  const [counter, setCounter] = useState(0);
  const [suggestions, setSuggestions] = useState<NectedSuggestionModel[]>([]);
  const key = useWatch({
    control,
    name: keyName,
  });

  const value: string | undefined = useWatch({
    control,
    name,
  });

  useEffect(() => {
    const suggests = [...customSuggestions];

    if (typeof setValue === 'function' && autoChange && !_isNil(value)) {
      const matchedExpression = value.match(HEADER_TOKEN_REGEX);

      if (
        !_isNil(value) &&
        !_isNil(matchedExpression) &&
        matchedExpression.some((expression) =>
          expression.includes('headers.')
        ) &&
        counter > 0
      ) {
        setValue(name, '');
      }

      if (!_isNil(key)) {
        suggests.push({
          name: `{{.headers.${capitalizeHeaderKey(key)}}}`,
          value: `{{.headers.${capitalizeHeaderKey(key)}}}`,
          executedValue: value,
          meta: 'string',
          score: 999,
        });
      }
    }

    setSuggestions(suggests);
    setCounter(counter + 1);
  }, [key, customSuggestions]);

  return (
    <NectedEditorField
      control={control}
      name={name}
      mode="singleLineText"
      customSuggestions={suggestions}
      readOnly={readOnly}
      methods="write"
      domain={editorDomain}
      handleGetExecData={handleGetExecData}
      execValues={execValues}
    />
  );
}
