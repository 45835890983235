import { useQuery } from '@tanstack/react-query';

import type { WorkSpaceDetailsModel } from '../types';
import { useAxiosPrivate } from './useAxiosPrivate';

export function useAllWorkspaceData(enabled: boolean) {
  const { axiosPrivate } = useAxiosPrivate();

  return useQuery<WorkSpaceDetailsModel[]>({
    queryKey: ['/workspace'],
    queryFn: async () => {
      const response = await axiosPrivate.get(`/workspace`);

      return response.data.data;
    },
    enabled,
  });
}
