import * as Comlink from 'comlink';
import { releaseProxy } from 'comlink';
import { useEffect, useMemo } from 'react';

import { GenerateTokens } from './TokenPollingWorker';

const makeWorkerApiAndCleanup = () => {
  // Here we create our worker and wrap it with comlink so we can interact with it
  const worker = new Worker(
    new URL('./TokenPollingWorker.ts', import.meta.url),
    {
      name: 'generate-tokens',
      type: 'module',
    }
  );
  const workerApi = Comlink.wrap<typeof GenerateTokens>(worker);
  const cleanup = () => {
    workerApi[releaseProxy]();
    worker.terminate();
  };

  const workerApiAndCleanup = { workerApi, cleanup };

  return workerApiAndCleanup;
};

export const useWorker = () => {
  const workerApiAndCleanup = useMemo(() => makeWorkerApiAndCleanup(), []);

  useEffect(() => {
    const { cleanup } = workerApiAndCleanup;

    return () => {
      cleanup();
    };
  }, [workerApiAndCleanup]);

  return workerApiAndCleanup;
};
