import { Inline, Stack } from '@bedrock-layout/primitives';
import styled from 'styled-components';
import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  Typography,
  useCurrentLayer,
} from 'ui';

import { useLogout } from '../Header/hooks/useLogout';
import { useSendEmailVerificationLink } from '../layouts/hooks/useSendEmailVerificationLink';

export const VerifyEmailTitle = styled(Typography)`
  text-align: center;
`;

export function VerifyEmailPopup() {
  const { sendVerificationLink } = useSendEmailVerificationLink();

  const { close: closeModal } = useCurrentLayer();
  const { logout } = useLogout(closeModal);

  return (
    <Modal size="medium" hideCloseButton={true}>
      <Inline justify="center">
        <ModalContent>
          <Stack gutter={'1rem'}>
            <VerifyEmailTitle name="heading1" fontWeight={700}>
              Verify your email
            </VerifyEmailTitle>
            <Typography>
              Before you go out and play on Nected. Please verify your email
              address. Click on re-send email verification button to get a new
              link on your email.
            </Typography>
          </Stack>
        </ModalContent>
      </Inline>

      <ModalFooter justify="center">
        <Button
          onClick={() => {
            sendVerificationLink();
          }}
        >
          Re-send verification link
        </Button>
        <Button
          appearance="danger"
          onClick={() => {
            logout();
          }}
        >
          Logout
        </Button>
      </ModalFooter>
    </Modal>
  );
}
