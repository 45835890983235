import { Inline } from '@bedrock-layout/inline';
import { Ref, forwardRef } from 'react';

import { WeekDayButton } from './WeekDayPicker.styled';
import { WEEKDAY_LIST } from './constants';

export type WeekDayPickerProps = {
  value?: number[];
  onChange?: (value: any) => void;
};

export const WeekDayPicker = forwardRef(
  ({ value = [], onChange }: WeekDayPickerProps, ref: Ref<HTMLElement>) => {
    return (
      <Inline align="center" gutter="0.5rem">
        {WEEKDAY_LIST.map((weekday, index) => {
          return (
            <WeekDayButton
              $isActive={value.includes(weekday.value)}
              type="button"
              onClick={() => {
                if (typeof onChange === 'function') {
                  if (!value.includes(weekday.value)) {
                    onChange([...value, weekday.value]);
                  } else {
                    onChange(value.filter((val) => val !== weekday.value));
                  }
                }
              }}
              key={`weekday_${index}`}
            >
              {weekday.label}
            </WeekDayButton>
          );
        })}
      </Inline>
    );
  }
);

WeekDayPicker.displayName = 'WeekDayPicker';
