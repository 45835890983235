import { Inline } from '@bedrock-layout/primitives';
import { useMemo } from 'react';
import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  Typography,
  useCurrentLayer,
} from 'ui';

import { getEntityTypeForUI } from '../../../utils/common';
import { envMap } from '../../../utils/constant';
import { ContainerStyled } from '../ApprovalFlowEnableWarningModal/ApprovalFlowEnableWarningModal.styled';

type AutoSelectVersionWarningModalProps = {
  entityType: string;
  handleAutoSelection?: () => void;
};

export function AutoSelectVersionWarningModal({
  entityType,
  handleAutoSelection,
}: AutoSelectVersionWarningModalProps) {
  const { close } = useCurrentLayer();

  const entityTypeUI = useMemo(
    () => getEntityTypeForUI(entityType),
    [entityType]
  );

  return (
    <Modal
      size="medium"
      title={
        <Typography name="heading2">
          <Inline
            justify="center"
            align="center"
            stretch="start"
            gutter={'1rem'}
          >
            <img
              style={{ maxWidth: '4rem' }}
              src={`${envMap.VITE_ASSETS_URL}website/icons/warning_icon.svg`}
              alt="warning image"
            />
            Alert
          </Inline>
        </Typography>
      }
      hideCloseButton
    >
      <ModalContent>
        <ContainerStyled>
          <Typography name="heading3" fontWeight={700}>
            {`Using this option will always auto select the current live version of this ${entityTypeUI}`}
          </Typography>
        </ContainerStyled>
      </ModalContent>

      <ModalFooter justify="center">
        <Button appearance="neutral" onClick={close}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            if (typeof handleAutoSelection === 'function') {
              handleAutoSelection();
            }
            close();
          }}
        >
          Continue
        </Button>
      </ModalFooter>
    </Modal>
  );
}
