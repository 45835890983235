import { PadBox } from '@bedrock-layout/padbox';
import { Column, Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import styled from 'styled-components';

export const ActionFooter = styled(PadBox)`
  border-top: 1px solid var(--color-darkGray);
  position: sticky;
  margin-top: auto;
  bottom: 0;
  background-color: var(--color-white);
`;

export const ColumnStyled = styled(Column)`
  flex: 1;
`;

export const ActionBody = styled(Stack)`
  block-size: 100vh;
  overflow-y: hidden;
`;

export const ActionBodyContainer = styled(PadBox)`
  block-size: calc(100vh - 150px);
  overflow-y: auto;
`;

export const SyntaxErrorContainer = styled(PadBox)`
  min-block-size: 5rem;
  background-color: var(--color-snow);
  border-radius: 4px;
  color: var(--color-fireEngineRed);
`;

export const ActionNamePill = styled(Inline)`
  min-block-size: 3rem;
  border-radius: 1rem;
  background-color: var(--color-whiteSmoke);
  padding: 1rem;
`;
