import { Inline } from '@bedrock-layout/primitives';
import _isNil from 'lodash/isNil';
import { ReactNode } from 'react';
import { TooltipReact, Typography } from 'ui';

import { SettingFieldContainer } from './DataSetForm.styled';

type SettingFieldProps = {
  label: string;
  children: ReactNode;
  fieldType?: 'boolean' | 'number';
  tooltip?: ReactNode;
};

export function SettingField({
  label,
  children,
  fieldType = 'number',
  tooltip,
}: SettingFieldProps) {
  if (fieldType === 'boolean') {
    return (
      <Inline align="center">
        <Inline gutter={8} align="center">
          <Typography>{label}</Typography>
          {!_isNil(tooltip) && (
            <TooltipReact id={label}>{tooltip} </TooltipReact>
          )}
        </Inline>

        {children}
      </Inline>
    );
  }

  return (
    <Inline align="start">
      <SettingFieldContainer align="center">
        <Inline gutter={8} align="center">
          <Typography>{label}</Typography>
          {!_isNil(tooltip) && (
            <TooltipReact id={label}>{tooltip} </TooltipReact>
          )}
        </Inline>
      </SettingFieldContainer>

      {children}
    </Inline>
  );
}
