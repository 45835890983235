import { ReactNode } from 'react';
import { Image, TextButton, useLayer } from 'ui';

import { envMap } from '../../../utils/constant';
import { DeleteAuthenticationKeyModal } from './DeleteAuthenticationKeyModal';

export type DeleteAuthenticationKeyButtonProps = {
  deleteAuthKey: (id: string) => void;
  authKeyId: string;
  checksum: string;
  title?: string;
  children?: ReactNode;
  messageTitle: string;
};

export function DeleteAuthenticationKeyButton({
  title = '',
  deleteAuthKey,
  checksum,
  authKeyId,
  messageTitle,
}: DeleteAuthenticationKeyButtonProps) {
  const { open: openModal } = useLayer(
    <DeleteAuthenticationKeyModal
      title={title}
      checksum={checksum}
      authKeyId={authKeyId}
      deleteAuthKey={deleteAuthKey}
      messageTitle={messageTitle}
    />
  );

  return (
    <TextButton
      onClick={() => {
        openModal();
      }}
      bold={false}
    >
      <Image
        src={`${envMap.VITE_ASSETS_URL}website/icons/trash.svg`}
        alt="trash"
        size="small"
      />
    </TextButton>
  );
}
