import type { ObservableQuery } from '@apollo/client';
import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  ExpandingTextField,
  Sheet,
  Typography,
  toasts,
  useCurrentLayer,
} from 'ui';

import { Form } from '../../../../../components/Form';
import { FormFields } from '../../../../../components/Form/types';
import {
  filterDataBasedOnFormFields,
  getTransformedSortedFormFields,
  getUpdatedFormConfigBasedOnApiData,
  parseFormData,
  validateFormData,
} from '../../../../../components/Form/utlis';
import type { SavedAuthConfigKeys } from '../../../../../types';
import {
  handleGetCheckSumByEntityName,
  handleSetCheckSumByEntityName,
  showGraphQlErrorToast,
} from '../../../../../utils/common';
import { useSaveAuthConfigKey } from '../../hooks/useSaveAuthConfigKeys';
import { useUpdateAuthConfigKey } from '../../hooks/useUpdateAuthConfigKey';
import {
  AddAuthenticationConfigSheetForm,
  PillStyled,
  SaveButtonContainer,
} from '../AuthenticationConfig.styled';
import { BasicAuthForm } from './basicAuth.styled';
import { basicAuthJson } from './formJson';

type BasicAuthSheetProps = {
  refetch?: ObservableQuery<any>['refetch'];
  authKey?: SavedAuthConfigKeys;
};

export function BasicAuthSheet({ authKey, refetch }: BasicAuthSheetProps) {
  const { close } = useCurrentLayer();
  const [formFields, setFormFields] = useState<FormFields[]>([]);

  const [authKeyData, setAuthKeyData] = useState<
    SavedAuthConfigKeys | undefined
  >(authKey);

  const [saveAuthConfigKey] = useSaveAuthConfigKey();
  const [updateAuthConfigKey] = useUpdateAuthConfigKey();

  const {
    control,
    handleSubmit,
    watch,
    reset,
    setError,
    formState: { errors },
  } = useForm<Record<string, any>>({
    defaultValues: {
      name: !_isNil(authKey) ? authKey.name : 'Untitled',
    },
  });

  useEffect(() => {
    const fields = getTransformedSortedFormFields(basicAuthJson);

    setFormFields(fields);

    return () => {
      if (typeof refetch === 'function') {
        void refetch();
      }
    };
  }, []);

  useEffect(() => {
    if (!_isNil(authKey) && formFields.length > 0) {
      const updatedFormFields = getUpdatedFormConfigBasedOnApiData(
        formFields,
        authKey
      );

      setFormFields(updatedFormFields);

      reset(authKey);
    }
  }, [authKey, JSON.stringify(formFields)]);

  const saveAuthData = async (data: any) => {
    const { name, ...restFormData } = data;

    if (!_isNil(authKeyData) && !_isNil(authKeyData.id)) {
      try {
        const checksum = handleGetCheckSumByEntityName('credentials');

        const response = await updateAuthConfigKey({
          variables: {
            id: authKeyData?.id,
            name,
            checksum: checksum ?? '',
            value: parseFormData(formFields, restFormData),
          },
        });

        handleSetCheckSumByEntityName(
          'credentials',
          response.data?.updateCredential.checksum
        );

        setAuthKeyData(response.data?.updateCredential);

        if (!_isNil(response.data?.updateCredential)) {
          toasts.success('Basic Auth Key Saved', 'success');
          close();
        } else {
          toasts.error('Basic Auth Key Not Saved', 'error');
        }
      } catch (error: unknown) {
        showGraphQlErrorToast(error);
      }
    } else {
      try {
        const response = await saveAuthConfigKey({
          variables: {
            name,
            type: 'API',
            authType: 'BASIC',
            value: parseFormData(formFields, restFormData),
          },
        });

        handleSetCheckSumByEntityName(
          'credentials',
          response.data?.saveAuthConfigKey.checksum
        );

        setAuthKeyData(response.data?.saveAuthConfigKey);

        if (!_isNil(response.data?.saveAuthConfigKey)) {
          toasts.success('Basic Auth Key Saved', 'success');
          close();
        } else {
          toasts.error('Basic Auth Key Not Saved', 'error');
        }
      } catch (error: unknown) {
        showGraphQlErrorToast(error);
      }
    }
  };

  const saveAuthHandler = (data: any) => {
    const isFormValid = validateFormData(data, formFields, setError);
    const updatedData = filterDataBasedOnFormFields(data, formFields);

    if (isFormValid && _isEmpty(errors)) {
      void saveAuthData({
        ...updatedData,
        name: data.name,
      });
    }
  };

  return (
    <Sheet size="small">
      <Stack
        as={AddAuthenticationConfigSheetForm}
        onSubmit={handleSubmit(saveAuthHandler)}
      >
        <BasicAuthForm>
          <Inline stretch="start" align="center">
            <Inline align="center" gutter="0.8rem">
              <Typography name="heading2">
                <ExpandingTextField control={control} name="name" />
              </Typography>

              <PillStyled padding={['0.2rem', '1rem']}>
                <Typography name="paragraphSmall">Basic Auth</Typography>
              </PillStyled>
            </Inline>

            {/* Please Do not remove the commented code. */}
            {/* <HowToLink
              link={getTooltipText(
                siteConstants,
                'credentials',
                'basicAuthHowTo',
                'howToLinks'
              )}
            /> */}
          </Inline>
          <Form
            name="basicAuth"
            formFields={formFields}
            watch={watch}
            entity="credentials"
            control={control}
          />
        </BasicAuthForm>
        <SaveButtonContainer padding="1rem">
          <Inline justify="end">
            <Button type="submit">Submit</Button>
          </Inline>
        </SaveButtonContainer>
      </Stack>
    </Sheet>
  );
}
