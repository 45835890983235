import { Ref, forwardRef, useMemo } from 'react';
import DatePicker, { DatePickerProps } from 'react-datepicker';
import { BiCalendar } from 'react-icons/bi';

import { TextInput } from '../TextInput';
import { formatNectedDate, isInvalidDate } from './helpers';
import './styles.css';

export type DateTimePickerInputProps = Pick<
  DatePickerProps,
  'minDate' | 'maxDate' | 'popperPlacement' | 'showYearDropdown'
> & {
  showIcon?: boolean;
  timeInputLabel?: string;
  value?: Date | null;
  placeholderText?: string;
  dateFormat?: string | string[];
  name?: string;
  onChange?: (e: any) => void;
  showTimeInput?: boolean;
  showCustomInput?: boolean;
  showIconInput?: boolean;
  appendText?: string;
  disabled?: boolean;
};

export const DateTimePickerInput = forwardRef(
  (
    {
      showIcon = true,
      timeInputLabel = 'Time:',
      showTimeInput = true,
      value,
      dateFormat = "yyyy-MM-dd'T'HH:mm:00XXX",
      name,
      onChange,
      placeholderText,
      minDate,
      maxDate,
      showCustomInput = false,
      appendText = '',
      disabled = false,
      showIconInput = false,
      popperPlacement = 'right-start',
      showYearDropdown = true,
    }: DateTimePickerInputProps,
    ref: Ref<HTMLElement>
  ) => {
    const customInput = useMemo(() => {
      if (showCustomInput) {
        return (
          <TextWithIcon
            appendText={appendText}
            showTimeInput={showTimeInput}
            placeholderText={placeholderText}
          />
        );
      } else if (showIconInput) {
        return <OnlyIconInput />;
      }
    }, []);

    return (
      <DatePicker
        showIcon={showIcon}
        timeInputLabel={timeInputLabel}
        showTimeInput={showTimeInput}
        selected={
          isInvalidDate(value as unknown as string) === true ? null : value
        }
        placeholderText={placeholderText}
        dateFormat={dateFormat}
        name={name}
        minDate={minDate}
        maxDate={maxDate}
        disabled={disabled}
        showYearDropdown={showYearDropdown}
        onYearChange={(e) => {
          if (typeof onChange === 'function') {
            onChange(e);
          }
        }}
        popperPlacement={popperPlacement}
        onChange={(e) => {
          if (typeof onChange === 'function') {
            onChange(e);
          }
        }}
        customInput={customInput}
      />
    );
  }
);

DateTimePickerInput.displayName = 'DateTimePickerInput';

const TextWithIcon = forwardRef(
  (
    {
      value,
      onClick,
      appendText = '',
      showTimeInput = false,
      placeholderText,
      ...rest
    }: any,
    ref
  ) => {
    let displayValue = '';

    if (!(showTimeInput as boolean)) {
      try {
        displayValue = formatNectedDate(value, 'date') as string;
      } catch (error) {}
    } else {
      displayValue = value;
    }

    return (
      <div onClick={onClick}>
        <TextInput
          value={
            displayValue +
            `${(appendText as string) !== '' ? ` ${appendText as string}` : ''}`
          }
          startIcon={<BiCalendar color="#bbb" size={20} />}
          readOnly
          placeholder={placeholderText}
        />
      </div>
    );
  }
);

TextWithIcon.displayName = 'TextWithIcon';

const OnlyIconInput = forwardRef(({ value, onClick }: any, ref) => (
  <>
    <BiCalendar color="#8f8e8e" size={20} onClick={onClick} />
  </>
));

OnlyIconInput.displayName = 'OnlyIconInput';
