import { useQuery } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import { useAtom } from 'jotai';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toasts } from 'ui';

import { axios } from '../../../api/axios';
import { userProfileAtom } from '../../../atom';
import { showErrorToast } from '../../../utils/common';
import { StatusCode } from '../../../utils/response/statusCode';
import { StatusMessage } from '../../../utils/response/statusMessage';
import type { CustomAxiosError } from '../../../utils/response/types';

export function useVerifyEmail() {
  const [searchParams] = useSearchParams();
  const [, setUserProfile] = useAtom(userProfileAtom);

  const navigate = useNavigate();
  const emailVerificationToken = searchParams.get('token') ?? '';

  return useQuery({
    queryKey: ['/auth/verify/email'],
    queryFn: async () => {
      return await axios.get(
        `/auth/verify/email?token=${emailVerificationToken}`
      );
    },
    onSuccess() {
      toasts.success('Email verified', 'success');
      setUserProfile(null);
      navigate('/home');
    },
    onError(error: AxiosError<CustomAxiosError>) {
      const { response } = error;
      let errorMessage;

      if (response?.data.code === StatusCode.EMAIL_ALREADY_VERIFIED) {
        errorMessage = StatusMessage.EMAIL_ALREADY_VERIFIED;
      } else if (response?.data.code === StatusCode.REQUEST_PARAMS) {
        errorMessage = 'Invalid token';
      }
      showErrorToast(error, errorMessage);

      navigate('/signin');
    },
  });
}
