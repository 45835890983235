import { gql, useLazyQuery } from '@apollo/client';

const getCommentsQuery = gql`
  query getComments($commitId: String!, $page: Int, $perPage: Int) {
    getComments(commitId: $commitId, page: $page, perPage: $perPage) {
      data {
        comment
        author
        timeStamp
        commitId
      }
      paginationInfo {
        perPage
        currentPage
        totalRecord
        totalPage
      }
    }
  }
`;

export function useGetComments() {
  return useLazyQuery<Record<string, any>, Record<string, any>>(
    getCommentsQuery
  );
}
