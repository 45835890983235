import { PadBox } from '@bedrock-layout/padbox';
import { useLocalStorageObserve } from 'hooks';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';

import { envMap } from '../../../utils/constant';

export const NectedLogo = () => {
  const [localTheme, setLocalTheme] = useState<any>(null);

  const theme = useLocalStorageObserve('local-theme');

  useEffect(() => {
    if (!_isNil(theme)) {
      try {
        setLocalTheme(JSON.parse(theme));
        getFavIconURL(JSON.parse(theme));
      } catch {}
    }
  }, [theme]);

  const generateUrl = () => {
    if (
      !_isNil(localTheme?.logoUrl) &&
      localTheme?.logoUrl !== '' &&
      typeof localTheme.logoUrl === 'string'
    ) {
      return localTheme.logoUrl as unknown as string;
    } else {
      // eslint-disable-next-line
      return !!envMap.VITE_COMPANY_LOGO_URL
        ? envMap.VITE_COMPANY_LOGO_URL
        : '/assets/konark/images/logo.svg';
    }
  };

  const getFavIconURL = (theme: Record<string, any>) => {
    let faviconURL = '';
    const currentTheme = theme;

    if (
      !_isNil(currentTheme?.favicon) &&
      currentTheme?.favicon !== '' &&
      typeof currentTheme.favicon === 'string'
    ) {
      faviconURL = currentTheme.favicon as unknown as string;
    } else {
      // eslint-disable-next-line
      faviconURL = !!envMap.VITE_THEME_FAV_ICON
        ? envMap.VITE_THEME_FAV_ICON
        : '/favicon.ico';
    }

    void updateFavicon(faviconURL);
  };

  const updateFavicon = (newFaviconURL: string) => {
    try {
      // Remove existing favicon
      const existingFavicon = document.querySelector("link[rel*='icon']");

      if (existingFavicon != null) {
        document.head.removeChild(existingFavicon);
      }

      // Create and append new favicon link
      const link = document.createElement('link');
      link.type = 'image/x-icon';
      link.rel = 'shortcut icon';
      link.href = newFaviconURL;
      document.head.appendChild(link);

      return true;
    } catch (error) {
      return false;
    }
  };

  return (
    <img
      src={
        !_isNil(generateUrl()) && !_isEmpty(generateUrl())
          ? generateUrl()
          : '/assets/konark/images/logo.svg'
      }
      alt="Logo"
      style={{ height: '3rem' }}
    />
  );
};

export const NectedLogoPadded = () => (
  <PadBox padding={['3.2rem', 0, 0, '3.2rem']}>
    <NectedLogo />
  </PadBox>
);
