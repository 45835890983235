import { PadBox } from '@bedrock-layout/padbox';
import styled from 'styled-components';
import { Typography } from 'ui';

export const StatusColumn = styled(PadBox)`
  inline-size: 100%;
`;

export const JsonContainer = styled(PadBox)`
  block-size: 20rem;
`;

export const ErrorMessage = styled(Typography)`
  word-break: break-all;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
`;

export const NoActionContainer = styled(Typography)`
  padding: 1rem 5rem;
`;

export const ErrorIcon = styled.div`
  min-inline-size: 2rem;
`;
