import { gql, useMutation } from '@apollo/client';

import { AddConfigValueType, SavedConfigModel } from '../models';

const updateRemoteConfig = gql`
  mutation updateConfig($configId: String!, $value: Any!, $checksum: String!) {
    updateConfig: updateVariable(
      input: { id: $configId, value: $value, checksum: $checksum }
    ) {
      id
      name
      value
      dataType
      checksum
    }
  }
`;

export function useUpdateConfig() {
  return useMutation<
    SavedConfigModel,
    {
      configId: string;
      value: AddConfigValueType;
      checksum: string;
    }
  >(updateRemoteConfig);
}
