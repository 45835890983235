import { Inline, PadBox } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import { Typography } from 'ui';

import { siteConstantsAtom } from '../../../../atom';
import { HowToLink } from '../../../../components/HowToLink/HowToLink';
import { getTooltipText } from '../../../../utils/common';

export function RuleTestHeader() {
  const [siteConstants] = useAtom(siteConstantsAtom);

  return (
    <PadBox padding="2.4rem">
      <Inline stretch="start" align="center">
        <Typography name="heading2">Test</Typography>
        <HowToLink
          link={getTooltipText(
            siteConstants,
            'rules',
            'testNodeSheetHowTo',
            'howToLinks'
          )}
        />
      </Inline>
    </PadBox>
  );
}
