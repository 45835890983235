import type { ObservableQuery } from '@apollo/client';
import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  ExpandingTextField,
  Sheet,
  Typography,
  toasts,
  useCurrentLayer,
} from 'ui';

import { Form } from '../../../../../components/Form';
import { FormFields } from '../../../../../components/Form/types';
import {
  filterDataBasedOnFormFields,
  getTransformedSortedFormFields,
  getUpdatedFormConfigBasedOnApiData,
  parseFormData,
  validateFormData,
} from '../../../../../components/Form/utlis';
import type { SavedAuthConfigKeys } from '../../../../../types';
import {
  handleGetCheckSumByEntityName,
  handleSetCheckSumByEntityName,
  showGraphQlErrorToast,
} from '../../../../../utils/common';
import { useSaveAuthConfigKey } from '../../hooks/useSaveAuthConfigKeys';
import { useUpdateAuthConfigKey } from '../../hooks/useUpdateAuthConfigKey';
import {
  AddAuthenticationConfigSheetForm,
  PillStyled,
  SaveButtonContainer,
  SuccessPillStyled,
} from '../AuthenticationConfig.styled';
import { oAuthJson } from './formJson';
import { OAuthForm } from './oAuth.styled';

type OAuthSheetProps = {
  refetch?: ObservableQuery<any>['refetch'];
  authKey?: SavedAuthConfigKeys;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
let childWindow: Window | null = null;

export function OAuthSheet({ authKey, refetch }: OAuthSheetProps) {
  const { close } = useCurrentLayer();
  const [formFields, setFormFields] = useState<FormFields[]>([]);

  const [authKeyData, setAuthKeyData] = useState<
    SavedAuthConfigKeys | undefined
  >(authKey);

  const [saveAuthConfigKey] = useSaveAuthConfigKey();
  const [updateAuthConfigKey] = useUpdateAuthConfigKey();

  const {
    control,
    handleSubmit,
    watch,
    reset,
    setError,
    formState: { errors },
  } = useForm<Record<string, any>>({
    defaultValues: {
      name: !_isNil(authKey) ? authKey.name : 'Untitled',
    },
  });

  useEffect(() => {
    const fields = getTransformedSortedFormFields(oAuthJson);

    setFormFields(fields);

    return () => {
      if (typeof refetch === 'function') {
        void refetch();
      }
    };
  }, []);

  useEffect(() => {
    window.addEventListener('message', receivedMessage, false);

    return () => {
      window.removeEventListener('message', receivedMessage, false);
      childWindow?.close();
    };
  }, []);

  useEffect(() => {
    if (!_isNil(authKey) && formFields.length > 0) {
      const updatedFormFields = getUpdatedFormConfigBasedOnApiData(
        formFields,
        authKey
      );

      setFormFields(updatedFormFields);

      reset(authKey);
    }
  }, [authKey, JSON.stringify(formFields)]);

  const openChildWindow = (authCodeUrl: string) => {
    const childWindowWidth = 720;
    const childWindowHeight = 720;

    const left = (window.innerWidth - childWindowWidth) / 2 + window.screenX;
    const top = (window.innerHeight - childWindowHeight) / 2 + window.screenY;

    childWindow = window.open(
      authCodeUrl,
      'nectedRedirectWindow',
      `width=${childWindowWidth},height=${childWindowHeight},left=${left},top=${top},resizable=yes,scrollbars=yes,toolbar=0`
    );
  };

  const saveAuthData = async (data: any) => {
    const { name, ...restFormData } = data;

    if (!_isNil(authKeyData) && !_isNil(authKeyData.id)) {
      try {
        const checksum = handleGetCheckSumByEntityName('credentials');

        const response = await updateAuthConfigKey({
          variables: {
            id: authKeyData?.id,
            name,
            checksum: checksum ?? '',
            value: parseFormData(formFields, restFormData),
          },
        });

        handleSetCheckSumByEntityName(
          'credentials',
          response.data?.updateCredential.checksum
        );

        const authCodeUrl = response.data?.updateCredential.authCodeUrl ?? '';

        setAuthKeyData(response.data?.updateCredential);

        if (!_isEmpty(authCodeUrl)) {
          openChildWindow(authCodeUrl);
        } else {
          if (response.data?.updateCredential.isValid as boolean) {
            toasts.success('oAuth Connected', 'success');
            close();
          } else {
            toasts.error('oAuth not Connected', 'error');
          }
        }
      } catch (error: unknown) {
        showGraphQlErrorToast(error);
      }
    } else {
      try {
        const response = await saveAuthConfigKey({
          variables: {
            name,
            type: 'API',
            authType: 'OAUTH',
            value: parseFormData(formFields, restFormData),
          },
        });

        handleSetCheckSumByEntityName(
          'credentials',
          response.data?.saveAuthConfigKey.checksum
        );

        const authCodeUrl = response.data?.saveAuthConfigKey.authCodeUrl ?? '';

        setAuthKeyData(response.data?.saveAuthConfigKey);

        if (!_isEmpty(authCodeUrl)) {
          openChildWindow(authCodeUrl);
        } else {
          if (response.data?.saveAuthConfigKey.isValid as boolean) {
            toasts.success('oAuth Connected', 'success');
            close();
          } else {
            toasts.error('oAuth not Connected', 'error');
          }
        }
      } catch (error: unknown) {
        showGraphQlErrorToast(error);
      }
    }
  };

  const saveAuthHandler = (data: any) => {
    const isFormValid = validateFormData(data, formFields, setError);
    const updatedData = filterDataBasedOnFormFields(data, formFields);

    if (isFormValid && _isEmpty(errors)) {
      void saveAuthData({
        ...updatedData,
        name: data.name,
      });
    }
  };

  const receivedMessage = (payload: any) => {
    if (payload.data.source === 'nectedRedirectWindow') {
      const { data } = payload.data;

      if (data?.code === 'success') {
        toasts.success('Connected successfully', 'success');

        if (!_isNil(data.checksum)) {
          handleSetCheckSumByEntityName('credentials', data.checksum);
        }
      } else if (!_isNil(data.error)) {
        toasts.error(data.error, 'error');
      }
    }
  };

  return (
    <Sheet size="small">
      <Stack
        as={AddAuthenticationConfigSheetForm}
        onSubmit={handleSubmit(saveAuthHandler)}
      >
        <OAuthForm>
          <Inline stretch="start" align="center">
            <Inline align="center" gutter="0.8rem">
              <Typography name="heading2">
                <ExpandingTextField control={control} name="name" />
              </Typography>

              <PillStyled padding={['0.2rem', '1rem']}>
                <Typography name="paragraphSmall">OAuth 2</Typography>
              </PillStyled>

              {(authKeyData?.isValid ?? false) && (
                <SuccessPillStyled padding={['0.2rem', '1rem']}>
                  <Typography name="paragraphSmall">Connected</Typography>
                </SuccessPillStyled>
              )}
            </Inline>

            {/* Please Do not remove the commented code. */}
            {/* <HowToLink
              link={getTooltipText(
                siteConstants,
                'credentials',
                'oAuthHowTo',
                'howToLinks'
              )}
            /> */}
          </Inline>
          <Form
            name="oAuth"
            formFields={formFields}
            watch={watch}
            entity="credentials"
            control={control}
          />
        </OAuthForm>
        <SaveButtonContainer padding="1rem">
          <Inline justify="end">
            <Button type="submit">Authorize & Save</Button>
          </Inline>
        </SaveButtonContainer>
      </Stack>
    </Sheet>
  );
}
