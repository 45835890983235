import { Inline, PadBox } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { createRef, useCallback, useEffect, useState } from 'react';
import { PopoverMethods, PopoverPanel, Typography } from 'ui';

import { siteConstantsAtom, userProfileAtom } from '../../atom';
import { SiteConstantsObject } from '../../types';
import { convertObjecttoStringCSS } from '../../utils/common';
import { envMap } from '../../utils/constant';
import { hideFAQForEntityDetails } from './constant';
import { FAQMenuButton } from './faqmenu.styled';

export function FAQMenu() {
  const [siteConstants] = useAtom(siteConstantsAtom);
  const [entity, setEntity] = useState('');
  const [showFAQInPopover, setShowFAQInPopover] = useState(true);

  const popoverMethods = createRef<PopoverMethods>();
  const [userProfile] = useAtom(userProfileAtom);

  const handleButtonClick = (href: string, target: string, title: string) => {
    if (!_isNil(userProfile) && !_isEmpty(href)) {
      const querystring = `?user_id=${
        window.localStorage.getItem('userUUID') as string
      }&email=${userProfile.email}&name=${userProfile.firstName} ${
        userProfile.lastName
      }&referrer_url=${window.location.href}&referrer_action=${title}`;
      window.open(`${href}${querystring}`, target);
    }
  };

  const pathname = window.location.pathname;
  const urlParams = new URLSearchParams(window.location.search);

  const pathEntity = pathname.split('/').at(1);
  const hasIdInPath = pathname.split('/').length > 2;

  const hasTypeInParams =
    !_isNil(urlParams.get('type')) && !_isEmpty(urlParams.get('type'));

  const renderFAQMenuItems = useCallback(
    (menuPayload: SiteConstantsObject) => {
      return (
        <>
          {menuPayload.faqMenu?.menuItems?.map((item, index) => {
            return (
              <Inline
                id={item.id}
                key={index}
                align="center"
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  handleButtonClick(item.href, item.target, item.title)
                }
              >
                <img
                  src={`${envMap.VITE_ASSETS_URL}website/icons/${item.icon}`}
                  width={20}
                />
                <Typography name="heading4" fontWeight={400} id={item.id}>
                  {item.title}
                </Typography>
              </Inline>
            );
          })}
        </>
      );
    },
    [pathEntity]
  );

  useEffect(() => {
    // get the pathname from the url after url change
    setEntity(pathEntity ?? 'home');
  }, [pathEntity]);

  useEffect(() => {
    if (
      (hasIdInPath || hasTypeInParams) &&
      hideFAQForEntityDetails.includes(entity)
    ) {
      setShowFAQInPopover(false);
    } else {
      setShowFAQInPopover(true);
    }
  }, [hasIdInPath, entity]);

  const menuPayload = !_isNil(siteConstants) ? siteConstants[entity] : null;

  if (_isNil(menuPayload)) {
    return null;
  }

  return (
    <>
      {showFAQInPopover ? (
        <PopoverPanel
          placement="left-start"
          trigger="click"
          launcher={
            <FAQMenuButton
              styles={convertObjecttoStringCSS(menuPayload.faqMenu?.style)}
            >
              <img
                src={`${envMap.VITE_ASSETS_URL}website/icons/questions.svg`}
              />
            </FAQMenuButton>
          }
          ref={popoverMethods}
        >
          <Stack gutter="1.5rem">{renderFAQMenuItems(menuPayload)}</Stack>
        </PopoverPanel>
      ) : (
        <Stack as={PadBox} padding="1.5rem" gutter="1.5rem">
          {renderFAQMenuItems(menuPayload)}
        </Stack>
      )}
    </>
  );
}
