import { gql, useMutation } from '@apollo/client';

import {
  RuleUpdateModel,
  RuleUpdateResponseModel,
} from '../../components/SimpleRule/models';

const updateSimpleRule = gql`
  mutation updateRule(
    $id: String!
    $name: String!
    $description: String
    $customInput: Map
    $settings: RuleSettingsInput
    $action: ActionInput
    $conditions: ConditionsInput
    $dataSetId: String
    $checksum: String!
    $firstCustomInput: String
    $editMode: Boolean
    $dependencyMap: [DependencyInput!]
  ) {
    updateRule(
      input: {
        id: $id
        name: $name
        description: $description
        customInput: $customInput
        settings: $settings
        action: $action
        conditions: $conditions
        dataSetId: $dataSetId
        checksum: $checksum
        firstCustomInput: $firstCustomInput
        editMode: $editMode
        dependencyMap: $dependencyMap
      }
    ) {
      id
      checksum
      status
      version
      isLive
      publishedAt
      approvalInfo {
        title
        description
        approvers
      }
      versionInfo {
        currentVersion
        currentLiveVersion
        lastVersion
      }
      dependencyMap {
        id
        name
        type
        version
        commitTitle
        subType
        nodeId
      }
    }
  }
`;

export function useUpdateSimpleRule() {
  return useMutation<RuleUpdateResponseModel, RuleUpdateModel>(
    updateSimpleRule
  );
}
