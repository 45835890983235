import { gql, useLazyQuery } from '@apollo/client';

type filtersType = {
  in: Record<string, any>;
  eq: Record<string, any>;
  nin: Record<string, any>;
  ne: Record<string, any>;
  gt: Record<string, any>;
  gte: Record<string, any>;
  lt: Record<string, any>;
  lte: Record<string, any>;
};

const getTableDataQuery = gql`
  query getWorkflow(
    $page: Int
    $perPage: Int
    $sort: Map
    $filters: QueryOperators
  ) {
    getWorkflow(
      perPage: $perPage
      page: $page
      sort: $sort
      filters: $filters
    ) {
      data {
        id
        name
        version
        createdAt
        createdBy
        publishedAt
        publishedBy
        updatedAt
        updatedBy
        workspaceId
        isLive
        checksum
        status
        versionInfo {
          currentVersion
          currentLiveVersion
          lastVersion
        }
        accessRole
      }
      paginationInfo {
        perPage
        totalRecord
        totalPage
      }
    }
  }
`;

export type TablePayloadVariables = {
  page: number;
  perPage: number;
  live?: boolean;
  sort?: Record<string, any>;
  filters?: filtersType;
};

export function useGetWorkflowList() {
  return useLazyQuery<any, TablePayloadVariables>(getTableDataQuery);
}
