import { useEffect, useState } from 'react';

import type { TimerId } from '../../types';
import { AutoSaving } from './AutoSaving';
import { Saved } from './Saved';

type AutoSaveLoaderProps = {
  isLoading: boolean;
  /**
   * Seconds to delay by
   */
  shouldDelayBy?: number;
};

export function AutoSaveLoader({
  isLoading,
  shouldDelayBy = 2,
}: AutoSaveLoaderProps) {
  const [isAutoSaving, setIsAutoSaving] = useState(false);

  useEffect(() => {
    const timerId: TimerId = setTimeout(() => {
      setIsAutoSaving(isLoading);
    }, shouldDelayBy * 1000);

    if (isLoading) {
      setIsAutoSaving(isLoading);
    }

    return () => {
      clearTimeout(timerId);
    };
  }, [isLoading]);

  return <>{isAutoSaving ? <AutoSaving /> : <Saved />}</>;
}
