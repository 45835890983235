import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import styled from 'styled-components';
import { Typography } from 'ui';

export const SchedulerFormWrapper = styled(Stack)`
  min-inline-size: 50rem;
  min-block-size: 30rem;
  flex-direction: column;
  display: flex;
`;

export const SchedulerDateWrapper = styled(Stack)`
  max-inline-size: 25rem;
  position: relative;
`;

export const TimeZoneContainer = styled(Typography)`
  margin-block-start: 2.5rem;
`;

export const ScheduleFooter = styled(Inline)`
  margin-top: auto;
  padding-top: 1rem;
  flex-direction: column;
`;

export const CronEnglishContainer = styled(Inline)`
  inline-size: 100%;
  padding: 0.5rem 1rem;
  background-color: var(--color-whiteSmoke);
  border-radius: 4px;
  max-inline-size: 50rem;
  word-wrap: break-word;
`;
