import { useMutation } from '@tanstack/react-query';
import type { AxiosError, AxiosResponse } from 'axios';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';

import { useAxiosPrivate } from '../../../../hooks';
import {
  handleGetCheckSumByEntityName,
  handleSetCheckSumByEntityName,
} from '../../../../utils/common';
import { CustomAxiosError } from '../../../../utils/response/types';
import { KeyValuePairList } from '../../../Integrations/types';
import { workflowStatusAtom } from '../../atoms/atoms';

export type TestWorkflowPayload = {
  id: string;
  nodeId?: string;
  headers?: KeyValuePairList[];
  type?: string;
  params?: Record<string, any>;
  additionalParams?: Record<string, any>;
};

export function useTestWorkflow() {
  const { axiosPrivate } = useAxiosPrivate();
  const [, setWorkflowStatus] = useAtom(workflowStatusAtom);

  const {
    mutate: testWorkflow,
    isLoading,
    data,
    error,
  } = useMutation({
    mutationFn: async (request: TestWorkflowPayload) => {
      const headers = (request.headers ?? []).reduce((acc, item) => {
        return {
          ...acc,
          [item.key]: item.value,
        };
      }, {});

      let requestBody: Record<string, any> = {
        id: request.id,
        checksum: handleGetCheckSumByEntityName('workflow') ?? '',
      };

      if ((request.headers ?? []).length > 0) {
        requestBody = { ...requestBody, headers };
      }

      if (!_isNil(request.type)) {
        requestBody = { ...requestBody, type: request.type };
      }

      if (!_isNil(request.nodeId)) {
        requestBody = { ...requestBody, nodeId: request.nodeId };
      }

      if (!_isNil(request.params)) {
        requestBody = { ...requestBody, params: request.params };
      }

      if (!_isNil(request.additionalParams)) {
        requestBody = {
          ...requestBody,
          additionalParams: request.additionalParams,
        };
      }

      return await axiosPrivate.post('/workflow/test', requestBody);
    },
    onSuccess(response: AxiosResponse) {
      if (!_isNil(response?.data.checksum)) {
        handleSetCheckSumByEntityName('workflow', response.data.checksum);
      }

      if (!_isNil(response?.data.data.workflowStatus)) {
        setWorkflowStatus(response?.data.data.workflowStatus);
      }

      return response;
    },
    onError(error: AxiosError<CustomAxiosError>) {
      return error;
    },
  });

  return {
    testWorkflow,
    isLoading,
    data,
    error,
  };
}
