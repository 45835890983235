import { Stack } from '@bedrock-layout/stack';
import styled from 'styled-components';

export const TreeSearchContainer = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
`;

export const TreeViewContainer = styled(Stack)`
  overflow-y: auto;
  max-block-size: 30rem;
  min-inline-size: 24.8rem;
`;
