import { FC } from 'react';
import styled from 'styled-components';

// SVG Component
interface AddIconProps {
  size?: number;
  color?: string;
}

export const AddIcon: FC<AddIconProps> = ({ size = 20, color }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 7v10m5-5H7"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

interface StyledButtonContainerProps {
  disabled?: boolean;
}

const ButtonContainer = styled.div<StyledButtonContainerProps>`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--color-primary2);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-primary1);
  cursor: pointer;
  transition: background-color 0.2s ease;
`;

export const AddButton = () => {
  return (
    <ButtonContainer>
      <AddIcon color="currentColor" size={20} />
    </ButtonContainer>
  );
};

AddButton.displayName = 'AddButton';
