import { PadBox } from '@bedrock-layout/primitives';
import styled from 'styled-components';

export const JsonOutputContainer = styled(PadBox)`
  background: var(--color-lightGray2);
  border-radius: 4px;
`;

export const JsonContainer = styled(PadBox)`
  height: 40rem;
`;

export const JsonTreeContainer = styled(PadBox)`
  block-size: 40rem;
  overflow-y: auto;
  background-color: var(--color-white);
`;

export const RestResponseActionFooter = styled.div`
  position: absolute;
  bottom: 0px;
  width: 100%;
`;

export const SelectionStyled = styled.div<{
  $bgColor?: string;
  $color: string;
}>`
  block-size: 2rem;
  inline-size: 8rem;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: ${({ $bgColor = '#fff' }) => $bgColor};
  color: ${({ $color = '#111' }) => $color};
`;
