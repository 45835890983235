import { gql, useLazyQuery } from '@apollo/client';

const getRuleVersionList = gql`
  query getRule(
    $id: String!
    $page: Int
    $perPage: Int
    $sort: Map
    $live: Boolean
    $filters: QueryOperators
  ) {
    getRule(
      id: $id
      perPage: $perPage
      page: $page
      sort: $sort
      live: $live
      filters: $filters
    ) {
      data {
        id
        commitId
        version
        status
        isLive
        approvalInfo {
          title
          description
          author
          timeStamp
        }
      }
      paginationInfo {
        perPage
        totalRecord
        totalPage
      }
    }
  }
`;

const getWorkflowVersionList = gql`
  query getWorkflow(
    $id: String!
    $page: Int
    $perPage: Int
    $sort: Map
    $live: Boolean
    $filters: QueryOperators
  ) {
    getWorkflow(
      id: $id
      perPage: $perPage
      page: $page
      sort: $sort
      live: $live
      filters: $filters
    ) {
      data {
        id
        commitId
        version
        status
        isLive
        approvalInfo {
          title
          description
          author
          timeStamp
        }
      }
      paginationInfo {
        perPage
        totalRecord
        totalPage
      }
    }
  }
`;

export function useGetRuleVersionList() {
  return useLazyQuery<Record<string, any>, Record<string, any>>(
    getRuleVersionList
  );
}

export function useGetWorkflowVersionList() {
  return useLazyQuery<Record<string, any>, Record<string, any>>(
    getWorkflowVersionList
  );
}
