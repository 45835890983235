import Cookies from 'js-cookie';
import _isNil from 'lodash/isNil';
import { Outlet } from 'react-router-dom';

import { envMap } from '../../utils/constant';
import { NectedLogo } from '../logo';
import {
  AuthConatinerWrapper,
  AuthenticationContainer,
  LogoContainer,
  PadBoxStyled,
  PageContentContainer,
  PageContentWithLogo,
} from './Authentication.styled';

export function WLAuthentication() {
  const isScrollable =
    _isNil(Cookies.get('cc_cookie')) &&
    envMap.VITE_COOKIE_CONSENT_ENABLED === 'true';

  return (
    <AuthConatinerWrapper isScrollable={isScrollable}>
      <AuthenticationContainer>
        <PageContentWithLogo gutter="0rem">
          <LogoContainer justify="start">
            <PadBoxStyled>
              <NectedLogo />
            </PadBoxStyled>
          </LogoContainer>

          <PageContentContainer>
            <Outlet />
          </PageContentContainer>
        </PageContentWithLogo>
      </AuthenticationContainer>
    </AuthConatinerWrapper>
  );
}
