import { useAtom } from 'jotai';
import { FiPlusCircle } from 'react-icons/fi';
import { Menu, MenuItem, Typography } from 'ui';

import { AddButton } from '../../../../../../../components/AddButton';
import { isWorkflowReadOnlyAtom } from '../../../../../atoms/atoms';
import { AddChildStyled } from './PathBlock.styled';

type AddConditionMenuProps = {
  append: (operator?: string) => void;
  type?: 'group' | 'condition';
};

export function AddConditionMenu({
  append,
  type = 'condition',
}: AddConditionMenuProps) {
  const onMenuItemClick = (operator: string) => append(operator);

  const [isWorkflowReadOnly] = useAtom(isWorkflowReadOnlyAtom);

  const launcher = (
    <AddChildStyled $disabled={isWorkflowReadOnly} align="center" gutter={5}>
      {isWorkflowReadOnly ? (
        <FiPlusCircle color="var(--color-darkGray)" />
      ) : (
        <AddButton />
      )}
      <Typography>
        Add {type === 'condition' ? 'Condition' : 'Group'}
      </Typography>
    </AddChildStyled>
  );

  return (
    <Menu
      launcher={launcher}
      disabled={isWorkflowReadOnly}
      onMenuItemClick={onMenuItemClick}
    >
      <MenuItem value="and">
        <Typography>And</Typography>
      </MenuItem>

      <MenuItem value="or">
        <Typography>Or</Typography>
      </MenuItem>
    </Menu>
  );
}
