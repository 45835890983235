import _isNil from 'lodash/isNil';

import { axiosVidhanPrivate } from '../api/axios';
import { envMap } from '../utils/constant';

const deploymentType = envMap.VITE_DEPLOYMENT_TYPE;
const API_URL = envMap.VITE_API_GET_STATE;
const environment =
  envMap.VITE_ENVIRONMENT === 'development' ? 'staging' : 'production';
const callGetUserStateApi = async () => {
  const response = await axiosVidhanPrivate.post(API_URL, {
    environment,
    isTest: false,
    params: {
      userid: window.localStorage.getItem('userUUID'),
    },
  });

  const userState = response.data.data.output[0].user_state;

  localStorage.setItem(
    'obstate',
    window.btoa(JSON.stringify(response.data.data.output[0].user_state))
  );

  if (!_isNil(userState)) {
    return true;
  } else {
    return false;
  }
};
export async function getUserState() {
  let result = false;

  if (deploymentType === 'self') {
    result = await callGetUserStateApi();
  }

  return result;
}
