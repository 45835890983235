import { gql, useLazyQuery } from '@apollo/client';

const getConnectorByIdQuery = gql`
  query getConnector($id: String!) {
    getConnector(id: $id) {
      data {
        id
        name
        checksum
        staging {
          conf
          isTested
          isPublish
        }
        production {
          conf
          isTested
          isPublish
        }
        plugin {
          name
          imageUrl
          displayName
          category
          id
          schema {
            integration {
              params
            }
          }
        }
      }
    }
  }
`;

export function useGetConnectorById() {
  return useLazyQuery(getConnectorByIdQuery);
}
