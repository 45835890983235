import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import _isNil from 'lodash/isNil';
import { Ref, forwardRef } from 'react';
import { VscTriangleDown } from 'react-icons/vsc';
import { Image, TextButton, Typography } from 'ui';

import { ErrorPopoverPositioned } from '../../../../../pages/Rules/components/SimpleRule/Error/ErrorPopoverPositioned';
import { envMap } from '../../../../../utils/constant';
import {
  ContainerStyled,
  DataSourceContainer,
  DataSourceValue,
} from './DataSourceLauncher.styled';

type DataSourceLauncherProps = {
  text: string;
  handleClearDataSet: () => void;
  error?: string;
  handleRefresh: () => void;
};

export const DataSourceLauncher = forwardRef(
  (
    {
      text = 'Select',
      handleClearDataSet,
      error,
      handleRefresh,
    }: DataSourceLauncherProps,
    ref: Ref<HTMLElement>
  ) => {
    const isEmptyText = text === '' || text === null || text === 'Select';

    return (
      <Stack gutter={'0.4rem'}>
        <Typography>Select Dataset</Typography>
        <Inline>
          <ContainerStyled
            as={Inline}
            align="center"
            justify="center"
            padding={[3, 8]}
            ref={ref}
          >
            {!_isNil(error) && <ErrorPopoverPositioned error={error} />}

            <DataSourceContainer stretch="start" align="center">
              <DataSourceValue>{text}</DataSourceValue>

              {isEmptyText ? (
                <VscTriangleDown size={12} />
              ) : (
                <TextButton onClick={handleClearDataSet}>
                  <Image
                    src={`${envMap.VITE_ASSETS_URL}website/icons/Cross.svg`}
                    alt="minus"
                    size="xs"
                  />
                </TextButton>
              )}
            </DataSourceContainer>
          </ContainerStyled>
        </Inline>
      </Stack>
    );
  }
);

DataSourceLauncher.displayName = 'DataSourceLauncher';
