import { Control, UseFormSetValue } from 'react-hook-form';
import { Button, Dataset, NectedSuggestionModel, Typography } from 'ui';

import { useOpenJsonEditorSheet } from '../../../hooks/useOpenJsonEditor';
import { useOpenJsonEditorSheetV2 } from '../../../hooks/useOpenJsonEditorV2';
import { ContainerHeader } from './ResultHeader.styled';

type ResultHeaderProps = {
  isAdd?: boolean;
  nodeName?: string;
  control?: Control<any>;
  resIndex: number;
  suggestionsObjs?: NectedSuggestionModel[];
  executedValueName?: string;
  returnTypeName?: string;
  setOriginalValue?: UseFormSetValue<any>;
  disabled?: boolean;
  dataSet: Record<string, Dataset>;
  dataType?: string;
  onChangeSpecial?: (val: any) => void;
  overrideValue?: any;
  section?: 'thenDataParams' | 'elseDataParams';
  version?: 'v1' | 'v2';
  editorType?: 'list' | 'json';
};

export function ResultHeader({
  isAdd = true,
  nodeName = '',
  resIndex,
  control,
  suggestionsObjs = [],
  executedValueName,
  returnTypeName,
  setOriginalValue,
  disabled,
  dataSet,
  dataType = 'list',
  onChangeSpecial,
  overrideValue,
  section = 'thenDataParams',
  version = 'v1',
  editorType = 'list',
}: ResultHeaderProps) {
  const { openWithProps: openJsonEditor } = useOpenJsonEditorSheet({
    name: `${nodeName}.value`,
    control,
    index: resIndex,
    section,
    type: dataType,
  });

  const { openWithProps: openJsonEditorV2 } = useOpenJsonEditorSheetV2({
    index: 0,
    section: 'thenDataParams',
    type: dataType ?? 'string',
  });

  const onAddEditClick = () => {
    if (version === 'v2') {
      return openJsonEditorV2({
        suggestionsObjs,
        type: editorType,
        disabled,
        dataSet,
        onChangeSpecial,
        onClose: () => {
          if (typeof setOriginalValue === 'function') {
            setOriginalValue(`${nodeName}.attribute`, null);
            setOriginalValue(`${nodeName}.source`, null);
          }
        },
        overrideValue,
      });
    }
    openJsonEditor({
      suggestionObjs: suggestionsObjs,
      executedValueName,
      returnTypeName,
      setOriginalValue,
      type: editorType,
      disabled,
      dataSet,
      onChangeSpecial,
      onClose: () => {
        if (typeof setOriginalValue === 'function') {
          setOriginalValue(`${nodeName}.attribute`, null);
          setOriginalValue(`${nodeName}.source`, null);
        }
      },
      overrideValue,
    });
  };

  if (!isAdd) {
    return (
      <ContainerHeader $isEdit>
        <Typography name="paragraphSmall">
          Edit {editorType === 'list' ? 'list' : 'json'} here
        </Typography>
        <Button size="xs" onClick={onAddEditClick}>
          Edit {editorType === 'list' ? 'List' : 'JSON'}
        </Button>
      </ContainerHeader>
    );
  }

  return (
    <ContainerHeader>
      <Typography name="paragraphSmall">
        Don&apos;t want to use tokens?
      </Typography>
      <Button size="xs" onClick={onAddEditClick}>
        Add Custom {editorType === 'list' ? 'List' : 'JSON'}
      </Button>
    </ContainerHeader>
  );
}
