import type { UseControllerProps, UseFormSetValue } from 'react-hook-form';

import DecisionTableHeaders from './DecisionTableHeaders';
import DecisionTableRows from './DecisionTableRows';

type DecisionTableBlockProps = Omit<UseControllerProps, 'name'> & {
  setValue: UseFormSetValue<any>;
};

export function DecisionTableBlock({
  control,
  setValue,
}: DecisionTableBlockProps) {
  return (
    <>
      <DecisionTableHeaders control={control} setValue={setValue} />
      <DecisionTableRows control={control} setValue={setValue} />
    </>
  );
}
