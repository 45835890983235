import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import styled from 'styled-components';

export const ContainerStyled = styled(Stack)`
  padding-top: 1rem;
  text-align: center;
`;

export const WarningTextContainer = styled(Inline)`
  border-radius: 8px;
  background-color: var(--color-azalea);
  padding: 1.5rem;
`;
