import { Inline } from '@bedrock-layout/primitives';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _isUndefined from 'lodash/isUndefined';
import { Ref, forwardRef, useMemo } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { Image, Typography } from 'ui';

import { envMap } from '../../../../utils/constant';
import {
  DataTypeLauncher,
  LauncherContainer,
} from './DataTypeSelectionLauncher.styled';

type DataTypeSelectionLauncherProps = {
  label: string;
  setTabIndex?: (index: number) => void;
  error?: string;
  isDisabled?: boolean;
  dataType?: string;
};

export const DataTypeSelectionLauncher = forwardRef(
  (
    {
      label = '-',
      setTabIndex,
      error,
      isDisabled = false,
      dataType = '',
    }: DataTypeSelectionLauncherProps,
    ref: Ref<HTMLElement>
  ) => {
    const icon = useMemo(() => {
      switch (dataType) {
        case 'dataSet':
          return (
            <div onClick={() => !_isUndefined(setTabIndex) && setTabIndex(1)}>
              <Image
                src={`${envMap.VITE_ASSETS_URL}website/icons/datasets.svg`}
                alt="datasets"
              />
            </div>
          );
        case 'restAPI':
          return (
            <div onClick={() => !_isUndefined(setTabIndex) && setTabIndex(2)}>
              <Image
                src={`${envMap.VITE_ASSETS_URL}nalanda/connectors/svg/restAPI.svg`}
                alt="minus"
                size="small"
                round
              />
            </div>
          );
        default:
          return <FiChevronDown size={20} />;
      }
    }, [dataType]);

    return (
      <LauncherContainer ref={ref}>
        <DataTypeLauncher padding="0.8rem" $isDisabled={isDisabled}>
          <Inline align="center" stretch="start">
            <Typography>
              {!_isNil(label) && !_isEmpty(label) && label}
            </Typography>
            {icon}
          </Inline>
        </DataTypeLauncher>

        {!_isNil(error) && <Typography name="error">{error}</Typography>}
      </LauncherContainer>
    );
  }
);

DataTypeSelectionLauncher.displayName = 'DataTypeSelectionLauncher';
