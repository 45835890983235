import { atom, useAtom } from 'jotai';

import { Environment } from '../../../../utils/constant';
import type { RuleEnvironment } from '../../../../utils/constant';
import type { IntegrationFormValues } from '../../types';

export type IntegrationFormValuesAtomValue = {
  staging: IntegrationFormValues;
  production: IntegrationFormValues;
};

export const integrationFormValuesAtom = atom<IntegrationFormValuesAtomValue>({
  staging: {},
  production: {},
});

export type StoreFormValuesFunction = (
  formValues: IntegrationFormValues,
  currentEnvironment: RuleEnvironment
) => void;

export type UseStoreFormValuesReturn = {
  integrationFormValues: IntegrationFormValuesAtomValue;
  storeFormValues: StoreFormValuesFunction;
  clearIntegrationFormValues: () => void;
};

export function useStoreFormValues(): UseStoreFormValuesReturn {
  const [integrationFormValues, setIntegrationFormValues] = useAtom(
    integrationFormValuesAtom
  );

  const storeFormValues = (
    formValuesWithoutName: IntegrationFormValues,
    currentEnvironment: RuleEnvironment
  ) => {
    if (currentEnvironment === Environment.STAGING) {
      setIntegrationFormValues((prev) => ({
        ...prev,
        staging: formValuesWithoutName,
      }));
    } else {
      setIntegrationFormValues((prev) => ({
        ...prev,
        production: formValuesWithoutName,
      }));
    }
  };

  const clearIntegrationFormValues = () =>
    setIntegrationFormValues({ staging: {}, production: {} });

  return {
    integrationFormValues,
    storeFormValues,
    clearIntegrationFormValues,
  };
}
