import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { Dataset, getDataTypeNected } from 'ui';

import { getPropertyIfExists } from '../../../utils/common';

type UseUpdateExecutedValueRuleArgs = {
  updatedDataset: Record<string, Dataset>;
};

export function useUpdateExecutedValueRules({
  updatedDataset,
}: UseUpdateExecutedValueRuleArgs) {
  // This is the state that represents which values to find executed value for
  const [valuesToFindExecValues, setValuesToFindExecValues] = useState<
    Array<Record<string, any>>
  >([]);

  /**
   * This is the state that contains the tokens and it's corresponding values
   * based on the above state variable
   */
  const [executedValue, setExecutedValue] = useState<Record<string, any>>({});

  const handleGetExecutionValues = (values: Record<string, any>) => {
    const finalList = Object.keys(values).map((key) => {
      const newValue = key.replace('{{.', '').replace('}}', '');

      return {
        source: newValue.split('.')[0],
        attribute: newValue.split('.').splice(1).join('.'),
      };
    });

    setValuesToFindExecValues(finalList);
  };

  useEffect(() => {
    setExecutedValue(() => {
      const params: Record<string, any> = {};

      valuesToFindExecValues.forEach((attr) => {
        const { source, attribute } = attr;

        const mappedData =
          !_isNil(source) &&
          !_isEmpty(source) &&
          !_isEmpty(updatedDataset[source])
            ? getPropertyIfExists(
                JSON.parse(
                  JSON.stringify(
                    Object.keys(updatedDataset[source].attributes).reduce(
                      (acc, curr) => {
                        return {
                          ...acc,
                          [curr]:
                            updatedDataset[source].attributes[`${curr}`]
                              .executedValue,
                        };
                      },
                      {}
                    )
                  )
                ) ?? {},
                attribute
              )
            : undefined;

        params[`{{.${source as string}.${attribute as string}}}`] = {
          executedValue: mappedData,
          meta: getDataTypeNected(mappedData),
        };
      });

      return params;
    });
  }, [JSON.stringify(valuesToFindExecValues)]);

  return {
    handleGetExecutionValues,
    executedValue,
  };
}
