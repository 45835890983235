import { Inline } from '@bedrock-layout/primitives';

import { useSetVersionCount } from '../../../../hooks/useSetVersionCount';
import { ENTITY_ID } from '../../../../utils/constant';
import { EnvButtons } from '../../../Rules/components/RuleComponents/EnvButtons/EnvButtons';
import { Container } from './DsVersionInfo.styled';

type DsVersionInfoProps = {
  id: string;
  status?: string;
};

export function DsVersionInfo({ id, status = '' }: DsVersionInfoProps) {
  const { versionCount } = useSetVersionCount({
    id,
    entityType: ENTITY_ID.datasets,
    status,
  });

  return (
    <Container align="center" gutter="1rem" className="ds-version-info">
      <Inline />

      <EnvButtons count={versionCount} entityName="dataset" />
    </Container>
  );
}
