import { PadBox } from '@bedrock-layout/padbox';
import { Stack } from '@bedrock-layout/stack';
import styled from 'styled-components';

export const NodeContainerStyled = styled(Stack)`
  block-size: 100vh;
`;

export const FooterStyled = styled(PadBox)`
  border-block-start: 1px solid var(--color-lightGray);
  margin-top: auto;
`;

export const JsEditorContainer = styled(PadBox)`
  block-size: calc(100vh - 25rem);
  border: 1px solid var(--color-lightGray);
  border-radius: 0.4rem;
`;
