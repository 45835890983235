import type { Control } from 'react-hook-form';
import { TextField } from 'ui';

import { FLOATING_NUMBER_REGEX } from '../../../../../utils/regex';
import type { AddConfigValueType } from '../../../models';
import type { RemoteConfigFormValues } from '../../RemoteConfig';

type RemoteConfigTextFieldProps = {
  control: Control<RemoteConfigFormValues>;
  name: string;
  value: AddConfigValueType;
  disabled: boolean;
};

export function RemoteConfigNumberField({
  value,
  name,
  control,
  disabled,
}: RemoteConfigTextFieldProps) {
  return (
    <TextField
      defaultValue={value}
      name={name}
      type={'number'}
      rules={{
        required: 'Field is required',
        pattern: {
          value: FLOATING_NUMBER_REGEX,
          message: 'Invalid number',
        },
      }}
      showErrorIcon={false}
      control={control}
      disabled={disabled}
    />
  );
}
