import { Stack } from '@bedrock-layout/stack';
import styled from 'styled-components';

export const RowContainer = styled(Stack)`
  border-bottom: 1px solid var(--color-whiteSmoke);

  :last-child {
    border-bottom: none;
  }
`;

export const WorkspaceListContainer = styled.div`
  max-block-size: 40rem;
  overflow-y: auto;
`;
