import { useAtom } from 'jotai';
import { UseControllerProps, UseFormSetValue } from 'react-hook-form';
import { FiSettings } from 'react-icons/fi';
import { IconButton, useLayer } from 'ui';

import { createRuleSheetAtom } from '../../../index';
import { TriggerSheet } from '../TriggerSheet/TriggerSheet';
import { DotsStyled } from './TriggerMenu.styled';

export type TriggerMenuProps = Omit<UseControllerProps, 'name'> & {
  type: 'staging' | 'production';
  setValue?: UseFormSetValue<any>;
};

export function TriggerMenu({ control, type, setValue }: TriggerMenuProps) {
  const [ruleType] = useAtom(createRuleSheetAtom);

  const { open: openTriggerSheet } = useLayer(
    <TriggerSheet
      control={control}
      type={type}
      ruleType={ruleType}
      setValue={setValue}
    />
  );

  return (
    <IconButton onClick={openTriggerSheet} type="button">
      <DotsStyled>
        <FiSettings size={20} />
      </DotsStyled>
    </IconButton>
  );
}
