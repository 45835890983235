import { gql, useLazyQuery, useMutation } from '@apollo/client';

import { emptyQuery } from '../../../../utils/constant';

const createCrendentialQuery = gql`
  mutation createCredential(
    $name: String!
    $appType: ConnectorAppType!
    $plugin: String
    $authType: AuthenticationTypes!
    $type: CredentialTypes!
    $value: Map!
  ) {
    createCredential(
      input: {
        name: $name
        appType: $appType
        plugin: $plugin
        authType: $authType
        type: $type
        value: $value
      }
    ) {
      id
      type
      name
      value
      checksum
      isValid
      authCodeUrl
    }
  }
`;

const updateCrendentialQuery = gql`
  mutation updateCredential(
    $id: String!
    $appType: ConnectorAppType!
    $plugin: String
    $value: Map!
    $checksum: String!
  ) {
    updateCredential(
      input: {
        id: $id
        appType: $appType
        plugin: $plugin
        value: $value
        checksum: $checksum
      }
    ) {
      id
      name
      value
      checksum
      isValid
      authCodeUrl
    }
  }
`;

const getQueryBasedonMethod = (endpoint: string) => {
  // Dont use aliasing while writing the query

  switch (endpoint) {
    case 'createCredential':
      return createCrendentialQuery;
    case 'updateCredential':
      return updateCrendentialQuery;
    default:
      return emptyQuery;
  }
};

export function useGetButtonSubmitAction(endpoint: string, method: string) {
  if (method === 'query') {
    return useLazyQuery<Record<string, any>, Record<string, any>>(
      getQueryBasedonMethod(endpoint)
    );
  } else {
    return useMutation<Record<string, any>, Record<string, any>>(
      getQueryBasedonMethod(endpoint)
    );
  }
}
