import _isNil from 'lodash/isNil';

import { AttributeModel } from '../../../../pages/Rules/models';
import { dataSetTypeToExcludeRules } from './DatasetDataTypeMapping';

export const getFilteredAttributesBasedOnSelectedDataset = (
  attributesArray: AttributeModel[],
  dataSetType: string
) => {
  return attributesArray.filter((attribute) => {
    if (!_isNil(dataSetTypeToExcludeRules[dataSetType])) {
      const dataTypesToExclude =
        dataSetTypeToExcludeRules[dataSetType].excludes;

      if (dataTypesToExclude.includes(attribute.dataType?.value ?? '')) {
        return false;
      }
    }

    return true;
  });
};
