import { gql, useQuery } from '@apollo/client';
import _isNil from 'lodash/isNil';
import _reduce from 'lodash/reduce';
import { useEffect, useState } from 'react';

import type { PluginParams, SortedPluginParamsFieldKeys } from '../../types';
import {
  getAuthKeyConvertedList,
  getTransformedPluginParamsWithAuthKey,
  getTransformedSortedPluginParams,
} from '../connector/utils';
import type { PluginModel } from '../plugin/Plugin';
import { useGetLazyAuthConfigKeys } from './useGetLazyAuthConfigKeys';

const getConnectorQuery = gql`
  query getPlugin($pluginId: String!) {
    databasePlugin: getPlugin(id: $pluginId) {
      data {
        id
        name
        displayName
        imageUrl
        category
        isSource
        schema {
          integration {
            params
          }
        }
      }
    }
  }
`;

export type GetPluginQueryResult = {
  databasePlugin: {
    data: PluginModel[];
  };
};

export function useGetPlugin(pluginId: string) {
  const [sortedFields, setSortedFields] = useState<
    SortedPluginParamsFieldKeys[]
  >([]);
  const [formJson, setFormJson] = useState<PluginParams>({});
  const [isSource, setIsSource] = useState(false);
  const [category, setCategory] = useState('');
  const [shouldFormRender, setShouldFormRender] = useState(false);

  const [getAuthKeys, { data: authKeyList, refetch }] =
    useGetLazyAuthConfigKeys();

  const { data, loading, error } = useQuery<
    GetPluginQueryResult,
    {
      pluginId: string;
    }
  >(getConnectorQuery, {
    variables: {
      pluginId,
    },
  });

  // Transform and sort the params to render in ascending order
  useEffect(() => {
    if (!_isNil(data)) {
      const { isSource, category, schema } = data.databasePlugin.data[0];

      const pluginParams = _isNil(schema?.integration?.params)
        ? {}
        : { ...schema.integration.params };

      setCategory(category);
      setIsSource(isSource);

      setFormJson(pluginParams);
      setSortedFields(getTransformedSortedPluginParams(pluginParams));

      if (category !== 'api') {
        setShouldFormRender(true);
      }
    }
  }, [data]);

  useEffect(() => {
    if (category === 'api') {
      void getAuthKeys();
    }
  }, [category]);

  useEffect(() => {
    if (!_isNil(authKeyList) && !_isNil(data)) {
      const authKeys = getAuthKeyConvertedList(
        authKeyList.getAuthConfigKeys.data
      );

      let sortedFieldsCloned = getTransformedPluginParamsWithAuthKey(
        authKeys,
        sortedFields
      );

      const convertedParams = _reduce(
        sortedFieldsCloned,
        (acc: Record<string, SortedPluginParamsFieldKeys>, paramKeys) => {
          const { key } = paramKeys;

          acc[key] = paramKeys;

          return acc;
        },
        {}
      );

      sortedFieldsCloned = getTransformedSortedPluginParams(convertedParams);

      setSortedFields(sortedFieldsCloned);
      setShouldFormRender(true);
    }
  }, [authKeyList, data]);

  return {
    category,
    error,
    isSource,
    loading,
    sortedFields,
    formJson,
    shouldFormRender,
    refetch,
  };
}
