import { axiosVidhanPrivate } from '../../../../api/axios';
import { envMap } from '../../../../utils/constant';

const deploymentType = envMap.VITE_DEPLOYMENT_TYPE;
export const updateWidgetState = async (state: string, userid?: string) => {
  const environment =
    envMap.VITE_ENVIRONMENT === 'development' ? 'staging' : 'production';
  const apiConfig = {
    url: envMap.VITE_API_STATE_UPDATE,
    payload: {
      environment,
      isTest: false,
      params: {
        state,
        userid: userid ?? window.localStorage.getItem('userUUID'),
      },
    },
  };

  const defaultWorkspaceUUID = window.localStorage.getItem('workspaceUUID');
  const currentWorkspaceUUID = window.sessionStorage.getItem('workspaceUUID');

  if (defaultWorkspaceUUID === currentWorkspaceUUID) {
    if (deploymentType === 'self') {
      try {
        const response = await axiosVidhanPrivate.post(
          apiConfig.url,
          apiConfig.payload
        );
        const data = response?.data?.data?.output ?? [];

        return data;
      } catch (err: unknown) {
        return null;
      }
    }
  }

  return null;
};
