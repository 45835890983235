import { Inline } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { UseControllerProps, UseFormSetValue, useWatch } from 'react-hook-form';
import { Image, TextButton, TextField } from 'ui';

import { FieldByDataType } from '../../../../../components/FieldByDataType/FieldByDataType';
import {
  envMap,
  hideOptionalCustomAttributes,
} from '../../../../../utils/constant';
import { maxFiftyCharactersRule } from '../../../../../utils/validation';
import { isRuleReadOnlyAtom } from '../../../index';
import type { sendEventToGTMType } from '../../../types';
import { getRequiredKey } from '../../../utils/common';
import { dataSetParamsAtom } from '../../CreateRuleSheet/CreateRuleSheet';
import { DecisionTableResultRow } from '../types';
import { DataContainer } from './AdditionalData.styled';

type AddAdditionalDataProps = Omit<UseControllerProps, 'name'> & {
  index: number;
  handleDeleteData: (index: number) => void;
  handleSendEventToGTM: (obj: sendEventToGTMType) => void;
  setValue: UseFormSetValue<any>;
  dataType: string;
};

export function AdditionalData({
  control,
  index,
  handleDeleteData,
  handleSendEventToGTM,
  setValue,
  dataType,
}: AddAdditionalDataProps) {
  const [isRuleReadOnly] = useAtom(isRuleReadOnlyAtom);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [dataset] = useAtom(dataSetParamsAtom);

  const decisionTableResults: DecisionTableResultRow[] = useWatch({
    control,
    name: 'results',
  });

  const generateSuggestionsByData = (results: DecisionTableResultRow[]) => {
    const generatedSuggestions: string[] = [];
    results.forEach((result: DecisionTableResultRow) => {
      const key = getRequiredKey(result, ['id']);

      if (![''].includes(result[key].dataType)) {
        generatedSuggestions.push(`{{.outputDataList.${result[key].keyName}}}`);
      }
    });

    return generatedSuggestions;
  };

  useEffect(() => {
    if (decisionTableResults.length > 0) {
      setSuggestions(generateSuggestionsByData(decisionTableResults));
    }
  }, [decisionTableResults]);

  const handleClick = () => {
    if (typeof handleSendEventToGTM === 'function') {
      handleSendEventToGTM({
        action: 'edit',
        element: 'additional_data',
        actionName: '',
      });
    }
  };

  const handleDeleteClick = () => {
    handleDeleteData(index);

    if (typeof handleSendEventToGTM === 'function') {
      handleSendEventToGTM({
        action: 'delete',
        element: 'additional_data',
        actionName: '',
      });
    }
  };

  return (
    <Inline align="start" gutter="1rem">
      <Inline onClick={handleClick}>
        <TextField
          control={control}
          rules={{
            required: 'Required',
            maxLength: maxFiftyCharactersRule,
          }}
          name={`additionalData.${index}.name`}
          placeholder="Key"
          isSmallInput
          size="small"
          showErrorIcon={false}
          disabled={isRuleReadOnly}
        />
      </Inline>

      <DataContainer onClick={handleClick}>
        <FieldByDataType
          name={`additionalData.${index}.value`}
          dataType={dataType}
          control={control}
          showError
          index={(decisionTableResults ?? []).length}
          additionalDataIndex={index}
          suggestions={suggestions}
          disabled={isRuleReadOnly}
          section={'thenDataParams'}
          isAdditionalData
          hideOptionalCustomAttributes={hideOptionalCustomAttributes}
          returnTypeName={`additionalData.${index}.returnType`}
          executedValueName={`additionalData.${index}.executedValue`}
          setValue={setValue}
          dataSet={dataset}
        />

        {!isRuleReadOnly && (
          <DataContainer>
            <TextButton onClick={handleDeleteClick}>
              <Image
                src={`${envMap.VITE_ASSETS_URL}website/icons/trash.svg`}
                alt="minus"
                size="small"
              />
            </TextButton>
          </DataContainer>
        )}
      </DataContainer>
    </Inline>
  );
}
