import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export function Toaster() {
  return (
    <ToastContainer
      position="bottom-center"
      containerId="nectedToasterContainer"
      autoClose={3500}
      closeButton={true}
      icon={true}
      newestOnTop={true}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      limit={5}
      theme="colored"
      hideProgressBar={false}
    />
  );
}
