import { PadBox } from '@bedrock-layout/padbox';
import { Stack } from '@bedrock-layout/stack';
import styled from 'styled-components';

export const NodeContainerStyled = styled(Stack)``;

export const FooterStyled = styled(PadBox)`
  border-block-start: 1px solid var(--color-lightGray);
  margin-top: auto;
`;

export const ExcelEditorContainer = styled(PadBox)<{ $readOnly?: boolean }>`
  block-size: 45rem;
  border: 1px solid var(--color-lightGray);
  border-radius: 4px;
`;
