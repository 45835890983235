import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  Typography,
  useCurrentLayer,
} from 'ui';

type DeleteDataSetModalProps = {
  onDeleteClick?: (dataSetId?: string) => void;
  dataSetId?: string;
};

export function DeleteDataSetModal({
  onDeleteClick,
  dataSetId,
}: DeleteDataSetModalProps) {
  const { close: closeModal } = useCurrentLayer();

  return (
    <Modal size="medium" title="Delete Data Source">
      <ModalContent>
        <Typography>Are you sure you want to delete the dataSet?</Typography>
      </ModalContent>
      <ModalFooter>
        <Button onClick={closeModal} appearance="neutral">
          Cancel
        </Button>

        <Button
          onClick={() => {
            if (typeof onDeleteClick === 'function') {
              onDeleteClick(dataSetId ?? '');
            }

            closeModal();
          }}
          appearance="danger"
        >
          Delete
        </Button>
      </ModalFooter>
    </Modal>
  );
}
