import { Inline } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import { type UseControllerProps, useWatch } from 'react-hook-form';
import { CheckboxField } from 'ui';

import { isRuleReadOnlyAtom } from '../../../..';
import { MenuPopover } from './MenuPopover';
import { TableColumnStyled } from './TableColumns.styled';

export type MenuColumnProps = Omit<UseControllerProps, 'name'> & {
  index: number;
  handleInsertRow: (index: number) => void;
  handleDeleteRow: (index: number) => void;
};

export function MenuColumn({
  control,
  index,
  handleInsertRow,
  handleDeleteRow,
}: MenuColumnProps) {
  const [isRuleReadOnly] = useAtom(isRuleReadOnlyAtom);

  const isRuleEnable: boolean = useWatch({
    name: `ruleList.${index}.isEnabled`,
    control,
  });

  return (
    <TableColumnStyled width="20%" padding={[6, 10]}>
      <Inline gutter="1rem" align="center">
        <CheckboxField
          control={control}
          name={`ruleList.${index}.isEnabled`}
          useId={`${index}_isEnabled`}
          appearance="switch"
          checked={isRuleEnable}
          disabled={isRuleReadOnly}
        />
        <MenuPopover
          handleDeleteRow={handleDeleteRow}
          handleInsertRow={handleInsertRow}
          index={index}
        />
      </Inline>
    </TableColumnStyled>
  );
}
