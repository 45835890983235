import { PadBox } from '@bedrock-layout/padbox';
import styled from 'styled-components';

export const NodeAccordion = styled.div`
  block-size: 2.2rem;
  inline-size: 2.2rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;

  &:hover {
    background-color: var(--color-primary2);
  }
`;

export const NodeContainer = styled(PadBox)<{ $showHover?: boolean }>`
  ${({ $showHover = false }) =>
    $showHover
      ? `&:hover {
    background-color: var(--color-primary2);
  }`
      : `&:hover {
    cursor: not-allowed;
  }`}
`;
