import { useEffect, useState } from 'react';

import { generateUid } from '../utils/common';

type UseHandleRequestsArgs = {
  onResolve: (...props: any) => void;
  hasActivity?: boolean;
};

/**
 *
 * @param hasActivity this variable indicates whether it has any activity on going like saving or creating etc
 * @param onResolve this function executes when one request is resolved
 * @returns List of functions to append and clear requests and requests itself
 */

export function useHandleRequests({
  onResolve,
  hasActivity = false,
}: UseHandleRequestsArgs) {
  // This state keeps an ID associated with each request
  const [requests, setRequests] = useState<any[]>([]);

  const removeRequest = () => {
    setRequests((r) => {
      r.shift();

      return r;
    });
  };

  const clearRequests = () => {
    setRequests([]);
  };

  const removeRestOfRequests = () => {
    setRequests((r) => {
      if (r.length > 1) {
        return [r[r.length - 1]];
      }

      r.shift();

      return r;
    });
  };

  const appendRequest = (props: Record<string, any> = {}) => {
    setRequests((r) => [...r, { id: generateUid('request_'), ...props }]);
  };

  useEffect(() => {
    if (requests.length > 0 && !hasActivity) {
      void onResolve(requests[0]);
    }
  }, [JSON.stringify(requests.length)]);

  return {
    removeRequest,
    requests,
    appendRequest,
    removeRestOfRequests,
    clearRequests,
  };
}
