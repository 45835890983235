import { Inline, PadBox, Stack } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useMemo, useState } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { FaArrowTurnUp } from 'react-icons/fa6';
import { GoHourglass } from 'react-icons/go';
import { IoCodeSharp } from 'react-icons/io5';
import { LuRectangleHorizontal } from 'react-icons/lu';
import { MdOutlineAccountTree, MdOutlineRule } from 'react-icons/md';
import { TiArrowLoop } from 'react-icons/ti';
import { IconButton, Typography } from 'ui';

import { roleJsonAtom } from '../../../../../../components/authentication/router/AuthProvider';
import { useGetPlugins } from '../../../../../../hooks/graphql/useGetPlugins';
import type { RuleListDataModel } from '../../../../../Rules/components/RuleSet/models';
import { workflowEdgesAtom, workflowNodesAtom } from '../../../../atoms/atoms';
import type {
  ControlItemsType,
  ControlListType,
} from '../../../../models/models';
import {
  checkIfNodeHasSrRoot,
  getAllParentLoopNodesForCurrentNode,
  getDirectParentsSkippingType,
  getNextNode,
  getNodeNameByPluginName,
  getNodeNameByRuleName,
  nonAddTypeNodes,
} from '../../../../utils/common';
import { maxNestedLoopNodesAllowed } from '../../../../utils/constant';
import { ControlBlockItem } from './ControlBlockItem';
import { ControlItem } from './ControlItem';
import {
  ControlHeader,
  ControlList,
  ControlListContainer,
} from './ControlPopover.styled';
import { RuleCreateList } from './RuleCreateList/RuleCreateList';
import { RuleList } from './RuleList/RuleList';
import { WorkflowList } from './WorkflowList/WorkflowList';

type ControlPopoverProps = {
  data?: any;
  id: string;
  onClose: () => void;
};

export const DIFFERENT_TABS: ControlItemsType[] = [
  {
    header: 'Add Node',
    currentTab: 0,
  },
  {
    header: 'Add Rule',
    currentTab: 1,
    backTo: 0,
  },
  {
    header: 'Create Rule',
    currentTab: 2,
    backTo: 1,
  },
  {
    header: 'Add Connector',
    currentTab: 3,
    backTo: 0,
  },
  {
    header: 'Add Workflow',
    currentTab: 4,
    backTo: 0,
  },
];

export function ControlPopover({
  data = {},
  id,
  onClose,
}: ControlPopoverProps) {
  const [currentTab, setCurrentTab] = useState<ControlItemsType>({
    header: 'Add Node',
    currentTab: 0,
  });
  const [selectedPlugin] = useState('');

  const [controlList, setControlList] = useState<ControlListType[]>([
    {
      name: 'Rule',
      type: 'rule',
      entityId: 'rule',
      icon: <MdOutlineRule size={18} />,
    },
    {
      name: 'Workflow',
      type: 'workflow',
      entityId: 'workflow',
      icon: <MdOutlineRule size={18} />,
    },
    {
      name: 'Code',
      type: 'code',
      entityId: 'code',
      icon: <IoCodeSharp size={18} />,
    },
  ]);

  const [controlBlocks] = useState<ControlListType[]>([
    {
      name: 'Delay',
      type: 'delay',
      entityId: 'delay',
      icon: <GoHourglass size={18} />,
    },
    {
      name: 'Response',
      type: 'response',
      entityId: 'response',
      icon: <FaArrowTurnUp size={14} />,
    },
    {
      name: 'Set Variable',
      type: 'setVariableNode',
      entityId: 'setVariableNode',
      icon: <LuRectangleHorizontal />,
    },
    {
      name: 'Switch',
      type: 'switchNode',
      entityId: 'switchNode',
      icon: <MdOutlineAccountTree />,
    },
    {
      name: 'Loop',
      type: 'loopNode',
      entityId: 'loopNode',
      icon: <TiArrowLoop />,
      metaData: {
        disabled: false,
      },
    },
  ]);

  const [workflowNodes] = useAtom(workflowNodesAtom);
  const [workflowEdges] = useAtom(workflowEdgesAtom);
  const [roleJson] = useAtom(roleJsonAtom);

  const handleSelectType = (
    type: string,
    entityId?: string,
    metaData?: Record<string, any>
  ) => {
    switch (type) {
      case 'rule': {
        const item = DIFFERENT_TABS.find((tab) => tab.currentTab === 1);

        if (!_isNil(item)) {
          setCurrentTab(item);
        }

        break;
      }
      case 'createRule': {
        const item = DIFFERENT_TABS.find((tab) => tab.currentTab === 2);

        if (!_isNil(item)) {
          setCurrentTab(item);
        }

        break;
      }
      case 'selectedRuleType': {
        onClose();
        data.addNode({
          type: metaData?.type,
          id,
        });

        break;
      }
      case 'plugin': {
        const item = DIFFERENT_TABS.find((tab) => tab.currentTab === 3);

        if (!_isNil(item)) {
          handleAddConnector(metaData);
        }

        break;
      }
      case 'code': {
        onClose();
        data.addNode({
          type: 'codeNode',
          id,
        });

        break;
      }
      case 'delay': {
        onClose();
        data.addNode({
          type: 'delayNode',
          id,
        });

        break;
      }
      case 'setVariableNode': {
        onClose();
        data.addNode({
          type: 'setVariableNode',
          id,
        });
        break;
      }
      case 'workflow': {
        const item = DIFFERENT_TABS.find((tab) => tab.currentTab === 4);

        if (!_isNil(item)) {
          setCurrentTab(item);
        }
        break;
      }

      case 'response': {
        onClose();
        data.addNode({
          type: 'responseNode',
          id,
        });

        break;
      }
      case 'switchNode': {
        onClose();
        data.addNode({
          type: 'switchNode',
          id,
        });

        break;
      }
      case 'loopNode': {
        onClose();
        data.addNode({
          type: 'loopNode',
          id,
        });

        break;
      }
    }
  };

  const handleAddRule = (item: RuleListDataModel) => {
    onClose();

    data.addNode({
      type: getNodeNameByRuleName(item.type),
      id,
      ruleItem: item,
    });
  };

  const handleAddWorkflow = (item: any) => {
    onClose();

    data.addNode({
      type: 'workflowNode',
      id,
      workflowItem: item,
    });
  };

  const handleAddConnector = (item: any) => {
    onClose();
    data.addNode({
      type: getNodeNameByPluginName(item?.category),
      id,
      connectorItem: item,
      metaData: {
        name: item?.name,
      },
    });
  };

  const onBackClick = (backIndex: number) => {
    const item = DIFFERENT_TABS.find((tab) => tab.currentTab === backIndex);

    if (!_isNil(item)) {
      setCurrentTab(item);
    }
  };

  // **** Plugin Code *****

  const [getConnectorPlugins] = useGetPlugins({
    isSource: false,
  });

  const handleGetPluginList = async () => {
    try {
      const { data } = await getConnectorPlugins();

      if (!_isNil(data)) {
        const dataBasePlugins = data.getPlugin.data
          .filter((plugin) =>
            ['database', 'api', 'third-party'].includes(plugin.category)
          )
          .filter((plugin) => !['slack'].includes(plugin.name));

        const controlListPayload = [...controlList];

        dataBasePlugins.forEach((plugin) => {
          controlListPayload.push({
            name: plugin.displayName,
            type: 'plugin',
            entityId: plugin.name,
            icon: plugin.imageUrl,
            metaData: {
              pluginId: plugin.id,
              category: plugin.category,
              name: plugin.name,
            },
          });
        });
        setControlList(controlListPayload);
      }
    } catch {}
  };

  useEffect(() => {
    void handleGetPluginList();
  }, []);

  const nonAddNodeLength = nonAddTypeNodes(workflowNodes);

  const hasNodeLimitExceeded =
    nonAddNodeLength >=
      roleJson?.internals?.workflow?.limits?.maxNodes?.value ?? 50;

  const isParentResponse = useMemo(() => {
    let isResponse = false;
    const directParents = getDirectParentsSkippingType(
      workflowNodes,
      workflowEdges,
      id,
      'addNode'
    );

    isResponse = !(
      directParents.find((n) => n.type === 'responseNode') == null
    );

    // eslint-disable-next-line
    if (data.isMergeNode) {
      return false;
    }

    return isResponse;
  }, [JSON.stringify(workflowNodes)]);

  const filteredControlList = useMemo(() => {
    const updatedControlList = [...controlList];

    return updatedControlList;
  }, [
    JSON.stringify(workflowNodes.length),
    JSON.stringify(controlList.length),
  ]);

  const filteredControlBlocks = useMemo(() => {
    const directParents = getDirectParentsSkippingType(
      workflowNodes,
      workflowEdges,
      id,
      'addNode'
    );

    let updatedControlBlocks = [...controlBlocks];

    const rootSrNode = checkIfNodeHasSrRoot(data.rootId, workflowNodes);
    const nextNode = getNextNode(workflowNodes, workflowEdges, id);

    const directParentsOfNextNode = getDirectParentsSkippingType(
      workflowNodes,
      workflowEdges,
      nextNode?.id ?? '',
      'addNode'
    );

    const parentLoopNodes = getAllParentLoopNodesForCurrentNode(
      id,
      workflowNodes
    );

    if (
      !_isNil(parentLoopNodes) &&
      parentLoopNodes.length >= maxNestedLoopNodesAllowed
    ) {
      updatedControlBlocks = updatedControlBlocks.map((item) => {
        if (item.type === 'loopNode') {
          return {
            ...item,
            metaData: {
              disabled: true,
            },
          };
        }

        return item;
      });
    }

    if (!_isNil(rootSrNode)) {
      updatedControlBlocks = updatedControlBlocks.filter((item) => {
        // eslint-disable-next-line
        if (item.type === 'delay') {
          // eslint-disable-next-line
          return !!data.isMergeNode && !rootSrNode?.data.rootId ? true : false;
        }

        return true;
      });
    }

    if (
      !_isNil(rootSrNode) &&
      // eslint-disable-next-line
      !nextNode?.data.isMergeNode &&
      // eslint-disable-next-line
      !data.isMergeNode
    ) {
      updatedControlBlocks = updatedControlBlocks.filter((item) => {
        // eslint-disable-next-line
        if (item.type === 'response') {
          return false;
        }

        return true;
      });
    }

    // if grand Parent and parentLoopNodes for current node for current node then hide response
    // and delay node

    const parentId = data?.rootId;
    const parentNode = workflowNodes.find((n) => n.id === parentId);
    const grandParentId = parentNode?.data?.rootId;

    if (
      !_isNil(parentLoopNodes) &&
      !_isEmpty(parentLoopNodes) &&
      ((!_isNil(grandParentId) && !_isEmpty(grandParentId)) ||
        // eslint-disable-next-line
        !data.isMergeNode)
    ) {
      updatedControlBlocks = updatedControlBlocks.filter((item) => {
        // eslint-disable-next-line
        if (['response', 'delay'].includes(item.type)) {
          return false;
        }

        return true;
      });
    }

    if (
      !_isNil(rootSrNode) &&
      // eslint-disable-next-line
      nextNode?.data.isMergeNode &&
      // eslint-disable-next-line
      !data.isMergeNode &&
      directParentsOfNextNode.filter((n) => n.type !== 'responseNode').length <=
        1
    ) {
      updatedControlBlocks = updatedControlBlocks.filter((item) => {
        // eslint-disable-next-line
        if (item.type === 'response') {
          return false;
        }

        return true;
      });
    }

    if (
      !_isNil(rootSrNode) &&
      // eslint-disable-next-line
      !nextNode?.data.isMergeNode &&
      // eslint-disable-next-line
      !!data.isMergeNode &&
      // eslint-disable-next-line
      !!nextNode?.data.rootId
    ) {
      updatedControlBlocks = updatedControlBlocks.filter((item) => {
        // eslint-disable-next-line
        if (item.type === 'response') {
          return false;
        }

        return true;
      });
    }

    if (
      !_isNil(rootSrNode) &&
      // eslint-disable-next-line
      (nextNode?.data.isMergeNode || _isNil(nextNode)) &&
      // eslint-disable-next-line
      !!data.isMergeNode
    ) {
      updatedControlBlocks = updatedControlBlocks.filter((item) => {
        // eslint-disable-next-line
        if (item.type === 'response') {
          return true;
        }

        return true;
      });
    }

    if (
      !_isNil(directParents.find((p) => p.type === 'responseNode')) &&
      !_isNil(rootSrNode) &&
      // eslint-disable-next-line
      !data.isMergeNode &&
      // eslint-disable-next-line
      !!data.rootId
    ) {
      updatedControlBlocks = updatedControlBlocks.filter((i) => {
        return ['delay'].includes(i.type);
      });
    }

    if (
      _isNil(rootSrNode) &&
      directParents.find((p) => p.type === 'responseNode') != null
    ) {
      updatedControlBlocks = updatedControlBlocks.filter((i) => {
        return ['delay'].includes(i.type);
      });
    }

    return updatedControlBlocks;
  }, [JSON.stringify(workflowNodes)]);

  const componentByTab = useMemo(() => {
    switch (currentTab.currentTab) {
      case 0:
        return (
          <Stack>
            {!isParentResponse && (
              <ControlList padding="0.8rem">
                {filteredControlList.map((item, index) => (
                  <ControlItem
                    item={item}
                    key={index}
                    onItemClick={handleSelectType}
                  />
                ))}
              </ControlList>
            )}

            <PadBox padding={['1rem', 0]}>
              <Inline>
                {filteredControlBlocks.map((item, index) => (
                  <ControlBlockItem
                    item={item}
                    key={index}
                    onItemClick={handleSelectType}
                  />
                ))}
              </Inline>

              {isParentResponse && filteredControlBlocks.length === 0 && (
                <PadBox padding="1rem">
                  <Typography>No nodes available</Typography>
                </PadBox>
              )}
            </PadBox>
          </Stack>
        );

      case 1:
        return (
          <RuleList
            onRuleSelect={handleAddRule}
            onItemClick={handleSelectType}
          />
        );

      case 2:
        return <RuleCreateList onItemClick={handleSelectType} />;

      case 4:
        return (
          <WorkflowList
            onWorkflowSelect={handleAddWorkflow}
            onItemClick={handleSelectType}
          />
        );

      default:
        return null;
    }
  }, [currentTab, JSON.stringify(workflowNodes), controlList, selectedPlugin]);

  return (
    <ControlListContainer padding={0} className="nowheel">
      <ControlHeader padding="1rem">
        <Inline gutter="1rem" align="center">
          {!_isNil(currentTab.backTo) && (
            <IconButton onClick={() => onBackClick(currentTab.backTo ?? 0)}>
              <FaArrowLeft size={18} />
            </IconButton>
          )}

          <Typography>{currentTab.header}</Typography>
        </Inline>
      </ControlHeader>

      {!hasNodeLimitExceeded ? (
        componentByTab
      ) : (
        <Typography>
          {roleJson?.internals?.workflow?.limits?.maxNodes?.message ??
            'No nodes available'}
        </Typography>
      )}
    </ControlListContainer>
  );
}
