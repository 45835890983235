import { gql, useLazyQuery } from '@apollo/client';

import type {
  DemoEntityResult,
  FirstCreatedRuleResponse,
  FirstCreatedWorkflowResponse,
} from '../models';

const getFirstCreatedRule = gql`
  query getRuleData {
    getFirstRule: getRule(page: 1, perPage: 1) {
      data {
        createdAt
        updatedAt
        id
        name
        description
        type
      }
    }
  }
`;

export function useGetFirstCreatedRule() {
  return useLazyQuery<FirstCreatedRuleResponse>(getFirstCreatedRule, {
    fetchPolicy: 'cache-first',
  });
}

const getFirstCreatedWorkflow = gql`
  query getWorkflowData {
    getFirstWorkflow: getWorkflow(page: 1, perPage: 1) {
      data {
        createdAt
        updatedAt
        id
        name
        description
      }
    }
  }
`;

export function useGetFirstCreatedWorkflow() {
  return useLazyQuery<FirstCreatedWorkflowResponse>(getFirstCreatedWorkflow, {
    fetchPolicy: 'cache-first',
  });
}

const getDemoEntity = gql`
  query getDemoUsecaseData($filters: QueryOperators) {
    getDemoUseCases(perPage: 100, filters: $filters) {
      data {
        name
        id
        data
        description
        category
        icon
      }
    }
  }
`;

export function useGetDemoEntity() {
  return useLazyQuery<DemoEntityResult>(getDemoEntity);
}
