import { Inline } from '@bedrock-layout/inline';
import { Stack } from '@bedrock-layout/stack';
import { ReactNode, Ref, forwardRef } from 'react';
import { ThemeProvider } from 'styled-components';
import type { Placement } from 'tippy.js';

import { RadioInput } from '../Form/RadioInput';
import { PopoverMethods, PopoverPanel } from '../Popover';
import { TooltipReact } from '../TooltipReact';
import { Typography } from '../Typography';
import {
  ButtonIconStyled,
  ButtonTextStyled,
  LabelStyled,
  ListItemStyled,
  PopoverListContainer,
  SubtitleStyled,
} from './ButtonWithPopover.styled';

export type Appearance =
  | 'contained'
  | 'bordered'
  | 'filled'
  | 'text'
  | 'danger'
  | 'neutral'
  | 'borderedNeutral'
  | 'bordered-black';

export type Size = 'small' | 'medium' | 'large';

export type Type = 'button' | 'submit' | 'reset';

export type ButtonOptionItemType = {
  label: string;
  subTitle: string;
  id: string;
};

export type ButtonWithPopoverProps = {
  onClick?: (e?: any) => void;
  onOptionChange?: (id: string) => void;
  children?: ReactNode;
  label?: ReactNode;
  appearance?: Appearance;
  size?: Size;
  disabled?: boolean;
  type?: Type;
  trailingIcon: ReactNode;
  buttonOptionList?: ButtonOptionItemType[];
  placement?: Placement;
  selectedOptionId?: string;
  hoverText?: string;
};

export const ButtonWithPopover = forwardRef(
  (
    {
      onClick,
      onOptionChange,
      children,
      label,
      type = 'button',
      appearance = 'contained',
      size = 'small',
      placement = 'top-start',
      disabled = false,
      trailingIcon,
      buttonOptionList,
      selectedOptionId,
      hoverText,
    }: ButtonWithPopoverProps,
    ref: Ref<PopoverMethods>
  ) => {
    const text = children ?? label;

    const handleOptionChange = (id: string) => {
      if (typeof onOptionChange === 'function') {
        onOptionChange(id);
      }
    };

    return (
      <ThemeProvider theme={{ appearance, size }}>
        <Inline align="center" justify="center" gutter="0.1rem">
          <TooltipReact
            id={selectedOptionId ?? ''}
            launcher={
              <ButtonTextStyled
                type={type}
                disabled={disabled}
                onClick={onClick}
              >
                {text}
              </ButtonTextStyled>
            }
          >
            <Typography>{hoverText}</Typography>
          </TooltipReact>

          <PopoverPanel
            trigger="click"
            placement={placement}
            launcher={<ButtonIconStyled>{trailingIcon}</ButtonIconStyled>}
            ref={ref}
            padding={0}
          >
            <PopoverListContainer>
              {buttonOptionList?.map((option, index) => (
                <ListItemStyled
                  key={index}
                  isSelected={selectedOptionId === option.id}
                  onClick={() => handleOptionChange(option.id)}
                >
                  <Stack>
                    <LabelStyled>{option.label}</LabelStyled>
                    <SubtitleStyled>{option.subTitle}</SubtitleStyled>
                  </Stack>
                  <RadioInput
                    displayRadio={true}
                    checked={selectedOptionId === option.id}
                    id={selectedOptionId ?? ''}
                    value={selectedOptionId ?? ''}
                  />
                </ListItemStyled>
              ))}
            </PopoverListContainer>
          </PopoverPanel>
        </Inline>
      </ThemeProvider>
    );
  }
);

ButtonWithPopover.displayName = 'ButtonWithPopover';
