import { Inline } from '@bedrock-layout/primitives';
import { motion } from 'framer-motion';
import { Control } from 'react-hook-form';
import { FiRefreshCcw } from 'react-icons/fi';
import { MdOutlineEdit } from 'react-icons/md';
import { DropdownField, IconButton, Typography } from 'ui';

import { AddButton } from '../AddButton';
import { ActionIcon, CreateConnector } from './RuleListField.styled';

type RuleListFieldProps = {
  control?: Control<any>;
  ruleList?: any[];
  editRule: () => void;
  refresh: () => void;
  name: string;
  disabled?: boolean;
  showEdit?: boolean;
  showRefresh?: boolean;
  isLoading?: boolean;
  ruleType?: string;
};

export function RuleListField({
  control,
  ruleList = [],
  refresh,
  editRule,
  name,
  disabled = false,
  showEdit = true,
  showRefresh = true,
  isLoading = false,
  ruleType = 'simpleRule',
}: RuleListFieldProps) {
  const MenuListFooter = () => (
    <CreateConnector
      onClick={() => {
        const link = `${window.location.origin}/rules?modal=create`;

        window.open(link, window !== window.parent ? '_self' : '_blank');
      }}
      gutter={8}
    >
      <AddButton />

      <Typography>Create Rule</Typography>
    </CreateConnector>
  );

  return (
    <Inline gutter={8} stretch="start" align="end">
      <div>
        <Inline gutter={8}>
          <Typography fontWeight={700}>Rule</Typography>
        </Inline>
        <DropdownField
          control={control}
          name={name}
          options={ruleList}
          MenuListFooter={<MenuListFooter />}
          disabled={disabled}
          showCustomMenuV2
        />
      </div>

      {showEdit && (
        <ActionIcon type="button" onClick={editRule}>
          <MdOutlineEdit />
        </ActionIcon>
      )}

      {showRefresh && (
        <ActionIcon type="button">
          <motion.div
            animate={{
              rotate: isLoading ? 360 : 0,
            }}
            transition={{
              duration: isLoading ? 2 : 0,
              repeat: isLoading ? Infinity : 0,
              ease: 'linear',
            }}
          >
            <IconButton
              onClick={() => {
                if (!disabled) {
                  void refresh();
                }
              }}
            >
              <FiRefreshCcw />
            </IconButton>
          </motion.div>
        </ActionIcon>
      )}
    </Inline>
  );
}
