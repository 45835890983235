import type { ObservableQuery } from '@apollo/client';
import _isNil from 'lodash/isNil';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  Typography,
  toasts,
  useCurrentLayer,
} from 'ui';

import {
  handleGetCheckSumByEntityName,
  showGraphQlErrorToast,
} from '../../../../utils/common';
import { useDeleteConnector } from '../hooks/useDeleteConnector';
import type { GetConnectorsQueryResult } from '../hooks/useGetConnectors';

type DeleteConnectorModalProps = {
  refetch: ObservableQuery<GetConnectorsQueryResult>['refetch'];
  connectorId?: string;
};

export const DeleteConnectorModal = ({
  connectorId,
  refetch,
}: DeleteConnectorModalProps) => {
  const navigate = useNavigate();
  const { close: closeModal } = useCurrentLayer();
  const [deleteConnector] = useDeleteConnector();

  const handleDeleteConnector = async (id: string) => {
    try {
      const checksum = handleGetCheckSumByEntityName('integrations');

      await deleteConnector({
        variables: {
          id,
          checksum: checksum ?? '',
        },
      });

      closeModal();
      toasts.success('Deleted successfully', 'deleted-success');

      await refetch();
      navigate('/integrations');
    } catch (error) {
      closeModal();
      showGraphQlErrorToast(error);
    }
  };

  return (
    <Modal size="medium" title="Delete Connector">
      <ModalContent>
        <Typography name="paragraph">
          Are you sure you want to delete the connector?
        </Typography>
      </ModalContent>

      <ModalFooter>
        <Button appearance="neutral" onClick={closeModal}>
          Cancel
        </Button>

        {!_isNil(connectorId) && (
          <Button
            onClick={async () => await handleDeleteConnector(connectorId)}
            appearance="danger"
          >
            Delete
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};
