import { BiSolidError } from 'react-icons/bi';
import { FaCheckCircle, FaMinusCircle } from 'react-icons/fa';
import { IoMdCloseCircle } from 'react-icons/io';
import { TooltipReact, Typography } from 'ui';

import { StatusContainer } from './NodeStatus.styled';

type NodeStatusProps = {
  status?: string;
  showError?: boolean;
  tooltipId?: string;
  errorTooltipText?: string;
};

export function NodeStatus({
  status = '',
  showError = false,
  tooltipId = '',
  errorTooltipText = '',
}: NodeStatusProps) {
  const currentStatus = listOfStatus[status];

  return (
    <StatusContainer>
      {!showError ? (
        currentStatus ?? (
          <TooltipReact
            id="Hello"
            launcher={<FaMinusCircle fontSize={16} color="orange" />}
          >
            <Typography>This node is not tested</Typography>
          </TooltipReact>
        )
      ) : (
        <div>
          <TooltipReact
            id={tooltipId}
            launcher={
              <BiSolidError size={20} color="var(--color-fireEngineRed)" />
            }
          >
            <Typography>{errorTooltipText}</Typography>
          </TooltipReact>
        </div>
      )}
    </StatusContainer>
  );
}

const listOfStatus: Record<string, any> = {
  success: <FaCheckCircle fontSize={16} color="green" />,
  error: <IoMdCloseCircle fontSize={16} color="red" />,
};
