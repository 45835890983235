import { useAtom } from 'jotai';
import _isUndefined from 'lodash/isUndefined';
import { useEffect, useState } from 'react';
import { Image } from 'ui';

import { currentWorkspaceDetailAtom } from '../../pages/Workspace/atom';
import type { PanelType } from '../../types';
import { checkIfThemeEnabled, isValidImageURL } from '../../utils/common';
import { envMap } from '../../utils/constant';
import { PanelContainerStyled, PanelItem } from './Panel.styled';
import { panelList as originalPanelList } from './constant';

type PanelProps = {
  defaultActivePanel?: PanelType;
  onPanelItemClick?: (id?: PanelType) => void;
};

export function Panel({ defaultActivePanel, onPanelItemClick }: PanelProps) {
  const [currentWorkspace] = useAtom(currentWorkspaceDetailAtom);
  const isWhiteLabelled = checkIfThemeEnabled(
    currentWorkspace?.whiteLabelEnabled,
    envMap.VITE_FULL_WHITELABELLED === 'true'
  );
  const filteredPanelList = isWhiteLabelled
    ? originalPanelList.filter((panel) => panel.id !== 'faqMenu')
    : originalPanelList;

  const defaultItemIndex = filteredPanelList.findIndex(
    (panelObj) => panelObj.id === defaultActivePanel
  );

  const [activeItemIndex, setActiveItemIndex] = useState(defaultItemIndex);

  useEffect(() => {
    if (typeof onPanelItemClick === 'function') {
      onPanelItemClick(filteredPanelList[activeItemIndex]?.id as PanelType);
    }
  }, [activeItemIndex]);

  useEffect(() => {
    if (!_isUndefined(defaultActivePanel)) {
      const index = filteredPanelList.findIndex(
        (panelObj) => panelObj.id === defaultActivePanel
      );
      setActiveItemIndex(index);
    }
  }, [defaultActivePanel]);

  const updateActivePanel = (index: number) => {
    if (index === activeItemIndex) {
      setActiveItemIndex(-1);

      return;
    }
    setActiveItemIndex(index);
  };

  return (
    <PanelContainerStyled>
      {filteredPanelList.map((currentPanel, index) => (
        <PanelItem
          key={currentPanel.id}
          $active={activeItemIndex === index}
          justify="center"
          align="center"
          onClick={() => updateActivePanel(index)}
        >
          {typeof currentPanel.Icon === 'string' ? (
            <Image src={isValidImageURL(currentPanel.Icon)} alt="connector" />
          ) : (
            <currentPanel.Icon
              size={24}
              color={
                activeItemIndex === index
                  ? 'var(--color-primary1)'
                  : 'var(--color-darkGray)'
              }
            />
          )}
        </PanelItem>
      ))}
    </PanelContainerStyled>
  );
}
