import { useEffect, useState } from 'react';
import { toasts } from 'ui';

import { useAxiosPrivate } from '../../../../hooks';

export function useGetWhitelistIps() {
  const { axiosPrivate } = useAxiosPrivate();

  const [whitelistIPs, setWhitelistIPs] = useState<string[]>([]);

  useEffect(() => {
    const getWhitelistIps = async () => {
      try {
        const { data } = await axiosPrivate('/vidhaan/agent');

        setWhitelistIPs(data.data.whitelistIps);
      } catch (error) {
        toasts.error(
          'Unable to fetch whitelisting Ips',
          'something-went-wrong'
        );
      }
    };

    void getWhitelistIps();
  }, []);

  return whitelistIPs;
}
