import { useMutation } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import { toasts } from 'ui';

import { useAxiosPrivate } from '../../../hooks';
import { showErrorToast } from '../../../utils/common';
import type { CustomAxiosError } from '../../../utils/response/types';

export function useSendEmailVerificationLink() {
  const { axiosPrivate } = useAxiosPrivate();

  const { mutate: sendVerificationLink } = useMutation({
    mutationFn: async () => {
      return await axiosPrivate.post('/auth/verify/email/resend');
    },
    onSuccess() {
      toasts.success('Email verification link has been sent', 'success');
    },
    onError(error: AxiosError<CustomAxiosError>) {
      showErrorToast(error);
    },
  });

  return {
    sendVerificationLink,
  };
}
