import type { ObservableQuery } from '@apollo/client';
import type { UseControllerProps } from 'react-hook-form';
import { DropdownField } from 'ui';

import type { Option, PluginFieldCommonProps } from '../../../../types';
import { PluginFieldContainer } from '../../../common/PluginFieldContainer';

type PluginDropdownFieldProps = UseControllerProps<any> &
  PluginFieldCommonProps & {
    options?: Option[];
    refetchAuthKeys?: ObservableQuery<any>['refetch'];
    footer?: JSX.Element;
    isMulti?: boolean;
    onSelect?: (value: any, oldValue?: any) => void;
  };

export function PluginDropDownField({
  errorMessage = 'Please enter a valid input',
  control,
  label,
  value,
  name,
  required = false,
  regex,
  placeholder,
  disabled = false,
  options = [],
  isMulti = false,
  refetchAuthKeys,
  footer,
  tooltipText,
  onSelect,
}: PluginDropdownFieldProps) {
  return (
    <PluginFieldContainer
      label={label}
      required={required}
      tooltipText={tooltipText}
    >
      <DropdownField
        defaultValue={value}
        name={name}
        options={options}
        rules={{
          required: required ? 'Field is required' : false,
          pattern: {
            value: regex,
            message: errorMessage,
          },
        }}
        control={control}
        placeholder={placeholder}
        disabled={disabled}
        MenuListFooter={footer}
        isMulti={isMulti}
        onSelect={onSelect}
      />
    </PluginFieldContainer>
  );
}
