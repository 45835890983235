import _isNil from 'lodash/isNil';
import { useEffect } from 'react';

type UseUpdateAttributesOnResize = {
  attributes: Array<keyof DOMRect>;
  containerClassName: string;
  dependentClassNameList: string[];
};

/**
 * @attribute list of attibutes that needs to calculated e.g. width, height
 * @containerClassName className of the div element whose attributes need to be calculated on window resize
 * @dependentClassNameList list of className of the div on which containerClassName is depending
 **/
export function useUpdateAttributesOnResize({
  attributes,
  containerClassName,
  dependentClassNameList,
}: UseUpdateAttributesOnResize) {
  const containerElement = document.querySelector(
    `.${containerClassName}`
  ) as HTMLDivElement;

  const dependentElementList = dependentClassNameList.map(
    (name) => document.querySelector(`.${name}`) as HTMLDivElement
  );

  useEffect(() => {
    return () => {
      window.removeEventListener('resize', calculateAttributes);
    };
  }, []);

  useEffect(() => {
    if (!_isNil(containerElement) && !_isNil(dependentElementList)) {
      calculateAttributes();

      window.addEventListener('resize', calculateAttributes);
    }
  }, [containerElement, dependentElementList]);

  const calculateAttributes = () => {
    if (!_isNil(containerElement) && !_isNil(dependentElementList)) {
      attributes.forEach((attribute) => {
        let size = 0;
        dependentElementList.forEach((element: HTMLDivElement) => {
          if (!_isNil(element)) {
            size += element.getBoundingClientRect()[
              attribute
            ] as unknown as number;
          }
        });

        containerElement.style.setProperty(
          attribute,
          `calc(100vh - ${size}px )`
        );
      });
    }
  };

  return {};
}
