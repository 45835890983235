import type { ObservableQuery } from '@apollo/client';
import type { AxiosResponse } from 'axios';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { type UseControllerProps, useWatch } from 'react-hook-form';
import { AsyncDropdownField, NectedSuggestionModel } from 'ui';

import { useAxiosPrivate } from '../../../hooks';
import { PluginFieldContainer } from '../../../pages/Integrations/components/common/PluginFieldContainer';
import type { PluginFieldCommonProps } from '../../../pages/Integrations/types';
import type { Option } from '../types';
import { convertBodyParams } from '../utlis';

type AsyncPluginDropdownFieldProps = UseControllerProps<any> &
  PluginFieldCommonProps & {
    options?: Option[];
    refetchAuthKeys?: ObservableQuery<any>['refetch'];
    footer?: JSX.Element;
    isMulti?: boolean;
    api?: boolean;
    apiSpec?: Record<string, any>;
    getDropdownOptions?: (obj?: any) => Promise<void>;
    parentFormData?: Record<string, any>;
    formJson: Record<string, any>;
    customSuggestions?: NectedSuggestionModel[];
  };

export function WebAsyncDropDownField({
  errorMessage = 'Please enter a valid input',
  control,
  label,
  value,
  name,
  required = false,
  regex,
  placeholder,
  disabled = false,
  options = [],
  apiSpec = {},
  footer,
  tooltipText,
  getDropdownOptions = async () => {},
  parentFormData = {},
  formJson,
  customSuggestions = [],
  formKeyPrefix,
}: AsyncPluginDropdownFieldProps) {
  const formValues = useWatch({
    control,
  });

  const { axiosPrivate } = useAxiosPrivate();

  const makeLabelValuePair = (data: any[]) => {
    if (_isEmpty(data)) {
      return [];
    }
    const options: any[] = data.map((item: any) => {
      return {
        ...item,
        value: item.value,
        label: item.label,
      };
    });

    return options;
  };

  // Do not remove as tokens will be used later on.
  // const tokenOptions = customSuggestions.map((suggesstion) => {
  //   return {
  //     label: suggesstion,
  //     value: suggesstion,
  //   };
  // });

  const fetchDataFromRestApi = async () => {
    let response: AxiosResponse<any, any> | null = null;
    const { method, endpoint, bodyParams = {}, fetchParent = {} } = apiSpec;

    try {
      if (method === 'GET') {
        response = await axiosPrivate.get(endpoint);
      } else if (method === 'POST') {
        const updatedBodyParams = convertBodyParams(
          bodyParams,
          fetchParent,
          formValues,
          formJson,
          parentFormData,
          formKeyPrefix
        );

        response = await axiosPrivate.post(endpoint, updatedBodyParams);
      }

      return response;
    } catch (err) {
      return err;
    }
  };

  const getOptions = async (
    searchText: string,
    callback: (data: any[]) => void
  ) => {
    const { requestType } = apiSpec;

    let response: any = null;

    if (requestType === 'rest') {
      response = await fetchDataFromRestApi();

      // Do not remove as tokens will be used later on.

      // const tokensList = customSuggestions
      //   .filter((suggesstion) => suggesstion.includes(searchText))
      //   .map((suggesstion) => {
      //     return {
      //       label: suggesstion,
      //       value: suggesstion,
      //     };
      //   });

      if (response?.data.code === 'success') {
        response = response?.data?.data?.result ?? null;

        if (!_isNil(response)) {
          callback(makeLabelValuePair(response));
        }
      }
    }
  };

  return (
    <PluginFieldContainer
      label={label}
      required={required}
      tooltipText={tooltipText}
    >
      <AsyncDropdownField
        defaultValue={value}
        name={name}
        options={options}
        rules={{
          required: {
            value: required,
            message: 'Required Field',
          },
          pattern: {
            value: regex,
            message: errorMessage,
          },
        }}
        control={control}
        placeholder={placeholder}
        disabled={disabled}
        MenuListFooter={footer}
        getDropdownOptions={getOptions}
        // showCustomMenu={customSuggestions.length > 0} Will uncomment this when tokens will be used
      />
    </PluginFieldContainer>
  );
}
