import { Ref } from 'react';
import { useController } from 'react-hook-form';
import type { FieldValues, UseControllerProps } from 'react-hook-form';
import { MonthDayPicker, MonthDayPickerProps } from 'ui';

import { Field } from '../Field';

export type MonthDayFieldPropTypes<P extends FieldValues> =
  UseControllerProps<P> &
    MonthDayPickerProps & {
      ref?: Ref<HTMLElement>;
      showError?: boolean;
      heightFull?: boolean;
      widthFull?: boolean;
    };

export const MonthDayField = <P extends FieldValues>(
  props: MonthDayFieldPropTypes<P>
) => {
  const {
    name,
    control,
    ref,
    rules,
    defaultValue,
    showError,
    heightFull = false,
    widthFull = false,
    ...rest
  } = props;
  const { field, fieldState } = useController({
    name,
    control,
    rules,
    defaultValue,
  });

  return (
    <Field
      fieldState={fieldState}
      showError={showError}
      heightFull={heightFull}
      widthFull={widthFull}
    >
      <MonthDayPicker {...field} {...rest} />
    </Field>
  );
};
