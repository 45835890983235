import { Inline, Stack } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import { IoIosCheckmarkCircle, IoMdCloseCircle } from 'react-icons/io';
import { Typography } from 'ui';

import { siteConstantsAtom } from '../../../../../../atom';
import { getTooltipText } from '../../../../../../utils/common';
import type { WorkflowNodeType } from '../../../../hooks/useOpenWorkflow';
import { WrappedName } from '../WorkflowTestSheet.styled';

type WorkflowKeyProps = {
  node?: WorkflowNodeType;
  isSuccess: boolean;
  name: string;
};

export function WorkflowKey({ node, isSuccess, name }: WorkflowKeyProps) {
  const [siteConstants] = useAtom(siteConstantsAtom);

  return (
    <Stack gutter="1rem">
      <Inline stretch="start" gutter="1rem">
        <WrappedName>{name}</WrappedName>

        {isSuccess ? (
          <IoIosCheckmarkCircle size={24} color="green" />
        ) : (
          <IoMdCloseCircle fontSize={24} color="red" />
        )}
      </Inline>

      {(node?.data.runInLoop?.status as unknown as boolean) && (
        <Typography name="paragraphSmall">
          {getTooltipText(
            siteConstants,
            'workflow',
            'ruleRunInLoop',
            'otherText'
          )}
        </Typography>
      )}

      {node?.type === 'dbNode' && (
        <Typography name="paragraphSmall">
          {getTooltipText(
            siteConstants,
            'workflow',
            'dbTestHelpText',
            'otherText'
          )}
        </Typography>
      )}
    </Stack>
  );
}
