import { Helmet } from 'react-helmet';

import { useGetSiteMeta } from '../../hooks/useGetSiteMeta';
import { QuestionnaireForm } from './components/QuestionnaireForm';

export const Questionnaire = () => {
  sessionStorage.removeItem('questionnaireData');
  const { siteMeta } = useGetSiteMeta();

  return (
    <>
      <Helmet>
        <title>
          {siteMeta[window.location.pathname.substring(1)]?.title ?? ''}
        </title>
        <meta
          name="description"
          content={
            siteMeta[window.location.pathname.substring(1)]?.description ?? ''
          }
        />
      </Helmet>
      <QuestionnaireForm />
    </>
  );
};
