import { useMutation } from '@tanstack/react-query';
import type { AxiosError } from 'axios';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';

import { useAxiosPrivate } from '../../../../hooks';
import { showErrorToast } from '../../../../utils/common';
import type { CustomAxiosError } from '../../../../utils/response/types';
import type {
  EntityLevelMemberApiParams,
  EntityLevelMemberDetailsType,
} from '../../component/types';

export function useGetEntityMemberList() {
  const { axiosPrivate } = useAxiosPrivate();

  const {
    isLoading,
    data,
    error,
    mutateAsync: getEntityMemberList,
  } = useMutation({
    mutationFn: async ({
      entityType,
      id,
      roles = '',
    }: EntityLevelMemberApiParams) => {
      let apiUrl = `/entity-access/${entityType}/${id}`;

      if (!_isNil(roles) && !_isEmpty(roles)) {
        apiUrl += `?roles=${roles}`;
      }

      const response = await axiosPrivate.get<{
        data: EntityLevelMemberDetailsType[];
      }>(apiUrl);

      return response.data.data;
    },
    onError(error: AxiosError<CustomAxiosError>) {
      showErrorToast(error);
    },
  });

  return {
    isLoading,
    data,
    error,
    getEntityMemberList,
  };
}
