import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { BsArrowRight } from 'react-icons/bs';
import styled from 'styled-components';
import { Typography } from 'ui';

export const WigdetContainer = styled(PadBox)<{
  width: string;
  height: string;
  styles: any;
}>`
  ${({ width }) => width !== '' && `width: ${width}`};
  ${({ height }) => height !== '' && `height: ${height}`};
  border-radius: 1rem;
  ${({ styles }) => styles};
`;

export const InlineStyled = styled(Inline)`
  justify-content: space-between;
`;

export const ContentContainerStyles = styled(Inline)`
  justify-content: space-between;
  margin-top: 2rem;
`;

export const TitleContainer = styled(Typography)`
  margin-top: 0rem;
`;

export const CBTitleContainer = styled(Typography)`
  margin-top: 1rem;
`;

export const StackTitleContainer = styled(Stack)`
  gap: 0.5rem;
  flex: 1;
`;

export const SubHeaderContainer = styled(Stack)`
  max-inline-size: inherit;
`;

export const CustomArrowIcon = styled.svg`
  font-size: 1.5rem;
  &:hover {
    background-color: var(--color-solitudeSecondary);
  }
`;

export const ArrowRightStyled = styled(BsArrowRight)`
  min-inline-size: 24px;
  min-block-size: 24px;
`;

export const CardBlocksTitle = styled(Typography)`
  padding-top: 1rem;
`;
