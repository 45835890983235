import { useAtom } from 'jotai';

import { ruleWarningsAtom } from '..';
import { AttributeModel } from '../models';
import { isTokenMappedInCI } from '../utils/common';

export function useCIWarning() {
  const [, setRuleWarnings] = useAtom(ruleWarningsAtom);

  const handleSetWarning = (
    customInput: Record<string, AttributeModel>,
    dataSetId?: string
  ) => {
    const isTokenMapped = isTokenMappedInCI(customInput, dataSetId);

    if (!isTokenMapped) {
      setRuleWarnings((prev) => {
        if (
          prev?.includes(
            'You have not mapped any primary keys in dataset. Without primary key mapping, this rule will fetch the whole data as per your dataset query.'
          )
        ) {
          return prev;
        }

        return [
          ...(prev ?? []),
          'You have not mapped any primary keys in dataset. Without primary key mapping, this rule will fetch the whole data as per your dataset query.',
        ];
      });
    } else {
      setRuleWarnings((prev) =>
        prev?.filter(
          (p) =>
            p !==
            'You have not mapped any primary keys in dataset. Without primary key mapping, this rule will fetch the whole data as per your dataset query.'
        )
      );
    }
  };

  return {
    handleSetWarning,
  };
}
