import { PadBox } from '@bedrock-layout/padbox';
import styled from 'styled-components';
import { Typography } from 'ui';

export const Indicator = styled(PadBox)`
  background-color: var(--color-cyprus);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const IndicatorText = styled(Typography)`
  font-weight: 600;
  color: var(--color-darkGreen);
`;
