import _isNil from 'lodash/isNil';
import styled from 'styled-components';

import { Typography } from '../Typography';

export const TreeWrapper = styled.div`
  width: 100%;
  height: 3.2rem;
  padding: 0rem 0.4rem;
  display: flex;
`;

export const TreeSectionWrapper = styled.div<{
  $paddingLeft?: string;
  $borderRight?: string;
  $width?: string;
}>`
  ${({ $width = '50%' }) => `width: ${$width};`}
  ${({ $paddingLeft = '0rem' }) => `padding-left: ${$paddingLeft};`}
  ${({ $borderRight }) =>
    !_isNil($borderRight) && `border-right: ${$borderRight};`}
`;

export const TreeSectionKey = styled.div<{ $borderLeft: string }>`
  padding-left: 0.5rem;
  ${({ $borderLeft = '1px solid gray' }) => `border-left: ${$borderLeft};`}
  height: 100%;
  padding-top: 0.2rem;
`;

export const TreeAction = styled.div`
  width: 3rem;
  cursor: pointer;
`;

export const EllipsisTypography = styled(Typography)`
  display: inline-block;
  white-space: nowrap;
  width: 100%;
  overflow: hidden !important;
  text-overflow: ellipsis;
`;

export const TextContainer = styled.div`
  max-block-size: 15rem;
  max-inline-size: 25rem;
  overflow-y: auto;
  word-break: break-all;
`;
