import { StatusCode } from './statusCode';

export const StatusMessage: { [K in keyof typeof StatusCode]: string } =
  Object.freeze({
    ACCESS_TOKEN: '',
    ACCESS_TOKEN_EXPIRED: '',
    ACCESS_TOKEN_NOT_FOUND: '',
    EMAIL_ALREADY_VERIFIED: 'Email already verified',
    EMAIL_OR_PASSWORD_WRONG: '',
    INVALID_ID: '',
    INVALID_TOKEN_ID: '',
    JSON_BINDING: '',
    LOGIN_REQUIRED: '',
    PASSWORD_RESET_TOKEN_EXPIRE: '',
    QUESTION_UPDATE: '',
    RECORD_NOT_FOUND: '',
    REDIS: '',
    REFRESH_TOKEN_EXPIRED: '',
    REQUEST_PARAMS: '',
    SERVER: '',
    SERVER_ROUTE_NOT_FOUND: '',
    SESSION_ID_NOT_FOUND: '',
    USER_ALREADY_DELETED: '',
    USER_ALREADY_EXIST: '',
    USER_CREATED_LOGIN_REQUIRED: '',
    USER_NOT_EXIST: '',
    COMPLETED: 'Completed',
    FAILED: 'Failed',
    RUNNING: 'Running',
  });
