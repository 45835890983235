import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useMemo, useState } from 'react';
import { Expander, Typography, toasts } from 'ui';

import { StatusCode } from '../../../../../../utils/response/statusCode';
import { RuleExecutionTest } from '../../../../../Rules/components/RuleExecutionTest/RuleExecutionTest';
import { useUseGetRuleTestActionResults } from '../../../../../Rules/hooks/restApi/useGetRuleTestActionResult';
import { usePolling } from '../../../../../Rules/hooks/usePolling';
import { useServerResponse } from '../../../../../Rules/hooks/useServerResponse';
import {
  ActionInfoObject,
  RuleTestActionObject,
  RuleTestActionPollingPayload,
} from '../../../../../Rules/types';
import {
  getActionInfoForRuleTestActions,
  getAllInProgressActions,
  getAllRuleTestActions,
  getFormattedJsonForRuleTestActions,
} from '../../../../../Rules/utils/common';

type WorkflowResultProps = {
  result: Record<string, any>;
  state: boolean;
  time?: number | null;
};

export function WorkflowResult({ result, state, time }: WorkflowResultProps) {
  const [pollingPayload, setPollingPayload] = useState<
    RuleTestActionPollingPayload[]
  >([]);
  const [actionResponse, setActionResponse] = useState<RuleTestActionObject[]>(
    []
  );
  // eslint-disable-next-line
  const [actionInfo, setActionInfo] = useState<ActionInfoObject>({
    status: StatusCode.RUNNING,
    count: 0,
  });

  const [link, setLink] = useState<string>();

  const {
    getActionResults,
    actionData,
    error: actionError,
  } = useUseGetRuleTestActionResults();
  const { serverResponse, setServerResponse } = useServerResponse();

  const handlePollingRequest = async (
    pollingPayload: RuleTestActionPollingPayload[]
  ) => {
    try {
      if (pollingPayload.length > 0) {
        await getActionResults({
          params: pollingPayload,
        });
      }
    } catch (err: unknown) {
      if (err instanceof Error) {
        toasts.error(err.message, 'error');
      }
    }
  };

  useEffect(() => {
    const executionId = result?.logId;

    if (!_isNil(executionId) && !_isEmpty(executionId)) {
      const filter = window.btoa(
        JSON.stringify({
          apiURL: `/audit/audit/${executionId as string}?pageSize=10&pageNo=0`,
          tableHeaders: [],
        })
      );

      const link = `/auditlogs/${executionId as string}?filter=${filter}`;
      setLink(link);
    }
  }, [result]);

  useEffect(() => {
    const actionInfoData = !_isNil(actionData) ? actionData.data : {};

    if (!_isNil(actionInfoData.data)) {
      const data = actionInfoData.data;
      const currActionResponse = structuredClone(actionResponse);

      currActionResponse.forEach((action: RuleTestActionObject) => {
        const { executionId } = action.actionData;

        if (_has(data, executionId)) {
          const { executionTime, output, status } = data[executionId];
          const hasError = !_isNil(output?.error) && !_isEmpty(output?.error);

          action.actionData = {
            isCollapsed: true,
            executionTime,
            status,
            executionId,
            hasError,
            json: getFormattedJsonForRuleTestActions(
              hasError ? output?.error : output?.Result
            ),
          };
        }
      });

      let currPollingPayload = structuredClone(pollingPayload);
      currPollingPayload = currPollingPayload.filter(
        (actionPayload: RuleTestActionPollingPayload) =>
          _has(data, actionPayload.executionId) &&
          data[actionPayload.executionId].status === StatusCode.RUNNING
      );

      setActionResponse(currActionResponse);
      setPollingPayload(currPollingPayload);
    }
  }, [JSON.stringify(actionData)]);

  const { setIsPolling } = usePolling({
    functionToExecute: handlePollingRequest,
    args: pollingPayload,
    timeInterval: 2000,
  });

  const handleExpandIconClick = (actionName: string) => {
    const updatedActionResponse = structuredClone(actionResponse);
    updatedActionResponse.forEach((action) => {
      action.actionData.isCollapsed =
        actionName !== action.actionName
          ? true
          : !action.actionData.isCollapsed;
    });

    setActionResponse(updatedActionResponse);
  };

  useEffect(() => {
    const currActionInfo: ActionInfoObject =
      getActionInfoForRuleTestActions(actionResponse);
    setActionInfo(currActionInfo);

    const isPollingRequired = actionResponse.some(
      (action: RuleTestActionObject) =>
        action.actionData.status === StatusCode.RUNNING
    );
    setIsPolling(isPollingRequired);
  }, [JSON.stringify(actionResponse)]);

  useEffect(() => {
    if (!_isEmpty(actionError) && !_isEmpty(actionError.response)) {
      const { status, data } = actionError.response;
      const message = !_isEmpty(data) ? (data as any).message : '';

      if (
        !_isNil(status) &&
        (status === 400 || status === 401 || status >= 500)
      ) {
        setIsPolling(false);

        const updatedActionResponse = actionResponse.map(
          (action: RuleTestActionObject) => {
            if (action.actionData.status === StatusCode.RUNNING) {
              action.actionData.status = StatusCode.FAILED;
              action.actionData.json = message;
              action.actionData.hasError = true;
            }

            return action;
          }
        );

        setActionResponse(updatedActionResponse);
      }
    }
  }, [actionError]);

  useEffect(() => {
    const json = !_isNil(result?.action) ? result : {};

    if (!_isNil(json.action)) {
      setServerResponse({
        json: JSON.stringify(json, null, 2),
        executionTime: String(time ?? 0),
      });

      const actionList = getAllRuleTestActions(json.action);

      if (actionList.length > 0) {
        setActionResponse(actionList);
        setPollingPayload(getAllInProgressActions(actionList));
      }
    }
  }, [JSON.stringify(result)]);

  // TITLE: Action
  const title = useMemo(() => {
    if (state) {
      return (
        <Typography fontWeight={700} name="success">{`Success ${
          !_isNil(time) ? `. ${time}ms` : ''
        }`}</Typography>
      );
    }

    return (
      <Typography name="error" fontWeight={700}>{`Error ${
        !_isNil(time) ? `. ${time}ms` : ''
      }`}</Typography>
    );
  }, [time, state]);

  const finalOutput = { ...result };

  if ('logId' in finalOutput) {
    delete finalOutput.logId;
  }

  return (
    <Expander title={title}>
      <RuleExecutionTest
        serverResponse={serverResponse}
        isTesting={false}
        actionResponse={actionResponse}
        actionInfo={actionInfo}
        handleExpandIconClick={handleExpandIconClick}
        overrideOutput={finalOutput}
        forceStatus={true}
        link={link}
      />
    </Expander>
  );
}
