import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { useEffect, useRef, useState } from 'react';
import type { UseControllerProps, UseFormSetValue } from 'react-hook-form';
import { useController, useWatch } from 'react-hook-form';
import {
  Dataset,
  OnClickRuleArgs,
  PopoverMethods,
  PopoverPanel,
  RuleField,
} from 'ui';

import { customAttributesAtom } from '../../../../../components/rules/forms/CustomAttributeSheet/CustomAttributeSheet';
import { isRuleReadOnlyAtom, selectedDataSetAtom } from '../../../index';
import type { sendEventToGTMType } from '../../../types';
import {
  allConditionTypes,
  updateDataSetOnChange,
} from '../../../utils/common';
import { dataSetParamsAtom } from '../../CreateRuleSheet/CreateRuleSheet';
import { decisionTableNodesAtom } from '../DecisionTable';
import { RuleLauncher } from './RuleLauncher';
import { RowPropertyContainerStyled } from './RuleProperty.styled';

type RulePropertyProps = UseControllerProps & {
  setValue: UseFormSetValue<any>;
  currentKey: string;
  onClick: ({ value, key, dataType }: OnClickRuleArgs) => void;
  handleSendEventToGTM?: (obj: sendEventToGTMType) => void;
};

export default function RuleProperty({
  setValue,
  control,
  name,
  currentKey,
  onClick,
  handleSendEventToGTM,
}: RulePropertyProps) {
  const ref = useRef<PopoverMethods>(null);
  const [isRuleReadOnly] = useAtom(isRuleReadOnlyAtom);
  const [dataset] = useAtom(dataSetParamsAtom);
  const [nodes, setNodes] = useAtom(decisionTableNodesAtom);
  const [customAttributes] = useAtom(customAttributesAtom);
  const [dataSetSelected] = useAtom(selectedDataSetAtom);

  const [filteredDataSet, setFilteredDataSet] =
    useState<Record<string, Dataset>>(dataset);

  const currentProperty = useWatch({ control, name });
  const { fieldState } = useController({
    name,
    control,
  });

  // Whenever this Property changes update the node with the same currentKey
  // in the nodes object
  useEffect(() => {
    if (!_isNil(nodes[currentKey]) && !_isNil(currentProperty)) {
      setNodes((prev) => ({
        ...prev,
        [currentKey]: {
          ...nodes[currentKey],
          attribute: currentProperty.value,
          dataType: currentProperty.dataType,
          sourceType: currentProperty.key,
        },
      }));
    }
  }, [currentProperty]);

  useEffect(() => {
    setFilteredDataSet(
      updateDataSetOnChange(customAttributes, dataset, dataSetSelected)
    );
  }, [customAttributes, dataset, dataSetSelected]);

  const title = !_isNil(currentProperty)
    ? currentProperty.dataType === 'jsCondition'
      ? 'JS Code'
      : currentProperty.value
    : undefined;

  return (
    <PopoverPanel
      trigger="click"
      placement="bottom-start"
      launcher={
        <RuleLauncher
          error={fieldState.error?.message}
          label={!_isNil(title) ? title : 'Property'}
          currentProperty={currentProperty}
          handleSendEventToGTM={handleSendEventToGTM}
          id={name}
        />
      }
      padding="8px"
      ref={ref}
      disabled={isRuleReadOnly}
    >
      <RowPropertyContainerStyled>
        <RuleField
          version="v2"
          dataset={filteredDataSet}
          control={control}
          name={name}
          onClick={(onRuleClickArgs) => {
            onClick(onRuleClickArgs);
            setValue(name, onRuleClickArgs);
            ref.current?.hide();
          }}
          allowList={true}
          typesToAllow={allConditionTypes}
        />
      </RowPropertyContainerStyled>
    </PopoverPanel>
  );
}
