import _isNil from 'lodash/isNil';
import { ReactNode } from 'react';
import { Button } from 'ui/src/Button';
import { useCurrentLayer } from 'ui/src/Layer';
import { Modal, ModalContent, ModalFooter } from 'ui/src/Modal';
import { Typography } from 'ui/src/Typography';

type RuleExitModalProps = {
  title: string;
  onClose: () => void;
  description?: ReactNode;
};

export const RuleExitModal = ({
  title,
  onClose,
  description,
}: RuleExitModalProps) => {
  const { close: closeModal } = useCurrentLayer();

  return (
    <Modal size="large" title={title}>
      <ModalContent>
        {!_isNil(description) && (
          <Typography name="paragraph">{description}</Typography>
        )}
      </ModalContent>

      <ModalFooter>
        <Button appearance="neutral" onClick={closeModal}>
          Continue Editing
        </Button>

        <Button
          onClick={() => {
            closeModal();
            onClose();
          }}
          appearance="danger"
        >
          Close &amp; Lose Changes
        </Button>
      </ModalFooter>
    </Modal>
  );
};
