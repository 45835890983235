import type { ObservableQuery } from '@apollo/client';
import { Inline } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { ReactElement, useEffect, useRef, useState } from 'react';
import { BiPlus } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Image,
  Menu,
  MenuItem,
  PopoverMethods,
  Typography,
  useLayer,
} from 'ui';

import { userProfileAtom } from '../../../atom';
import { CreateButton } from '../../../components/CreateButton/CreateButton';
import { permissionObj } from '../../../components/PermissionComponent/constant';
import { useCheckPermissions } from '../../../components/PermissionComponent/hooks/useCheckPermissions';
import { roleJsonAtom } from '../../../components/authentication/router/AuthProvider';
import { PluginModal } from '../../../components/rules/forms/AddSource/components/PluginModal';
import { useGetPermissionsByEntity } from '../../../hooks/restApi/useGetPermissionsByEntity';
import { isValidImageURL } from '../../../utils/common';
import { ENTITY_ID } from '../../../utils/constant';
import { currentWorkspaceDetailAtom } from '../../Workspace/atom';
import type { GetDataSetsQueryResult } from '../hooks/useGetDataSets';
import {
  GetConnectorsQueryResult,
  useGetPublishedConnectors,
} from '../hooks/useGetPublishedConnectors';

type CreateDataSetMenuProps = {
  refetch: ObservableQuery<GetDataSetsQueryResult>['refetch'];
};

export function CreateDataSetMenu({ refetch }: CreateDataSetMenuProps) {
  const {
    data,
    error,
    loading,
    refetch: refetchPublish,
  } = useGetPublishedConnectors(true);
  const navigate = useNavigate();
  const ref = useRef<PopoverMethods>(null);
  const [currentWorkspace] = useAtom(currentWorkspaceDetailAtom);
  const [roleJson, setRoleJson] = useAtom(roleJsonAtom);
  const [userProfile] = useAtom(userProfileAtom);
  const email = !_isEmpty(userProfile) ? userProfile?.email : '';

  const { isHide: createDisable } = useCheckPermissions({
    allowedPermission: [permissionObj.create, permissionObj.edit],
    entityList: [ENTITY_ID.datasets],
  });

  const [publishConnectorData, setPublishConnectorData] = useState<
    GetConnectorsQueryResult | undefined
  >();

  const { getUserPermissionsByEntity } = useGetPermissionsByEntity();

  const handleGetPermissionByEntity = async () => {
    const role = currentWorkspace?.role ?? '';
    const wid = currentWorkspace?.uuid ?? '';

    const user = window.btoa(email ?? '');

    await getUserPermissionsByEntity(
      user,
      role,
      wid,
      'integrations',
      setRoleJson
    );
  };

  const integrationPermissions = roleJson.internals?.integrations?.permissions;
  const showAddItem =
    !_isNil(integrationPermissions) &&
    ((integrationPermissions.create as unknown as boolean) ||
      (integrationPermissions.edit as unknown as boolean));

  useEffect(() => {
    if (!_isEmpty(email)) {
      void handleGetPermissionByEntity();
    }
  }, [email]);

  useEffect(() => {
    if (!_isNil(data)) setPublishConnectorData(data);
  }, [JSON.stringify(data)]);

  const { open: openPluginModal } = useLayer(
    <PluginModal refetch={refetchPublish} />
  );

  if (_isNil(publishConnectorData)) {
    return null;
  }

  if (!_isNil(error)) {
    return <div>error</div>;
  }

  const isPublishedConnectorListEmpty =
    !loading &&
    !_isNil(publishConnectorData) &&
    publishConnectorData.getConnector.data.length === 0;

  const handleMenuItemClick = (connector: any) => {
    const state = 'staging';
    const editorObject = connector?.plugin;
    const connecterID = connector?.id;
    navigate(
      `/datasets?type=create&stage=${state}&editor=${
        editorObject?.name as string
      }&connectorId=${connecterID as string}`
    );
  };

  const handleCreateConnectorClick = () => {
    ref.current?.hide();

    openPluginModal();
  };

  const menuItems: ReactElement[] = [];

  if (!isPublishedConnectorListEmpty) {
    publishConnectorData.getConnector.data.forEach((connector) => {
      const { name, plugin } = connector;
      const { imageUrl } = plugin;

      menuItems.push(
        <MenuItem value={connector} key={name}>
          <Inline gutter=".8rem">
            <Image
              src={isValidImageURL(imageUrl)}
              alt="minus"
              size="small"
              round
            />
            <Typography>{name}</Typography>
          </Inline>
        </MenuItem>
      );
    });
  } else {
    menuItems.push(
      <MenuItem value={null}>
        <Inline align="center">
          <Image
            src={'./assets/konark/icons/integrations.svg'}
            alt="minus"
            size="small"
          />
          <Typography>No Integration available</Typography>
        </Inline>
      </MenuItem>
    );
  }

  if (showAddItem) {
    menuItems.push(
      <MenuItem
        value="openIntegrations"
        removeHover
        style={{
          position: 'sticky',
          bottom: '0px',
        }}
      >
        <CreateButton
          onClick={handleCreateConnectorClick}
          buttonLabel="Add Integration"
        />
      </MenuItem>
    );
  }

  return (
    <Menu
      maxHeight="30rem"
      launcher={
        <Button disabled={createDisable} leadingIcon={<BiPlus size={20} />}>
          Create Data Source
        </Button>
      }
      ref={ref}
      onMenuItemClick={(connector) => {
        if (connector === 'openIntegrations') {
          openPluginModal();
        } else if (!_isNil(connector)) {
          handleMenuItemClick(connector);
        }
        ref.current?.hide();
      }}
    >
      {menuItems}
    </Menu>
  );
}
