import React from 'react';
import { Image, TextButton, useLayer } from 'ui';

import { envMap } from '../../../utils/constant';
import DeleteRemoteConfigModal from './DeleteRemoteConfigModal';

export type RemoteConfigDeleteButtonProps = {
  deleteConfig: (id: string) => void;
  deletingIndex: string;
  title: string;
};

export function RemoteConfigDeleteButton({
  deletingIndex,
  deleteConfig,
  title,
}: RemoteConfigDeleteButtonProps) {
  const { open: openModal } = useLayer(
    <DeleteRemoteConfigModal
      deleteConfig={deleteConfig}
      deletingIndex={deletingIndex}
      title={title}
    />
  );

  return (
    <TextButton onClick={() => openModal()}>
      <Image
        src={`${envMap.VITE_ASSETS_URL}website/icons/trash.svg`}
        alt="trash"
        size="small"
      />
    </TextButton>
  );
}
