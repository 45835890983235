import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { Dataset, getDataTypeNected } from 'ui';

import { getPropertyIfExists } from '../../../utils/common';
import { WorkflowNodeType } from './useOpenWorkflow';

type UseUpdateExecutedValueArgs = {
  parentNodes: WorkflowNodeType[];
  updatedDataset: Record<string, Dataset>;
};

export function useUpdateExecutedValue({
  parentNodes,
  updatedDataset,
}: UseUpdateExecutedValueArgs) {
  // This is the state that represents which values to find executed value for
  const [valuesToFindExecValues, setValuesToFindExecValues] = useState<
    Array<Record<string, any>>
  >([]);

  /**
   * This is the state that contains the tokens and it's corresponding values
   * based on the above state variable
   */
  const [executedValue, setExecutedValue] = useState<Record<string, any>>({});

  const handleGetExecutionValues = (values: Record<string, any>) => {
    const finalList = Object.keys(values).map((key) => {
      const newValue = key.replace('{{.', '').replace('}}', '');

      return {
        source: newValue.split('.')[0],
        attribute: newValue.split('.').splice(1).join('.'),
      };
    });

    setValuesToFindExecValues(finalList);
  };

  useEffect(() => {
    setExecutedValue(() => {
      const params: Record<string, any> = {};

      valuesToFindExecValues.forEach((attr) => {
        if (attr.source === 'globalVar') {
          const mappedData = getPropertyIfExists(
            JSON.parse(
              JSON.stringify(
                Object.keys(updatedDataset.globalVar.attributes).reduce(
                  (acc, curr) => {
                    return {
                      ...acc,
                      [curr]:
                        updatedDataset.globalVar.attributes[`${curr}`]
                          .executedValue,
                    };
                  },
                  {}
                )
              )
            ) ?? {},
            attr.attribute
          );
          params[`{{.${attr.source as string}.${attr.attribute as string}}}`] =
            {
              executedValue: mappedData,
              meta: getDataTypeNected(mappedData),
            };

          return;
        }
        const parentData = getPropertyIfExists(
          JSON.parse(
            JSON.stringify(
              parentNodes.find((p) => p.data.name === attr.source)?.data
                ?.executedValue ?? {}
            )
          ),
          attr.attribute
        );

        if (!_isNil(parentData)) {
          params[`{{.${attr.source as string}.${attr.attribute as string}}}`] =
            {
              executedValue: parentData,
              meta: getDataTypeNected(parentData),
            };
        }
      });

      return params;
    });
  }, [JSON.stringify(valuesToFindExecValues)]);

  return {
    handleGetExecutionValues,
    executedValue,
  };
}
