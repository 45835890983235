import { PadBox } from '@bedrock-layout/padbox';
import { JsonHeroTreeViewer, NectedEditor } from 'ui';

import { ExecutionView } from '../../../../../../components/jsonOutputField/components/ExecutionView';
import { editorDomain } from '../../../../../../utils/constant';
import { OutputContainer } from '../../ApiTriggerSheet/ApiTestResult.styled';
import { JsonContainer, JsonTreeContainer } from './ResponseNodeTest.styled';

type ResponseNodeTestProps = {
  output?: Record<string, any>;
};

export function ResponseNodeTest({
  output = {
    _comment: 'Please Test the node first',
  },
}: ResponseNodeTestProps) {
  return (
    <PadBox padding="2rem">
      <OutputContainer padding="1rem">
        <ExecutionView>
          <JsonContainer>
            <NectedEditor
              domain={editorDomain}
              mode="json"
              defaultValue={JSON.stringify(output, null, 2)}
              readOnly={true}
            />
          </JsonContainer>

          <JsonTreeContainer>
            <JsonHeroTreeViewer json={output} />
          </JsonTreeContainer>
        </ExecutionView>
      </OutputContainer>
    </PadBox>
  );
}
