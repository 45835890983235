import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import _isNil from 'lodash/isNil';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Expander, Typography, useLayer } from 'ui';

import { useSendEventToGTM } from '../../hooks/useSendEventToGTM';
import { convertObjecttoStringCSS, isValidImageURL } from '../../utils/common';
import { envMap } from '../../utils/constant';
import { RuleTemplatePopup } from '../Listing/gettingStarted/components/rulesTemplate';
import { ListingVideoBlock } from '../Listing/gettingStarted/components/videosPopup';
import type {
  GSActionProps,
  GSContentProps,
} from '../Listing/gettingStarted/content';
import {
  GSBlock,
  GSBlockContainer,
  IconContainer,
  ListingBlockExpanderContainer,
} from './listingBlocks.styled';

export type ListingBlockProps = {
  title: string;
  entity: string;
  collapsed: boolean;
  blockData: GSContentProps[];
  updateExpanderState: () => void;
  subModules: Record<string, boolean> | undefined;
};
const deploymentType = envMap.VITE_DEPLOYMENT_TYPE;
export const ListingBlock = ({
  title,
  blockData = [],
  entity,
  collapsed,
  updateExpanderState,
  subModules,
}: ListingBlockProps) => {
  const [searchParams] = useSearchParams();

  const { openWithProps: openRuleTemplatePopup } = useLayer(
    <RuleTemplatePopup
      title="Start from Template"
      type="CREATE_TEMPLATE"
      selectedTab={1}
      subModules={subModules}
    />
  );

  const { openWithProps: openVideoPopup } = useLayer(
    <ListingVideoBlock entity={entity} />
  );
  const { sendEventToGTM } = useSendEventToGTM();

  useEffect(() => {
    if (
      !_isNil(searchParams.get('nected_action')) &&
      searchParams.get('nected_action') === 'open_create_rule_popup'
    ) {
      openRuleTemplatePopup({});
    }
  }, []);

  const handleOnClick = (action: GSActionProps, title: string) => {
    sendEventToGTM({
      event: 'lb_clicks',
      action: 'click',
      type: entity,
      title,
    });

    if (action.type === 'POPUP') {
      handlePopupActions(action);
    }
  };

  const handlePopupActions = (action: GSActionProps) => {
    if (action.name === 'OPEN_RULE_TEMPLATE') {
      openRuleTemplatePopup({});
    } else if (action.name === 'OPEN_VIDEO_POPUP') {
      openVideoPopup({ entity });
    }
  };

  let updatedBlockData = blockData;

  if (deploymentType === 'onpremise') {
    updatedBlockData = updatedBlockData.filter(
      (i) => i.id !== 'i_need_assistance' && i.id !== 'start_from_template'
    );
  }

  return (
    <ListingBlockExpanderContainer>
      <Expander
        onClick={updateExpanderState}
        collapsed={collapsed}
        title={title}
        fontWeight={700}
      >
        <Inline>
          {updatedBlockData.map((i, index) => {
            return (
              <GSBlockContainer
                id={i.id}
                key={index}
                onClick={() => {
                  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                  !_isNil(i.action) ? handleOnClick(i.action, i.title) : null;
                }}
                as={GSBlock}
                styles={convertObjecttoStringCSS(i.styles)}
              >
                <IconContainer>
                  <img
                    src={isValidImageURL(`${envMap.VITE_ASSETS_URL}${i.icon}`)}
                    alt="icon"
                    style={{ maxWidth: '10rem' }}
                  />
                </IconContainer>

                <Stack gutter={'1rem'}>
                  <Typography fontWeight={700} name={'heading4'}>
                    {i.title}
                  </Typography>
                </Stack>
              </GSBlockContainer>
            );
          })}
        </Inline>
      </Expander>
    </ListingBlockExpanderContainer>
  );
};
