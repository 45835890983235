import { PadBox } from '@bedrock-layout/padbox';
import { Inline, Stack } from '@bedrock-layout/primitives';
import styled from 'styled-components';

export const ControlListContainer = styled(PadBox)`
  min-block-size: 16rem;
  max-block-size: 42rem;
  inline-size: 28rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  overflow-y: auto;
`;

export const ControlHeader = styled(PadBox)`
  border-block-end: 1px solid var(--color-lightGray6);
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ControlList = styled(PadBox)`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 1rem;
`;

export const ControlItemContainer = styled(Inline)`
  inline-size: 45%;
  cursor: pointer;
`;

export const ControlBlockItemContainer = styled(Stack)<{
  isDisabled?: boolean;
}>`
  inline-size: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${({ isDisabled = false }) => !isDisabled && 'cursor: pointer;'}
`;

export const ControlIconContainer = styled(PadBox)`
  border: 1px solid var(--color-lightGray6);
  block-size: 4.5rem;
  inline-size: 4.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.8rem;
`;
