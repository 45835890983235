import { DropdownField, DropdownFieldPropTypes } from 'ui';

export const dataTypes = [
  {
    label: 'String',
    value: 'string',
  },
  {
    label: 'Numeric',
    value: 'numeric',
  },
  {
    label: 'Boolean',
    value: 'boolean',
  },
  {
    label: 'Date',
    value: 'date',
  },
  {
    label: 'DateTime',
    value: 'dateTime',
  },
  {
    label: 'Json',
    value: 'json',
  },
  {
    label: 'List',
    value: 'list',
  },
  {
    label: 'restAPI',
    value: 'restAPI',
  },
];

export type DataTypeDropdownResult = {
  label: string;
  value: string;
};

export function DataTypeDropdown(props: DropdownFieldPropTypes<any>) {
  return <DropdownField {...props} options={dataTypes} />;
}
