import { gql, useLazyQuery } from '@apollo/client';

import type { UsedByInternalDataModel } from '../../model';

const getUsedByInternalQuery = gql`
  query getUsedByInternalList(
    $entityId: String
    $dependencyId: String
    $perPage: Int
    $page: Int
    $version: String
  ) {
    getUsedByInternalList(
      entityId: $entityId
      dependencyId: $dependencyId
      perPage: $perPage
      page: $page
      version: $version
    ) {
      data {
        entityId
        entityType
        entityVersion
        dependencyId
        dependencyType
        dependencyVersion
        dependencyCommitTitle
      }
      paginationInfo {
        perPage
        currentPage
        totalPage
        totalRecord
      }
    }
  }
`;

export function useGetUsedByInternalData() {
  return useLazyQuery<UsedByInternalDataModel, Record<string, any>>(
    getUsedByInternalQuery
  );
}
