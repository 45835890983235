import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import styled from 'styled-components';
import { Typography } from 'ui';

export const ContainerStyled = styled(PadBox)`
  block-size: 4rem;
  max-inline-size: 18rem;
  background-color: var(--color-white);
  border: 1px solid var(--color-gainsboro);
  border-radius: 4px;
  cursor: pointer;
  position: relative;
`;

export const RefreshStyled = styled(PadBox)<{
  $showBorder?: boolean;
  $isDisabled?: boolean;
}>`
  cursor: pointer;
  block-size: 4rem;
  inline-size: 4rem;
  ${({ $showBorder = true }) =>
    $showBorder && 'border: 1px solid var(--color-solitude);'}
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ $isDisabled = false }) => $isDisabled && 'pointer-events: none'}
`;

export const DataSourceContainer = styled(Inline)`
  inline-size: 16rem;
  block-size: inherit;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0.3rem 0.5rem;
`;

export const DataSourceValue = styled(Typography)`
  word-break: break-all;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
`;
