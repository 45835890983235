import { gql, useMutation } from '@apollo/client';
import type { FetchResult } from '@apollo/client';

import type { RuleEnvironment } from '../../../../utils/constant';
import { Environment } from '../../../../utils/constant';
import type {
  ConnectorConfig,
  IntegrationFormValues,
  SortedPluginParamsFieldKeys,
  UpdateConnectorResult,
} from '../../types';
import { parseFormData } from '../connector/utils';

const updateProductionConnectorQuery = gql`
  mutation updateConnector(
    $id: String!
    $name: String!
    $config: Map!
    $checksum: String!
    $environment: Environment!
  ) {
    updateConnector(
      input: {
        id: $id
        name: $name
        productionConf: $config
        checksum: $checksum
      }
      environment: $environment
    ) {
      id
      checksum
    }
  }
`;

const updateStagingConnectorQuery = gql`
  mutation updateConnector(
    $id: String!
    $name: String!
    $config: Map!
    $checksum: String!
    $environment: Environment!
  ) {
    updateConnector(
      input: { id: $id, name: $name, stagingConf: $config, checksum: $checksum }
      environment: $environment
    ) {
      id
      checksum
    }
  }
`;

type UpdateConnectorVariables = {
  name: string;
  id: string;
  checksum: string;
  config: ConnectorConfig;
  environment: RuleEnvironment;
};

export type UpdateConnectorFunction = (
  formValues: unknown,
  checkSum: string
) => Promise<FetchResult<UpdateConnectorResult>>;

export type UseUpdateConnectorReturn = {
  loading: boolean;
  updateConnector: UpdateConnectorFunction;
};

export function useUpdateConnector(
  currentEnvironment: RuleEnvironment,
  connectorId: string,
  sortedFields: SortedPluginParamsFieldKeys[]
): UseUpdateConnectorReturn {
  const [updateConnectorMutator, { loading }] = useMutation<
    UpdateConnectorResult,
    UpdateConnectorVariables
  >(
    currentEnvironment === Environment.STAGING
      ? updateStagingConnectorQuery
      : updateProductionConnectorQuery
  );

  const updateConnector = async (formValues: unknown, checksum: string) => {
    const { name, ...rest } = formValues as Required<IntegrationFormValues>;

    return await updateConnectorMutator({
      variables: {
        name,
        id: connectorId,
        checksum,
        environment: currentEnvironment,
        config: {
          ...parseFormData(sortedFields, rest),
        },
      },
    });
  };

  return {
    loading,
    updateConnector,
  };
}
