import styled from 'styled-components';

export const LabelStyled = styled.label<{ checked: boolean }>`
  background-color: ${({ checked }) =>
    checked ? 'var(--color-primary1)' : 'var(--color-white)'};
  color: ${({ checked }) =>
    checked ? 'var(--color-white)' : 'var(--color-black)'};
  border: ${({ checked }) =>
    checked
      ? ' 1px solid var(--color-primary1)'
      : '1px solid var(--color-primary1)'};
  border-radius: 2rem;
  transition: background-color 0.5s;
  cursor: pointer;
`;

export const LabelStyledV2 = styled.label<{ checked: boolean }>`
  border: ${({ checked }) =>
    checked
      ? ' 1px solid var(--color-primary1)'
      : '1px solid var(--color-lightGray)'};
  border-radius: 0.4rem;
  transition: background-color 0.5s;
  width: 100%;
  cursor: pointer;
`;
