import { PadBox } from '@bedrock-layout/padbox';
import { useRef } from 'react';
import type { ReactElement } from 'react';
import type { Control } from 'react-hook-form';
import type { Placement } from 'tippy.js';
import { CheckboxField, Menu, MenuItem } from 'ui';
import type { PopoverMethods } from 'ui';

import { FILTER_ROLES } from '../Team/constant';
import type { Role, RoleDetails } from '../types';

type FilterRoleUiProps = {
  children?: ReactElement;
  id?: string;
  launcher: ReactElement;
  onClickRole: (role: RoleDetails) => void;
  placement: Placement;
  removeMember?: (id?: string, role?: Role) => void;
  leadingMenu?: ReactElement;
  role?: Role;
  control: Control<any>;
};

export function FilterRoleUi({
  launcher,
  placement,
  control,
}: FilterRoleUiProps) {
  const ref = useRef<PopoverMethods>(null);

  return (
    <Menu
      launcher={launcher}
      onMenuItemClick={(role) => {}}
      ref={ref}
      placement={placement}
    >
      <>
        {FILTER_ROLES.map(({ value, label }, index) => {
          return (
            <MenuItem value={value} key={value}>
              <PadBox padding="1rem">
                <CheckboxField
                  label={label}
                  control={control}
                  name={`roles.${index}`}
                  useId={`roles.${index}`}
                  value={value}
                />
              </PadBox>
            </MenuItem>
          );
        })}
      </>
    </Menu>
  );
}
