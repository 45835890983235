import { Inline } from '@bedrock-layout/primitives';
import { useState } from 'react';
import type { UseControllerProps, UseFormSetValue } from 'react-hook-form';
import { useFieldArray } from 'react-hook-form';

import ResultBlock from './ResultHeaderBlock';

type ResultFieldArrayProps = Omit<UseControllerProps, 'name'> & {
  setValue: UseFormSetValue<any>;
};

export default function ResultFieldArray({
  control,
  setValue,
}: ResultFieldArrayProps) {
  const { fields } = useFieldArray({
    name: 'results',
    control,
  });

  const [indexToObserve, setIndexToObserve] = useState(-1);

  return (
    <Inline align="stretch">
      {fields.map((field, index) => {
        return (
          <ResultBlock
            id={field.id}
            isFirst={index === 0}
            isLast={index === fields.length - 1}
            key={field.id}
            control={control}
            setValue={setValue}
            index={index}
            total={fields.length}
            indexToObserve={indexToObserve}
            setIndexToObserve={setIndexToObserve}
          />
        );
      })}

      {fields.length === 0 && (
        <ResultBlock
          id={'blank_field'}
          isFirst={true}
          isLast={true}
          key={'blank_field'}
          control={control}
          setValue={setValue}
          index={-1}
          showBlank
          total={fields.length}
          indexToObserve={indexToObserve}
          setIndexToObserve={setIndexToObserve}
        />
      )}
    </Inline>
  );
}
