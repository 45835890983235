import type { UseControllerProps, UseFormSetValue } from 'react-hook-form';

import RowsFieldArray from './RowsFieldArray';

type DecisionTableRowsProps = Omit<UseControllerProps, 'name'> & {
  setValue: UseFormSetValue<any>;
};

export default function DecisionTableRows({
  control,
  setValue,
}: DecisionTableRowsProps) {
  return <RowsFieldArray setValue={setValue} control={control} />;
}
