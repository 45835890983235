import { PadBox, Stack } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import { type Control, useWatch } from 'react-hook-form';
import { CheckboxField, DropdownField, TextField, Toast } from 'ui';

import { siteConstantsAtom } from '../../../atom';
import { getTooltipText } from '../../../utils/common';
import { Environment } from '../../../utils/constant';
import type { RuleEnvironment } from '../../../utils/constant';
import { timeToExpireUnits } from '../utils';
import { TextFieldContainer } from './DataSetForm.styled';
import { SettingField } from './SettingsField';

type DataSettingForm = {
  control: Control<any>;
  currentEnvironment: RuleEnvironment;
  isLive?: boolean;
};

export function DataSetSettingForm({
  control,
  currentEnvironment,
  isLive = false,
}: DataSettingForm) {
  const fieldNamePrefix = 'productionSettings';

  const [siteConstants] = useAtom(siteConstantsAtom);

  const dataSetStatus = useWatch({
    control,
    name: 'status',
  });

  const isEditable = useWatch({ control, name: 'editable' });

  const getTooltipByEnvironmentDataset = (entity: string) => {
    if (currentEnvironment === Environment.STAGING) {
      if (entity === 'rowLimit') {
        return getTooltipText(siteConstants, 'datasets', 'rowLimitStaging');
      }

      if (entity === 'cache') {
        return getTooltipText(siteConstants, 'datasets', 'cacheStaging');
      }
    } else if (currentEnvironment === Environment.PRODUCTION) {
      if (entity === 'rowLimit') {
        return getTooltipText(siteConstants, 'datasets', 'rowLimitProduction');
      }

      if (entity === 'cache') {
        return getTooltipText(siteConstants, 'datasets', 'cacheProduction');
      }
    }

    return undefined;
  };

  return (
    <Stack as={PadBox} padding={'2rem'} gutter="1.6rem">
      <Toast
        message={getTooltipText(
          siteConstants,
          'datasets',
          'settingsInfo',
          'otherText'
        )}
        type="infoBlue"
      />

      <SettingField
        label="Row Limit"
        tooltip={getTooltipByEnvironmentDataset('rowLimit')}
      >
        <TextField
          type="number"
          size="small"
          control={control}
          name={`${fieldNamePrefix}.rowLimit`}
          showErrorIcon={false}
          // eslint-disable-next-line
          disabled={isLive || !isEditable}
        />
      </SettingField>

      <SettingField
        label="Cache"
        fieldType="boolean"
        tooltip={getTooltipByEnvironmentDataset('cache')}
      >
        <CheckboxField
          control={control}
          name={`${fieldNamePrefix}.cacheEnabled`}
          appearance="switch"
          // eslint-disable-next-line
          disabled={isLive || !isEditable}
        />
      </SettingField>

      <SettingField label="Time to expire">
        <TextFieldContainer>
          <TextField
            type="number"
            name={`${fieldNamePrefix}.durationValue`}
            control={control}
            showErrorIcon={false}
            // eslint-disable-next-line
            disabled={isLive || !isEditable}
          />
        </TextFieldContainer>

        <DropdownField
          control={control}
          name={`${fieldNamePrefix}.durationUnit`}
          options={timeToExpireUnits}
          // eslint-disable-next-line
          isDisabled={isLive || !isEditable}
        />
      </SettingField>

      {dataSetStatus !== 'published' && (
        <Toast
          message="The changes in the settings will be live when dataset is published to production"
          type="warningBlue"
        />
      )}
    </Stack>
  );
}
