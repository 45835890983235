import { PadBox } from '@bedrock-layout/padbox';
import styled from 'styled-components';

export const SourceType = styled(PadBox)`
  background-color: var(--color-solitude);
  border-radius: 6px;
`;

export const FormPanel = styled(PadBox)`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const Form = styled.form`
  flex: 1;
`;

export const FormFooter = styled(PadBox)`
  position: sticky;
  bottom: 0;
  border-block-start: 1px solid var(--color-solitude);
  inset-inline-start: 0;
  background-color: var(--color-white);
`;

export const Informational = styled(PadBox)`
  background-color: var(--color-honeydew);
  color: var(--color-charcoal);
  border-radius: 6px;
`;

export const Table = styled.table`
  border: 1px solid var(--color-solitude);
  border-radius: 6px;
  inline-size: 100%;
  text-align: left;
  border-spacing: 0;
`;

export const TableHeader = styled.th`
  border-block-end: 1px solid var(--color-solitude);
`;

export const TableData = styled.td`
  max-inline-size: 18rem;
  vertical-align: top;

  > div:first-child {
    max-inline-size: 25rem;
  }
`;

export const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: var(--color-solitudeSecondary);
  }
`;
