import { Inline } from '@bedrock-layout/inline';
import { PadBox } from '@bedrock-layout/padbox';
import { Stack } from '@bedrock-layout/stack';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { FaMinus, FaPlus } from 'react-icons/fa';

import { ExecutedValueTooltip } from '../../ExecutedValueTooltip/ExecutedValueTooltip';
import { Typography } from '../../Typography';
import { NodeLeaf } from '../components';
import { NodeStyled } from '../components/Node.styled';
import { StyledText } from '../components/NodeLeaf.styled';
import { IconByDataType } from './IconByDataType';
import { JsonViewer } from './JsonViewer';
import { NodeAccordion } from './ListViewer.styled';
import { getDataTypeNected, getObjectUnion } from './helpers/search';

type ListViewerProps = {
  exeVal: any[];
  depth: number;
  nodeKey: string;
  onLeafClick: (
    value: string,
    node: string,
    dataType: string,
    executedValue?: any
  ) => void;
  searchedLeaves: string[];
  kName: string;
  currentDatatype: string;
  typesToAllow?: string[];
  showTooltip?: boolean;
  searchInput?: string;
  version?: 'v1' | 'v2';
  viewName?: string;
};

export function ListViewer({
  exeVal,
  depth,
  onLeafClick,
  nodeKey,
  searchedLeaves,
  kName,
  currentDatatype,
  typesToAllow = [],
  showTooltip = false,
  searchInput = '',
  version = 'v1',
  viewName,
}: ListViewerProps) {
  const [areChildrenVisible, setAreChildrenVisible] = useState(true);
  const elements = exeVal;

  let clickList = false;

  if (typesToAllow.includes('list')) {
    clickList = true;
  }

  return (
    <PadBox padding={[0, 0, 6, 0]}>
      <Inline stretch="start">
        <Inline gutter={4} align="center">
          <NodeStyled
            align="center"
            $showHover={clickList}
            gutter={8}
            $padding={version === 'v2' ? '4px 0px 4px 10px' : undefined}
            $version={version}
            onClick={() => {
              if (clickList) {
                onLeafClick(`${kName}`, nodeKey, 'list', exeVal);
              }
            }}
          >
            {showTooltip ? (
              <ExecutedValueTooltip
                attribute={kName}
                value={exeVal}
                dataType="list"
                id={nodeKey + kName}
              >
                <Inline align="center">
                  {version === 'v2' && (
                    <span>
                      <IconByDataType
                        dataType={'list'}
                        color={
                          clickList ? 'var(--color-paleDodgerBlue)' : 'black'
                        }
                      />
                    </span>
                  )}
                  <StyledText
                    name={'secondaryXsDark'}
                    fontWeight={clickList ? 700 : 400}
                    $version={clickList ? version : undefined}
                  >
                    {viewName ?? kName}
                  </StyledText>
                  <Typography name="secondaryXs">list</Typography>
                </Inline>
              </ExecutedValueTooltip>
            ) : (
              <Inline align="center">
                {version === 'v2' && (
                  <span>
                    <IconByDataType
                      dataType={'list'}
                      color={
                        clickList ? 'var(--color-paleDodgerBlue)' : 'black'
                      }
                    />
                  </span>
                )}
                <StyledText
                  name={'secondaryXsDark'}
                  fontWeight={clickList ? 700 : 400}
                  $version={clickList ? version : undefined}
                >
                  {viewName ?? kName}
                </StyledText>
                <Typography name="secondaryXs">list</Typography>
              </Inline>
            )}
          </NodeStyled>

          <NodeAccordion
            onClick={() => {
              setAreChildrenVisible((prev) => !prev);
            }}
          >
            {areChildrenVisible ? <FaMinus size={7} /> : <FaPlus size={7} />}
          </NodeAccordion>
        </Inline>
      </Inline>

      {areChildrenVisible && (
        <motion.div>
          <Stack
            gutter="0px"
            style={{
              paddingLeft: 16,
            }}
          >
            {(Array.isArray(elements) ? elements : [])?.map(
              (item, index, arr) => {
                if (index > 0) {
                  return null;
                }

                const currentType = getDataTypeNected(item);

                const currentValue = getObjectUnion(arr);

                if (
                  currentType === 'json' &&
                  !typesToAllow.includes(currentType)
                ) {
                  return (
                    <JsonViewer
                      execValue={currentValue}
                      kName={`${kName}[${index}]`}
                      currentDatatype="list"
                      nodeKey={nodeKey}
                      depth={depth}
                      viewName={`index [${index}]`}
                      onLeafClick={onLeafClick}
                      searchedLeaves={searchedLeaves}
                      key={`${kName}[${index}]`}
                      typesToAllow={typesToAllow}
                      showTooltip={showTooltip}
                      searchInput={searchInput}
                      allowList
                      version={version}
                    />
                  );
                }

                if (currentType === 'list') {
                  return (
                    <ListViewer
                      exeVal={currentValue}
                      kName={`${kName}[${index}]`}
                      currentDatatype="list"
                      nodeKey={nodeKey}
                      depth={depth + 1}
                      viewName={`index [${index}]`}
                      onLeafClick={onLeafClick}
                      searchedLeaves={searchedLeaves}
                      key={`${kName}[${index}]`}
                      typesToAllow={typesToAllow}
                      showTooltip={showTooltip}
                      searchInput={searchInput}
                      version={version}
                    />
                  );
                }

                if (
                  typesToAllow.length > 0 &&
                  !typesToAllow.includes(currentType)
                ) {
                  return null;
                }

                return (
                  <NodeLeaf
                    key={`${kName}[${index}]`}
                    title={`index [${index}]`}
                    executedValue={currentValue}
                    showTooltip={showTooltip}
                    dataType={currentType}
                    onClick={() =>
                      onLeafClick(`${kName}[${index}]`, nodeKey, currentType)
                    }
                    version={version}
                  />
                );
              }
            )}
          </Stack>
        </motion.div>
      )}
    </PadBox>
  );
}
