import { Inline } from '@bedrock-layout/inline';
import _isNil from 'lodash/isNil';
import _isUndefined from 'lodash/isUndefined';
import { BiChevronDown, BiChevronRight } from 'react-icons/bi';

import { getDataTypeNected } from '../ConditionPopovers';
import { IconByDataType } from '../ConditionPopovers/RulePopover/IconByDataType';
import { TooltipReact } from '../TooltipReact';
import { Typography } from '../Typography';
import {
  EllipsisTypography,
  TextContainer,
  TreeAction,
  TreeSectionKey,
  TreeSectionWrapper,
  TreeWrapper,
} from './TreeBuilder.styled';

type TreeBuilderProps = {
  currKey: string;
  value: any;
  dataset: Record<string, any>;
  expandedState: Record<string, boolean>;
  appendedKey: string;
  setExpandedState: (value: any) => void;
  depth: number;
  maxDepth: number;
};

const colorList = [
  'var(--color-black)',
  'var(--color-pastelMagenta)',
  'var(--color-azul)',
  'var(--color-purpleMimosa)',
  'var(--color-deepSaffron)',
  'var(--color-parisGreen)',
  'var(--color-lightGray8)',
];

export const TreeBuilder = ({
  currKey,
  value,
  dataset,
  depth = 0,
  expandedState = {},
  setExpandedState,
  appendedKey,
  maxDepth,
}: TreeBuilderProps) => {
  const paddingCalc = (depth >= maxDepth ? maxDepth : depth) * 1.4;

  if (typeof value === 'object' && !_isNil(value)) {
    return (
      <>
        <TreeWrapper>
          <TreeAction
            onClick={() =>
              setExpandedState((prev: Record<string, boolean>) => {
                return {
                  ...prev,
                  [appendedKey]: _isUndefined(prev[appendedKey])
                    ? false
                    : !prev[appendedKey],
                };
              })
            }
          >
            {_isUndefined(expandedState[appendedKey]) ||
            expandedState[appendedKey] ? (
              <BiChevronDown fontSize={24} />
            ) : (
              <BiChevronRight fontSize={24} />
            )}
          </TreeAction>
          <TreeSectionWrapper
            $width={`calc(40% - 3rem)`}
            $paddingLeft={`${paddingCalc}rem`}
            $borderRight="1px solid #eee"
          >
            <TreeSectionKey
              $borderLeft={`2px solid ${
                depth < maxDepth ? colorList[depth % maxDepth] : '#ddd'
              }`}
            >
              <EllipsisTypography>{currKey}</EllipsisTypography>
            </TreeSectionKey>
          </TreeSectionWrapper>

          <TreeSectionWrapper $paddingLeft="1.4rem" $width="60%">
            <Inline gutter="0.8rem" align="center">
              <span>
                <IconByDataType
                  dataType={getDataTypeNected(value)}
                  color="var(--color-black)"
                />
              </span>
              <Typography name="paragraphSmall">
                {Object.keys(value).length}{' '}
                {Array.isArray(value) ? 'items' : 'fields'}
              </Typography>
            </Inline>
          </TreeSectionWrapper>
        </TreeWrapper>

        {(_isUndefined(expandedState[appendedKey]) ||
          expandedState[appendedKey]) &&
          Object.keys(value ?? {}).map((c) => {
            return (
              <TreeBuilder
                currKey={`${Array.isArray(value) ? 'index ' + c : c}`}
                value={value[c]}
                dataset={dataset}
                depth={depth + 1}
                expandedState={expandedState}
                setExpandedState={setExpandedState}
                appendedKey={`${appendedKey}.${c}`}
                key={`${appendedKey}.${c}`}
                maxDepth={maxDepth}
              />
            );
          })}
      </>
    );
  }

  return (
    <TreeWrapper>
      <TreeAction />

      <TreeSectionWrapper
        $width={`calc(40% - 3rem)`}
        $paddingLeft={`${paddingCalc}rem`}
        $borderRight="1px solid #eee"
      >
        <TreeSectionKey
          $borderLeft={`2px solid ${
            depth < maxDepth ? colorList[depth % maxDepth] : '#ddd'
          }`}
        >
          <Typography>{currKey}</Typography>
        </TreeSectionKey>
      </TreeSectionWrapper>

      <TreeSectionWrapper $paddingLeft="1.4rem" $width="60%">
        <Inline gutter="0.8rem" align="center">
          <span>
            <IconByDataType
              dataType={getDataTypeNected(value)}
              color="var(--color-black)"
            />
          </span>
          {typeof value === 'string' && value.length > 30 ? (
            <TooltipReact
              id={appendedKey}
              launcher={
                <span>
                  <Typography name="paragraphSmall">
                    {value.substring(0, 30)}...
                  </Typography>
                </span>
              }
            >
              <TextContainer>
                <Typography name="paragraphSmall">{value}</Typography>
              </TextContainer>
            </TooltipReact>
          ) : (
            // eslint-disable-next-line
            <Typography name="paragraphSmall">{`${value}`}</Typography>
          )}
        </Inline>
      </TreeSectionWrapper>
    </TreeWrapper>
  );
};
