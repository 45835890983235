import { gql, useLazyQuery } from '@apollo/client';

const getRuleWarnings = gql`
  query getRule($id: String) {
    getRule(id: $id) {
      data {
        id
        warnings
        checksum
        dataSetId
        status
      }
    }
  }
`;

export function useGetWarnings() {
  return useLazyQuery(getRuleWarnings, {
    fetchPolicy: 'no-cache',
  });
}
