import { ApolloProvider } from '@apollo/client';
import { ReactNode } from 'react';

import { useApolloClient } from '../hooks/useApolloClient';

export type ApolloClientProviderProps = {
  children: ReactNode;
};

export function ApolloClientProvider({ children }: ApolloClientProviderProps) {
  const { client } = useApolloClient();

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
