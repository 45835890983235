import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import styled from 'styled-components';

export const CodeContainer = styled(PadBox)`
  block-size: 30rem;
`;

export const DateTimeWrapper = styled(Inline)`
  max-inline-size: 25rem;
  position: relative;
`;
