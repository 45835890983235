import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import React, { memo, useEffect, useRef } from 'react';
import { Handle, Position } from 'reactflow';
import { PopoverMethods, PopoverPanel } from 'ui';

import { workflowNodesAtom } from '../../../atoms/atoms';
import { AddNodeLargeStyled } from './AddNode.styled';
import { AddNodeButton } from './AddNodeButton';
import { TriggerPopover } from './TriggerPopover/TriggerPopover';

export const AddNodeLarge = memo(({ data, isConnectable, id }: any) => {
  const popoverRef = useRef<PopoverMethods>(null);

  const [workflowNodes] = useAtom(workflowNodesAtom);

  useEffect(() => {
    if (workflowNodes.length === 1 && !_isNil(popoverRef.current)) {
      popoverRef.current.show();
    }
  }, [workflowNodes, popoverRef]);

  return (
    <React.Fragment>
      <Handle
        type="source"
        position={Position.Bottom}
        style={{ background: '#eee', height: 1, width: 1 }}
        isConnectable={isConnectable}
      />

      <PopoverPanel
        launcher={
          <AddNodeLargeStyled>
            <span>
              <AddNodeButton id={id} />
            </span>
          </AddNodeLargeStyled>
        }
        padding={0}
        trigger="click"
        ref={popoverRef}
        placement="bottom"
      >
        <TriggerPopover data={data} id={id} />
      </PopoverPanel>
    </React.Fragment>
  );
});

AddNodeLarge.displayName = 'AddNodeLarge';
