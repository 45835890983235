import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useRef, useState } from 'react';
import type { UseControllerProps, UseFormSetValue } from 'react-hook-form';
import { useController, useWatch } from 'react-hook-form';
import { TextInput, Typography } from 'ui';

import { isRuleReadOnlyAtom } from '../../../index';
import {
  ColumnHeaderContainer,
  HeaderInputContainer,
  ResultBar,
  ResultHeader,
} from './DecisionTableBlock.styled';
import { ResultActions } from './ResultActions';
import { ResultAddMenu } from './ResultAddMenu';

type ResultBlockProps = Omit<UseControllerProps, 'name'> & {
  id: string;
  isFirst: boolean;
  isLast: boolean;
  setValue: UseFormSetValue<any>;
  index: number;
  indexToObserve: number;
  setIndexToObserve: (index: number) => void;
  showBlank?: boolean;
  total: number;
};

export default function ResultBlock({
  id,
  isFirst,
  isLast,
  control,
  setValue,
  index,
  showBlank = false,
  total = 0,
  indexToObserve,
  setIndexToObserve,
}: ResultBlockProps) {
  const results = useWatch({ control, name: 'results' });
  const [showResultActions, setShowResultActions] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  const currentProperty = results[index];

  const currentKey = _isNil(currentProperty)
    ? undefined
    : Object.keys(currentProperty).filter((key) => key !== 'id')[0];

  const [isRuleReadOnly] = useAtom(isRuleReadOnlyAtom);

  useEffect(() => {
    if (index === indexToObserve && index !== -1) {
      containerRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });

      setIndexToObserve(-1);
    }
  }, [indexToObserve]);

  const toggleResultActions = () => setShowResultActions(!showResultActions);

  const keyNameLocal = useWatch({
    name: `results.${index}.${currentKey ?? ''}.keyName`,
    control,
  });

  const [localValue, setLocalValue] = useState('');

  useEffect(() => {
    if (keyNameLocal !== localValue) {
      setLocalValue(keyNameLocal);
    }
  }, [JSON.stringify(keyNameLocal)]);

  const { fieldState } = useController({
    // eslint-disable-next-line
    name: `results.${index}.${currentKey}.keyName`,
    control,
  });

  return (
    <ResultHeader
      ref={containerRef}
      padding={isLast ? ['0.8rem', '1.6rem', '0.8rem'] : '0.8rem'}
    >
      <ColumnHeaderContainer>
        <ResultBar
          justify="start"
          align="center"
          stretch="start"
          onMouseEnter={toggleResultActions}
          onMouseLeave={toggleResultActions}
        >
          <Typography fontWeight={700}>Result</Typography>

          {showResultActions && !showBlank && (
            <ResultActions
              control={control}
              setValue={setValue}
              index={index}
            />
          )}

          <ResultAddMenu
            setValue={setValue}
            control={control}
            index={index}
            total={total}
            setIndexToObserve={setIndexToObserve}
          />
        </ResultBar>

        {!showBlank && !_isNil(currentKey) && !_isEmpty(currentKey) && (
          <HeaderInputContainer>
            <TextInput
              onChange={(e) => {
                setLocalValue(e.target.value);
              }}
              onBlur={() => {
                if (localValue !== keyNameLocal) {
                  setValue(
                    `results.${index}.${currentKey}.keyName`,
                    localValue
                  );
                }
              }}
              size="small"
              value={localValue}
              isSmallInput
              showErrorIcon={false}
              disabled={isRuleReadOnly}
              // eslint-disable-next-line
              hasError={!!fieldState.error?.message}
            />

            {
              // eslint-disable-next-line
              !!fieldState.error?.message && (
                <Typography name="errorXs">
                  {fieldState.error?.message ?? ''}
                </Typography>
              )
            }
          </HeaderInputContainer>
        )}
      </ColumnHeaderContainer>
    </ResultHeader>
  );
}
