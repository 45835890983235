import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Button,
  Modal,
  ModalContent,
  RadioListFieldV2,
  Typography,
  useCurrentLayer,
} from 'ui';

import { workflowEdgesAtom } from '../../pages/Workflow/atoms/atoms';
import {
  RadioContainer,
  StyledButtonContainer,
  TitleContainer,
} from './DeleteBranchModal.styled';

type DeleteBranchModalProps = {
  localData?: any;
  id?: string;
};

export function DeleteBranchModal({
  id = '',
  localData,
}: DeleteBranchModalProps) {
  const [workflowEdges] = useAtom(workflowEdgesAtom);
  const [options, setOptions] = useState([
    {
      label: 'Delete All Branches',
      value: 'delete_all',
    },
    {
      label: 'Keep Default Branch',
      value: 'default',
    },
  ]);

  const { close } = useCurrentLayer();

  const { handleSubmit, control } = useForm<any>({
    defaultValues: {
      radio_list: 'delete_all',
    },
  });

  const onSubmit = (values: any) => {
    if (values.radio_list === 'delete_all') {
      localData.deleteNode({
        id,
        type: localData.nodeType ?? 'dtNode',
      });
    } else if (values.radio_list === 'default') {
      const defaultEdges = workflowEdges.filter(
        (e) => e.data.edgeType === 'defaultCase'
      );
      const currentEdge = defaultEdges?.find((e) => e.source === id);

      if (!_isNil(currentEdge)) {
        localData.deleteNodeSwitch({
          id,
          pathId: currentEdge.id,
          type: localData.nodeType ?? 'dtNode',
        });
      }
    } else {
      const edgeId = workflowEdges.find(
        (edge) => edge.data?.pathId === values.radio_list
      )?.id;

      localData.deleteNodeSwitch({
        id,
        pathId: edgeId,
        type: localData.nodeType ?? 'dtNode',
      });
    }

    close();
  };

  useEffect(() => {
    if (!_isNil(localData.switcher)) {
      setOptions(() => {
        return [
          {
            label: 'Delete All Branches',
            value: 'delete_all',
          },
          {
            label: 'Keep Default Branch',
            value: 'default',
          },
          ...localData.switcher.map((s: any) => {
            return {
              label: `Keep ${s.name as unknown as string}`,
              value: s.pathId,
            };
          }),
        ];
      });
    }
  }, [JSON.stringify(localData?.switcher)]);

  return (
    <Modal padding={0} hideCloseButton size="medium">
      <ModalContent>
        <TitleContainer>
          <Typography name="heading2">Delete Switch Node</Typography>
        </TitleContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <RadioContainer gutter="2rem">
            <RadioListFieldV2
              name="radio_list"
              options={options}
              control={control}
              displayRadio
            />
          </RadioContainer>

          <StyledButtonContainer gutter={'1rem'} justify="center">
            <Button type="submit" appearance="danger">
              Continue
            </Button>
            <Button
              type="button"
              onClick={() => {
                close();
              }}
              appearance="neutral"
            >
              Cancel
            </Button>
          </StyledButtonContainer>
        </form>
      </ModalContent>
    </Modal>
  );
}
