import { PadBox, Stack } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { useRef } from 'react';
import type { ReactElement } from 'react';
import type { Placement } from 'tippy.js';
import { Menu, MenuItem, Typography, toasts } from 'ui';
import type { PopoverMethods } from 'ui';

import { subscriptionPlanAtom } from '../../../../atom';
import { useAxiosPrivate } from '../../../../hooks';
import { SubscriptionPlanType } from '../../../../types';
import { checkLimitExceeded } from '../../../../utils/common';
import { ROLES_DETAILS } from '../Team/constant';
import type { Role, RoleDetails } from '../types';

type RoleListProps = {
  children?: ReactElement;
  id?: string;
  launcher: ReactElement;
  onClickRole: (roleDetails: RoleDetails, id?: string) => void;
  placement: Placement;
  onRemoveMember?: (memberId?: string, role?: Role) => void;
  role?: Role;
  rolesToHide?: Role[];
  disabled?: boolean;
};

export function RoleList({
  children,
  id,
  launcher,
  onClickRole,
  placement,
  onRemoveMember,
  role,
  rolesToHide = [],
  disabled = false,
}: RoleListProps) {
  const ref = useRef<PopoverMethods>(null);
  const [subscriptionPlan, setSubscriptionPlan] = useAtom(subscriptionPlanAtom);
  const { axiosPrivate } = useAxiosPrivate();
  const getAccountUsageDetails = async () => {
    const response = await axiosPrivate.get<{
      data: SubscriptionPlanType;
    }>(`/plan/usage`);

    if (!_isNil(response.data.data)) {
      setSubscriptionPlan({
        ...response.data.data,
        planName: response.data.data.plan.name,
      });
    }
  };

  const handleSelectRole = (selectedRole: RoleDetails, id?: string) => {
    const rolesWithEditAccess = ['admin', 'approver', 'editor'];

    if (
      rolesWithEditAccess.includes(role ?? '') &&
      rolesWithEditAccess.includes(selectedRole.value)
    ) {
      onClickRole(selectedRole, id);
      void getAccountUsageDetails();
    } else if (
      checkLimitExceeded('editor', subscriptionPlan) &&
      rolesWithEditAccess.includes(selectedRole.value)
    ) {
      toasts.info(
        `${subscriptionPlan?.usage.editor as string}/${
          subscriptionPlan?.plan.editorLimit as string
        } editors used. Upgrade plan to invite more.`,
        'ws-invite-limit-exceeded'
      );
    } else {
      onClickRole(selectedRole, id);
      void getAccountUsageDetails();
    }
  };

  return (
    <Menu
      launcher={launcher}
      onMenuItemClick={() => {}}
      ref={ref}
      disabled={disabled}
      placement={placement}
      maxHeight="32rem"
    >
      <>
        {ROLES_DETAILS.filter(
          (details) => !rolesToHide.includes(details.value)
        ).map((details) => {
          const { label, description, value } = details;

          return (
            <MenuItem
              value={details}
              key={label}
              disabled={disabled}
              selected={role === value}
              onClick={() => {
                ref.current?.hide();
                handleSelectRole(details, id);
              }}
            >
              <PadBox padding="0.5rem">
                <Stack>
                  <Typography fontWeight={700}>{label}</Typography>

                  <Typography name="secondary2">{description}</Typography>
                </Stack>
              </PadBox>
            </MenuItem>
          );
        })}

        {!_isNil(children) && (
          <MenuItem
            value={id}
            onClick={() => {
              ref.current?.hide();

              if (typeof onRemoveMember === 'function') {
                onRemoveMember(id, role);
              }
            }}
          >
            <PadBox padding="1rem">{children}</PadBox>
          </MenuItem>
        )}
      </>
    </Menu>
  );
}
