import { PadBox } from '@bedrock-layout/padbox';
import { Inline, Stack } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _has from 'lodash/has';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import _reduce from 'lodash/reduce';
import { useEffect, useState } from 'react';
import { UseFormSetValue, useFieldArray, useForm } from 'react-hook-form';
import {
  Button,
  Sheet,
  Spinner,
  TooltipReact,
  Typography,
  toasts,
  useCurrentLayer,
} from 'ui';

import { siteConstantsAtom } from '../../../../../atom';
import { customAttributesAtom } from '../../../../../components/rules/forms/CustomAttributeSheet/CustomAttributeSheet';
import { getUserState } from '../../../../../hooks/getUserState';
import { useSendEventToGTM } from '../../../../../hooks/useSendEventToGTM';
import {
  getTestValuesDefaultDTSR,
  getTooltipText,
  isOnboardingCompleted,
} from '../../../../../utils/common';
import { demoRuleCreated } from '../../../../../utils/constant';
import { StatusCode } from '../../../../../utils/response/statusCode';
import { updateWidgetState } from '../../../../Home/components/sub-components/UpdateWidgetState';
import { useUseGetRuleTestActionResults } from '../../../hooks/restApi/useGetRuleTestActionResult';
import { useTestRule } from '../../../hooks/restApi/useTestRule';
import { usePolling } from '../../../hooks/usePolling';
import { useServerResponse } from '../../../hooks/useServerResponse';
import type { TestObjectModel } from '../../../models';
import type {
  ActionInfoObject,
  RuleTestActionObject,
  RuleTestActionPollingPayload,
} from '../../../types';
import {
  convertDataToTestableData,
  getActionInfoForRuleTestActions,
  getAllInProgressActions,
  getAllRuleTestActions,
  getFormattedJsonForRuleTestActions,
  validateTestParameters,
} from '../../../utils/common';
import {
  dataSetParamsAtom,
  isRulePublishableAtom,
  isRulePublishedAtom,
} from '../../CreateRuleSheet/CreateRuleSheet';
import { RuleTestWarnings } from '../../RuleComponents/RuleTestWarnings/RuleTestWarnings';
import { RuleExecutionTest } from '../../RuleExecutionTest/RuleExecutionTest';
import { RuleTestHeader } from '../../RuleTestHeader/RuleTestHeader';
import { TestNodesDataModel } from '../../SimpleRule/TestNodeSheet/TestNodeSheet';
import { TestAddItems } from '../../TestNodeComponents/TestAddItems';
import { TestNodeField } from '../../TestNodeComponents/TestNodeField';
import { TestNodeFooter } from '../../TestNodeComponents/TestNodeFooter';
import { decisionTableNodeIdAtom } from '../DecisionTable';
import {
  EmptyInputText,
  Form,
  Header,
  HeaderItem,
  Row,
  RowItem,
  SheetContainer,
  TestNodesContainer,
} from './TestNodeSheet.styled';

type TestNodesHeaderModel = {
  label: string;
  dataType: string;
};

type TestNodeSheetProps = {
  isDemo?: boolean;
  ruleName: string;
  setValue: UseFormSetValue<any>;
};

export function TestNodeSheet({
  isDemo = false,
  ruleName,
  setValue: setParentValue,
}: TestNodeSheetProps) {
  const [dataset] = useAtom(dataSetParamsAtom);
  const [customAttributes] = useAtom(customAttributesAtom);
  const [decisionTableId] = useAtom(decisionTableNodeIdAtom);
  const [, setRulePublishable] = useAtom(isRulePublishableAtom);
  const [isRulePublished] = useAtom(isRulePublishedAtom);
  const { attributes } = dataset.customInput;
  const [siteConstants] = useAtom(siteConstantsAtom);

  const { serverResponse, setServerResponse } = useServerResponse();
  const [actionResponse, setActionResponse] = useState<RuleTestActionObject[]>(
    []
  );
  const [actionInfo, setActionInfo] = useState<ActionInfoObject>({
    status: StatusCode.RUNNING,
    count: 0,
  });

  const [pollingPayload, setPollingPayload] = useState<
    RuleTestActionPollingPayload[]
  >([]);
  const { close } = useCurrentLayer();

  const {
    getActionResults,
    actionData,
    error: actionError,
  } = useUseGetRuleTestActionResults();

  const { sendEventToGTM } = useSendEventToGTM();

  const handlePollingRequest = async (
    pollingPayload: RuleTestActionPollingPayload[]
  ) => {
    try {
      if (pollingPayload.length > 0) {
        await getActionResults({
          params: pollingPayload,
        });
      }
    } catch (err: unknown) {
      if (err instanceof Error) {
        toasts.error(err.message, 'error');
      }
    }
  };

  const { setIsPolling } = usePolling({
    functionToExecute: handlePollingRequest,
    args: pollingPayload,
    timeInterval: 2000,
  });

  const [headerList, setHeaderList] = useState<TestNodesHeaderModel[]>([]);

  const {
    testRule,
    isLoading: isTesting,
    data,
    error,
  } = useTestRule({
    ruleName,
    ruleType: 'decisionTable',
    setValue: setParentValue,
  });

  const { control, handleSubmit, setError, setValue } =
    useForm<TestNodesDataModel>({
      defaultValues: {
        test: [
          _reduce(
            customAttributes,
            (result: Record<string, TestObjectModel>, value, key) => {
              if (key !== 'CI0') {
                return {
                  ...result,
                  [key]: {
                    value: getTestValuesDefaultDTSR(customAttributes, key),
                    isNullable: false,
                    isOptional: false,
                  },
                };
              }

              return result;
            },
            {}
          ),
        ],
        headers: [],
        queryParams: [],
      },
    });

  const { fields } = useFieldArray({
    control,
    name: 'test',
  });

  useEffect(() => {
    const actionInfoData = !_isNil(actionData) ? actionData.data : {};

    if (!_isNil(actionInfoData.data)) {
      const data = actionInfoData.data;
      const currActionResponse = structuredClone(actionResponse);

      currActionResponse.forEach((action: RuleTestActionObject) => {
        const { executionId } = action.actionData;

        if (_has(data, executionId)) {
          const { executionTime, output, status } = data[executionId];
          const hasError = !_isNil(output?.error) && !_isEmpty(output?.error);

          action.actionData = {
            isCollapsed: true,
            executionTime,
            status,
            executionId,
            hasError,
            json: getFormattedJsonForRuleTestActions(
              hasError ? output?.error : output?.Result
            ),
          };
        }
      });

      let currPollingPayload = structuredClone(pollingPayload);
      currPollingPayload = currPollingPayload.filter(
        (actionPayload: RuleTestActionPollingPayload) =>
          _has(data, actionPayload.executionId) &&
          data[actionPayload.executionId].status === StatusCode.RUNNING
      );

      setActionResponse(currActionResponse);
      setPollingPayload(currPollingPayload);
    }
  }, [JSON.stringify(actionData)]);

  useEffect(() => {
    if (!_isEmpty(attributes)) {
      setHeaderList(
        _reduce(
          attributes,
          (result: TestNodesHeaderModel[], value, key) => {
            if (key !== 'CI0') {
              result.push({ label: key, dataType: attributes[key].dataType });
            }

            return result;
          },
          []
        )
      );
    }

    return () => {
      setHeaderList([]);
    };
  }, [attributes]);

  const onSubmit = async (data: TestNodesDataModel) => {
    sendEventToGTM({
      event: 'rule',
      ruleId: decisionTableId,
      ruleName,
      type: 'descisionTable',
      action: 'test',
      nec_source: '',
    });

    if (isDemo && !isOnboardingCompleted(demoRuleCreated)) {
      updateWidgetState(demoRuleCreated)
        .then(() => {
          void getUserState();
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    }
    const isValid = validateTestParameters(data, setError);

    if (!_isNil(decisionTableId) && isValid) {
      try {
        await testRule({
          id: decisionTableId,
          params: convertDataToTestableData(data.test[0], attributes),
          headers: data.headers,
        });

        if (!isRulePublished) {
          setRulePublishable(true);
        }
      } catch {
        setRulePublishable(false);
      }
    }
  };

  useEffect(() => {
    const json = !_isNil(data) ? data.data : {};
    const errorJson = error?.response?.data;

    if (!_isNil(json.data)) {
      setServerResponse({
        json: JSON.stringify(json, null, 2),
        executionTime: json.data?.executionTime,
      });

      const actionList = getAllRuleTestActions(json.data.action);

      setActionResponse(actionList);
      setPollingPayload(getAllInProgressActions(actionList));
      setIsPolling(true);

      if (!isRulePublished) {
        setRulePublishable(true);
      }
    } else if (!_isNil(errorJson)) {
      setServerResponse({
        json: JSON.stringify(errorJson, null, 2),
        executionTime: '',
      });

      setRulePublishable(false);
    }
  }, [data, error]);

  useEffect(() => {
    const currActionInfo: ActionInfoObject =
      getActionInfoForRuleTestActions(actionResponse);
    setActionInfo(currActionInfo);

    const isPollingRequired = actionResponse.some(
      (action: RuleTestActionObject) =>
        action.actionData.status === StatusCode.RUNNING
    );

    setIsPolling(isPollingRequired);
    setActionInfo(currActionInfo);
  }, [actionResponse]);

  useEffect(() => {
    if (!_isEmpty(actionError) && !_isEmpty(actionError.response)) {
      const { status, data } = actionError.response;
      const message = !_isEmpty(data) ? (data as any).message : '';

      if (
        !_isNil(status) &&
        (status === 400 || status === 401 || status >= 500)
      ) {
        setIsPolling(false);

        const updatedActionResponse = actionResponse.map(
          (action: RuleTestActionObject) => {
            if (action.actionData.status === StatusCode.RUNNING) {
              action.actionData.status = StatusCode.FAILED;
              action.actionData.json = message;
              action.actionData.hasError = true;
            }

            return action;
          }
        );

        setActionResponse(updatedActionResponse);
      }
    }
  }, [actionError]);

  const handleExpandIconClick = (actionName: string) => {
    const updatedActionResponse = structuredClone(actionResponse);
    updatedActionResponse.forEach((action) => {
      action.actionData.isCollapsed =
        actionName !== action.actionName
          ? true
          : !action.actionData.isCollapsed;
    });

    setActionResponse(updatedActionResponse);
  };

  return (
    <Sheet size="large">
      <SheetContainer>
        <RuleTestHeader />
        <RuleTestWarnings />
        <Stack gutter="1.6rem" as={Form} onSubmit={handleSubmit(onSubmit)}>
          {headerList.length > 0 ? (
            <>
              <Typography fontWeight={700}>Input Attributes</Typography>
              <TestNodesContainer as="ul">
                <Header gutter={0}>
                  {headerList.map((header, index) => (
                    <HeaderItem key={`attributeHeader_${index}`}>
                      <PadBox padding={[5, 10]}>
                        <Inline stretch="start" align="end">
                          {header.label.length > 15 ? (
                            <TooltipReact
                              id={`attributeHeader_${header.label}`}
                              launcher={
                                <span>
                                  <Typography>
                                    {header.label.substring(0, 15)}...
                                  </Typography>
                                </span>
                              }
                            >
                              <Typography>{header.label}</Typography>
                            </TooltipReact>
                          ) : (
                            <Typography>{header.label}</Typography>
                          )}
                          <Typography name="secondaryXs">
                            {header.dataType}
                          </Typography>
                        </Inline>
                      </PadBox>
                    </HeaderItem>
                  ))}
                </Header>

                {fields.map((field, index) => (
                  <Row
                    gutter={0}
                    key={`testRowItem_${field.id}`}
                    align="stretch"
                  >
                    {Object.keys(field)
                      .filter((key) => key !== 'id')
                      .map((key, fieldIndex) => (
                        <RowItem key={`testRowItem_${index}_${fieldIndex}`}>
                          <PadBox padding={[5, 10]} as={Inline} align="center">
                            <TestNodeField
                              nodeKey={key}
                              index={index}
                              dataType={attributes[key].dataType}
                              control={control}
                              isNullable={customAttributes[key]?.isNullable}
                              isOptional={customAttributes[key]?.isOptional}
                              setValue={setValue}
                            />
                          </PadBox>
                        </RowItem>
                      ))}
                  </Row>
                ))}
              </TestNodesContainer>{' '}
            </>
          ) : (
            <EmptyInputText name="secondarySmall">
              You do not have any input attributes configured. Please go ahead
              and click on {`"Test Now"`} to test the rule.
            </EmptyInputText>
          )}

          <Stack gutter={16}>
            <TestAddItems
              control={control}
              label="Headers"
              name="headers"
              tooltipText={getTooltipText(siteConstants, 'rules', 'testHeader')}
            />
          </Stack>

          <Inline justify="end">
            <Button type="submit" disabled={isTesting}>
              {isTesting ? <Spinner size="extraSmall" /> : 'Test Now'}
            </Button>
          </Inline>
          {!_isEmpty(serverResponse.json) && (
            <RuleExecutionTest
              serverResponse={serverResponse}
              isTesting={isTesting}
              actionResponse={actionResponse}
              actionInfo={actionInfo}
              handleExpandIconClick={handleExpandIconClick}
            />
          )}
        </Stack>
        <TestNodeFooter>
          <Button onClick={close}>Close</Button>
        </TestNodeFooter>
      </SheetContainer>
    </Sheet>
  );
}
