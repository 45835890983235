import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { RxCross2 } from 'react-icons/rx';
// eslint-disable-next-line import/no-named-default
import { default as _ReactPlayer } from 'react-player';
import { ReactPlayerProps } from 'react-player/types/lib';
import {
  Button,
  IconButton,
  Modal,
  ModalContent,
  ModalFooter,
  Typography,
  useCurrentLayer,
} from 'ui';

import { envMap } from '../../../../utils/constant';
import { IconContainer } from '../../../ListingBlocks/listingBlocks.styled';
import {
  CustomClose,
  VideoBlock,
  VideoBlockContainer,
} from '../../listing.styled';

const ReactPlayer = _ReactPlayer as unknown as React.FC<ReactPlayerProps>;

type VideoDataProps = {
  name: string;
  videoUrl: string;
  time: string;
  documentUrl: string;
};

type VideoCategory = {
  category: string;
  items: VideoDataProps[];
};

type ListingVideoBlockProps = {
  entity: string;
};
export const ListingVideoBlock = ({ entity }: ListingVideoBlockProps) => {
  const { close: closePopup } = useCurrentLayer();
  const customClosePopup = () => {
    closePopup();
  };
  const [selectedVideo, setSelectedVideo] = useState<number | null>(null);
  const [videosData, setVideosData] = useState<VideoDataProps[]>([]);

  useEffect(() => {
    fetch(`${envMap.VITE_ASSETS_URL}home/learn_videos.json`)
      .then(async (response) => await response.json())
      .then((data) =>
        setVideosData(
          data.filter(
            (i: VideoCategory) =>
              i.category.toLowerCase() === entity.toLowerCase()
          )[0].items
        )
      )
      .catch(() => setVideosData([]));
  }, []);

  const handlePlayVideo = (index: number) => {
    setSelectedVideo(index);
  };

  const handleVideoBack = () => {
    setSelectedVideo(null);
  };

  return (
    <Modal hideCloseButton={true} size="doubleExtraLarge">
      <CustomClose>
        <IconButton onClick={customClosePopup}>
          <RxCross2 size={16} />
        </IconButton>
      </CustomClose>
      <ModalContent>
        <Stack gutter={'1.5rem'}>
          <Typography name="heading2">
            {!_isNil(selectedVideo)
              ? videosData[selectedVideo].name
              : 'Learn about Nected'}
          </Typography>
          {_isNil(selectedVideo) && (
            <Inline as={VideoBlockContainer}>
              {videosData.map((i, index) => {
                return (
                  <PadBox
                    key={index}
                    onClick={() => {
                      handlePlayVideo(index);
                    }}
                    as={VideoBlock}
                    padding="1.5rem"
                    gutter="1.5rem"
                  >
                    <IconContainer>
                      <img
                        src={`${envMap.VITE_ASSETS_URL}home/home_play.svg`}
                        alt="icon"
                        style={{ maxWidth: '10rem' }}
                      />
                    </IconContainer>

                    <Stack gutter={'0rem'}>
                      <Inline>
                        <Typography fontWeight={700} name={'heading4'}>
                          {i.name}
                        </Typography>
                      </Inline>

                      <Typography name="secondary2" fontWeight={400}>
                        {i.time}
                      </Typography>
                    </Stack>
                  </PadBox>
                );
              })}
            </Inline>
          )}
          {!_isNil(selectedVideo) && (
            <Stack gutter={'0.5rem'}>
              <ReactPlayer
                url={videosData[selectedVideo].videoUrl}
                playing={true}
                pip
                controls
                width={'100%'}
                height={'550px'}
                config={{
                  youtube: {
                    playerVars: { showinfo: 1 },
                  },
                }}
              />
              <Button appearance="neutral" onClick={handleVideoBack}>
                Back
              </Button>
            </Stack>
          )}
        </Stack>
      </ModalContent>
      <ModalFooter></ModalFooter>
    </Modal>
  );
};
