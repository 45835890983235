import _isNil from 'lodash/isNil';
import { ReactNode } from 'react';

import { Footer, FormStyled } from './FormWrapper.styled';

type FormProps = {
  children: ReactNode;
  footerContent?: ReactNode;
};

export function FormWrapper({ children, footerContent }: FormProps) {
  return (
    <FormStyled>
      {children}
      {!_isNil(footerContent) && <Footer stretch="all">{footerContent}</Footer>}
    </FormStyled>
  );
}
