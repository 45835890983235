import { Inline } from '@bedrock-layout/primitives';
import styled from 'styled-components';

export const ActionFieldContainer = styled(Inline)`
  flex-wrap: wrap;
  border-radius: 6px;
  border: 1px solid var(--color-whiteSmoke);
  padding: 0.8rem;
  max-inline-size: 60vw;
  min-inline-size: 46.4rem;
  margin-bottom: 0.8rem;
`;
