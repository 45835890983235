import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';

export function getUserAvatar(
  firstName: string,
  lastName: string,
  email: string
) {
  let avatar = '';

  if (!_isEmpty(firstName) && !_isNil(firstName)) {
    avatar = firstName.at(0) ?? '';
  }

  if (!_isEmpty(lastName) && !_isNil(lastName)) {
    avatar += lastName.at(0) ?? '';
  }

  if (_isEmpty(avatar) && !_isEmpty(email)) {
    avatar = email[0].toUpperCase() ?? '';
  }

  return avatar;
}

export function getUserName(firstName: string, lastName: string) {
  let name = '';

  if (!_isEmpty(firstName) && !_isNil(firstName)) {
    name = firstName;
  }

  if (!_isEmpty(lastName) && !_isNil(lastName)) {
    name += ` ${lastName}`;
  }

  return name;
}
