import styled from 'styled-components';

export const TableRowContainerStyled = styled.li<{ $hasError?: boolean }>`
  border-block-end: 1px solid var(--color-whiteSmoke);

  ${({ $hasError = false }) =>
    $hasError && 'background-color: var(--color-snow);'}

  &:last-child {
    border-block-end: none;
  }
`;
