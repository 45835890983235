import { AnimatePresence } from 'framer-motion';
import React, {
  ReactElement,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Portal } from 'react-portal';

import { createUUID } from './utils';

type OpenLayerFunction = (layer: Layer) => void;
type CloseLayerFunction = (layerId: string) => void;
type CloseAllLayersFunction = () => void;

export type LayerContextValue = {
  open: OpenLayerFunction;
  close: CloseLayerFunction;
  closeAllLayers: CloseAllLayersFunction;
};

const LayerContext = createContext<LayerContextValue>({
  open: () => {},
  close: () => {},
  closeAllLayers: () => {},
});

export type CurrentLayerContextValue = {
  layerId: string;
  close: () => void;
  isLast: boolean;
};

const CurrentLayerContext = createContext({
  layerId: '',
  close: () => {},
  isLast: false,
  totalLayers: 0,
  closeAllLayers: () => {},
});

export type LayerProviderProps = {
  children: ReactNode;
};

export type Layer = {
  layer: ReactElement;
  layerId: string;
};

function isLayerAvailable({ layerId }: Layer, previousLayers: Layer[]) {
  return previousLayers.some(
    (existingLayer) => existingLayer.layerId === layerId
  );
}

export function LayerProvider({ children }: LayerProviderProps) {
  const [openLayers, setOpenLayers] = useState<Layer[]>([]);

  const open = useCallback((newLayer: Layer) => {
    setOpenLayers((previousLayers) => {
      if (!isLayerAvailable(newLayer, previousLayers)) {
        return [...previousLayers, newLayer];
      }

      return previousLayers;
    });
  }, []);

  const close = useCallback((layerId: string) => {
    setOpenLayers((previousLayers) => {
      return previousLayers.filter(
        (existingLayer) => existingLayer.layerId !== layerId
      );
    });
  }, []);

  const closeAllLayers = useCallback(() => {
    setOpenLayers([]);
  }, [JSON.stringify(openLayers.length)]);

  const layerContextValue = useMemo(
    () => ({ open, close, closeAllLayers }),
    [open, close, closeAllLayers]
  );

  const currentLayerContexts = openLayers.map(({ layer, layerId }, index) => ({
    layer,
    layerId,
    value: {
      layerId,
      close: () => {
        close(layerId);
      },
      closeAllLayers,
      isLast: openLayers.length - 1 === index,
      totalLayers: openLayers.length,
    },
  }));

  return (
    <LayerContext.Provider value={layerContextValue}>
      {children}
      <Portal>
        <AnimatePresence>
          {currentLayerContexts.map(({ layer, layerId, value }) => (
            <CurrentLayerContext.Provider key={layerId} value={value}>
              {layer}
            </CurrentLayerContext.Provider>
          ))}
        </AnimatePresence>
      </Portal>
    </LayerContext.Provider>
  );
}

export function useLayer(layer: ReactElement) {
  const layerId = useRef<string>('');
  const {
    open: openLayer,
    close: closeLayer,
    closeAllLayers,
  } = useContext(LayerContext);

  useEffect(() => {
    layerId.current = `Layer-${createUUID()}`;
  }, []);

  const open = useCallback(() => {
    openLayer({ layer, layerId: layerId.current });
  }, [layer, openLayer]);

  const openWithProps = useCallback(
    (additionalProps: Record<string, any>) => {
      openLayer({
        layer: React.cloneElement(layer, { ...additionalProps }),
        layerId: layerId.current,
      });
    },
    [layer, openLayer]
  );

  return useMemo(
    () => ({
      open,
      openWithProps,
      close: closeLayer,
      closeAllLayers,
    }),
    [open, openWithProps, closeLayer, closeAllLayers]
  );
}

export function useCurrentLayer() {
  return useContext(CurrentLayerContext);
}
