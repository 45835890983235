export const basicAuthJson = {
  username: {
    label: 'Username',
    order: 9,
    placeholder: '',
    regex: '',
    tooltipText: null,
    type: 'text',
    hidden: 'false',
    isDisabled: 'false',
    isRequired: 'true',
    width: '100%',
  },
  password: {
    label: 'Password',
    order: 9,
    placeholder: '',
    regex: '',
    tooltipText: null,
    type: 'password',
    hidden: 'false',
    isDisabled: 'false',
    isRequired: 'true',
    width: '100%',
  },
};
