import { gql, useLazyQuery } from '@apollo/client';

const getDataSetsQuery = gql`
  query getDataSet(
    $dataSetId: String!
    $live: Boolean
    $filters: QueryOperators
  ) {
    getDataSet(id: $dataSetId, live: $live, filters: $filters) {
      data {
        id
        name
        fields
        version

        connector {
          id
          name
          plugin {
            imageUrl
            name
          }
          staging {
            isTested
            isPublish
          }
        }
      }
    }
  }
`;

export function useGetDataSetById() {
  return useLazyQuery(getDataSetsQuery);
}
