import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  toasts,
  useCurrentLayer,
} from 'ui';

import { useGetPlugins } from '../../../../../hooks/graphql/useGetPlugins';
import { useGetPublishedConnectors } from '../../../../../pages/DataSets/hooks/useGetPublishedConnectors';
import type { ConnectorAndPluginModel } from '../../../../../pages/Integrations/types';
import { ControlListType } from '../../../../../pages/Workflow/models/models';
import { CreateActionContainer } from '../../../../CreateAction/components/CreateActionContainer';

type DataSetModalV2Props = {
  getDataSets: () => void;
};

export function DataSetModalV2({ getDataSets }: DataSetModalV2Props) {
  const [searchInput] = useState<string>('');
  const [, setConnectorList] = useState<ConnectorAndPluginModel[]>([]);
  const [controlList, setControlList] = useState<ControlListType[]>([]);
  const [refetchCount, setRefetchCount] = useState(0);

  const { data, refetch } = useGetPublishedConnectors(true);
  const [getConnectorPlugins, { data: pluginData }] = useGetPlugins({
    isSource: true,
  });

  const { close: closeModal } = useCurrentLayer();

  useEffect(() => {
    if (!_isNil(data)) {
      setConnectorList(
        data.getConnector.data.filter(
          (connector) =>
            _isEmpty(searchInput) ||
            connector.name.toLowerCase().includes(searchInput.toLowerCase())
        )
      );
    }
  }, [data, searchInput]);

  useEffect(() => {
    if (!_isNil(pluginData)) {
      setControlList(
        pluginData.getPlugin.data.map((p) => {
          return {
            name: p.displayName,
            type: 'plugin',
            entityId: p.name,
            icon: p.imageUrl,
            metaData: {
              pluginId: p.id,
              category: p.category,
            },
          };
        })
      );
    }
  }, [pluginData]);

  useEffect(() => {
    void getConnectorPlugins();
  }, []);

  useEffect(() => {
    return () => {
      void getDataSets();
    };
  }, []);

  useEffect(() => {
    if (!_isNil(data)) {
      const publishedConnectors = data.getConnector.data;

      const controlListPayload: any[] = [];

      publishedConnectors.forEach((connector) => {
        if (
          _isNil(
            controlListPayload.find((i) => i.entityId === connector.plugin.name)
          )
        ) {
          controlListPayload.push({
            name: connector.plugin.displayName,
            type: 'plugin',
            entityId: connector.plugin.name,
            icon: connector.plugin.imageUrl,
            metaData: {
              pluginId: connector.plugin.id,
              category: connector.plugin.category,
            },
          });
        }
      });
    }
  }, [data]);

  const refetchData = async () => {
    try {
      await refetch();
      toasts.success('Fetched connector successfully!', 'connector-fetch');
    } catch (error) {
      toasts.error('Failed to fetch connector', 'connector-fetch');
    }
  };

  return (
    <Modal size="small" hideHeader>
      <ModalContent>
        <CreateActionContainer
          handleRefresh={async () => {
            await refetchData();
            setRefetchCount((c) => c + 1);
          }}
          refetchCount={refetchCount}
          controlList={controlList}
          handleClick={(item) => {
            window.open(
              `${
                window.location.origin
              }/datasets?type=create&stage=staging&editor=${
                item.key as string
              }&connectorId=${item.value as string}`,
              window !== window.parent ? '_self' : '_blank'
            );

            closeModal();
          }}
        />
      </ModalContent>

      <ModalFooter justify="end" showBorder>
        <Button appearance="filled" onClick={closeModal}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
}
