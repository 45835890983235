import type { ObservableQuery } from '@apollo/client';
import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import _isNil from 'lodash/isNil';
import { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Typography, useLayer } from 'ui';

import { permissionObj } from '../../../../components/PermissionComponent/constant';
import { useCheckPermissions } from '../../../../components/PermissionComponent/hooks/useCheckPermissions';
import { handleSetCheckSumByEntityName } from '../../../../utils/common';
import { ENTITY_ID } from '../../../../utils/constant';
import type { ConnectorModel } from '../../models';
import type { ConnectorPublishedStatus, Plugin } from '../../types';
import { IntegrationCardDetails } from '../common/IntegrationCardDetails';
import type { GetConnectorsQueryResult } from '../hooks/useGetConnectors';
import { ConnectorCardStyled } from './ConnectorCard.styled';
import { ConnectorStatusStyled } from './ConnectorStatus.styled';
import { ConnectorFormSheet } from './form/ConnectorFormSheet';
import { ConnectorMenu } from './menu/ConnectorMenu';

export type ConnectorCardProps = {
  connector: ConnectorModel & {
    plugin: Plugin;
    checksum: string;
  };
  refetch: ObservableQuery<GetConnectorsQueryResult>['refetch'];
};

function getConnectorPublishStatus(
  staging: ConnectorPublishedStatus,
  production: ConnectorPublishedStatus
) {
  const isPublishedInStaging = (staging ?? { isPublish: false }).isPublish;
  const isPublishedInProduction = (production ?? { isPublish: false })
    .isPublish;
  const isTestedInStaging = (staging ?? { isTested: false }).isTested;
  const isTestedInProduction = (production ?? { isTested: false }).isTested;

  if (isPublishedInProduction) {
    return 'Production';
  }

  if (isPublishedInStaging) {
    return 'Staging';
  }

  if (isTestedInStaging || isTestedInProduction) {
    return 'Tested';
  }

  return 'Draft';
}

export function ConnectorCard({ connector, refetch }: ConnectorCardProps) {
  const params = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { openWithProps: openConnectorFormSheet } = useLayer(
    <ConnectorFormSheet
      connector={connector}
      refetch={refetch}
      currentStage={searchParams.get('stage') ?? 'staging'}
    />
  );

  const { staging, production } = connector;

  const status = getConnectorPublishStatus(staging, production);
  useEffect(() => {
    if (!_isNil(params.actionId) && params.actionId === connector.id) {
      openConnectorFormSheet({
        connector,
      });
    }
  }, [JSON.stringify(params)]);

  const { isHide: isEditDisabled } = useCheckPermissions({
    allowedPermission: [permissionObj.create, permissionObj.edit],
    entityList: [ENTITY_ID.integrations],
  });

  const { isHide: isDeleteDisabled } = useCheckPermissions({
    allowedPermission: [permissionObj.delete],
    entityList: [ENTITY_ID.integrations],
  });
  const handleOpenConnectorFormSheet = () => {
    let state = 'staging';
    handleSetCheckSumByEntityName('integrations', connector.checksum);

    if (connector.production?.isPublish) {
      state = 'production';
    }
    navigate(
      `/integrations/${connector.id}?stage=${state}&connector=${connector?.plugin.name}`
    );
  };

  return (
    <ConnectorCardStyled>
      <PadBox padding="0.8rem">
        <Stack gutter="1.6rem">
          <Inline
            justify={isEditDisabled && isDeleteDisabled ? 'center' : 'end'}
            align="center"
          >
            <ThemeProvider theme={{ status: status.toLocaleLowerCase() }}>
              <ConnectorStatusStyled>
                <Typography>{status}</Typography>
              </ConnectorStatusStyled>
            </ThemeProvider>

            {(!isEditDisabled || !isDeleteDisabled) && (
              <ConnectorMenu
                openConnectorFormSheet={handleOpenConnectorFormSheet}
                connectorId={connector.id}
                refetch={refetch}
                checksum={connector.checksum}
                isEditDisabled={isEditDisabled}
                isDeleteDisabled={isDeleteDisabled}
              />
            )}
          </Inline>

          <IntegrationCardDetails
            alt={connector?.plugin?.displayName}
            image={connector?.plugin?.imageUrl}
            name={connector?.name}
            connectAppearance={
              ['Production', 'Staging'].includes(status)
                ? 'connected'
                : ['Tested'].includes(status)
                ? 'tested'
                : 'pending'
            }
            onClick={handleOpenConnectorFormSheet}
          />
        </Stack>
      </PadBox>
    </ConnectorCardStyled>
  );
}
