import { PadBox } from '@bedrock-layout/padbox';
import { Stack } from '@bedrock-layout/stack';
import { JsonHeroTreeViewer, NectedEditor } from 'ui';

import { ExecutionView } from '../../../../../../components/jsonOutputField/components/ExecutionView';
import { editorDomain } from '../../../../../../utils/constant';
import {
  JsonContainer,
  OutputContainer,
  TreeContainer,
} from './SetVariableNodeTest.styled';

type SetVariableNodeTestProps = {
  output?: Record<string, any>;
};
export function SetVariableNodeTest({
  output = {
    _comment: 'Please Test the node first',
  },
}: SetVariableNodeTestProps) {
  return (
    <PadBox padding="2rem">
      <OutputContainer padding="1rem">
        <Stack gutter="1rem">
          <ExecutionView>
            <JsonContainer>
              <NectedEditor
                domain={editorDomain}
                mode="json"
                defaultValue={JSON.stringify(output, null, 2)}
                readOnly={true}
              />
            </JsonContainer>

            <TreeContainer padding="0.4rem">
              <JsonHeroTreeViewer json={output} />
            </TreeContainer>
          </ExecutionView>
        </Stack>
      </OutputContainer>
    </PadBox>
  );
}
