import type { ObservableQuery } from '@apollo/client';
import type { AxiosError } from 'axios';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { useCurrentLayer } from 'ui';

import { checksumWarningAtom } from '../../../../../atom';
import { useLayerCloseOnPath } from '../../../../../hooks/useLayerCloseOnPath';
import type { EntityType } from '../../../../../hooks/useLayerCloseOnPath';
import {
  checksumMessage,
  getResponseErrorMessage,
  handleGetCheckSumByEntityName,
  handleSetCheckSumByEntityName,
  showGraphQlErrorToast,
} from '../../../../../utils/common';
import { Environment } from '../../../../../utils/constant';
import type { CustomAxiosError } from '../../../../../utils/response/types';
import type { ConnectorModel } from '../../../models';
import type { Plugin } from '../../../types';
import { IntegrationSheet } from '../../common/IntegrationSheet';
import { useCurrentEnvironment } from '../../hooks/useCurrentEnvironment';
import { useGetConnector } from '../../hooks/useGetConnector';
import type { GetConnectorsQueryResult } from '../../hooks/useGetConnectors';
import { useResetForm } from '../../hooks/useResetForm';
import { useStoreFormValues } from '../../hooks/useStoreFormValues';
import { useUpdateConnectorPublishStatus } from '../../hooks/useUpdateConnectorPublishStatus';
import { useUpdateConnectorStatus } from '../../hooks/useUpdateConnectorStatus';

export type ConnectorFormSheetProps = {
  connector: ConnectorModel & {
    plugin: Plugin;
  };
  refetch: ObservableQuery<GetConnectorsQueryResult>['refetch'];
  currentStage?: string;
};

export function ConnectorFormSheet({
  connector,
  refetch,
  currentStage,
}: ConnectorFormSheetProps) {
  const { updateConnectorStatus, connectorStatus } = useUpdateConnectorStatus();
  const { currentEnvironment, toggleEnvironment } = useCurrentEnvironment();
  const { clearIntegrationFormValues, storeFormValues } = useStoreFormValues();
  const [connectorName, setConnectorName] = useState('');
  const { closeAllLayers } = useCurrentLayer();

  const [, setShowChecksumPopup] = useAtom(checksumWarningAtom);

  useResetForm(() => {
    clearIntegrationFormValues();
    void refetch();
  });

  useLayerCloseOnPath({
    entityName: location.pathname.split('/')[1] as EntityType,
    onRouteChange: () => closeAllLayers(),
  });

  const {
    stagingSortedFields,
    productionSortedFields,
    formJson,
    loading: getConnectorLoading,
    error,
    shouldFormRender,
    getCurrentConnector,
    refetch: refetchAuthKeys,
  } = useGetConnector(
    connector.id,
    updateConnectorStatus,
    storeFormValues,
    setConnectorName
  );

  useEffect(() => {
    void getCurrentConnector({
      variables: {
        connectorId: connector.id,
      },
    });
  }, []);

  const { displayName, name, imageUrl, id } = connector.plugin;

  const { updateInProgress, updateConnector, publishConnector } =
    useUpdateConnectorPublishStatus(
      connector.id,
      currentEnvironment,
      currentEnvironment === Environment.STAGING
        ? stagingSortedFields
        : productionSortedFields,
      updateConnectorStatus,
      connectorName,
      displayName
    );

  const onSubmit = async (formValues: unknown) => {
    const { name } = formValues as Record<string, any>;

    try {
      const checksum = handleGetCheckSumByEntityName('integrations');

      const { data } = await updateConnector(formValues, checksum ?? '');

      if (!_isNil(data)) {
        handleSetCheckSumByEntityName(
          'integrations',
          data.updateConnector.checksum
        );
      }

      updateConnectorStatus(currentEnvironment, false, false, true);

      setConnectorName(name);
    } catch (error: unknown) {
      showGraphQlErrorToast(error);

      if (error instanceof Error) {
        if (
          getResponseErrorMessage(
            error as AxiosError<CustomAxiosError>
          ).includes(checksumMessage)
        ) {
          setShowChecksumPopup({
            showPopup: true,
            metaData: {
              connectorId: connector.id,
              connectorName,
              type: displayName,
            },
          });
        }
      }
    }
  };

  const isLoading = getConnectorLoading;

  if (!_isNil(error)) {
    return <div>error</div>;
  }

  return (
    <IntegrationSheet
      connectorId={connector.id}
      connectorName={connectorName}
      connectorStatus={connectorStatus}
      displayName={displayName}
      imageUrl={imageUrl}
      isCreatingOrUpdating={updateInProgress}
      isLoading={isLoading}
      onSubmit={onSubmit}
      publishConnector={publishConnector}
      stagingSortedFields={stagingSortedFields}
      productionSortedFields={productionSortedFields}
      formJson={formJson}
      formType="edit"
      pluginId={id}
      pluginName={name}
      toggleEnvironment={toggleEnvironment}
      updateConnectorStatus={updateConnectorStatus}
      shouldFormRender={shouldFormRender}
      currentStage={currentStage}
      refetchAuthKeys={refetchAuthKeys}
    />
  );
}
