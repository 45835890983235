import { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { useRefreshToken } from '../../../hooks';
import { useAuth } from './AuthProvider';

export function RememberMe() {
  const { refresh } = useRefreshToken();
  const { isLoggedIn, rememberMe, auth } = useAuth();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let isMounted = true;
    const generateNewAccessToken = async () => {
      try {
        await refresh();
      } catch {
      } finally {
        isMounted && setIsLoading(false);
      }
    };

    if (auth.refreshToken !== '') {
      (async function () {
        !isLoggedIn && rememberMe
          ? await generateNewAccessToken()
          : setIsLoading(false);
      })().catch(() => {});
    } else {
      setIsLoading(false);
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <>{rememberMe ? isLoading ? <p>Loading...</p> : <Outlet /> : <Outlet />}</>
  );
}
