export const DATE_FORMAT_REGEX = /^(\d{4})-(\d{2})-(\d{2})$/;

export const DATE_TIME_REGEX =
  /^(\d{4})-(\d{2})-(\d{2})(?:T|\s)(\d{2}):(\d{2}):(\d{2})(?:\.(\d{1,3}))?(?:(Z)|(?:([+-])(\d{2}):(\d{2})))$/;

export const FLOATING_NUMBER_REGEX = /^-?\d+(\.\d+)?$/;

export const INTEGER_REGEX = /^[-\d]+$/;

export const KEY_REGEX = /^[a-zA-Z0-9_]+$/;

export const ACTION_KEY_REGEX =
  /^[a-zA-Z_0-9][\w-]*(?:\[\d*]|\[[a-zA-Z_][\w-]*])*$/;

export const NO_SPACES_ALLOWED_REGEX = /^[^\s]+$/;

export const PASSWORD_REGEX =
  /^(?!\S*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[#?!@$%^&*-]).{8,50}$/;

export const TEXT_INPUT_REGEX = /^[a-zA-Z0-9-\\.\\_ ]{1,50}$/;

export const TOKEN_REGEX = /(?<={{.).[.a-zA-Z_0-9]*(?=}})/g;

export const HEADER_TOKEN_REGEX = /(?<={{.).[.a-zA-Z_0-9-]*(?=}})/g;

export const VALID_STRING_REGEX = /^[\w\d\s\W]+$/;

export const MONGO_QUERY_REGEX =
  /^db\.(\w+)\.((find|findOne|aggregate){1})\((.*?)\)/;

export const MONGO_ACTION_REGEX = /^db\.(\w+)\.(\w+)\((.*?)\)/;

export const NOT_EMPTY_STRING_REGEX = /^(?!\s*$).{1,50}$/;

export const REST_API_KEY_REGEX = /^[a-zA-Z_$][a-zA-Z0-9_$]*$/;

export const REMOVE_JAVASCRIPT_COMMENTS_REGEX =
  /\/\*[\s\S]*?\*\/|(?<=[^:])\/\/.*|^\/\/.*/gm;

export const VALID_JSON_REGEX = /(['"])?([a-zA-Z0-9_]+)(['"])?:/g;

export const REMOVE_DOUBLE_DOLLAR_REGEX = /\$\$(.*?)\$\$/g;

export const CURLY_BRACES_PRESENT_REGEX = /{.*}/;

export const DOUBLE_DOLLAR_PRESENT_REGEX = /\$\$(.*?)\$\$/;

export const CAMEL_CASE_REGEX = /(?:^\w|[A-Z]|\b\w)/g;

export const SPACE_REGEX = /\s+/g;

export const OUTPUT_DATA_REGEX = /^.*output\[\d+\].*$/;

export const LIST_KEY_REGEX = /\[\d+\]/g;

export const EXTRACT_TOKEN_REGEX = /\{\{\s*(\.[a-zA-Z0-9-_[\]]+)+\s*\}\}/gm;

export const EXTRACT_SQUARE_BRACKETS = /\[\d\]/g;

export const KEY_VALUE_STRUCT = /(['"])?([a-zA-Z0-9_]+)(['"])?:/g;

export const HEX_CODE_REGEX =
  /^#([a-fA-F0-9]{8}|[a-fA-F0-9]{6}|[a-fA-F0-9]{4}|[a-fA-F0-9]{3})$/;
