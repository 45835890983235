import { Stack } from '@bedrock-layout/stack';
import styled from 'styled-components';
import { Typography } from 'ui';

export const BannerContainer = styled(Stack)`
  max-width: 70rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CenteredText = styled(Typography)`
  text-align: center;
`;
