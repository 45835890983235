import { PadBox } from '@bedrock-layout/padbox';
import { Inline } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import { memo, useEffect, useState } from 'react';
import { IoPlayOutline } from 'react-icons/io5';
import { TiArrowLoop } from 'react-icons/ti';
import { Handle, NodeProps, Position } from 'reactflow';
import { IconButton, Typography, toasts, useLayer } from 'ui';

import { useSendEventToGTM } from '../../../../../../hooks/useSendEventToGTM';
import { COMMON_NODE_STYLES } from '../../../../../../utils/constant';
import {
  changedNodeIdsAtom,
  isWorkflowTestOnlyAtom,
  workflowEdgesAtom,
  workflowErrorByNodeAtom,
  workflowNodesAtom,
  workflowStatusAtom,
} from '../../../../atoms/atoms';
import { useTestLoopNode } from '../../../../hooks/useTestLoopNode';
import {
  checkNodeDisabled,
  getExecutedValueAndStatus,
} from '../../../../utils/common';
import { statusListForDataUpdate } from '../../../../utils/constant';
import { LoopNodeSheet } from '../../../Sheets/LoopNodeSheet/LoopNodeSheet';
import { NodeStatus } from '../../NodeStatus/NodeStatus';
import { LoopAction } from './LoopAction/LoopAction';
import {
  IconContainer,
  LoopActionsContainer,
  LoopContainer,
  LoopInfoContainer,
} from './NectedLoopNode.styled';

export const NectedLoopNode = memo(
  ({ data, isConnectable, id, type }: NodeProps) => {
    const { openWithProps } = useLayer(<LoopNodeSheet />);

    const [workflowErrorByNode] = useAtom(workflowErrorByNodeAtom);
    const [workflowNodes] = useAtom(workflowNodesAtom);
    const [, setChangedNodeIds] = useAtom(changedNodeIdsAtom);
    const [workflowEdges] = useAtom(workflowEdgesAtom);
    const [workflowStatus] = useAtom(workflowStatusAtom);
    const [isWorkflowTestOnly] = useAtom(isWorkflowTestOnlyAtom);

    const [, setIsNodeDisabled] = useState(false);
    // eslint-disable-next-line
    const { testData, loopData } = useTestLoopNode({
      localData: data,
      id,
    });

    useEffect(() => {
      const nodeDisabled = checkNodeDisabled(
        data.rootId ?? '',
        id,
        workflowNodes,
        workflowEdges
      );

      setIsNodeDisabled(nodeDisabled.isDisabled);
    }, [JSON.stringify(workflowNodes)]);

    const { sendEventToGTM } = useSendEventToGTM();

    const workflowNode = workflowNodes.find((wn) => wn.id === id);

    useEffect(() => {
      if (!_isNil(loopData)) {
        if (
          !_isNil(workflowNode) &&
          statusListForDataUpdate.includes(workflowStatus)
        ) {
          const newWorkflowNode = workflowNode;

          const exec = getExecutedValueAndStatus(loopData);
          newWorkflowNode.data.status = exec.status;

          newWorkflowNode.data.executedValue = exec.executedValue;

          setChangedNodeIds([]);

          setTimeout(() => {
            data.onWorkflowNodeChange(workflowNode);
          }, 100);
        }
      }
    }, [loopData]);

    const handleTestData = async () => {
      try {
        await testData(data.input, () =>
          toasts.success('Node tested successfully', 'node-test')
        );
      } catch (error) {}
    };

    return (
      <>
        <Handle
          type="source"
          position={Position.Bottom}
          style={COMMON_NODE_STYLES}
          isConnectable={isConnectable}
        />

        <Handle
          type="target"
          position={Position.Top}
          style={COMMON_NODE_STYLES}
          // eslint-disable-next-line
          isConnectable={isConnectable}
        />
        <LoopContainer
          padding="0.5rem"
          $isError={!(workflowErrorByNode[id] == null)}
        >
          <NodeStatus status={data.status} />

          <IconContainer
            onClick={() => {
              sendEventToGTM({
                event: 'workflow',
                source: 'listing',
                element: type,
                action: 'node_click',
                type: data.nodeType,
              });

              openWithProps({
                id,
                data,
              });
            }}
          >
            <TiArrowLoop size={32} />
          </IconContainer>
          <PadBox
            padding={{
              top: '0.6rem',
              bottom: '0.6rem',
            }}
          >
            <LoopInfoContainer>
              <Typography name="paragraphXs" fontWeight={700}>
                {data.name ?? 'Step 1'}
              </Typography>

              <Typography name="paragraphXs">Loop</Typography>

              {!(workflowErrorByNode[id] == null) && (
                <Typography name="errorXs">
                  {workflowErrorByNode[id]?.message}
                </Typography>
              )}
            </LoopInfoContainer>

            <LoopActionsContainer>
              <Inline
                align="center"
                style={{
                  background: 'var(--color-lightGray7)',
                  width: '4.5rem',
                }}
              >
                <IconButton
                  disabled={!isWorkflowTestOnly}
                  onClick={handleTestData}
                >
                  <IoPlayOutline
                    color={
                      isWorkflowTestOnly
                        ? 'var(--color-black)'
                        : 'var(--color-darkGray)'
                    }
                  />
                </IconButton>

                <LoopAction data={data} id={id} />
              </Inline>
            </LoopActionsContainer>
          </PadBox>
        </LoopContainer>
      </>
    );
  }
);

NectedLoopNode.displayName = 'NectedLoopNode';
