import { Inline } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import { useRef } from 'react';
import { BiChevronDown } from 'react-icons/bi';
import { Menu, MenuItem, PopoverMethods, Typography } from 'ui';

import { isRuleReadOnlyAtom } from '../../..';
import { simpleRuleNodesAtom } from '../SimpleRule';
import { OperatorStyled } from './RuleBlock.styled';

type GroupOperatorSelectionProps = {
  operator: string;
  id: string;
};

export function GroupOperatorSelection({
  id,
  operator,
}: GroupOperatorSelectionProps) {
  const menuRef = useRef<PopoverMethods>(null);
  const [isRuleReadOnly] = useAtom(isRuleReadOnlyAtom);
  const [, setRules] = useAtom(simpleRuleNodesAtom);

  return (
    <Menu
      onMenuItemClick={(value) => {
        setRules((prev) => ({
          ...prev,
          [id]: { ...prev[id], operator: value },
        }));

        menuRef.current?.hide();
      }}
      ref={menuRef}
      disabled={isRuleReadOnly}
      placement="bottom-start"
      launcher={
        <Inline align="center" gutter={4}>
          <OperatorStyled operator={operator}>
            <Typography>{operator === 'and' ? 'And' : 'Or'}</Typography>

            <BiChevronDown color="var(--color-black)" />
          </OperatorStyled>
        </Inline>
      }
    >
      <MenuItem value="and">
        <Typography>And</Typography>
      </MenuItem>
      <MenuItem value="or">
        <Typography>Or</Typography>
      </MenuItem>
    </Menu>
  );
}
