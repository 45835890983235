import { useMutation } from '@tanstack/react-query';
import type { AxiosError, AxiosResponse } from 'axios';
import { toasts } from 'ui';

import { useAxiosPrivate } from '../../../../hooks';
import { RuleTestActionPollingPayload } from '../../types';

export type TestRuleActionPayload = {
  params: RuleTestActionPollingPayload[];
};

export function useUseGetRuleTestActionResults() {
  const { axiosPrivate } = useAxiosPrivate();

  const {
    mutate: getActionResults,
    isLoading,
    data: actionData,
    error,
  } = useMutation({
    mutationFn: async (request: TestRuleActionPayload) => {
      return await axiosPrivate.post(`rule/execution-log`, request.params);
    },
    onSuccess(response: AxiosResponse) {
      return response;
    },
    onError(error: AxiosError) {
      const { message } = error;

      toasts.error(message, 'error');
    },
  });

  return {
    getActionResults,
    isLoading,
    actionData,
    error,
  };
}
