import { PadBox } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { RxCross2 } from 'react-icons/rx';
// eslint-disable-next-line import/no-named-default
import { default as _ReactPlayer } from 'react-player';
import { ReactPlayerProps } from 'react-player/types/lib';
import {
  IconButton,
  Modal,
  ModalContent,
  ModalFooter,
  Typography,
  toasts,
  useCurrentLayer,
} from 'ui';

import { getUserState } from '../../../../hooks/getUserState';
import { useSendEventToGTM } from '../../../../hooks/useSendEventToGTM';
import { isOnboardingCompleted } from '../../../../utils/common';
import { demoRuleCreated } from '../../../../utils/constant';
import { currentWorkspaceDetailAtom } from '../../../Workspace/atom';
import {
  ActionBlock,
  ActionBlockContainer,
  CustomClose,
} from './PopupWidget.styled';
import { updateWidgetState } from './UpdateWidgetState';
import { useGenrateEntityId } from './useGenerateEntityId';

const ReactPlayer = _ReactPlayer as unknown as React.FC<ReactPlayerProps>;

type PopoupWidgetsProps = {
  popupData?: any;
  state?: string;
  user: {
    email: string;
  };
};

export function PopoupWidgets({ popupData, user, state }: PopoupWidgetsProps) {
  const { close: closePopup } = useCurrentLayer();
  const [currentWorkspace] = useAtom(currentWorkspaceDetailAtom);
  const role = currentWorkspace?.role ?? '';
  const { generateEntityId } = useGenrateEntityId();
  const { sendEventToGTM } = useSendEventToGTM();

  function linkRedirect(link: string, target: string) {
    window.open(link, target);
  }

  const sendEventOnCloseClick = () => {
    switch (state) {
      case 'demo_rule_created':
        sendEventToGTM({
          event: 'widget',
          type: 'demo',
          type_selected: '',
          popup: 'close',
        });
        break;
      case 'new_rule_created':
        sendEventToGTM({
          event: 'widget',
          type: 'scratch',
          type_selected: '',
          popup: 'close',
        });
        break;
      case 'video':
        sendEventToGTM({
          event: 'widget',
          type: 'video',
          type_selected: '',
          popup: 'close',
        });
        break;
    }
  };

  const sendEventOnActionClick = (title: string, type: string) => {
    switch (state) {
      case 'demo_rule_created':
        sendEventToGTM({
          event: 'widget',
          type: 'demo',
          type_selected: title,
          popup: '',
        });

        sendEventToGTM({
          event: 'demo_rule_clicked',
          title,
        });

        sendEventToGTM({
          event: 'rule',
          source: 'demo',
          type,
          element: '',
          action: 'add_click',
        });
        break;
      case 'new_rule_created':
        sendEventToGTM({
          event: 'widget',
          type: 'scratch',
          type_selected: title,
          popup: '',
        });

        sendEventToGTM({
          event: 'rule',
          source: 'scratch',
          type: title,
          element: '',
          action: 'add_click',
        });
        break;
    }
  };

  const customClosePopup = () => {
    closePopup();
    sendEventOnCloseClick();

    if (popupData?.content?.type === 'VIDEO') {
      updateWidgetState(popupData.state)
        .then((res) => {
          void getUserState();
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    }
  };

  const handleActionBlockClick = (actionContainer: any) => {
    const { url, target, permission, message, entityId, id } =
      actionContainer.action;
    const { title, type } = actionContainer;
    sendEventOnActionClick(title, type);

    if (
      _isNil(permission) ||
      (!_isNil(permission) && (permission as string[]).includes(role))
    ) {
      if (!_isNil(entityId) && !_isEmpty(entityId)) {
        linkRedirect(url, target);
      } else if (_isNil(entityId) && _isNil(id)) {
        linkRedirect(url, target);
      } else {
        void updateUserState();
        void createEntityAndOpen(id, url, target);
      }
    } else {
      toasts.error(message ?? '', 'error');
      closePopup();
    }
  };

  const updateUserState = () => {
    if (!isOnboardingCompleted(demoRuleCreated)) {
      updateWidgetState(demoRuleCreated)
        .then(() => {
          void getUserState();
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    }
  };

  const createEntityAndOpen = (id: string, url: string, target: string) => {
    generateEntityId({
      usecaseId: id,
      url,
      target,
    });
  };

  const { title, subTitle } = popupData;
  function renderContent(popupData: any) {
    switch (true) {
      case Boolean(popupData?.content?.type === 'VIDEO'):
        return (
          <Stack gutter={'3rem'}>
            <Stack gutter={'0.4rem'}>
              <Typography name="heading2">{popupData.title}</Typography>
            </Stack>
            <Stack gutter={'0.5rem'}>
              <ReactPlayer
                url={popupData.content.data}
                playing={false}
                pip
                controls
                width={popupData.content.settings.width}
                height={popupData.content.settings.height}
                config={{
                  youtube: {
                    playerVars: { showinfo: 1 },
                  },
                }}
              />
            </Stack>
          </Stack>
        );
      default:
        return (
          <Stack gutter={'3rem'}>
            <Stack gutter={'0.4rem'}>
              <Typography name="heading2">{title}</Typography>
              <Typography name="heading3">{subTitle}</Typography>
            </Stack>
            <Stack gutter={'0.5rem'}>
              <ActionBlockContainer gutter="1rem">
                {popupData?.data.map((actionContainer: any, index: number) => (
                  <PadBox
                    key={index}
                    onClick={() => handleActionBlockClick(actionContainer)}
                    as={ActionBlock}
                    padding="1.5rem"
                    gutter="1.5rem"
                  >
                    <div style={{ minWidth: '100px' }}>
                      <img src={actionContainer.icon} alt="icon" />
                    </div>

                    <Stack gutter={'1rem'}>
                      <Typography fontWeight={700}>
                        {actionContainer.title}
                      </Typography>
                      <Typography name="secondary2">
                        {actionContainer.content}
                      </Typography>
                    </Stack>
                  </PadBox>
                ))}
              </ActionBlockContainer>
            </Stack>
          </Stack>
        );
    }
  }

  return (
    <Modal hideCloseButton={true} size="doubleExtraLarge">
      <CustomClose>
        <IconButton onClick={customClosePopup}>
          <RxCross2 size={16} />
        </IconButton>
      </CustomClose>
      <ModalContent>{renderContent(popupData)}</ModalContent>
      <ModalFooter></ModalFooter>
    </Modal>
  );
}
