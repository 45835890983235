import { Inline } from '@bedrock-layout/primitives';
import { useAtom } from 'jotai';
import _isNil from 'lodash/isNil';
import type { ReactNode } from 'react';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { BiChevronRight } from 'react-icons/bi';
import { Typography } from 'ui';

import { currentWorkspaceDetailAtom } from '../../pages/Workspace/atom';
import { checkIfThemeEnabled } from '../../utils/common';
import { envMap } from '../../utils/constant';
import { HowToLinkText, HowToStyled } from './HowToLink.styled';

type HowToLinkProps = {
  link?: string;
  variant?: 'link' | 'button';
  children?: ReactNode;
};

export function HowToLink({
  link = 'https://docs.nected.ai/',
  variant = 'button',
  children,
}: HowToLinkProps) {
  const [currentWorkspace] = useAtom(currentWorkspaceDetailAtom);

  const handleHowToClick = (event: any) => {
    event.stopPropagation();

    window.open(link, '_blank');
  };

  if (
    checkIfThemeEnabled(
      currentWorkspace?.whiteLabelEnabled,
      envMap.VITE_FULL_WHITELABELLED === 'true'
    )
  ) {
    return null;
  }

  if (variant === 'link') {
    return (
      <HowToLinkText align="center" gutter={4} onClick={handleHowToClick}>
        {!_isNil(children) ? children : <Typography>Learn more</Typography>}

        <BiChevronRight size={20} />
      </HowToLinkText>
    );
  }

  return (
    <HowToStyled onClick={handleHowToClick} padding={['0.5rem', '1rem']}>
      <Inline gutter={6} align="center">
        {!_isNil(children) ? (
          children
        ) : (
          <>
            <AiOutlineQuestionCircle size={20} />
            <Typography>How To</Typography>
          </>
        )}
      </Inline>
    </HowToStyled>
  );
}
