import { gql, useMutation } from '@apollo/client';

const requestReviewMutation = gql`
  mutation requestReview(
    $id: String!
    $entityType: EntityTypes!
    $title: String!
    $description: String
    $approvers: [String!]!
    $checksum: String!
  ) {
    requestReview(
      id: $id
      entityType: $entityType
      checksum: $checksum
      input: { title: $title, description: $description, approvers: $approvers }
    ) {
      status
      checksum
      message
    }
  }
`;

export function useRequestReviewForEntity() {
  return useMutation<Record<string, any>>(requestReviewMutation);
}
