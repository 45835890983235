import { ApolloError } from '@apollo/client';
import { Inline } from '@bedrock-layout/primitives';
import { Stack } from '@bedrock-layout/stack';
import { useAtom } from 'jotai';
import _isEmpty from 'lodash/isEmpty';
import _isNil from 'lodash/isNil';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Modal,
  ModalContent,
  ModalFooter,
  Spinner,
  TextAreaField,
  TextField,
  Typography,
  toasts,
  useCurrentLayer,
} from 'ui';

import { subscriptionPlanAtom } from '../../atom';
import {
  checkLimitExceeded,
  generateEntityLink,
  getMethodNameForApi,
  getMethodNameForMutationApi,
  showGraphQlErrorToast,
} from '../../utils/common';
import { maxFiftyCharactersRule } from '../../utils/validation';
import { StyledContainer } from './CloneEntityModal.styled';
import { useCreateEntityByType } from './hooks/useCreateEntityByType';
import { useGetEntityDataByIdAndType } from './hooks/useGetEntityDataByIdAndType';

type CloneEntityModalProps = {
  entityType: string;
  id?: string;
  subType?: string;
};

export const CloneEntityModal = ({
  id,
  entityType,
  subType,
}: CloneEntityModalProps) => {
  const navigate = useNavigate();
  const [subscriptionPlan] = useAtom(subscriptionPlanAtom);

  const [isDataFetching, setIsDataFetching] = useState(false);
  const [isCreating, setIsCreating] = useState(false);

  const { close } = useCurrentLayer();

  const [getEntityDataQuery, { data: entityData, error }] =
    useGetEntityDataByIdAndType(entityType, subType);

  const [createEntityQuery] = useCreateEntityByType(entityType, subType);

  const { control, setValue, handleSubmit } = useForm<Record<string, string>>({
    mode: 'onChange',
  });

  const methodName = getMethodNameForApi(entityType);
  const methodNameMutate = getMethodNameForMutationApi(entityType);

  useEffect(() => {
    if (!_isNil(id) && !_isNil(entityType)) {
      void getEntityData();
    }
  }, [id, entityType, subType]);

  useEffect(() => {
    if (!_isNil(entityData) && !_isEmpty(entityData) && !_isNil(methodName)) {
      const data = entityData[methodName].data?.[0];

      if (!_isNil(data)) {
        setValue('name', `${data.name as string}_copy`);
        setValue('description', data.description);
      }
    }
  }, [JSON.stringify(entityData), methodName]);

  useEffect(() => {
    if (!_isNil(error) && error instanceof ApolloError) {
      showGraphQlErrorToast(error);
    }
  }, [error]);

  const getEntityData = async () => {
    try {
      setIsDataFetching(true);
      await getEntityDataQuery({
        variables: { id, live: false, filters: {} },
        fetchPolicy: 'no-cache',
      });
    } catch (err) {
    } finally {
      setIsDataFetching(false);
    }
  };

  const onSubmit = async (formData: Record<string, any>) => {
    try {
      setIsCreating(true);
      const payload = entityData[methodName ?? ''].data?.[0];

      const dependencyMap = payload.dependencyMap?.map((currMapping: any) => ({
        entityId: currMapping.id,
        type: currMapping.type,
        version: currMapping.version,
        nodeId: currMapping.nodeId,
      }));

      const { data } = await createEntityQuery({
        variables: {
          ...payload,
          name: formData.name,
          description: formData.description,
          dependencyMap,
        },
      });

      if (!_isNil(data)) {
        const link = generateEntityLink({
          entityId: data[methodNameMutate ?? ''].id,
          entityType,
          category: subType,
        });

        navigate(link);
      }

      close();
    } catch (error: unknown) {
      if (error instanceof Error) {
        toasts.error(error.message, 'create-entity-error');
      }
    } finally {
      setIsCreating(false);
    }
  };

  return (
    <Modal
      title={`Clone ${
        entityType.charAt(0).toUpperCase() + entityType.slice(1)
      }`}
      size="extraLarge"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <ModalContent>
          <StyledContainer gutter="1.5rem">
            {isDataFetching ? (
              <Inline justify="center">
                <Spinner />
              </Inline>
            ) : (
              <>
                <Stack gutter="0.5rem">
                  <Typography
                    className="label-style"
                    name="heading4"
                  >{`${entityType} Name`}</Typography>
                  <TextField
                    size="medium"
                    name="name"
                    rules={{
                      required: {
                        value: true,
                        message: 'Required Field',
                      },
                      maxLength: maxFiftyCharactersRule,
                    }}
                    showErrorIcon={false}
                    control={control}
                    placeholder="Enter name"
                  />
                </Stack>
                <Stack gutter="0.5rem">
                  <Typography className="label-style" name="heading4">
                    {`${entityType} Description (Optional)`}
                  </Typography>
                  <TextAreaField
                    isDefaultOutlineVisible={true}
                    isShowingEllipsis={true}
                    control={control}
                    name="descripiton"
                    placeholder="Enter description"
                    size="large"
                  />
                </Stack>
              </>
            )}
          </StyledContainer>
        </ModalContent>
        <ModalFooter>
          <Button
            type="submit"
            disabled={checkLimitExceeded(entityType, subscriptionPlan)}
          >
            {isCreating ? <Spinner size="extraSmall" /> : 'Next'}
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};
