import _isNil from 'lodash/isNil';
import _reduce from 'lodash/reduce';

import type { PluginModel } from './components/plugin/Plugin';
import type { ConnectorAndPluginModel } from './types';

export function getCategoryWiseConnectors(
  connectors: ConnectorAndPluginModel[]
) {
  return _reduce(
    connectors,
    (acc: Record<string, ConnectorAndPluginModel[]>, connector) => {
      const { category } = connector;

      if (_isNil(acc[category])) {
        acc[category] = [];
      }
      acc[category].push(connector);

      return acc;
    },
    {}
  );
}

export function getCategoryWisePlugin(plugins: PluginModel[]) {
  return _reduce(
    plugins,
    (acc: Record<string, PluginModel[]>, plugin) => {
      const { category } = plugin;

      if (_isNil(acc[category])) {
        acc[category] = [];
      }
      acc[category].push(plugin);

      return acc;
    },
    {}
  );
}
