import { Inline, PadBox } from '@bedrock-layout/primitives';
import styled from 'styled-components';
import { Typography } from 'ui';

export const StepperContainer = styled(PadBox)`
  border-radius: 0.6rem;
  cursor: pointer;
  inline-size: 25%;
`;

export const StepperStepsHeaderBlock = styled(Inline)`
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

export const StepsCompletionContainer = styled.div`
  inline-size: 100%;
`;
export const CenterdText = styled(Typography)`
  text-align: center;
`;

export const StepperLeftContainer = styled(PadBox)`
  inline-size: 80%;
`;
export const StepperRightContainer = styled(PadBox)`
  inline-size: 20%;
`;

export const ResourceTitle = styled(Typography)`
  margin-bottom: 1rem;
`;

export const ResourceBlock = styled(PadBox)`
  background-color: var(--color-white);
  padding: 1rem;
  border-radius: 0.8rem;
`;

export const VideoThumbnail = styled.img`
  border-radius: 0.8rem;
  cursor: pointer;
`;

export const ResourceItem = styled(Inline)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
`;
