import { useMutation } from '@tanstack/react-query';
import type { AxiosError, AxiosResponse } from 'axios';

import { useAxiosPrivate } from '../../../../../hooks';
import { showErrorToast } from '../../../../../utils/common';
import type { CustomAxiosError } from '../../../../../utils/response/types';

export type ExecuteRestAPIAction = {
  environment: 'staging' | 'production';
  id: string;
  method: 'execute';
  params: Record<string, any>;
};

export function useExecuteRestAPIAction() {
  const { axiosPrivate } = useAxiosPrivate();

  const {
    data,
    mutate: executeRestAPIAction,
    isLoading,
  } = useMutation({
    mutationFn: async (request: ExecuteRestAPIAction) => {
      return await axiosPrivate.post('/integration/connector/execute', request);
    },
    onSuccess(response: AxiosResponse, requestPayload: ExecuteRestAPIAction) {
      return response;
    },
    onError(error: AxiosError<CustomAxiosError>) {
      showErrorToast(error);
    },
  });

  return {
    executeRestAPIAction,
    data,
    isLoading,
  };
}
